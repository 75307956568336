import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#3668BC",
        secondary: "#3668BC",
        accent: "#B7970C",
        error: "#E20028",
      },
      dark: {
        primary: "#3668BC",
        secondary: "#3668BC",
        accent: "#B7970C",
        error: "#E20028",
      },
    },
  },
});
