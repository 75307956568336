var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{staticClass:"mx-auto mt-2 rounded-lg elevation-4 mb-4 ml4",style:('border: 4px solid #1086D4;'),attrs:{"outlined":""}},[_c('v-list-item-content',[_c('div',{staticClass:"text-primary text-desp"},[_c('v-icon',{staticStyle:{"color":"#1086D4 !important"}},[_vm._v("mdi-clock-time-five-outline")]),_vm._v(" Oportunidad de Mercado ")],1),_c('div',{staticClass:"text-primary text-desp"},[_vm._v("Periodo de ventas : "+_vm._s(_vm.dateText))])])],1),(_vm.step1)?_c('v-card',{staticClass:"mx-auto rounded-xl elevation-5 ma-5",attrs:{"outlined":""}},[(_vm.loading)?_c('v-row',[_c('v-progress-linear',{attrs:{"active":_vm.loading,"indeterminate":_vm.loading,"top":"","color":"deep-purple accent-4"}})],1):_vm._e(),_c('br'),_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headerOpportunityMarket,"items":_vm.dataOpportunityMarket,"items-per-page":10,"item-key":"name","locale":"esp","search":_vm.search,"footer-props":{
				showFirstLastPage: true,
				firstIcon: 'mdi-arrow-collapse-left',
				lastIcon: 'mdi-arrow-collapse-right',
				prevIcon: 'mdi-minus',
				nextIcon: 'mdi-plus',
			}},scopedSlots:_vm._u([{key:`item.DIVI`,fn:function({ value }){return [_c('a',{staticClass:"a-set",on:{"click":function($event){return _vm.setDivision(value)}}},[_vm._v(_vm._s(value))])]}}],null,true)}),_c('v-footer',[_c('p',[_c('b',[_vm._v("Nota: Los datos corresponden al mes cerrado anterior")])])])],1):_vm._e(),(_vm.step2)?_c('v-card',{staticClass:"mx-auto rounded-xl elevation-5 ma-5",attrs:{"outlined":""}},[(_vm.loading)?_c('v-row',[_c('v-progress-linear',{attrs:{"active":_vm.loading,"indeterminate":_vm.loading,"top":"","color":"deep-purple accent-4"}})],1):_vm._e(),_c('br'),_c('v-row',[_c('v-col',{attrs:{"cols":"4","align-self":"left"}},[_c('v-btn',{attrs:{"color":"green"},on:{"click":function($event){return _vm.backStep1()}}},[_vm._v("Volver")])],1)],1),_c('br'),_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.searchDivision),callback:function ($$v) {_vm.searchDivision=$$v},expression:"searchDivision"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headerOpportunityMarketDivision,"items":_vm.dataOpportunityMarketDivision,"items-per-page":10,"item-key":"name","locale":"esp","search":_vm.searchDivision,"footer-props":{
				showFirstLastPage: true,
				firstIcon: 'mdi-arrow-collapse-left',
				lastIcon: 'mdi-arrow-collapse-right',
				prevIcon: 'mdi-minus',
				nextIcon: 'mdi-plus',
			}},scopedSlots:_vm._u([{key:`item.BEZEI`,fn:function({ value }){return [_c('a',{staticClass:"a-set",on:{"click":function($event){return _vm.setMaterial(value)}}},[_vm._v(_vm._s(value))])]}}],null,true)}),_c('v-footer',[_c('p',[_c('b',[_vm._v("Nota: Los datos corresponden al mes cerrado anterior")])])])],1):_vm._e(),(_vm.step3)?_c('v-card',{staticClass:"mx-auto rounded-xl elevation-5 ma-5",attrs:{"outlined":""}},[(_vm.loading)?_c('v-row',[_c('v-progress-linear',{attrs:{"active":_vm.loading,"indeterminate":_vm.loading,"top":"","color":"deep-purple accent-4"}})],1):_vm._e(),_c('br'),_c('v-row',[_c('v-col',{attrs:{"cols":"4","align-self":"left"}},[_c('v-btn',{attrs:{"color":"green"},on:{"click":function($event){return _vm.backStep2()}}},[_vm._v("Volver a División")])],1)],1),_c('br'),_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.searchMaterial),callback:function ($$v) {_vm.searchMaterial=$$v},expression:"searchMaterial"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headerOpportunityMarketMaterial,"items":_vm.dataOpportunityMarketMaterial,"items-per-page":10,"item-key":"name","locale":"esp","search":_vm.searchMaterial,"footer-props":{
				showFirstLastPage: true,
				firstIcon: 'mdi-arrow-collapse-left',
				lastIcon: 'mdi-arrow-collapse-right',
				prevIcon: 'mdi-minus',
				nextIcon: 'mdi-plus',
			}}}),_c('v-footer',[_c('p',[_c('b',[_vm._v("Nota: Los datos corresponden al mes cerrado anterior")])])])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }