<template>
    <div class="loader--container" v-if="loaderActive">
        <div><img src="/img/loader-1.gif" /></div>
    </div> 
</template>

<script>
import { mapGetters } from "vuex";
export default {
    computed: {
    ...mapGetters({      
        loaderActive: "loader/loaderActive"
    }),
  },
}
</script>

<style scoped>
  .loader--container{
    width: 100%;
    height: 100%;
    position: fixed;   
    z-index: 10000000;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255,255,255)
  }
</style>