<template>
  <v-container fluid>
    <v-tabs v-model="tab" background-color="accent" dark grow>
      <v-tab><v-icon>mdi-numeric-1-circle</v-icon> Carga de archivo </v-tab>
      <v-tab :disabled="!isLoaded">
        <v-icon>mdi-numeric-2-circle</v-icon> Pedido
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card :loading="loading">
          <v-card-title>Carga de información</v-card-title>
          <v-card-text>
            <v-form>
              <p>
                Seleccione su archivo con extensión csv para continuar con la
                carga
              </p>
              <v-row>
                <v-col cols="12" md="8">
                  <v-file-input
                    label="Archivo CSV"
                    outlined
                    v-model="file"
                    dense
                    accept=".csv"
                  ></v-file-input>
                </v-col>
                <v-col cols="12" md="4">
                  <v-btn color="primary" @click="sendFile">Cargar pedido</v-btn>
                </v-col>
                <v-col cols="12">
                  <a target="_blank" :href="`${url}/assets/pedidocsv.csv`">
                    <v-icon x-large>mdi-file-delimited-outline</v-icon>
                    Descarga formato CSV
                  </a>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
        <v-card class="mt-4" :disabled="!isLoaded" :loading="loading">
          <v-card-title>Datos cargados</v-card-title>
          <v-card-text>
            <h2>Datos válidos</h2>
            <v-data-table
              dense
              :headers="headers"
              :items="data.datos_material"
              item-key="codigo_material"
              class="elevation-1"
              disable-pagination
            >

              <template v-slot:[`item.precio_con_descuento`]="{ item }">
                $ {{ formatNumber(item.precio_con_descuento) }}
              </template>
              <template v-slot:[`item.importe_producto`]="{ item }">
                $ {{ formatNumber(item.importe_producto)}}
               <!-- $ {{ formatNumber(item.u_pedidas * item.precio_con_descuento) }}-->
              </template>
            </v-data-table>
            <h2 class="mt-5">Datos con error</h2>
            <v-data-table
              dense
              :headers="headers_errors"
              :items="data.datos_error"
              item-key="dataError"
              class="elevation-1"
            >
            </v-data-table>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn color="success" @click="continueToOrder"
              >Continuar
              <v-icon>mdi-menu-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card color="basil" flat>
          <v-card-text>
            <order-csv
              :idorder="data.orden_compra"
              :dataorder="dataorder"
            ></order-csv>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import axios from "@/plugins/axios";
import { mapGetters, mapMutations, mapActions } from "vuex";
import OrderCsv from "@/views/order_csv/OrderCsv.vue";
import { eventBus } from "@/main";
import moment from 'moment'


export default {
  name: "UploadFile",
  components: { OrderCsv },
  data() {
    return {
      url: process.env.VUE_APP_FILES_URL,
      tab: 0,
      file: [],
      isLoaded: false,
      loading: false,
      headers: [
        { text: "Imagen" },
        { text: "Código", value: "codigo_material" },
        { text: "Nombre", value: "nombre_material" },
        { text: "Cantidad", value: "u_pedidas" },
        { text: "Empaque", value: "empaque" },
        { text: "Unidad medida", value: "unidad_medida" },
        { text: "Promedio mensual de compra", value: "PMCOM" },
        { text: "Días solicitados de inventario", value: "DSINV" },
        { text: "Precio lista", value: "precio_lista" },
        { text: "Precio descuento", value: "precio_con_descuento" },
        { text: "Importe total", value: "importe_producto" },
      ],
      headers_errors: [
        { text: "Índice", value: "id_rec" },
        { text: "Código material", value: "codigo_material" },
        { text: "Cantidad", value: "u_pedidas" },
        { text: "Estatus", value: "validacion" },
        { text: "Detalle", value: "mensaje_error" },
      ],
      data: {},
      dataorder: [],
      today2: moment().format('YYYY/MM/DD'), 
      customer_code:null,
      numero_vendedor:null,
      type:null,
      pathHome:null,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  mounted() {
  if (this.$hasAnyRole(['Customer','CustomerPR'])) {
  this.customer_code =  this.user.custumer_code;
  this.type = 'CL';
  this.numero_vendedor = this.user.custumer_code;
  this.pathHome = '/';
 }else{
  const currentCustomerInfo= JSON.parse(localStorage.getItem('currentCustomerInfo')) || [];
        if (currentCustomerInfo.length<1) {
        this.$router.push({name:'Home'})
        } 
  this.customerInfo_1 = currentCustomerInfo;
  this.customer_code =  parseInt(currentCustomerInfo?.KUNNR,10) 
  this.sellerOrderAviable = false;
  this.type = 'VE';
  this.numero_vendedor = this.user.employee_number;
  this.pathHome = 'CustomerCoverDetails';
 } 
    eventBus.$on("OrderCsv::orderCreated", () => {
      this.tab = 0;
      this.file = [];
      this.data = {};
      this.dataorder = [];
    });
    this.generarPDF();
  },
  methods: {
    ...mapActions({
        sendToPDF: 'printer/sendToPDF',
        sendToExcel: 'printer/sendToExcel',
    }),
    ...mapMutations({
      setOrderId: "ordercsv/SET_ORDER_ID",
      setCard: "ordercsv/SET_CART",
    }),
    generarPDF(){
            this.sendToPDF({active: true});
    },
    handleFileUpload(event) {
      this.file = event.target.files[0];
    },
    sendFile() {
      this.loading = true;

      if(this.file == [] || this.file.length <= 0) {
        this.loading = false;
        this.$toast.warning("Debe seleccionar el archivo CSV para cargar el pedido");

        return;
      }
      const formData = new FormData();
      formData.append("file", this.file);
      axios
        .post("uploadFile", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.loading = false;
          this.data = response.data;
          this.getItems(this.data);
        })
        .catch((error) => {
          console.error(error);
          this.$toast.warning("Error al procesar el archivo. Verifique formato.");
          this.loading = false;
        });
    },
    getItems(data) {
      this.loading = true;
      axios
        .post("getItems", {
          pedido: data,
          customer: this.customer_code,
          VKORG: this.user.VKORG,
          VTWEG: this.user.VTWEG,
          VKBUR: this.user.VKBUR,
        })
        .then((response) => {
          this.data = response.data;
          this.setOrderId(response.data.orden_compra);
          this.setCard(response.data.datos_material);
          this.loading = false;
          this.isLoaded = true;
        })
        .catch((error) => {
          this.$toast.warning("Error al procesar el archivo. Verifique formato.");
          console.error(error);
          this.loading = false;
        });
    },
    formatNumber(value) {
      const f = parseFloat(value);
      return f.toFixed(2);
    },
    roundOff(num, places) {
      const x = Math.pow(num, places);
      const y = Math.round(num * x) / x;
      return y;
    },
    continueToOrder() {
      this.tab = 1;
      this.dataorder = this.data.datos_material;
      eventBus.$emit("OrderCsv::addOrderId", {
        orderId: this.data.orden_compra,
      });
    },
  },
};
</script>
