<template>
    
        
            <table style="min-width: 80px;  margin:0 auto;">
                <tbody>
                    <tr>
                        <td class="text-center" style="width: 50%"> 
                            <div v-if="item.planner.length > 0"> 
                                <div v-for="(planner, index) in item.planner" :key="index">                                              
                                    <div v-if="date.day == 'domingo' && index == 0">
                                        <!-- Validación asesor -->
                                        <div v-if="validateRoleManager()">
                                            <!-- icono gerente -->                                        
                                            <v-icon 
                                                :color="planner.status !== 1 ? 'black darken-2' : ''"
                                                @click="$emit(planner.status !== 1 ? 'selectCustomerResume' : 'selectCustomerEdit', item)">
                                                mdi-check-bold
                                            </v-icon>                                                                            
                                        </div>
                                        <div v-else>
                                            <!-- icono asesor -->
                                            <v-icon
                                                :color="planner.status !== 1 ? 'black darken-2' : ''">
                                                mdi-check-bold
                                            </v-icon>
                                        </div>
                                        
                                    </div>

                                    <v-icon :color="(planner.status != 0) ? 'green' : 'dark'"
                                        v-if="(date.day == formatDate(planner.start_date)) && planner.VP">
                                        mdi-check-all
                                    </v-icon>
                                </div>
                            </div>             
                            <div v-else>                                
                                <!-- Validación asesor -->
                                <div v-if="validateRoleManager()">
                                    <!-- icono gerente -->
                                    <v-icon color="black darken-2" v-if="date.day == 'domingo'" @click="$emit('selectCustomerResume', item)">
                                        mdi-check-bold
                                    </v-icon>                            
                                </div>
                                <div v-else>
                                    <!-- icono asesor -->
                                    <v-icon color="black darken-2" v-if="date.day == 'domingo'">
                                        mdi-check-bold
                                    </v-icon>
                                </div>       
                            </div>
                        </td>
                        <td class="text-center" style="width: 50%; ">
                            
                            <div v-for="(pedido, index) in item.pedidos" :key="index">
                                <div v-if="date.date == pedido.FECHA">
                                    <detalles-pedido :pedido="pedido" :tvpp="pedido.TVPP" :index="index"></detalles-pedido>
                                </div>
                            </div>
                                
                        </td>
                    </tr>
                </tbody>
            </table>                        
    
</template>

<script>
/* eslint-disable */
import { mapGetters } from "vuex";
import DetallesPedido from './DetallesPedido'
import moment from "moment"
export default {
    props:['item', 'date', 'startDate', 'endDate'],
    components: {DetallesPedido},
    data(){
        return {
            today: moment(),
            frequency: {
                'semanal': 1,
                'quincenal': 2,
                'mensual': 4
            },
        }
    },
    computed:{
        ...mapGetters({
            authenticated: "auth/authenticated"
        }),
    },
    methods:{
        formatDate(date) {
            return moment(date).format('dddd');
        },
        formatToday(date){
            return moment(date, 'YYYY-MM-DD'); // Crear un objeto Moment para la fecha objetivo
        },
        validateRoleManager(){
            return this.$hasAnyRole(['Manager']) && 
                   this.$route.meta.role === 'Manager' && 
                   this.today.isSameOrAfter(this.formatToday(this.startDate), 'day') &&
                   this.today.isSameOrBefore(this.formatToday(this.endDate), 'day')
        }
    }
    
}
</script>

<style>

</style>