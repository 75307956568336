<template>
    <v-card>
      <user-data-update-form 
        :user-name="user.name" 
        :loading="loading" 
        @on-save="handleContactInfoUpdate" 
      />
    </v-card>
  </template>
  
  <script>
  import UserDataUpdateForm from '@/components/forms/UserDataUpdateForm.vue';
  import { mapGetters, mapActions } from 'vuex';
  
  export default {
    components: {
      UserDataUpdateForm
    },
  
    data() {
      return {
        loading: false,
      }
    },
  
    methods: {
      ...mapActions('updateData', ['updateContactInfo']),
      ...mapActions('modalStepsAfterLogin', ['findStepByKey', 'updateStepStatus']),
  
      async handleContactInfoUpdate(formData) {
        const { phone, email } = formData;
        this.loading = true;
        try {
          await this.updateContactInfo({ phone, email });
          alert('Información actualizada correctamente.');
          //find key 'updateData'
          const updateStep = await this.findStepByKey('updateData');
          if (updateStep) {
            // 'doneRequired'  false
            updateStep.doneRequired = false;
            this.updateStepStatus(updateStep);
          }
        } catch (error) {
          console.error('Error al actualizar la información:', error);
        }
        this.loading = false;
      }
    },
  
    computed: {
      ...mapGetters('auth', ['user']),
    },
  }
  </script>
  