var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid grey lighten-5 pt-4"},[_c('v-row',[_c('v-col',{attrs:{"md":"6","offset":"6"}},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'ContactCreate' }}},[_c('v-btn',{attrs:{"color":"primary"}},[_vm._v("Crear Contacto")])],1)],1)],1),_c('br'),_c('card',[_c('h2',{staticClass:"h2"},[_vm._v("Contactos")]),(_vm.hasError)?_c('div',{staticClass:"alert alert-danger",staticStyle:{"font-weight":"500"},attrs:{"role":"alert"}},[_vm._v(_vm._s(_vm.msg))]):_vm._e(),_c('v-progress-linear',{attrs:{"active":_vm.loading,"indeterminate":_vm.loading,"top":"","color":"deep-purple accent-4"}}),(_vm.dataContact.length > 0)?_c('div',{staticClass:"card-body text-lg-center"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headerContact,"items":_vm.dataContact,"items-per-page":10,"item-key":"DESCR","locale":"esp","footer-props":{
						showFirstLastPage: true,
						firstIcon: 'mdi-arrow-collapse-left',
						lastIcon: 'mdi-arrow-collapse-right',
						prevIcon: 'mdi-minus',
						nextIcon: 'mdi-plus',
					}}})],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }