<template>
  <v-dialog :value="showDialog" width="900" persistent>
    <v-card>
      <v-tabs
        background-color="accent-4"
        center-active
        dark
        v-model="tab"
      >
        <v-tab>Nueva Solicitud</v-tab>
        <v-tab>Listado de Solicitudes</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <PromissoryNoteForm :customerCode="customerCode" :customerName="customerName"/>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <TablePromissoryNotes :customerCode="customerCode" v-if="tab == 1"></TablePromissoryNotes>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <v-card-actions>
          <button class="cancel-button" @click="close">Cerrar</button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PromissoryNoteForm from "./PromissoryNoteForm.vue";
import TablePromissoryNotes from "./TablePromissoryNotes.vue";

export default {
  name: "CreatePromissoryNote",
  props: ["showDialog", "customerCode", "customerName"],
  components: { PromissoryNoteForm, TablePromissoryNotes },
  data: () => ({
    customerId: null,
    tab: 0,
  }),
  methods: {
    close() {
      this.tab = 0;
      this.$emit('cancel');
    }
  },
};
</script>

