<template>
  <div>
    <v-container fluid class="white lighten-5 py-7" ref="printReport">
      <v-card
        class="pa-5 mb-0 ma-5 card-shadow"
        rounded="0"
        v-if=" 
          currentLevelLabel && this.currentLevelMatch(['family', 'material'])
        "
      >
        <h2 class="text-h5 ma-0 text-uppercase font-weight-bold">
          {{ currentLevelLabel }}
        </h2>
      </v-card>

      <v-col md="7" class="row-containerPAnelSol">
        <v-text-field v-model="search" label="Buscar" single-line hide-details>
          <template v-slot:append>
            <div class="red-icon-background-PanelSol">
              <v-icon style="font-size: 20px">mdi-magnify</v-icon>
            </div>
          </template>
        </v-text-field>
        <!--<v-icon style="font-size: 30px">mdi-notebook</v-icon>-->
        <!-- Icono de notebook -->
      </v-col>

      <v-card class="pas-5 mt-8 ma-5 card-shadow" rounded="0">
        <v-data-table
          id="borderless-table"
          dense
          :headers="headers"
          :items="filteredSellData"
          @pagination="handleShowOptionsChange"
          :loading="loading || loadingShowOptions"
          loading-text="Cargando información..."
          no-data-text="No se encontró información"
          :footer-props="{
            itemsPerPageText: 'Mostrar en grupo de',
            itemsPerPageAllText: 'Todos',
            pageText: '{0}-{1} de {2}',
          }"
        >
        </v-data-table>
      </v-card>
    </v-container>

    <!-- Alerts -->
    <v-snackbar v-model="error" top right>
      {{ errorMessage }}
    </v-snackbar>
  </div>
</template>

<script>
import api from "@/plugins/axios";
import Document from "@/mixins/document";
import Service from "@/mixins/service";
import { mapActions } from "vuex";

const mainHeaderBase = [ 
  { text: "Grupo de Material 4", value: "BEZEI" },
  { text: "Material", value: "MATNR" },
  { text: "Nombre", value: "TXZ01" },
  { text: "Cuenta proveedor", value: "LIFNR" },
  { text: "Ctd. Pendiente", value: "MENGE2" },
  { text: "UM", value: "MEINS3" },
  { text: "Fecha Entrega", value: "EINDT" },
];

export default {
  name: "OCPendientes",

  mixins: [Document, Service],

  data() {
    return {
      headers: mainHeaderBase,
      loading: false,
      loadingShowOptions: false,
      sellData: [],
      sellAux: [],
      errorMessage: "",
      search: "",
      loadAll: false,
      currentLevelLabel: "",
      length: -1,
    };
  },

  mounted() {
    this.fetchOCPendientesData();
  },

  computed: {
    filteredSellData() {
      return this.sellData.filter(item =>
        Object.values(item).some(val =>
          typeof val === 'string' && val.toLowerCase().includes(this.search.toLowerCase())
        )
      );
    },
  },

  methods: {
    ...mapActions({
      sendToPDF: "printer/sendToPDF",
    }),

    async fetchOCPendientesData() {
      this.loading = true;

      try {
        const { data } = await api.post(
          `${process.env.VUE_APP_API_SERVER}/pedidos-pendientes-ordm`,
          {}
        );

        data.forEach(item => {
      // Quitar ceros a la izquierda de Material y Cuenta
      item.MATNR = item.MATNR.replace(/^0+/, ''); // Quitar ceros a la izquierda
      item.LIFNR = item.LIFNR.replace(/^0+/, ''); // Quitar ceros a la izquierda
      
      // Quitar decimales de la cantidad pendiente
      item.MENGE2 = Math.floor(parseFloat(item.MENGE2)); // Quitar decimales
    });
        this.sellData = data;
        this.sellAux = data; // This will help us to fill up sellData when user selects show all records.
      } catch (error) {
        this.errorMessage = "Ocurrió un problema al recuperar la información.";
        console.error("Error fetching ordenes Pendientes:", error);
      }

      this.loading = false;
      this.sendToPDF({ active: true });
    },

    // When user select all records, screen freezes, we need to start pushing N items evert N seconds to prevent it
    async handleShowOptionsChange(pagination) { // Every time that this.sellData is modified this funcition its called again

      if (pagination.itemsPerPage == -1) { // It means that user wants to see all de records
        
        if (this.length == -1) { // If lenght = -1 it means that paginations process just started
          this.loadingShowOptions = true;
          this.sellData = []; // We clean the list to start filling it up
          this.length = 0;
          return;
        }

        if (this.sellData.length < this.sellAux.length) { // We verify that pagination is still in progress         
          const promiseResponse = await this.getSellDataPendientes(this.sellAux, this.length, (this.length + 100)); // It gets a list from the current position to position + N items
          this.length = this.length + 100;
          this.sellData.push(...promiseResponse); // Pushing the new items, at this point handleShowOptionsChange() its called again
        } else {
          this.loadingShowOptions = false; // Pagination has ended
        }

      } else { // It means that user elect group by 5, 10, etc but not All 
        this.length = -1;
        this.loadingShowOptions = false;

        if (this.sellData.length < this.sellAux.length) // In case user was displaying all records and change the groupBy option, and pagination have not finished
          this.sellData = this.sellAux;
      }
    },

    async getSellDataPendientes(items, start, end) {
      const resultpen = () => {
        const itemsSlice = items.slice(start, end);
        return itemsSlice;
      };

      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(resultpen());
        }, 1000);
      });
    },
  },
};
</script>

<style scoped>
.table-row {
  height: 60px;
  background-color: rgb(240, 240, 240);
}
.v-data-table >>> .v-data-table-header {
  box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029 !important;
  height: 60px;
  border-bottom: 2px solid black;
  background-color: rgb(240, 240, 240);
}

/* Agregar CSS para hacer que las flechas de ordenamiento siempre estén visibles */
.v-data-table-header .v-icon {
  opacity: 1 !important;
  visibility: visible !important;
}

#borderless-table >>> div > table > tbody > tr > td {
  border-bottom: 0px;
}

.row-containerPAnelSol {
  display: flex;
  align-items: center; /* Alinea los elementos verticalmente en el centro */
}
</style>
