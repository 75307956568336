import store from "@/store";
//import {hasAnyRole} from "./index"
import EmployeesView from "../views/administrator/EmployeesView.vue";
export default [
    {
        path: "/administrator/employees",
        name: "EmployeesView",
        component: EmployeesView,
      beforeEnter: (to, from, next) => {
             if (!store.getters["auth/authenticated"]) { 
              return next({ name: "SignIn" });
            } /*
            if (!hasAnyRole(store.getters["auth/roles"], ["Administrator"])) { 
              return next({
                  name: "403",
                });
              } */ 
            next();
          } 
      }
]