import axios from "@/plugins/axios";
import { mapGetters} from "vuex";

export const updateData = {
  namespaced: true,
  
  state: () => ({
    mustUpdateData: false,
    roles: ['Manager', 'manager', 'RegionalManager', 'Submanager', 'Director', 'Seller'] 
  }),

  getters: {
    mustUpdateData: (state) => state.mustUpdateData
  },

  mutations: {
    SET_UPDATE_DATA(state, value) {
      state.mustUpdateData = value; 
    }
  },

  computed: {
    ...mapGetters({
      userCode: "auth/userCode",
    }),
  },

  actions: {
    async updateContactInfo({ rootGetters, state }, { phone, email }) {
      const username = localStorage.getItem("username");
      if (!username) {
        throw new Error("Username no encontrado en el localStorage");
      }

      const roles = rootGetters['auth/roles']; 
      const hasRoles = roles.some(role => state.roles.includes(role.name)); 

      if (!hasRoles) {
        throw new Error("Acceso denegado. No tienes el rol adecuado para actualizar los datos de contacto.");
      }

      const payload = {
        username: username,
        phone: phone, 
        email: email,  
      };

      await axios.post('/user/update-contact-info', payload);
    },

    async checkSetNewUserInfo({ rootGetters,state,commit,dispatch}) {
      const hasRoles = await dispatch('auth/hasAnyRole', state.roles, { root: true }); 
      if (hasRoles) {
        const user = rootGetters['auth/user'];  
        if (user.email && user.phone ){  
          commit('SET_UPDATE_DATA', false); 
        }else{  
          commit('SET_UPDATE_DATA', true);
        }
      } else {
        commit('SET_UPDATE_DATA', false);
      }
    }
  },
};
