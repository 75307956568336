<template>
    <div>
      <v-skeleton-loader
        v-bind="attrs"
        type="card-avatar, article, actions"
        v-if="data == null"
      ></v-skeleton-loader>
      <div v-else>
        <v-card
          class="mx-auto rounded-lg elevation-4"
          outlined
          :style="'border: 4px solid gray;'"
        >
          <v-list-item-content>
            <div class="text-general">
              <v-icon>mdi-account-circle</v-icon>
              MI CUENTA:
            </div>
          </v-list-item-content>
        </v-card>
        <v-card class="mx-auto rounded-xl elevation-5 ma-5" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="text-overline mb-4">
                <v-avatar size="100" style="border: solid 4px #ff000a">
                  <img
                    src="@/assets/img/logo_iusa@2x.png"
                    alt="Cliente"
                    v-if="!user.avatar"
                  />
                  <img :src="user.avatar" alt="Cliente" v-else />
                </v-avatar>
              </div>
              <v-list-item-title class="text-h5 mb-1">
              {{ getName() }}
              <v-list-item-subtitle>
                {{ data.KUNNR }}
              </v-list-item-subtitle>
            </v-list-item-title>
  
            <div style="color: red !important">
              <v-icon style="color: red">mdi-email</v-icon>
              <span> {{ getEmail() }} </span>
            </div>
  
            <div style="color: blue !important">
              <a
                ><v-icon style="color: blue">mdi-phone</v-icon>
                <span> {{ getPhone() }}</span></a
              >
            </div>
  
            <v-layout justify-center>
              <v-switch v-model="switch1" class="green-label"
                ><template v-slot:label>
                  <span class="pr-2"> El número tiene Whatsapp </span>
                  <v-icon style="color: #25d366">mdi-whatsapp</v-icon>
                </template></v-switch
              >
            </v-layout>
  
            <div class="text-center">
              <v-btn
                type="submit"
                rounded
                block
                style="
                  background-color: #2fd410;
                  color: #fff;
                  text-transform: none;
                  font-weight: 300;
                "
              >
                Editar
              </v-btn>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </div>
  </div>
</template>

<script>
import axios from "@/plugins/axios";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      data: null,
      switch1: true,
      attrs: {
        class: "mb-6",
        boilerplate: true,
        elevation: 2,
      },
    };
  },

  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
  },

  mounted() {
    this.getCustomerInformation();
  },

  methods: {
    getCustomerInformation() {
      axios
        .post("getCustomerInformation", {
          client_code: this.user.custumer_code,
          society: "217",
          organization: "IUS2",
          channel: "DT",
        })
        .then((response) => {
          this.data = response.data;
        });
    },
    getName() {
      if(!this.data) return 'Usuario';

      if(this.data.NAME1) return this.data.NAME1;

      if(this.data.user.profile?.name) return this.data.user.profile.name;

      if(this.data.user.name) return this.data.user.name;
    },
    getEmail() {
      if(!this.data) return 'Sin email registrado';

      if(this.data.SMTP_ADDR) return this.data.SMTP_ADDR;

      if(this.data.user.email) return this.data.user.email;
    },
    getPhone() {
      if(!this.data) return 'Sin número registrado';

      if(this.data.TELF1) return this.data.TELF1;

      if(this.data.user.profile) return this.data.user.profile.telephone;
      
      return 'Sin número registrado';
    }
  },
};
</script>
