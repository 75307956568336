<template>
  <div>
    <div class="card__totals mt-3 mb-2">
      <v-btn icon>
        <v-icon color="white">mdi-magnify-plus-outline</v-icon>
      </v-btn>
      Clientes Totales: {{ customers.length }}
    </div>

    <ul class="card__list">
      <li class="card__li--between">
        <span>Con coordenadas:</span> 
        <span class="px-3">{{ countLocation }}</span>
      </li>
      <li class="card__li--between">
        <span>Con visitas planeadas:</span> 
        <span class="px-3">{{ countPlanner }}</span>
      </li>
      <li class="card__li--between">
        <span>Sin visitas planeadas:</span>
        <span class="px-3">{{ countWithoutPlanner }}</span>
      </li>
    </ul>

    <ul class="card__list">
      <li>
        <span>
          <input type="checkbox" id="selectAll" v-model="checkAll" @click="selectAll" @change="filterLocation"/>
          <label for="selectAll" class="ml-1">Seleccionar TODO</label>
        </span>
      </li>
      <li v-for="(label) in namesLabels" :key="label.name">
        <div class="d-flex justify-content-left align-items-center" >          
          <input type="checkbox" :id="`select${label.name}`" :value="label.name" v-model="checkLabels" @change="filterLocation"/>
          <v-icon :color="label.color">mdi-google-maps</v-icon>
          <label class="li__label--single-line" :for="`select${label.name}`" :title="label.name">{{ capitalizeWords(label.name) }}</label>
          <span class="ml-auto px-3">{{ countByGroupName(label.name) }}</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "FilterLocationCustomers",
  props: {
    customers: {
      type: Array,
      required: true,
    },
    namesLabels:{
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      checkLabels: [],
      checkAll: true
    };
  },  
  methods: {
    countByGroupName(label) {
      return this.customers.filter(
        (customer) =>
          customer.planner.length > 0 &&
          customer.label === label &&
          customer.location !== null
      ).length;
    },
    selectAll(){
      if(this.checkAll){
        this.checkLabels = [];
      }else{
        this.checkLabels = this.namesLabels.map(label => label.name);
      }
    },
    filterLocation(){
      this.$emit('filterLocation', this.checkLabels);
    },
    capitalizeWords(text){
      let words = text.toLowerCase().split(' ');
      let wordsCapitalize = words.map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      });
      return wordsCapitalize.join(' ');
    }
  },
  computed: {
    countLocation() {
      return this.customers.filter((customer) => customer.location !== null).length;
    },
    countPlanner() {
      return this.customers.filter((customer) => customer.planner.length > 0).length;
    },
    countWithoutPlanner() {
      const customers = this.customers.length;
      return customers - this.countPlanner;
    },
  },
  watch:{
    customers:{
      deep: true,
      immediate: true, 
      handler() {
        this.checkAll = true;
        this.checkLabels = this.namesLabels.map(label => label.name);
      }
    }
  }
};
</script>

<style scoped>
  .card__list {
    overflow-y: auto;
    max-height: 200px;
    width: 100%;
    padding: 10px 0;
    margin: 0;
  }
  .card__li--between{
    display: flex;
    justify-content: space-between;
  }
  .card__totals{
    border-top: 1px solid #FFF;
    border-bottom: 1px solid #FFF;
    padding: 5px 0;
  }
  .li__label--single-line {
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
</style>