<template>
	<v-row no-gutters :class="{ 'fill-height': !isMobile }">
		<v-col cols="12" md="3">
			<v-row no-gutters class="fill-height">
				<step
					v-for="(step, index) in steps" 
					:key="index" 
					:text="step.text" 
					:done="step.done || false"
					:visited="step.visited" 
					:step="step.value" 
					:current-step="currentStepValue"
					:disabled="validateDisabledStep(step, index)" 
					:active-if-is-next-step="!prevStepIsDisabled(index)"
					@on-click="(e) => emitStepSelection(index, e)"
				/>
			</v-row>
		</v-col>
		<v-col cols="12" md="9" class="pa-4 height-100-col">
			<slot />
			<div class="height-100 align-content-end mt-4">
				<slot name="footer">
					<v-row no-gutters :class="[hideBackBtn || hideNextBtn ? 'justify-end' : 'justify-space-between']">
						<v-btn v-if="!hideBackBtn" width="150px" class="red-button" :disabled="!canGoBack" @click="updateCurrentStep(-1)">
							Atras
						</v-btn>
						<v-btn v-if="!hideNextBtn && !lastStep" width="150px" class="red-button" :disabled="!canGoNext" @click="updateCurrentStep(1)">
							Siguiente
						</v-btn>
						<v-btn v-if="lastStep" width="150px" class="red-button" :disabled="!finished" @click="$emit('on-finish')">
							Cerrar
						</v-btn>
					</v-row>
				</slot>
			</div>
		</v-col>
	</v-row>
</template>

<script>
import Step from './Step.vue';

export default {
	name: 'Steper',

	props: {
		value: {
			type: Number,
			default: 0,
		},

		steps: {
			type: Array,
			required: true,
		},

		freeNavigationBetweenSteps: Boolean,
		hideNextBtn: Boolean,
		hideBackBtn: Boolean,
		finished: Boolean,
	},

	components: { Step },

	methods: {
		emitStepSelection(index, step) {
			this.$emit('step-selected', index, step);
			this.currentStepValue = step;
		},

		updateCurrentStep(step) {
			const currentStep = this.currentStepValue;
			const updatedStep =
				step >= 1
					? this.nextStep(currentStep + 1)
					: this.prevStep(currentStep - 1);

			if (updatedStep > 0) {
				this.emitStepSelection(updatedStep - 1, updatedStep);
			}
		},

		prevStep(numberOfSteps) { // Returns the first back step that is not disabled
			if (numberOfSteps == 0) return 0;

			// If the step is disabled it will call itself with a previews step
			const isDisabled = this.steps[numberOfSteps - 1].disabled;
			return isDisabled ? this.prevStep(numberOfSteps - 1) : numberOfSteps;
		},

		nextStep(numberOfSteps) { // Returns the first next step that is not disabled
			if (numberOfSteps > this.numberOfSteps) return 0;

			// If the step is disabled it will call itself with a next step
			const isDisabled = this.steps[numberOfSteps - 1].disabled;
			return isDisabled ? this.nextStep(numberOfSteps + 1) : numberOfSteps;
		},

		validateDisabledStep(step, index) {
			if (step.disabled) return true;
			if (this.freeNavigationBetweenSteps) return false;

			return this.prevStepNotDone(index);
		},

		prevStepIsDisabled(index) { // This makes available an step when the previews step is disabled but done
			if (this.freeNavigationBetweenSteps) return true;

			if (index > 0) {
				const step = this.steps[index - 1];
				return step.disabled && step.done;
			}

			return false;
		},

		prevStepNotDone(index) {
			if (index > 0) {
				const step = this.steps[index - 1];

				return !step.done && step.doneRequired !== false;
			}

			return false;
		},
	},

	computed: {
		currentStepValue: {
			set(newVal) {
				this.$emit('input', newVal);
			},

			get() {
				return this.value;
			},
		},

		numberOfSteps() {
			return this.steps.length;
		},

		currentStep() {
			return this.steps.find((s) => s.value === this.currentStepValue) || null;
		},

		currentStepDone() {
			return this.currentStep?.done || false;
		},

		lastStep() {
			return this.currentStepValue === this.numberOfSteps;
		},

		canGoNext() {
			if (this.freeNavigationBetweenSteps || this.currentStep?.doneRequired === false) return true;

			return this.currentStepDone && !this.lastStep;
		},

		canGoBack() {
			return this.currentStepValue > 1;
		},

		isMobile() {
			return (
				this.$vuetify.breakpoint.name === 'sm' ||
				this.$vuetify.breakpoint.name === 'xs'
			);
		},
	},
};
</script>
