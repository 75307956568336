<template>
  <div class="container-fluid grey lighten-5 pt-4">
    <h2 class="h2">
      <v-icon style="color: #2c3e50; font-size: 32px; margin-top: -5px"
        >mdi-shield-account</v-icon
      >
      Solicitud Alta Cliente
    </h2>
    <h4>Formulario</h4>
    <p></p>
    <v-alert type="info"
      >Si usted está realizando su solicitud de alta desde una computadora; por
      favor tenga a la mano fotografías de fachada, bodega e interior de su
      establecimiento.</v-alert
    >
    <p></p>
    <div
      class="alert alert-danger"
      role="alert"
      v-if="hasError"
      style="font-weight: 500"
    >
      {{ msg }}
    </div>
    <p></p>
    <!-- Dialog loading -->
    <v-dialog v-model="loading" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Por favor espere...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- /. Dialog -->
    <v-stepper v-model="e1">
      <v-stepper-header>
        <v-stepper-step
          :complete="e1 > 1"
          step="1"
          append-icon="mdi-chevron-right"
          editable
        >
          <v-badge
            bordered
            color="error"
            :content="generalErrors"
            :value="generalErrors"
          >
            Información General
          </v-badge>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="e1 > 2" step="2" editable>
          <v-badge
            bordered
            color="error"
            :content="fiscalErrors"
            :value="fiscalErrors"
          >
            Información Fiscal y de Negocio
          </v-badge>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="3" editable>
          <v-badge
            bordered
            color="error"
            :content="documentErrors"
            :value="documentErrors"
          >
            Documentación Solicitada
          </v-badge>
        </v-stepper-step>
      </v-stepper-header>
      <form @submit.prevent="submit" enctype="multipart/form-data">
        <v-stepper-items>
          <v-stepper-content step="1">
            <div class="card">
              <div class="card-body text-lg-left">
                <div class="row">
                  <div
                    :class="[
                      customerRequest ? 'col-md-6' : 'col-md-12',
                      'text-left',
                    ]"
                  >
                    <label for="" class="mb-2 ml-2 label">Selecciona</label>
                    <v-radio-group
                      v-model="form.person_type"
                      row
                      class="justify-center"
                      @change="changeTaxRegimens"
                    >
                      <v-radio label="Personsa Física" value="fisica" id="radiofisica"></v-radio>
                      <v-radio label="Persona Moral" value="moral" id="radiomoral"></v-radio>
                    </v-radio-group>
                    <div
                      class="invalid-field-message"
                      v-if="errors.person_type?.length"
                    >
                      {{ errors.person_type?.[0] }}
                    </div>
                  </div>
                  <div
                    class="col d-flex justify-end"
                    style="text-align: center"
                    v-if="customerRequest"
                  >
                    <v-btn
                      color="success"
                      v-on:click="comments()"
                      style="text-transform: none"
                      class="text-h4 p-4 mt-4"
                    >
                      Seguimiento de Alta
                      <v-icon dark right class="text-h5 ml-2">
                        mdi-comment-text-multiple
                      </v-icon>
                    </v-btn>
                  </div>
                  <div class="col-md-4">
                    <label for="" class="mb-2 ml-2 label">{{
                      form.person_type == "moral" ? "Razón Social" : "Nombre"
                    }}</label>
                    <div class="input-group">
                      <input
                        class="form-control"
                        :placeholder="
                          form.person_type == 'moral'
                            ? 'Razón Social'
                            : 'Nombre'
                        "
                        v-model="form.company_name"
                        autofocus
                        id="company_name"
                      />
                      <div
                        class="invalid-field-message"
                        v-if="errors.company_name?.length"
                      >
                        {{ errors.company_name?.[0] }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label for="" class="mb-2 ml-2 label">RFC</label>
                    <div class="input-group">
                      <input
                        class="form-control"
                        placeholder="RFC"
                        v-model="form.rfc"
                        @keydown="checkRFClength($event)"
                        id="rfc"
                      />
                      <div
                        class="invalid-field-message"
                        v-if="errors.rfc?.length"
                      >
                        {{ errors.rfc?.[0] }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label for="" class="mb-2 ml-2 label">Calle</label>
                    <div class="input-group">
                      <input
                        class="form-control"
                        placeholder="Calle"
                        v-model="form.a_street"
                        id="street"
                      />
                      <div
                        class="invalid-field-message"
                        v-if="errors.a_street?.length"
                      >
                        {{ errors.a_street?.[0] }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-2">
                    <label for="" class="mb-2 ml-2 label"
                      >Número exterior</label
                    >
                    <div class="input-group">
                      <input
                        class="form-control"
                        placeholder="Número"
                        v-model="form.a_number"
                        id="exterior_number"
                      />
                      <div
                        class="invalid-field-message"
                        v-if="errors.a_number?.length"
                      >
                        {{ errors.a_number?.[0] }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <label for="" class="mb-2 ml-2 label"
                      >Número interior</label
                    >
                    <div class="input-group">
                      <input
                        class="form-control"
                        placeholder="Número"
                        v-model="form.interior_number"
                      />
                      <div
                        class="invalid-field-message"
                        v-if="errors.interior_number?.length"
                      >
                        {{ errors.interior_number?.[0] }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label for="" class="mb-2 ml-2 label">CP</label>
                    <div class="input-group">
                      <input
                        class="form-control"
                        placeholder="Buscar.."
                        v-model="form.a_postal_code"
                        @keydown="checkNumber($event, 5)"
                        id="postal_code"
                      />
                      <v-btn
                        icon
                        @click="getAddress()"
                        style="background-color: #ced4da"
                        id="zipButton"
                      >
                        <v-icon style="color: black">mdi-magnify</v-icon>
                      </v-btn>
                      <div
                        class="invalid-field-message"
                        v-for="(errorCp, index) in errors.a_postal_code"
                        :key="'cp-' + index"
                      >
                        {{ errorCp }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label for="" class="mb-2 ml-2 label">Estado</label>
                    <div class="input-group">
                      <!-- <input
                        class="form-control"
                        placeholder="Estado"
                        v-model="form.a_estate"
                      /> -->
                      <select v-model="form.state_sap_id" class="form-control" placeholder="Selecciona el estado" id="state_sap_id">
                        <option disabled value="">Selecciona el estado</option>
                        <option v-for="state in states" :key="state.id" :value="state.sap_id">{{ state.state }}</option>
                      </select>
                      <div
                        class="invalid-field-message"
                        v-if="errors.a_estate?.length"
                      >
                        {{ errors.a_estate?.[0] }}
                      </div>
                    </div>
                  </div>
                </div>
                <p></p>
                <div class="row">
                  <div class="col-md-4">
                    <label for="" class="mb-2 ml-2 label"
                      >Delegación o Municipio</label
                    >
                    <div class="input-group">
                      <input
                        class="form-control"
                        placeholder="Delegación o Municipio"
                        v-model="form.a_town"
                      />
                      <div
                        class="invalid-field-message"
                        v-if="errors.a_town?.length"
                      >
                        {{ errors.a_town?.[0] }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label for="" class="mb-2 ml-2 label">Colonia</label>
                    <!--<div class="input-group">
                      <input
                        class="form-control"
                        placeholder="Colonia"
                        v-model="form.a_suburb"
                      />
                    </div>-->
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Puedes escribir la colonia"
                        v-model="form.a_suburb"
                        v-if="canWriteAddress"
                      />
                      <select
                        class="form-select"
                        aria-label="multiple select example"
                        placeholder="Seleccionar"
                        id="suburb"
                        v-model="form.a_suburb"
                        v-else
                      >
                        <option value="" disabled>Selecciona una opción</option>
                        <option
                          v-for="suburb in suburbs"
                          v-bind:key="suburb"
                          v-bind:value="suburb"
                        >
                          {{ suburb }}
                        </option>
                      </select>
                      <div
                        class="invalid-field-message"
                        v-if="errors.a_suburb?.length"
                      >
                        {{ errors.a_suburb?.[0] }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label for="" class="mb-2 ml-2 label">Teléfono</label>
                    <div class="input-group">
                      <span class="input-group-text" id="basic-addon1"
                        ><i class="fa fa-phone"></i
                      ></span>
                      <input
                        class="form-control"
                        placeholder="Teléfono"
                        v-model="form.phone"
                        @keydown="checkNumber($event, 10)"
                        id="phone"
                      />
                      <div
                        class="invalid-field-message"
                        v-if="errors.phone?.length"
                      >
                        {{ errors.phone?.[0] }}
                      </div>
                    </div>
                  </div>
                </div>
                <p></p>
                <div class="row">
                  <div class="col-md-4">
                    <label for="" class="mb-2 ml-2 label"
                      >Nombre de Contacto</label
                    >
                    <div class="input-group">
                      <span class="input-group-text" id="basic-addon1"
                        ><i class="fa fa-user"></i
                      ></span>
                      <input
                        class="form-control"
                        placeholder="Nombre de Contacto"
                        v-model="form.contact_name"
                        id="contact_name"
                      />
                      <div
                        class="invalid-field-message"
                        v-if="errors.contact_name?.length"
                      >
                        {{ errors.contact_name?.[0] }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label for="" class="mb-2 ml-2 label">Email</label>
                    <div class="input-group">
                      <span class="input-group-text" id="basic-addon1"
                        ><i class="fa fa-envelope"></i
                      ></span>
                      <input
                        class="form-control"
                        placeholder="Email"
                        v-model="form.email"
                        id="contact_email"
                      />
                      <div
                        class="invalid-field-message"
                        v-if="errors.email?.length"
                      >
                        {{ errors.email?.[0] }}
                      </div>
                    </div>
                  </div>
                  <p></p>
                  <hr />
                  <v-row no-gutters>
                    <v-col md="4" offset-md="8" style="text-align: right">
                      <v-btn
                        color="primary"
                        @click="e1 = 2"
                        style="text-transform: none"
                        id="firstButton"
                      >
                        Siguiente
                        <v-icon dark right> mdi-chevron-right </v-icon></v-btn
                      >
                    </v-col>
                  </v-row>
                </div>
                <p></p>
              </div>
            </div>
          </v-stepper-content>
          <v-stepper-content step="2">
            <div class="card">
              <div class="card-body text-lg-left">
                <p></p>
                <p></p>
                <div class="row">
                  <div class="col-md-4">
                    <label for="" class="mb-2 ml-2 label">Regimén Fiscal</label>
                    <div class="input-group">
                      <select class="form-select" v-model="form.fiscal_regimen" id="fiscal_regimen">
                        <option disabled value="">
                          Seleccione un elemento
                        </option>
                        <option
                          v-for="tax_regime in tax_regimes"
                          :key="'tax-regime-' + tax_regime.id"
                          :value="tax_regime.code"
                        >
                          {{ tax_regime.code }} - {{ tax_regime.name }}
                        </option>
                      </select>
                      <div
                        class="invalid-field-message"
                        v-if="errors.fiscal_regimen?.length"
                      >
                        {{ errors.fiscal_regimen?.[0] }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label for="" class="mb-2 ml-2 label">Método de Pago</label>
                    <div class="input-group">
                      <span class="input-group-text" id="basic-addon1"
                        ><i class="fa fa-credit-card"></i
                      ></span>
                      <select class="form-select" v-model="form.payment_method" id="payment_method">
                        <option disabled value="">
                          Seleccione un elemento
                        </option>
                        <option
                          v-for="method in payment_methods"
                          :key="`pm-${method.id}`"
                          :value="method.code"
                        >
                          {{ method.code }} - {{ method.method }}
                        </option>
                      </select>
                      <div
                        class="invalid-field-message"
                        v-if="errors.payment_method?.length"
                      >
                        {{ errors.payment_method?.[0] }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label for="" class="mb-2 ml-2 label">Uso de CFDI</label>
                    <div class="input-group">
                      <select
                        v-model="form.cfdi_use"
                        class="form-select"
                        aria-label="multiple select example"
                        id="cdfi_use"
                      >
                        <option disabled value="">
                          Seleccione un elemento
                        </option>
                        <option
                          v-for="cfdiuse in cfdi_uses"
                          :key="cfdiuse.id"
                          v-bind:value="cfdiuse.id"
                        >
                          {{ cfdiuse.code }} - {{ cfdiuse.name }}
                        </option>
                      </select>
                      <div
                        class="invalid-field-message"
                        v-if="errors.cfdi_use?.length"
                      >
                        {{ errors.cfdi_use?.[0] }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label for="" class="mb-2 ml-2 label">Forma de Pago</label>
                    <div class="input-group">
                      <select class="form-select" v-model="form.way_pay_id" id="way_pay_id">
                        <option value="" disabled>
                          Selecciona un elemento
                        </option>
                        <option
                          v-for="way in way_pays"
                          :key="`fp-${way.id}`"
                          :value="way.id"
                        >
                          {{ way.code }} - {{ way.name }}
                        </option>
                      </select>
                      <div
                        class="invalid-field-message"
                        v-if="errors.way_pay_id?.length"
                      >
                        {{ errors.way_pay_id?.[0] }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label for="" class="mb-2 ml-2 label"
                      >¿Solicitas línea de crédito?</label
                    >
                    <div class="input-group">
                      <select
                        class="form-select"
                        aria-label="multiple select example"
                        v-model="form.request_credit"
                        @change="form.line_request_credit = ''"
                      >
                        <option value="" disabled>Selecciona una opción</option>
                        <option value="Si">Si</option>
                        <option value="No">No</option>
                      </select>
                      <div
                        class="invalid-field-message"
                        v-if="errors.request_credit?.length"
                      >
                        {{ errors.request_credit?.[0] }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4" v-show="showLineRequestCredit">
                    <label for="" class="mb-2 ml-2 label"
                      >Línea de Crédito Solicitada</label
                    >
                    <div class="input-group">
                      <select
                        v-model="form.line_request_credit"
                        class="form-select"
                      >
                        <option disabled value="">Seleccione una opción</option>
                        <option value="20000">$ 20,000</option>
                        <option value="25000">$ 25,000</option>
                        <option value="30000">$ 30,000</option>
                        <option value="35000">$ 35,000</option>
                        <option value="40000">$ 40,000</option>
                        <option value="45000">$ 45,000</option>
                        <option value="50000">$ 50,000</option>
                        <option value="55000">$ 55,000</option>
                        <option value="60000">$ 60,000</option>
                        <option value="65000">$ 65,000</option>
                        <option value="70000">$ 70,000</option>
                        <option value="75000">$ 75,000</option>
                        <option value="80000">$ 80,000</option>
                        <option value="85000">$ 85,000</option>
                        <option value="90000">$ 90,000</option>
                        <option value="95000">$ 95,000</option>
                        <option value="100000">$ 100,000</option>
                        <option value="125000">$ 125,000</option>
                        <option value="150000">$ 150,000</option>
                        <option value="175000">$ 175,000</option>
                        <option value="200000">$ 200,000</option>
                        <option value="225000">$ 225,000</option>
                        <option value="250000">$ 250,000</option>
                        <option value="275000">$ 275,000</option>
                        <option value="300000">$ 300,000</option>
                        <option value="325000">$ 325,000</option>
                        <option value="350000">$ 350,000</option>
                        <option value="375000">$ 375,000</option>
                        <option value="400000">$ 400,000</option>
                      </select>
                      <div
                        class="invalid-field-message"
                        v-if="errors.line_request_credit?.length"
                      >
                        {{ errors.line_request_credit?.[0] }}
                      </div>
                      <!-- <span class="input-group-text" id="basic-addon1"
                        ><i class="fa fa-dollar-sign"></i
                      ></span>
                      <input
                        class="form-control"
                        placeholder="Línea de Crédito Solicitada"
                        v-model="form.line_request_credit"
                      /> -->
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div>
                      <label for="" class="mb-2 ml-2 label"
                        >Nombre de Representante Legal</label
                      >
                      <div class="input-group">
                        <span class="input-group-text" id="basic-addon1"
                          ><i class="fa fa-user"></i
                        ></span>
                        <input
                          class="form-control"
                          placeholder="Nombre de Representante Legal"
                          v-model="form.fiscal_rep_name"
                        />
                        <div
                          class="invalid-field-message"
                          v-if="errors.fiscal_rep_name?.length"
                        >
                          {{ errors.fiscal_rep_name?.[0] }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="row">
                    <select v-model="form.line_request_credit" class="form-control">
                      <option disabled value="">Seleccione una opción</option>
                      <option>12000</option>
                      <option>50000</option>
                      <option>100000</option>
                      <option>200000</option>
                      <option>400000</option>
                    </select>
                  </div> -->
                  <div class="col-md-4">
                    <label for="" class="mb-2 ml-2 label"
                      >Ventas Mensuales del Negocio</label
                    >
                    <div class="input-group">
                      <span class="input-group-text" id="basic-addon1"
                        ><i class="fa fa-dollar-sign"></i
                      ></span>
                      <input-money-number
                        v-model="form.month_sales"
                        placeholder="Ventas Mensuales del Negocio"
                      ></input-money-number>
                      <div
                        class="invalid-field-message"
                        v-if="errors.month_sales?.length"
                      >
                        {{ errors.month_sales?.[0] }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label for="" class="mb-2 ml-2 label"
                      >Tamaño del Negocio (m2)</label
                    >
                    <div class="input-group">
                      <input type="text" class="form-control" v-model="form.business_size" placeholder="Tamaño del Negocio (m2)">
                      <div
                        class="invalid-field-message"
                        v-if="errors.business_size?.length"
                      >
                        {{ errors.business_size?.[0] }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label for="" class="mb-2 ml-2 label"
                      >Giro del Negocio</label
                    >
                    <div class="input-group">
                      <input
                        class="form-control"
                        placeholder="Giro del Negocio"
                        v-model="form.line_bussines"
                      />
                      <div
                        class="invalid-field-message"
                        v-if="errors.line_bussines?.length"
                      >
                        {{ errors.line_bussines?.[0] }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label for="" class="mb-2 ml-2 label"
                      >¿Las instalaciones son propias?</label
                    >
                    <div class="input-group">
                      <select
                        class="form-select"
                        aria-label="multiple select example"
                        v-model="form.own_facilities"
                      >
                        <option value="" disabled>Selecciona una opción</option>
                        <option value="Si">Si</option>
                        <option value="No">No</option>
                      </select>
                      <div
                        class="invalid-field-message"
                        v-if="errors.own_facilities?.length"
                      >
                        {{ errors.own_facilities?.[0] }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label for="" class="mb-2 ml-2 label"
                      >¿Entregas en Dirección Fiscal?</label
                    >
                    <div class="input-group">
                      <select
                        class="form-select"
                        aria-label="multiple select example"
                        v-model="form.delivery_fiscal_addres"
                      >
                        <option value="" disabled>Selecciona una opción</option>
                        <option value="Si">Si</option>
                        <option value="No">No</option>
                      </select>
                      <div
                        class="invalid-field-message"
                        v-if="errors.delivery_fiscal_addres?.length"
                      >
                        {{ errors.delivery_fiscal_addres?.[0] }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12" v-if="false">
                    <v-btn color="primary" @click="addCustomerAddress"
                      >Agregar dirección</v-btn
                    >
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Razón social</th>
                            <th class="text-left">RFC</th>
                            <th class="text-left">Dirección</th>
                            <th class="text-left">Delegación/Municipio</th>
                            <th class="text-left">Código postal</th>
                            <th class="text-left">Estado</th>
                            <th class="text-left">Contacto</th>
                            <th class="text-left">Teléfono</th>
                            <th>Acciones</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in form.addresses" :key="item.id">
                            <td>{{ item.name }}</td>
                            <td>{{ item.rfc }}</td>
                            <td>
                              {{ item.street }} {{ item.interior_number }}
                              {{ item.outdoor_number }} {{ item.suburb }}
                            </td>
                            <td>{{ item.town }}</td>
                            <td>{{ item.state }}</td>
                            <td>{{ item.postal_code }}</td>
                            <td>{{ item.contact_name }}</td>
                            <td>{{ item.contact_phone }}</td>
                            <td>
                              <v-btn
                                icon
                                color="warning"
                                @click="editAddress(item)"
                              >
                                <v-icon>mdi-pencil</v-icon>
                              </v-btn>
                              <v-btn
                                icon
                                color="error"
                                @click="deleteAddress(item.id)"
                              >
                                <v-icon>mdi-trash-can</v-icon>
                              </v-btn>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </div>
                  <hr />
                  <v-row no-gutters>
                    <v-col md="4" offset-md="8" style="text-align: right">
                      <v-btn @click="e1 = 1" class="mr-2">Regresar</v-btn>
                      <v-btn
                        color="primary"
                        @click="e1 = 3"
                        style="text-transform: none"
                      >
                        Siguiente
                        <v-icon dark right> mdi-chevron-right </v-icon></v-btn
                      >
                    </v-col>
                  </v-row>
                </div>
                <p></p>
              </div>
            </div>
          </v-stepper-content>
          <p></p>
          <v-stepper-content step="3">
            <div class="card">
              <div class="card-header" style="font-weight: 700">
                Documentación Solicitada
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-4">
                    <div style="text-align: left; font-weight: 700">
                      <label for=""
                        >Foto 1 (Fachada)<br />
                        Tipo archivo: (.jpg .png)
                        <v-icon>mdi-image</v-icon></label
                      >
                      <div class="input-group">
                        <input
                          type="file"
                          class="form-control"
                          placeholder="Foto 1 (Fachada)"
                          @change="select_facade_photo"
                        />
                        <div
                          class="invalid-field-message"
                          v-if="errors.facade_photo?.length"
                        >
                          {{ errors.facade_photo?.[0] }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div style="text-align: left; font-weight: 700">
                      <label for=""
                        >Foto 2 (Interior)<br />
                        Tipo archivo: (.jpg .png)
                        <v-icon>mdi-image</v-icon></label
                      >
                      <div class="input-group">
                        <input
                          type="file"
                          class="form-control"
                          placeholder="Foto 2 (Interior)"
                          @change="select_indoor_photo"
                        />
                        <div
                          class="invalid-field-message"
                          v-if="errors.indoor_photo?.length"
                        >
                          {{ errors.indoor_photo?.[0] }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div style="text-align: left; font-weight: 700">
                      <label for=""
                        >Foto 3 (Mostrador/Bodega)<br />
                        Tipo archivo: (.jpg .png)
                        <v-icon>mdi-image</v-icon></label
                      >
                      <div class="input-group">
                        <input
                          type="file"
                          class="form-control"
                          placeholder="Foto 3 (Mostrador/Bodega)"
                          @change="select_bar_photo"
                        />
                        <div
                          class="invalid-field-message"
                          v-if="errors.bar_photo?.length"
                        >
                          {{ errors.bar_photo?.[0] }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div style="text-align: left; font-weight: 700">
                      <label for=""
                        >Identificación oficial de persona Fisica o
                        representante legal (ambos lados)<br />
                        Extensión archivo: (.pdf)
                        <v-icon>mdi-file-pdf-box</v-icon></label
                      >
                      <div class="input-group">
                        <input
                          type="file"
                          class="form-control"
                          placeholder="Identificación oficial de persona Fisica o representante legal (ambos lados)"
                          @change="select_official_id"
                        />
                        <div
                          class="invalid-field-message"
                          v-if="errors.official_id?.length"
                        >
                          {{ errors.official_id?.[0] }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div style="text-align: left; font-weight: 700">
                      <label for=""
                        >Comprobante de domicilio dirección fiscal no mayor a
                        tres meses<br />
                        Extensión archivo: (.pdf)
                        <v-icon>mdi-file-pdf-box</v-icon></label
                      >
                      <div class="input-group">
                        <input
                          type="file"
                          class="form-control"
                          placeholder="Comprobante de domicilio dirección fiscal no mayor a tres meses"
                          @change="select_proof_recidency"
                        />
                        <div
                          class="invalid-field-message"
                          v-if="errors.proof_recidency?.length"
                        >
                          {{ errors.proof_recidency?.[0] }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div style="text-align: left; font-weight: 700">
                      <label for=""
                        >Constancia de Situación Fiscal no mayor a tres meses<br />
                        Extensión archivo: (.pdf)
                        <v-icon>mdi-file-pdf-box</v-icon></label
                      >
                      <br />
                      <div class="input-group">
                        <input
                          type="file"
                          class="form-control"
                          placeholder="Constancia de Situación Fiscal no mayor a tres meses"
                          @change="select_evid_tax_registration"
                        />
                        <div
                          class="invalid-field-message"
                          v-if="errors.evid_tax_registration?.length"
                        >
                          {{ errors.evid_tax_registration?.[0] }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4" v-show="showCharter">
                    <div style="text-align: left; font-weight: 700">
                      <label for=""
                        >Acta constitutiva<br />
                        Extensión archivo: (.pdf)
                        <v-icon>mdi-file-pdf-box</v-icon></label
                      >
                      <div class="input-group">
                        <input
                          type="file"
                          class="form-control"
                          placeholder="Acta constitutiva"
                          @change="select_charter"
                        />
                        <div
                          class="invalid-field-message"
                          v-if="errors.charter?.length"
                        >
                          {{ errors.charter?.[0] }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4" v-show="showPowerAttorney">
                    <div style="text-align: left; font-weight: 700">
                      <label for=""
                        >Poder del Representante Legal. Solo en el caso de que
                        no este dentro del Acta Constitutiva<br />
                        Extensión archivo: (.pdf)
                        <v-icon>mdi-file-pdf-box</v-icon></label
                      >
                      <div class="input-group">
                        <input
                          type="file"
                          class="form-control"
                          placeholder="Solo en el caso de que no este dentro del Acta Constitutiva"
                          @change="select_power_attorney"
                        />
                        <div
                          class="invalid-field-message"
                          v-if="errors.power_attorney?.length"
                        >
                          {{ errors.power_attorney?.[0] }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4" v-show="showShcp">
                    <div style="text-align: left; font-weight: 700">
                      <label for=""
                        >En caso de que las entregas sean en un domicilio
                        distinto al Fiscal, formato de Alta de Apertura ante
                        SAT<br />
                        Extensión archivo: (.pdf)
                        <v-icon>mdi-file-pdf-box</v-icon></label
                      >
                      <div class="input-group">
                        <input
                          type="file"
                          class="form-control"
                          placeholder="En caso de que las entregas sean en un domicilio distinto al Fiscal, formato de Alta de Apertura ante SAT"
                          @change="select_shcp_opening"
                        />
                        <div
                          class="invalid-field-message"
                          v-if="errors.shcp_opening?.length"
                        >
                          {{ errors.shcp_opening?.[0] }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4" v-show="showCreditBureau">
                    <div style="text-align: left; font-weight: 700">
                      <label for=""
                        >Formato de Buró de Crédito<br />
                        Extensión archivo: (.pdf)
                        <v-icon>mdi-file-pdf-box</v-icon></label
                      >
                      <div class="input-group">
                        <input
                          type="file"
                          class="form-control"
                          placeholder="Formato de Buró de Crédito"
                          @change="select_credit_bureau"
                        />
                        <div
                          class="invalid-field-message"
                          v-if="errors.credit_bureau?.length"
                        >
                          {{ errors.credit_bureau?.[0] }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4" v-show="showPromissoryNote">
                    <div style="text-align: left; font-weight: 700">
                      <label for=""
                        >Pagaré<br />
                        Extensión archivo: (.pdf)
                        <v-icon>mdi-file-pdf-box</v-icon></label
                      >
                      <div class="input-group">
                        <input
                          type="file"
                          class="form-control"
                          placeholder="Pagaré"
                          @change="select_promissory_note"
                        />
                        <div
                          class="invalid-field-message"
                          v-if="errors.promissory_note?.length"
                        >
                          {{ errors.promissory_note?.[0] }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4" v-show="showOfficalIdEndors">
                    <div style="text-align: left; font-weight: 700">
                      <label for=""
                        >Identificación oficial de Aval (ambos lados)<br />
                        Extensión archivo: (.pdf)
                        <v-icon>mdi-file-pdf-box</v-icon></label
                      >
                      <div class="input-group">
                        <input
                          type="file"
                          class="form-control"
                          placeholder="Identificación oficial de Aval (ambos lados)"
                          @change="select_official_id_endors"
                        />
                        <div
                          class="invalid-field-message"
                          v-if="errors.official_id_endors?.length"
                        >
                          {{ errors.official_id_endors?.[0] }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4" v-show="showProofRecidencyEndors">
                    <div style="text-align: left; font-weight: 700">
                      <label for=""
                        >Comprobante de domicilio de Aval<br />
                        Extensión archivo: (.pdf)
                        <v-icon>mdi-file-pdf-box</v-icon></label
                      >
                      <div class="input-group">
                        <input
                          type="file"
                          class="form-control"
                          placeholder="Comprobante de domicilio de Aval"
                          @change="select_proof_recidency_endors"
                        />
                        <div
                          class="invalid-field-message"
                          v-if="errors.proof_recidency_endors?.length"
                        >
                          {{ errors.proof_recidency_endors?.[0] }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 d-flex justify-end">
                    <v-checkbox
                      class="mr-5"
                      v-model="form.regulation_data_protect"
                      :label="`Aviso de Protección de Datos`"
                      required
                      :rules="[
                        (v) =>
                          !!v || 'Debe aceptar el Aviso de Protección de Datos',
                      ]"
                      :readonly="!openedRegulationDataDialog"
                      @click="openRegulationDataProtect($event)"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="form.credit_policies"
                      :label="`Políticas de Crédito Nacionales`"
                      required
                      :rules="[
                        (v) =>
                          !!v ||
                          'Debe aceptar las Políticas de Crédito Nacionales',
                      ]"
                      :readonly="!openedCreditPoliciesDialog"
                      @click="openPoliticalDialog"
                    ></v-checkbox>
                  </div>
                  <p></p>
                  <hr />
                  <v-row no-gutters>
                    <v-col md="4" offset-md="8" style="text-align: right">
                      <v-btn @click="e1 = 2" class="mr-2">Regresar</v-btn>
                      <v-btn
                        color="success"
                        style="text-transform: none"
                        type="submit"
                      >
                        Enviar
                        <v-icon dark right>
                          mdi-check-circle-outline
                        </v-icon></v-btn
                      >
                    </v-col>
                  </v-row>
                </div>
                <p></p>
              </div>
            </div>
          </v-stepper-content>
          <p></p>
        </v-stepper-items>
      </form>
    </v-stepper>
    <p></p>
    <div class="text-center ma-2">
      <v-snackbar v-model="snackbar" :timeout="timeout">
        {{ msg }}
        <template v-slot:action="{ attrs }">
          <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
    <v-dialog v-model="showRegulationDataProtectectDialog" width="50%">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Aviso de Protección de Datos
        </v-card-title>

        <v-card-text>
          <iframe
            id="fred"
            style="border: 1px solid #666ccc"
            title="PDF in an i-Frame"
            :src="pdf_file"
            frameborder="1"
            scrolling="auto"
            width="100%"
            height="600px"
          ></iframe>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="cancelRegulationData"
            >No acepto</v-btn
          >
          <v-btn color="primary" @click="acceptRegulationDataCheckbox">
            Acepto
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showPoliticalDialog" width="50%">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Aviso de Protección de Datos
        </v-card-title>

        <v-card-text>
          <iframe
            id="fred"
            style="border: 1px solid #666ccc"
            title="PDF in an i-Frame"
            :src="political_pdf"
            frameborder="1"
            scrolling="auto"
            width="100%"
            height="600px"
          ></iframe>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="cancelCreditPolicies"
            >No acepto</v-btn
          >
          <v-btn color="primary" @click="acceptPoliticalCheckbox">
            Acepto
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addressDialog" width="70%">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"
          >Registrar dirección</v-card-title
        >
        <v-card-text>
          <CustomerAddresses
            :dataAddress="address"
            :submitAddress="onSubmitAddress"
            :cancelSubmitAddress="onCancelSubmitAddress"
          ></CustomerAddresses>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "@/plugins/axios";
import { mapGetters, mapMutations } from "vuex";
import InputMoneyNumber from "@/components/InputMoneyNumber.vue";
// import InputNumber from "@/components/InputNumber.vue";
import CustomerAddresses from "./CustomerAddresses.vue";
import { eventBus } from "@/main";

export default {
  name: "ClientRequest",
  components: { InputMoneyNumber, CustomerAddresses },

  props: ["userloaded", "customerRequest"],

  data() {
    return {
      pdf_file: this.$baseUrl + "/storage/aviso-de-privacidad.pdf",
      political_pdf: this.$baseUrl + "/storage/politica-de-ventas.pdf",
      selected: "",
      loading: false,
      e1: 1,
      snackbar: false,
      msg: null,
      hasError: false,
      timeout: 8000,
      touched: false,
      form: {
        person_type: null,
        id_user: localStorage.getItem("id"),
        company_name: "",
        rfc: "",
        a_street: "",
        a_number: "",
        a_suburb: "",
        a_town: "",
        a_estate: "",
        state_sap_id: "",
        a_postal_code: "",
        phone: "",
        contact_name: "",
        email: "",
        to_be_checked: false,
        agent_number: "",
        sales_office: "",
        sales_area: "",
        branch_code: "",
        supply_center: "",
        fiscal_regimen: "",
        payment_method: "",
        cfdi_use: "",
        transport_area: "",
        iva: "",
        request_credit: "",
        delivery_fiscal_addres: "",
        fiscal_rep_name: "",
        line_request_credit: "",
        month_sales: "",
        business_size: "",
        line_bussines: "",
        own_facilities: "",
        way_pay_id: "",
        line_credit_suggest: "",
        facade_photo: [],
        indoor_photo: [],
        bar_photo: [],
        evid_tax_registration: [],
        official_id: [],
        proof_recidency: [],
        proof_recidency_distinct: [],
        regulation_data_protect: [],
        credit_bureau: [],
        shcp_opening: [],
        charter: [],
        power_attorney: [],
        promissory_note: [],
        proof_recidency_endors: [],
        official_id_endors: [],
        bank_reference: [],
        credit_policies: [],
        addresses: [],
      },
      centerData: null,
      cfdiUseData: null,
      IvaData: null,
      suburbs: [],
      tax_regimes: [],
      tax_regimes_all: [],
      way_pays: [],
      cfdi_uses: [],
      payment_methods: [],
      errors: [],
      showedRegulationDataProtect: false,
      showRegulationDataProtectectDialog: false,
      showedPolitical: false,
      showPoliticalDialog: false,
      openedRegulationDataDialog: false,
      openedCreditPoliciesDialog: false,
      endpoint: "/saveCustumerData",
      canWriteAddress: false,
      addressDialog: false,
      address: null,
      states: [],
    };
  },

  async mounted() {
    let self = this;
    self.getIvaData();
    this.loadData();
    this.getGeneralData();
  },

  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
    generalErrors() {
      if (!this.touched) {
        return 0;
      }
      let numerrors = 0;
      let errorsKey = [
        "company_name",
        "rfc",
        "a_street",
        "a_number",
        "a_suburb",
        "a_town",
        "a_estate",
        "a_postal_code",
        "phone",
        "contact_name",
        "email",
      ];

      for (let item in errorsKey) {
        if (errorsKey[item] in this.errors) {
          numerrors++;
        }
      }
      return numerrors;
    },
    fiscalErrors() {
      if (!this.touched) {
        return 0;
      }
      let numerrors = 0;
      let errorsKey = [
        "fiscal_regimen",
        "payment_method",
        "cfdi_use",
        "request_credit",
        "delivery_fiscal_addres",
        "fiscal_rep_name",
        "line_request_credit",
        "month_sales",
        "business_size",
        "line_bussines",
        "own_facilities",
        "way_pay_id",
      ];

      for (let item in errorsKey) {
        if (errorsKey[item] in this.errors) {
          numerrors++;
        }
      }
      return numerrors;
    },
    documentErrors() {
      if (!this.touched) {
        return 0;
      }
      let numerrors = 0;
      let errorsKey = [
        "facade_photo",
        "indoor_photo",
        "bar_photo",
        "evid_tax_registration",
        "official_id",
        "proof_recidency",
        "proof_recidency_distinct",
        "regulation_data_protect",
        "credit_bureau",
        "shcp_opening",
        "charter",
        "power_attorney",
        "promissory_note",
        "proof_recidency_endors",
        "official_id_endors",
        "bank_reference",
        "credit_policies",
      ];

      for (let item in errorsKey) {
        if (errorsKey[item] in this.errors) {
          numerrors++;
        }
      }
      return numerrors;
    },
    showLineRequestCredit() {
      return this.form.request_credit == "Si";
    },
    showShcp() {
      return this.form.delivery_fiscal_addres == "No";
    },
    showCreditBureau() {
      return this.form.request_credit == "Si";
    },
    showPromissoryNote() {
      return this.form.request_credit == "Si";
    },
    showOfficalIdEndors() {
      return (
        this.form.request_credit == "Si" &&
        this.form.line_request_credit > 50000
      );
    },
    showProofRecidencyEndors() {
      return (
        this.form.request_credit == "Si" &&
        this.form.line_request_credit > 50000
      );
    },
    showCharter() {
      return this.form.person_type == "moral";
    },
    showPowerAttorney() {
      return this.form.person_type == "moral";
    },
  },

  watch: {
    customerRequest(val) {
      if (val != null) {
        this.loadRequestData();
        console.log("update");
      }
    },
    "form.delivery_fiscal_addres"(val) {
      if (val == "Si") {
        this.form.addresses = [];
      }
    },
  },

  methods: {
    ...mapMutations({
      setVisibleAlert: "auth/SET_VISIBLE_ALERT",
    }),
    select_facade_photo(event) {
      this.form.facade_photo = event.target.files[0];
    },
    select_indoor_photo(event) {
      this.form.indoor_photo = event.target.files[0];
    },
    select_bar_photo(event) {
      this.form.bar_photo = event.target.files[0];
    },
    select_evid_tax_registration(event) {
      this.form.evid_tax_registration = event.target.files[0];
    },
    select_official_id(event) {
      this.form.official_id = event.target.files[0];
    },
    select_proof_recidency(event) {
      this.form.proof_recidency = event.target.files[0];
    },
    select_regulation_data_protect(event) {
      this.form.regulation_data_protect = event.target.files[0];
    },
    select_credit_bureau(event) {
      this.form.credit_bureau = event.target.files[0];
    },
    select_shcp_opening(event) {
      this.form.shcp_opening = event.target.files[0];
    },
    select_charter(event) {
      this.form.charter = event.target.files[0];
    },
    select_power_attorney(event) {
      this.form.power_attorney = event.target.files[0];
    },
    select_promissory_note(event) {
      this.form.promissory_note = event.target.files[0];
    },
    select_proof_recidency_endors(event) {
      this.form.proof_recidency_endors = event.target.files[0];
    },
    select_official_id_endors(event) {
      this.form.official_id_endors = event.target.files[0];
    },
    select_credit_policies(event) {
      this.form.credit_policies = event.target.files[0];
    },
    submit() {
      this.touched = true;
      this.loading = true;
      this.e1 = 3;
      let form = new FormData();
      for (let key in this.form) {
        if (this.form[key] != null && key != "addresses") {
          console.log(key, typeof this.form[key]);
          form.append(key, this.form[key]);
        }
      }
      for (let i = 0; i < this.form.addresses.length; i++) {
        for (let key in this.form.addresses[i]) {
          form.append(`addresses[${i}][${key}]`, this.form.addresses[i][key]);
        }
      }
      axios
        .post(this.endpoint, form)
        .then((response) => {
          this.$router.push({
            name: "CustomerStatus",
            query: {
              data: response.data[0].id,
            },
          });
        })
        .catch((error) => {
          if (error.response.data.errors) {
            this.errors = error.response.data.errors;
            this.msg = "Por favor revisa los campos faltantes";
            this.hasError = true;
            this.snackbar = true;
            setTimeout(() => {
              this.hasError = false;
            }, 8000);
          } else {
            this.msg = error.response.data.message;
            this.hasError = true;
            setTimeout(() => {
              this.hasError = false;
            }, 8000);
          }
        })
        .finally(() => (this.loading = false));
    },
    getGeneralData() {
      axios
        .get("request-data")
        .then((response) => {
          this.tax_regimes_all = response.data.tax_regimes;
          this.way_pays = response.data.way_pays;
          this.payment_methods = response.data.payment_methods;
          this.cfdi_uses = response.data.cfdi_uses;
          this.states = response.data.states;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getIvaData() {
      axios
        .get("IvaData")
        .then((response) => (this.IvaData = response.data))
        .catch((error) => console.log(error))
        .finally(() => (this.loading = false));
    },
    getAddress() {
      this.cleanAddress();
      this.canWriteAddress = false;
      this.loading = true;
      this.errors = {};
      if (this.form.a_postal_code.length != 5) {
        this.errors.a_postal_code = ["El código postal deben ser 5 números"];
        this.loading = false;
        return this.setVisibleAlert("El código postal deben ser 5 números");
      }
      let form = new FormData();
      form.append("postal_code", this.form.a_postal_code);
      axios
        .post("searchPostalCode", form)
        .then((response) => {
          if (response.data?.[0]?.mesage) {
            this.canWriteAddress = true;
            this.form.to_be_checked = true;
            return (this.errors.a_postal_code = [
              "CP no existe en la base de datos del sat, revisar su constancia de situación fiscal por favor.",
            ]);
          } else {
            this.data = response.data;
            this.$set(this.form, "a_estate", response.data.a_estate);
            this.$set(this.form, "a_town", response.data.a_town);
            this.suburbs = response.data.a_suburb;
            if (this.suburbs.length <= 0) {
              this.canWriteAddress = true;
            }
            let state = this.states.find(item => item.state == response.data.a_estate);
            if(state) {
              this.form.state_sap_id = state.sap_id;
            }
          }
          //this.msg = response.data.mesage
        })
        .catch((error) => {
          if (error.response.data.errors) {
            this.msg = error.response.data.errors;
            this.hasError = true;
            this.snackbar = true;
            setTimeout(() => {
              this.hasError = false;
            }, 8000);
          } else {
            alert(error);
          }
        })
        .finally(() => (this.loading = false));
    },
    loadData() {
      if (this.userloaded) {
        this.form.contact_name = this.userloaded.profile?.name || "";
        this.form.email = this.userloaded.email || "";
        this.form.phone = this.userloaded.profile?.telephone || "";
      }
    },
    checkNumber(event, maxlength) {
      if (
        [
          "0",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "Backspace",
        ].includes(event.key)
      ) {
        if (
          event.key != "Backspace" &&
          event.target.value.length >= maxlength
        ) {
          return event.preventDefault();
        }
      } else {
        return event.preventDefault();
      }
    },
    checkRFClength(event) {
      let maxRfc = this.form.person_type == "fisica" ? 13 : 12;
      if (event.target.value.length >= maxRfc && event.key != "Backspace") {
        return event.preventDefault();
      }
    },
    changeTaxRegimens(value) {
      this.tax_regimes = this.tax_regimes_all.filter(
        (item) =>
          item.type.toLowerCase() == value || item.type.toLowerCase() == "ambos"
      );
      if (value == "moral" && this.form.rfc.length > 12) {
        this.form.rfc = this.form.rfc.slice(0, -1);
      }
    },
    openRegulationDataProtect(event) {
      if (!this.showedRegulationDataProtect) {
        this.showRegulationDataProtectectDialog = true;
        this.openedRegulationDataDialog = true;
        event.preventDefault();
      }
    },
    acceptRegulationDataCheckbox() {
      this.showRegulationDataProtectectDialog = false;
      this.form.regulation_data_protect = true;
    },
    openPoliticalDialog(event) {
      if (!this.showedPolitical) {
        this.showPoliticalDialog = true;
        this.openedCreditPoliciesDialog = true;
        event.preventDefault();
      }
    },
    acceptPoliticalCheckbox() {
      this.showPoliticalDialog = false;
      this.form.credit_policies = true;
    },
    cancelRegulationData() {
      this.form.regulation_data_protect = false;
      this.showRegulationDataProtectectDialog = false;
    },
    cancelCreditPolicies() {
      this.form.credit_policies = false;
      this.showPoliticalDialog = false;
    },
    loadRequestData() {
      this.endpoint = "/updateCustomerDetail";

      this.suburbs.push(this.customerRequest.a_suburb);
      this.changeTaxRegimens(this.customerRequest.person_type);
      this.form.custumer_id = this.customerRequest.custumer_id;
      this.form.person_type = this.customerRequest.person_type;
      this.form.company_name = this.customerRequest.company_name;
      this.form.rfc = this.customerRequest.rfc;
      this.form.a_street = this.customerRequest.a_street;
      this.form.a_number = this.customerRequest.a_number;
      this.form.interior_number = this.customerRequest.interior_number;
      this.form.a_suburb = this.customerRequest.a_suburb;
      this.form.a_town = this.customerRequest.a_town;
      this.form.a_estate = this.customerRequest.a_estate;
      this.form.state_sap_id = this.customerRequest.state_sap_id;
      this.form.a_postal_code = this.customerRequest.a_postal_code;
      this.form.phone = this.customerRequest.phone;
      this.form.contact_name = this.customerRequest.contact_name;
      this.form.email = this.customerRequest.email;
      this.form.to_be_checked = this.customerRequest.to_be_checked;
      this.form.agent_number = this.customerRequest.agent_number;
      this.form.sales_office = this.customerRequest.sales_office;
      this.form.sales_area = this.customerRequest.sales_area;
      this.form.branch_code = this.customerRequest.branch_code;
      this.form.supply_center = this.customerRequest.supply_center;
      this.form.fiscal_regimen = this.customerRequest.fiscal_regimen;
      this.form.payment_method = this.customerRequest.payment_method;
      this.form.cfdi_use = this.customerRequest.cfdi_use;
      this.form.transport_area = this.customerRequest.transport_area;
      this.form.iva = this.customerRequest.iva;
      this.form.request_credit = this.customerRequest.request_credit;
      this.form.delivery_fiscal_addres =
        this.customerRequest.delivery_fiscal_addres;
      this.form.fiscal_rep_name = this.customerRequest.fiscal_rep_name;
      this.form.line_request_credit = this.customerRequest.line_request_credit;
      this.form.month_sales = this.customerRequest.month_sales;
      this.form.business_size = this.customerRequest.business_size;
      this.form.line_bussines = this.customerRequest.line_bussines;
      this.form.own_facilities = this.customerRequest.own_facilities;
      this.form.way_pay_id = this.customerRequest.way_pay_id;
      this.form.line_credit_suggest = this.customerRequest.line_credit_suggest;
      this.form.facade_photo = this.customerRequest.facade_photo;
      this.form.indoor_photo = this.customerRequest.indoor_photo;
      this.form.bar_photo = this.customerRequest.bar_photo;
      this.form.evid_tax_registration =
        this.customerRequest.evid_tax_registration;
      this.form.official_id = this.customerRequest.official_id;
      this.form.proof_recidency = this.customerRequest.proof_recidency;
      this.form.proof_recidency_distinct =
        this.customerRequest.proof_recidency_distinct;
      this.form.regulation_data_protect =
        this.customerRequest.regulation_data_protect;
      this.form.credit_bureau = this.customerRequest.credit_bureau;
      this.form.shcp_opening = this.customerRequest.shcp_opening;
      this.form.charter = this.customerRequest.charter;
      this.form.power_attorney = this.customerRequest.power_attorney;
      this.form.promissory_note = this.customerRequest.promissory_note;
      this.form.proof_recidency_endors =
        this.customerRequest.proof_recidency_endors;
      this.form.official_id_endors = this.customerRequest.official_id_endors;
      this.form.bank_reference = this.customerRequest.bank_reference;
      this.form.credit_policies = this.customerRequest.credit_policies;
      this.form.addresses = this.customerRequest.addresses;
    },
    comments() {
      this.$router.push("/customer-status?data=" + this.form.custumer_id);
    },
    cleanAddress() {
      this.form.a_suburb = "";
      this.form.a_town = "";
      this.form.a_estate = "";
    },
    addCustomerAddress() {
      this.addressDialog = true;
    },
    onSubmitAddress(form) {
      if(this.address && this.address.id) {
        let index = this.form.addresses.findIndex(item => item.id == this.address.id);

        if(index >= 0) {
          this.form.addresses[index].person_type = form.person_type;
          this.form.addresses[index].name = form.name;
          this.form.addresses[index].rfc = form.rfc;
          this.form.addresses[index].postal_code = form.postal_code;
          this.form.addresses[index].state = form.state;
          this.form.addresses[index].town = form.town;
          this.form.addresses[index].suburb = form.suburb;
          this.form.addresses[index].street = form.street;
          this.form.addresses[index].outdoor_number = form.outdoor_number;
          this.form.addresses[index].interior_number = form.interior_number;
          this.form.addresses[index].contact_name = form.contact_name;
          this.form.addresses[index].contact_phone = form.contact_phone;
        }
      } else {
        this.form.addresses.push(form);
      }
      eventBus.$emit("CustomerAddresses::resetForm");
      this.addressDialog = false;
    },
    onCancelSubmitAddress() {
      this.addressDialog = false;
    },
    editAddress(item) {
      this.address = item;
      this.addCustomerAddress();
    },
    deleteAddress(id) {
      this.$swal
        .fire({
          title: "Estas seguro de eliminar?",
          text: "Para confirmar los cambios debes enviar los cambios.",
          icon: "warning",
          showCancelButton: true,
          confirmButton: "#3085d",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Si, eliminar!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            let index = this.form.addresses.findIndex((item) => item.id == id);
            if (index >= 0) {
              this.form.addresses.splice(index, 1);
              this.$toast.success("Dirección eliminada");
            }
          }
        });
    },
  },
};
</script>

<style scoped>
.label {
  font-weight: 700;
}
.invalid-field-message {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}
</style>
