import { render, staticRenderFns } from "./ResultItemValCredit.vue?vue&type=template&id=445c4704&scoped=true&"
import script from "./ResultItemValCredit.vue?vue&type=script&lang=js&"
export * from "./ResultItemValCredit.vue?vue&type=script&lang=js&"
import style0 from "./ResultItemValCredit.vue?vue&type=style&index=0&id=445c4704&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "445c4704",
  null
  
)

export default component.exports