<template>
    <v-autocomplete
      v-model="destiny"
      :items="customerDestinyList"
      :item-text="getItemTextDestinations"
      return-object
      dense
      rounded
      label="Destino de Compra"
      style="background-color: #4a74ff !important; color: #fff !important"
    >
    </v-autocomplete>
  </template>
  <script>
  import axios from "@/plugins/axios";
  import { mapGetters } from "vuex";
  export default {
    name: "CustomerDestiny",
    computed: {
      ...mapGetters({
        user: "auth/user",
      }),
    },
    props: {
      warnDestiny: {
        type: Function,
        default: null,
      },
    },
    data: () => ({
      customerDestinyList: [],
      destiny: "",
    }),
    async mounted() {
      let self = this;
      self.getDestinyCustomer();
    },
    methods: {
      getDestinyCustomer() {
        axios
          .post("destinyCustomer", {
            customer: this.user.custumer_code,
            VKORG: this.user.VKORG,
            VTWEG: this.user.VTWEG,
            VKBUR: this.user.VKBUR,
          })
          .then((response) => {
            this.customerDestinyList = response.data;
            this.destiny = this.customerDestinyList;
            //console.log(this.destiny);
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => (this.dialogLoading = false));
      },
      getItemTextDestinations(item) {
        return `${item.KUNNR} ${item.STRAS} ${item.ORT01}  ${item.PSTLZ} `;
      },
    },
    watch: {
      destiny: function (val) {
        this.warnDestiny(val);
      },
    },
  };
  </script>
  <style>
  .v-select__selection,
  .v-select__selection--comma,
  .v-select.v-text-field input {
    color: white !important;
  }
  </style>
  