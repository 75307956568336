<template>
  <v-menu bottom left>
    <template v-slot:activator="{ on, attrs }">
      <v-btn dark icon v-bind="attrs" v-on="on">
        <v-badge :content="messages" :value="messages" color="red" overlap>
          <v-icon>mdi-bell</v-icon>
        </v-badge>
      </v-btn>
    </template>

    <v-list>
      <v-list-item v-if="notifications.length == 0">
        <v-list-item-title>No tienes nuevas notificaciones</v-list-item-title>
      </v-list-item>
      <v-list-item v-for="(item, i) in notifications" :key="i">
        <v-list-item-avatar>
          <v-icon class="grey lighten-1" dark v-if="item.icon"> {{ item.icon }} </v-icon>
          <v-icon class="" color="grey lighten-1" dark v-else> mdi-bell </v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>{{ item.data.title }}</v-list-item-title>

          <v-list-item-subtitle>{{ item.data.subtitle }}</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
          <v-btn-toggle
            v-model="toggle_multiple"
            dense
            background-color="primary"
            dark
            multiple
            v-if="item.data.action"
          >
            <v-btn color="success" @click="emitOk(item.data.action, item)">
              Aceptar
            </v-btn>

            <v-btn @click="emitCancel(item.data.action, item)">
              Cancelar
            </v-btn>
          </v-btn-toggle>
          <v-btn color="primary" small elevation="0" v-else @click="goTo(item)">
            <v-icon>mdi-eye</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { eventBus } from '@/main';
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Notifications",

  data: () => ({
    toggle_multiple: null
  }),

  computed: {
    ...mapGetters({
      notifications: "auth/notifications",
    }),
    messages() {
      return this.notifications.length;
    },
  },

  mounted() {
    // this.loadNotifications();
  },

  methods: {
    ...mapActions({
      loadNotifications: "auth/loadNotifications",
      markAsRead: "auth/markAsRead",
    }),
    emitOk(action, data) {
      console.log('ACTION OK:', data);
      eventBus.$emit('notifications:' + action, data);
    },
    emitCancel(action, data) {
      console.log('ACTION CANCEL:', data);
      eventBus.$emit('notifications:' + action + '_cancel', data);
    },
    goTo(notification) {
      this.markAsRead(notification.id);
      this.$router.push(notification.data.url);
    }
  },
};
</script>

<style lang="scss" scoped></style>
