<template>
  <v-dialog
    v-model="showModal"
    :persistent="!noPersistent"
    transition="dialog-bottom-transition"
    width="700px"
  >
    <v-sheet width="100%" class="pb-4">
      <v-toolbar :dark="true">
        <v-toolbar-title>Cambiar contraseña</v-toolbar-title>
        <v-spacer />
        <v-btn @click="showModal = false" icon>
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </v-toolbar>

      <div class="mx-4 mt-4">
        <v-card>
          <v-card-title class="headline"> Gestionar Contraseña </v-card-title>
          <v-card-text class="text-left">
            <v-alert
              v-if="hasError"
              text
              outlined
              color="deep-orange"
              class="text-caption"
            >
              {{ message }}
            </v-alert>

            <v-alert
              v-if="messages.length > 0"
              text
              outlined
              color="deep-orange"
              class="text-caption"
            >
              <div v-for="(m, index) in messages" :key="index">{{ m }}</div>
            </v-alert>

            <v-form :ref="formRef" v-model="validForm">
              <password-form v-model="passwordForm" :ref="inputsRef" />
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="success darken-1"
              @click="changePassword"
              :loading="updatingPassword"
            >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-sheet>
  </v-dialog>
</template>

<script>
import PasswordForm from '@/components/forms/PasswordForm';
import passwordChange from '@/mixins/passwordChange';

export default {
  name: 'UpdatePasswordModal',

  components: {
    PasswordForm,
  },

  mixins: [passwordChange],

  props: {
    value: {
      type: Boolean,
      default: true,
    },

    noPersistent: Boolean,
  },

  methods: {
    changePassword() {
      const userName = localStorage.getItem('username');
      this.updatePassword(userName, () => (this.showModal = false));
    },
  },

  computed: {
    showModal: {
      set(newValue) {
        if (!newValue) this.resetForm();

        this.$emit('input', newValue);
      },

      get() {
        return this.value;
      },
    },
  },
};
</script>
