<template>
    <div id="contenedor" class="page-edo-pedido">
      
      <v-container fluid class="white lighten-5" style="padding-left: 3%;padding-right: 3%;">
          
        <div class="d-flex align-items-center">
            <v-card class="mx-auto rounded-lg elevation-4 ma-3 w-100" outlined :style="'border: 4px solid black; background-color:transparent;' ">
                <v-list-item-content>
                    <div class="text-general-module mb-0">
                      <v-icon color="black">mdi-card-bulleted</v-icon>
                      <span>&nbsp;ESTADO DE PEDIDOS</span>
                      <span v-if="opt == 'byGroup'">&nbsp;DEL GRUPO</span>
                      <span v-else>&nbsp;DEL CLIENTE</span>
                    </div>                    
                </v-list-item-content>               
            </v-card>            
        </div>     

        <ButtonsrGroupCustomer :opt="opt"></ButtonsrGroupCustomer>          
  
        <div v-if="!loadingClient" class="mt-3">
          
            <div class="row">
              <div class="col">
                <div class="h-title py-2">
                    <span><b>Estado de pedidos {{ dataClient?.CTEGPO > 0?'de grupos':'' }}</b></span>
                </div>
              </div>
            </div>              
            
            <div class="flex-bloq renglonaje-select-small">
              <div class="cont-shadow bloq-height">
                <div class="row">
                  <div class="col-7 col-md-12">
                    <div class="row">
                      <div class="col-12 col-md-3 py-1">
                        <div v-if="dataClient?.CTEGPO > 0" class="d-flex align-items-center renglonaje-select-small">                           
                            <label class="mr-3 filter-text">Cliente:</label>
                            <v-select
                              :items="clientsSelect"  
                                item-text="label"
                                item-value="value"
                                v-model="clientCurrent"
                                hide-details                                 
                                small
                                dense
                                solo
                                placeholder="Mostrar Todos"
                                @change="filterClient()"                                        
                            ></v-select>
                        </div>
                      </div>
                      <div class="col-12 col-md-3 py-1">
                        <div class="d-flex align-items-center">                           
                            <label class="mr-3 filter-text">Pedido:</label>
                            <v-select
                              :items="pedidosSelect"  
                                item-text="label"
                                item-value="value"
                                v-model="pedidoCurrent"
                                hide-details                                 
                                small
                                dense
                                solo
                                placeholder="Mostrar Todos"
                                @change="filterClient()"                                        
                            ></v-select>
                        </div>
                      </div>
                      <div class="col-12 col-md-3 py-1">
                        <div class="d-flex align-items-center">                           
                            <label class="mr-3 filter-text">Orden:</label>
                            <v-select
                              :items="ordenSelect"  
                                item-text="label"
                                item-value="value"
                                v-model="ordenCurrent"
                                hide-details                                 
                                small
                                dense
                                solo
                                placeholder="Mostrar Todos"
                                @change="filterClient()"                                        
                            ></v-select>
                        </div>
                      </div>
                      <div class="col-12 col-md-3 py-1">
                        <div class="d-flex align-items-center">                           
                            <label class="mr-3 filter-text">Estado:</label>
                            <v-select
                                :items="statusSelect"  
                                  item-text="label"
                                  item-value="value"
                                  v-model="statuCurrent"
                                  hide-details                                 
                                  small
                                  dense
                                  solo
                                  placeholder="Mostrar Todos"
                                  @change="filterClient()"                                           
                              ></v-select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-5 col-md-12 d-flex justify-content-center align-items-center">
                    <div>
                      <v-btn @click="reload()" class="btn-danger btn-custom btn-filtro" rounded>Actualizar</v-btn>
                    </div>
                  </div>
                </div>
              </div>
              
              <div class="cont-shadow bloq-height text-left">
                <div class="row">
                  <div class="col-4 col-lg-6">
                      <div>Mostrar N° días en:</div>
                      <div class="row mt-2">
                        <v-radio-group v-model="optionDays" >
                          <div class="d-flex align-items-center">
                            <div class="mr-4">
                              <v-radio                                                 
                                  label="Días Hábiles"
                                  color="primary"
                                  :value="0"
                                  hide-details                                 
                                ></v-radio>
                            </div>                            
                            <div>
                              <v-radio                                                          
                                label="Días Naturales"
                                color="primary"
                                :value="1"
                                hide-details
                              ></v-radio> 
                            </div>                            
                          </div>
                        </v-radio-group>
                      </div>  
                    </div>
                    <div class="col-8 col-lg-6">
                        <div class="mb-2">Pedidos actualizados al {{ currentDate }} {{clock}}</div>
                        
                            <!-- <div class="col-4">
                              <v-btn @click="reload()" class="btn-danger btn-custom" block rounded="xl">Actualizar</v-btn>
                            </div> -->
                            <div class="d-flex align-items-center mt-3">
                              
                                <div class="filter-text mr-3">Periodo:</div>
                                  <v-select
                                    :items="periods"  
                                      item-text="label"
                                      item-value="value"
                                      v-model="period"
                                      hide-details                                 
                                      small
                                      dense
                                      solo
                                      placeholder="Mostrar Todos"
                                      @change="filterClient()"   
                                      style="max-width:300px"                                  
                                  ></v-select>
                                                         
                            </div>
                       
                    </div>
                </div>                
              </div>
            </div>
            
            

            <div id="div-a-imprimir-material">
              <v-data-table
                v-if="clients.length > 0"
                  :headers="dataClient?.CTEGPO>0 ? headers : headers.slice(1,12)"
                  :items="ordenClients"
                  :footer-props="footerProps"
                  :options="dataTableOptions"  
                  :sort-by="sortBy"
                  :sort-desc="sortDesc"    
                  :mobile-breakpoint="null"   
                  :loading="isLoading"        
                  class="elevation-2 table-renglonaje"               
              >

              <template v-slot:[`item.KUNNR`]="{ item }">
                <a href="#" style="color: #000">{{ formatCustomer(item.KUNNR) }}</a>
              </template>
                
              <template v-slot:[`item.PEDIDO.PEDIDO.VBELN`]="{ item }">
                <div class="text-left">
                  <a class="link_table" href="#" @click.prevent="openModal(item.MATNR)">{{ item.PEDIDO.PEDIDO.VBELN }}</a>
                </div>
                <div class="d-flex align-items-center">
                  <div class="mr-4 d-flex align-items-center">
                    <b>Peso:</b> {{ item.PEDIDO.PEDIDO.PNETO }}
                  </div>
                  <div class="d-flex align-items-center">
                    <b>Vol:</b> {{ item.PEDIDO.PEDIDO.VOLUM }}
                  </div>
                </div>
              </template>

                <template v-slot:[`item.MCS`]="{ item }">
                    <div class="d-flex justify-content-center">
                        <img v-if="item.MCS != ''" :src="`/img/renglonaje/penalizaciones/${item.MCS}.svg`" style="width:45px;" alt="">
                        <span v-if="item.listZks != ''" class="ml-2">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">                                    
                                    <img v-bind="attrs" v-on="on" src="/img/renglonaje/penalizaciones/ZKRG.svg" style="width:32px;" alt="">                                    
                                </template>
                                <span v-for="(zk, index) in item.listZks" :key="index" class="tooltip__zk">
                                    {{zk}}
                                    <span v-if="index < item.listZks.length - 1">,</span>
                                </span>
                            </v-tooltip>
                        </span>
                    </div>                        
                </template>

                <template v-slot:[`item.NETWR1`]="{ item }">                        
                  ${{ item.NETWR1 | formatCurrency}}                       
                </template>

                <template v-slot:[`item.STATUS`]="{ item }">
                  <span :class="getStatusClass(item.STATUS)">{{ item.STATUS }}</span>                  
                </template>

                <!--Días captura y factura-->
                <template v-slot:[`item.BACKORDER.BACKORDER.MBACK`]="{ item }">
                  <span v-if="item.FACUS">{{ calcularDiasNoHabiles(item.AUDAT, item.FKDAT_VF) }}</span>
                  <span v-else>NA</span>
                </template>

                <!--Días factura y entrega-->
                <template v-slot:[`item.BACKORDER.BACKORDER.TBACK`]="{ item }">                        
                  <span v-if="item.FACUS">{{ calcularDiasNoHabiles(item.FKDAT_VF, item.FACUS) }}</span>
                  <span v-else>NA</span>            
                </template>
                
                <!--Días totales-->
                <template v-slot:[`item.TDIAS`]="{ item }">                        
                  <span v-if="item.FACUS">{{ calcularDiasNoHabiles(item.AUDAT, item.FACUS) }}</span>
                  <span v-else>NA</span>
                </template>TDIAS
              </v-data-table>
              <template v-else>
                <v-alert type="info">
                  No hay pedidos
                </v-alert>
              </template>              
            </div>              
          
        </div>

        <v-row v-if="loadingClient">
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-progress-linear
              indeterminate
              color="cyan"
            ></v-progress-linear><br>
            Cargando... Por favor espere
          </v-col>
        </v-row>
        <LegacyFooter></LegacyFooter>
      </v-container>
    </div>
  </template>
    
    <script>
    import axios from "@/plugins/axios";
    import { mapGetters, mapActions } from "vuex";
    import moment from "moment";    
    import ButtonsrGroupCustomer from '@/components/ButtonsrGroupCustomer'
    import LegacyFooter from "@/views/seller/components/LegacyFooter.vue"

    export default {
      name: "OrderStatus",
      components: {ButtonsrGroupCustomer,LegacyFooter},
      props: ['opt'],
      data() {
        return {
          isLoading: true,
          last_page: 1,
          itemsPerPage: 10, // Número de ítems por página
          currentPage: 1, // Página actual
          currentDate: null,
          clock: null,
          clientCurrent:'',
          clientsSelect:[],
          clientsAll:[],
          clients:[],
          periods:[{
            label: 'últimos 30 días',
            value: '1'
          }],
          pedidoCurrent:'',
          ordenCurrent: '',
          pedidosAll:[],
          ordenAll: [],
          pedidosSelect:[],
          ordenSelect: [],
          statuCurrent:'',
          statusSelect:[
            {
              label: 'CAPTURADO',
              value: 'CAPTURADO'
            },
            {
              label: 'PICKING',
              value: 'PICKING'
            },
            {
              label: 'FACTURADO',
              value: 'FACTURADO'
            },
            {
              label: 'EN RUTA',
              value: 'EN RUTA'
            },
            {
              label: 'ENTREGADO',
              value: 'ENTREGADO'
            },
            {
              label: 'BLOQUEADO',
              value: 'BLOQUEADO'
            }
          ],
          statusAll:[],
            dayHabil:true,
            dayNatural:true,
          period: '',
          loadingClient: true,
          loadingDataClient: true,
          dateCurrent: null,
          isMobile: false,
          dataParticipationDivision:{},
          months: [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre",
          ],
          isCheckedDiaNatural:false,
          isCheckedDiaHabil:true,
          monthCurrent: '',
          page:0,
          headers: [
          { text: 'Cliente N°', value: 'KUNNR', align:"center" },
          { text: 'N° Pedido', value: 'PEDIDO.PEDIDO.VBELN', align:"center" },
          { text: 'Orden de compra', value: 'BSTNK', align:"center" },
          // { text: 'Referencia', value: 'REFER', align:"center" },
          { text: 'Partidas', value: 'NUMPART', align:"center" },
          { text: 'Monto netos/IVA del pedido', value: 'NETWR1', align:"center" },
          { text: 'Estado', value: 'STATUS', align:"center" },
          { text: 'N° de factura', value: 'VBELN_VF', align:"center" },
          { text: 'Fecha de Captura', value: 'AUDAT', align:"center" },                                  
          { text: 'N° días', value: 'BACKORDER.BACKORDER.MBACK', align:"center" },
          { text: 'Fecha de Factura', value: 'FKDAT_VF', align:"center" },
          { text: 'N° días', value: 'BACKORDER.BACKORDER.TBACK', align:"center" },
          { text: 'Fecha de Entrega', value: 'FACUS', align:"center" },
          { text: 'N° de días Totales', value: 'TDIAS', align:"center" },
          //{ text: 'N° de días Totales', value: 'BACKORDER', align:"center" },
            ], 
            footerProps: {
                itemsPerPageOptions: [10, 14, 24, 34, { text: 'Todas', value: -1 }],
                itemsPerPageText: 'Mostrar en grupo de:',
                showCurrentPage: false,
                pageText: 'Desplegado {0} / {1} de {2}'           
            }, 
            dataTableOptions: {
                itemsPerPage: 24,
                pagination: {
                    enabled: true,
                    options: {
                        numeric: true,
                    },
                },
            },
            sortBy: '',
            sortDesc: false, 
            statusClasses: {
              'CAPTURADO': 'color-1',
              'PICKING': 'color-2',
              'FACTURADO': 'color-4',
              'EN RUTA': 'color-7',
              'ENTREGADO': 'color-6',
              'BLOQUEADO': 'color-3',
              'CANCELADO': 'color-5',
            },
            optionDays: 1,
            breadcrumbsItems:[
            {
                text: 'IEL',
                disabled: false,
                href: '/',
                } 
            ],
            customerInfo:[],
            dataClient:[],
        };
      }, 
      async mounted() {
        
        let current_date = new Date();
      
        this.dateCurrent =
          current_date.getDate() +
          " de " +
          this.months[current_date.getMonth()] +
          " del " +
          current_date.getFullYear();
  
        this.monthCurrent = this.months[current_date.getMonth()].slice(0,3);

        this.currentDate = moment().format("D [de] MMMM [del] YYYY");
        setInterval(() => this.clock = moment().format("h:mm a"), 1000)
  
        window.addEventListener('resize', this.checkMobile);
      
        if (this.$getLocalStorageCustomer() != null) {
            const currentCustomerInfo= JSON.parse(localStorage.getItem('currentCustomerInfo')) || [];
                if (currentCustomerInfo.length<1) {
                this.$router.push({name:'Home'})
                }   
                this.customerInfo =  currentCustomerInfo;  
                const customer_code =  parseInt(this.customerInfo?.KUNNR,10) 
           
                this.getDataClient(customer_code).then( () => {
                  this._getClients(customer_code);  
                });   
             
             }else{
                const customer_code = this.user.custumer_code;
                this.getDataClient(customer_code).then( () => {
                  this._getClients(customer_code);   
                });   

            }

        this.fillLast12Months();
      },    
      computed: {
        ...mapGetters({
          authenticated: "auth/authenticated",
          user: "auth/user", 
        }),  
        ordenClients(){
          const clientsClon = [...this.clients];
          return clientsClon.sort((a, b) => new Date(a.AUDAT) - new Date(b.AUDAT));
        }
      },
      beforeDestroy() {
        window.removeEventListener('resize', this.checkMobile);
      },
      methods: {
        async getDataClient(customer_code) {        
            try{
                let response = await axios.post("grupo-cliente/data-client", {
                    client: customer_code,
                    VKORG: this.user.VKORG,
                    VTWEG: this.user.VTWEG,
                    VKBUR: this.user.VKBUR,
                    P_OPCGPO: this.opt != 'byGroup' ? 0 : ''
                }) 
                this.dataClient = response.data.dataDetailClient 
            }catch(error){
                console.log(error)
            }
        },
        ...mapActions({
            sendToPDF: 'printer/sendToPDF'
        }),
        createBreadcrumb(){   
            let user = [{
                text:this.user.VTWEG =='DT'?'Minoristas':'Privados',
                disabled: false,
                href: '/',
            },
            {
            text: this.directorFullName,
            disabled: false,
            href: '/',
            },
            {
            text: this.sellerFullName,
            disabled: false,
            href: '/resultados',
            }, 
            {
            text: 'Carátula',
            disabled: false  ,
            href: this.$router.resolve({ name: 'CustomerCoverDetails' }).href,
            }, 
            {
            text: 'Estado de pedidos / '+this.customerInfo?.NAME1 +this.currentPeriod,
            disabled: true  ,
            href: "",
            },
            ]  
            this.breadcrumbsItems = [...this.breadcrumbsItems, ...user]
            console.log(this.customerInfo)
            },
        _countWeekends(startDate, endDate) {
          let count = 0;
          const currentDay = new Date(startDate);

          while (currentDay <= endDate) {
            const dayOfWeek = currentDay.getDay();
            if (dayOfWeek === 0 || dayOfWeek === 6) {
              count++;
            }
            currentDay.setDate(currentDay.getDate() + 1);
          }

          return count;
        },
        _parseDate(dateString) {
          const parts = dateString.split('/');
          return new Date(parts[2], parts[1] - 1, parts[0]);
        },
        // _daysDifference(startDate, endDate) {
        //   if(startDate != null && endDate != null) {       
        //     startDate = moment(startDate, 'DD/MM/YYYY');
        //     endDate = moment(endDate, 'DD/MM/YYYY');
        //     const daysDifference = endDate.diff(startDate, 'days');

        //     if(this.optionDays == 0){
        //       let diasNoHabiles = this.calcularDiasNoHabiles(startDate, endDate)
        //       return daysDifference - diasNoHabiles
        //     }

        //     return daysDifference;
        //   }
        //   return 0
        // },
        _obtenerMes(fecha) {
          const partes = fecha.split('/');
          return partes[1]+'/'+partes[2]; // partes[1] representa el mes en formato "MM"
        },
        _convertFecha(fecha) {
          const partes = fecha.split('/');
            return new Date(partes[2], partes[1] - 1, partes[0]); // mes - 1 porque los meses en JavaScript van de 0 a 11
        },
        // Función para determinar si un día es hábil (lunes a viernes)
        _esDiaHabil(fecha) {
          const diaSemana = fecha.getDay();
          return diaSemana >= 1 && diaSemana <= 5; // 1 es lunes, 5 es viernes
        },
        checkMobile() {
          this.isMobile = window.innerWidth <= 600;
        },
        reload(){
          this.clients = this.clientsAll;
          this.isCheckedDiaNatural = false;
          this.isCheckedDiaHabil = false;
          this.clientCurrent = ''
          this.pedidoCurrent = ''
          this.statuCurrent = ''
          this.ordenCurrent = ''
          this.optionDays = 1
          this.period = ''


          this.clients.map((c =>{
              if (!this.clientsSelect.some(client => client.value === c.KUNNR)) {
                console.info( "select => ", c.KUNNR );
                this.clientsSelect.push({
                  value: c.KUNNR,
                  label: this.formatCustomer(c.KUNNR)
                });
              }
              
              if(c.PEDIDO.PEDIDO.VBELN){
                if (!this.pedidosAll.some(f => f.value === c.PEDIDO.PEDIDO.VBELN)) {
                  
                  this.pedidosAll.push({
                    value: c.PEDIDO.PEDIDO.VBELN,
                    label: c.PEDIDO.PEDIDO.VBELN
                  });
                }
              }

              // if(c.STATUS){

              //   if (!this.statusAll.some(s => s.value === c.STATUS)) {
              //     this.statusSelect.push({
              //       value: c.STATUS,
              //       label: c.STATUS
              //     });
              //   }
              // }
            }));

        },
        filterClient(){
          let fechaActual = moment();
          this.clients = this.clientsAll.filter(cliente => {
            const fechaCondition =
              (this.period == 1 && moment(cliente.AUDAT).isAfter(fechaActual.clone().subtract(1, 'month'))) ||
              (this.period != 1 && moment(cliente.AUDAT).format('YYYY/MM') == this.period) ||
              this.period == 0;

            return (
              fechaCondition &&
              (this.clientCurrent === '' || cliente.KUNNR.includes(this.clientCurrent)) &&
              (this.statuCurrent === '' || cliente.STATUS === this.statuCurrent) &&
              (this.pedidoCurrent === '' || cliente.PEDIDO.PEDIDO.VBELN === this.pedidoCurrent) &&
              (this.ordenCurrent === '' || cliente.BSTNK === this.ordenCurrent)
            );
          });
        },
        
      _getClients(customer_code) {
      var  client = customer_code;
      var  url = "order-status/for-client";
        //Un solo cliente 
        /*
        if( this.user.custumer_code ){
          client = this.user.custumer_code;
          url = "order-status/for-client";
        }
        else{
          //en caso de traer la lista de clientes
          client = this.user.employee_number;
          url = "order-status/general";
          //2543897;
          //02544036
        } */
        //client = "254389978444";
        
        axios
          .post(url, {
            client: client,
            P_OPCGPO: this.opt != 'byGroup' ? 0 : ''
          })
          .then((response) => {
            this.clients = response.data;

            this.clients.forEach(cliente => {
              if(cliente.AUDAT != '' ){
                cliente.AUDAT = moment(cliente.AUDAT, 'DD/MM/YYYY').format('YYYY/MM/DD');
              }

              if(cliente.FACUS != '' ){
                cliente.FACUS = moment(cliente.FACUS, 'DD/MM/YYYY').format('YYYY/MM/DD');
              }

              if(cliente.FKDAT_VF != '' ){
                cliente.FKDAT_VF = moment(cliente.FKDAT_VF, 'DD/MM/YYYY').format('YYYY/MM/DD');
              }
            });

            this.clients.map((c =>{
              if (!this.clientsSelect.some(client => client.value === c.KUNNR)) {
                
                this.clientsSelect.push({
                  value: c.KUNNR,
                  label: this.formatCustomer(c.KUNNR)
                });
              }
              
              if(c.PEDIDO.PEDIDO.VBELN){
                if (!this.pedidosAll.some(f => f.value === c.PEDIDO.PEDIDO.VBELN)) {
                  
                  this.pedidosAll.push({
                    value: c.PEDIDO.PEDIDO.VBELN,
                    label: c.PEDIDO.PEDIDO.VBELN
                  });
                }
              }

              if(c.STATUS){

                if (!this.statusAll.some(s => s.value === c.STATUS)) {
                  this.statusAll.push({
                    value: c.STATUS,
                    label: c.STATUS
                  });
                }
              }

              if(c.BSTNK){
                if (!this.ordenAll.some(s => s.value === c.BSTNK)) {
                  this.ordenAll.push({
                    value: c.BSTNK,
                    label: c.BSTNK
                  });
                }
              }
            }));

            // this.statusSelect = this.statusAll;
            this.clientsAll = this.clients;
            this.pedidosSelect = this.pedidosAll;
            this.ordenSelect = this.ordenAll;
            this.loadingClient = false;
            this.isLoading = false;
          })
          .catch((error) => {
            console.warn(error);
          })
          .finally(() => (this.loadingClient = false));
      },
      changeDiaNatural() {

        this.isCheckedDiaHabil = !this.isCheckedDiaNatural;

        this.clients = this.clientsAll;
      },
      changeDiaHabil(){
        this.isCheckedDiaNatural = !this.isCheckedDiaHabil;

        this.clients = this.clientsAll;
      },
      formatPrice(value) {
        if(value){
          if( value < 0 ){
            var cred = value.split( '-' );
            return '-' + cred[1].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          }
          return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        return "0,00";
      },
      formatPor(value) {
        var valueXtra = value.split('.');
        return valueXtra[0] + '.' + valueXtra[1].slice(0,2);
      },
      
        getStatusClass(status) {
          return this.statusClasses[status] || ''; 
        }, 
        calcularDiasNoHabiles(startDate, endDate) {
          startDate = moment(startDate, 'YYYY/MM/DD');
          endDate = moment(endDate, 'YYYY/MM/DD');

          const diasNoHabiles = [5, 6]; // Sábado y Domingo
          let diasHabiles = 0;
          let diasTotales = 0;
          startDate.add(1, 'day');

          while (startDate.isSameOrBefore(endDate, 'day')) {
            if (this.optionDays === 0) {
              if (!diasNoHabiles.includes(startDate.day())) {
                diasHabiles++;
              }
            } else {
              diasTotales++;
            }
            // Avanzar al siguiente día
            startDate.add(1, 'day');
          }

          return this.optionDays === 0 ? diasHabiles : diasTotales;
        },
        formatCustomer(data){
            if (data.length >= 6) {
                // Utilizar el método slice para obtener los últimos 6 dígitos
                var ultimosSeisDigitos = data.slice(-6);
                return ultimosSeisDigitos;
            } else {
                // Si la cadena tiene menos de 6 caracteres, devolver la cadena original
                return data;
            }
        },
        fillLast12Months() {
          let currentDate = moment();
          
          for (let i = 0; i < 12; i++) {
            const label = currentDate.format('MMMM / YYYY'); // Formato del mes y año
            const value = currentDate.format('YYYY/MM'); // Formato del año y mes
            this.periods.push({ label, value });
            
            // Restar un mes para el próximo ciclo
            currentDate = currentDate.subtract(1, 'month');
          }
        }       
        
      },

      watch: {
        clients(){
          this.sendToPDF({active: true})
        }
        /*period(newValue, oldValue) {
          
          if(newValue != oldValue){
            
          }
        }*/
      }, 
  };
    </script>

    <style>
    .page-edo-pedido thead tr th{
      background: #F5F5F5 0% 0% no-repeat padding-box ;
        box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029 ;
        opacity: 1;
        padding: 8px 0 !important; 
    }
    .page-edo-pedido tr:nth-child(even) {
        background-color: #f5f5f5;
    }
    </style>
    
  <style scoped>
    .h-title{
        background: #F5F5F5 0% 0% no-repeat padding-box;
        box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
        opacity: 1;
        border-bottom: 2px solid #707070 !important;
        font-weight: 500;
        font-family: Roboto;
        font-size: 14px;
    }
    .cont-shadow{
        background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
        opacity: 1;
    }
    .flex-bloq{
      display: flex;
      flex-wrap: wrap;      
    }
    .bloq-height{
      flex: 1;
      padding: 15px;
    }
    @media (max-width: 1350px) {
      .flex-bloq{
        display: inherit
      }
    }
    @media (max-width: 815px){
      .btn-filtro{
        width: auto !important;
      }
    }
    
    .capturado{
      color: yellow;
    }
    .page-edo-pedido .v-input--selection-controls{
      margin-top: 0px;
      padding-top: 0px;
    }
    .page-edo-pedido .row+.row {
      margin-top: 0px;
    }
    @media (max-width: 900px) {
      .div-block-btn-filter{
        display: none;
      }
    }
    
    .btn-danger{
        background-color:red !important;
        color:white !important;
    }

    .filter-text{
      font-size: px;
    }
  
  /*Lo nuevo*/
  .btn-custom{
    background: transparent linear-gradient(180deg, #A82222 0%, #FF0000 100%) 0% 0% no-repeat padding-box;
        opacity: 1;
        color: #FFF !important;
        text-transform: inherit !important;
        font-size: 12px !important;
        
       
  }

  .btn-filtro{
    width: 300px;
  }
  .table-heading {
    font-weight: bold;
    font-size: 0.75rem;
    background: rgb(245, 245, 245);
    color: #000;
    border-width: 1px 1px 3px 1px;
    border-color: rgb(228, 231, 234);
    border-bottom-color: rgb(112, 112, 112);
  }
  
  
  .table-heading tr {
    color: #000;
  }
  
  td {
      padding: 10px;
    }
  .text-center{
    text-align:center;
  }
  .pagination-right {
  justify-content: flex-end;
}

  .color-1{
    color: #000;
  }
  .color-2{
    color: #ff9100;
  }
  .color-3{
    color: #5b08e8;
  }
  .color-4{
    color: #228839;
  }
  .color-5{
    color: #c82333;
  }
  .color-6{
    color: #0069d9;
  }
  .color-7{
    color: pink;
  }
  </style>
