<template>
    <div class="container-fluid">
      <CardTitle title="BENEFICIOS COMERCIALES" icon="card-plus-outline">
        <template v-slot:content>
          <v-card class="mx-auto elevation-5" outlined>
        <div class="pb-3">
          <v-simple-table dense  >
                <template v-slot:default>
                  <thead>
                    <tr class=" text-black custom-body-hs font-size-x" > 
                      <td class="box-shadow" v-for="(item,index) in headers_bono_ind" :key="index" :style="index<1?'width:42%':''">{{ item.text }}</td> 
                    </tr>
                  </thead>
                  <tbody>
                    <tr  v-for="(item,index) in bonus" :key="index" class=" no-border custom-body-hs font-weight-regular font-size-x">
                      <td class="text-left ">
                        <span class="pa-0 ml-md-10">{{ item.TITULO }}</span></td>   
                        <td :class="index!=0?'font-weight-regular':''">{{ item.PERIODO4.replaceAll(" ","&nbsp;") }}</td>  
                        <td :class="index!=0?'font-weight-regular':''">{{ item.PERIODO3.replaceAll(" ","&nbsp;") }}</td>   
                        <td :class="index!=0?'font-weight-regular':''">{{ item.PERIODO2.replaceAll(" ","&nbsp;") }}</td>   
                      <td >{{ item.PERIODO1 }}</td>   
                      
                    </tr> 
                  </tbody>
                </template>
              </v-simple-table>  
        </div>
      </v-card>
        </template> 
      </CardTitle>
    

    </div>
  </template>
  
  <script>
  import axios from "@/plugins/axios";
  import { mapGetters } from "vuex";
  import CardTitle from '@/components/CardTitle.vue'
  import { mapActions } from "vuex";
  export default {
    components: {
      CardTitle
    },
    props: {
  generalInfoCustomer:{},
  },
  watch:{
    generalInfoCustomer(){
      let self = this;
      self.getBonus();
    }
  },
    data() {
      return {
        bonus: [],
        headers_bono_ind: [],
      };
    },
    computed: {
      ...mapGetters({
        user: "auth/user",
      }),
    }, 
    methods: {
      ...mapActions({
        desactiveLoader: 'loader/desactiveLoader'
      }),
      getBonus() {
        axios
          .post("totalBenefits", {
            client_code: this.generalInfoCustomer.KUNNR,
          },{
            noCancelRequest:true,
            cachable:true,
          })
          .then((response) => {
            var headerBonus = response.data.header;
            this.bonus = response.data.data;
            this.headers_bono_ind = [
          {
            text: "Concepto",
            align: "start",
            value: "TITULO",
            class: "border-bottom: none;",
            sortable: false,
          },
          { text: headerBonus[0].PERIODO4, sortable: false, value: "PERIODO1" },
          { text: headerBonus[0].PERIODO3, sortable: false, value: "PERIODO2" },
          { text: headerBonus[0].PERIODO2, sortable: false, value: "PERIODO3" },
          { text: headerBonus[0].PERIODO1, sortable: false, value: "PERIODO4" },
        ]
          this.desactiveLoader();
          })
          .catch((e) => {
            this.desactiveLoader();
            console.log(e);
          });
      },
      format(value) {
        const result = Number(value).toFixed(2);
        return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
  };
  </script>
  
  <style src="@/assets/css/customerHome.css" scoped></style>
  