<template> 
<div>
  <div class="d-flex flex-wrap" style="background-color: #000; color:#fff">
        <div class="text-left ml-5 mt-1 mr-5 " >Clientes con venta</div> 
            <v-btn v-if="!loading" icon @click="closeModal()" class="ml-auto">
                <v-icon size="20" color="#fff" >mdi-close-circle</v-icon>
            </v-btn> 
      </div> 
          <v-row class="ma-1 "> 
            <v-col cols="12" v-if="!loading">
              <v-simple-table dense class="my-table" v-if="!threeMonths">
                <template v-slot:default>
                  <thead>
                    <tr class="custom-table-bordered">
                      <th colspan="3"   class="text-center font-weight-bold">
                        <span  class=" text-danger" style="font-size:1.4em;">
                        Dif:&nbsp;{{ number_format(data.DIFACT,false) }}
                        </span> 
                      </th> 
                      <th colspan="10"  class="text-center font-weight-bold">
                        <span  class="font-weight-bold " style="font-size:1.4em;color:rgb(0,105,217)">
                        Prom:&nbsp;{{ number_format(data.PROM12,false)}}
                        </span> 
                      </th> 
                    </tr>
                    <tr  class="custom-table-bordered text-center  bg-gray">
                      <th  :class="index==0 ? 'text-gold':''"  v-for="(item,index) in months.slice(0,13)" :key="index"><b>{{item.date}}</b></th> 
                    </tr>
                  </thead>
                  <tbody class="custom-table-bordered">               
                    <tr class=" text-center bg-gray">
                      <td :class="index==0 ? 'text-gold':''" v-for="(item,index) in data?.CTEVTA?.CTEVTA " :key="index">{{ number_format(item.TOTAL) }}</td> 
                    </tr>
                    
                  </tbody>
                </template>
              </v-simple-table>

              <v-simple-table v-else dense class="my-table">
                <template v-slot:default>
                  <thead>
                    <tr class="custom-table-bordered">
                      <th class="text-center font-weight-bold">
                        <span  class=" text-dark" style="font-size:1.4em;">
                        Mes actual
                        </span> 
                      </th> 
                      <th class="text-center font-weight-bold">
                        <span  class=" text-dark" style="font-size:1.4em;">
                        Promedio
                        </span> 
                      </th> 
                      <th colspan="3"    class="text-center ">
                        <span  class="font-weight-bold text-dark" style="font-size:1.4em;">
                        Meses 
                        </span> 
                      </th> 
                    </tr>
                    <tr  class="custom-table-bordered text-center  bg-gray">
                      <th  class="font-weight-bold text-dark">{{ months[0].date }}</th>
                      <th  class="font-weight-bold text-dark">Ultimos 3 <br>meses</th>
                      <th v-for="(item,index) in months.slice(1,4)" :key="index"><b>{{item.date}}</b></th> 
                    </tr>
                  </thead>
                  <tbody class="custom-table-bordered">               
                    <tr class=" text-center bg-gray">
                      <td>{{ number_format(data?.CTEVTA?.CTEVTA[0]?.TOTAL) }}</td> 
                      <td>{{ number_format(data?.AVG3,false) }}</td> 
                      <td v-for="(item,index) in data?.CTEVTA?.CTEVTA.slice(1,4) " :key="index">{{ number_format(item.TOTAL) }}</td>  
                    </tr>                    
                  </tbody>
                </template>
              </v-simple-table>

            </v-col>
            <template v-else>
                <v-progress-linear indeterminate color="teal" ></v-progress-linear>
                </template>
          </v-row> 
</div> 
</template>
<script>
    import axios from "@/plugins/axios";
    import MixinHelpers from '@/mixins/helpers';
export default ({
  props: ['manager','modal','managerData','months','period','threeMonths'],
      mixins: [MixinHelpers],
  data() {
        return {
            loading: false,
            data: [], 
        }
    }, 
    watch:{
      loading(value){
        this.$emit('loadingCustomersWithSales',value)
        },
        modal(value){ 
            if (value) {
                this.customersWithSales();
            } 
        }, 
      },
      mounted(){
      this.customersWithSales()
      },
  methods: {
    closeModal(){
      this.$emit('closeModal')
    }, 
    customersWithSales(){  
          this.loading=true;
          var month = this.period.month
        if (month < 10) { month = `0${month}`}
          const period = `${this.period.year}${month}`
          var params ={ 
            manager:this.managerData.PERNR, 
            period: period,
            channel: this.managerData.VTWEG
          } 
          axios.post('/manager/home/customers-with-sales',params).then((res) => { 
              this.data = res.data; 
              this.loading = false;
          })
          .catch((err) => { 
                console.log(err);
                this.loading = false;
              }) ;  
        },
        loadCustomersWithSales(){
          this.emit()
        }

      },  
    name:'CustomerSales',
});
</script >
<style scoped>
.my-table th,
.my-table td {
  border: 0px 4px 0px 0px solid #dad9d9;
  
}
.bg-gray {
  
  background-color: rgb(245,245,245);
}
.my-table {
  width: 100%;
}
.custom-table-bordered  {
  border-width: 2.5px;
  border-color: rgb(224, 228, 232);
}
.custom-table-bordered>tr>td {
  border-width: 2.5px;
  border-color: rgb(224, 228, 232);
}

.custom-table-bordered>th {
  border-width: 2.5px;
  border-color: rgb(224, 228, 232);
}


</style>