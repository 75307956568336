<template>
  <v-app id="inspire">
    <v-main class="background-grey lighten-5">
      <v-container fluid fill-height>
        <v-row align="center" justify="center">
          <v-col
            cols="9"
            md="3"
            class="red accent-3 mr-n0 mb-5 mb-sm-0 mb-md-0 mb-lg-0 mb-xl-0 mr-sm-n3 mr-md-n3 mr-lg-n3 mr-xl-n3 mt-2 mt-sm-n3 mt-md-n3 mt-lg-n3 mt-xl-n3 d-lg-flex justify-center elevation-10 order-1 order-sm-0 order-md-0 order-lg-0 order-xl-0"
            style="
              background-color: #fe0106 !important;
              height: 650px;
              border-radius: 19px;
            "
          >
            <v-carousel :show-arrows="false" hide-delimiter-background>
              <v-carousel-item>
                <v-card-text class="white--text mt-5">
                  <p></p>
                  <v-img
                    width="45"
                    src="img/logo_iusa_w.svg"
                    class="mt-n10"
                  ></v-img>
                  <div class="mt-15 mb-15">
                    <img src="img/propuesta-1-x2.svg" alt="" />
                  </div>
                  <p class="text-center mt-5 mb-12 ma-2 text-h5">
                    Bienvenid@ a <strong>IUSA EN LÍNEA</strong>
                  </p>
                </v-card-text>
              </v-carousel-item>
              <v-carousel-item>
                <v-card-text class="white--text mt-5">
                  <p></p>
                  <v-img
                    width="45"
                    src="img/logo_iusa_w.svg"
                    class="mt-n10"
                  ></v-img>
                  <img src="img/banner-login-3.svg" alt="" />
                  <p class="text-justify mt-n5 mb-12 ma-4"></p>
                  <p></p>
                  <p class="text-justify mt-n5 mb-12 ma-4">
                    Contamos con Promociones Exclusivas, Catálogos y Listas de
                    precios actualizadas y a la mano para que puedas descargar.
                  </p>
                </v-card-text>
              </v-carousel-item>
            </v-carousel>
          </v-col>
          <v-col col="12" md="4">
            <div
              class="alert alert-danger"
              role="alert"
              v-if="hasError"
              style="font-weight: 500"
            >
              <v-icon style="color: #000" class="mt-n1"> mdi-alert </v-icon>
              {{ msg }}.
            </div>

            <v-card
              class="elevation-10"
              style="border-radius: 19px 19px 19px 19px"
            >
              <v-row>
                <v-card-text class="mt-4">
                  <h5
                    class="text-center text--accent-3 mb-2"
                    style="font-weight: 500"
                  >
                    <img src="img/icon-person-badge.svg" />
                    Inicio de Sesión
                  </h5>
                  <v-col cols="12" align="left" justify="left">
                    <form @submit.prevent="submit">
                      <label class="mb-2" style="font-size: 14px"
                        >Usuario:</label
                      >
                      <v-text-field
                        class="mb-n3"
                        placeholder="Ingrese Usuario"
                        type="text"
                        solo
                        v-model="form.username"
                        autofocus
                        required
                      ></v-text-field>
                      <label class="mb-2" style="font-size: 14px"
                        >Contraseña:</label
                      >
                      <v-text-field
                        placeholder="Ingrese Contraseña"
                        solo
                        :type="showPassword ? 'text' : 'password'"
                        v-model="form.password"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="showPassword = !showPassword"
                        required
                      ></v-text-field>
                      <div class="text-center mt-n5 mb-8">
                        <vue-recaptcha
                          v-if="$env == 'production'"
                          :sitekey="$googleSitekey"
                          @verify="onVerify"
                          @expired="onExpired"
                          class="g-recaptcha"
                        ></vue-recaptcha>
                      </div>
                      <div class="text-center">
                        <v-btn
                          type="submit"
                          rounded
                          block
                          style="
                            background-color: #2fd410;
                            color: #fff;
                            text-transform: none;
                            font-weight: 600;
                            font-size: 16px;
                          "
                        >
                          {{ procesando ? "Validando..." : "Iniciar Sesión" }}
                        </v-btn>
                      </div>
                    </form>
                    <div class="text-center">
                      <v-card-subtitle
                        class="mt-n1 text-center"
                        style="color: #999"
                        ><router-link
                          :to="{ name: 'PasswordReset' }"
                          style="
                            color: rgb(153, 153, 153);
                            text-decoration: none;
                          "
                          >¿Has olvidado tu contraseña?</router-link
                        ></v-card-subtitle
                      >
                    </div>
                  </v-col>
                </v-card-text>
              </v-row>
              <div class="text-center mt-5" style="line-height: 10%">
                <p>Si aún necesitas ayuda, ponte en contacto con el</p>
                <h6>
                  Soporte IUSA en Línea
                  <div id="#ff0004"></div>
                </h6>
                <br />

                <h4>Contáctanos:</h4>

                <a
                  href="https://wa.me/5217121241222"
                  target="_blank"
                  style="text-decoration: none; color: #4caf50 !important"
                >
                  <v-btn class="mx-2" fab color="success" outlined small>
                    <v-icon>mdi-whatsapp</v-icon>
                  </v-btn>
                </a>
                <a
                  href="mailto:servicedesk@iusa.com.mx"
                  target="_blank"
                  style="text-decoration: none; color: #1976d2 !important"
                >
                  <v-btn class="mx-2" fab color="primary" outlined small>
                    <v-icon>mdi-email-outline</v-icon>
                  </v-btn>
                </a>
              </div>
              <v-card-actions> </v-card-actions>
            </v-card>
          </v-col>
          <v-skeleton-loader
            v-if="loading"
            type="list-item-three-line"
          ></v-skeleton-loader>
          <p v-else><b>Versión {{ version.version }}</b></p>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
import VueRecaptcha from "vue-recaptcha";

import { eventBus } from "@/main";
import axios from "@/plugins/axios";

export default {
  name: "SignIn",
  components: {
    VueRecaptcha,
  },
  data() {
    return {
      production: this.$production,
      showPassword:false,
      procesando: false,
      hasError: false,
      recaptcha: null,
      msg: null,
      form: {
        username: "",
        password: "",
      },
      version: "*.*.*",
      loading: true,
    };
  },
  mounted() {
    this.loadVersion();
  },
  methods: {
    ...mapActions({
      signIn: "auth/signIn",      
      desactiveLoader: 'loader/desactiveLoader'
    }),
    onVerify: function (response) {
      this.recaptcha = response;
    },
    onExpired: function () {
      this.recaptcha = null;
    },
    submit() {
      //Reseteo de LocalStorage al iniciar sesión            
      this.$resetLocalStorage()       
      
      if (this.$env == 'development' || (this.$env == 'production' && this.recaptcha != null)) {
        this.procesando = true;
        let data = { password: this.form.password, username: this.form.username };

        this.signIn(data)
          .then(() => {
            this.$router.replace({
              name: "Home",
            });            
            eventBus.$emit("subscribe");                
          })
          .catch((err) => {
            this.desactiveLoader()     
            if(err.response.status == 401) {
              if(err.response.data.id) {
                this.msg = 'El usuario esta desactivado';
              } else {
                this.msg = err.response.data.message;
              }
            }
            if(err.response.status == 404) {
              this.msg = 'No existe el usuario';
            }
            this.procesando = false;
            this.hasError = true;
            setTimeout(() => {
              this.hasError = false;
            }, 5000);
            this.form.password = null;
          });
      } 
      else {
        this.hasError = true;
        setTimeout(() => {
          this.hasError = false;
        }, 5000);
        this.msg = "El campo CAPTCHA es obligatorio";
      } 
    },
    isValidEmail(email) {
      const emailRegex = /^[a-zA-Z0-9_.+]+(?<!^[0-9]*)@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
      return emailRegex.test(email);
    },
    loadVersion() {
      this.loading = true;
      axios.get(`${process.env.VUE_APP_BASE_SERVER}/auth/version`).then(response => {
        if(response.data.version) {
          this.version = response.data.version
        }
      }).finally(() => {
        this.loading = false
      })
    },
  },
};
</script>

<style scoped>
.v-carousel__controls {
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  bottom: 0;
  display: flex;
  height: 50px;
  justify-content: center;
  list-style-type: none;
  position: absolute;
  width: 150%;
  z-index: 1;
}
.text-center {
  text-align: center;
}

.g-recaptcha {
  display: inline-block;
}
</style>
