<template>
  <div>
      <v-card  style="box-shadow: -1px 1px 7px 0px rgba(224,37,37,0.45);-webkit-box-shadow: -1px 1px 7px 0px rgba(224,37,37,0.45); -moz-box-shadow: -1px 1px 7px 0px rgba(224,37,37,0.45);" class="mt-5">
        <v-card-text>
          <v-row class="pa-0 custom-header-actions-cl">
            <v-col cols="12" class="pa-0" >
              <div class="d-flex justify-center justify-lg-space-between">
                <div class="d-flex">
                  <div class="mr-2 custom-container-appe-cl" style="min-width:100%">
                    <v-alert dense solo style="border-radius: 20px; font-size:0.8rem;" color="#CF6679"
                    class="text-white font-weight-bold">
                     {{seller.APPEN > 0 ?'Tienes&nbsp;'+seller?.APPEN:'No&nbsp;tienes'}}&nbsp;aplicaciones de&nbsp;pago&nbsp;pendientes
                    </v-alert>
                  </div>
                  <div class="d-flex container-billing-type-cl" style="min-width:100%">
                    <div class="">  
                        <v-icon size="35" class="ml-8">mdi-cash-multiple</v-icon> 
                        <span class="">
                        Facturación:
                        </span> 
                      </div>
                      <div class="ml-xl-2">
                        <CustomSelect v-model="billingType"  value="1" :options="[{ value: '1', label: 'Trimestral' },{ value: '2', label: 'Mensual' }]" :placeholder="'Trimestral'"></CustomSelect>
                      </div>
                  </div>
                </div> 
              <div class="d-flex">
                    <div class="mt-xl-n2 label-ccb-cl mr-lg-10">
                      <v-checkbox v-model="isCustomerTerminated" class="">
                      <template v-slot:label>
                      <div style="color:#047DFF">
                        Ocultar Clientes con Condición de Baja
                        </div>
                      </template>
                      </v-checkbox>
                    </div> 
                    <div class="mt-lg-1 mb-lg-0 container-search-customr-cl">
                      <v-text-field v-model="searchCustomerInput"  label="Buscar Cliente" solo outlined dense class=" text-uppercase"
                        prepend-inner-icon="mdi-magnify" 
                        append-outer-icon="mdi-magnify"
                        @click:append-outer="searchCustomers()"
                        @input="searchCustomers()">
                      </v-text-field> 
                    </div>
              </div>
            </div> 
             </v-col>
          </v-row>
          <v-simple-table class="my-table w-full">
            <template v-slot:default>
              <thead class="table-heading">
                <tr>
                   <th :colspan="showMoreTrim ?'1':'12'"></th> 
                  <th :colspan="showMoreTrim ?'9':'4'">
                    <div style="display: flex !important; justify-content:center !important" class="py-2">
                        <div>
                          <span class="text-black font-weight-bold" >
                            Facturacion Neta / <span style="font-size: 10px;" > Renglonaje </span>
                          </span>
                          <v-checkbox v-model="showMoreTrim" :hide-details="true" solo style="margin-top: 0">
                            <template v-slot:label>
                              <span class=" text--darken-2 " style="font-size: 11px;">
                                Mostrar más {{ billingType==1 ?'trimestres':'meses' }}
                              </span>
                            </template> 
                          </v-checkbox>
                        </div> 
                    </div>
                  </th>
                </tr>
                <tr class="mt-n10 " >
                  <th class="text-center text-dark font boder-x-none column-fixed">
                    <a @click="orderBy(1)">
                    <div class="d-flex d-inline-block justify-start ml-6">
                      <div>Cliente </div>
                      <div> <v-icon  class="mt-n1">mdi-arrow-{{ orderByCustomer ?'up':'down' }}</v-icon> </div>
                    </div>
                  </a>
                  </th>

                
                <template  v-if="!showMoreTrim" >
                  <th class="text-center text-dark font boder-x-none"></th>
                  <th   class="text-center text-dark font boder-x-none">Rotulado</th>
                  <!-- <th   class="text-center text-dark font boder-x-none">Fotos</th> -->
                  <th  class="text-center text-dark font boder-x-none">&nbsp;&nbsp;Facturación&nbsp;<br />Nuevos </th>
                  <th   class="text-center text-dark font boder-x-none">
                    <a  @click="orderBy(2)"> 
                      #&nbsp;de&nbsp;meses desde&nbsp;alta
                        <br />del&nbsp;cliente <br />en&nbsp;sistema 
                       <div> <v-icon class="">mdi-arrow-{{ orderByTime?'up':'down' }}</v-icon> </div> 
                    </a>
                  </th>
                  <th  class="text-center text-dark font boder-x-none ">
                    <a @click="orderBy(3)"> 
                      Prom&nbsp;Pond&nbsp;de&nbsp;<br />días&nbsp;de&nbsp;pago&nbsp;del mes<br/> actual&nbsp;(#Docs)
                       <div class="mb-n4"> <v-icon class="">mdi-arrow-{{ orderByPPD?'up':'down' }}</v-icon> </div> 
                    </a>
                  </th>
                  <th   class="text-center text-dark font boder-x-none"> Límite&nbsp;de <br />crédito </th>
                  <th  class="text-center text-dark font boder-x-none">Pagaré</th>
                  <th  class="text-center text-dark font boder-x-none">
                   <div class="pa-0 ma-0 d-flex justify-center  flex-column">
                    <div class="text-center">% Pagos <br>{{ currentMonth }}</div>
                    <div>
                      <CustomSelectRed value="1" v-model="paymentType" :options="[{ value: '1', label: 'Vía IEL + SPEI' },{ value: '2', label: 'IEL' },{ value: '3', label: 'Otros pagos' }]"
                        :placeholder="'Ver pagos'" 
                        style="color: #000; width: max-content; font-size: 0.8em;"></CustomSelectRed>
                        </div>
                    </div>
                  </th>
                  <th  class="text-center text-dark font boder-x-none pt-6"> 
                    <a @click="orderBy(4)"> 
                      Pedidos <br>&nbsp;{{ currentMonth }} 
                      <div> <v-icon class="">mdi-arrow-{{ orderByOrders?'up':'down' }}</v-icon> </div>
                    </a>
                  </th>
                  <th  v-if="!showMoreTrim" class="text-center text-dark font boder-x-none pt-6">
                    <a @click="orderBy(5)"> 
                      Crecimiento <br />acumulado 
                      <div> <v-icon class="">mdi-arrow-{{ orderByComulativeG?'up':'down' }}</v-icon> </div> 
                      </a>
                  </th>
                  </template>

                  <template  v-if="billingType==1" >
                      <th v-for="(item,index) in headerBillingQuarters.slice(0, showMoreTrim?8:4)" :key="item.i" class="text-center text-dark font boder-x-none" width="100">
                        <a @click="orderBy(index+6)" > 
                          Trimestre <br />{{ item.text }} <br />
                          <div :class="showMoreTrim?'':'mb-n8'"> <v-icon >mdi-arrow-{{ orderByPeriod[index]?'up':'down' }}</v-icon> </div> 
                        </a>
                        </th>
                    </template>
                    <template  v-else >
                      <th v-for="(item,index) in headerBillingMonths.slice(0, showMoreTrim?8:4)" :key="item.i" class="text-center text-dark font boder-x-none" width="100"> 
                        <a @click="orderBy(index+14)" > 
                          {{ item.text }} <br />
                          <div :class="showMoreTrim?'':'mb-n8'"> <v-icon >mdi-arrow-{{ orderByPeriod[index+8]?'up':'down' }}</v-icon> </div> 
                        </a>
                        
                      </th>
                    </template>  
                </tr>
                 <tr v-if="loading">
                  <td colspan="19">
                      <span class="text-secondary">Cargando... Por favor espere</span>
                      <v-progress-linear
                          indeterminate
                          color="teal"
                      ></v-progress-linear>
                  </td>
                </tr>
              </thead>
              <tbody class="custom-table-bordered" v-if="!loading">
                <tr v-for="(customer,index) in filteredCustomers" :key="index" >
                    <td class="text-left column-fixed bg-white" style="border-right:0; border-width:0px; font-size: 0.8rem;">
                    <div class="d-flex d-inline-block ma-0 pa-0">
                      <div class="align-self-center ma-1 font-weight-bold" style="font-size:14px">
                        {{ index+1 }} 
                      </div> 
                      <div>
                        <a @click="showCustomerCover(customer.KUNNR)"> 
                        <span v-if = "parseInt(customer.APPEND,10) >=1" class="small text-decoration-underline font-weight-bold text-danger " >
                          <b>    {{ transformKUNNR(customer.KUNNR) }}  <br />
                            <v-tooltip top >
                            <template v-slot:activator="{ on, attrs }"> 
                              <span class="d-flex mt-n1" v-on="on" v-bind="attrs" v-html="(customer?.NAME1).replaceAll(' ','&nbsp;')"></span> 
                            </template>
                            <span> Cliente con aplicaciones de pago pendiente</span>
                          </v-tooltip>
                            </b> 
                      </span>
                      <span v-else class="small text-decoration-underline font-weight-bold">   
                        <b>    {{ transformKUNNR(customer.KUNNR) }}<br />
                          <span class="d-flex mt-n1" v-html="(customer?.NAME1).replaceAll(' ','&nbsp;')"></span>
                        </b>
                      </span>
                    </a>
                    </div>
                    </div>
                    </td>
                  <template v-if="!showMoreTrim">
                                        
                    <td class="d-flex justify-end  align-center" style="border:0;  border-width:0px; font-size: 0.8rem;">
                      <v-tooltip  v-if="customer.CGRUPO === 'P' || customer.CGRUPO === 'H'" top >
                          <template v-slot:activator="{ on, attrs }"> 
                            <img src="/img/account-group-outline.svg"  v-on="on" v-bind="attrs" class="ma-1" style="width: 25px"/>  
                          </template>
                          <span>Cliente de grupo</span>
                        </v-tooltip> 
                      <v-tooltip top v-if = "customer.SUSPE >= 1">
                          <template v-slot:activator="{ on, attrs }"> 
                              <v-icon color='#B71C1C' v-on="on" v-bind="attrs">
                                mdi-cancel
                              </v-icon> 
                          </template>
                          <span>Cliente bloqueado</span>
                        </v-tooltip>
                        <v-tooltip top v-if = "customer.CTEBA >= 1">
                          <template v-slot:activator="{ on, attrs }"> 
                              <v-icon color='black' v-on="on" v-bind="attrs">
                                mdi-account-remove
                              </v-icon> 
                          </template>
                          <span>Cliente dado de baja</span>
                        </v-tooltip>                        
                      <v-tooltip top v-if="customer.CSUSPE==1" >
                          <template v-slot:activator="{ on, attrs }"> 
                            <v-img  v-on="on" v-bind="attrs" src="/img/cliente-baja.svg" height="20px" width="10px" class="ma-1"> </v-img>
                          </template>
                          <span>Cliente con suspensión temporal</span>
                      </v-tooltip> 
                      <v-icon v-if="customer.NEWCUSTOMER > 0 && customer.NEWCUSTOMER <= 6" :color="newCustomerColor(customer.NEWCUSTOMER)" class="">mdi-account-check</v-icon>
                      <v-btn icon @click="showInfo(2,customer)">
                          <v-icon :color="customer.MPIEL > 0?'green':'red'">
                                mdi-currency-usd
                              </v-icon> 
                      </v-btn> 
                      <a @click="showPie(customer.KUNNR)" class="ma-1"> <v-icon color="#B71C1C">mdi-chart-pie</v-icon></a> 
                    </td>
                    <!-- <td >
                      <div class="d-flex d-inline-block">  
                        <img src="../../../../public/img/logo_iusa_gray.svg" class="mr-1" width="20px" /> 
                    </div>  
                    </td> -->
                    <td>
                      <div class="d-flex flex-nowrap">
                      <div class="d-flex d-inline-block">  
                          <img src="../../../../public/img/logo_iusa_gray.svg" class="mr-1" width="20px" /> 
                      </div>  
                      <v-tooltip bottom v-if="customer.ROTUL!=0">
                          <template v-slot:activator="{ on, attrs }">
                            <a v-bind="attrs" v-on="on" @click="showPhoto(customer.DOCUMENT)">
                                <v-icon >mdi-camera</v-icon> 
                            </a>                          
                          </template>
                          <span >Ver fotos del cliente</span>
                      </v-tooltip> 
                      <v-tooltip bottom v-else>
                          <template v-slot:activator="{ on, attrs }">
                            <a v-bind="attrs" v-on="on">
                                <v-icon :color="'#CFD8DC'">mdi-camera</v-icon> 
                            </a>                          
                          </template>
                          <span >No hay fotos del cliente</span>
                      </v-tooltip>
                      <v-tooltip bottom v-if="customer.DOCUMENT">
                          <template v-slot:activator="{ on, attrs }">
                              <v-badge :value="requireUpdatePhotos(customer.DOCUMENT.last_photo_update)" color="error" icon="mdi-alert-circle" content="Actualizar" overlap >
                               <v-icon v-bind="attrs" v-on="on" @click="getCustomerImages(customer)" color="orange">mdi-upload</v-icon> 
                              </v-badge> 
                          </template>
                          <span>Actualizar fotos</span>
                      </v-tooltip> 
                      </div>
                    </td>
                    <td   class="">{{numberformat(customer.PFACN,false)}}%</td>
                    <td >
                      {{ customer.ERDAT }} Meses</td>
                    <td class="font-weight-bold">{{ customer.PPDP3 }} ({{ customer.DPPP3 }})</td>
                    <td >${{ numberformat(customer.LCRED) }}</td>
                    <td >
                      <span class="d-flex">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs"
                                v-on="on" :color="customer.SPAGA==1?'green': customer.SPAGA == 2 ? 'orange': customer.SPAGA  == 0? 'gray': customer.SPAGA == 4 ? 'brown' : 'red'">mdi-circle</v-icon> 
                          </template>
                          <span>{{ customer.SPAGA==1?'Vigente': customer.SPAGA == 2 ? 'Por vencer':customer.SPAGA  == 0? 'Sin información adicional': customer.SPAGA == 4 ? 'Pagaré Rechazado' : 'Vencido' }}</span>
                      </v-tooltip> 
                      <v-tooltip bottom v-if="customer.APAGA" > 
                          <template v-slot:activator="{ on, attrs }">
                            <a :href="apiUrl+'/'+customer.APAGA" target="_blank">
                          <v-icon v-bind="attrs"
                                v-on="on" color="red">mdi-file-download-outline</v-icon>
                            </a>  
                          </template>
                          <span>Descargar</span>
                      </v-tooltip>
                      <v-tooltip>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary" icon @click="showNewPromissoryNote(customer)" v-on="on" v-bind="attrs">
                              <v-icon>mdi-note-plus</v-icon>
                            </v-btn>
                          </template>
                        </v-tooltip>
                      </span>
                    </td>
                    <td  class="text-decoration-underline text--accent-4">
                      <a :class="minMaxPayments?.maxPPISP == customer.PPISP && customer.PPISP!=0 ?'text-success':(minMaxPayments?.minPPISP == customer.PPISP && customer.PPISP!=0 ? 'text-success':'text-success')" v-if="paymentType==1" @click="showInfo(1,customer)"> {{sum(customer.PPISP,customer.PPIEL,true) }}%</a>
                      <a :class="minMaxPayments?.maxPPIEL == customer.PPIEL && customer.PPIEL!=0 ?'text-success':(minMaxPayments?.minPPIEL == customer.PPIEL && customer.PPIEL!=0? 'text-danger':'text-black')" v-if="paymentType==2" @click="showInfo(1,customer)">{{numberformat(customer.PPIEL,false)}}% </a>
                      <a :class="minMaxPayments?.maxPPOPA == customer.PPOPA && customer.PPOPA!=0?'text-success':(minMaxPayments?.minPPOPA == customer.PPOPA && customer.PPOPA!=0? 'text-danger':'text-black')" v-if="paymentType==3" @click="showInfo(1,customer)">{{numberformat(customer.PPOPA,false)}}% </a>
                    </td>
                    <td ><b>${{numberformat (customer.PEDMES??0) }}</b></td>
                    <td  :class="'text-decoration-underline '+ 
                    (customer.PCREA > 0 ? 'text-success': (customer.PCREA < 0 ? 'text-danger':'text-yellow') ) "> <a @click="showInfo(3,customer)"> {{numberformat(customer.PCREA,false)}}%</a></td>
                  </template>
                  <template v-if="billingType==1">
                    <td v-for="item in customer.FACTRI.TRIMESTRE.slice(0, showMoreTrim?8:4)" :key="item.i" style="font-size: 13px; width: 100px !important"> 
                      <b> ${{ numberformat(item.FACNETA) }}</b><br />
                      <span class="font-weight-bold small">{{ item.RENGLO }}</span> 
                    </td>
                  </template>
                  <template v-else>
                    <td v-for="item in customer.FACMES.MES.slice(0, showMoreTrim?8:4)" :key="item.i" style="font-size: 13px; width: 100px !important"> 
                      <b> ${{ numberformat(item.FACNETA) }}</b><br /> 
                       <span class="font-weight-bold small">{{ item.RENGLO }}</span> 
                    </td>
                  </template>
                </tr>
                <tr class="mt-n10 table-heading">
                  <th class="text-center text-dark font boder-x-none" > </th>
                  <template v-if="!showMoreTrim">
                    <th  class="text-center text-dark font boder-x-none"></th>
                    <th  class="text-center text-dark font boder-x-none">Rotulado</th>
                    <!-- <th  class="text-center text-dark font boder-x-none">Fotos</th> -->
                    <th   class="text-center text-dark font boder-x-none">&nbsp;&nbsp;Facturación&nbsp;<br />Nuevos </th>
                    <th class="text-center text-dark font boder-x-none">#&nbsp;de&nbsp;meses desde&nbsp;alta
                      <br />del&nbsp;cliente <br />en&nbsp;sistema </th>
                    <th  class="text-center text-dark font boder-x-none">
                      Prom&nbsp;Pond&nbsp;de&nbsp;<br />días&nbsp;de&nbsp;pago&nbsp;del mes<br/> actual&nbsp;(#Docs) </th>
                    <th class="text-center text-dark font boder-x-none"> Límite&nbsp;de <br />crédito </th>
                    <th class="text-center text-dark font boder-x-none">Pagaré</th>
                    <th  class="text-center text-dark font boder-x-none">
                      <div class="text-center">% Pagos <br>{{ currentMonth }}</div> 
                    </th>
                    <th  class="text-center text-dark font boder-x-none"> Pedidos <br>&nbsp;{{ currentMonth }} </th>
                    <th class="text-center text-dark font boder-x-none"> Crecimiento <br />acumulado
                      <br />del&nbsp;cliente
                    </th>
                  </template>
                  <template v-if="billingType==1">
                    <th v-for="item in headerBillingQuarters.slice(0, showMoreTrim?8:4)" :key="item.i" class="text-center text-dark font boder-x-none" style="width: 100px !important;">
                        Trimestre <br />{{ item.text }}
                    </th> 
                  </template>
                  <template  v-else >
                      <th v-for="(item,index) in headerBillingMonths.slice(0, showMoreTrim?8:4)" :key="item.i" class="text-center text-dark font boder-x-none" width="100">
                        <a @click="orderBy(index+14)" > 
                          {{ item.text }} 
                        </a>
                      </th>
                    </template> 
                </tr>
                <tr v-if="loading">
                  <td colspan="19">
                      <span class="text-secondary">Cargando... Por favor espere</span>
                      <v-progress-linear
                          indeterminate
                          color="teal"
                      ></v-progress-linear>
                  </td>
                </tr>                   
              </tbody>
            </template>
          </v-simple-table> 
          <div class="w-full text-body-1 font-weight-bold text-black my-4">
            <v-icon color="#5600E8" class="mr-1">mdi-account-check</v-icon>
            <span class="mr-4"> Clientes Nuevos a 1 Mes</span>
            <v-icon color="#007BFF" class="mr-1">mdi-account-check</v-icon>
            <span class="mr-4"> Clientes Nuevos a 3 Meses</span>
            <v-icon color="#F71FFF" class="mr-1">mdi-account-check</v-icon>
            <span> Clientes Nuevos a 6 Meses</span>
          </div> 
        </v-card-text> 
      </v-card>
      <v-dialog v-model="showModalPie" :max-width="800" :persistent="loadingBilling">
    <!-- 500 para table 1 |  350 a table 2 | 800 table 3 -->
        <v-card class="pa-3">
            <Graph :charTitle="'Detalle de cliente '+currentClient " :charData="billingByArea" :load="loadingBilling" @closeModal="closeModal()" @periodChanged="loadSalesPerArea"> </Graph>
            <div  class="text-right ma-5" >
                <v-btn small outlined color="success" @click="closeModal()">
                Cerrar
              </v-btn>
            </div>   
          </v-card> 
      </v-dialog>  
  <v-dialog v-model="showModal" :max-width="800" :persistent="loadingBilling">
    <!-- 500 para table 1 |  350 a table 2 | 800 table 3 -->
    <v-card class="pa-3">

          <ComulativeClientGrowth v-if="comulativeClientGrowth" :currentCustomer="customerSelected.KUNNR" > </ComulativeClientGrowth> 
          <CustomerPaymentTypes v-if="showCustomerPaymentTypes" :customer="customerSelected.KUNNR" > </CustomerPaymentTypes>  
            <div class="text-right mt-3">
          <v-btn @click="closeModal()" outlined class="text-left" color="success">
                  Cerrar
                </v-btn>
              </div>  
        </v-card>                    
  </v-dialog>   
  <PaymentInfo v-model="paymentInfo"  :customer="customerSelected" /> 
  
  <UpdateCustomerImagesForm
    :dialogModel="updateImageModal"
    :document="dataDocument"
    :userId="customerSelected.CUSTOMER?.user_id"
    :customerId="customerSelected.CUSTOMER?.id"
    @updatedPhotos="updateCustomerPhotos"
    @closeModal="updateImageModal=false"
    /> 

  <CoolLightBox  
    :items="photos" 
    :index="index"
    @close="index = null">
</CoolLightBox> 


  <CreatePromissoryNote 
    :showDialog="promissoryNoteDialog"
    :customerCode="promissoryNoteCustomer"
    :customerName="promissoryNoteCustomerName"
    @cancel="cancelAddPromissoryNote"></CreatePromissoryNote>
  </div>
</template>

<script>
import CustomSelectRed from "./../components/CustomSelectRed.vue";
import ComulativeClientGrowth from "./modals/ComulativeClientGrowth.vue"
import CustomSelect from "./../components/CustomSelect.vue";
import PaymentInfo from "./modals/PaymentInfo.vue"; 
import Graph from "./modals/Graph.vue";
import CustomerPaymentTypes from "./modals/CustomerPaymentTypes.vue";
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
import axios from "@/plugins/axios"; 
import moment from 'moment';
import CreatePromissoryNote from "@/views/client_request/promissory_notes/CreatePromissoryNote.vue";
import UpdateCustomerImagesForm from '@/components/forms/UpdateCustomerImagesForm.vue'
export default({  
  watch: {
    seller(){
      this._getData(); 
    },
    isCustomerTerminated(value){ 
      this.filteredCustomers = value ? this.customers.filter(i => i.CTEBA == 0) : this.customers;
    },
  },
    props: [
      'user', //Auth user
      'seller'//general info
    ],
    name:'CustomerList',
    components:{ ComulativeClientGrowth,PaymentInfo,Graph,CustomSelectRed,CustomSelect, CoolLightBox,
    CreatePromissoryNote,CustomerPaymentTypes,UpdateCustomerImagesForm},
    data () {
    return {   
      form: {
        facade_photo: [],
        indoor_photo: [],
        bar_photo: [],
      },
        loadPhotos:false,
        dataDocument:{},  
        updateImageModal:false,
        loading:true,
        orderByCustomer:false,
        orderByComulativeG:false,
        orderByOrders:false,
        orderByPPD:false,
        orderByTime:false,
        orderByPeriod:[
          false,false,false,false,false,false,false,false, //Ordenamiento para trimestres
          false,false,false,false,false,false,false,false //Ordenamiento para meses
        ],
        apiUrl:process.env.VUE_APP_FILES_URL,
        customers:[],
        paymentType:"1",
        minMaxPayments:[],
        showModalPie:false,
        billingByArea:[],
        loadingBilling:false,
        billingType:'1',
        filteredCustomers:0,
        perPage:10,
        isCustomerTerminated:false,
        paymentInfo:false,
        showModal:false, 
        searchCustomerInput:"",
        currentMonth:this.getCurrentMonth(),
        customerSelected:{},
        showMoreTrim:false,
        today: new Date(),
        maxTrim:0,
        minTrim:0,
        currentClient:0,
        headerBillingQuarters :[
          {text:this.getTrim(1)}, 
          {text:this.getTrim(2)}, 
          {text:this.getTrim(3)}, 
          {text:this.getTrim(4)}, 
          {text:this.getTrim(5)}, 
          {text:this.getTrim(6)}, 
          {text:this.getTrim(7)}, 
          {text: this.getTrim(8)},
        ],
        headerBillingMonths: [
          {text:this.subtractMonths(0,1)}, 
          {text:this.subtractMonths(1,1)}, 
          {text:this.subtractMonths(2,1)}, 
          {text:this.subtractMonths(3,1)}, 
          {text:this.subtractMonths(4,1)}, 
          {text:this.subtractMonths(5,1)}, 
          {text:this.subtractMonths(6,1)}, 
          {text:this.subtractMonths(7,1)}, 
        ], 
        totalItems:0,
        maxPages:0,
        bodyBillingQuarters :[],
        photos: [],  
        comulativeClientGrowth:false,
        items: [
          {
              description: "",
              title: "",
              src:""
          }
        ],         
        index: null, 
        promissoryNoteDialog: false,
        promissoryNoteCustomer: null,
        promissoryNoteCustomerName: null,
        showCustomerPaymentTypes:false,
    }
  },
  methods: {
    requireUpdatePhotos(date){
      const months = this.calculateMonths(date);
      return months > 6 ? true : false  //Si es mayor a 6 entonces recordar que debe actualizar
    },
    updateCustomerPhotos(documents){
     this.customerSelected.DOCUMENT = documents;
     this.customerSelected.ROTUL=1; 
     this.getCustomerImages( this.customerSelected)
     this.syncCustomerCache(this.customerSelected)
    },
    newCustomerColor(number){ 
      switch (number) {
        case 1: return "#5600E8"; 
        case 3: return"#007BFF"; 
        case 6: return"#F71FFF";  
        default:return "";
      } 
    },
  
   async showCustomerCover(customer){  
      localStorage.setItem('currentCustomerCode', JSON.stringify(customer)); 
      this.$router.push({name: 'CustomerCoverDetails'});
    },


  async  syncCustomerCache(customerData){/*  Para actualizar en caché */ 
      var storageId = 'get-customers-'+Number(this.seller.PERNR);
            var currentCache = await axios.storage.get(storageId) 
             var indexSelected =  this.customers.findIndex(item => item.KUNNR == customerData.KUNNR); 
               currentCache.data.data[0][indexSelected]=  customerData;  
    },/*  Para actualizar en caché */ 

   getCustomerImages(customer) {   
      this.customerSelected = customer;
      this.dataDocument = customer.DOCUMENT 
      this.updateImageModal=true; 
      if (this.dataDocument?.facade_photo) {
              this.items.push({
                description: "Fachada",
                title: "Fachada",
                src: this.apiUrl + this.dataDocument.facade_photo,
              });
            }
            if (this.dataDocument?.indoor_photo) {
              this.items.push({
                description: "Interior",
                title: "Interior",
                src: this.apiUrl + this.dataDocument.indoor_photo,
              });
            }
            if (this.dataDocument?.bar_photo) {
              this.items.push({
                description: "Mostrador/Bodega:",
                title: "Mostrador/Bodega:",
                src: this.apiUrl + this.dataDocument.bar_photo,
              });
            } 
    },
    restoreOrderArrows(col){
      this.orderByCustomer= col == 1?true:false;
      this.orderByTime=col == 2?true:false; 
      this.orderByPPD= col == 3?true:false;
      this.orderByOrders=col == 4?true:false;
      this.orderByComulativeG=col == 5?true:false;
      if (col>5) { 
        col = (col>5 && col < 14)?col-6:col-13;
        for (let i = 0; i < this.orderByPeriod.length; i++) {
          if (i == col) {
            this.orderByPeriod[i]=true;
          }else { 
            this.orderByPeriod[i]=false;
          }  
        }
      } 
    },
    orderBy(col){
      var ordered;
      switch (col) {
        case 1: //customer 
          ordered = this.customers.sort((a, b) => {
                  var cmpareName = this.orderByCustomer ?
                  a.NAME1.localeCompare(b.NAME1):
                  b.NAME1.localeCompare(a.NAME1); 
                  return cmpareName !== 0 ? cmpareName : b.KUNNR - a.KUNNR;
                })
          if (this.orderByCustomer) { this.orderByCustomer = false; }else{ this.restoreOrderArrows(1)}   
          this.filteredCustomers =  ordered;
          break;
        case 2: //Meses de alta
        ordered = this.customers.sort((a, b) => {
                  const fechaA = Number(a.ERDAT);
                  const fechaB = Number(b.ERDAT);
                  if (this.orderByTime){
                    if (fechaA < fechaB) return -1;
                    if (fechaA > fechaB) return 1;
                    return 0; 
                    } 
                    if (fechaA > fechaB) return -1;
                    if (fechaA < fechaB) return 1;
                    return 0; 
                  })
            this.filteredCustomers =  ordered;
          if (this.orderByTime) { this.orderByTime = false; }else{ this.restoreOrderArrows(2)}   
          
          break; 
        case 3: //Prom.pond Pago 
          if (this.orderByPPD) { //ASC 
            ordered = this.customers.sort((a, b) =>{ 
              if ( Number(a.PPDP3) <  Number(b.PPDP3)) return -1;
              if ( Number(a.PPDP3) >  Number(b.PPDP3)) return 1;
              return 0;  
            }) 
           this.orderByPPD= false; 
          }else {
            ordered = this.customers.sort((a, b) =>{  
              if ( Number(a.PPDP3) >  Number(b.PPDP3)) return -1;
              if ( Number(a.PPDP3) <  Number(b.PPDP3)) return 1;
              return 0;   
            }) 
            this.restoreOrderArrows(3)
          }
          this.filteredCustomers =  ordered;
          break; 
        case 4: //customer 
          if (this.orderByOrders) { //ASC  
            ordered = this.customers.sort((a, b) =>{  
              const currentBillingM =   Number(a.FACMES.MES[0].FACNETA); 
              const otheBilling =  Number(b.FACMES.MES[0].FACNETA);
              if (currentBillingM < otheBilling) return -1;
              if (currentBillingM > otheBilling) return 1;
              return 0; 
            }) 
           this.orderByOrders= false; 
          }else {
            ordered = this.customers.sort((a, b) =>{   
              const currentBillingM =   Number(a.FACMES.MES[0].FACNETA); 
              const otheBilling =  Number(b.FACMES.MES[0].FACNETA);
              if (currentBillingM > otheBilling) return -1;
              if (currentBillingM < otheBilling) return 1;
              return 0; 
            })
            this.restoreOrderArrows(4)   
          }
          this.filteredCustomers =  ordered;
          break; 
        case 5: //customer 
            ordered = this.customers.sort((a, b) =>{ 
                const cmpareName = this.orderByComulativeG ? 
                a.PCREA.localeCompare(b.PCREA):
                b.PCREA.localeCompare(a.PCREA);
                return cmpareName !== 0? cmpareName: b.PCREA - a.PCREA;
            })  
           if (this.orderByComulativeG) {  this.orderByComulativeG= false;   }else { this.restoreOrderArrows(5)}
           this.filteredCustomers =  ordered;
          break; 
        default:
          if (col> 5 && col < 14){
            col = col-6;
            if(this.orderByPeriod[col]){
              ordered = this.customers.sort((a, b) => {  
              const currentBillingM =   Number(a.FACTRI.TRIMESTRE[col].FACNETA); 
              const otheBilling =  Number(b.FACTRI.TRIMESTRE[col].FACNETA);
              if (currentBillingM < otheBilling) return -1;
              if (currentBillingM > otheBilling) return 1;
              return 0; 
            })  
            this.orderByPeriod[col] = false;
              }else{
                ordered = this.customers.sort((a, b) =>{   
              const currentBillingM =   Number(a.FACTRI.TRIMESTRE[col].FACNETA); 
              const otheBilling =  Number(b.FACTRI.TRIMESTRE[col].FACNETA);
              if (currentBillingM > otheBilling) return -1;
              if (currentBillingM < otheBilling) return 1;
              return 0; 
            })  
            this.restoreOrderArrows(col+6)
              }
              this.filteredCustomers =  ordered;
          }else {
            col = col-14;
            if(this.orderByPeriod[col+8]){
              ordered = this.customers.sort((a, b) => {  
              const currentBillingM =   Number(a.FACMES.MES[col].FACNETA); 
              const otheBilling =  Number(b.FACMES.MES[col].FACNETA);
              if (currentBillingM < otheBilling) return -1;
              if (currentBillingM > otheBilling) return 1;
              return 0; 
            }) 
            this.orderByPeriod[col+8] = false;
              }else{
                ordered = this.customers.sort((a, b) =>{   
              const currentBillingM =   Number(a.FACMES.MES[col].FACNETA); 
              const otheBilling =  Number(b.FACMES.MES[col].FACNETA);
              if (currentBillingM > otheBilling) return -1;
              if (currentBillingM < otheBilling) return 1;
              return 0; 
            })  
        
            this.restoreOrderArrows(col+21)
              }
              this.filteredCustomers =  ordered;}
         
          break;
      }
      
    },
    sum(val1=0,val2=0,round = false)
    {
      return this.numberformat(Number(val1)+Number(val2),round);
    },
    calculatePercent(total =  0, value= 0){
      if (total > 0 ){
        if (value ==0 ) return this.numberformat(0,false)
        return this.numberformat(total/value,false);
      }
    },
    transformKUNNR(customer_id){ 
        return Number(customer_id) 
    } ,
    calculateMonths(date) {
      if (date) {
        const currentDate = moment();
        const selectedDate = moment(date);
        const monthsDiff = currentDate.diff(selectedDate, 'months');
       return Math.abs(monthsDiff);
      } else {
       return 0;
      }
    },
    getCurrentBilling(customer){
      if (customer.FACMES?.MES){
        let currentBillingM =   customer.FACMES.MES.slice(0,1)
       // console.log(currentBillingM[0].FACNETA);
        return currentBillingM[0].FACNETA
      }
      return 0
    },
    getCurrentMonth(){ 
    let today = new Date();
    //today.setMonth(today.getMonth() - months); 
    let month = today.toLocaleString('default', { month:  'long' });
    let formatedMonth = month.charAt(0).toUpperCase() + month.slice(1);
    return formatedMonth; 
    },
    searchCustomers(){
      this.searchCustomerInput = this.searchCustomerInput.toUpperCase();
     const searchItems = this.customers.filter(i => 
     (
        i.KUNNR == this.searchCustomerInput 
     || i.NAME1 == this.searchCustomerInput 
     || i.NAME1.includes(this.searchCustomerInput) 
     || i.KUNNR.toString().includes(this.searchCustomerInput)));
     this.filteredCustomers =  searchItems;
    },
    showPie(client){
        this.currentClient = client;
        this.showModalPie = true;
        let currentTrim = this.getCurrentTrim();
        let currentYear = new Date().getFullYear();
        this.loadSalesPerArea(currentYear+"-"+currentTrim);
    },
    closeModal(){
      this.showModal=false;
      this.showModalPie=false;
      this.comulativeClientGrowth=false;
      this.showCustomerPaymentTypes=false;
      this.paymentInfo=false; 
    },
    showInfo(type,client){
      this.customerSelected = client;
      this.showModal=true;
      switch (type) { 
          case 1: //users 
          this.showModal=false;
            this.paymentInfo=true; 
            this.showCustomerPaymentTypes=false;
            this.comulativeClientGrowth=false;
            break;
          case 2: //
            this.paymentInfo=false; 
            this.comulativeClientGrowth=false;
            this.showCustomerPaymentTypes=true;
            break;
        default:
            this.paymentInfo=false; 
            this.showCustomerPaymentTypes=false;
            this.comulativeClientGrowth=true;
          break;
      }
      
          
    },
    loadSalesPerArea(per){ 
      let year = per.slice(0,4);
      let trim = per.slice(5,6);  
      this.loadingBilling =true;
      axios
          .post("/seller/home/sales-by-div", {
            customer_id: this.currentClient,
            type:2,
            trim: trim,
            year: year,
            channel: this.seller.VTWEG
          })
          .then((response) => {
            if (response.data?.success==false) {
                console.log( response.data)
            } else {
                if (!response.data.DIVISION) { 
                  this.billingByArea = []; 
                }else {
                  this.billingByArea =
                "NETWR1" in response.data.DIVISION //Cuando viene un item. Convertirlo en array con index 0
                  ? [response.data.DIVISION]
                  : response.data.DIVISION;

                }
                
            } 
            this.loadingBilling =false;
          })
          .catch((e) => {
            console.log(e);
            this.loadingBilling =false;
          }); 
    },
    closePieModal(){
      this.showModalPie=false
    },
    _getData(){
      this.loading=true; 
        axios
          .post("/seller/home/get-customers", {
            seller_id: this.seller.PERNR,
            channel: this.seller.VTWEG
          },{
            noCancelRequest:true,
            cachable:true,
            id:'get-customers-'+Number(this.seller.PERNR)
          })
          .then((response) => {
            this.$emit('desactiveLoader');
            if (response.data?.success==false) {
                console.log( response.data)
            } else { 
              this.customers = response.data[0]; 
              this.filteredCustomers =  this.customers;
                this.minMaxPayments = response.data[1]; 
                this.orderBy(14);
            } 
          })
          .catch((e) => {
            this.$emit('desactiveLoader');
            console.log(e);
          }).finally (() => { 
            this.loading =false;
          });          
          
    },
  numberformat(number=0,round=true){ 
    let result = round? Math.trunc(number): Number(number).toFixed(2);
    return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  getCurrentTrim(){ 
    let currentMonth =  new Date().getMonth()+1;
    return Math.ceil(currentMonth/3);
  },
  getTrim(row){
    let currentTrim = this.getCurrentTrim();
    let currentYear = new Date().getFullYear();
    for (let index = 2; index <= row; index++) {
       currentTrim = currentTrim - 1;
       if (currentTrim == 0 ) {
        currentTrim = 4;
        currentYear = currentYear-1;
       } 
    }
   return currentTrim+"/"+currentYear;
  },
  subtractMonths(months=0,widthYear = 0) {
    let today = moment()
    let month = today.subtract(months, 'months').format('M')
    month = this.$options.filters.formatMonthText(month)
    let year = today.format('YYYY')
    if (widthYear) {
      return month+'/'+year.slice(2,4);
    }  
    return month;
  }, 
  _getDocumentData(customer_code){       
    axios.get("/seller/home/get-data-customer", {
        params:{
          client_id: customer_code
        }
    }).then((response) => {
        if( response.data.result == 1 ){
            let dataDocument = response.data.data;
            let urlServer = process.env.VUE_APP_FILES_URL 
            this.items[customer_code] = []              
            if(dataDocument?.facade_photo){
                this.items[customer_code].push({
                    description: "Fachada",
                    title: "Fachada",
                    src: urlServer+'/'+dataDocument.facade_photo
                });
            }
            if(dataDocument?.indoor_photo){
                this.items[customer_code].push({
                    description: "Interior",
                    title: "Interior",
                    src: urlServer+dataDocument.indoor_photo
                });
            }
           
            if(dataDocument?.bar_photo){
                this.items[customer_code].push({
                    description: "Mostrador/Bodega:",
                    title: "Mostrador/Bodega:",
                    src: urlServer+dataDocument.bar_photo
                });
            } 
          }
        
    })
    .catch((e) => {
        console.log(e);
    });
  },
  sendPhoto(code){
    this.index = 0  
    this.photos = this.items[code]  
  },
  showNewPromissoryNote(data) {
    this.promissoryNoteDialog = true;
    this.promissoryNoteCustomer = data.KUNNR;
    this.promissoryNoteCustomerName = data.NAME1;
  },
  cancelAddPromissoryNote() {
    this.promissoryNoteDialog = false;
    this.promissoryNoteCustomer = null;
    this.promissoryNoteCustomerName = null;
  },
  showPhoto(photos){
    this.index = 0     
    let urlServer = process.env.VUE_APP_FILES_URL;
    this.photos = []; 
    const photoMappings = [
        { key: 'facade_photo', description: 'Fachada', title: 'Fachada' },
        { key: 'display_photo', description: 'Exhibición', title: 'Exhibición' },
        { key: 'left_display_photo', description: 'Exhibición Izquierda', title: 'Exhibición Izquierda' },
        { key: 'right_display_photo', description: 'Exhibición Derecha', title: 'Exhibición Derecha' },
        { key: 'indoor_photo', description: 'Exhibición central', title: 'Exhibición central' },
        { key: 'bar_photo', description: 'Mostrador/Bodega', title: 'Bodega' },
    ]; 
photoMappings.forEach(photo => {
    if (photos?.[photo.key]) {
        this.photos.push({
            description: photo.description,
            title: photo.title,
            src: urlServer + '/' + photos[photo.key],
            });
        }
    });  
  }
}, 
})
</script>


<style scoped src="../../../../public/css/seller.css"></style>