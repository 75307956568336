<template>
    <!-- Menu Print -->
    <div class="btn-print ml-4">
        <v-icon class="btn-print-icon" @click="closePrint = false">
            mdi mdi-printer-outline
        </v-icon>

        <div class="modal-print elevation-2" v-if="!closePrint">
            <div style="position:relative; width:100%">
                <span style="position:absolute; right: -15px; top:-10px">
                    <v-icon color="red" @click="closePrint = true">
                        mdi mdi-close-circle
                    </v-icon>
                </span>
            </div>
            <v-row>
                <v-col>
                    <p class="mb-2"><b>Descargar en:</b></p>   
                    <v-btn-toggle>                                
                        <v-btn v-if="existExcel" class="btns-print-group " outlined color="grey" @click="closePrint=true">
                            <vue-json-to-csv :show-labels="showLabels" :json-data="itemCSV" :csv-title="nameCSV">                                            
                                Excel
                                <v-icon color="green" class="ml-2" small>mdi mdi-file-download</v-icon>            
                            </vue-json-to-csv> 
                        </v-btn>                                     
                        <v-btn class="btns-print-group" outlined color="grey" @click="closePrint=true; $emit('generarDocumento')">
                            PDF
                            <v-icon color="red" class="ml-2" small>mdi mdi-file-download</v-icon>
                        </v-btn>                        
                    </v-btn-toggle>
                </v-col>
            </v-row>
        </div>
    </div>
    <!-- End Menu Print -->
</template>

<script>
import VueJsonToCsv from 'vue-json-to-csv'
export default {
    props: {
        itemCSV: Array,
        nameCSV: String,
        existExcel: {
            type: Boolean,
            default: true,
            required: false
        },
        showLabels: {
            type: Boolean,
            default: true,
            required: false
        }
    },
    components: {VueJsonToCsv},
    data(){
        return{
            closePrint: true
        }
    }
}
</script>

<style>
    .btn-print{
        cursor: pointer;
        display: block;
        position: relative;
    }
    .btn-print .btn-print-icon {
        font-size: 30px !important;
        color: black  !important;
    } 
    .modal-print{
        position: absolute !important;
        bottom: -105px !important;
        right: 0 !important;
        background: #f8f8f8;
        padding: 15px 25px;
        font-size: 13px;
        z-index: 10;
        min-width: 200px;
    }
    .btns-print-group{        
        font-size: 12px !important;
        height: 40px !important;
        width: 100px;
    }
</style>