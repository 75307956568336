<template>
  <v-container fluid fill-height style="background-color: #ddd">
    <v-row align="center" justify="center">
      <v-col col="12" md="8">
        
        <div v-if="hasError" class="alert alert-danger" role="alert">
          {{ msg }}
        </div>

        <v-card class="elevation-10 pa-5 pa-md-10 rounded-xl">
          <v-card-text>
            <iusa-logo lg/>

            <h6 class="my-5">
              <v-icon color="black">mdi-lock</v-icon>
              <strong>Restablecimiento de Contraseña</strong>
            </h6>

            <div v-if="!send">
              <p class="mt-5">
                Pon tu <strong>nombre de usuario de IUSA</strong> en línea o
                la dirección de <strong>correo electrónico</strong> que
                usaste para registrarte. Te enviaremos un mensaje con tu
                nombre de usuario y un enlace para restablecer tu
                contraseña.
              </p>

              <v-form @submit.prevent="submit" :disabled="loading">
                <v-row justify="center">
                  <v-col col="12" md="8" class="text-left">
                    <v-text-field
                      v-model="form.username"
                      label="Usuario / Email"
                      placeholder="Ingrese Usuario / Email" 
                      type="text" 
                      autofocus
                      required 
                    />

                    <div class="d-flex justify-center">
                      <vue-recaptcha 
                        v-if="!recaptchaInDevMode"
                        :sitekey="$googleSitekey"
                        @verify="onVerify" 
                        @expired="onExpired"
                      />
                    </div>
                  
                    <v-btn 
                      type="submit" 
                      color="#2fd410" 
                      class="text-capitalize white--text mt-4"
                      rounded
                      block 
                      :disabled="loading"
                    >
                      {{ loading ? "Validando..." : "Enviar" }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
                
               <help-banner class="mt-7"/>
            </div>

            <div v-else>
              <p class="mt-5 text-center">
                Se te ha enviado un mensaje por
                <strong>correo electrónico</strong> con instrucciones sobre
                cómo restablecer tu contraseña.
              </p>
              <img src="img/mailing-password.svg" alt="" />
              <div class="mt-4">
                <router-link to='/' class="font-weight-bold success--text">Ir a login.</router-link>
              </div>
            </div>
          </v-card-text>
        </v-card>

        <!-- Alerts -->
        <v-snackbar v-model="snackbar" :timeout="timeout">
          {{ msg }}
          <template v-slot:action="{ attrs }">
            <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

import recaptcha from '@/mixins/recaptcha';
import VueRecaptcha from "vue-recaptcha";
import IusaLogo from '@/components/IusaLogo';
import HelpBanner from '@/components/HelpBanner';

export default {
  name: "PasswordReset",

  components: {
    VueRecaptcha,
    IusaLogo,
    HelpBanner
  },

  mixins: [recaptcha],

  data() {
    return {
      form: {},
      loading: false,
      send: false,
      hasError: false,
      msg: null,
      snackbar: false,
      timeout: 8000,
    };
  },

  methods: {
    async submit() {
      this.loading = true;

      if (this.recaptchaVerified) {
        try {
          await axios.post(`${process.env.VUE_APP_BASE_SERVER}/auth/send-email-password-recovery`, this.form)
          this.send = true;
          this.form.username = null;
          this.recaptcha = null;
          this.goHome();
        } catch ({ response }) {
          if(response?.status === 400 || response?.status === 404)
            this.showError(response.data.message)
          else 
            this.showError('Hubo un error al procesar tu solicitud')
        }

      } else {
        this.showError('El campo CAPTCHA es obligatorio.')
      }

      this.loading = false;
    },

    showError(msg) {
      this.hasError = true;
      this.snackbar = true;
      this.msg = msg;

      setTimeout(() => {
        this.hasError = false;
      }, this.timeout);
    },

    goHome() {
      setTimeout(() => {
        this.$router.replace('/')
      }, 7000);
    }
  },
};
</script>