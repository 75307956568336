<template>
  <div>
    <div class="text-left pa-1 pl-3" style="background-color: #000; color:hsl(0, 0%, 100%)">
      Detalle de Crecimiento constante del cliente {{ currentCustomer }} del mes de {{currentDate}} 
    </div>
    <v-simple-table dense>
      <template v-slot:default>
        <thead class="">
          <tr class="custom-table-bordered ">
            <th class="text-center text-body-1 text-dark" colspan="7"><b>Facturación actual</b></th>
          </tr>
          <tr class="custom-table-bordered ">
            <td class="text-center table-heading text-body-2 ">Mes</td>
            <td class="text-center table-heading text-body-2 ">Facturación</td>
          </tr>
        </thead>
        <tbody v-show="!loading"   class="custom-table-bordered">
          <tr v-for="(item, index) in billing.FACACTUAL?.FAC_ACTUAL" :key="index">
            <td :style="index%2==0 ?'background-color: #fff;':''" class="table-heading small text-body-2">{{ formatDate(item.SPMON) }}</td>
            <td :style="index%2==0 ?'background-color: #fff;':''" class="table-heading text-body-2"> ${{number_format(item.FACNETA) }}</td>
          </tr>  
          <tr style="">
            <td colspan="2" style="background-color: #fff;-webkit-box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.75);
-moz-box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.75);
box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.75)" class="table-heading small text-body-2">
              <v-row>
                <v-col cols="6" class="pa-3">
                  <b class="ml-n9">Total=</b>
                </v-col>
                <v-col cols="6">
                  <b class="ml-n9"> ${{ number_format(billing.TOTACT) }}</b>
                </v-col>
              </v-row>
            </td>
          </tr> 
        </tbody>
        <tbody v-if="loading">
          <tr>
            <td colspan="7"> <v-progress-linear class="mt-3" indeterminate color="teal"></v-progress-linear> <span
                class="text--disabled"> Cargando información</span></td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-simple-table dense>
      <template v-slot:default>
        <thead class="">
          <tr class="custom-table-bordered ">
            <th class="text-center text-body-1 text-dark" colspan="7"><b>Facturación Anterior</b></th>
          </tr>
          <tr class="custom-table-bordered ">
            <td class="text-center table-heading text-body-2 ">Mes</td>
            <td class="text-center table-heading text-body-2 ">Facturación</td>
          </tr>
        </thead>
        <tbody v-if="!loading" class="custom-table-bordered">
          <tr v-for="(item, index) in billing.FACANT?.FAC_ANT" :key="index">
            <td :style="index%2==0 ?'background-color: #fff;':''" class="table-heading small text-body-2">{{ formatDate(item.SPMON) }}</td>
            <td :style="index%2==0 ?'background-color: #fff;':''" class="table-heading text-body-2"> ${{number_format(item.FACNETA) }}</td>
          </tr> 
          <tr>
            <td colspan="2" style="background-color: #fff;-webkit-box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.75);
-moz-box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.75);
box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.75)" class="table-heading small text-body-2">
              <v-row>
                <v-col cols="6" class="pa-3">
                  <b class="ml-n9">Total=</b>
                </v-col>
                <v-col cols="6">
                  <b class="ml-n9"> ${{ number_format(billing.TOTANT) }} </b>
                </v-col>
              </v-row>
            </td>
          </tr>  
        </tbody> 
      </template>
    </v-simple-table>
    <v-row v-if="!loading"> 
                <v-col cols="6" class="pa-3">
                  <b class="ml-n9">Crecimiento=</b>
                </v-col>
                <v-col cols="6">
                  <b class="ml-n9"> {{ number_format(billing.PORCRE,false) }}% </b>
                </v-col>
                
    </v-row>
    
  </div>
</template>
<script>

import axios from "@/plugins/axios";
import helpers from "@/mixins/helpers";
export default ({
  watch: {
    currentCustomer(value) {
      this.getPaymentInfo(value)
    }
  },
  props: {
    currentCustomer: {
      default: 0
    },
  },
  computed:{
    currentDate(){
       const date = new Date();      
      let month = date.getMonth(); 
      let year = date.getFullYear(); 
      return this.monthNames[month]+" "+year;
    },
    monthNames(){
      return ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
      "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ];

    }
  },
  mixins:[helpers],
  name: "ComulativeClientGrowth",
  data() {
    return {
      historicos: [],
      loading: false,
      billing: []
    }
  },
  mounted() {
    this.getPaymentInfo(this.currentCustomer);
  },
  methods: {
    getPaymentInfo(customer) {
      this.loading = true;
      axios
        .post("seller/home/comulative-client-growth", {
          customer_id: customer,
        })
        .then((response) => {
          if (response.data?.success == false) {
            console.log(response.data)
          } else { 
            this.billing = response.data;  
            this.billing.FACACTUAL.FAC_ACTUAL =  
             ('SPMON' in this.billing.FACACTUAL.FAC_ACTUAL) ?  //Cuando viene un item. Convertirlo en array con index 0
            [this.billing.FACACTUAL.FAC_ACTUAL]:this.billing.FACACTUAL.FAC_ACTUAL 
            this.billing.FACANT.FAC_ANT =  
             ('SPMON' in this.billing.FACANT.FAC_ANT) ?  //Cuando viene un item. Convertirlo en array con index 0
            [this.billing.FACANT.FAC_ANT]:this.billing.FACANT.FAC_ANT 
          }
          this.loading = false;
        }).finally(() => {
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
        },
      numberformat(number = 0) {
      let result = Number(number).toFixed(2);
      return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    formatDate(value){
      const data = value.toString();
      const year= data.slice(0,4);
      const month= data.slice(-2);
      return month+"/"+year;
    },
    closeModal(){
      this.$emit('closeModal',true)
    }
    },
  
  
})
</script>
 <style scoped> 

</style>