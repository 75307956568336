<template>
  <v-overlay v-if="!boxOverlay" :value="show">
    <v-progress-circular v-if="type == 'circular'" class="mb-2" indeterminate size="64" />
    <v-progress-linear v-else-if="type == 'linear'" class="mb-2" color="white" indeterminate />
    <slot><div>{{ text }}</div></slot>
  </v-overlay>

  <v-dialog v-else :value="show" :hide-overlay="hideBackgroundTransparency" persistent width="300">
    <v-card color="primary" dark>
      <v-card-text class="pt-0 pb-2">
        <slot><div class="py-2">{{ text }}</div></slot>
        <v-progress-circular v-if="type == 'circular'" class="mb-2" indeterminate size="64" />
        <v-progress-linear v-else-if="type == 'linear'" color="white" indeterminate />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    
    show: {
      type: Boolean,
      default: true,
    },

    type: {
      type: String,
      default: '' // circular, linear
    },

    text: {
      type: String,
      default: ''
    },

    boxOverlay: Boolean,
    hideBackgroundTransparency: Boolean
  },
}
</script>