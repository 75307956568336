<template>
  <div>
    <div class="d-flex align-center my-3">
      <h2><b>Productos IUSA </b></h2>
      <h4 class="ml-1">/ Más de 4,200 productos</h4>
    </div>
    <div class="d-flex" v-if="loading">
      <v-skeleton-loader
        class="mx-1"
        width="300"
        type="card"
      ></v-skeleton-loader>
      <v-skeleton-loader
        class="mx-1"
        width="300"
        type="card"
      ></v-skeleton-loader>
      <v-skeleton-loader
        class="mx-1"
        width="300"
        type="card"
      ></v-skeleton-loader>
      <v-skeleton-loader
        class="mx-1"
        width="300"
        type="card"
      ></v-skeleton-loader>
    </div>
    <v-slide-group active-class="success" show-arrows v-else>
      <v-slide-item
        v-for="(item, data) in items_product"
        :key="data"
        style="margin: 10px !important"
      >
        <v-card
          class="mx-auto pedidos-card-sugerencias"
          max-width="280"
          style="text-align: left" elevation="0"
        >
          <v-card-subtitle class="text-subtitle-1 font-weight-black" style="height: 8em">{{ item.name }}</v-card-subtitle>
          <v-card-text>
            <div class="w-full d-flex flex-column justify-start">
              <v-img :src="item.image" height="200px" class="mx-auto"></v-img>
              <p class="text-subtitle-1 text-center">SKU: {{ item.sku }} </p>
            </div>
          </v-card-text>
        </v-card>
      </v-slide-item>
    </v-slide-group>
  </div>
</template>

<script>
import axios from "@/plugins/axios";
export default {
  name: "CustomerProducts",

  data() {
    return {
      loading: true,
      entradas: [],
      items_product: [],
    };
  },
  async mounted() {
    this.getImages();
  },
  methods: {
    getImages() {
      axios
        .post("randomCart", {
          //VTWEG: this.user.VTWEG,
          VTWEG: "DT",
        })
        .then((response) => {
          this.loading = false;
          this.items_product = response.data.map((element) => ({
            image: element.imagen_url,
            name: element.nombre,
            sku: element.material,
            measures: element.medidas,
          }));
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
  },
};
</script>

<style>
.v-slide-group__prev {
  position: absolute;
  left: 1px;
  z-index: 10;
  height: 100%;
}
.v-slide-group__next {
  position: absolute;
  right: 1px;
  z-index: 10;
  height: 100%;
}

.v-slide-group__prev > i, .v-slide-group__next > i {
  font-weight: 900 !important;
  font-size: 2.5em !important;
}
.theme--light.v-icon.v-icon.v-icon--disabled {
  color: #000 !important;
}
</style>