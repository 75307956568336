import { render, staticRenderFns } from "./UserNotificationForm.vue?vue&type=template&id=b74fd646&scoped=true&"
import script from "./UserNotificationForm.vue?vue&type=script&lang=js&"
export * from "./UserNotificationForm.vue?vue&type=script&lang=js&"
import style0 from "./UserNotificationForm.vue?vue&type=style&index=0&id=b74fd646&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b74fd646",
  null
  
)

export default component.exports