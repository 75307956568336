<template>
  <div>
    <v-row>
      <v-col cols="12" sm="4" md="4">
        <div class="col-12 row">
          <div class="col-6">
            <v-avatar cols="6" size="120" style="border: solid 2px #ff000a">
              <img
                src="img/icon_default_user.svg"
                alt="IUSA"
                style="width: 90%; height: 90%"
              />
            </v-avatar>
          </div>
          <div class="col-6" style="align-items: left">
            <span style="text-align: left; font-weight: 800; color: #000">
              Cliente: {{ customerInfo.NAME1 }}
            </span>
            <br /><br />
            <span style="text-align: left; font-weight: 400; color: #000">
              ID: {{ customerInfo.KUNNR }}
            </span>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="4" md="4">
        <v-card-subtitle class="pa-lg-2">
          <p style="text-align: center">
            LIMITE DE CRÉDITO:
            <span style="font-weight: 800">${{ customerInfo.KLIMK }}</span>
          </p>
          <v-progress-linear v-model="porcentaje" height="25">
            <strong style="color: #fff">{{ porcentaje }}%</strong>
          </v-progress-linear>
          <v-row>
            <v-col cols="6" sm="12" md="6">
              <span style="font-size: 12px"
                >SALDO AL CORTE:
                <span style="font-weight: 800; font-size: 14px"
                  >${{ customerInfo.SKFOR }}</span
                ></span
              >
            </v-col>
            <v-col cols="6" sm="12" md="6">
              <span style="font-size: 12px"
                >CREDITO DISPONIBLE:
                <span style="font-weight: 800; font-size: 14px"
                  >${{ customerInfo.CREDD }}
                </span></span
              >
            </v-col>
          </v-row>
          <p></p>
        </v-card-subtitle>
      </v-col>
      <v-col cols="12" sm="4" md="4">
        <v-card-subtitle>
          <div style="text-align: center">
            <span style="font-size: 28px">$ {{ formatPrice(total) }}</span>
            <br />
            MONTO TOTAL DEL PEDIDO
          </div>
          <!-- CREDITO DISPONIBLE:
                {{ parseFloat(customerInfo.CREDD) }} -->
        </v-card-subtitle>
      </v-col>
    </v-row>
    <v-alert
      type="info"
      dismissible
      v-if="customerInfo.AUFSD == 1"
    >
      <v-row align="center">
        <v-col class="grow">
          <span style="font-weight: 700; font-size: 16px"
            >Estimado cliente usted tiene un bloqueo de partidas vencidas, favor
            de realizar su pago en línea <router-link
                :to="{ name: 'AccountStatus' }"
                style="color:#fff;"
              > aquí. </router-link>Se generará su pedido.</span
          >
        </v-col>
        <!-- <v-col class="shrink">
                <v-btn>Take action</v-btn>
            </v-col> -->
      </v-row>
      <v-alert
      icon="mdi-close-octagon"
      prominent
      type="error"
      dismissible
      v-if="total > this.aviable || catchTotal > this.aviable"
    >
      <v-row align="center">
        <v-col class="grow">
          <span style="font-weight: 700; font-size: 16px"
            >Ha superado el Limite de crédito, El pedido será bloqueado por crédito</span
          >
        </v-col>
        <!-- <v-col class="shrink">
                <v-btn>Take action</v-btn>
            </v-col> -->
      </v-row>
    </v-alert>
    </v-alert>
    <v-alert
      icon="mdi-close-octagon"
      prominent
      type="error"
      dismissible
      v-if="customerInfo.DOCVENC  > 1 "
    >
      <v-row align="center">
        <v-col class="grow">
          <span style="font-weight: 700; font-size: 16px"
            >El pedido será bloqueado por crédito</span
          >
        </v-col>
        <!-- Si los DOCVENC es mayor a 1 se envia alerta de que sera bloeueado por credito-->
      </v-row>
    </v-alert>
  </div>
</template>
<script>
import axios from "@/plugins/axios";
import { mapGetters } from "vuex";
export default {
  name: "CustomerInfo",
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  props: {
    catchTotal: {
      type: Number,
      default: null,
    },
    warnLock: {
      type: Function,
      default: null,
    },
    purValidation:{
      type: Function,
      default: null
    },
  },
  data: () => ({
    customerInfo: [],
    total: 0,
    porcentaje: 0,
    purshaseVal: null,
  }),
  async mounted() {
    let self = this;
    self.getInfoCustomer();
  },
  methods: {
    getInfoCustomer() {
      axios
        .post("infoCustomer", {
          customer: this.user.custumer_code,
          VKORG: this.user.VKORG,
          VTWEG: this.user.VTWEG,
          VKBUR: this.user.VKBUR,
        })
        .then((response) => {
          this.customerInfo = response.data;
          this.saldo = this.customerInfo.SKFOR.replace(/,/g, "");
          this.limite = this.customerInfo.KLIMK.replace(/,/g, "");
          this.porcentaje =
            (parseInt(this.saldo) * 100) / parseInt(this.limite);
          this.porcentaje = parseInt(this.porcentaje);
          this.warnLock(this.customerInfo.AUFSD);
          this.purValidation(this.customerInfo.DOCVENC);
        })
        .catch((error) => {
          //alert(error);
          console.log(error);
        })
        .finally();
    },
    formatPrice(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
  watch: {
    catchTotal: function (val) {
      this.total = val;
    },
    purValidation: function(val) {
       this.purshaseVal = val;
    },
  },
};
</script>
