<template>
    <div>
        <v-simple-table>
                  <template v-slot:default>
                    <thead class="table-heading"> 
                      <tr>
                        <th class="text-center text-dark"><b>Dirección</b>  </th>
                        <th class="text-center text-dark"><b>Cierre semana<br />anterior</b>  </th>
                        <th class="text-center text-dark" style="border-width: 0px 2px 0px 0px"><b>%&nbsp;de&nbsp;crecimiento<br />semana&nbsp;anterior</b></th>
                        <th class="text-center text-danger"><b>Promedio <br>Base&nbsp;semanal&nbsp;de<br>las&nbsp;ultimas&nbsp;12&nbsp;semanas</b></th>
                        <th class="text-center text-dark"><b>Facturación&nbsp;bruta<br>PNC&nbsp;semana actual</b></th>
                        <th class="text-center text-dark"><b>Facturación&nbsp;bruta<br />PNC&nbsp;+&nbsp;PPR<br>semana&nbsp;actual</b></th>
                        <th class="text-center text-danger"><b>Promedio&nbsp;últimas<br />3&nbsp;semanas&nbsp;+<br>actual <br>(PNC + PPR)</b></th>
                        <th class="text-center text-dark"><b>%&nbsp;de&nbsp;crecimiento&nbsp;</b></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="!loading">
                        <td><b>{{chnl == 'PR'? 'Privados':'Minorista'}}</b></td>
                        <td><b>{{formatNumber(weeklyBillingSummaryData.CSEMANT,"$",0)}}</b></td>
                        <td style="border-width: 0px 1px 0px 0px"><b>{{formatFloat(weeklyBillingSummaryData.PCSEMANT,2)}}%</b></td>
                        <td>{{formatNumber(weeklyBillingSummaryData.PBSEM12,"$",0)}}</td>
                        <td>{{formatNumber(weeklyBillingSummaryData.FBPNC,"$",0)}}</td> 
                        <td>{{formatNumber(weeklyBillingSummaryData.PSEMPNC,"$",0)}}</td>
                        <td>{{formatNumber(weeklyBillingSummaryData.PBSEM3 ?? 0 ,"$",0)}}</td> 
                        <td><b>{{formatFloat(weeklyBillingSummaryData.PCREC,2)}}%</b> 
                          <a @click="showModal(1)"> <v-icon color="red" size="15">mdi-plus-circle-outline</v-icon> 
                          </a>
                        </td>
                      </tr>
                      <tr v-else>
                        <td colspan="8">
                          <v-progress-linear indeterminate color="cyan" />
                        </td>
                      </tr>
                    </tbody>
                  </template>
        </v-simple-table>
        
        <v-simple-table class="my-t">
                <template v-slot:default>
                  <thead class="table-heading">
                    <tr class="custom-ligth-row">  
                      <th colspan="13" class="text-center pt-1">
                        <div class="d-flex justify-center">
                          <b class="pr-8 pl-8 text-danger " >Datos generales de grupos por canal</b>
                        </div> 
                      </th>  
                    </tr>
                    <tr class="custom-ligth-row ">  
                      <th colspan="6"></th>
                      <th colspan="7" class="text-center">
                        <div class="d-flex justify-center mt-n3 mb-n5">
                          <b class="pr-8 pl-8 text-dark " >Facturación neta por trimestre (Miles)</b>
                        </div> 
                      </th>  
                    </tr>
                    <tr>
                      <th class="text-center text-dark"><b>Canal</b>  </th>
                      <th class="text-center text-dark"><b>Números de <br />grupos</b>  </th>
                      <th class="text-center text-dark"><b>Pedidos totales<br>del&nbsp;mes&nbsp;(miles)</b></th>
                      <th class="text-center text-dark"><b>Centros&nbsp;de <br>suministro</b></th>
                      <th class="text-center text-dark"><b>Crecimiento<br>acumulado<br>de&nbsp;grupo</b></th>
                      <th class="text-center text-dark"><b>Crecimiento&nbsp;<br>3&nbsp;vs&nbsp;3<br>&nbsp;de&nbsp;grupo</b></th>
                      <th class="text-center text-dark" v-for="(item,index) in quarters" :key="index">
                        <b v-html=" index == quarters.length - 1 ? `Proyección <br> ${item.date}`: item.date"></b></th> 
                      </tr>
                  </thead>
                  <tbody>
                    <tr  v-if="!loadingOverview">
                      <td class="text-decoration-underline"> Datos generales <br> de grupos </td>
                      <td>{{formatFloat(groupOverviewData.TOTGRP,0)}}</td>
                      <td>{{formatNumber(groupOverviewData.CENSUM,"$",0)}}</td>
                      <td>{{formatFloat(groupOverviewData.CENSUM,0)}}</td>
                      <td> <a @click="showModal(2)" class="text-decoration-underline text-dark">{{formatFloat(groupOverviewData.CREACU,2)}}%</a></td>
                      <td> <a @click="showModal(2)" class="text-decoration-underline text-dark">{{formatFloat(groupOverviewData.CREC3X3,2)}}%</a></td> 
                      <td>{{formatNumber(groupOverviewData.FACNETA1,"$",0)}}</td>
                      <td>{{formatNumber(groupOverviewData.FACNETA2,"$",0)}}</td>
                      <td>{{formatNumber(groupOverviewData.FACNETA3,"$",0)}}</td>
                      <td>{{formatNumber(groupOverviewData.FACNETA4,"$",0)}}</td>
                      <td>{{formatNumber(groupOverviewData.FACNETA5,"$",0)}}</td>
                      <td>{{formatNumber(groupOverviewData.FACNETA6,"$",0)}}</td>
                      <td>{{formatNumber(groupOverviewData.PROYECC,"$",0)}}</td>
                    </tr>
                    <tr v-else>
                        <td colspan="8">
                          <v-progress-linear indeterminate color="cyan" />
                        </td>
                      </tr>
                  </tbody>
                </template>
              </v-simple-table>

              <v-dialog  v-model="modal" width="auto">
                <v-card class="pb-0"> 
                <customer-per-week  v-if="showCustomerPerWeek"  v-on:closeModal="closeModal" />
                <cumulative-growth v-if="showCumulativeGrowth"   v-on:closeModal="closeModal" /> 
                </v-card>
              </v-dialog> 
    </div> 
</template>
<script>
import CustomerPerWeek from './modals/CustomerPerWeek.vue';
import CumulativeGrowth from './modals/CumulativeGrowth.vue';
import axios from '@/plugins/axios'
import numbersFormats from '@/mixins/numbersFormats';
import service from '@/mixins/service';


export default({
    name:'AverageOrders',
    components:{ 
      CustomerPerWeek,
      CumulativeGrowth
    },  
    mixins:[numbersFormats,service],
    props: {
      chnl: { 
        required:true,
        String
      },
      regionalManager:{
        required:true,
      }
    },
    data: () => ({
      showCustomerPerWeek: false, 
      modal:false,
      showCumulativeGrowth:false,
      weeklyBillingSummaryData:[],
      groupOverviewData:[],
      loadingOverview:true,
      loading:true,
      quarters:[],
  }),
  methods:{
   async weeklyBillingSummary(){
      this.loading=true;
        try { 
          var params = { 
            channel: this.chnl
          }
          if (this.regionalManager) params.rgManager = this.regionalManager;
          const response = await axios.get("/daily-director/weekly-billing-summary-management", {
                params:params
              });
          this.weeklyBillingSummaryData = response.data;
        } catch (error) {
          this.error = "No se pudo obtener la información";
          this.loading=false;
        } 
        this.loading=false;
    },

    async groupOverview(){
      this.loadingOverview=true;
      var params = {
            channel : this.chnl
          }
          if (this.regionalManager) params.rgManager = this.regionalManager;
        try { 
          const response = await axios.get("/daily-director/group-overview", {
                params: params
              });
              
          this.groupOverviewData = response.data;
        } catch (error) {
          this.error = "No se pudo obtener la información";
          this.loadingOverview=false;
        } 
        this.loadingOverview=false;
    },

    closeModal() {
      this.modal=false;
      this.showCumulativeGrowth=false;
      this.showCustomerPerWeek = false;
    },
    showModal(action){
        this.modal=true;
        switch (action) {
            case 1: 
            this.showCustomerPerWeek=true; 
            this.showCumulativeGrowth=false; 
            break;
            case 2: 
            this.showCumulativeGrowth=true; 
            this.showCustomerPerWeek=false;
            break; 
        }
    }
  },
  mounted(){
    this.weeklyBillingSummary();
    this.groupOverview()
  },
  created(){
    this.quarters = this.calcularUltimosTrim(7).reverse()
  }
})
</script>
<style scoped src="../../../../../public/css/managercustom.css"></style>