<template>
  <div>

    <v-container fluid class="white statement__container">

        <div class="d-flex align-items-center">            
            <v-card class="mx-auto rounded-lg elevation-4 ma-3 w-100" outlined :style="'border: 4px solid black; background-color:transparent;' ">
                <v-list-item-content>
                    <div class="text-general-module mb-0">
                      <v-icon color="black">mdi-account-group</v-icon>
                        RESUMEN DE GRUPO
                    </div>
                </v-list-item-content>
            </v-card>            
        </div>
      
      <!-- Menu -->
      <ButtonsrGroupCustomer :opt="opt"></ButtonsrGroupCustomer>

      <!-- Datos del cliente con grupo o sin grupo -->
      <DataCustomer class="mt-5" :dataClient="dataClient" :loader="loadingDataClient">
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-icon @click="participationDivision(0)" v-bind="attrs"
                v-on="on" color="#B71C1C">mdi-chart-pie</v-icon> 
            </template>
            <span>Participación / Divisiones</span>
        </v-tooltip>
      </DataCustomer>
      
      <!-- Table Rumen Grupo -->
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12">         
            <v-simple-table class="my-t elevation-2">
              <template v-slot:default>
                <thead class="h-title">
                  <tr>
                    <th colspan="14" class="text-center" style="height: 40px !important">Resumen de grupo</th>
                  </tr>
                </thead>
                <thead class="h-title">
                  <tr>
                    <th colspan="5"></th>
                    <th class="text-center p-0 m-0 pt-1" style="height: 30px" colspan="2">Pagos del trimestre</th>
                    <th class="text-center p-0 m-0 pt-1" style="height: 30px" colspan="6">Facturación por trimestre</th>
                    <th class="text-center p-0 m-0 pt-1" style="height: 30px; color: red;">Proyección</th>
                  </tr>
                  <tr>                      
                    <th class="text-center p-1">Número de clientes</th>
                    <th class="text-center p-1">% Cliente más grande</th>
                    <th class="text-center p-1">Pedido de {{ monthCurrent }}</th>
                    <th class="text-center p-1">Crec. Acum. del Grupo</th>
                    <th class="text-center p-1">Crec. 3 vs 3 del Grupo</th>
                    <th class="text-center p-1" v-for="(trim, index) in arrayTrimestres.slice(-2)" :key="'pagos_trim_' + index">
                      {{ trim.date }}                      
                    </th>
                    <th style="width: 100px !important" class="text-center" v-for="(trim, index) in arrayTrimestres" :key="'trim_' + index">
                      {{ trim.date }}
                    </th>
                  </tr>
                </thead>
                <tbody class="sin-border">                  
                  <tr v-if="loadingDataResumenGroup || loadingDataResumenGroup">
                    <td colspan="14" style="padding: 0 !important; margin: 0 !important; background: #f5f5f5 !important;">
                      <v-progress-linear
                        indeterminate
                        color="cyan"
                      ></v-progress-linear>
                     <p class="mb-0 py-2">Cargando... Por favor espere</p>
                    </td>
                  </tr>
                  <tr v-else>
                    <td>{{ dataResumenGroup.TOTCTE }}</td>
                    <td><span class="amount-positive">{{ formatPor( dataResumenGroup.PORCTE ) }}%</span></td>
                    <td>${{ formatPrice( dataResumenGroup.TOTMAC ) }}</td>
                    <td>
                      <a @click="crecimientoAcumulado(clientIdCurrent, 1)" class="amount-positive" href="#">{{ formatPor(dataResumenGroup.CREACU) }}%</a>
                    </td>
                    <td>
                      <a @click="detail3vs3(clientIdCurrent, 1)" class="amount-positive" href="#">{{ formatPor(dataResumenGroup.CRE3X3) }}%</a>
                    </td>
                    <td>
                      ${{ formatPrice( dataResumenGroup.PAGTRI ) }}                      
                    </td>
                    <td>
                      ${{ formatPrice( dataResumenGroup.PAGTRI2 ) }}
                    </td> 
                    <td style="width: 100px !important" v-for="(factura, index) in dataResumenGroup.FACTRIM.FACTRIM.slice().reverse()" :key="'fact_trim_' + index">
                      ${{ formatPrice(factura.FACNETA) }}
                    </td>  
                    <td>${{ formatPrice(dataResumenGroup.proyeccion) }}</td>   
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
        </v-col>
      </v-row>
      <!--end Table Rumen Grupo -->

      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12">
          
          <v-simple-table class="elevation-2 my-t">
            <template v-slot:default>
              <thead class="h-title">
                <tr>
                  <th colspan="14" class="text-center" style="height: 40px !important">Clientes del grupo</th>
                </tr>
              </thead>
              <thead class="h-title">
                <tr>
                  <th colspan="5"></th>
                  <th class="text-center p-0 m-0 pt-1" style="height: 30px" colspan="2">Pagos del trimestre</th>
                  <th class="text-center p-0 m-0 pt-1" style="height: 30px" colspan="6">Facturación Neta Por trimestre/Renglonaje por trimestre</th>
                  <th class="text-center p-0 m-0 pt-1" style="height: 30px; color: red;">Proyección</th>
                </tr>
                <tr>                      
                  <th class="text-center p-1">Número de clientes</th>
                  <th class="text-center p-1">Rotulado</th>
                  <th class="text-center p-1">Pedido de {{ monthCurrent }}</th>
                  <th class="text-center p-1">Crec. Acum. del Grupo</th>
                  <th class="text-center p-1">Crec. 3 vs 3 del Grupo</th>
                  <th class="text-center p-1" v-for="(trim, index) in arrayTrimestres.slice(-2)" :key="'trim_customer' + index">
                      {{ trim.date }}                      
                    </th>                  
                  <th style="width: 100px !important" class="text-center" v-for="trim, index in arrayTrimestres" :key="index">
                      {{ trim.date }}
                    </th>
                </tr>
              </thead>
              <tbody class="sin-border">
                <template v-if="loadingDataClientGroup || loadingDataResumenGroup">
                  <tr>
                    <td colspan="14" style="padding: 0 !important; margin: 0 !important; background: #f5f5f5 !important;">
                      <v-progress-linear
                        indeterminate
                        color="cyan"
                      ></v-progress-linear>
                      <p class="mb-0 py-2">Cargando... Por favor espere</p>
                    </td>
                  </tr>
                </template>
                <template v-if="!loadingDataClientGroup && dataClientGroup.length == 0">
                  <tr>
                    <td colspan="14">
                      Sin clientes de grupo
                    </td>
                  </tr>
                </template>
                <template v-if="!loadingDataClientGroup && !loadingDataResumenGroup">
                  <tr v-for="dcg in dataClientGroup" :key="dcg.KUNAG">
                      <td style="text-align: left;">
                        <div class="d-flex align-items-center justify-content-between">
                          <p class="mb-0">{{ formatCustomer(dcg.KUNAG) }} {{ dcg.NAME1 }}</p>                          
                          <img v-if="dcg.KUNAGP == '1'" src="/img/icon-start.svg">
                        </div>
                      </td>
                      <td>
                        <img src="/img/logo_iusa_gray.svg" class="mr-1" width="20px" />
                        <v-tooltip bottom v-if="dcg.photos != null">
                            <template v-slot:activator="{ on, attrs }">
                              <a v-bind="attrs" v-on="on" @click="sendPhoto(dcg.photos)">
                                  <v-icon >mdi-camera</v-icon> 
                              </a>                          
                            </template>
                            <span >Ver fotos del cliente</span>
                        </v-tooltip> 
                        <v-tooltip bottom v-else>
                            <template v-slot:activator="{ on, attrs }">
                              <a v-bind="attrs" v-on="on">
                                  <v-icon :color="'#CFD8DC'">mdi-camera</v-icon> 
                              </a>                          
                            </template>
                            <span >No hay fotos del cliente</span>
                        </v-tooltip>
                      </td>
                      <td>${{ formatPrice( dcg.ORDER ) }}</td>
                      <td><a @click="crecimientoAcumulado(dcg.KUNAG, 2)"  class="amount-positive" href="#">{{ formatPor(dcg.CREACU) }}%</a></td>
                      <td><a @click="detail3vs3(dcg.KUNAG, 2)"  class="amount-positive" href="#">{{ formatPor(dcg.CRE3X3) }}%</a></td>
                      <td>${{ formatPrice( dcg.PAGTRI ) }}</td>
                      <td>${{ formatPrice( dcg.PAGTRI2 ) }}</td> 
                      <td style="width: 100px !important" v-for="(factura, index) in dcg.FACTRIM.FACTRIM.slice().reverse()" :key="'fact_trim_customer' + index">
                        <div class="d-flex justify-content-center">
                          <div style="display: table-cell">
                            <p class="mb-0 text-right">${{ formatPrice(factura.FACNETA) }}</p>
                            <p class="mb-0 text-right">{{ factura.RENTRIM }}</p>
                          </div>
                        </div>
                      </td>      
                      <td>
                        <div class="d-flex justify-content-center">
                          <div style="display: table-cell">
                            <p class="mb-0 text-right">${{ formatPrice(dcg.proyeccion) }}</p>
                            <p class="mb-0 text-right">{{ dcg.proyeccion_reng }}</p>
                          </div>
                        </div>                      
                      </td>                   
                  </tr>
                </template>
              </tbody>
            </template>
          </v-simple-table>
            
        </v-col>
      </v-row>     

      <v-dialog
        v-model="dialogParticipationDivisionShow"
        transition="dialog-bottom-transition"
        :max-width="isMobile ? '100%' : '40%'">
        <v-card class="pa-3">
          <div class="text-center pa-1 pl-3" style="background-color: #000; color:#fff">
            Participación / Divisiones - {{ dataClient.NAME1 }}
          </div>

          <div class="row">
            <div class="col">
              <v-simple-table dense>
                <thead>
                  <tr class="custom-table-bordered">
                    <th class="text-center text-dark" style="color:#00B99D !important;">Facturación Bruta</th>
                    <th class="text-center text-dark" style="color:#FF9F00 !important;">% de participación</th>
                    <th class="text-center text-dark" style="color:#746EE5 !important;">División</th>
                  </tr>
                </thead>
                <tbody class="custom-table-bordered">
                  <template v-if="loadingParticipationDivision">
                    <tr>
                      <td colspan="3">
                        <v-progress-linear
                          indeterminate
                          color="cyan"
                        ></v-progress-linear><br>
                        Cargando... Por favor espere
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr v-for="dpd in dataParticipationDivision" :key="dpd.DIVI">
                      <td class="font-weight-bold text-body-2"><b>${{ formatPrice( formatPor( dpd.FACNETA ) ) }}</b></td>
                      <td class="font-weight-bold text-body-2"><b>%{{ formatPor( dpd.PORPART ) }}</b></td>
                      <td class="font-weight-bold text-body-2"><b>{{ dpd.DIVI }}</b></td>
                    </tr>
                  </template>
                </tbody>
              </v-simple-table><br>
              <pie-chart v-if="!loadingParticipationDivision" :chartdata="chartdata4" :options="chartOptions" />
            </div>
            <div class="col trimestre-client-group">
              <span>Trimestre:</span>
              <custom-select :options="trimestres" :placeholder="'Periodo'"
                  v-model="period"></custom-select>
            </div>            
          </div>
          <v-card-actions>
            <v-btn color="primary" text @click="dialogParticipationDivisionShow = false">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="dialogShow2"
        scrollable
        transition="dialog-bottom-transition"
        :max-width="isMobile ? '100%' : '40%'">
        <v-card class="pa-3">
          <div class="text-center pa-1 pl-3" style="background-color: #000; color:#fff">
            Detalle de Crecimiento {{ titleModalCrecimiento }} {{ formatCustomer(dataClient.KUNNR) }} del Mes: {{ fechaModal }}
          </div>
          
            <v-simple-table dense>
              <template v-if="loadingDetail3vs3">
                <thead class="">
                  <tr class="custom-table-bordered ">
                    <th class="text-center text-dark " colspan="2">
                      <v-progress-linear
                        indeterminate
                        color="cyan"
                      ></v-progress-linear><br>
                      Cargando... Por favor espere
                    </th>
                  </tr>
                </thead>
              </template>
              <template v-else>
                <thead>
                  <tr class="custom-table-bordered ">
                    <th class="text-center text-dark " colspan="2"><b>Facturación Actual</b></th>
                  </tr>
                  <tr class="custom-table-bordered">
                    <th class="text-center text-dark">Mes</th>
                    <th class="text-center text-dark">Facturación</th>
                  </tr>
                </thead>
                <tbody class="custom-table-bordered">
                  <tr v-for="dbc3, index in dataAcumContant.Fact_Actual.fechas" :key="index">
                    <td class="text-body-2">{{ daytext( dbc3.SPMON ) }}</td>
                    <td class="text-body-2">
                      <span v-if="dbc3.FACNETA != '0'">${{ dbc3.FACNETA | removeDecimals }}</span>
                      <span v-else>$0</span>
                    </td>
                  </tr>
                  <tr class="custom-table-bordered">
                    <td class="text-center text-dark font-weight-bold">Total = </td>
                    <td class="text-center text-dark font-weight-bold">${{ dataAcumContant.Fact_Actual.total | removeDecimals }}</td>                   
                  </tr>
                </tbody>
                <thead>
                  <tr class="custom-table-bordered ">
                    <th class="text-center text-dark " colspan="2"><b>Facturación Anterior</b></th>
                  </tr>
                  <tr class="custom-table-bordered">
                    <th class="text-center text-dark">Mes</th>
                    <th class="text-center text-dark">Facturación</th>
                  </tr>
                </thead>
                <tbody class="custom-table-bordered">
                  <tr v-for="dbl3, index in dataAcumContant.Fact_Anterior.fechas" :key="index">
                    <td class="text-body-2">{{ daytext( dbl3.SPMON ) }}</td>
                    <td class="text-body-2">
                      <span v-if="dbl3.FACNETA != '0'">${{  dbl3.FACNETA | removeDecimals }}</span>
                      <span v-else>$0</span>
                    </td>
                  </tr>
                  <tr class="custom-table-bordered">
                    <td class="text-center text-dark font-weight-bold">Total = </td>
                    <td class="text-center text-dark font-weight-bold">${{ dataAcumContant.Fact_Anterior.total | removeDecimals }}</td>
                  </tr>
                  <tr class="custom-table-bordered">
                    <td class="text-center text-dark font-weight-bold">Crecimiento = </td>
                    <td class="text-center text-dark font-weight-bold">{{dataAcumContant.crecimiento | formatCurrency}}%</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-card-actions>
              <v-btn color="primary" text @click="dialogShow2 = false">Cerrar</v-btn>
            </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="printing" persistent>
      <!-- 500 para table 1 |  350 a table 2 | 800 table 3 -->
      <v-card class="pa-3">
        <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
      <span>Generando archivo...</span>
      </v-card>
    </v-dialog>

    <v-dialog v-model="updateImageModal" :persistent="loadPhotos ">
      <v-card class="pa-3">
        <v-form ref="form" v-if="!loadPhotos">
                <div class="text-justify text-general-module ma-4">
                  <span class="ml-4 mr-4">FOTOS DEL NEGOCIO</span>
                  <span class="ml-4 mr-4"> <br>Requisitos para subir fotos:<br>
                    <ul>
                      <li>Solo acepta formato jpg y png.</li>
                      <li>Máximo del peso por foto es de 5MB.</li>
                      <li>Tomar fotos legibles.</li>
                    </ul> 
                  </span>
                </div>
                <v-row dense>
                  <v-col cols="12" md="6" align="left">
                    <label for="" class="mb-2 text-table-profile ml-10 mr-10" >Fachada <v-icon>mdi-image</v-icon></label>
                    <v-file-input class="ml-10 mr-10" solo placeholder="Fachada" v-model="form.facade_photo"></v-file-input>
                    <a class="mb-2 text-table-profile ml-10 mr-10"  v-if="dataDocument?.facade_photo"  :href="apiUrl +'/'+ dataDocument?.facade_photo" target="_blank">
                      <img class="img-fachada" :src="apiUrl +'/'+ dataDocument?.facade_photo"/>
                    </a>
                  </v-col>
                  <v-col cols="12" md="6" align="left">
                    <label for="" class="mb-2 text-table-profile ml-10 mr-10" >Interior <v-icon>mdi-image</v-icon></label >
                    <v-file-input   class="ml-10 mr-10"  solo placeholder="Interior" v-model="form.indoor_photo"  ></v-file-input>
                    <a class="mb-2 text-table-profile ml-10 mr-10" v-if="dataDocument?.indoor_photo" :href="apiUrl +'/'+  dataDocument?.indoor_photo" target="_blank">
                      <img class="img-fachada" :src="apiUrl +'/'+  dataDocument?.indoor_photo"/></a>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" md="6" align="left">
                    <label for="" class="mb-2 text-table-profile ml-10 mr-10"
                      >Mostrador/Bodega: <v-icon>mdi-image</v-icon></label >
                    <v-file-input class="ml-10 mr-10" solo placeholder="Mostrador/Bodega:" v-model="form.bar_photo"></v-file-input>
                    <a class="mb-2 text-table-profile ml-10 mr-10" v-if="dataDocument?.bar_photo" :href="apiUrl +'/'+  dataDocument?.bar_photo" target="_blank">
                      <img class="img-fachada" :src="apiUrl +'/'+  dataDocument?.bar_photo"/>
                    </a>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="4" offset="4">
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-btn :disabled="loadPhotos" depressed color="success" class="justify-text" large @click="updatePhotos()" >
                          Confirmar
                          <v-icon style="margin-left: 0.5em">mdi-check-bold</v-icon>
                        </v-btn>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-btn depressed color="error" class="justify-text" large v-on:click="updateImageModal=false">
                          Cancelar
                          <v-icon style="margin-left: 0.5em">mdi-window-close</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
        </v-form>
        <div v-else >
          <v-progress-circular indeterminate color="primary" class="ma-3" ></v-progress-circular>
        </div>
        </v-card>
  </v-dialog>

      <ConfidentialityNotice></ConfidentialityNotice>
    </v-container>

    <CoolLightBox
      :items="photos"
      :index="index"
      @close="index = null"
    ></CoolLightBox>
  </div>
</template>
  
  <script>
  import axios from "@/plugins/axios";
  import CustomSelect from "./CustomSelect.vue";
  import ConfidentialityNotice from "../../components/ConfidentialityNotice.vue";
  import { mapGetters, mapActions } from "vuex";
  import PieChart from "./PieChart.vue";
  import CoolLightBox from 'vue-cool-lightbox';
  import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
  import ButtonsrGroupCustomer from '@/components/ButtonsrGroupCustomer.vue'
  import moment from 'moment';
  import DataCustomer from '@/components/DataCustomer.vue';

  // import PRNavBar from "@/template/partials/PRNavBar.vue";
  //import jsPDF from 'jspdf';
  
  export default {
    name: "ClientGroup",
    components: {CustomSelect, PieChart, ConfidentialityNotice,ButtonsrGroupCustomer,CoolLightBox, DataCustomer},
    props: ['opt'],
    data() {
      return {
        showPrint:false,
        showPrintNumber:0,
        printing:false,
        imageArray: [],
        period: '',
        clientIdCurrent: '',
        trimestreCurrent: '',
        loadingDataClientGroup:true,
        trimestreProyeccionCurrent: '',
        trimestres: [],
        urlServer:process.env.VUE_APP_BASE_SERVER,        
        dialogShow:false,
        dialogShow2:false,
        dialogParticipationDivisionShow:false,
        loadingDataClient: true,
        loadingDataResumenGroup: true,
        loadingDetailCumulative: false,
        loadingDetail3vs3:false,
        loadingParticipationDivision: false,
        dateCurrent: null,
        isMobile: false,
        quarters: [],
        quartersData:[],
        quartersDataClient:[],
        dataClient: {
          NAME1: '',
          STCD1: '',
          KUNNR: '',
          KLIMK: '',
          SKFOR: '',
          CREDD: '',
          SVENC: '',
          VTEXT: '',
          STOTA: '',
          DMBTRNC: '',
          DMBTRDZ: ''
        },
        dataClientSum: {
          NAME1: '',
          STCD1: '',
          KUNNR: '',
          KLIMK: '',
          SKFOR: '',
          CREDD: '',
          SVENC: '',
          VTEXT: '',
          STOTA: '',
          DMBTRNC: '',
          DMBTRDZ: ''
        },
        dataResumenGroup: {
          CRE3X3: '',
          CREACU: '',
          FNMSG: '',
          MYERR: '',
          PAGTRI: '',
          PORCTE: '',
          PROYEC: '',
          TOTCTE: '',
          TOTMAC: ''
        },
        dataClientGroup:[{
          KUNAG: '',
          NAME1: '',
          ORDER: '',
          CREACU: '',
          CRE3X3: '',
          PAGTRI: '',
          PAGTRI2: '',
          PROYEC: '',
          FNMSG: ''
        }],
        dataResumenGroupBillingPerQuarter:{
          data: [
            {
                HC_TITULO: "",
                HC_VAL1: "",
                HC_VAL2: "",
                HC_VAL3: "",
                HC_VAL4: "",
                HC_VAL5: "",
                HC_VAL6: "",
                HC_VAL7: "",
                HC_VAL8: "",
                MYERR: "",
                FNMSG: ""
            }
          ],
          renglonaje: [
            {
                HC_TITULO: "",
                HC_VAL1: "",
                HC_VAL2: "",
                HC_VAL3: "",
                HC_VAL4: "",
                HC_VAL5: "",
                HC_VAL6: "",
                HC_VAL7: "",
                HC_VAL8: "",
                MYERR: "",
                FNMSG: ""
            }
          ],
          header: [
              {
                  HC_TITULO: "",
                  HC_VAL1: "",
                  HC_VAL2: "",
                  HC_VAL3: "",
                  HC_VAL4: "",
                  HC_VAL5: "",
                  HC_VAL6: "",
                  HC_VAL7: "",
                  HC_VAL8: "",
                  MYERR: "",
                  FNMSG: ""
              }
          ]
        },
        dataClientDetail: {
          CRE3X3: '',
          CREACU: '',
          FNMSG: '',
          MYERR: '',
          PAGTRI: '',
          PORCTE: '',
          PROYEC: '',
          TOTCTE: '',
          TOTMAC: ''
        },
        dialogLoadingDataClient: false,
        dataDetailCumulative:{
          billingCurrentTotal: 0,
          billingLastTotal: 0,
          dataBillingCurrent: [],
          dataBillingLast: []
        },
        dataAcumContant:{
          Fact_Actual: {fechas: [], total: 0},
          Fact_Anterior: {fechas: [], total: 0},
        },
        dataParticipationDivision:{},
        months: [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ],
        monthCurrent: '',
        chartdata4:null,
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false
        },
        currentIndex: null,
        rotuladoPhotos:[],
        photos:[],
        items: [
          {
              description: "",
              title: "",
              src:""
          }
        ],         
        index: null,
        customerSelected:0,
        customerSelectedName:'',
        loadPhotos:false,
        dataDocument:{},  
        updateImageModal:false,
        form: {
          facade_photo: [],
          indoor_photo: [],
          bar_photo: [],
        },
        apiUrl:process.env.VUE_APP_BASE_SERVER,
        arrayTrimestres: [],
        breadcrumbsItems: [
                {
                text: 'IEL',
                disabled: false,
                href: '/',
                },       
            ],
            customerInfo:[],
            titleModalCrecimiento: '',
            fechaModal: ''
      };
    },
    
    async mounted() {
      this.fechaModal = moment().format('MM/YYYY');
      this.calcularUltimosTrim(7)
      
      let current_date = new Date();
  
      var customer_code =0;
            
      if (this.$getLocalStorageCustomer() != null) {
        const currentCustomerInfo= JSON.parse(localStorage.getItem('currentCustomerInfo')) || [];
                if (currentCustomerInfo.length<1) {
                this.$router.push({name:'Home'})
                }   
                this.customerInfo =  currentCustomerInfo;  
                customer_code =  parseInt(this.customerInfo?.KUNNR,10) 
             }else{ 
                 customer_code = this.user.custumer_code; 
            }

      this.clientIdCurrent = customer_code;
      this.dateCurrent = current_date.getDate() +" de " +this.months[current_date.getMonth()] +" del " +current_date.getFullYear();
      this.monthCurrent = this.months[current_date.getMonth()].slice(0,3);

      window.addEventListener('resize', this.checkMobile); 
      this._getDataClient(customer_code);
      this._getDataClientSum(customer_code);
      this._getDataResumenGroup(customer_code);
      this._getPastSixQuarters();
      this._getDayBusinessDays();
      this._getDataClientGroup(customer_code);
      this._getTrimestre();
         
      this.createBreadcrumb();

      
    },
  
    computed: {
      ...mapGetters({
        authenticated: "auth/authenticated",
        user: "auth/user",
      }),
      currentPeriod(){
      const today = new Date;
      let firstDay = 1; 
      let year = today.getFullYear();
      let currentMonth = today.toLocaleString('default', { month: 'long' }) 
       currentMonth=  currentMonth.charAt(0).toUpperCase() + currentMonth.slice(1);
      return ` del (${firstDay} de ${currentMonth} ${year} al ${today.getDate()} de ${currentMonth} del ${year})`;
    },
        directorFullName(){
          return this.user.VTWEG =='DT'?'Minorista Nacional':'Privados Nacional';
          //  return 'Minorista Nacional'
                // return this.seller.NACHND+" "+this.seller.NACH2D+" "+this.seller.VORNAD
            },
        sellerFullName(){ 
            return this.customerInfo.NAMEVEN;
        },
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.checkMobile);
    },
    methods: {
      ...mapActions({
            sendToPDF: 'printer/sendToPDF'
        }),        
      createBreadcrumb(){   
            let user = [
            {
            text: this.user.VTWEG =='DT'?'Minoristas':'Privados',
            disabled: false,
            href: '/',
            },  
            {
            text: this.directorFullName,
            disabled: false,
            href: '/',
            },
            {
            text: this.sellerFullName,
            disabled: false,
            href: '/resultados',
            }, 
            {
            text: 'Carátula',
            disabled: false  ,
            href: this.$router.resolve({ name: 'CustomerCoverDetails' }).href,
            }, 
            {
            text: 'Resumen de grupo / '+this.customerInfo?.NAME1 +this.currentPeriod,
            disabled: true  ,
            href: "",
            },
            ]  
            this.breadcrumbsItems = [...this.breadcrumbsItems, ...user]
            
            },
      sendPhoto(photos){
        this.index = 0    
      
        let urlServer = process.env.VUE_APP_BASE_SERVER      
        this.photos = []
        if (photos?.facade_photo) {
            this.photos.push({
                description: "Fachada",
                title: "Fachada",
                src: urlServer + '/' + photos.facade_photo
            });
        }

        if (photos?.indoor_photo) {
            this.photos.push({
                description: "Interior",
                title: "Interior",
                src: urlServer + '/' + photos.indoor_photo
            });
        }

        if (photos?.bar_photo) {
            this.photos.push({
                description: "Mostrador/Bodega:",
                title: "Mostrador/Bodega:",
                src: urlServer + '/' + photos.bar_photo
            });
        }     
      },
      showPhotosToUpdate(customer_code){
        const customer = this.dataClientGroup.find(value => value.KUNAG == customer_code)
        if(customer){
          this.customerSelected = customer;
          this.dataDocument = customer.photos;
          this.updateImageModal=true; 
        }
      },

      updatePhotos() { 
        
        this.loadPhotos = true;
        let ed = new FormData(); 
        ed.append("indoor_photo", this.form.indoor_photo);
        ed.append("facade_photo", this.form.facade_photo);
        ed.append("bar_photo", this.form.bar_photo);
        ed.append("id_customer_docu_data",this.customerSelected.photos.id);
        axios
          .post("grupo-cliente/update-image", ed)
          .then((res) => { 
            if (res.data.success) {
              const customer = this.dataClientGroup.find(value => value.KUNAG == this.customerSelected.KUNAG)
              if(customer){
                this.dataDocument = res.data.document
                this.customerSelected.photos = res.data.document
              }
              
              this.$swal({
              title: "Datos actualizados",
              icon: "success",
              type: "succes",
            }).then(() => {              
              this.loadPhotos = false;
            }); 
            } else{
              console.log(res.data)
            }   
            this.loadPhotos = false;
          })
          .catch((err) => {
            this.loadPhotos = false;
            console.log(err);
          }) ;
      },
      checkMobile() {
        this.isMobile = window.innerWidth <= 600;
      },
      _esDiaLaborable(fecha) {
        const diaSemana = fecha.getDay(); // 0: Domingo, 1: Lunes, ..., 6: Sábado
        return diaSemana !== 0 && diaSemana !== 6; // No es domingo ni sábado
      },
      _calcularDiasHabiles(desde, hasta) {
        const unDia = 24 * 60 * 60 * 1000; // Milisegundos en un día
        let diasHabiles = 0;

        for (let fecha = new Date(desde); fecha <= hasta; fecha = new Date(fecha.getTime() + unDia)) {
          if (this._esDiaLaborable(fecha)) {
            diasHabiles++;
          }
        }

        return diasHabiles;
      },
      _getDayBusinessDays(){
        const fecha = new Date();
        var mes = fecha.getMonth() + 1; // Los meses en JavaScript van de 0 a 11
        mes = mes < 10 ? `0${mes}` : mes.toString();
        
        var fechaInicio = new Date('2023-'+mes+'-01');
        var fechaFin = new Date('2023-'+mes+'-31');
        this._calcularDiasHabiles(fechaInicio, fechaFin);
      },
      _getDataClient(customer_code) {
        
        axios
          .post("grupo-cliente/data-client", {
            client: customer_code,//'0000116564',
            VKORG: this.user.VKORG,
            VTWEG: this.user.VTWEG,
            VKBUR: this.user.VKBUR,
          })
          .then((response) => {
            this.dataClient = response.data.dataDetailClient;
            this.loadingDataClient = false;
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => (this.dialogLoadingDataClient = false));
      },
      _getDataClientSum(customer_code) {
        
        axios
          .post("grupo-cliente/data-client-sum", {
            client: customer_code,//'0000116564',
            VKORG: this.user.VKORG,
            VTWEG: this.user.VTWEG,
            VKBUR: this.user.VKBUR,
          })
          .then((response) => {
            this.dataClientSum = response.data.dataDetailClient;
            this.loadingDataClient = false;
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => (this.dialogLoadingDataClient = false));
      },
      _getDataResumenGroup(customer_code) {
        
        axios
          .post("grupo-cliente/resumen-group", {
            client: customer_code,//'0000116564',
            VKORG: this.user.VKORG,
            VTWEG: this.user.VTWEG,
            VKBUR: this.user.VKBUR,
          })
          .then((response) => {
            this.dataResumenGroup = response.data.dataResumenGroup;
            this.loadingDataResumenGroup = false;
            // this.factTrimestre();
          })
          .catch((error) => {
            console.error(error);
          });
      },
      _getDataClientGroup(customer_code) {
        
        this.dataClientGroup = [];
        this.loadingDataClientGroup = true;
        axios
          .post("grupo-cliente/client-group", {
            client: customer_code,//'0000116564',
            VKORG: this.user.VKORG,
            VTWEG: this.user.VTWEG,
            VKBUR: this.user.VKBUR,
          })
          .then((response) => {
            this.dataClientGroup = response.data.dataResumenGroup;
            this.loadingDataClientGroup = false;
            // this.factTrimestreClientGroup(this.dataClientGroup);
            // this.dataClientGroup.map((c)=>{
            //   this._rotulado( c.KUNAG, c.NAME1 );
            //   this._getDocumentData( c.KUNAG );
            // });
          })
          .catch((error) => {
            console.error(error);
          });
      },
      _getPastSixQuarters(){
        const currentDate = new Date();
        const quarters = [];

        for (let i = 0; i < 7; i++) {
          const year = currentDate.getFullYear();
          const currentMonth = currentDate.getMonth();
          const currentQuarter = Math.floor(currentMonth / 3) + 1;

          if( i > 0 ){
            quarters.push({
              year,
              quarter: currentQuarter
            }); 
          }

          // Restar 3 meses para ir al trimestre anterior
          currentDate.setMonth(currentDate.getMonth() - 3);
        }
        this.quarters = quarters;
      },      

      calcularUltimosTrim(numTrim){  
        const fechaActual = moment()     
        const anioActual = fechaActual.year(); 
        const mesActual = fechaActual.format('MM'); 
        const fechaInicio = moment({ year: anioActual, month: mesActual - 1 });        
       
        for (let i = 0; i < numTrim; i++) {
          const trimestreInicio = fechaInicio.clone().subtract(i * 3, 'months');
          const trimestreNum = Math.floor(trimestreInicio.month() / 3) + 1;
          const trimestreAnio = trimestreInicio.year();   
          
          this.arrayTrimestres.push({date: `${trimestreNum}/${trimestreAnio}`, trim: trimestreNum, year: trimestreAnio});        
        }   
        
        this.arrayTrimestres = this.arrayTrimestres.reverse();
      },
      
    
      daytext( day ){
        let dayText = day.slice(4,6) + '/' + day.slice(0,4);
        return dayText;
      },
   
      formatPrice(value) {
        // return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return this.$options.filters.formatCurrency(value)
      },
      formatPor(value) {
        return Math.trunc(value)
      },      
      async detail3vs3(customer_code, opt){
        this.loadingDetail3vs3 = true;
        this.dialogShow2 = true;

        let path = '';
        path = (opt == 1) ? 'detail-3vs3' : 'detail-3vs3-customer';
        this.titleModalCrecimiento = (opt == 1) ? 'Constante de Grupo' : 'Constante de Cliente';

        let response =  await axios.get('grupo-cliente/'+path, {
          params:{
            client: customer_code,
            VKORG: this.user.VKORG,
            VTWEG: this.user.VTWEG,
            VKBUR: this.user.VKBUR
          }
        });

        this.dataAcumContant = response.data.data;
        this.loadingDetail3vs3 = false;

      },
      async crecimientoAcumulado( customer_code, opt ){
        this.loadingDetail3vs3 = true;
        this.dialogShow2 = true;

        let path = '';
        path = (opt == 1) ? 'detail-acum-group' : 'detail-acum-customer';
        this.titleModalCrecimiento = (opt == 1) ? 'Acumulado de Grupo' : 'Acumulado de Cliente';

        let response = await axios.get("grupo-cliente/"+path, {
          params:{
            client: customer_code,
            VKORG: this.user.VKORG,
            VTWEG: this.user.VTWEG,
            VKBUR: this.user.VKBUR
          }
        });
        
        this.dataAcumContant = response.data.data;
        this.loadingDetail3vs3 = false;
      },
      _getTrimestre(){
        let current_date = moment();
          const yearInit = 2023;
          const currentYear = current_date.year();
          const currentQuarter = Math.ceil(current_date.month() / 3);

          for (let y = yearInit; y <= currentYear; y++) {
            const numQuarters = (y === currentYear) ? currentQuarter : 4;
            for (let t = 1; t <= numQuarters; t++) {
              this.trimestres.push({
                value: `${y}/${t}`,
                label: `${y}/${t}`
              });
            }
          }          
      },
      participationDivision(f){
        if( f == 0){
          var periodSelect = this.trimestres[this.trimestres.length - 1];
          this.period = periodSelect.value;
        }
        
       var customer_code =  parseInt(this.customerInfo?.KUNNR,10) 
            if (!customer_code) {  
              customer_code = this.user.custumer_code; 
            }

        this.dialogParticipationDivisionShow=true;
        this.loadingParticipationDivision = true;
        var trimestre = this.period.split("/");
        axios
          .post("grupo-cliente/participation-division", {
            client: customer_code,//'0000116564',
            VKORG: this.user.VKORG,
            VTWEG: this.user.VTWEG,
            VKBUR: this.user.VKBUR,
            trim:trimestre[1],
            year:trimestre[0]
          })
          .then((response) => {
            
            this.dataParticipationDivision = response.data;

            var divisiones = [];
            var porcentaje = [];
            var cost = [];
            var colors = ["#FF9F00","#746EE5", "#00B99D"];
            let c = 1;

            for( var d = 0; d < response.data.length; d++){
              divisiones.push(response.data[d].DIVI);
              cost.push( this.formatPrice( this.formatPor( response.data[d].FACNETA ) ));
              porcentaje.push( this.formatPor( response.data[d].PORPART) );

              if(c>3){

                const letters = '0123456789ABCDEF';
                let color = '#';
                for (let i = 0; i < 6; i++) {
                  color += letters[Math.floor(Math.random() * 16)];
                }
                colors.push( color );
              }
              c++;
            }

            this.chartdata4 = {
              labels:divisiones,
              datasets: [
                {
                  backgroundColor: colors,
                  data: porcentaje,
                  fill: false,
                  tension: 0.1,
                },
              ],
            };
            this.loadingParticipationDivision = false;
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => (this.loadingParticipationDivision = false));
      },
      formatCustomer(data){
            if (data.length >= 6) {
                // Utilizar el método slice para obtener los últimos 6 dígitos
                var ultimosSeisDigitos = data.slice(-6);
                return ultimosSeisDigitos;
            } else {
                // Si la cadena tiene menos de 6 caracteres, devolver la cadena original
                return data;
            }
        },
        sendCustomerHome(code){
          this.$removeLocalStorageCustomer();
          if(code != this.user.custumer_code){
            this.$setLocalStorageCustomer(code);
          }            
          this.$router.push({ name: 'Home' });
        }
    },
    watch: {
      period(newValue, oldValue) {
        
        if(newValue != oldValue){
          this.participationDivision(1);
        }
      },
      dataClient(){
        this.sendToPDF({active: true})
      }
    },
  };
  </script>
  
<style scoped>  
  .amount-negative{
    color: red;
  }
  .amount-positive{
    color: green;
  }
  .modal-btn-close{
    background: white;
    border-radius: 50%;
    color: black;
    cursor:pointer;
    padding: 0.5em;
    font-weight:bold;
    text-decoration: none;
  }
  .modal-card .col{
    border:solid 1px black;
  }
  .modal-header{
    background:black !important;
    color:white !important;
  }

.btn-cg{
  font-weight: bold;
}
.btn-active-cg{
  background: linear-gradient(to bottom, #FFD75E, #806C2F);
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}

/* Estilos adicionales para resaltar el botón al pasar el cursor sobre él */
/* .btn-active-cg:hover {
  background: linear-gradient(to bottom, #806C2F, #FFD75E);
} */

/*Lo nuevo*/
/* .card-p {
  margin: 0;
  padding: 0;
  color: #000;
} */
/*.t-shadow{
  box-shadow: -8px 0px 6px -6px rgba(0, 0, 0, 0.5), 8px 0px 6px -6px rgba(0, 0, 0, 0.5);
}*/
/* .my-table th,
.my-table td {
  border: 0px 4px 0px 0px solid #dad9d9;
}

.my-table {
  width: 100%;
} */

.statement__container{
    font-size: 14px !important;
    padding-left: 3%;
    padding-right: 3%;
} 

.statement__container table tbody td{
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    border: 1px solid var(--gris-de-las-tablas);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #DDE1E6;
    opacity: 1;
    height: auto !important;
    padding: 4px !important;
}

.statement__container thead th{
  font-weight: 500;
  font-size: 14px !important;
  height: 10px !important;
}

.table-heading {
  font-weight: bold;
  font-size: 0.75rem;
  background: rgb(245, 245, 245);
  color: #000;
  border-width: 1px 1px 3px 1px;
  border-color: rgb(228, 231, 234);
  border-bottom-color: rgb(112, 112, 112);
}

.h-title{
    background: #F5F5F5 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
    opacity: 1;
    border-bottom: 2px solid #707070 !important;
    font-weight: 500;
    font-family: Roboto !important;
    padding: 12px !important;
    font-size: 14px !important;
}


/* .custom-table-bordered>tr>td {
  border-color: rgb(224, 228, 232);
}

.custom-table-bordered>th {
  border-color: rgb(224, 228, 232);
  font-size: 14px;
}

.table-heading tr {
  color: #000;
}

th.boder-x-none {
  border-right: none;
  border-left: none;
}

th.boder-l-none {
  border-left: none;
}

th.boder-r-none {
  border-right: none;
}
.menu-f button{
  text-transform: none !important;
}
td {
    padding: 10px;
    border: 1px solid #ccc;
  }
.shadow-td{
  width: 7em;
}
.sin-border td{
  border: none;
  border-bottom: none !important;
}
     

    
    /* .btn-multipago{
        background: transparent linear-gradient(180deg, #A82222 0%, #FF0000 100%) 0% 0% no-repeat padding-box;
        opacity: 1;
        color: #FFF !important;
        text-transform: inherit !important;        
        height: 20px !important;
        font-size: 12px !important;
    } */

    /* .border-bootom-none{
        border-bottom: 1px solid #a5a5a5 !important
    }        

    .statement__container .close-icon {
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        margin: 8px;
    }

    .t-documento{
        height:0 !important; 
        padding:0 10px !important; 
        border:0 !important
    }

    .icon-pnc{
        background: #dc3545;
        border-radius: 5px;
        font-size: 11px;
        padding: 2px 3px;
        max-width: 30px;
        color: #FFF;
        margin-right: 2px;
    }
    
     */

    /* Style Input */
    /* .statement__container table thead .v-input__slot, .statement__container table thead .v-input__control{
        min-height: auto !important;  
        background: transparent linear-gradient(180deg, #A82222 0%, #FF0000 100%) 0% 0% no-repeat padding-box !important;
        opacity: 1;          
    }

    .statement__container table  thead .v-input__slot .v-icon{
        color: #FFF !important;
    }

    .statement__container table .v-input__slot .v-label, .statement__container table .v-input__slot .v-select__selection {
        font-size: 11px !important;
        color: #FFF !important;
    }

    .statement__container .cont-search{
        background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
        opacity: 1;
        padding: 7px 20px;
    }

    .statement__container .cont-search .v-input__control{
        min-height: auto !important;
    }
    
    .statement__container .cont-search .v-input__control .v-label{
        font-size: 14px;
    } */
    /* End Style Input */
</style>
