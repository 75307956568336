export default {
    namespaced: true,
    state: {
      loaderActive: false
    },
    getters: {
        loaderActive: (state) => state.loaderActive,
    },
    mutations: {
      SET_LOADER(state, data) {        
        state.loaderActive = data;
      }
    },
    actions: {
      activeLoader({ commit }) {
        commit('SET_LOADER', true);
      },
      desactiveLoader({ commit }) {
        commit('SET_LOADER', false);
      }
    }
  };