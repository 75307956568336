<template> 
    <div>
      <div class="d-flex flex-wrap" style="background-color: #000; color:#fff">
        <div class="text-left ml-3 mt-1" >Clientes con compra mensual</div> 
            <v-btn v-if="!loading" icon @click="closeModal()" class="ml-auto">
                <v-icon size="20" color="#fff" >mdi-close-circle</v-icon>
            </v-btn> 
      </div> 
              <v-row class="ma-1 "> 
                <v-col cols="12">
                  <v-simple-table dense class="my-table" v-if="!loading">
                <template v-slot:default>
                  <thead> 
                    <tr  class="custom-table-bordered text-center">
                      <th>Mes</th>
                      <th>Clientes</th> 
                    </tr>
                  </thead>
                  <tbody class="custom-table-bordered">               
                    <tr v-for="(item,index) in data.slice(0,threeMonths?3:13 )" :key="index" class=" text-center">
                      <td>{{ months[index+1].full_name   }}</td>
                      <td>{{item.CTECOM}}</td> 
                    </tr> 
                    <tr  class=" text-center">
                      <td class="text-primary text-black font-weight-bold">Promedio total</td>
                      <td>{{number_format( (threeMonths ? data?.PROM3:data?.PROM12) ?? 0 , false)}}</td> 
                    </tr>                    
                  </tbody>
                </template>
              </v-simple-table>
              <template v-else>
                <v-progress-linear indeterminate color="teal" ></v-progress-linear>
                </template>
                </v-col>
              </v-row> 
    </div> 
    </template>
    <script>
    import axios from "@/plugins/axios";
    import MixinHelpers from '@/mixins/helpers';

    export default ({
      props: ['manager','modal','managerData','months','period','threeMonths'],
      mixins: [MixinHelpers],
      watch:{
      loading(value){
        this.$emit('loadingCustomerBuyMonthly',value)
        },
        modal(value){ 
            if (value) {
                this.loadCustomersWithMonthlyBuy();
            } 
        }, 
      },
      mounted(){
      this.loadCustomersWithMonthlyBuy()
      },
      data() {
        return {
            loading: false,
            data: [], 
        }
    }, 
      methods: {
        closeModal(){
          this.$emit('closeModal')
        },
        loadCustomersWithMonthlyBuy(){  
          this.loading=true;
          var month = this.period.month
        if (month < 10) { month = `0${month}`}
          const period = `${this.period.year}${month}`
          var params ={ 
            manager:this.managerData.PERNR, 
            period: period,
            channel: this.managerData.VTWEG
          } 
          axios.post('/manager/home/customers-with-monthly-sales',params).then((res) => { 
              this.data = res.data;
              console.log(this.data)
              this.loading = false;
          })
          .catch((err) => { 
                console.log(err);
                this.loading = false;
              }) ;  
        }

      }, 
        name:'CustomerSalesMonthly',
    });
    </script >
    <style scoped>
    .my-table th,
    .my-table td {
      border: 0px 4px 0px 0px solid #dad9d9;
      
    }
    .bg-gray {
      
      background-color: rgb(245,245,245);
    }
    .my-table {
      width: 100%;
    }
    .custom-table-bordered  {
      border-width: 2.5px;
      border-color: rgb(224, 228, 232);
    }
    .custom-table-bordered-outine{
        border-width: 2.5px ;
      border-color: rgb(224, 228, 232);
    }
    .custom-table-bordered>tr>td {
      border-width: 2.5px;
      border-color: rgb(224, 228, 232);
    }
    
    .custom-table-bordered>th {
      border-width: 2.5px;
      border-color: rgb(224, 228, 232);
    }
    
    
    </style>