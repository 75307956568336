export default {
  namespaced: true,
  state: {
    orderId: null,
    cart: [],
  },
  getters: {
    orderId: (state) => state.orderId,
    cart: (state) => state.cart,
    subtotal: (state) =>
    state.cart.length <= 0
      ? 0
    :
    state.cart
      .map((item) => item.importe_producto)
      .reduce((a, b) => a + b, 0),
    iva:  (state) =>
    state.cart.length <= 0
      ? 0
      : state.cart
      .map((item) =>
      (item.MWST == 0 || item.MWST == 2 || item.MWST == 3 || item.MWST == 6) ?  (parseFloat(item.importe_producto) * 0):(parseFloat(item.importe_producto) * 0.16)).reduce((a, b) => a + b,0),
    total: (state, getters) => getters.subtotal + getters.iva,
  },
  mutations: {
    SET_ORDER_ID(state, orderId) {
      state.orderId = orderId;
    },
    SET_CART(state, cart) {
      state.cart = cart;
    },
    ADD_ITEM(state, item) {
      state.cart.push(item);
    },
    DELETE_FROM_CART(state, code) {
      let index = state.cart.findIndex((item) => item.codigo_material == code);
      if (index >= 0) {
        state.cart.splice(index, 1);

        return true;
      }
      return false;
    },
  },
};

    /*total: (state) =>
      state.cart.length <= 0
        ? 0
        : state.cart
            .map(
              (item) =>
                parseFloat(item.u_pedidas) *
                parseFloat(item.precio_con_descuento)
            )
            .reduce((a, b) => a + b),*/
