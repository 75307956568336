<template>
	<v-chip pill class="pill-container" :class="{ 'pill-text': onlyText }" :style="cssVars" dark :color="color">
		<slot />
	</v-chip>
</template>

<script>
import statusColor from '@/mixins/statusColor';

export default {
	name: 'ItemStatus',

	props: {
		status: {
			type: String,
			required: true,
		},

		type: {
			type: String,
			default: 'pill'
		},

		width: {
			type: String,
			default: '70px'
		},

		onlyText: Boolean
	},

	mixins: [ statusColor ],

	computed: {
		color() {
			if (this.onlyText) return 'transparent';

			return this.getColor(this.status);
		},

		cssVars() {
			return {
				'--color': this.getColor(this.status),
				'--width': this.width,
			}
		}
	}
}
</script>

<style scoped>
.pill-container {
	justify-content: center;
	text-align: center;
	margin: auto;
	min-width: var(--width);
}

.pill-text {
	color: var(--color) !important;
}
</style>