<template>
	<v-form @submit.prevent="onSearch" :disabled="disabled">
		<v-text-field 
			v-model="search"
			class="search-input rounded-0"
			:placeholder="placeholder"
			:label="label"
			:name="name"
			solo
			dense
		>
			<template v-slot:append>
				<button v-if="!loading" class="red-button card-shadow" type="submit"><v-icon :class="buttonClass" :color="buttonColor">mdi-magnify</v-icon></button>
				<v-progress-circular v-else indeterminate color="white" class="red-button card-shadow" size="30"/>
			</template>
		</v-text-field>
	</v-form>
</template>

<script>
export default {
	name: 'searchInput',

	props: {
		value: {
			type: String,
			default: '',
		},

		name: {
			type: String,
			default: 'searchItem'
		},

		placeholder: {
			type: String,
			default: 'Buscar'
		},

		label: {
			type: String,
			default: ''
		},

		buttonClass: {
			type: String,
			default: ''
		},

		buttonColor: {
			type: String,
			default: 'white'
		},

		disabled: Boolean,
		loading: Boolean
	},

	methods: {
		onSearch(){
			console.log('onSearch')
			this.$emit('onSearch', this.value)
		},

	},

	computed: {
		search: {
			set(newValue){
				this.$emit('input', newValue)
			},
			get(){
				return this.value
			}
		}
	}
}
</script>

<style scoped>
.red-button {
  background-color: rgb(200,32,49);
  min-height: 38px;
  min-width: 38px;
  margin-right: -12px;
}

.search-input {
  border-radius: 0px;
  padding-right: 0px;
}
</style>