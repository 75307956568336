<template>
    <div>
        <v-dialog :value="dialogModel" :persistent="persistent ">
            <v-card class="pa-3">
            <v-form ref="form" v-if="!loading " v-model="valid">
                    <div class="text-justify text-general-module ma-4">
                        <span class="ml-4 mr-4">FOTOS DEL NEGOCIO</span>
                        <span class="ml-4 mr-4"> <br>Requisitos para subir fotos:<br>
                        <ul>
                            <li>Solo acepta formato jpg y png.</li>
                            <li>Máximo del peso por foto es de 5MB.</li>
                            <li>Tomar fotos legibles.</li> 
                            <li v-if="document?.last_photo_update">Última Actualización: {{  document.last_photo_update }}</li> 
                        </ul> 
                        </span>
                    </div>
                    <v-row dense>
                        <v-col cols="12" lg="2" md="4" sm="6" align="left">
                        <label for="" class="mb-2 text-table-profile ml-10 mr-10" >Fachada <v-icon>mdi-image</v-icon></label>
                        <v-file-input :rules="fileRules"  class="ml-10 mr-10" solo placeholder="Fachada" v-model="form.facade_photo"></v-file-input>
                        <a class="mb-2 text-table-profile ml-10 mr-10"  v-if="document?.facade_photo"  :href="apiUrl +'/'+ document?.facade_photo" target="_blank">
                            <img class="img-fachada" :src="apiUrl +'/'+ document?.facade_photo"/>
                        </a> 
                        </v-col>

                        <v-col cols="12" lg="2" md="4" sm="6" align="left">
                        <label for="" class="mb-2 text-table-profile ml-10 mr-10" >Exhibición <v-icon>mdi-image</v-icon></label >
                        <v-file-input  :rules="fileRules"   class="ml-10 mr-10"  solo placeholder="Exhibición" v-model="form.display_photo"  ></v-file-input>
                        <a class="mb-2 text-table-profile ml-10 mr-10" v-if="document?.display_photo" :href="apiUrl +'/'+  document?.display_photo" target="_blank">
                            <img class="img-fachada" :src="apiUrl +'/'+  document?.display_photo"/></a>
                        </v-col>

                        <v-col cols="12" lg="2" md="4" sm="6" align="left">
                        <label for="" class="mb-2 text-table-profile ml-10 mr-10" >Exhibición Derecha <v-icon>mdi-image</v-icon></label>
                        <v-file-input :rules="fileRules"  class="ml-10 mr-10" solo placeholder="Exhibición Derecha" v-model="form.right_display_photo"></v-file-input>
                        <a class="mb-2 text-table-profile ml-10 mr-10"  v-if="document?.right_display_photo"  :href="apiUrl +'/'+ document?.right_display_photo" target="_blank">
                            <img class="img-fachada" :src="apiUrl +'/'+ document?.right_display_photo"/>
                        </a>
                        </v-col>
                        
                        <v-col cols="12" lg="2" md="4" sm="6" align="left">
                        <label for="" class="mb-2 text-table-profile ml-10 mr-10" >Exhibición Izquierda <v-icon>mdi-image</v-icon></label>
                        <v-file-input :rules="fileRules"  class="ml-10 mr-10" solo placeholder="Exhibición Izquierda" v-model="form.left_display_photo"></v-file-input>
                        <a class="mb-2 text-table-profile ml-10 mr-10"  v-if="document?.left_display_photo"  :href="apiUrl +'/'+ document?.left_display_photo" target="_blank">
                            <img class="img-fachada" :src="apiUrl +'/'+ document?.left_display_photo"/>
                        </a>
                        </v-col>

                        <v-col cols="12" lg="2" md="4" sm="6" align="left">
                        <label for="" class="mb-2 text-table-profile ml-10 mr-10" >Exhibición central  <v-icon>mdi-image</v-icon></label >
                        <v-file-input  :rules="fileRules" class="ml-10 mr-10"  solo placeholder="Exhibición central " v-model="form.indoor_photo"  ></v-file-input>
                        <a class="mb-2 text-table-profile ml-10 mr-10" v-if="document?.indoor_photo" :href="apiUrl +'/'+  document?.indoor_photo" target="_blank">
                            <img class="img-fachada" :src="apiUrl +'/'+  document?.indoor_photo"/></a>
                        </v-col>
                       
                        <v-col cols="12" lg="2" md="4" sm="6" align="left">
                            <label for="" class="mb-2 text-table-profile ml-10 mr-10"
                                >Bodega: <v-icon>mdi-image</v-icon></label >
                            <v-file-input  :rules="fileRules"  class="ml-10 mr-10" solo placeholder="Bodega:" v-model="form.bar_photo"></v-file-input>
                            <a class="mb-2 text-table-profile ml-10 mr-10" v-if="document?.bar_photo" :href="apiUrl +'/'+  document?.bar_photo" target="_blank">
                                <img class="img-fachada" :src="apiUrl +'/'+  document?.bar_photo"/>
                            </a>
                        </v-col> 
                    </v-row> 
                    <v-row>
                        <v-col cols="12" md="4" offset="4">
                        <v-row>
                            <v-col cols="12" md="6">
                            <v-btn :disabled="loading || !valid" depressed color="success" class="justify-text"  large @click="validateForm()" >
                                Confirmar
                                <v-icon style="margin-left: 0.5em">mdi-check-bold</v-icon>
                            </v-btn>
                            </v-col>
                            <v-col cols="12" md="6">
                            <v-btn depressed color="error" class="justify-text" large @click="close()">
                                Cancelar
                                <v-icon style="margin-left: 0.5em">mdi-window-close</v-icon>
                            </v-btn>
                            </v-col>
                        </v-row>
                        </v-col>
                    </v-row>
            </v-form>
            <div v-else >
                <v-progress-circular indeterminate color="primary" class="ma-3" ></v-progress-circular>
            </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from "@/plugins/axios";
import dates from "@/mixins/dates.js"
export default {
	name: 'UpdateCustomerImagesForm',
  mixins:[dates],
  props: {
    customerId: {
      type: Number,
      default: 0
    }, 
    userId: {
      type: Number,
      default: 0
    }, 
    document: { 
      default() {
        return {
            facade_photo:'',
            indoor_photo:'',
            bar_photo:'',
            display_photo:'',
            left_display_photo:'',
            right_display_photo:''
            } 
        }
    },  
    persistent: {
      type: Boolean,
      default: true
    },  
    dialogModel:Boolean, 
	},  
    data(){
      return {
        form: {},  
        apiUrl:process.env.VUE_APP_FILES_URL,
        loading:false,
        fileRules: [
          v => !!v || 'Este campo es requerido', 
      ],
      valid: false,
      }
    },  

  methods: { 
    validate () {
        this.$refs.form.validate()
      },
      reset () {
        this.$refs.form.reset()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },

    close(){
        this.form = {};
        this.$emit('closeModal')
    },
    
    validateForm(){

      this.validate();
      if (this.valid) {  
        this.updatePhotos();
        this.reset();
      } 
    }, 
      updatePhotos() {  
       this.loadPhotos = true; 
       var ed = new FormData(); 
       ed.append("indoor_photo", this.form.indoor_photo);
       ed.append("facade_photo", this.form.facade_photo);
       ed.append("bar_photo", this.form.bar_photo);
       ed.append("display_photo", this.form.display_photo);
       ed.append("left_display_photo", this.form.left_display_photo);
       ed.append("right_display_photo", this.form.right_display_photo);  
       ed.append("user_id", this.userId ); // id de users
       ed.append("customer_id",   this.customerId ); // id de cliente. NO SAP
       axios
          .post("/seller/home/customer-gallery/update",ed)
          .then((res) => { 
            if (res.data.success) {
              this.$swal({
              title: "Datos actualizados",
              icon: "success",
              type: "succes",
            }).then(() => {
            const newDocuments = res.data.document; 
            this.$emit('updatedPhotos',newDocuments); 
             this.loading = false; 
            }); 
            }    
            this.loading = false;
          })
          .catch((err) => {
            this.$swal({
              title: "hubo problemas al realizar la solicitud. "+err,
              icon: "success",
              type: "danger",
            })
            this.loading = false; 
          }) ;   
      }, 
  }, 
}
</script>