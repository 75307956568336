<template>
    <div> 
      <slot name="params"></slot> 
      <div class="bar-container">
          <div :class="barColor" :style="'width:'+chartValue+'%;'"></div>
      </div>
    </div>
</template>
<script> 

export default({
  watch: { 

      },

    name:"ChartBar", 
    props:{
      chartValue: { 
        default:50
      }, 
      color:{}
    },  
    data: () => ({  
    }),  
    methods:{  
    },
  computed:{
    barColor(){
      var customClass = 'custom-bar';
      switch (this.color) {
        case 1: customClass = customClass+ " bar-color-green"; break; 
        case 2: customClass = customClass+ " bar-color-yellow"; break; 
        case 3: customClass = customClass+ " bar-color-red"; break; 
      }
      return customClass;
    }
  },
    mounted() { 
  },
})
</script>

<style scoped >


.bar-container { 
    height: 22px;
    background-color: #f0f0f0;
    border-radius: 0px 10px 10px 0px; 
    position: relative;
    overflow: hidden; 
    margin:4px
  }
  .bar-color-green {
    background-color: #28A745;
  }
  .bar-color-red {
    background-color: #DC3545;
  }
  .bar-color-yellow {
    background-color: #FFD75E;
  }
  .custom-bar { 
    height: 100%; 
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    max-width: 100% !important;
  }
  </style> 