<template>
  <div class="ma-0">
    <v-simple-table dense class="ma-0">
      <template v-slot:default>
        <thead>
          <tr v-if="!calculateIndicators" class="table-montly ">
            <th v-for=" (item, index) in indicatorsPerMonth" :key="index" :class="(index == 0) ? 'border-left-red' : ''">
              {{ item }} <br>
              ${{ format(body[index]) }}
            </th>
          </tr>
          <tr v-if="calculateIndicators">
            <td colspan="3"> <v-progress-linear :active="calculateIndicators" :indeterminate="calculateIndicators"
                color="cyan"></v-progress-linear></td>
          </tr>
        </thead>
      </template>
    </v-simple-table>
  </div>
</template>
<script>
export default ({
  watch: {
  },
  data() {
    return {
      paymentTypeTitle: 'Detalle de pagos',
      loading: true,
      paymentDetails: [],
      modalPaymentDetails: true,
    }
  },
  methods: {
    format(value) {
      let result =  Math.trunc(value);
      return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    getMontlyDetails(items) {
      this.$emit("getMontlyDetails", items)
      /*
    this.paymentTypeTitle = 'Detalle de pagos'
    this.loading=true;
    this.paymentDetails=items;
    this.modalPaymentDetails=true;
    this.loading=false; */
    },
    closePaymentModal() {
      this.$emit("closePaymentModal", false)
    },
  },
  props: [
    'indicatorsPerMonth',
    'calculateIndicators',
    'body', 'months',
    'header'
  ],

  name: 'TableMontly',
}
)
</script>
<style src="@/assets/css/customerHome.css" scoped></style> 
