<template>
	<icon-btn 
		:icon="icon" 
		:color="color" 
		:dark="dark" 
		:no-fab="noFab"
		:large="large"
		@on-click="$emit('on-click')"
	>
		<slot />
	</icon-btn>
</template>

<script>
import IconBtn from './IconBtn.vue';

const buttonActions = ['check', 'watch', 'delete', 'edit'];

export default {
	name: 'ActionBtn',

	components: { IconBtn },

	props: {
		type: {
			String,
			required: true,
			validator: (value) => buttonActions.includes(value)
		},

		color: {
			type: String,
			default: 'white',
		},

		dark: Boolean,
		noFab: Boolean,
		large: Boolean,
	},

	computed: {
		icon() {
			switch (this.type) {
				case 'check': return 'mdi-check-bold';
				case 'watch': return 'mdi-eye-arrow-right-outline';
				case 'delete': return 'mdi-trash-can-outline';
				case 'edit': return 'mdi-file-edit-outline';
				default: return '';
			}
		}
	}
}
</script>