<template>
  <div class="container-fluid">
    <CardTitle title="INDICADORES TRIMESTRALES PARA BONO" icon="format-list-bulleted-triangle">
      <template v-slot:content>
        <v-card v-if="bonus.length > 0" class="mx-auto elevation-5 " outlined>
      <div>

        <div class="elevation-5">
          <div class="card-body text-lg-center font-size-x  ">
            <v-progress-linear indeterminate color="cyan" v-if="!headers_bono_ind.length && loading"></v-progress-linear>

            <!-- <h2 class="h2">Tiempo de Entrega de Pedidos en los últimos 3 meses</h2> -->
            <v-simple-table class="d-none d-sm-block" dense  v-if="headers_bono_ind.length && !loading">
              <template v-slot:default>
                <thead>
                  <tr class="font-weight-bold font-size-x text-black">
                    <td v-for="(item, index) in headers_bono_ind" :key="index" :colspan="index == 0 ? '1' : '0'"
                      class="box-shadow ml-10 mr-10 ">{{ item.text.replaceAll(" ","&nbsp;") }}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr class=" no-border custom-body-hs" v-for="(item, index) in bonus" :key="index">
                    <td class="font-weight-bold text-left font-weight-regular">
                      <div class="d-flex">
                          <div class="align-self-center">
                            <span :class="'justify-start mr-auto'">{{ item.TITULO.replaceAll(" ", "&nbsp;") }}</span>
                          </div>
                          <div class="ml-auto align-selft-center">
                            <span class="text-center ml-1 mr-1" v-if="index > 0 && index < 4">
                            <v-icon @click="plusBotton(item.TITULO)">mdi-plus-box-outline</v-icon>
                          </span> 
                          </div>
                      </div> 
                    </td> 
                    <td class="pa-1">
                      <span v-if="index == 0" class="text-decoration-underline">
                        <a @click="getBenefits(4)"> ${{ number_format(item.PERIODO4) }} </a>
                      </span>
                      <span v-else-if="index <= 3">
                        <span class="text-decoration-underline ">
                          <a @click="indicatorsPaymentDetails(1,index)">${{ number_format(item.PERIODO4) }}</a></span>&nbsp;/&nbsp;<span
                          :class="item.PORC4 < 100 ? 'text-danger' : 'text-success'">{{ porc(item.PORC4) }}%</span>
                      </span>
                      <span v-else>
                        {{ item.PERIODO4 }}
                      </span>
                      <transition name="slide-fade">
                        <TableMontly v-if="item.TITULO == showPayment" :calculateIndicators="calculateIndicators"
                          :months="indicatorsPerMonth.body.per4" :body="indicatorsPerMonth.totals.per4"
                          :indicatorsPerMonth="indicatorsPerMonth.headers.per4"> </TableMontly>
                      </transition>
                    </td>
                    <td class="pa-1">
                      <span v-if="index == 0" class="text-decoration-underline">
                        <a @click="getBenefits(3)"> ${{ number_format(item.PERIODO3) }} </a>
                      </span>
                      <span v-else-if="index <= 3">
                        <span class="text-decoration-underline ">
                          <a @click="indicatorsPaymentDetails(2,index)">${{ number_format(item.PERIODO3) }}</a></span>&nbsp;/&nbsp;<span
                          :class="item.PORC3 < 100 ? 'text-danger' : 'text-success'">{{ porc(item.PORC3) }}%</span>
                      </span>
                      <span v-else>
                        {{ item.PERIODO3 }} 
                      </span>
                      <transition name="slide-fade">
                        <TableMontly v-if="item.TITULO == showPayment" :calculateIndicators="calculateIndicators"
                          :months="indicatorsPerMonth.body.per3" :body="indicatorsPerMonth.totals.per3"
                          :indicatorsPerMonth="indicatorsPerMonth.headers.per3"> </TableMontly>
                      </transition>
                    </td>
                    <td class="pa-1">
                      <span v-if="index == 0" class="text-decoration-underline">
                       <a @click="getBenefits(2)"> ${{ number_format(item.PERIODO2) }} </a>
                      </span>
                      <span v-else-if="index <= 3">
                        <span class="text-decoration-underline  ">
                          <a @click="indicatorsPaymentDetails(3,index)">${{ number_format(item.PERIODO2) }}</a></span>&nbsp;/&nbsp;<span
                          :class="item.PORC2 < 100 ? 'text-danger' : 'text-success'">{{ porc(item.PORC2) }}%</span>
                      </span>
                      <span v-else>
                        {{ item.PERIODO2 }} 
                      </span>
                      <transition name="slide-fade">
                        <TableMontly v-if="item.TITULO == showPayment" :calculateIndicators="calculateIndicators"
                          :months="indicatorsPerMonth.body.per2" :body="indicatorsPerMonth.totals.per2"
                          :indicatorsPerMonth="indicatorsPerMonth.headers.per2"> </TableMontly>
                      </transition>
                    </td>
                    <td class="pa-1">
                      <span v-if="index == 0" class="text-decoration-underline">
                        <a @click="getBenefits(1)"> ${{ number_format(item.PERIODO1) }} </a> 
                      </span>
                      <span v-else-if="index <= 3">
                        <span class="text-decoration-underline ">
                          <a @click="indicatorsPaymentDetails(4,index)">${{ number_format(item.PERIODO1) }}</a></span>&nbsp;/&nbsp;<span
                          :class="item.PORC1 < 100 ? 'text-danger' : 'text-success'">{{ porc(item.PORC1) }}%</span>
                      </span>
                      <span v-else>
                        {{ item.PERIODO1 }}
                      </span>
                      <transition name="slide-fade">
                        <TableMontly v-if="item.TITULO == showPayment" :calculateIndicators="calculateIndicators"
                          :months="indicatorsPerMonth.body.per1" :body="indicatorsPerMonth.totals.per1"
                          :indicatorsPerMonth="indicatorsPerMonth.headers.per1"> </TableMontly>
                      </transition>
                    </td> 
                  
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <!-- mobile-view -->
            <v-simple-table class="d-sm-none" dense  v-if="headers_bono_ind.length && !loading">
              <template v-slot:default>
                <thead>
                  
                </thead>
                <tbody  v-for="(item, index) in bonus" :key="index">
                  <tr>
                      <td colspan="4" class="font-weight-bold text-left font-weight-regular">
                        <div class="d-flex ">
                          <span class="mr-auto">
                            {{ item.TITULO.replaceAll(" ", "&nbsp;") }}
                            </span>
                            <span class="text-right">
                            <v-icon @click="plusBotton(item.TITULO)" color="#C82333">mdi-plus-box-outline</v-icon>
                          </span>
                        </div> 
                      </td>
                  </tr>
                  <tr class=" no-border custom-body-hs">
                         <td v-for="(hitem, hindex) in headers_bono_ind.slice(1)" :key="hindex"  class="font-weight-bold font-size-x text-black box-shadow" >
                          {{ hitem.text }}  
                         </td>  
                    </tr>
                    <tr> 
                    <td class="pa-0">
                      <span v-if="index == 0" class="text-decoration-underline">
                        <a @click="getBenefits(1)"> ${{ number_format(item.PERIODO1) }} </a> 
                      </span>
                      <span v-else-if="index <= 3">
                        <span class="text-decoration-underline text-yellow-gold">
                          <a @click="indicatorsPaymentDetails(4,index)"> ${{ number_format(item.PERIODO1) }} </a></span> / <span
                          :class="item.PORC1 < 100 ? 'text-danger' : 'text-success'">{{ porc(item.PORC1) }}%</span>
                      </span>
                      <span v-else>
                        {{ item.PERIODO1 }}
                      </span>
                      <transition name="slide-fade">
                        <TableMontly v-if="item.TITULO == showPayment" :calculateIndicators="calculateIndicators"
                          :months="indicatorsPerMonth.body.per1" :body="indicatorsPerMonth.totals.per1"
                          :indicatorsPerMonth="indicatorsPerMonth.headers.per1"> </TableMontly>
                      </transition>
                    </td>
                    <td class="pa-0">
                      <span v-if="index == 0" class="text-decoration-underline">
                       <a @click="getBenefits(2)"> ${{ number_format(item.PERIODO2) }} </a>
                      </span>
                      <span v-else-if="index <= 3">
                        <span class="text-decoration-underline text-yellow-gold"><a @click="indicatorsPaymentDetails(3,index)">
                            ${{ number_format(item.PERIODO2) }} </a></span> / <span
                          :class="item.PORC2 < 100 ? 'text-danger' : 'text-success'">{{ porc(item.PORC2) }}%</span>
                      </span>
                      <span v-else>
                        {{ item.PERIODO2 }} 
                      </span>
                      <transition name="slide-fade">
                        <TableMontly v-if="item.TITULO == showPayment" :calculateIndicators="calculateIndicators"
                          :months="indicatorsPerMonth.body.per2" :body="indicatorsPerMonth.totals.per2"
                          :indicatorsPerMonth="indicatorsPerMonth.headers.per2"> </TableMontly>
                      </transition>
                    </td>
                    <td class="pa-0">
                      <span v-if="index == 0" class="text-decoration-underline">
                        <a @click="getBenefits(3)"> ${{ number_format(item.PERIODO3) }} </a>
                      </span>
                      <span v-else-if="index <= 3">
                        <span class="text-decoration-underline text-yellow-gold"><a @click="indicatorsPaymentDetails(2,index)">
                            ${{ number_format(item.PERIODO3) }} </a></span> / <span
                          :class="item.PORC3 < 100 ? 'text-danger' : 'text-success'">{{ porc(item.PORC3) }}%</span>
                      </span>
                      <span v-else>
                        {{ item.PERIODO3 }} 
                      </span>
                      <transition name="slide-fade">
                        <TableMontly v-if="item.TITULO == showPayment" :calculateIndicators="calculateIndicators"
                          :months="indicatorsPerMonth.body.per3" :body="indicatorsPerMonth.totals.per3"
                          :indicatorsPerMonth="indicatorsPerMonth.headers.per3"> </TableMontly>
                      </transition>
                    </td>
                    <td class="pa-0">
                      <span v-if="index == 0" class="text-decoration-underline">
                        <a @click="getBenefits(4)"> ${{ number_format(item.PERIODO4) }} </a>
                      </span>
                      <span v-else-if="index <= 3">
                        <span class="text-decoration-underline text-yellow-gold"><a @click="indicatorsPaymentDetails(1,index)">
                            ${{ number_format(item.PERIODO4) }} </a></span> / <span
                          :class="item.PORC4 < 100 ? 'text-danger' : 'text-success'">{{ porc(item.PORC4) }}%</span>
                      </span>
                      <span v-else>
                        {{ item.PERIODO4 }}
                      </span>
                      <transition name="slide-fade">
                        <TableMontly v-if="item.TITULO == showPayment" :calculateIndicators="calculateIndicators"
                          :months="indicatorsPerMonth.body.per4" :body="indicatorsPerMonth.totals.per4"
                          :indicatorsPerMonth="indicatorsPerMonth.headers.per4"> </TableMontly>
                      </transition>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>  
          </div>
        </div>
      </div>
        </v-card>
        <v-dialog v-model="modalPaymentDetails" :persistent="modalPaymentDetailsLoader" width="auto">
          <PaymentBonusDetailsTable :loading="modalPaymentDetailsLoader" :paymentDetails="paymentDetails"
            :title="paymentTypeTitle" v-on:closeModal="closePaymentModal"></PaymentBonusDetailsTable>
        </v-dialog> 
        <v-dialog v-model="modalPaymentTypes" :persistent="false" width="auto">
          <v-card class="pa-0 ma-0">
            <v-toolbar :dark=true>
                <v-toolbar-title>
                  <v-progress-circular v-if="loadingBenefits"  indeterminate color="primary" ></v-progress-circular>
     Detalles de pago del trimestre {{ benefits.period }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn class="right" @click="modalPaymentTypes = false" icon>
                  <v-icon>mdi-close-circle</v-icon>
                </v-btn>
              </v-toolbar> 
            <v-card-text class="pa-0">
              <v-simple-table dense v-if="!loadingBenefits">
          <template v-slot:default>
            <tbody class="custom-table-bordered">
              <tr v-for="(value,index) in benefits.details" :key="index">
                <td :class="(index%2==0?'table-heading':'')+' small text-body-2 pb-4 pt-4'">{{ value.title }}</td>
                <td :class="(index%2==0?'table-heading':'')+' text-body-2'">${{number_format(value.amount,false) }}</td>
              </tr>    
            </tbody>
            <tbody v-if="loading">
              <tr>
                <td colspan="7"> <v-progress-linear class="mt-3" indeterminate color="teal"></v-progress-linear> <span
                    class="text--disabled"> Cargando información</span></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
            </v-card-text>  
          </v-card>
        </v-dialog>
      </template>
    </CardTitle> 
  </div>
</template>

<script>
import axios from "@/plugins/axios";
import { mapGetters } from "vuex"; 
import PaymentBonusDetailsTable from './components/PaymentBonusDetailsTable.vue'
import TableMontly from './components/TableMontly.vue'
import CardTitle from '@/components/CardTitle.vue' 
import HelperMixin from '@/mixins/helpers.js' 
export default {
  props: {
    generalInfoCustomer: { //para evaluacion PNC y REORDEN DE PROD.
            default: []
        }, 
  },
  mixins:[HelperMixin],
  watch: {
    generalInfoCustomer(){
      let self = this;
    self.getBonus();
    this.loaded = false;
    },
    showPaymentWithoutIVA(value) {
      if (value) {
        for (let i = 1; i <= 4; i++) {
          this.getIndicatorByMonth(i);
        }
      }
    },
    showPaymentIEL(value) {
      if (value) {
        for (let i = 1; i <= 4; i++) {
          this.getIndicatorByMonth(i, 2);
        }
      }
    },
    showPaymentSPEI(value) {
      if (value) {
        for (let i = 1; i <= 4; i++) {
          this.getIndicatorByMonth(i, 3);
        }
      }
    },
    allPayments(value) {
      if (value) {
        for (let i = 1; i <= 4; i++) {
          this.getIndicatorByMonth(i, 4);
        }
      }
    }
  },
  components: {
    PaymentBonusDetailsTable, TableMontly, CardTitle,
  },
  data() {
    return {
      paymentType:[  'PIUSAL','PSPEI','ALL' ],
      benefits:{},
      loadingBenefits:false,
      modalPaymentDetailsLoader: false,
      modalPaymentTypes:false,
      title: "Pagos IEL + SPEI",
      bonus: [], 
      calculateIndicators: false,
      paymentTypeTitle: "",
      showPaymentWithoutIVA: false,
      showPaymentIEL: false,
      showPaymentSPEI: false,
      allPayments: false,
      showPayment: "",  
      paymentDetails: [],
      modalPaymentDetails: false,
      indicatorsPerMonth: {
        "body": { per1: '', per2: '', per3: '', per4: '' },
        "headers": { per1: '', per2: '', per3: '', per4: '' },
        "totals": { per1: '', per2: '', per3: '', per4: '' },
      },
      search: "", 
      per_1: null,
      per_2: null,
      per_3: null,
      per_4: null,
      loading: false,
      loaded: false,
      isVisible: false,
      headers_bono_ind: [],
      select_bono_ind: [
        "Pagos IEL + SPEI",
        "Pagos vía IEL",
        "Pagos vía SPEI",
      ],
      trims: []
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {
    plusBotton(title) {
      this.showPayment = title == this.showPayment ? "" : title;
      switch (title) {
        case this.bonus[0].TITULO:
          this.showPaymentWithoutIVA = true;
          this.showPaymentIEL = false;
          this.showPaymentSPEI = false;
          this.allPayments = false;
          break;
        case this.bonus[1].TITULO:
          this.showPaymentWithoutIVA = false;
          this.showPaymentIEL = true;
          this.showPaymentSPEI = false;
          this.allPayments = false;
          break;
        case this.bonus[2].TITULO:
          this.showPaymentWithoutIVA = false;
          this.showPaymentIEL = false;
          this.showPaymentSPEI = true;
          this.allPayments = false;
          break;
        case this.bonus[3].TITULO:
          this.showPaymentWithoutIVA = false;
          this.showPaymentIEL = false;
          this.showPaymentSPEI = false;
          this.allPayments = true;
          break;
        default:
          this.showPaymentWithoutIVA = false;
          this.showPaymentIEL = false;
          this.showPaymentSPEI = false;
          this.allPayments = true;
          break;
      }
    },
    
    getIndicatorByMonth(period, type = 1) { //1 sin iva, 2 IEL, 3 SPEI, 4 SPEI+IEL
    //  console.log(this.generalInfoCustomer)
      this.calculateIndicators = true;
      this.paymentDetails = [];
      const per = this.trims[period - 1][period];

      const year = 20 + per.slice(-2)
      const trim = this.getPeriodInString(per.slice(0, -3))
      const trims = [[1, 2, 3], [4, 5, 6], [7, 8, 9], [10, 11, 12]]
      var periods = []
      const params = {
        customer_code: this.generalInfoCustomer.KUNNR,
        trim: trim,
        year: year
      }
      axios.post("/indicatorsPayment/details", params)
        .then((response) => {
          const paymentDetails = (response.data.items == 1) ? [response.data.data] : response.data.data;


          for (let i = trims[trim - 1][0]; i <= trims[trim - 1][2]; i++) {

            switch (type) {
              case 1: //1 sin iva, 
                periods.push(paymentDetails.filter(item => parseInt(item.MESDOC.slice(0, 2)) == i)) //parsed to int
                break;
              case 2: //2 IEL, 
                periods.push(paymentDetails.filter(item => (parseInt(item.MESDOC.slice(0, 2)) == i && item.TIPOP == "PIUSAL"))) //parsed to int
                break;
              case 3: //3 SPEI, 
                periods.push(paymentDetails.filter(item => (parseInt(item.MESDOC.slice(0, 2)) == i && item.TIPOP == "PSPEI"))) //parsed to int
                break;
              default:
                periods.push(paymentDetails.filter(item => parseInt(item.MESDOC.slice(0, 2)) == i)) //parsed to int
                break;
            }
          }
          // var period1 = period.push  
          switch (period) {
            case 4:
              this.indicatorsPerMonth.body.per1 = periods;
              this.indicatorsPerMonth.headers.per1 = this.getMonthNamesByPeriod(period);
              this.indicatorsPerMonth.totals.per1 = this.getTotalByMonth(periods);
              break;
            case 3: this.indicatorsPerMonth.body.per2 = periods;
              this.indicatorsPerMonth.headers.per2 = this.getMonthNamesByPeriod(period);
              this.indicatorsPerMonth.totals.per2 = this.getTotalByMonth(periods);
              break;
            case 2: this.indicatorsPerMonth.body.per3 = periods;
              this.indicatorsPerMonth.headers.per3 = this.getMonthNamesByPeriod(period);
              this.indicatorsPerMonth.totals.per3 = this.getTotalByMonth(periods);
              break;
            default: this.indicatorsPerMonth.body.per4 = periods;
              this.indicatorsPerMonth.headers.per4 = this.getMonthNamesByPeriod(period);
              this.indicatorsPerMonth.totals.per4 = this.getTotalByMonth(periods);
              break;
          }
          this.calculateIndicators = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
          this.calculateIndicators = false;
        })
        .finally(() => {
          this.loading = false;
          this.calculateIndicators = false;
        });
    },
    
    getTotalByMonth(items) {
      //console.log('items') 

      var months = [];
      for (let i = 0; i < items.length; i++) {
        if (items[i].length == 1) {
          months.push(parseFloat(items[i][0].IMPXIVA));
        } else {
          let sum = 0;
          for (let j = 0; j < items[i].length; j++) {
            sum = sum + parseFloat(items[i][j].IMPXIVA);
          }
          months.push(sum) //parsed to int ;
        }
      }
      //    console.log(months)
      return months;
    },
    getMonthNamesByPeriod(period) {
      var currentTrim = (this.trims[period - 1][period]).split("/")
      var months = currentTrim[0].split("-");
      var year = "20" + currentTrim[1];
      for (let i = 0; i < months.length; i++) {
        months[i] = months[i] + "/" + year;
      }
      return months;
    },
    getBenefits(quarter){
      this.modalPaymentTypes=true;
      this.loadingBenefits =true;
      axios.post("/cover/benefitDetails", { 
        customer_id: this.generalInfoCustomer.KUNNR , 
        channel:this.generalInfoCustomer.VTWEG, 
        period:quarter})
           .then((response) => {
            if (response.data?.success==false) {
                console.log( response.data)
            } else {
              this.benefits = response.data;
            } 
            this.loadingBenefits =false;
          })
          .catch((e) => {
            console.log(e);
            this.loadingBilling =false;
          });  
    },
    closePaymentModal() {
      this.modalPaymentDetails = false;
    },
    getPeriodInString(period) {
      switch (period) {
        case 'Ene-Feb-Mar': period = 1; break;
        case 'Abr-May-Jun': period = 2; break;
        case 'Jul-Ago-Sep': period = 3; break;
        default: period = 4; break;
      }
      return period;
    },
    indicatorsPaymentDetails(period,type) { //1 sin iva, 2 spei, 3 IEL+SPEI
     
      this.modalPaymentDetailsLoader = true;
      this.modalPaymentDetails = true;
      this.paymentDetails = [];
      const per = this.trims[period - 1][period];
      const year = 20 + per.slice(-2)
      const trim = this.getPeriodInString(per.slice(0, -3))
      const params = {
        customer_code: this.generalInfoCustomer.KUNNR,
        trim: trim,
        year: year
      }
      this.paymentTypeTitle = "Detalle de pagos del cliente "+parseInt(this.generalInfoCustomer.KUNNR)+" del trimestre " + year+'/'+trim;
      axios.post("/indicatorsPayment/details", params)
        .then((response) => {       
          let allPayments = (response.data.items == 1) ? [response.data.data] : response.data.data;
          this.paymentDetails = type!=3 ? allPayments.filter(item =>  item.TIPOP == this.paymentType[type-1]) : allPayments;    
          this.modalPaymentDetailsLoader = false;
        })
        .catch((e) => {
          console.log(e);
          this.modalPaymentDetailsLoader = false;
        });
    },
    getBonus() { 
      axios
        .post("indicatorsPayment", {
          client_code: this.generalInfoCustomer.KUNNR,
        },{
          noCancelRequest:true,
          cachable:true,
        })
        .then((response) => {
          var headerBonus = response.data.header;
          this.bonus = response.data.data;
          this.per_1 = headerBonus[0].PERIODO4;
          this.per_2 = headerBonus[0].PERIODO3;
          this.per_3 = headerBonus[0].PERIODO2;
          this.per_4 = headerBonus[0].PERIODO1;
          this.trims = [
            { 1: headerBonus[0].PERIODO4 },
            { 2: headerBonus[0].PERIODO3 },
            { 3: headerBonus[0].PERIODO2 },
            { 4: headerBonus[0].PERIODO1 },
          ]
          this.headers_bono_ind = [
            {
              text: headerBonus[0].TITULO,
              align: "start",
              value: "TITULO",
              class: "border-bottom: none;",
              sortable: false,
            },
            { text: headerBonus[0].PERIODO4, sortable: false, value: "PERIODO4", align: "center", },
            { text: headerBonus[0].PERIODO3, sortable: false, value: "PERIODO3", align: "center", },
            { text: headerBonus[0].PERIODO2, sortable: false, value: "PERIODO2", align: "center", },
            { text: headerBonus[0].PERIODO1, sortable: false, value: "PERIODO1", align: "center", },
          ] 
        })
        .catch((e) => {
          console.log(e);
        });
    },
    format(value) {
      let result = Number(value).toFixed(2);
      return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    porc(value) {
      let result = Number(value).toFixed(0);
      return result;
    },
    docs(value) {
      let result = Number(value).toFixed(0);
      return result;
    }, 

  },

};
</script>
<style src="@/assets/css/customerHome.css" scoped></style> 