<template>
  <div>
      <v-card elevation="0" flat> 
            <v-card-text class="d-flex flex-wrap"> 
              <v-simple-table class="mt-4 my-table" dense>
                <template v-slot:default>
                  <thead class="table-heading">
                    <tr class="th-auto">
                      <th rowspan="2"  class="text-center text-dark boder-x-none">   
                        <div class="d-flex justify-center ">
                          <div class="align-self-end">
                            <b>  Zona</b>
                            <span class="d-flex justify-center ma-0">
                              <v-btn :disabled="loading" @click="ordering('VORNA',true)" icon color="disabled">
                                <v-icon size="18">mdi-arrow-{{orderBy.VORNA.asc?'up':'down'}}</v-icon>
                              </v-btn>
                            </span>
                          </div> 
                        </div>  
                      </th>
                      <th rowspan="2" class="text-center boder-x-none text-dark" >
                        <div class="d-flex justify-center ">
                          <div class="align-self-end">
                            <b>Antigüedad<br />en&nbsp;meses&nbsp;/<br />Ingreso</b>
                            <span class="d-flex justify-center ma-0">
                                <v-btn  :disabled="loading"  @click="ordering('ANTIG')" icon color="disabled">
                                <v-icon size="18">mdi-arrow-{{orderBy.ANTIG.asc?'up':'down'}}</v-icon>
                                <!-- <v-icon>mdi-menu-up</v-icon> -->
                              </v-btn>
                            </span>
                          </div> 
                        </div>  
                      </th>
                      <th rowspan="2" class="text-center boder-x-none text-dark" >
                        <b >#&nbsp;de&nbsp;Clientes&nbsp;Sist/Act<br />con&nbsp;Venta<br /> {{ getMonths().replaceAll(' ','&nbsp;') }}</b >
                      </th>
                      <th rowspan="2" class="text-center boder-x-none text-dark" >
                        <div class="d-flex  justify-center">
                          <div class="align-self-end">
                            <b >{{ ('% de clientes con').replaceAll(' ','&nbsp;')}}  <br>   
                          {{ ('pedidos / avance ').replaceAll(' ','&nbsp;')}}<br>
                          {{ ('contra promedio 6 ').replaceAll(' ','&nbsp;')}}<br> meses. 
                        </b >
                            <span class="d-flex justify-center ma-0">
                              <v-btn   :disabled="loading"  @click="ordering('AVPROM6')" icon color="disabled">
                                <v-icon size="18">mdi-arrow-{{orderBy.AVPROM6.asc?'up':'down'}}</v-icon>
                              </v-btn>
                            </span>
                          </div> 
                        </div>   
                      </th>
                      <th rowspan="2" class="text-center boder-x-none text-dark">
                        <b>%&nbsp;de<br />clientes&nbsp;con <br>pago&nbsp;vía<br />IEL</b>
                      </th>
                      <th rowspan="2" class="text-center boder-x-none text-dark">
                        <b>Pedidos<br />de&nbsp;{{months[1].full_name}}</b>
                      </th>
                      <th rowspan="2" class="text-center boder-x-none text-dark">
                        <div class="d-flex justify-center">
                          <div class="align-self-end">
                            <b>Pedidos<br />del&nbsp;día<br />anterior</b>
                            <span class="d-flex justify-center ma-0">
                              <v-btn   :disabled="loading"  @click="ordering('PEDDIAA')"  icon color="disabled">
                                <v-icon size="18">mdi-arrow-{{orderBy.PEDDIAA.asc?'up':'down'}}</v-icon>
                              </v-btn>
                            </span>
                          </div> 
                        </div>  
                      </th>
                      <th colspan="3" class="text-center boder-x-none text-dark h-auto">
                        <b>Crecimientos por:</b>
                        <div class="d-flex d-nowrap pl-10 pr-10 pb-n3 pt-n3">  
                            <v-checkbox v-model="isBillingGrowth" dense class="mt-n2 mb-n4" >
                              <template v-slot:label>
                                <span class="text-black font-weight-bold font-size-8">Facturación</span>
                              </template>
                            </v-checkbox> 
                            <div class="ml-auto">
                              <v-checkbox v-model="isOrderGrowth" dense  class="mt-n2 mb-n4" >
                          <template v-slot:label>
                            <span class="text-black font-weight-bold font-size-8">Pedidos</span>
                          </template>
                        </v-checkbox> 
                        </div> 
                      </div> 
                      </th>
                    </tr>
                    <tr class="custom-row2 th-auto">
                      <th class="text-center boder-x-none text-dark "> 
                        <div class="d-flex justify-center">
                          <div class="align-self-end">
                            <b class="font-size-9">Acum&nbsp;{{months[1].date}}</b>
                            <span class="d-flex justify-center ma-0">
                              <v-btn  :disabled="loading" @click="ordering( isBillingGrowth?'PORCREC':'CRECPED')" icon color="disabled">
                                <v-icon v-if="isBillingGrowth" size="18">mdi-arrow-{{orderBy.PORCREC.asc?'up':'down'}}</v-icon>
                                <v-icon v-else size="18">mdi-arrow-{{orderBy.CRECPED.asc?'up':'down'}}</v-icon>
                              </v-btn>
                            </span>
                          </div> 
                        </div>  
                      </th>
                      <th class="text-center boder-x-none text-dark">
                        <div class="d-flex justify-center">
                          <div class="align-self-end">
                            <b class="font-size-9">Últ&nbsp;3 meses</b>
                            <span class="d-flex justify-center ma-0">
                              <v-btn :disabled="loading" @click="ordering( isBillingGrowth?'CRECFA3':'CRECPE3')"  icon color="disabled">
                                <v-icon v-if="isBillingGrowth" size="18">mdi-arrow-{{orderBy.CRECFA3.asc?'up':'down'}}</v-icon>
                                <v-icon v-else size="18">mdi-arrow-{{orderBy.CRECPE3.asc?'up':'down'}}</v-icon>
                              </v-btn>
                            </span>
                          </div> 
                        </div> 
                      </th>
                      <th class="text-center boder-x-none text-dark "> 
                      <div class="d-flex justify-center">
                          <div class="align-self-end">
                            <b class="font-size-9">Últ&nbsp;mes&nbsp;vs&nbsp;3 <br> anteriores</b>
                            <span class="d-flex justify-center ma-0">
                              <v-btn :disabled="loading" @click="ordering( isBillingGrowth?'CRECFA1X3':'CRECPE1X3')"   icon color="disabled">
                                <v-icon v-if="isBillingGrowth" size="18">mdi-arrow-{{orderBy.CRECFA1X3.asc?'up':'down'}}</v-icon>
                                <v-icon v-else size="18">mdi-arrow-{{orderBy.CRECPE1X3.asc?'up':'down'}}</v-icon>
                              </v-btn>
                            </span>
                          </div> 
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="!loading" class="custom-white-table">
                    <tr v-for="(seller,index) in filteredSellers" :key="index">
                      <td style="border-width: 0px 0px 1px 1px">
                        <span class="d-flex">
                          <span class="mt-3 mr-n2 font-weight-bold" style="width:25px">{{index+1}}</span>&nbsp;
                          <v-btn @click="showBillingByDiv(seller.PERNR)" icon>
                            <v-icon color="#B71C1C">mdi-chart-pie</v-icon>
                          </v-btn>
                          <div class="ml-1 mr-1 d-flex align-start flex-column">
                              <div class="text-capitalize font-weight-bold text-decoration-underline">
                                <a @click="showSeller(seller.PERNR)">
                                {{ capitalize(seller.VORNA+' '+seller.NACHN+" "+seller.NACH2).replaceAll(' ','&nbsp;') }} 
                              </a>
                            </div> 
                            <div >
                            <v-icon>mdi-car</v-icon>
                            <v-icon :color="traffigLightColor(seller.SEMAFO)" size="15">mdi-circle</v-icon>
                            <span  style="font-size: 0.7em; font-weight: 750" class="font-weight-bold text-left text-uppercase"> 
                             {{  (seller.BZIRK)  }} 
                            </span>
                          </div> 
                          </div>
                          
                          <div class="text-right ml-auto">
                          <b  class="text-decoration-underline " @click="evalFactAnt(seller.PERNR,seller.NHAB)">{{ number_format(seller.NHAB) }} h</b>  <br />

                            <div  v-if="editHabSeller==seller.PERNR" class="d-flex d-inline">
                              <div class="text-left" style="font-size: 12px"> 
                                <v-btn icon :disabled="UpdatingCitizens" @click="editHabSeller=0"> <v-icon color="red">mdi-close</v-icon> </v-btn> 
                              </div>
                              <div class="d-flex align-items-center "> 
                                  <v-text-field                      
                                    hide-details="auto"
                                    v-model="number_citizen"
                                    solo dense
                                    :disabled="UpdatingCitizens"
                                    style="width:70px"
                                    @input="filterNumber(number_citizen)"
                                  ></v-text-field>
                                  <v-btn icon  @click="updateSellerDetails(index)" :loading=" UpdatingCitizens"> 
                                  <v-icon class="ml-1">mdi mdi-send-circle</v-icon>
                                  </v-btn>
                              </div>
                            </div>
                            <b v-else > ${{number_format(seller.DAT01)}}&nbsp;S/h</b>   
                          </div>
                        </span>
                      </td> 
                      <td>
                        <span style="display: grid">
                          <span class="font-weight-bold" style="font-size: 0.7em">{{ getNumberOfMonthsFromDate(seller.ANTIG).diff }} </span>
                          <span class="font-weight-bold font-size-85">${{seller.INGPAG}}
                          </span>
                        </span>
                      </td>
                      <td>
                        <span class="font-weight-bold font-size-85">
                          <span>{{seller.CTESIS}}&nbsp;/&nbsp;{{ seller.CTEACT }}</span>
                          <br />
                          <span class="text-danger">{{seller.CTEACT}}</span>&nbsp;/
                          <span class="text-success">{{seller.CTEACT1}}</span>&nbsp;/&nbsp;
                          <span class="">{{seller.CTEACT2}}</span>&nbsp;/&nbsp;
                          <span class="">{{seller.CTEACT3}}</span>&nbsp;/
                          <span class="">{{seller.CTEACT4}}</span>
                        </span>
                      </td>
                      <td style="font-size: 0.75em" class="font-weight-bold">
                        <span>{{number_format(seller.PORCTEPED,false)}} % &nbsp;/</span> <br />
                        <span class="text-success">{{number_format(seller.AVPROM6,false)}}%</span>
                      </td>
                      <td class="font-size-85">
                        <span class="text--disabled text-decoration-underline">
                          <a @click="showCustomersViaIEL(seller.PERNR)">{{number_format(seller.PORPIEL)}}%</a></span>
                      </td>
                      <td class="font-size-85">
                        <span class="text-black">${{number_format(seller.PEDMESA)}}</span>
                      </td>
                      <td class="font-weight-bold text-black font-size-85">
                        <span>${{ number_format(seller.PEDDIAA)}}</span>
                      </td>
                      <template v-if="isBillingGrowth">
                      <td class="text-decoration-underline font-weight-bold text-black font-size-85">
                        <a class="" @click="showCumulativeGrowth(seller.PERNR)"> <span>{{number_format(seller.PORCREC,false)}}%</span> </a> 
                      </td>
                      <td class="text-decoration-underline font-weight-bold text-black font-size-85">
                        <a class="" @click="showCumulativeGrowth(seller.PERNR,'_3M')"><span>{{number_format(seller.CRECFA3,false)}}%</span></a>
                      </td> 
                      <td class="text-decoration-underline font-weight-bold text-black font-size-85">
                        <a class="" @click="showCumulativeGrowth(seller.PERNR,'_1X3')"><span>{{number_format(seller.CRECFA1X3,false)}}%</span></a>
                      </td>
                      </template>
                      <template v-else>
                        <td class="text-decoration-underline font-weight-bold text-black font-size-85">
                          <a class="" @click="showCumulativeGrowth(seller.PERNR)"><span>{{number_format(seller.CRECPED,false)}}%</span></a>
                      </td>
                      <td class="text-decoration-underline font-weight-bold text-black font-size-85">
                        <a class="" @click="showCumulativeGrowth(seller.PERNR,'_3M')"><span>{{number_format(seller.CRECPE3,false)}}%</span></a>
                      </td> 
                      <td class="text-decoration-underline font-weight-bold text-black font-size-85">
                        <a class="" @click="showCumulativeGrowth(seller.PERNR,'_1X3')"><span>{{number_format(seller.CRECPE1X3,false)}}%</span></a>
                      </td>
                      </template>
                    </tr> 
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="10">
                        <v-progress-linear indeterminate color="cyan"></v-progress-linear>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <hr /> 
            </v-card-text> 
        </v-card>
        <v-dialog v-model="showModal" width="auto" :persistent="showPopulationInput" >
        <v-card class="pb-4">
          <div v-if="showPopulationInput" class="mb-3">
            <v-card-text>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field v-model="populationInput" :counter="10" :rules="populationRules" required ></v-text-field>
                <v-btn :disabled="!valid" color="success" class="mr-4" @click="validate" >
                  Guardar
                </v-btn>
                <v-btn color="warning" @click="resetValidation">
                  Cancelar
                </v-btn>
              </v-form>
            </v-card-text>
          </div>   

          <PaymentsViaIELSeller v-if="paymentInfo" 
          @closeModal="closeModal()"
          :period="months[0]" 
          :seller="sellerSelected" 
          :modal="showPopulationInput"
          :channel="chnl"
          :months="months"
          />

          <CumulativeGrowth v-if="cumulativeGrowthModal" 
          :title="`Detalle de crecimiento constante por asesor ${cumulativeGrowthSeller} del mes: ${months[0].date}`"
          :isBilling="isBillingGrowth?1:0" 
          :seller="cumulativeGrowthSeller"
          :channel="chnl"
          :isSeller="1"
          :type="growthType"
          :period="months[0]" 
          :modal="cumulativeGrowthModal"
          @closeModal="closeModal()"
       />  
        </v-card>
    </v-dialog>

    <billing-by-area 
          :employeeNumber="billingByDivSeller" 
          :employeeChannel="chnl"
          :period="months[0]"
          v-model="billingByDivModal" 
          :title="`Detalle de zona ${billingByDivSeller}`"
      />

  </div>
</template>
<script>
 
import CumulativeGrowth from '@/views/manager/modals/CumulativeGrowth.vue';
import PaymentsViaIELSeller from '@/views/manager/modals/PaymentsViaIELSeller.vue';
import BillingByArea from '@/components/modals/BillingByArea.vue';
import MixinService from '@/mixins/service'
import MixinHelpers from '@/mixins/helpers'
import MixinDates from '@/mixins/dates';
import axios from "@/plugins/axios";
import moment from 'moment';
export default({
  name:'SellerList',
  mixins: [MixinService,MixinHelpers,MixinDates],
  props: {
  chnl: { 
        required:true,
        String
      },
      regionalManager:{
        default:0
      },
      period:{
        required:true
      }
    },
  components:{
      PaymentsViaIELSeller,
      CumulativeGrowth,
      BillingByArea
  },  
  data: () => ({
      valid: true,
      showModal: false,
      showPopulationInput: false,
      populationInput: "",  
      paymentInfo: false, 
      populationRules: [
      (v) => !!v || "Este campo es requerido",
      (v) =>
          (v && v.length <= 10) || "Este campo debe ser menor a 10 caracteres",
      ],
      months:[],
      sellers:[],
      filteredSellers:[],
      loading:true,
      isOrderGrowth:false,
      isBillingGrowth:true, 
      orderBy:{
               VORNA: {asc:false,column:'VORNA'},
               NHAB: {asc:false,column:'NHAB'},
               ANTIG: {asc:false,column:'ANTIG'},
               PEDDIAA: {asc:false,column:'PEDDIAA'},
               PEDMESA: {asc:false,column:'PEDMESA'}, 
               PORCREC: {asc:false,column:'PORCREC'},
               CRECFA3: {asc:false,column:'CRECFA3'},
               CRECFA1X3: {asc:false,column:'CRECFA1X3'},
               CRECPED: {asc:false,column:'CRECPED'},
               CRECPE3: {asc:false,column:'CRECPE3'},
               CRECPE1X3: {asc:false,column:'CRECPE1X3'},
               AVPROM6: {asc:false,column:'AVPROM6'},

            },
      year:2024,
      month:"03",
      monthsOption:[],
      yearsOptions:[],
      today:moment(),
      cumulativeGrowthModal:false,
      cumulativeGrowthSeller:0,
      growthType:0,
      sellerSelected:0,
      billingByDivModal:false,
      billingByDivSeller:0,
      editHabSeller: 0,   
      number_citizen: 0,
      UpdatingCitizens:false,

}),
created(){ 
    this.months = this.calcularUltimosMeses(5);  
    this.monthsOption = this.getMonthsFromYear(this.today.year()).reverse()
    this.yearsOptions = this.getYearsList(2)
    this.year= this.today.year()
    this.month= this.today.format('M') 
  }, 
  watch:{ 
    regionalManager(){
      this.loadSellers();
    } ,
    period(newValue,oldValue){
      if (newValue!=oldValue) {
        var period = this.removeTimeFromDate(this.period,'MMM YYYY','YYYYMM'); 
        this.year = period.slice(0,4)
        this.month = `${period.slice(-2)}`
        this.loadSellers();
      }
   
    },

  isOrderGrowth(value){ 
    if (value) {
      this.isBillingGrowth=false;
    }else{
      this.isBillingGrowth=true;
    }
  },
  isBillingGrowth(value){ 
    if (value) {
      this.isOrderGrowth=false;
    }else{
      this.isOrderGrowth=true;
    }
  },
  showModal(value){
    if (!value) {
    this.cumulativeGrowthModal = false;  
    this.paymentInfo = false;  
    this.billingByDivModal = false;
    }
  }

},
  computed:{

  },
  methods:{ 
    showSeller(seller_id){
      localStorage.setItem('currentSellerCode', JSON.stringify(seller_id)); 
      this.$router.push({name: 'SellerResults'});
    },
    filterNumber(number_citizen) {
      //Evitamos escribir todon lo que no sea números
      this.$nextTick(() => {
        this.number_citizen = number_citizen.replace(/\D/g, "")
      });
    },
    async updateSellerDetails(index){
    this.UpdatingCitizens=true;
      try {
        if(this.number_citizen != ''){
          let response = await axios.put(`seller/home/update/${this.editHabSeller}`, {
              number_citizen: this.number_citizen
          })
          if(response.data){
            this.UpdatingCitizens=false; 
            this.sellers[index].NHAB = this.number_citizen;
            this.sellers[index].DAT01 = this.number_format(this.sellers[index].FACNETA / this.number_citizen,false);
            this.editHabSeller = 0
          
          }
        }
      } catch (error) {
        this.editHabSeller = 0
        this.UpdatingCitizens= false; 
        console.error(error)
      }
       
    },
    evalFactAnt(seller,value){
      this.editHabSeller = Number(seller);
      this.number_citizen = value;
    },
    showCumulativeGrowth(seller,type=""){
    this.cumulativeGrowthModal = true; 
    this.cumulativeGrowthSeller =seller;
    this.growthType =type;
    this.showModal = true;
    },
    showBillingByDiv(seller){
      this.billingByDivModal= true;
      this.billingByDivSeller = seller;
    },
    getMonths(){ 
      var months = this.months;  
      return this.implode(this.arrayPluck(months,'short_name'),' / ') 
    },
    traffigLightColor(value){
      switch (value) {
        case "1":return "#FF0000";
        case "2":return "orange";
        case "3":return "#0BDA51"; 
        default: return "gray";
      } 
    },
    loadSellers(){  
    this.loading=true;

    axios.get('/daily-director/sellers',{
      params:{
        rgManager: this.regionalManager,
        channel:this.chnl,
        month:this.month,
        year:this.year, 
      }
      }).then((res) => { 
        this.sellers = res.data;
        this.filteredSellers = res.data;
        this.loading = false;
    })
    .catch((err) => { 
          console.error(err);
          this.loading = false;
        }) ;  
  },

  showCustomersViaIEL(customer) {
    this.sellerSelected = customer;
    this.showModal = true;
    this.paymentInfo = true;   
  },
  closeModal() {
   
    this.showModal = false;
  },
  validate() {
    this.$refs.form.validate();
    this.showPopulationInput = false;
    this.showModal = false;
  },
  resetValidation() {
    this.$refs.form.resetValidation();
    this.$refs.form.reset();
    this.showPopulationInput = false;
    this.showModal = false;
  },
  editPopulation(item) { 
    this.paymentInfo = false;
    this.showPopulationInput = true;
    this.populationInput = item;
    this.showModal = true;
  },

//** Ordering */ 
  ordering(column,isString=false){   
  var col = this.orderBy[column]  
  var ordered = this.sellers.sort((a, b) => {  
      if (isString) {
        const cmpareName = col.asc ? 
              a[column].localeCompare(b[column]):
              b[column].localeCompare(a[column]); 
          return cmpareName !== 0? cmpareName: a[column] - b[column];  
           
      }else{
        if (col.asc) { 
                  if ( Number(a[column]) <  Number(b[column])) return -1;
                  if ( Number(a[column]) >  Number(b[column])) return 1;
                  return 0;  
                }else{ 
                  if ( Number(a[column]) >  Number(b[column])) return -1;
                  if ( Number(a[column]) <  Number(b[column])) return 1;
                  return 0;
          } 
      }   
          });
this.orderBy[column].asc=!col.asc;
this.filteredSellers = ordered
    
  },

/** End ordering */
  },
  mounted(){
    //this.loadSellers();
  }
})
</script>

<style> 
.th-auto th {
height: auto !important;
padding-bottom: 0px !important;
} 
</style>