import axios from "@/plugins/axios";

export default {
  namespaced: true,
  state: {
    creditnotes: [],
    pagination: {
      page: 1,
      to: 1,
    },
    promissorynote: {},
  },

  getters: {
    allCreditNotes(state) {
        return state.creditnotes;
    },
    pagination(state) {
        return state.pagination;
    },
    customer(state) {
        return state.customer;
    },
  },

  mutations: {
    SET_CREDIT_NOTES(state, payload) {
        state.creditnotes = payload;
    },
    SET_PAGINATION(state, payload) {
        state.pagination.total = payload.to;
    },
    SET_CUSTOMER(state, payload) {
        state.customer = payload;
    },
  },

  actions: {
    async loadCustomerRequest({ commit }, payload) {
      let querySearch = '';
      if(payload && payload != '') {
        querySearch += '&filter[rfc]=%25' + payload + '%25';
      }
      return axios
        .get(`/promissory-notes?filter[custumer_status_id]=4,2${querySearch}`)
        .then((response) => {
          commit("SET_CREDIT_NOTES", response.data.data);
          commit("SET_PAGINATION", response.data);
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
    },
    async loadCustomerDetail({ commit }, payload) {
      return axios
        .get(`/customers/${payload}`)
        .then((response) => {
          commit("SET_CUSTOMER", response.data);
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
    },
    async updateCustomerRequest(_, {id, form}) {
        return axios
            .put(`/customers/{customer}/promissory-notes/${id}`, form)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                throw error;
            });
    }
  },
};
