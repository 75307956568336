<template>
    <v-container fluid class="grey lighten-5">
        <v-col class="">
            <v-card class="mx-auto rounded-lg elevation-4 ma-3 ml-5 mr-5" outlined
                :style="'border: 4px solid black; background-color:transparent;' ">
                <v-list-item-content>
                    <div class="text-general-module">
                        <v-icon color="black">mdi-account-circle</v-icon>
                        Cuenta 
                    </div>
                </v-list-item-content>
            </v-card> 
            <v-card  class="mx-auto rounded-xl elevation-5 ma-4 ml-4 mr-4" outlined>
                <div class="mb-4 mt-15 ">
                    <v-card class=" align-center justify-center elevation-0">
                        <v-dialog v-model="dialog" width="500">
                            <template v-slot:activator="{ on, attrs }">
                                <input type="file" ref="file" id="image" name="image" style="display: none"
                                    @change="onFileSelected">
                                <div class="d-flex justify-center">
                                    <span @click="$refs.file.click()" v-bind="attrs" v-on="on">
                                        <span class="text-profile-module" v-if="isHidden && !isHiddenProfile">
                                            Editar
                                            <v-icon color="black">
                                                mdi-square-edit-outline
                                            </v-icon><br>
                                            <label for="" class="mb-2 text-table-profile ml-10 mr-10">Tipo archivo: (.jpg .png) <v-icon>mdi-image</v-icon></label>
                                        </span>
                                    </span>
                                </div>
                                <div class="justify-center" v-bind:style="{ display: showPhoto ? 'block' : 'none' }">
                                    <v-avatar color="black" class="avatar-tag mb-3" :size="avatarSize">
                                        <img :src="urlServer + profile.image_url">
                                    </v-avatar>
                                </div>
                            </template>

                            <v-card>
                                <v-card height="50vh" v-if="img">
                                    <cropper class="cropper" :src="img" :stencil-props="{ aspectRatio: 1/1}"
                                        @change="change">
                                    </cropper>
                                </v-card>
                                <v-card v-else height="50vh"></v-card>
                                <v-card-actions>
                                    <v-btn color="primary" text @click="dialog = false">
                                        CANCELAR
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" text @click="onUpload()">
                                        OK
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-card>
                </div>
                <div class="mt-10 ma-4 mb-15">
                    <div class="text-justify text-general-module ma-4 ml-15" v-if="!isHidden">
                        <span class="ml-4 mr-4">DATOS PERSONALES</span>
                        <v-btn depressed v-on:click="selectEdit(1)">
                            Editar Datos
                            <v-icon style="margin-left: 0.5em;">
                                mdi-square-edit-outline
                            </v-icon>
                        </v-btn>
                    </div>
                    <div class="ma-4 ml-10 mb-4" v-if="!isHidden">
                        <v-simple-table class="table-profile mb-4 ml-10 mr-10">
                            <tbody>
                                <tr>
                                    <td>Nombre(s)</td>
                                    <td class="text-table-profile">{{profile.name}}</td>
                                    <td>Fecha de Nacimiento</td>
                                    <td class="text-table-profile">{{profile.birthday}}</td>
                                </tr>
                                <tr>
                                    <td>Teléfono</td>
                                    <td class="text-table-profile">{{profile.telephone}}</td>
                                    <td>
                                        <span class="pr-2">
                                            ¿El número tiene
                                        </span>
                                        <v-icon style="color: #25D366">mdi-whatsapp</v-icon> ?
                                    </td>
                                    <td class="text-table-profile">
                                        <v-layout class="justify-center">
                                            <v-switch :disabled="true"  v-model="whatsapp" class="green-label">
                                                <template v-slot:label></template>
                                            </v-switch>
                                        </v-layout>
                                    </td>
                                </tr>
                                <tr>
                                    <td>E-mail</td>
                                    <td class="text-table-profile">{{profile.email}}</td>
                                    <td>¿Recibir info de Marketing?</td>
                                    <td class="text-table-profile">
                                        <v-layout class="justify-center">
                                            <v-switch :disabled="true"  v-model="marketing_messages" class="green-label">
                                                <template v-slot:label></template>
                                            </v-switch>
                                        </v-layout>
                                    </td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </div>
            <template v-if="!isHidden">
    <!--Contact section  --> 
                    <div class="text-justify text-general-module ma-4 ml-15" >
                            <span class="ml-4 mr-4">CONTACTOS</span>
                            <v-btn depressed v-on:click="selectEdit(2)"> 
                                Agregar <v-icon style="margin-left: 0.5em;"> mdi-plus </v-icon>
                            </v-btn>
                    </div>
                    <div class="ma-4 ml-10 mb-4" v-if="!isHidden">
                            <v-simple-table class="table-profile mb-4 ml-10 mr-10">
                                <tbody>
                                    <tr>
                                        <td class="text-table-profile">Nombre(s)</td>
                                        <td class="text-table-profile">Correo</td>
                                        <td class="text-table-profile">Teléfono</td>
                                        <td class="text-table-profile">Puesto</td>
                                        <td class="text-table-profile">confirmación <br> captura de pedido</td>
                                        <td class="text-table-profile">Aviso de embarque</td>
                                        <td class="text-table-profile">Acuse de recibo</td>
                                        <td class="text-table-profile">Aviso Próxima entrega</td>
                                        <td class="text-table-profile">Acciones</td>
                                    </tr>
                                    <template v-if="!loadingContacts"> 
                                        <tr v-for="contact in dataContact" :key="contact.id" class="text-left">
                                        <td style="text-transform: capitalize;">
                                            <v-icon v-if="contact.main == 1" style="color:#F7D70A;margin-right: 0.5em;">mdi-star</v-icon>{{(contact.professional_degree?.title ?? '')+' '+contact.name+ ' ' + contact.last_name}}
                                        </td>
                                        <td>{{contact.email}}</td>
                                        <td>{{contact.phone}}</td>
                                        <td>{{contact.job_title?.title ?? 'Sin especificar' }}</td>
                                        <td> 
                                            <v-icon v-if="contact.order_capture_confirmation" color="success">mdi-check-circle</v-icon>
                                            <v-icon v-else color="error">mdi-cancel</v-icon> 
                                        </td>
                                        <td> 
                                            <v-icon v-if="contact.bill_of_lading" color="success">mdi-check-circle</v-icon>
                                            <v-icon v-else color="error">mdi-cancel</v-icon> 
                                        </td>
                                        <td> 
                                            <v-icon v-if="contact.acknowledgment_of_receipt" color="success">mdi-check-circle</v-icon>
                                            <v-icon v-else color="error">mdi-cancel</v-icon> 
                                        </td>
                                        <td> 
                                            <v-icon v-if="contact.departure_notice" color="success">mdi-check-circle</v-icon>
                                            <v-icon v-else color="error">mdi-cancel</v-icon> 
                                        </td> 
                                        <td>
                                            <v-btn depressed v-on:click="editContact(contact)">
                                                <v-icon>mdi-square-edit-outline</v-icon>
                                            </v-btn>
                                            <v-btn depressed v-on:click="deleteContact(contact)">
                                                <v-icon>mdi-delete</v-icon>
                                            </v-btn>
                                        </td>
                                    </tr>
                                    </template>
                                    <template v-else> 
                                        <tr> <td colspan="9"> <v-progress-linear indeterminate></v-progress-linear></td></tr>
                                    </template>     
                                </tbody>
                            </v-simple-table>
                    </div> 
    <!-- end contact section  -->  
        </template>
        <!--Destiny section  --> 
        <ReceptionForm v-if="!isHiddenReception"  class="justify-text ml-10 mr-10" justify="center" :profile="profile"   /> 
        <!-- End estiny section  -->  
                    <v-row v-if="showPhoto" class="justify-text ml-10 mr-10" justify="center">
                        <div class="text-justify text-general-module ma-4 ml-15">
                            <span class="ml-4 mr-4">FOTOS DEL NEGOCIO</span>
                        </div>
                        <v-row dense>
                            <v-col cols="12" md="4" align="center">
                                <label style="font-weight:bold" class="text-table-profile ml-10 mr-10">Fachada:</label><br>
                                <a v-if="dataDocument?.facade_photo" @click="index = 0"><img class="img-fachada" :src="urlServer+dataDocument?.facade_photo"></a>
                                <v-icon v-if="!dataDocument?.facade_photo">mdi-image</v-icon><span v-if="!dataDocument?.facade_photo">(Sin Imagen)</span>
                            </v-col>
                            <v-col cols="12" md="4" align="center">
                                <label style="font-weight:bold" class="text-table-profile ml-10 mr-10">Interior:</label><br>
                                <a v-if="dataDocument?.indoor_photo" @click="index = 1"><img class="img-fachada" :src="urlServer+dataDocument?.indoor_photo"></a>
                                <v-icon v-if="!dataDocument?.indoor_photo">mdi-image</v-icon><span v-if="!dataDocument?.indoor_photo">(Sin Imagen)</span>
                            </v-col>
                            <v-col cols="12" md="4" align="center">
                                <label style="font-weight:bold" class="text-table-profile ml-10 mr-10">Mostrador/Bodega:</label><br>
                                <a v-if="dataDocument?.bar_photo" @click="index = 2"><img class="img-fachada" :src="urlServer+dataDocument?.bar_photo"></a>
                                <v-icon v-if="!dataDocument?.bar_photo">mdi-image</v-icon><span v-if="!dataDocument?.bar_photo">(Sin Imagen)</span>
                            </v-col>
                        </v-row>
                    </v-row> 
                    <!--v-if="!isHidden"-->
                    <v-row v-if="isHidden && !isHiddenProfile" class="justify-text ml-10 mr-10" justify="center">
                        <v-form ref="form">

                            <div class="text-justify text-general-module ma-4">
                                <span class="ml-4 mr-4">DATOS PERSONALES</span>
                            </div>

                            <v-row dense>
                                <v-col cols="12" md="6" align="left">
                                    <label for="" class="text-table-profile ml-10 mr-10">Nombre (s)</label>
                                    <v-text-field class="ml-10 mr-10" v-model="profile.name" label="Nombre" solo
                                        required>
                                    </v-text-field>

                                    <label for="" class="mb-2 text-table-profile ml-10 mr-10">Teléfono</label>
                                    <v-text-field class="ml-10 mr-10" solo placeholder="Ingrese Teléfono"
                                        v-model="profile.telephone" type="number" maxlength="10" @input="validateText" :value="max" required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6" align="left">
                                    <label for="" class="mb-2 text-table-profile ml-10 mr-10">Fecha de
                                        nacimiento</label>
                                    <v-text-field class="ml-10 mr-10" solo placeholder="Ingrese DD/MM/AAAA" type="date"
                                        required v-model="profile.birthday"></v-text-field>
                                    <v-container class="px-0 ml-10 mr-10" fluid>
                                        <v-switch v-model="whatsapp">
                                            <template v-slot:label>
                                                ¿El número tiene WhatsApp? &nbsp;
                                                <v-icon> mdi-whatsapp </v-icon>
                                            </template>
                                        </v-switch>
                                    </v-container>
                                    <v-container class="px-0 ml-10 mr-10" fluid>
                                        <v-switch v-model="marketing_messages">
                                            <template v-slot:label>
                                                ¿Recibir info de Marketing? &nbsp;
                                            </template>
                                        </v-switch>
                                    </v-container>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12" md="4" offset="4">
                                    <v-row>
                                        <v-col cols="12" md="6">
                                            <v-btn :disabled="loadingUpdateProfile" :loading="loadingUpdateProfile" depressed color="success" class="justify-text" large @click="updateProfile()">
                                                Confirmar
                                                <v-icon style="margin-left: 0.5em;">
                                                    mdi-check-bold
                                                </v-icon>
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-btn :disabled="loadingUpdateProfile" depressed color="error" class="justify-text" large
                                                v-on:click="_initForms()">
                                                Cancelar
                                                <v-icon style="margin-left: 0.5em;">
                                                    mdi-window-close
                                                </v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-row>
                    <template>
                    <!--Contact form section  -->
                    <ContactForm  v-if="isHidden && !isHiddenContact" 
                    @closeForm="_initForms()"  
                    @updatedContactList="_getContactList(profile.user_id)" 
                    :userId="profile.user_id"
                    :itemEditContact="dataContactCurrent" />
                    <!--End contact form section  -->
                    </template>
                  
                </div>
            </v-card>
        </v-col>

        <CoolLightBox 
            :items="items" 
            :index="index"
            @close="index = null">
        </CoolLightBox>
    </v-container>
</template>
<script>

import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import axios from "@/plugins/axios";
import { mapGetters } from "vuex";
import global from '@/mixins/global';
import ContactForm from './ContactForm.vue';
import ReceptionForm from './ReceptionForm.vue'; 
export default {
    name: 'ProfileUser',
    mixins:[global],
    components: {
        CoolLightBox,
        Cropper,
        ContactForm,
        ReceptionForm
    },
    data() {
        return {
            max:10,
            items: [],
            index: null,
            profile:[],
            showPhoto:true,
            dataContactCurrent: {
                id: "",
                name: "",
                lastName: "",
                email: "",
                phone: "",
                main: 0,
                sendFormContact: false
            }, 
            dataContact: null, 
            dataDocument: {},
            dialog: false,
            image_loading: false,
            selectedFile: null,
            avatarStatus: true,
            avatarSize: 120,
            img: null,
            coordinates: null,
            isHidden: false,
            isHiddenProfile: false,
            isHiddenReception: false,
            isHiddenContact: false,
            urlServer:process.env.VUE_APP_FILES_URL + '/',
            form: {
                facade_photo: [],
                indoor_photo: [],
                bar_photo: []
            },
            whatsapp: false,
            marketing_messages: false,
            customer:[],
            loadingUpdateProfile:false,
            loadingContacts:false,
        }
    },
    watch:{
        customer(){
            this.userInformation();
        }
    },
    mounted() {
      let customer = this.$getLocalStorageCustomer();
      this.customer = customer ? 
      {
        customerCode: customer,
        channel:this.user.VTWEG
      } :{
        customerCode: this.user.custumer_code,
        channel:this.user.VTWEG
      }  
    },
    computed: {
        ...mapGetters({ 
            user: "auth/user",
        })
    }, 
    methods: {
        validateText() {
            if (this.profile.telephone.length > 10) {
                this.max = parseInt(Number(this.profile.telephone.slice(0, 10)));
                this.profile.telephone = null;
                this.profile.telephone = this.max;
                return false;
            }
        },

        deleteContact(contact){   
        this.loadingContacts=true;
        axios.post("contact-client/delete", {
            client: contact.client_id,
            contact: contact.id
        })
        .then((response) => {
            if( response.data.result == 1 ){
                this.$swal({
                title: response.data.message,
                icon: "success",
                type: "succes"
                })
                this._getContactList(this.profile.user_id)  
            }
            else{ 
                this.$swal({
                title: response.data.message,
                icon: "error",
                });
            }
        })
        .catch((error) => {
           console.error(error);
        }).finally( () => this.loadingContacts=false)
    },
        editContact(contact){ 
            this.selectEdit(4); 
            this.dataContactCurrent = contact; 
        }, 
        userInformation() { 
            axios.post("userInformation", {
                customerCode: this.customer.customerCode,
            }).then((response) => {
                this.profile = response.data;
                this.profile.customer_code= this.customer.customerCode;
                this.whatsapp= this.profile.whatsapp==1?true:false;
                this.marketing_messages= this.profile.marketing_messages==1?true:false;
                this.dataDocument = this.profile?.document;
                if (this.dataDocument) {
                    this.putGalery();
                }  
                this._getContactList(this.profile.user_id); 
            })
            .catch((e) => { 
                console.log(e);
            });
        },
        putGalery(){
            this.items = [];
            if(this.dataDocument?.facade_photo){
                this.items.push({
                    description: "Fachada",
                    title: "Fachada",
                    src: this.urlServer+this.dataDocument.facade_photo
                    });
                }
            if(this.dataDocument?.indoor_photo){
                this.items.push({
                        description: "Interior",
                        title: "Interior",
                        src: this.urlServer+this.dataDocument.indoor_photo
                        });
                    }
            if(this.dataDocument?.bar_photo){
                this.items.push({
                    description: "Mostrador/Bodega:",
                    title: "Mostrador/Bodega:",
                    src: this.urlServer+this.dataDocument.bar_photo
                    });
                } 
        },
        change({ coordinates }) {
            this.coordinates = coordinates
        },
        onFileSelected(event) {
            try {
                this.selectedFile = event.target.files[0]
                this.img = URL.createObjectURL(this.selectedFile)
            } catch {
                this.img = null
            }
        },
        onUpload() {
            const fd = new FormData()
            this.image_loading = true
            fd.append('image', this.selectedFile, this.selectedFile.name)
            fd.append('height', this.coordinates.height)
            fd.append('width', this.coordinates.width)
            fd.append('left', this.coordinates.left)
            fd.append('top', this.coordinates.top)

            axios.post('editImage', fd, {
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then((res) => {
                
                this.user.avatar = this.urlServer  + res.data.data;
                this.profile.image_url = res.data.data;

                this.$swal({
                    title: "Imagen actualizada",
                    icon: "success",
                    type: "succes"
                }).then(function () {
                  //  location.reload();
                }
                );
            }).catch((err) => {
                console.log(err)
            })
            this.dialog = false
            this.$forceUpdate()
        },
        updateProfile() { 
            if (
                this.profile.name == "" ||
                this.profile.telephone == "" ||
                this.profile.birthday == ""
            ) {
                this.$swal({
                    title: "Los campos no pueden ir vacios.",
                    icon: "error",
                });
            } else {

                if( this.profile.telephone.length > 10 ){
                    this.$swal({
                        title: "El telefono es mayor a 10 digitos.",
                        icon: "error",
                    });
                    return false;
                } 
                const  params = {
                   'name': this.profile.name, 
                    'telephone': this.profile.telephone, 
                    'birthday': this.profile.birthday,   
                    'whatsapp': this.whatsapp?1:0,
                    'marketing_messages': this.marketing_messages?1:0,
                    'customerCode': this.customer.customerCode,
                } 
            this.loadingUpdateProfile=true;

                axios.post('updateProfileData', params).then((res) => {
                    if (res.data.success) { 
                        this.$swal({
                            title: res.data.message,
                            icon: "success",
                            type: "succes"
                        }) 
                        this._initForms();
                        this.loadingUpdateProfile=false; 
                       /* this.isHiddenReception=false;
                        this.showPhoto=true; */
                       
                    } 
                }).catch((err) => {
                    this.loadingUpdateProfile=false; 
                    this.isHidden = false
                    console.log(err)
                })
            }
        },
        _initForms(){
            this.showPhoto = true;
            this.isHiddenProfile = false;
            this.isHiddenReception = false;
            this.isHiddenContact = false; 
            this.isHidden = false
        },
        _getContactList(user) {
            this._initForms()
            this.loadingContacts=true;
            axios
            .post("contact-client/list", { client: user  }) //user_id from users table
            .then((response) => {
                if( response.data.result == 1 )  this.dataContact = response.data.data;
            })
            .catch((error) => {
                console.error(error);
            }).finally( ()=> this.loadingContacts=false)
        },  
        selectEdit(type){ 
            console.log(type)
            switch (type) {
                case 1:
                this.showPhoto = false;
                this.isHiddenProfile = false;
                this.isHiddenReception = true;
                this.isHiddenContact = true;
                break;
                case 2: //crear contacto
                this.dataContactCurrent ={} 
                this.showPhoto = false;
                this.isHiddenProfile = true;
                this.isHiddenContact = false;
                this.isHiddenReception = true;
                break;
                case 3://Recepción
                this.showPhoto = false;
                this.isHiddenProfile = true;
                this.isHiddenReception = false;
                this.isHiddenContact = true;
                break;
                case 4://Editar Contacto
                this.showPhoto = false;
                this.isHiddenProfile = true;
                this.isHiddenReception = true;
                this.isHiddenContact = false;
                break;
            
                default:
                this.showPhoto = true;
                this.isHiddenProfile = false;
                this.isHiddenReception = false;
                this.isHiddenContact = false;
                break;
            } 
            this.isHidden = true;
        },
        select_facade_photo(event) {
            this.form.facade_photo = event.target.files[0];
        },
        select_indoor_photo(event) {
            this.form.indoor_photo = event.target.files[0];
        },
        select_bar_photo(event) {
            this.form.bar_photo = event.target.files[0];
        },
    },
}
</script>
<style>
 .img-fachada{
    border-radius: 1em;
    height:15em;
    width:15em;
 }
</style>
