export default {
  methods: {
		getColor(status) {
			const statusLowerCase = status ? status.toLowerCase() : '';

			switch (statusLowerCase) {
				case 'disponible':
				case 'verde':
				case 'available': return 'green'
				case 'parcial':
				case 'amarillo':
				case 'partial': return 'orange'
				default: return 'grey'
			}
		}
	},
}