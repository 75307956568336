// define a mixin object

import axios from "@/plugins/axios";
import { eventBus } from "@/main";
import { mapMutations } from "vuex";

import notification from "./../assets/audio/Notification.mp3";

export default {
  
  methods: {
    ...mapMutations({
      setVisibleAlert: "auth/SET_VISIBLE_ALERT",
      addDiary: "diary/ADD_DIARY",
    }),
    getUrl(path) {
      return process.env.VUE_APP_URL_SERVER + "/" + path;
    },
    async requestNotificationPermission() {
      if (!("Notification" in window)) {
        this.setVisibleAlert("El navegador no soporta las notificaciones");
        return false;
      }

      let permission = await Notification.requestPermission();

      if(permission == 'granted') {
        this.notify('Gracias por activar las notificaciones');
      } else if(permission == 'denied') {
        this.setVisibleAlert('No volveremos a mostrar notificaciones');
      }
    },
    notify(message) {
      if(Notification.permission == 'granted') {
        const notification = new Notification(message);
        setTimeout(() => notification.close(), 10*1000);
        this.setVisibleAlert(message);
      } else {
        this.setVisibleAlert(message);
      }
    },
    playSound() {
      const audio = new Audio(notification);
      audio.play();
    },
    addListeners() {
      // Agregar todos sus "listeners", estos serán globales para toda la aplicación
      eventBus.$on('notifications:new_event', (data) => {
        console.log('NEW EVENT:', data);
        this.addDiary(data.data);
      });
      eventBus.$on('notifications:new_event_cancel', (data) => {
        console.log(data);
      });
    },
    searchPostalCode(form) {
      return axios.post("searchPostalCode", form);
    },
    getCurrentDateTime() {
      const now = new Date();
    
      const day = String(now.getDate()).padStart(2, '0');
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const year = now.getFullYear();
    
      let hours = now.getHours();
      const ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12 || 12;
    
      const minutes = String(now.getMinutes()).padStart(2, '0');
    
      const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes} ${ampm}`;
    
      return formattedDateTime;
    },
    formatPrice(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
