<template> 
    <div>
    <div class="text-left pl-3" style="background-color: #000; color:#fff">
       Histórico
      </div>
       <div class="text-right mt-n6 mr-1" style="background-color: #000; color:#fff">
        <v-icon size="20" color="#fff" @click="closeModal()">mdi-close-circle</v-icon>
      </div> 
      <div>
        <v-simple-table dense>
        <template v-slot:default>
          <thead  class="custom-table-bordered"> 
            <tr class="font-weight-bold">
              <td class="bg-gray">Sem 25</td> 
              <td class="bg-gray">Sem 24</td> 
              <td class="bg-gray">Sem 23</td> 
              <td class="bg-gray">Sem 22</td> 
            </tr>
            <tr>  
              <td >$</td> 
              <td >$</td> 
              <td >$</td> 
              <td >$</td> 
            </tr> 

            <tr>  
              <td >%</td> 
              <td >%</td> 
              <td >%</td> 
              <td >%</td> 
            </tr> 
          </thead> 
        </template>
      </v-simple-table>  
      </div> 
</div>  
</template>

<script>
export default({
name:'CustomerPerWeek',
methods:{
    closeModal(){ 
    this.$emit('closeModal')
}
}
})
</script>