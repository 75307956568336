<template>
    <div>
      <div class="text-left pa-1 pl-3" style="background-color: #000; color:hsl(0, 0%, 100%)">
        Detalle de Crecimiento acumulado de grupo del mes {{currentDate}} 
      </div>
      <v-simple-table dense>
        <template v-slot:default>
          <thead class="">
            <tr class="custom-table-bordered ">
              <th class="text-center text-body-1 text-dark" colspan="7"><b>Facturación actual</b></th>
            </tr>
            <tr class="custom-table-bordered ">
              <td class="text-center table-heading text-body-2 ">Mes</td>
              <td class="text-center table-heading text-body-2 ">Facturación</td>
            </tr>
          </thead>
          <tbody v-show="!loading"   class="custom-table-bordered">
            <tr v-for="(item) in  [0,1,2,3]" :key="item">
              <td :style="item%2==0 ?'background-color: #fff;':''" class="table-heading small text-body-2">{{ item}}</td>
              <td :style="item%2==0 ?'background-color: #fff;':''" class="table-heading text-body-2"> ${{item }}</td>
            </tr>  
            <tr style="">
              <td colspan="2" style="background-color: #fff;-webkit-box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.75);
  box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.75)" class="table-heading small text-body-2">
                <v-row>
                  <v-col cols="6" class="pa-3">
                    <b class="ml-n9">Total=</b>
                  </v-col>
                  <v-col cols="6">
                    <b class="ml-n9"> ${{ numberformat(0) }}</b>
                  </v-col>
                </v-row>
              </td>
            </tr> 
          </tbody>
          <tbody v-if="loading">
            <tr>
              <td colspan="7"> <v-progress-linear class="mt-3" indeterminate color="teal"></v-progress-linear> <span
                  class="text--disabled"> Cargando información</span></td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-simple-table dense>
        <template v-slot:default>
          <thead class="">
            <tr class="custom-table-bordered ">
              <th class="text-center text-body-1 text-dark" colspan="7"><b>Facturación Anterior</b></th>
            </tr>
            <tr class="custom-table-bordered ">
              <td class="text-center table-heading text-body-2 ">Mes</td>
              <td class="text-center table-heading text-body-2 ">Facturación</td>
            </tr>
          </thead>
          <tbody v-if="!loading" class="custom-table-bordered">
            <tr v-for="(item) in [0,1,2,3]" :key="item">
              <td :style="item%2==0 ?'background-color: #fff;':''" class="table-heading small text-body-2">{{item}}</td>
              <td :style="item%2==0 ?'background-color: #fff;':''" class="table-heading text-body-2"> ${{numberformat(0) }}</td>
            </tr> 
            <tr>
              <td colspan="2" style="background-color: #fff;-webkit-box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.75);
  box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.75)" class="table-heading small text-body-2">
                <v-row>
                  <v-col cols="6" class="pa-3">
                    <b class="ml-n9">Total=</b>
                  </v-col>
                  <v-col cols="6">
                    <b class="ml-n9"> ${{ numberformat(0) }} </b>
                  </v-col>
                </v-row>
              </td>
            </tr>  
          </tbody> 
        </template>
      </v-simple-table>
      <v-row v-if="!loading"> 
                  <v-col cols="6" class="pa-3">
                    <b class="ml-n9">Crecimiento=</b>
                  </v-col>
                  <v-col cols="6">
                    <b class="ml-n9"> {{ numberformat(billing.PORCRE) }}% </b>
                  </v-col>
                  
      </v-row>
      
    </div>
  </template>
  <script>
   
  export default ({
    watch: {
      currentCustomer(value) {
        this.getPaymentInfo(value)
      }
    },
    props: {
      currentCustomer: {
        default: 0
      },
    },
    computed:{
      currentDate(){
         const date = new Date();      
        let month = date.getMonth(); 
        let year = date.getFullYear(); 
        return this.monthNames[month]+" "+year;
      },
      monthNames(){
        return ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
        "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
      ];
  
      }
    },
  
    name: "ComulativeClientGrowth",
    data() {
      return {
        historicos: [],
        loading: false,
        billing: []
      }
    },
    mounted() {
      this.getPaymentInfo(this.currentCustomer);
    },
    methods: {
      getPaymentInfo(customer) { 
        console.log(customer)
          },
        numberformat(number = 0) {
        let result = Number(number).toFixed(2);
        return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      },
      formatDate(value){
        const data = value.toString();
        const year= data.slice(0,4);
        const month= data.slice(-2);
        return month+"/"+year;
      },
      closeModal(){
        this.$emit('closeModal',true)
      }
      },
    
    
  })
  </script>
   <style scoped> 
  
  </style>