<template>
  <div class="details--content">
    <a href="#" :class="[tvpp ? 'text-success' : 'text-warning', 'details--link']" @click.prevent="open"><b>{{pedido.monto | formatCurrencyMiles}}</b>
    </a>
    <div class="datails--modal elevation-2" v-if="active">
        <div class="modal--head">
            Detalles 
             <a href="#" @click.prevent="active = false">
                <v-icon color="white darken-2">
                    mdi-close-circle
                </v-icon>
             </a>
        </div>
        <v-simple-table  border="1" class="details--table">
            <template v-slot:default>
                <thead class="table--thead">                    
                    <tr>
                        <th class="text-center">Pedido</th>
                        <th class="text-center">Hora de Ingreso</th>
                        <th class="text-center">Usuario Captura</th>
                        <th class="text-center">Distancia de Captura</th>
                        <th class="text-center">Total Pedido</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(detalles, index) in pedido.detalles" :key="index">
                        <td>{{detalles.VBELN}}</td>
                        <td>{{detalles.FHORA}}</td>
                        <td>{{detalles.USERC}}</td>
                        <td>{{detalles.DISTA}}</td>
                        <td>${{detalles.NETWR | formatCurrency}}</td>
                    </tr>
                </tbody>
                <tfoot class="text-left">
                    <tr>
                        <td colspan="4"><b>Total:</b></td>
                        <td><b>${{pedido.monto | formatCurrency}}</b></td>
                    </tr>
                </tfoot>
            </template>
        </v-simple-table>
    </div>    
  </div>
</template>

<script>
import { eventBus } from '@/main.js';
export default {
    props: ['pedido', 'tvpp', 'index'],
    data(){
        return {
            active: false
        }
    },    
    mounted() {
        const n = this
        eventBus.$on('close-other-components', function(index){
            if (n.index !== index) {
                n.active = false;
            }
        }.bind(this));
    },
    methods:{
        open(){
            eventBus.$emit('close-other-components', this.index)
            this.active = true
        }
    }
}
</script>

<style scoped>
    .details--content {
        position: relative !important;
    }

    .datails--modal {
        position: absolute !important;
        top: 20px;
        right: -30px;
        background: white;
        z-index: 1000000000 !important;
    }

    .details--table{
        min-width: 600px;
        border: thin solid rgba(0,0,0,.12);
    }
    .details--table thead, .details--table tfoot{
        background: #f2f2f2;        
    }
    .details--table thead th, .details--table tbody td, .details--table tfoot td{
        height: 35px !important;
    }

    .modal--head{
        background: #000;
        color: #fff;
        font-size: 16px;       
        padding-left: 5px;
        display: flex;
        justify-content: space-between;
    }
   
</style>