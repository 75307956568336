<template>
  <v-container fluid class="grey lighten-5">
    <v-card
      class="mx-auto rounded-lg elevation-4 ma-3 ml-5 mr-5 mb-7"
      outlined
      :style="'border: 4px solid black; background-color:transparent;'"
    >
      <v-list-item-content>
        <div class="text-general-module">
          <v-icon color="black">mdi-cart</v-icon>
          Crear Pedido BackOrder
        </div>
      </v-list-item-content>
    </v-card>
    <v-card>
      <customer-info
        :catchTotal="total"
        :warnLock="catchRequestLock"
        :purValidation="catchpurValidation"
      ></customer-info>
      <p></p>
      <div class="col-12" style="text-align: left">
        <span class="text-ped">Captura de Pedido</span>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-md-4">
            <div class="input-group">
              <v-text-field
                class="text-ped"
                v-model="order"
                label="Ingrese Orden de Compra"
                required
                readonly
              ></v-text-field>
            </div>
          </div>
          <div class="col-md-6">
            <customer-destiny :warnDestiny="cacthDestiny"></customer-destiny>
          </div>
          <div class="col-md-2"></div>
          <div class="col-md-3">
            <h2
              class="text-ped"
              style="text-align: center"
              @click="dialogCatalogo = true"
            >
              Mas de 4,200 productos
            </h2>
          </div>
          <div class="col-md-3">
            <v-switch>
              <template v-slot:label>
                <p class="text-ped">Pedido Programado</p>
              </template>
            </v-switch>
          </div>
        </div>
      </div>
      <div class="col-12" style="text-align: end; font-size: 12px">
        <a @click="dialogPromociones = true">
          <v-icon class="text-ped-links">mdi-bookmark-check</v-icon>Promociones
        </a>
        <a @click="dialogLiquidaciones = true">
          <v-icon class="text-ped-links">mdi-bookmark-check</v-icon
          >Liquidaciones
        </a>
        <a @click="dialogPedidos = true">
          <v-icon class="text-ped-links">mdi-book-open-variant</v-icon>Mis
          pedidos
        </a>
        <!--<a>
          <v-icon class="text-ped-links">mdi-bookmark-check</v-icon>Instrucciones de captura
        </a>
        <a>
          <v-icon class="text-ped-links">mdi-bookmark-plus</v-icon>Información del pedido
        </a>-->
      </div>
      <div class="card-body" ref="errorMaterial">
        <div class="row">
          <div class="col-md-12" v-if="hasError">
            <v-alert text dense outlined type="error">
              {{ hasError }}
            </v-alert>
          </div>
          <div class="col-md-8">
            <material-into-cart
              :warnMaterial="catchMaterialItem"
              :warnError="catchErrorMaterial"
              :warnDialogLoading="catchDialogLoading"
              :newMaterial="sendMaterialNew"
            ></material-into-cart>
          </div>
          <div class="col-md-4"></div>
        </div>
      </div>
      <br />
      <list-cart-reminder
        :capturedMaterialItem="materialItem"
        :warnTotal="catchTotal"
        :warnError="catchErrorMaterial"
        :warnDialogLoading="catchDialogLoading"
        :warnCart="catchCart"
        :capturedIdOrder="order_id"
      ></list-cart-reminder>
      <v-row v-if="cart.length != 0" class="justify-end">
        <v-col md="2" class="align-self-end">
          <label
            >Subtotal <strong>$ {{ formatPrice(subtotalPrint) }}</strong></label
          ><br />
          <label
            >IVA 16% <strong>$ {{ formatPrice(iva) }}</strong></label
          ><br />
          <label
            >Total
            <v-card-title style="color: #000; font-weight: 800"
              >$ {{ formatPrice(total) }}
            </v-card-title></label
          ><br />
          <v-btn
            style="background: #2fd410 !important; color: #fff !important"
            rounded
            block
            :disabled="cart.length == 0 || submitted"
            @click="checkBackOrder"
          >
            <v-icon style="color: #fff !important">mdi-check</v-icon>Comprar
            Ahora
          </v-btn>
        </v-col>
      </v-row>
      <br />
      <div class="col-12" style="text-align: initial; font-size: 12px">
        <a>
          <v-icon class="text-ped-links">mdi-bookmark-check</v-icon>Cargar
          archivo
        </a>
        <!--<a>
          <v-icon class="text-ped-links">mdi-bookmark-check</v-icon>Convertir a pedido apoyo a
          marca
        </a>
        <a>
          <v-icon class="text-ped-links">mdi-book-open-variant</v-icon>Convertir a pedido
          especial
        </a>
        <a>
          <v-icon class="text-ped-links">mdi-bookmark-check</v-icon>Convertir a Pedido de
          Tienda Nueva
        </a>-->
        <a>
          <v-icon class="text-ped-links">mdi-bookmark-plus</v-icon>Datos
          Generales
        </a>
        <a>
          <v-icon class="text-ped-links">mdi-bookmark-check</v-icon>Generar
          archivo
        </a>
      </div>
      <loading-bar :capturedDialogLoading="dialogLoading"></loading-bar>
    </v-card>
    <br />
   <!--<v-row>
      <v-col sm="12">
        <div class="col-12" style="text-align: left">
          <span class="text-ped">La mejor sugerencia</span> / en base a tus
          compras.
        </div>
        <v-slide-group active-class="success" show-arrows>
          <v-slide-item
            v-for="(item, i) in items_product"
            :key="i"
            style="margin: 10px !important"
          >
            <v-card
              class="mx-auto pedidos-card-sugerencias"
              max-width="280"
              style="text-align: left"
            >
              <v-card-text>
                <strong>{{ item.name_product }}</strong>
              </v-card-text>
              <v-card-subtitle> SKU: {{ item.sku }} </v-card-subtitle>
              <v-img :src="item.src" width="180px"></v-img>
              <v-card-subtitle style="text-align: center">
                Empaque de 1 pza
              </v-card-subtitle>
              <v-card-title>
                <span style="text-align: center"> A {{ item.price }}</span>
              </v-card-title>
              <v-card-text>
                <v-btn
                  color="primary"
                  rounded
                  block
                  style="background: #079FFF color: #fff !important"
                >
                  <v-icon style="color: #fff !important">mdi-plus</v-icon
                  >Agregar al Carrito
                </v-btn>
              </v-card-text>
              <v-card-text>
                <v-btn
                  style="background: #2fd410 !important; color: #fff !important"
                  rounded
                  block
                >
                  <v-icon style="color: #fff !important">mdi-check</v-icon
                  >Comprar Ahora
                </v-btn>
              </v-card-text>
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </v-col>
    </v-row>-->
    <v-dialog v-model="dialogBackOrder" persistent scrollable>
      <v-card>
        <v-card-title>Confirmar BackOrder</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
        <p><b>Seleccionar los backorders confirmados</b></p>
        <v-row v-for="(item, i) in cart" :key="i">
          <v-checkbox v-model="item.reminder_check">
            <template v-slot:label>
              <div>
                {{ item.codigo_material }}-{{ item.nombre_material }}
                <strong>Backorder: {{ item.recordatorios }}</strong>
              </div>
            </template>
          </v-checkbox>
        </v-row>
       </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!--<button class="btn btn-danger" @click="dialogBackOrder = false">
             Cancelar
           </button>
           <button class="btn btn-success" @click="orderGenerate">
             Aceptar junto con Pedido
           </button>-->
           
           <v-btn
            color="error"
            @click="dialogBackOrder = false"
          >
            Cancelar 
          </v-btn>
         
          <v-btn
            color="success"
            @click="orderGenerate"
          >
            Enviar Pedido
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog loading -->
    <!--<v-row justify="center">
      <v-dialog
        v-model="dialogPromociones"
        hide-overlay
        width="600"
        transition="dialog-bottom-transition"
        justify="center"
      >
        <v-card justify="center">
          <div class="text-ped" style="text-align: center">PROMOCIONES</div>
          <div class="col-12 row">
            <table class="table">
              <thead>
                <tr
                  style="font-size: 10px; background-color: rgb(238, 238, 238)"
                >
                  <th colspan="2">Producto</th>
                  <th>Distribuidor</th>
                  <th>Promoción</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, i) in items_product"
                  :key="i"
                  style="font-size: 12px"
                >
                  <td>
                    <v-img :src="item.src" width="50" class="imageCarItem" />
                  </td>
                  <td style="text-align: start">
                    {{ item.sku }}-{{ item.name_product }}
                  </td>
                  <td>
                    {{ item.price }}
                  </td>
                  <td>
                    {{ item.less }}
                  </td>
                  <td>
                    <v-btn
                      color="primary"
                      rounded
                      block
                      style="background: #079FFF color: #fff !important"
                    >
                      <v-icon style="color: #fff !important">mdi-cart</v-icon
                      >Add
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <v-card-actions class="justify-end">
            <v-btn text @click="dialogPromociones = false">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>-->
    <!-- /. Dialog -->
    <!-- Dialog loading -->
    <!--<v-row justify="center">
      <v-dialog
        v-model="dialogLiquidaciones"
        hide-overlay
        width="600"
        transition="dialog-bottom-transition"
        justify="center"
      >
        <v-card justify="center">
          <div class="text-ped" style="text-align: center">LIQUIDACIONES</div>
          <div class="col-12 row">
            <table class="table">
              <thead>
                <tr
                  style="font-size: 10px; background-color: rgb(238, 238, 238)"
                >
                  <th colspan="2">Producto</th>
                  <th>Distribuidor</th>
                  <th>Liquidación</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, i) in items_product"
                  :key="i"
                  style="font-size: 12px"
                >
                  <td>
                    <v-img :src="item.src" width="50" class="imageCarItem" />
                  </td>
                  <td style="text-align: start">
                    {{ item.sku }}-{{ item.name_product }}
                  </td>
                  <td>
                    {{ item.price }}
                  </td>
                  <td>
                    {{ item.less }}
                  </td>
                  <td>
                    <v-btn
                      color="primary"
                      rounded
                      block
                      style="background: #079FFF color: #fff !important"
                    >
                      <v-icon style="color: #fff !important">mdi-cart</v-icon
                      >Add
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <v-card-actions class="justify-end">
            <v-btn text @click="dialogLiquidaciones = false">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>-->
    <!-- /. Dialog -->
    <!-- Dialog Catalogo -->
    <!--<v-row justify="center">
      <v-dialog
        v-model="dialogCatalogo"
        hide-overlay
        width="800"
        transition="dialog-bottom-transition"
        justify="center"
      >
        <v-card justify="center">
          <div class="text-ped" style="text-align: center">CATALOGO</div>
          <div class="col-12 row">
            <v-text-field
              v-model="material_code_search"
              label="Buscar nombre / SKU"
              append-icon="mdi-magnify"
              style="padding: 15px"
            ></v-text-field>
            <v-data-table
              :headers="headersCatalogo"
              :items="catalogo_list"
              :items-per-page="5"
              :search="material_code_search"
              class="elevation-1"
            >
              <template v-slot:[`item.MATNR`]="{ item }">
                {{ parseInt(item.MATNR) }}
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn
                  color="primary"
                  rounded
                  block
                  @click="newMaterialSearch(parseInt(item.MATNR))"
                  style="background: #079FFF color: #fff !important"
                >
                  <v-icon style="color: #fff !important">mdi-cart</v-icon>Add
                </v-btn>
              </template>
            </v-data-table>
          </div>
          <v-card-actions class="justify-end">
            <v-btn text @click="dialogCatalogo = false">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>-->
    <!-- /. Dialog -->
    <!-- Dialog Pedidos -->
    <!--<v-row justify="center">
      <v-dialog
        v-model="dialogPedidos"
        hide-overlay
        width="800"
        transition="dialog-bottom-transition"
        justify="center"
      >
        <v-card justify="center">
          <div class="text-ped" style="text-align: center">MIS PEDIDOS</div>
          <div class="text-ped" style="text-align: initial">
            <v-btn
              style="margin-left: 10px"
              dark
              rounded
              color="primary"
              elevation="2"
              small
              @click="generateNewOrder()"
            >
              <v-icon style="color: #fff !important">mdi-plus</v-icon> Crear
              Nuevo Pedido
            </v-btn>
          </div>

          <div class="col-12 row">
            <v-data-table
              :headers="headersPedido"
              :items="orders_list"
              :items-per-page="5"
              class="elevation-1"
            >
              <template v-slot:[`item.name`]="{ item }">
                <a @click="loadOrder(item)">{{ item.name }}</a>
              </template>
              <template v-slot:[`item.total`]="{ item }">
                {{ formatPrice(item.total) }}
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn
                  style="margin-left: 10px"
                  dark
                  rounded
                  color="red darken-2"
                  elevation="2"
                  small
                  @click="deleteOrder(item)"
                >
                  <v-icon style="color: #fff !important">mdi-delete</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </div>
          <v-card-actions class="justify-end">
            <v-btn text @click="dialogPedidos = false">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>-->
    <!-- /. Dialog -->
  </v-container>
</template>

<script>
import axios from "@/plugins/axios";
import LoadingBar from "./componets/LoadingBar.vue";
import CustomerInfo from "./componets/CustomerInfo.vue";
import MaterialIntoCart from "./componets/MaterialIntoCart.vue";
import ListCartReminder from "./componets/ListCartReminder.vue";
import CustomerDestiny from "./componets/CustomerDestiny.vue";
import { mapGetters } from "vuex";
export default {
  name: "ShoppingCart",
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  components: {
    CustomerInfo,
    MaterialIntoCart,
    ListCartReminder,
    LoadingBar,
    CustomerDestiny,
  },
  data: () => ({
    material_code_search: "",
    submitted: false,
    hasError: null,
    materialItem: {},
    subtotal: 0,
    subtotalPrint:0,
    iva: 0,
    total: 0,
    total2: "",
    dialogLoading: false,
    customerDestiny: {},
    order: "",
    cart: [],
    requestLock: 0,
    dialogBackOrder: false,
    order_response: "",
    dialogPromociones: false,
    dialogLiquidaciones: false,
    dialogCatalogo: false,
    sendMaterialNew: "",
    dialogPedidos: false,
    order_id: null,
    id_i: '',
    id_r:'',
    orden_comfirm:'',
    purchase_validation: null,
    order_purchase: null,
    headersCatalogo: [
      {
        text: "Material",
        align: "start",
        sortable: false,
        value: "MATNR",
      },
      { text: "Descripción", value: "MAKTX" },
      { text: "actions", value: "actions" },
    ],
    catalogo_list: [],
    headersPedido: [
      { text: "ID", value: "id" },
      {
        text: "Orden de compra",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "Num. Partidas", value: "materials" },
      { text: "Importe Total", value: "total" },
      { text: "", value: "actions" },
    ],
    orders_list: [],
    items_product: [
      {
        src: "img/267925_1.jpg",
        href: "https://iusa.com.mx/catalogo_construccion/",
        percent: "18%",
        price: "$4,155.00",
        less: "$3,777.00",
        name_product: "CALENTADOR IUSA DE RÁPIDA RECUPERACIÓN, 5L, GAS LP",
        sku: "308773",
      },
      {
        src: "img/lbl-iusa-26_principal.jpg",
        href: "https://iusa.com.mx/catalogo_construccion/",
        percent: "25%",
        price: "$8,522.00",
        less: "$7,747.00",
        name_product: "CALENTADOR SOLAR PRIMO DE TUBOS AL VACÍO",
        sku: "308773",
      },
      {
        src: "img/617035_01.jpg",
        href: "https://iusa.com.mx/catalogo_construccion/",
        percent: "20%",
        price: "$8,236.00",
        less: "$7,100.00",
        name_product: "MOTOBOMBA A GASOLINA PARA ALTA CARGA 6.5 HP",
        sku: "308773",
      },
      {
        src: "img/268458_1.jpg",
        href: "https://iusa.com.mx/catalogo_construccion/",
        percent: "38%",
        price: "$14,800.00",
        less: "$17,168.00",
        name_product: "AIRE ACONDICIONADO MINI SPLIT, MASTERFRIZ 2023",
        sku: "308773",
      },
      {
        src: "img/619049_01.jpg",
        href: "https://iusa.com.mx/catalogo_construccion/",
        percent: "10%",
        price: "$14,800.00",
        less: "$17,168.00",
        name_product: "ROTOMARTILLO 750 W",
        sku: "308773",
      },
    ],
  }),
  async mounted() {
    console.log("Material:");
    this.getOrderID();
    this.checkOrdersHistory();
    this.getCatalogo();
    console.log(this.$route.query.data);
    //this.getRoute(this.$route.query.data);
    console.log("id order back:"+this.id_r);
    console.log("order back name:"+this.orden_comfirm);
  },
  methods: {
   /* getRoute(value){    
        for (let i = 0; i < value.length; i++) {
          this.id_r = value[i].id_r;
          this.orden_comfirm = value[i].orden_compra;
        }
    },*/
    formatPrice(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getCatalogo() {
      axios
        .get("getCatalogo")
        .then((response) => {
          this.catalogo_list = response.data;
        })
        .catch((error) => {
         // alert(error);
          console.log(error);
        })
        .finally();
    },
    getCatalogoRandom() {
      axios
        .get("getCatalogoRandom")
        .then((response) => {
          this.items_product = response.data;
        })
        .catch((error) => {
          // alert(error);
          console.log(error);
        })
        .finally();
    },
    newMaterialSearch(item) {
      this.sendMaterialNew = item;
    },
    getOrderID() {
      axios
        .post("getOrderIDReminder", {
          customer: this.user.custumer_code,
        })
        .then((response) => {
          this.order_id = response.data.id;
          this.id_r = response.data.backorder_id;
          this.orden_comfirm = response.data.name;

          console.log("get id order: " +response.data.id);
          console.log("get back order: " +response.data.backorder_id);
          console.log("get name order: " +response.data.name);
          //this.getCartItems(this.order_id);
          this.getOrderInfo(this.order_id);
        })
        .catch((error) => {
          // alert(error);
           console.log(error);
        })
        .finally();
    },
    getOrderInfo(id) {
      axios
        .post("getOrderInfo", {
          id_order: id,
        })
        .then((response) => {
          this.order = response.data.name;
        })
        .catch((error) => {
          // alert(error);
          console.log(error);
        })
        .finally();
    },
    checkOrdersHistory() {
      axios
        .post("checkOrdersHistory", {
          customer: this.user.custumer_code,
        })
        .then((response) => {
          this.orders_list = response.data;
        })
        .catch((error) => {
         // alert(error);
          console.log(error);
        })
        .finally();
    },
    saveOrderInfo() {
      axios
        .post("saveOrderInfo", {
          order_id: this.order_id,
          orden_compra: this.order,
          destiny: this.customerDestiny.KUNNR,
          cart: this.cart,
          total: this.total,
        })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          //alert(error);
          // alert(error);
          console.log(error);
        })
        .finally();
    },
    loadOrder(item) {
      this.dialogLoading = true;
      this.dialogPedidos = false;
      this.order_id = item.id;
      //this.getCartItems(this.order_id);
      this.getOrderInfo(this.order_id);
    },
    deleteOrder(item) {
      this.dialogLoading = true;
      this.dialogPedidos = false;
      axios
        .post("orderDelete", {
          order_id: item.id,
        })
        .then((response) => {
          console.log(response);
          this.dialogPedidos = true;
          this.checkOrdersHistory();
        })
        .catch((error) => {
          //alert(error);
          // alert(error);
          console.log(error);
        })
        .finally();
    },
    generateNewOrder() {
      this.dialogLoading = true;
      this.dialogPedidos = false;
      axios
        .post("generateNewOrder", {
          customer: this.user.custumer_code,
        })
        .then((response) => {
          this.order_id = response.data;
          this.checkOrdersHistory();
        })
        .catch((error) => {
          // alert(error);
          console.log(error);
        })
        .finally();
    },
    catchMaterialItem(data) {
      console.log("Material"+data);
      this.materialItem = data;
    },
    catchErrorMaterial(data) {
      this.hasError = data;
      window.scroll(0, window.pageYOffset - 50);
    },
    catchTotal(data) {
     /* this.subtotalPrint =  parseFloat(data).toFixed(2);
      this.subtotal = parseFloat(data);
      this.iva = parseFloat((this.subtotal * 0.16).toFixed(2));
      this.total = this.subtotal + this.iva;
      this.total = this.total.toFixed(2);*/

      this.subtotal = 0;

var iva_a = 0;
//this.iva = 0;
//this.total = 0;
for (let index = 0; index < data.length; index++) {
  const element = data[index];
  var importe_producto = element["importe_producto"];
  var impuesto = element["MWST"];
  var calcular_impuesto = 0;

  if (impuesto == 0) {
    calcular_impuesto = 0;
  } else if (impuesto == 1) {
    calcular_impuesto = 0.16;
  } else if (impuesto == 2) {
    calcular_impuesto = 0;
  } else if (impuesto == 3) {
    calcular_impuesto = 0;
  } else if (impuesto == 4) {
    calcular_impuesto = 0.16;
  } else if (impuesto == 5) {
    calcular_impuesto = 0.16;
  } else if (impuesto == 6) {
    calcular_impuesto = 0;
  } else {
    calcular_impuesto = 0.16;
  }

  this.subtotal += parseFloat(importe_producto);
  iva_a += parseFloat(importe_producto) * (calcular_impuesto);


} //end for
console.log("importe:" + this.iva);
this.subtotalPrint = parseFloat(this.subtotal).toFixed(2);
this.subtotal = parseFloat(this.subtotal);
this.iva = parseFloat((iva_a).toFixed(2));
this.total = this.subtotal + this.iva;
this.total = this.total.toFixed(2);
    },
    catchDialogLoading(data) {
      this.dialogLoading = data;
    },
    cacthDestiny(data) {
      console.log(data);
      this.customerDestiny = data;
    },
    catchCart(data) {
      this.cart = data;
      this.saveOrderInfo();
    },
    catchRequestLock(data) {
      console.log(data);
      this.requestLock = data;
    },
    catchpurValidation(data) {
      console.log(data);
      if (data > 1) {
        // if(data == 0){
        this.purchase_validation = 2;
      } else {
        this.purchase_validation = 1;
      }

    },
    checkBackOrder() {
      this.hasError = null;
      if (this.order == "") {
        this.hasError = "Es necesario ingresar orden de compra";
        this.$refs.errorMaterial.scrollTop = 0;
      } else if (this.customerDestiny == {}) {
        this.hasError = "Es necesario ingresar destino de compra";
        //this.$refs.errorMaterial.scrollTop = 0;
      } else {
        const existBackOrder = this.cart.some((item) => item.recordatorios > 0);
        if (existBackOrder) {
          this.dialogBackOrder = true;
        } else {
          this.orderGenerate();
        }
      }
    },
    orderGenerate() {
      this.dialogBackOrder = false;
      this.dialogLoading = true;
      this.submitted = true;

      if(this.customerDestiny == {}){
        this.warnError("El campo de Destino de compra es obligatorio.");
      }else if (this.order_id == ""){
       // this.warnError("El campo de Orden de compra es obligatorio.");

       this.$swal({
         title: "El campo de Orden de compra es obligatorio.",
         icon: "error",
         type: "error",
       }).then((result) => {
         console.log(result);
         location.reload();

       });
      }else{
      axios
        .post("generateOrderReminderCustomer", {
          order_id: this.order_id,
          orden_compra: this.order,
          destino_compra: this.customerDestiny.KUNNR,
          carrito: this.cart,
          customer: this.user.custumer_code,
          VKORG: this.user.VKORG,
          VTWEG: this.user.VTWEG,
          VKBUR: this.user.VKBUR,
          id_back: this.id_r,
          numero_vendedor: this.customerDestiny.KUNNR,
          purchase_validation: this.purchase_validation,
          //bandera_programado: this.banderaProgramado,
          //fecha_rec: this.formatDate(this.fecha_programado),
        })
        .then((response) => {

          // console.log(response.data.RDCreaEntrega);
         if(response.data.message == "BACKORDER GENERADO" ){
         
         this.$swal.fire(response.data.message, 'Número de Pedido: S/N','success');
         this.$router.replace({
           name: "HistoryOrders",
         });
       
       }else{        
         this.dialogLoading = false;
        // this.order_response = response.data.RDCreaEntrega.VBELN;
        this.order_response = response.data.VBELN;
        this.order_purchase = response.data.PVALID;
         this.cart = [];
         this.order = null;
         
           if (this.order_purchase == 2) {

             this.$swal.fire('Pedido Generado', 'Número de Pedido: ' + this.order_response + '. Pedido Bloqueado por Credito', 'success');
             this.$router.replace({
               name: "HistoryOrders",
             });
           } else {
             this.$swal.fire('Pedido Generado', 'Número de Pedido: ' + this.order_response + '.', 'success');
             this.$router.replace({
               name: "HistoryOrders",
             });
           }
       }
        })
        .catch((error) => {
          this.dialogLoading = false;
          if(error.response.status == 404){
         // alert(error.response.data.error_code);
         this.$swal({
              title: error.response.data.error_code,
              icon: "error",
              type: "error",
            }).then((result) => {
              console.log(result);
             // location.reload();

            });
          }else{
            //alert(error);
            console.log(error);
          }

          this.submitted= false
         
        })
        .finally(() => (this.dialogLoading = false,  this.submitted= false));
      }
    },
   
  },
};
</script>
<style>
</style>
