<template>
    <div>
        <div v-if="item.planner.length > 0">
            <div v-if="item.planner[0].presentation !== null && item.planner[0].presentation !== undefined && item.planner[0].presentation !== ''">
                <v-icon color="green">                             
                    mdi-account-multiple
                </v-icon>
            </div>
            <div v-else>
                <v-icon color="red" :class="{'cursor-default': $hasAnyRole(['Manager'])}" @click="($hasAnyRole(['Seller']) && (today.isSameOrAfter(formatToday(startDate), 'day') && today.isSameOrBefore(formatToday(endDate), 'day'))) ? dialog = true : false">                             
                    mdi-account-multiple
                </v-icon>
            </div>
        </div>
        <div v-else>
            <v-icon color="red">                            
                mdi-account-multiple
            </v-icon>
        </div>
        

        <!-- Model Presentacion -->
        <v-dialog v-model="dialog" persistent max-width="500px" > 
            <v-card>
                <v-card-title class="d-flex justify-content-center">
                    <span class="text-h6">
                        <b>Información de Presentación</b>
                    </span>
                </v-card-title>

                <v-card-text class="pb-0">
                  
                    <v-container>
                        <v-col cols="12">
                            <v-menu
                                v-model="menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="date"
                                        :min="startDate"
                                    :max="endDate"
                                        label="Fecha de Presentación"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"                                        
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="date"
                                    @input="selectedDate"></v-date-picker>
                            </v-menu>
                    </v-col>
                    </v-container>
                  
                </v-card-text>

                <v-card-actions class="d-flex justify-content-center">                    
                    <v-btn color="blue darken-1" text @click="closeModal">
                        Cancelar
                    </v-btn>
                    <v-btn color="success" @click="confirmar"> Confirmar </v-btn>                    
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- End Model Presentacion -->
    </div>
</template>

<script>
import moment from "moment"
import { mapGetters } from "vuex";
export default {
    props: ['item', 'startDate', 'endDate'],
    data(){
        return {
            today: moment(),
            dialog: false,
            menu: false,
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)  
        }
    },
    computed:{
        ...mapGetters({
            authenticated: "auth/authenticated"
        }),
    },
    methods:{
        closeModal(){
            this.dialog = false
        },
        selectedDate(date){            
            const selected = moment(date);
            let minDate = this.startDate
            let maxDate = moment(this.endDate).endOf('day')

            if (selected.isSameOrAfter(minDate) && selected.isSameOrBefore(maxDate)) {
                this.menu = false;
                this.date = selected.format('YYYY-MM-DD');
            } else {
                this.date = null
                return false;
            }
        },
        confirmar(){    
            if(this.date != null){         
                this.$emit('confirmarPresentacion', {item: this.item, date: this.date})
                this.dialog = false
            }

        },
        formatToday(date){
            return moment(date, 'YYYY-MM-DD'); // Crear un objeto Moment para la fecha objetivo
        }
    }
}
</script>

<style>
    .cursor-default{
        cursor: default !important;
    }
</style>