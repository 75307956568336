<template>
  <v-container>
    <v-card>
      <v-card-title>Gerencia | Solicitudes</v-card-title>
      <v-card-text>
        <div class="row">
          <div class="col-sm-12">
            <div class="page-title-box">
              <h2><span> Solicitud de Alta de Cliente </span></h2>
              <div class="clearfix"></div>
            </div>
            <div class="row mt-5">
              <div class="col-md-6" style="margin-bottom: 15px">
                <div class="row">
                  <div class="col-md-6">Fecha de Solicitud</div>
                  <div class="col-md-6">{{ data.created_at }} hrs.</div>
                </div>
              </div>
              <div class="col-md-6">
                <v-btn
                  color="success"
                  @click="showObservationsDialog"
                  class="mr-5"
                >
                  Observaciones
                </v-btn>
                <v-btn
                  color="primary"
                  @click="sendToRevision"
                  :disabled="loading"
                  >Enviar a revisión</v-btn
                >
              </div>
            </div>
            <v-list dense v-if="Object.keys(errors).length > 0">
              <v-alert type="error" outlined dense
                >Errores de validación</v-alert
              >
              <v-list-item-group v-model="selectedItem" color="primary">
                <v-list-item v-for="(item, i) in errors" :key="i">
                  <v-list-item-icon>
                    <v-icon class="red--text">mdi-alert</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="red--text">{{
                      item
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <v-divider></v-divider>
            <h5 class="mt-5">Información General</h5>
            <p></p>
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-2">
                    <label for="" class="">Tipo persona</label>
                  </div>
                  <div class="col-md-4">
                    <v-select
                      :items="['fisica', 'moral']"
                      v-model="data.person_type"
                      dense
                      outlined
                    ></v-select>
                  </div>
                </div>
              </div>
              <div class="col-md-6 input-box">
                <div class="row">
                  <div class="col-md-4">
                    <label for="">{{
                      data.person_type == "moral" ? "Razón social" : "Nombre"
                    }}</label>
                  </div>
                  <div class="col-md-8">
                    <v-text-field
                      outlined
                      dense
                      type="text"
                      class="input-control"
                      v-model="data.company_name"
                    ></v-text-field>
                    <p ng-if="message_error.company_name" style="color: red">
                      {{ message_error.company_name }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 input-box">
                <div class="row">
                  <div class="col-md-4"><label for="">RFC</label></div>
                  <div class="col-md-8">
                    <v-text-field
                      outlined
                      dense
                      type="text"
                      class="input-control"
                      v-model="data.rfc"
                    ></v-text-field>
                    <p ng-if="message_error.rfc" style="color: red">
                      {{ message_error.rfc }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 input-box">
                <div class="row">
                  <div class="col-md-4"><label for="">Calle</label></div>
                  <div class="col-md-8">
                    <v-text-field
                      outlined
                      dense
                      type="text"
                      class="input-control"
                      v-model="data.a_street"
                    ></v-text-field>
                    <p ng-if="message_error.a_street" style="color: red">
                      {{ message_error.a_street }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 input-box">
                <div class="row">
                  <div class="col-md-4">
                    <label for="">Número Exterior</label>
                  </div>
                  <div class="col-md-8">
                    <v-text-field
                      outlined
                      dense
                      type="text"
                      class="input-control"
                      v-model="data.a_number"
                    ></v-text-field>
                    <p ng-if="message_error.a_number" style="color: red">
                      {{ message_error.a_number }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 input-box">
                <div class="row">
                  <div class="col-md-4"><label for="">Colonia</label></div>
                  <div class="col-md-8">
                    <v-text-field
                      outlined
                      dense
                      type="text"
                      class="input-control"
                      v-model="data.a_suburb"
                    ></v-text-field>
                    <p ng-if="message_error.a_suburb" style="color: red">
                      {{ message_error.a_suburb }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 input-box">
                <div class="row">
                  <div class="col-md-4">
                    <label for="">Número Interior</label>
                  </div>
                  <div class="col-md-8">
                    <v-text-field
                      outlined
                      dense
                      type="text"
                      class="input-control"
                      v-model="data.interior_number"
                    ></v-text-field>
                    <p ng-if="message_error.interior_number" style="color: red">
                      {{ message_error.interior_number }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 input-box">
                <div class="row">
                  <div class="col-md-4">
                    <label for="">Delegación o Municipio</label>
                  </div>
                  <div class="col-md-8">
                    <v-text-field
                      outlined
                      dense
                      type="text"
                      class="input-control"
                      v-model="data.a_town"
                    ></v-text-field>
                    <p ng-if="message_error.a_town" style="color: red">
                      {{ message_error.a_town }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 input-box">
                <div class="row">
                  <div class="col-md-4"><label for="">Estado</label></div>
                  <div class="col-md-8">
                    <v-text-field
                      outlined
                      dense
                      type="text"
                      class="input-control"
                      v-model="data.a_estate"
                    ></v-text-field>
                    <p ng-if="message_error.a_town" style="color: red">
                      {{ message_error.a_town }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 input-box">
                <div class="row">
                  <div class="col-md-4"><label for="">CP</label></div>
                  <div class="col-md-8">
                    <v-text-field
                      outlined
                      dense
                      type="text"
                      class="input-control"
                      v-model="data.a_postal_code"
                    ></v-text-field>
                    <p ng-if="message_error.a_postal_code" style="color: red">
                      {{ message_error.a_postal_code }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 input-box">
                <div class="row">
                  <div class="col-md-4"><label for="">Teléfono</label></div>
                  <div class="col-md-8">
                    <v-text-field
                      outlined
                      dense
                      type="text"
                      class="input-control"
                      v-model="data.phone"
                    ></v-text-field>
                    <p ng-if="message_error.phone" style="color: red">
                      {{ message_error.phone }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 input-box">
                <div class="row">
                  <div class="col-md-4">
                    <label for="">Nombre de Contacto</label>
                  </div>
                  <div class="col-md-8">
                    <v-text-field
                      outlined
                      dense
                      type="text"
                      class="input-control"
                      v-model="data.contact_name"
                    ></v-text-field>
                    <p ng-if="message_error.contact_name" style="color: red">
                      {{ message_error.contact_name }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 input-box">
                <div class="row">
                  <div class="col-md-4"><label for="">Email</label></div>
                  <div class="col-md-8">
                    <v-text-field
                      outlined
                      dense
                      type="text"
                      class="input-control"
                      v-model="data.email"
                    ></v-text-field>
                    <p ng-if="message_error.email" style="color: red">
                      {{ message_error.email }}
                    </p>
                  </div>
                </div>
              </div>
              <v-row class="mb-5">
                <v-col cols="12">
                  <v-card dark>
                    <v-card-title class="justify-center">
                      <h5 class="text-white text-center mb-4">Direcciones registradas</h5>
                    </v-card-title>
                    <v-card-text>
                      <v-simple-table dense light>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">#</th>
                              <th class="text-left">Tipo</th>
                              <th class="text-left">Razón social</th>
                              <th class="text-left">RFC</th>
                              <th class="text-left">Dirección</th>
                              <th class="text-left">Delegación/Municipio</th>
                              <th class="text-left">Código postal</th>
                              <th class="text-left">Estado</th>
                              <th class="text-left">Contacto</th>
                              <th class="text-left">Teléfono</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(item, index) in data.addresses"
                              :key="item.id"
                            >
                              <td>{{ index + 1 }}</td>
                              <td>{{ item.person_type }}</td>
                              <td>{{ item.name }}</td>
                              <td>{{ item.rfc }}</td>
                              <td>
                                {{ item.street }} {{ item.interior_number }}
                                {{ item.outdoor_number }} {{ item.suburb }}
                              </td>
                              <td>{{ item.town }}</td>
                              <td>{{ item.state }}</td>
                              <td>{{ item.postal_code }}</td>
                              <td>{{ item.contact_name }}</td>
                              <td>{{ item.contact_phone }}</td>
                            </tr>
                            <tr v-if="data.addresses.length <= 0">
                              <td colspan="10">No hay direcciones registradas</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </div>
            <hr />
            <h5 class="my-5">Información Fiscal y de Negocio</h5>
            <div class="row">
              <div class="col-md-6 input-box">
                <div class="row">
                  <div class="col-md-4">
                    <label for="">Regimen Fiscal</label>
                  </div>
                  <div class="col-md-8">
                    <v-autocomplete
                      v-model="data.fiscal_regimen"
                      :items="tax_regimes"
                      outlined
                      dense
                      label="Selecciona el regimen fiscal"
                      item-text="label"
                      item-value="code"
                    ></v-autocomplete>
                    <p ng-if="message_error.fiscal_regimen" style="color: red">
                      {{ message_error.fiscal_regimen }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 input-box">
                <div class="row">
                  <div class="col-md-4">
                    <label for="">Método de Pago</label>
                  </div>
                  <div class="col-md-8">
                    <v-select
                      :items="payment_methods"
                      label="Método de pago"
                      outlined
                      dense
                      v-model="data.payment_method"
                      item-text="method"
                      item-value="code"
                    ></v-select>
                    <p ng-if="message_error.payment_method" style="color: red">
                      {{ message_error.payment_method }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 input-box">
                <div class="row">
                  <div class="col-md-4"><label for="">Uso de CFDI</label></div>
                  <div class="col-md-8">
                    <v-autocomplete
                      v-model="data.cfdi_use"
                      :items="cfdi_uses"
                      outlined
                      dense
                      label="Selecciona el uso de CFDI"
                      item-text="label"
                      item-value="id"
                    ></v-autocomplete>
                    <p ng-if="message_error.cfdi_use" style="color: red">
                      {{ message_error.cfdi_use }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 input-box">
                <div class="row">
                  <div class="col-md-4">
                    <label for="">Forma de Pago</label>
                  </div>
                  <div class="col-md-8">
                    <v-autocomplete
                      v-model="data.way_pay_id"
                      :items="way_pays"
                      outlined
                      dense
                      label="Selecciona forma de pago"
                      item-text="label"
                      item-value="id"
                    ></v-autocomplete>
                    <p ng-if="message_error.way_pay_id" style="color: red">
                      {{ message_error.way_pay_id }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 input-box">
                <div class="row">
                  <div class="col-md-4">
                    <label for="">¿Solicita línea de crédito?</label>
                  </div>
                  <div class="col-md-8">
                    <v-select
                      :items="['Si', 'No']"
                      v-model="data.request_credit"
                      outlined
                      dense
                      placeholder="Selecciona"
                    ></v-select>
                    <p ng-if="message_error.request_credit" style="color: red">
                      {{ message_error.request_credit }}
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="col-md-6 input-box"
                v-if="data.request_credit == 'Si'"
              >
                <div class="row">
                  <div class="col-md-4">
                    <label for="">Línea de Crédito Solicitada</label>
                  </div>
                  <div class="col-md-8">
                    <v-text-field
                      outlined
                      dense
                      type="text"
                      class="input-control"
                      v-model="data.line_request_credit"
                    ></v-text-field>
                    <p
                      v-if="message_error.line_request_credit"
                      style="color: red"
                    >
                      {{ message_error.line_request_credit }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 input-box">
                <div class="row">
                  <div class="col-md-4">
                    <label for="">¿Entrega en Dirección Fiscal?</label>
                  </div>
                  <div class="col-md-8">
                    <v-select
                      :items="['Si', 'No']"
                      v-model="data.delivery_fiscal_addres"
                      outlined
                      dense
                      placeholder="Selecciona"
                    ></v-select>
                    <p
                      ng-if="message_error.delivery_fiscal_addres"
                      style="color: red"
                    >
                      {{ message_error.delivery_fiscal_addres }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 input-box">
                <div class="row">
                  <div class="col-md-4">
                    <label for="">Nombre de Representante Legal</label>
                  </div>
                  <div class="col-md-8">
                    <v-text-field
                      outlined
                      dense
                      type="text"
                      class="input-control"
                      v-model="data.fiscal_rep_name"
                    ></v-text-field>
                    <p ng-if="message_error.fiscal_rep_name" style="color: red">
                      {{ message_error.fiscal_rep_name }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 input-box">
                <div class="row">
                  <div class="col-md-4">
                    <label for="">Ventas Mensuales del Negocio</label>
                  </div>
                  <div class="col-md-8">
                    <v-text-field
                      outlined
                      dense
                      type="text"
                      class="input-control"
                      v-model="data.month_sales"
                    ></v-text-field>
                    <p ng-if="message_error.month_sales" style="color: red">
                      {{ message_error.month_sales }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 input-box">
                <div class="row">
                  <div class="col-md-4">
                    <label for="">Tamaño del Negocio (m2)</label>
                  </div>
                  <div class="col-md-8">
                    <v-text-field
                      outlined
                      dense
                      type="text"
                      class="input-control"
                      v-model="data.business_size"
                    ></v-text-field>
                    <p ng-if="message_error.business_size" style="color: red">
                      {{ message_error.business_size }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 input-box">
                <div class="row">
                  <div class="col-md-4">
                    <label for="">Giro del Negocio</label>
                  </div>
                  <div class="col-md-8">
                    <v-text-field
                      outlined
                      dense
                      type="text"
                      class="input-control"
                      v-model="data.line_bussines"
                    ></v-text-field>
                    <p ng-if="message_error.line_bussines" style="color: red">
                      {{ message_error.line_bussines }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 input-box">
                <div class="row">
                  <div class="col-md-4">
                    <label for="">¿Las instalaciones son propias?</label>
                  </div>
                  <div class="col-md-8">
                    <v-text-field
                      outlined
                      dense
                      type="text"
                      class="input-control"
                      v-model="data.own_facilities"
                    ></v-text-field>
                    <p ng-if="message_error.own_facilities" style="color: red">
                      {{ message_error.own_facilities }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 input-box">
                <div class="row">
                  <div class="col-md-4">
                    <label for="">Oficina de Ventas</label>
                  </div>
                  <div class="col-md-8">
                    <v-autocomplete
                      v-model="data.sales_office"
                      :items="salesOffice"
                      :loading="salesOfficeLoading"
                      :search-input.sync="searchSaleOffice"
                      item-text="concatenated"
                      item-value="code"
                      label="Oficina de ventas"
                      placeholder="Escribe para buscar"
                      outlined
                      dense
                    ></v-autocomplete>
                    <p ng-if="message_error.sales_office" style="color: red">
                      {{ message_error.sales_office }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 input-box">
                <div class="row">
                  <div class="col-md-4">
                    <label for="">Zona de Ventas</label>
                  </div>
                  <div class="col-md-8">
                    <v-autocomplete
                      v-model="data.sales_area"
                      :items="salesAreas"
                      :loading="salesAreaLoading"
                      :search-input.sync="searchSaleArea"
                      item-text="label"
                      item-value="code"
                      label="Oficina de ventas"
                      placeholder="Escribe para buscar"
                      outlined
                      dense
                    ></v-autocomplete>
                    <p ng-if="message_error.sales_area" style="color: red">
                      {{ message_error.sales_area }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 input-box">
                <div class="row">
                  <div class="col-md-4">
                    <label for="">Código de Ramo</label>
                  </div>
                  <div class="col-md-8">
                    <v-autocomplete
                      v-model="data.branch_code"
                      :items="branchCodes"
                      :loading="branchCodeLoading"
                      :search-input.sync="searchBranchCode"
                      item-text="label"
                      item-value="code"
                      label="Código de ramo"
                      placeholder="Escribe para buscar"
                      outlined
                      dense
                    ></v-autocomplete>
                    <p ng-if="message_error.branch_code" style="color: red">
                      {{ message_error.branch_code }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 input-box">
                <div class="row">
                  <div class="col-md-4">
                    <label for="">Centro de Suministro</label>
                  </div>
                  <div class="col-md-8">
                    <v-autocomplete
                      v-model="data.supply_center"
                      :items="supplyCenters"
                      outlined
                      dense
                      label="Centro de suministro"
                      item-text="label"
                      item-value="code"
                    ></v-autocomplete>
                    <p v-if="message_error.supply_center" style="color: red">
                      {{ message_error.supply_center }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 input-box">
                <div class="row">
                  <div class="col-md-4">
                    <label for="">Zona de Transporte</label>
                  </div>
                  <div class="col-md-8">
                    <v-autocomplete
                      v-model="data.transport_area"
                      :items="transportationZones"
                      :loading="transportationZoneLoading"
                      :search-input.sync="searchTransportationZone"
                      item-text="label"
                      item-value="code"
                      label="Zona de transporte"
                      placeholder="Escribe para buscar"
                      outlined
                      dense
                    ></v-autocomplete>
                    <p ng-if="message_error.transport_area" style="color: red">
                      {{ message_error.transport_area }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <p></p>
            <div class="row">
              <div class="col-md-6 input-box">
                <div class="row">
                  <div class="col-md-4"><label for="">IVA</label></div>
                  <div class="col-md-8">
                    <v-select
                      :items="ivas"
                      label="IVA"
                      outlined
                      dense
                      v-model="data.iva"
                      item-text="label"
                      item-value="id"
                    ></v-select>
                    <p ng-if="message_error.iva" style="color: red">
                      {{ message_error.iva }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <v-row>
              <v-col cols="12" md="3">
                <v-row>
                  <v-col cols="12" md="4">
                    <label for="">Nombre Asesor (Vendedor)</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      outlined
                      dense
                      type="text"
                      class="input-control"
                      v-model="data.agent_name"
                      placeholder="Nombre agente de ventas"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="3">
                <v-row>
                  <v-col cols="12" md="4">
                    <label for="">Número Asesor (Vendedor)</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      outlined
                      dense
                      type="text"
                      class="input-control"
                      v-model="data.agent_number"
                      placeholder="Agente de ventas"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="3">
                <v-row>
                  <v-col cols="12" md="4">
                    <label for="">Teléfono Asesor (Vendedor)</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      outlined
                      dense
                      type="text"
                      class="input-control"
                      v-model="data.agent_phone"
                      placeholder="5599999999"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="3">
                <v-row>
                  <v-col cols="12" md="4">
                    <label for="">Email Asesor (Vendedor)</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      outlined
                      dense
                      type="text"
                      class="input-control"
                      v-model="data.agent_email"
                      placeholder="vendedor@iusa.com.mx"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-row>
                  <v-col cols="12" md="4">
                    <label for="">Nombre Gerente</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      outlined
                      dense
                      type="text"
                      class="input-control"
                      v-model="data.manager"
                      placeholder="Nombre gerente"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="3">
                <v-row>
                  <v-col cols="12" md="4">
                    <label for="">Número Gerente</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      outlined
                      dense
                      type="text"
                      class="input-control"
                      v-model="data.manager_code"
                      placeholder="Número de gerente"
                      :rules="[value => !!value || 'El campo es requerido.']"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="3">
                <v-row>
                  <v-col cols="12" md="4">
                    <label for="">Teléfono Gerente</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      outlined
                      dense
                      type="text"
                      class="input-control"
                      v-model="data.manager_phone"
                      placeholder="5599999999"
                      :rules="[value => !!value || 'El campo es requerido.']"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="3">
                <v-row>
                  <v-col cols="12" md="4">
                    <label for="">Email Gerente</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      outlined
                      dense
                      type="text"
                      class="input-control"
                      v-model="data.manager_email"
                      placeholder="gerente@iusa.com.mx"
                      :rules="[value => !!value || 'El campo es requerido.']"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <v-row>
                  <v-col cols="12" md="4">
                    <label for="">Nombre Subgerente</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      outlined
                      dense
                      type="text"
                      class="input-control"
                      v-model="data.assistant_manager"
                      placeholder="Nombre del subgerente"
                      @input="handleAssistantManager"
                    ></v-text-field>
                    <span class="text-right">* En caso de no conocer utilice: NA</span>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="4">
                <v-row>
                  <v-col cols="12" md="4">
                    <label for="">Número Subgerente</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      outlined
                      dense
                      type="text"
                      class="input-control"
                      v-model="data.assistant_manager_code"
                      placeholder="Agente de ventas"
                      hint="Sino tiene el número utilize 9999999"
                      :rules="[value => !!value || 'El campo es requerido.']"
                      @input="handleAssistantManagerCode"
                    ></v-text-field>
                    <span class="text-right">* En caso de no conocer utilice el número: 9999999</span>
                  </v-col>
                  <v-col cols="12">
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <p></p>
            <h5>Documentación Solicitada</h5>

            <div class="row">
              <div class="col-md-4 d-flex flex-column align-center mt-4">
                <button
                  class="btn"
                  @click="
                    modal('facade_photo', data.facade_photo, 'Foto 1 (Fachada)')
                  "
                >
                  <div class="text-center">
                    <v-icon class="text-h1">mdi-image</v-icon>
                  </div>
                  <span class="text-center">Foto 1 (Fachada)</span>
                  <div class="w-100 text-center font-weight-light">
                    {{ data.facade_photo }}
                  </div>
                </button>
              </div>
              <div class="col-md-4 d-flex flex-column align-center mt-4">
                <button
                  class="btn"
                  @click="
                    modal(
                      'indoor_photo',
                      data.indoor_photo,
                      'Foto 2 (Interior)'
                    )
                  "
                >
                  <div class="text-center">
                    <v-icon class="text-h1">mdi-image</v-icon>
                  </div>
                  <span class="text-center">Foto 2 (Interior)</span>
                  <div class="w-100 text-center font-weight-light">
                    {{ data.indoor_photo }}
                  </div>
                </button>
              </div>
              <div class="col-md-4 d-flex flex-column align-center mt-4">
                <button
                  class="btn"
                  @click="
                    modal(
                      'bar_photo',
                      data.bar_photo,
                      'Foto 3 (Mostrador/Bodega)'
                    )
                  "
                >
                  <div class="text-center">
                    <v-icon class="text-h1">mdi-image</v-icon>
                  </div>
                  <span class="text-center">Foto 3 (Mostrador/Bodega)</span>
                  <div class="w-100 text-center font-weight-light">
                    {{ data.bar_photo }}
                  </div>
                </button>
              </div>
              <div class="col-md-4 d-flex flex-column align-center mt-4">
                <a
                  class="btn"
                  target="_blank"
                  :href="`${url}/${data.evid_tax_registration}`"
                >
                  <div class="text-center">
                    <v-icon class="text-h1">mdi-file-pdf-box</v-icon>
                  </div>
                  <span class="text-center"
                    >Constancia de Situación Fiscal no mayor a tres meses</span
                  >
                  <div class="w-100 text-center font-weight-light">
                    {{ data.evid_tax_registration }}
                  </div>
                </a>
              </div>
              <div class="col-md-4 d-flex flex-column align-center mt-4">
                <a
                  class="btn"
                  target="_blank"
                  :href="`${url}/${data.official_id}`"
                >
                  <div class="text-center">
                    <v-icon class="text-h1">mdi-file-pdf-box</v-icon>
                  </div>
                  <span class="text-center"
                    >Identificación oficial de persona Fisica o representante
                    legal</span
                  >
                  <div class="w-100 text-center font-weight-light">
                    {{ data.official_id }}
                  </div>
                </a>
              </div>
              <div class="col-md-4 d-flex flex-column align-center mt-4">
                <a
                  class="btn"
                  target="_blank"
                  :href="`${url}/${data.proof_recidency}`"
                >
                  <div class="text-center">
                    <v-icon class="text-h1">mdi-file-pdf-box</v-icon>
                  </div>
                  <span class="text-center"
                    >Comprobante de domicilio dirección fiscal</span
                  >
                  <div class="w-100 text-center font-weight-light">
                    {{ data.proof_recidency }}
                  </div>
                </a>
              </div>
              <div class="col-md-4 d-flex flex-column align-center mt-4">
                <a
                  class="btn"
                  target="_blank"
                  :href="`${url}/${data.proof_recidency_distinct}`"
                >
                  <div class="text-center">
                    <v-icon class="text-h1">mdi-file-pdf-box</v-icon>
                  </div>
                  <span class="text-center"
                    >En caso de que las entregas sean en domicilio diferente al
                    fiscal, comprobante de domicilio</span
                  >
                  <div class="w-100 text-center font-weight-light">
                    {{ data.proof_recidency_distinct }}
                  </div>
                </a>
              </div>
              <div class="col-md-4 d-flex flex-column align-center mt-4">
                <a
                  class="btn"
                  target="_blank"
                  :href="`${url}/${data.credit_bureau}`"
                >
                  <div class="text-center">
                    <v-icon class="text-h1">mdi-file-pdf-box</v-icon>
                  </div>
                  <span class="text-center">Formato de Buró de Crédito</span>
                  <div class="w-100 text-center font-weight-light">
                    {{ data.credit_bureau }}
                  </div>
                </a>
              </div>
              <div
                class="col-md-4 d-flex flex-column align-center mt-4"
                v-if="data.delivery_fiscal_addres == 'No'"
              >
                <a
                  class="btn"
                  target="_blank"
                  :href="`${url}/${data.shcp_opening}`"
                >
                  <div class="text-center">
                    <v-icon class="text-h1">mdi-file-pdf-box</v-icon>
                  </div>
                  <span class="text-center"
                    >Formato de Alta de Apertura ante SAT</span
                  >
                  <div class="w-100 text-center font-weight-light">
                    {{ data.shcp_opening }}
                  </div>
                </a>
              </div>
              <div
                class="col-md-4 d-flex flex-column align-center mt-4"
                ng-if="data.person_type == 'moral'"
              >
                <a class="btn" target="_blank" :href="`${url}/${data.charter}`">
                  <div class="text-center">
                    <v-icon class="text-h1">mdi-file-pdf-box</v-icon>
                  </div>
                  <span class="text-center">Acta constitutiva</span>
                  <div class="w-100 text-center font-weight-light">
                    {{ data.charter }}
                  </div>
                </a>
              </div>
              <div
                class="col-md-4 d-flex flex-column align-center mt-4"
                ng-if="data.person_type == 'moral'"
              >
                <a
                  class="btn"
                  target="_blank"
                  :href="`${url}/${data.power_attorney}`"
                >
                  <div class="text-center">
                    <v-icon class="text-h1">mdi-file-pdf-box</v-icon>
                  </div>
                  <span class="text-center"
                    >Poder notarial para ser el representante legal de la
                    persona moral o Física</span
                  >
                  <div class="w-100 text-center font-weight-light">
                    {{ data.power_attorney }}
                  </div>
                </a>
              </div>
              <div
                class="col-md-4 d-flex flex-column align-center mt-4"
                v-if="data.delivery_fiscal_addres == 'No'"
              >
                <a
                  class="btn"
                  target="_blank"
                  :href="`${url}/${data.bank_reference}`"
                >
                  <div class="text-center">
                    <v-icon class="text-h1">mdi-file-pdf-box</v-icon>
                  </div>
                  <span class="text-center"
                    >Referencias Bancarias (caratula de estado de cuenta)</span
                  >
                  <div class="w-100 text-center font-weight-light">
                    {{ data.bank_reference }}
                  </div>
                </a>
              </div>
              <hr>
               <p class=" h5"> Información de pagaré y aval </p>
              <div
                class="col-md-3 d-flex flex-column align-center mt-4"
                ng-if="data.request_credit == 'Si'"
              >
                <a
                  class="btn"
                  target="_blank"
                  :href="`${url}/${data.promissory_note}`"
                >
                  <div class="text-center">
                    <v-icon class="text-h1">mdi-file-pdf-box</v-icon>
                  </div>
                  <span class="text-center">Pagaré</span>
                  <div class="w-100 text-center font-weight-light">
                    {{ data.promissory_note }}
                  </div>
                </a>
              </div>
              <div
                class="col-md-3 d-flex flex-column align-center mt-4"
                v-if="data.request_credit == 'Si' && data.line_request_credit > 50000"
              >
                <a
                  class="btn"
                  target="_blank"
                  :href="`${url}/${data.official_id_endors}`"
                >
                  <div class="text-center">
                    <v-icon class="text-h1">mdi-file-pdf-box</v-icon>
                  </div>
                  <span class="text-center"
                    >Identificación oficial de Aval</span
                  >
                  <div class="w-100 text-center font-weight-light">
                    {{ data.official_id_endors }}
                  </div>
                </a>
              </div>
              <div class="col-md-3 d-flex flex-column align-center mt-4">
                <a
                  class="btn"
                  target="_blank"
                  :href="`${url}/${data.proof_recidency_endors}`"
                >
                  <div class="text-center">
                    <v-icon class="text-h1">mdi-file-pdf-box</v-icon>
                  </div>
                  <span class="text-center"
                    >Comprobante de domicilio de Aval</span
                  >
                  <div class="w-100 text-center font-weight-light">
                    {{ data.proof_recidency_endors }}
                  </div>
                </a>
              </div>
              <div v-if="data.bank_guarantee_name"  class="col-md-3 d-flex flex-column align-center mt-4">
                <p>Datos de crédito</p>
                  <div class="text-center">
                    <v-icon class="text-h1">mdi-account-child</v-icon>
                  </div>
                  <p class="text-left">
                  <b> Aval:</b> <label class=""> {{ data.bank_guarantee_name.toUpperCase() }}</label> <br>
                  <b>  Fecha de firma de pagaré:</b> {{  data.promissory_note_date }} <br>
                   <b> Monto de pagaré:</b> ${{ formatPrice(data.promissory_note_amount)}}
                   
                  </p>
                  <div class="w-100 text-center font-weight-light"> 
                  </div> 
              </div>
            </div>

            <v-btn color="primary" @click="sendToRevision" :disabled="loading"
              >Enviar a revisión</v-btn
            >

            <v-dialog v-model="dialogImage.show" width="500">
              <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                  {{ dialogImage.title }}
                </v-card-title>

                <v-card-text>
                  <p v-if="!dialogImage.image">No sé encontró la imagen</p>
                  <v-img
                    lazy-src="/img/propuesta-1-x2.svg"
                    :src="dialogImage.image"
                  ></v-img>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="dialogImage.show = false">
                    Cerrar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- Modal -->
          </div>
        </div>
      </v-card-text>
      <v-dialog v-model="dialog" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text class="h2">
            Enviado a revisión correctamente
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="observations.dialog"
        hide-overlay
        persistent
        width="50%"
      >
        <v-card>
          <v-card-title>
            <span> Observaciones </span>
            <v-spacer></v-spacer>
            <v-btn color="secondary" @click="observations.dialog = false"
              >Cerrar</v-btn
            >
          </v-card-title>
          <v-card-text class="h2">
            <observations-timeline
              :observations="observations.data"
            ></observations-timeline>
          </v-card-text>
          <v-card-actions>
            <v-btn color="secondary" @click="observations.dialog = false"
              >Cerrar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-container>
</template>

<script>
import axios from "@/plugins/axios";
import ObservationsTimeline from "@/views/client_request/ObservationsTimeline.vue";
import { mapGetters } from "vuex";

export default {
  components: { ObservationsTimeline },
  data() {
    return {
      id: null,
      url: this.$filesUrl,
      data: {},
      message_error: [],
      target: null,
      title: null,
      src: null,
      dialog: false,
      loading: false,
      salesOfficeLoading: false,
      searchSaleOffice: null,
      salesOffice: [],
      salesAreaLoading: false,
      searchSaleArea: null,
      salesAreas: [],
      supplyCentersLoading: false,
      searchSupplyCenter: null,
      supplyCenters: [],
      transportationZoneLoading: false,
      searchTransportationZone: null,
      transportationZones: [],
      branchCodeLoading: false,
      searchBranchCode: null,
      branchCodes: [],
      ivas: [],
      supply_centers: [],
      way_pays: [],
      tax_regimes: [],
      payment_methods: [],
      cfdi_uses: [],
      dialogImage: {
        show: false,
        image: null,
        title: null,
      },
      observations: {
        dialog: false,
        data: [],
      },
      errors: {},
    };
  },

  computed: {
    ...mapGetters({
      user: "auth/user"
    }),
  },

  mounted() {
    this.id = this.$route.params.id;
    this.loadCustomerRequest();
    this.loadInitialData();
  },

  watch: {
    searchSaleOffice(val) {
      this.loadSaleOffices(val);
    },
    searchTransportationZone(val) {
      this.loadTransportationZones(val);
    },
    searchBranchCode(val) {
      this.loadBranchCodes(val);
    },
  },

  methods: {
    loadCustomerRequest() {
      axios.get(`/customerrequests/${this.id}`).then((response) => {
        this.data = response.data;

        if(! this.data.manager || this.data.manager == 'null') {
          this.data.manager = this.user.name;
        }
        if(! this.data.manager_code || this.data.manager_code == 'null') {
          this.data.manager_code = this.user.employee_number;
        }
      });
    },
    sendToRevision() {
      if(!this.validate()) {
        return;
      }
      this.loading = true;
      this.data.status = "revision-credito";
      this.data.custumer_id = this.id;

      axios
        .post(`/updateCustomerDetail`, this.data)
        .then(() => {
          this.dialog = true;

          setTimeout(() => {
            this.dialog = false;
            this.$router.push({ name: "Manager" });
          }, 2000);
        })
        .catch((error) => {
          console.log(error);
          let title = "Ocurrio un error";

          if (error.response.status == 422) {
            title = "Error de validación de datos.";
            this.errors = error.response.data.errors;
          }
          this.$swal({
            title: title,
            icon: "warning",
            type: "warning",
          });
        })
        .finally(() => (this.loading = false));
    },
    loadInitialData() {
      this.loadRequestData();
      this.loadSaleOffices();
      this.loadTransportationZones();
      this.loadBranchCodes();
      this.loadRequestDataV2();
    },
    loadSaleOffices(search = null) {
      if (this.salesOffice.length > 0) return;
      if (this.salesOfficeLoading) return;

      let params = "";
      if (search != null) {
        params += `search=${search}`;
      }

      this.salesOfficeLoading = true;
      axios
        .get(`/sales-offices?${params}`)
        .then((response) => {
          this.salesOffice = response.data;
        })
        .finally(() => (this.salesOfficeLoading = false));
    },
    loadSaleAreas(search = null) {
      if (this.salesAreas.length > 0) return;
      if (this.salesAreaLoading) return;

      let params = "";
      if (search != null) {
        params += `search=${search}`;
      }

      this.salesAreaLoading = true;
      axios
        .get(`/areas-offices?${params}`)
        .then((response) => {
          this.salesAreas = response.data;
        })
        .finally(() => (this.salesAreaLoading = false));
    },
    loadSupplyCenter(search = null) {
      if (this.supplyCenters.length > 0) return;
      if (this.supplyCentersLoading) return;

      let params = "";
      if (search != null) {
        params += `search=${search}`;
      }

      this.supplyCentersLoading = true;
      axios
        .get(`/supply-center?${params}`)
        .then((response) => {
          this.supplyCenters = response.data.supply_centers;
        })
        .finally(() => (this.supplyCentersLoading = false));
    },
    loadTransportationZones(search = null) {
      if (this.transportationZones.length > 0) return;
      if (this.transportationZoneLoading) return;

      let params = "";
      if (search != null) {
        params += `search=${search}`;
      }

      this.transportationZoneLoading = true;
      axios
        .get(`/transportation-zones?${params}`)
        .then((response) => {
          this.transportationZones = response.data;
        })
        .finally(() => (this.transportationZoneLoading = false));
    },
    loadBranchCodes(search = null) {
      if (this.branchCodes.length > 0) return;
      if (this.branchCodeLoading) return;

      let params = "";
      if (search != null) {
        params += `search=${search}`;
      }

      this.branchCodeLoading = true;
      axios
        .get(`/branch-codes?${params}`)
        .then((response) => {
          this.branchCodes = response.data;
        })
        .finally(() => (this.branchCodeLoading = false));
    },
    loadRequestData() {
      axios
        .get("request-data")
        .then((response) => {
          this.ivas = response.data.ivas;
          this.way_pays = response.data.way_pays;
          this.tax_regimes = response.data.tax_regimes;
          this.payment_methods = response.data.payment_methods;
          this.cfdi_uses = response.data.cfdi_uses;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    modal(title, url, name) {
      this.dialogImage.title = name;
      this.dialogImage.image = this.url + "/" + url;
      this.dialogImage.show = true;
      console.log(title);
    },
    showObservationsDialog() {
      this.observations.dialog = true;
      this.observations.data = this.data.observations;
    },
    formatPrice(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    validate() {
      if(!this.data.agent_number) {
        this.$toast.warning('El campo número asesor es requerido');
        return false;
      }
      if(!this.data.agent_phone) {
        this.$toast.warning('El campo teléfono del asesor es requerido');
        return false;
      }
      if(!this.data.agent_email) {
        this.$toast.warning('El campo correo del asesor es requerido');
        return false;
      }
      if(!this.data.manager_code) {
        this.$toast.warning('El campo número de gerente es requerido');
        return false;
      }
      if(!this.data.manager_phone) {
        this.$toast.warning('El campo teléfono del gerente es requerido');
        return false;
      }
      if(!this.data.manager_email) {
        this.$toast.warning('El campo correo del gerente es requerido');
        return false;
      }
      if(!this.data.assistant_manager_code) {
        this.$toast.warning('El campo número de subgerente es requerido');
        return false;
      }
      return true;
    },
    handleAssistantManager(value) {
      console.log('Assistant', value);
      if(value == 'NA') {
        this.data.assistant_manager_code = '9999999';
      }
    },
    handleAssistantManagerCode(value) {
      console.log('Assistant', value);
      if(value == '9999999') {
        this.data.assistant_manager = 'NA';
      }
    },
    loadRequestDataV2() {
      axios
        .get(`/v2/request-data?`)
        .then((response) => {
          this.supplyCenters = response.data.supply_centers;
          this.salesAreas = response.data.sales_zones;
        })
        .finally(() => (this.supplyCentersLoading = false));
    },
  },
};
</script>

<style>
.select-control {
  width: 100%;
  padding: 5px;
  font-size: 14px;
  border-width: 0px;
  border-color: #cccccc;
  background-color: #ffffff;
  color: #000000;
  border-style: hidden;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(215, 215, 215, 0.99);
  text-shadow: 0px 0px 0px rgba(66, 66, 66, 0.75);
}

.svg-icon {
  font-size: 3em;
}
</style>
