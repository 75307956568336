<template>
  <div>
      <v-simple-table class="mt-0 my-table pb-0" dense>
              <template v-slot:default>
                <thead class="table-heading">
                  <tr>
                    <th  rowspan="2"  class="text-center text-dark custom-border-right">
                      <b>Pedidos <br> de&nbsp; {{ months[0].full_name }}</b>
                    </th>
                    <th  rowspan="2"  class="text-center text-dark custom-border-right">
                      <b>{{ ('Promedio mensual de Pedidos').replaceAll(' ','&nbsp;') }} <br>
                        {{getMonths}}
                      </b>
                    </th>  
                    <th rowspan="2" class="text-center text-dark custom-border-right">
                      <b>Pedidos&nbsp;del&nbsp; <br>dia anterior</b>
                    </th>  
                    <th colspan="3" class="text-center text-dark custom-border-right">
                      <div class="d-flex d-inline-block justify-center">
                        <div class="mt-2">
                          Crecimientos por:
                        </div>
                        <v-checkbox v-model="isBillingGrowth" dense :disabled="loading">
                          <template v-slot:label>
                            <div>
                             <b class=" text-black small">Facturación</b>
                            </div>
                          </template>
                        </v-checkbox>
                        <v-checkbox  v-model="isOrderGrowth" dense :disabled="loading">
                          <template v-slot:label>
                            <div>
                             <b class=" text-black small">Pedidos</b>
                            </div>
                          </template>   
                        </v-checkbox>
                      </div> 
                    </th>
                  </tr> 
                  <tr>
                    <th class="text-center text-dark"><b>Acum.<br /> {{ months[0].date }}</b></th>
                    <th class="text-center text-dark"><b>Últ. 3 Meses</b></th>
                    <th class="text-center text-dark"><b>Últ. mes vs 3 anteriores</b></th> 
                  </tr>
                 
                </thead>
                <tbody v-if="!loading" class="custom-table">
                  <tr class="text-body-2">
                    <td>
                      <div class="d-flex d-inline-block justify-center"> 
                        <div class="align-self-center mr-2 ml-2">
                          ${{number_format(orders.PEDMES,1)}}
                        </div>   
                      </div> 
                    </td>  
                    <td >${{number_format(orders.PROP3M,1)}}</td>
                    <td>${{number_format(orders.PEDDANT,1)}}</td> 
                    <template v-if="isBillingGrowth">
                      <td  style="border-width: 0px;"> <a @click="showCumulativeGrowthModal()" class="text-decoration-underline text-black" >{{number_format(orders.CREACUFAC - 100 ,false)}}%</a></td>
                      <td  style="border-width: 0px;"> <a @click="showCumulativeGrowthModal('_3M')" class="text-decoration-underline text-black">{{number_format(orders.CREACU3FAC - 100 ,false)}}%</a></td>
                      <td  style="border-width: 0px;"> <a @click="showCumulativeGrowthModal('_1X3')" class="text-decoration-underline text-black font-weight-bold" >{{number_format(orders.CREACU1X3FAC - 100 ,false)}}%</a></td>
                    </template> 
                    <template v-else>
                      <td  style="border-width: 0px;"> <a @click="showCumulativeGrowthModal()" class="text-decoration-underline text-black" >{{number_format(orders.CREACU - 100 ,false)}}%</a></td>
                      <td  style="border-width: 0px;"> <a @click="showCumulativeGrowthModal('_3M')" class="text-decoration-underline text-black" >{{number_format(orders.CREACU3 - 100 ,false)}}%</a></td>
                      <td  style="border-width: 0px;"> <a @click="showCumulativeGrowthModal('_1X3')" class="text-decoration-underline text-black font-weight-bold" >{{number_format(orders.CREACU1X3 - 100 ,false)}}%</a></td>
                    </template> 
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <th colspan="6">
                      <v-progress-linear indeterminate color="cyan"></v-progress-linear>
                    </th>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>    
  <v-dialog  v-model="showModal" width="auto">
      <v-card class="pb-4"> 
        <CumulativeGrowth 
        :title="`Detalle de crecimiento constante de Gerencia del mes: ${months[0].date}`"
        :isBilling="isBillingGrowth?1:0"
        :manager="manager"
        :type="growthType"
        :channel="managerData.VTWEG"
        :period="months[0]" 
        :modal="showModal"
        @closeModal="closeModal()"
         /> 
      </v-card>
    </v-dialog> 
  </div>
</template>

<script>
  
import CumulativeGrowth from './modals/CumulativeGrowth.vue';
import MixinService from '@/mixins/service'
import axios from "@/plugins/axios";
import MixinHelpers from '@/mixins/helpers';
export default ({
  name:'NetSales',
  props: ['manager','managerData'],
  mixins: [MixinService,MixinHelpers],
  components:{ 
  CumulativeGrowth
},
  data: () => ({
      customerSales:false,
      customersBuyWeekly:false,
      showModal:false,
      quarter3:true,
      zoneWithSalesModal:false,
      paymentInfoModal:false,
      orders:[],
      loading:true,
      months:[],
      isOrderGrowth:false,
      isBillingGrowth:true,
      currentPage:1,
      growthType:'', //tipo de crecimiento
  }),
  computed:{
    getMonths(){ 
        var months = this.months;   
        return this.implode(this.arrayPluck(months.slice(1, 4),'short_name'),'/') 
        
      },
  },
  watch:{
    isOrderGrowth(value){ 
      if (value) {
        this.isBillingGrowth=false;
      }else{
        this.isBillingGrowth=true;
      }
    },
    manager(){
      this.loadOrders()
      } ,
    isBillingGrowth(value){ 
      if (value) {
        this.isOrderGrowth=false;
      }else{
        this.isOrderGrowth=true;
      }
    }
  },
  created(){
        this.months = this.calcularUltimosMeses(4); 
      }, 
  methods:{
     
      loadOrders(){  
      this.loading=true;
      axios.post('/manager/home/orders',{
        manager: this.managerData.PERNR,
          channel: this.managerData.VTWEG
        }).then((res) => { 
          this.orders = res.data;
          this.loading = false;
      })
      .catch((err) => { 
            console.log(err);
            this.loading = false;
          }) ;  
    },
    showCumulativeGrowthModal(type='')
      { 
          this.growthType = type;
          this.showModal = true; 
      },
      closeModal() {
    this.showModal = false;
  },
  }
})
</script>