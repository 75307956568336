<template>
  <div>

    <div class="details--content">
      <a href="#" @click.prevent="open">
        <v-icon :color="producto.PNC ? 'green' : 'red'" style="font-size:20px">
            mdi-circle
        </v-icon>
      </a>
      
      <div class="datails--modal elevation-2" v-if="active">
          <div class="modal--head">
              Detalles de Pedido de Productos Nuevos
              <a href="#" @click.prevent="active = false">
                  <v-icon color="white darken-2">
                      mdi-close-circle
                  </v-icon>
              </a>
          </div>
          <v-simple-table  border="1" class="details--table">
              <template v-slot:default>                  
                  <tbody>
                      <tr>
                          <td>Antiguedad (Mayor o igual a 6 meses)</td>
                          <td>{{antiguedad}} meses</td>
                          <td>
                            <v-icon :color="producto.DET_NPRODUCTO.evalAntiguedad ? 'green' : 'red'">
                                {{ validateCheckCircle(producto.DET_NPRODUCTO.evalAntiguedad) }}
                            </v-icon>
                          </td>
                      </tr>
                      <tr>
                          <td>Promedio Ponderado (Menor o igual a 36)</td>
                          <td>{{producto.DET_NPRODUCTO.PPOND}} días</td>
                          <td>
                            <v-icon :color="producto.DET_NPRODUCTO.evalPonderado ? 'green' : 'red'">
                                {{ validateCheckCircle(producto.DET_NPRODUCTO.evalPonderado) }}
                            </v-icon>
                          </td>
                      </tr>
                      <tr>
                          <td>Renglonaje Anterior (Mayor o igual a 20)</td>
                          <td>{{producto.DET_NPRODUCTO.RENGA}}</td>
                          <td>
                            <v-icon :color="producto.DET_NPRODUCTO.evalRenglonaje ? 'green' : 'red'">
                                {{ validateCheckCircle(producto.DET_NPRODUCTO.evalRenglonaje) }}
                            </v-icon>
                          </td>
                      </tr>
                      <tr>
                          <td>PNC hechos en el mes (Cero)</td>
                          <td>{{producto.DET_NPRODUCTO.PPN}} al mes</td>
                          <td>
                            <v-icon :color="producto.DET_NPRODUCTO.evalPedidoPPN ? 'green' : 'red'">
                                {{ validateCheckCircle(producto.DET_NPRODUCTO.evalPedidoPPN) }}
                            </v-icon>
                          </td>
                      </tr>
                      <tr>
                        <td>Sin saldos / facturas vencidas</td>
                        <td></td>
                        <td>
                            <v-icon :color="producto.DET_NPRODUCTO.evalVSALD ? 'green' : 'red'">
                                {{ validateCheckCircle(producto.DET_NPRODUCTO.evalVSALD) }}
                            </v-icon>
                          </td>
                      </tr>
                  </tbody>
              </template>
          </v-simple-table>
      </div>    
    </div>    
  </div>
</template>

<script>
/* eslint-disable */
import { eventBus } from '@/main.js';
import moment from 'moment';
export default {
    props: ['producto', 'index'],
    data(){
        return {
            active: false,
            antiguedad: ''
        }
    },
    mounted() {
        const n = this
        eventBus.$on('close-productos-nuevos', function(index){
            if (n.index !== index) {
                n.active = false;
            }
        }.bind(this));
    },
    watch: {
      'producto.DET_NPRODUCTO.ERDAT':{
        immediate: true,
        handler(newDate) {
          const fechaActual = moment();
          this.antiguedad = fechaActual.diff(newDate, 'month')               
        }
      }      
    },
    methods:{
        open(){
            eventBus.$emit('close-productos-nuevos', this.index)
            this.active = true
        },
        validateCheckCircle(value){
            return value ? 'mdi-check-circle': 'mdi-close-circle';
        }
    }
}
</script>

<style scoped>
    .details--content {
        position: relative;        
    }

    .datails--modal {
        position: absolute;
        top: 20px;
        right: -10px;
        background: white;
        z-index: 1;
    }

    .details--table{
        min-width: 450px;
        border: thin solid rgba(0,0,0,.12);
    }
    .details--table thead{
        background: #f2f2f2;        
    }
    .details--table thead th, .details--table tbody td{
        height: 35px !important;
        text-align: left;
    }

    .modal--head{
        background: #000;
        color: #fff;
        font-size: 16px;       
        padding-left: 5px;
        display: flex;
        justify-content: space-between;
    }    
</style>