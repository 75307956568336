<template>
  <div
    class="step"
    @click="validateSelection"
    :class="{ 'step-visited': visited, 'step-not-clickable': !clickable }"
  >
    <div class="step-text" :class="{ 'step-active': active, 'step-disabled': disabled }">
      <v-icon v-if="icon" color="white" size="20">{{ icon }}</v-icon> {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Step',

  props: {
    text: {
      type: String,
      required: true,
    },

    step: {
      type: Number,
      required: true,
    },

    currentStep: {
      type: Number,
      default: null,
    },

    icon: {
      type: String,
      default: null,
    },

    activeIfIsNextStep: { // If current step is 1 only step 2 will be clickable, 3, 4 and so on will be disabled
			type: Boolean,
			default: true
		},

    done: Boolean,
    visited: Boolean, // Puts step in a diferent color
    disabled: Boolean, // Disabled by an external condition
  },

	methods: {
		validateSelection () {
			if (this.clickable) {
				this.$emit('on-click', this.step)
			}
		}
	},

  computed: {
		clickable () {
			if(this.disabled) return false;
			if(this.active) return false;

			return this.canBeSelected || this.done || this.visited;
		},

		canBeSelected() {
			if(!this.activeIfIsNextStep) return true;

			return this.step - this.currentStep === 1;
		},

    active() {
      return this.step === this.currentStep;
    },
  },
};
</script>

<style scoped>
.step {
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(155, 2, 2);
  color: white;
  cursor: pointer;
}

.step-active {
  font-weight: bolder;
}

.step-text {
  min-height: 30px;
  text-align: center;
}

.step-active::after {
  display: block;
  width: 200px;
  content: '';
  border-bottom: 3px solid white;
  transition: 0.2s ease-in;
  animation: grow-line 0.3s;
}

.step-visited {
  background-color: rgb(219, 2, 24);
  transition: 0.2s ease-in;
}

.step-not-clickable, .step-disabled {
	cursor: not-allowed;
}

.step-disabled {
	color: rgb(215, 212, 212);
}

@keyframes grow-line {
  from {
    width: 0px;
  }
  to {
    width: 200px;
  }
}
</style>
