<template>
  <div>
    <v-simple-table dense class="ma-2">
      <template v-slot:default>
        <thead>
          <tr class="custom-body-hs-montlty text-justify">
            <th class="text-capitalize" v-for=" (item, index) in data" :key="index"> {{ dateFormat(item.SPMON) }} </th>
          </tr>
        </thead>
        <tbody>
          <tr class="custom-body-hs-montlty">
            <td v-for=" (item, index) in data" :key="index">
              <span v-if="type">  {{ item.RENTRIDIA }}  </span>
              <span v-else>  $&nbsp;{{ numberformat(item.FACNETA) }} </span>
            </td>
          </tr>
          <tr class="custom-body-hs-montlty" v-if="!data">
            <td colspan="3"> <v-progress-linear indeterminate color="cyan"></v-progress-linear></td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>
<script>
import moment from 'moment'
export default ({
  name: 'MonthlyDetailsTable',
  props: ['data', 'type'],
  methods: {
    dateFormat(date) {
      return moment(date, "YYYYMM").format("MMM");
    },
    numberformat(number = 0, round = true) {
      let result = round ? Math.trunc(number) : Number(number).toFixed(2);
      return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
  }
})
</script>
<style scoped>
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}
</style>
<style src="@/assets/css/customerHome.css" scoped></style>