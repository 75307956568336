export default {
    methods: {        
        /** Capitalizar palabras*/
        capitalize(string) { 
           var words = string.split(" "); 
           for (var i = 0; i < words.length; i++) {
             var word = words[i];
             words[i] = word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
           }  
           return words.join(" "); 
        },
        //Array a string eligiendo el separador
        implode(arr,separator=" ") {
            return arr.join(separator);
          },  
        arrayPluck(arr, propertyName) {
            return arr.map(item => item[propertyName]);
          },
          arraySum(arr) { 
            return arr.reduce((accum, current) => accum + Number(current), 0);
          },
          /**
           * 
           * @param {*} number 
           * @param {*} trunc 
           * @returns string
           */
        number_format(number = 0, trunc = true) {
            let result = trunc ? Math.trunc(number) : Number(number).toFixed(2);
            return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          },
    }
}

