<template>
  <div>   
    <v-container fluid class="grey lighten-5 quoter-card" ref="printResultSellerView">
    <div class="d-flex">
      <v-card class="flex-grow-1 mx-auto rounded-lg elevation-4 ma-3" outlined
        :style="'border: 4px solid #171313; background-color:transparent;'">
        <div class="d-flex justify-space-between align-center">
          <p></p>
          <h5 class="font-weight-bold pt-3">
            <span class="icoiusa-file-sign"></span>
            COTIZACIÓN
          </h5>
          <img class="mr-4" src="/img/logo_service_desk.png" alt="" width="20px" />
        </div>
      </v-card>
    </div> 
    <div class="grid-container mt-25">
      <div class="customer mt-5">
        <quoter-card :showTitle="false" class="w-full" :red="true">
          <div v-if="!loadingCustomerinformation">
            <h4 class="mt-5 font-weight-bold">{{ customerInfo.NAME1 }}</h4>
            <p class="font-weight-bold font-size-15">
              Cliente ({{ customerInfo.KUNNR.replace(/^0+/, '') }})
            </p>
          </div>
          <div v-else>
            <v-progress-circular indeterminate color="primary"></v-progress-circular> <span>Cargando información de
              cliente</span>
          </div>
        </quoter-card>
      </div>
      <div class="destination">
        <quoter-card title="Destinario mercancia" v-if="quoterType">
          <!-- Si quoterType es 1, mostrar el componente actual -->
           <div v-if="quoterType == 2">
                <v-radio-group v-model="destinyType" :value="1">
                <v-radio class="w-full m-0 p-0" label="Destinatario de cliente" :value="1"></v-radio>
                <v-radio class="w-full m-0 p-0" label="Otro destinatario" :value="2"></v-radio>
              </v-radio-group>
                <div class="" v-if="destinyType == 2"> 
                  <v-form ref="destinyForm" lazy-validation>
                            <v-text-field  v-model="destiny" dense :rules="[  
                              v => !!v || 'Este campo es requerido', 
                            v => (v && v.length > 3) || 'Este campo debe contener almenos 3 caracteres', 
                      v => (v && v.length <= 500) || 'Este campo debe ser menor a 500 caracteres',
                    ]" solo class="text-ped pt-0 mt-0 w-100 text-uppercase" required></v-text-field>
      </v-form>
            </div> 
             </div>
              <template v-if="destinations.length > 0 ">
              <v-autocomplete v-if="quoterType==1 || (quoterType==2 && destinyType==1)" :value="0" v-model="destiny" :items="destinations" :item-text="formatDestinyText"
                return-object dense rounded placeholder="Selecciona el destino de compra" class="w-full">
              </v-autocomplete>
            </template>
            <template v-else> 
              <v-progress-linear indeterminate color="cyan"></v-progress-linear>
            </template> 
        </quoter-card>
      </div>
      <div class="type-1">
        <quoter-card title="Tipo de cotización">
          <v-radio-group v-model="quoterType" @change="resetIncrease" :value="1">
            <v-radio class="w-full m-0 p-0" label="Cotización cliente" :value="1"></v-radio>
            <v-radio class="w-full m-0 p-0" label="Cotización cliente/cliente" :value="2" v-if="$hasAnyRole(['Customer','CustomerPR'])"></v-radio>
          </v-radio-group>
          <v-form ref="formQuoterType" v-if="quoterType == 2">
            <div class="d-flex align-items-baseline">
              <span class="w-40 text-left font-weight-bold font-size-9">Cliente*:</span>
              <v-text-field @input="$refs.formQuoterType.validate()" dense solo class="text-ped pt-0 mt-0 w-40"
                v-model="customer_customer" :rules="[(v) => !!v || 'Cliente es requerido']"></v-text-field>
            </div>
            <div class="d-flex align-items-baseline">
              <span class="w-40 text-left font-weight-bold font-size-9">Vendedor*:</span>
              <v-text-field @input="$refs.formQuoterType.validate()" dense solo class="text-ped pt-0 mt-0 w-40"
                v-model="customer_seller" :rules="[(v) => !!v || 'Vendedor es requerido']"></v-text-field>
            </div>
          </v-form>
        </quoter-card>
      </div>
      <div class="main">
        <quoter-card title="Cotización" v-if="destiny">
          <div class="d-flex align-items-baseline">
            <v-progress-circular v-if="order.length == 0" class="pt-2 mr-1" indeterminate color="primary"
              size="20"></v-progress-circular>
            <span class="w-40 text-left font-weight-bold font-size-9">Folio cotizaciones:</span>
            <v-text-field dense solo readonly class="text-ped pt-0 mt-0 w-40 font-size-8" v-model="order"
              required></v-text-field>
            <div class="w-20"></div>
          </div>
          <div v-if="order.length > 0" class="d-flex align-items-baseline">
            <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
              offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="start_date" label="Fecha Vigencia*" prepend-icon="mdi-calendar" readonly
                  v-bind="attrs" v-on="on" required></v-text-field>
              </template>
              <v-date-picker v-model="effectiveDatePicker" :min-date="new Date()" @input="closeDateMenu1"></v-date-picker>
            </v-menu>
            <div class="w-20"></div>
          </div>
          <div class="d-flex align-items-baseline">
            <span class="w-40 text-left font-weight-bold font-size-9">Número de licitación:</span>
            <v-text-field :disabled="order != '' ? false : true" dense solo class="text-ped pt-0 mt-0 w-40"
              v-model="nu_tender" required></v-text-field>
            <div class="w-20"></div>
          </div>
        </quoter-card>
      </div>
      <div class="search">
        <quoter-card title="Búsqueda" v-if="destiny">
          <!--<div class="d-flex align-items-baseline">
            <span class="w-40 text-left font-weight-bold font-size-9"
              >Descripción:</span
            >
            <v-text-field
              dense
              solo
              class="text-ped pt-0 mt-0 w-40"
              required
            ></v-text-field>
            <div class="w-20"></div>
          </div>-->
          <v-form ref="searchMaterialForm">
            <div class="d-flex align-items-baseline">
              <span class="w-40 text-left font-weight-bold font-size-9">Código de material-SKU/Descripción:</span>
              <v-text-field dense solo class="text-ped pt-0 mt-0 w-40" v-model="searchForm.sku" :disabled="showNameField"
                required type="text" :rules="[(v) => !!v || 'Este campo es requerido']"
                v-on:keyup.enter="onEnterCode()">
                <template v-slot:append>
                  <v-btn v-if="searchForm.sku" icon @click="clearSku">
                    <v-icon>mdi-close-circle</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
              <div id="resultados"></div>
            </div>

            <div class="d-flex align-items-baseline">
              <span class="w-40 text-left font-weight-bold font-size-9">Unidades:</span>
              <v-text-field dense solo class="text-ped pt-0 mt-0 w-40" v-model="searchForm.quantity" required
                :rules="[(v) => !!v || 'La cantidad es requerida', (v) => !!/^[0-9]+$/.test(v) || 'Sólo se admiten números']"
                ref="units"
                v-on:keyup.enter="addItemToCart"></v-text-field>
              <div class="w-20"></div>
            </div>
          </v-form>
          <div v-if="showNameField">
            <div class="d-flex d-inline-block">
              <div style="width: 60px" class="mr-1">
              <img class="w-full" :src="searchForm.url" alt="" />
            </div> 
            <v-text-field v-model="searchForm.name" label="Nombre" solo readonly></v-text-field>
            </div> 
          </div>
          <div class="mt-8">
            <v-btn class="button-green px-16 text-white font-weight-black py-5" rounded-md @click="addItemToCart">
              <span>Agregar a carrito</span>
              <v-icon>mdi-cart</v-icon>
            </v-btn>
          </div>
        </quoter-card>
      </div>
      <div class="promotions">
        <div class="d-flex justify-space-between align-start">
          <quoter-card :showTitle="false" class="w-40">
            <div class="d-flex justify-space-between align-center">
              <span class="font-weight-bold mr-2">Promociones</span>
              <span class="icoiusa-asterisk-circle-outline"></span>
            </div>
          </quoter-card>
          <quoter-card :showTitle="false" class="w-40">
            <div class="d-flex justify-space-between align-center">
              <span class="font-weight-bold mr-2">Liquidaciones</span>
              <span class="icoiusa-tag"></span>
            </div>
          </quoter-card>
        </div>
        <div class="mt-8">
          <quoter-card :showTitle="false" class="w-full" :red="true">
            <div class="d-flex flex-wrap align-center">
              <span v-if="destiny && order" class="w-full text-center text-black font-weight-black text-h5 mt-8">
                $ {{ formatPrice(statetotal.toFixed(2)) }}
              </span>
              <span v-else class="w-full text-center text-black font-weight-black text-h5 mt-8">
                $ 0.00
              </span>
              <p class="w-full text-center text-black font-weight-black text-subtitle-1 mt-4">
                MONTO TOTAL DE LA COTIZACIÓN
              </p>
            </div>
          </quoter-card>
        </div>
        <div class="mt-8">
          <v-btn height="3em" class="button-warning px-16 text-white py-6" rounded-md>
            <span class="font-weight-black text-subtitle-1">MÁS DE 4,200 PRODUCTOS</span>
          </v-btn>
        </div>

      </div>
    </div>
    <v-row v-if="destiny && order">
      <v-col md="4">
        <div class="text-ped  ml-2" style="text-align: left;">
          Detalle Compra
        </div>
      </v-col>
      <v-col md="8">
        <v-row v-if="quoterType == '2'">
          <v-col md="3">
            <div class="text-ped ml-2 mt-5 text-right">
              Seleccionar Incremento:
            </div>
          </v-col>
          <v-col md="2">
            <div class="ml-2  text-right">
              <v-text-field v-model="increase_other" hide-details dense solo class="text-ped pt-0 mt-5 font-size-8"
                v-on:keyup.enter="updateIncreaseState($event)">
                <template v-slot:append>
                  <v-icon class="color-percent-box">mdi-percent-box</v-icon>
                </template>
              </v-text-field>
            </div>
          </v-col>
          <v-col md="3">
            <div v-if="increase_other == 0" class="ml-2 mt-7 text-left">
              Sin incremento
            </div>
          </v-col>
        </v-row>
      </v-col>

    </v-row>
    <v-row v-if="destiny && order">
      <v-col cols="12">
        <v-simple-table>
          <thead class="table-header">
            <tr>
              <th class="table-header text-center">Producto</th>
              <th class="table-header text-center">Cantidad</th>
              <th class="table-header text-center">Empaque</th>
              <th class="table-header text-center">
                Unidad de medida de venta
              </th>
              <th v-if="!this.$hasAnyRole(['Customer','CustomerPR'])" class="table-header text-center">Existencia</th>
              <th class="table-header text-center">
                Promedio mensual de compra*
              </th>
              <th class="table-header text-center">
                Días solicitados de inventario*
              </th>
              <th class="table-header text-center">Precio Descuento</th>
              <th class="table-header text-center">Importe Total</th>
              <th class="table-header"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in statecart" :key="`product-${i}`">
              <td class="h-auto d-flex flex-nowrap align-center">
                <div style="width: 70px" class="mr-1">
                  <img class="w-full" :src="item.image_url" alt="" />
                </div>
                <div class="d-flex flex-column">
                  <span class="text-left font-size-8">{{ item.codigo_material }}
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon :class="item.validacion" v-bind="attrs" v-on="on">mdi-alert-circle
                        </v-icon>
                      </template>
                      <span>{{ item.stock_label }}</span>
                    </v-tooltip>
                  </span>
                  <p class="text-left font-size-8" style="max-width: 150px; word-wrap: break-word">
                    {{ item.nombre_material }}
                  </p>
                </div>
              </td>
              <td class="text-center" style="width: 90px; font-size: 8px;">
                <v-text-field class="font-size-8 mb-0" dense hide-details single-line v-model="item.u_pedidas" @keydown.enter.prevent="editQuantity(item, item.u_pedidas)">
              </v-text-field>
              <br />
               <!--<a class="text-black" @click="editQuantity(item)">
                  {{item.u_pedidas}}
                </a>-->
              </td>
              <td>
                <span class="font-size-8">{{ parseFloat(item.empaque).toFixed(0) }}</span>
              </td>
              <td>
                <span class="font-size-8">{{ item.unidad_medida }}</span>
              </td>
              <td v-if="$hasAnyRole(['Seller','Manager'])">
                <span class="font-size-8">{{ format(item.existencia) }}</span>
              </td>
              <td>
                <span class="font-size-8">{{ item.PMCOM }}</span>
              </td>
              <td>
                <span class="font-size-8">{{ item.DSINV }}</span>
              </td>
              <td>
                <span class="font-size-8">$ {{ format(item.precio_con_descuento) }}</span>
              </td>
              <td>
                <b class="font-size-8">$ {{ format(item.importe_producto) }}</b>
              </td>
              <td>
                <button class="icon-button" @click="deleteItem(item)">
                  <img src="/img/trash-can-outline.svg" alt="" />
                </button>
              </td>
            </tr>
            <tr v-if="statecart.length == 0 && !loadingCart">
              <td colspan="10" class="text-center">
                No hay productos en el carrito
              </td>
            </tr>
            <tr v-else-if="loadingCart">
              <td colspan="10" class="text-center">
                <v-progress-linear indeterminate color="cyan"></v-progress-linear>
                <span>Cargando...</span>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
    <div v-if="destiny && order" class="mt-4 footer-container">
      <div class="btn-dashboard">
        <v-btn block elevation="-1" class="button-block" @click="goTo('/')">
          <span class="font-size-8">Carátula cliente</span>
          <span class="icoiusa-chart-box-outline ml-4"></span>
        </v-btn>
      </div>
      <div class="btn-files" v-if="statecart.length > 0">
        <v-btn block elevation="-1" class="button-block" @click="generateFiles">
          <span class="font-size-8">Generar cotización en CSV</span>
          <span class="icoiusa-file-download ml-4"></span>
        </v-btn>
        <!--<v-text block elevation="-1" >
          <span class="font-size-8">Generar cotización</span>
          <span class="icoiusa-file-download ml-4"></span>
        </v-text>-->
      </div>
      <div class="btn-quotations">
        <v-btn block elevation="-1" class="button-block" @click="goTo('/quoter-history')">
          <span class="font-size-8">Mis cotizaciones</span>
          <span class="icoiusa-file-sign ml-4"></span>
        </v-btn>
      </div>
      <div class="btn-save" v-if="statecart.length > 0">
        <v-btn class="button-green w-full text-white font-weight-bold font-size-8" @click="finalizeQuote">
          <span>Guardar </span>
          <span class="icoiusa-content-save-check-outline ml-4"></span>
        </v-btn>
      </div>
      <div class="btn-total">
        <div class="w-full text-left py-1 new-card-title new-card-pink d-flex justify-space-around">
          <span class="w-50 text-center">Subtotal del pedido:</span>
          <span class="text-center">$ {{ formatPrice(statesubtotal.toFixed(2)) }}</span>
        </div>
        <div class="w-full text-left py-1 new-card-title new-card-pink d-flex justify-space-around">
          <span class="w-50 text-center">I.V.A. 16%</span>
          <span class="text-center">$ {{ formatPrice(stateiva.toFixed(2)) }}</span>
        </div>
        <div class="w-full text-left py-1 new-card-title new-card-red d-flex justify-space-around">
          <span class="w-50 text-center">Total con IVA MXN</span>
          <span class="text-center">$ {{ formatPrice(statetotal.toFixed(2)) }}</span>
        </div>
      </div>
    </div>

    <v-row>
      <v-col>
        <p class="m-0 p-0 text-left">Última actualización {{ actualDate }}</p>
        <p class="m-0 p-0 text-left">
          * Dato estadístico (Considerando los últimos seis meses de compra o a
          partir de la primera compra)
        </p>
        <p class="m-0 p-0 text-left">** Sujeto a facturación</p>
      </v-col>
      <v-col>
        <p class="m-0 p-0 text-right">
          Información confidencial Prohibida su reproducción o divulgaciòn total
          o parcial, así como su uso o aprovechamiento sin autorización escrita
          de IUSA.
        </p>
      </v-col>
    </v-row>
    <v-dialog v-model="quantityDialog.show" persistent max-width="700">
      <v-card>
        <v-card-title>
          <span class="text-h5">Editar cantidad</span>
        </v-card-title>
        <v-card-text>
          <div class="d-flex align-items-baseline">
            <span class="w-30 text-left font-weight-bold font-size-9">Producto:</span>
            <v-text-field dense solo class="text-ped pt-0 mt-0 w-40" required readonly
              v-model="quantityDialog.name"></v-text-field>
          </div>
          <div class="d-flex align-items-baseline">
            <span class="w-30 text-left font-weight-bold font-size-9">Nueva cantidad:</span>
            <v-text-field dense solo class="text-ped pt-0 mt-0 w-40" required type="number" min="1"
              v-model="quantityDialog.quantity"></v-text-field>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn :disabled="loadupdateQuantity" @click="cancelQuantity">Cancelar</v-btn>
          <v-btn color="primary" @click="saveQuantity" :loading="loadupdateQuantity">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="searchProductDialog" :persistent="searchProductsLoader" width="550" min-width="550">
      <v-card>
        <div class="w-full bg-black d-flex justify-content-between align-center px-4">
          <span class="text-white d-flex align-center">Productos</span>
          <v-btn v-if="!searchProductsLoader" icon small style="bg-gray" @click="searchProductDialog = false">
            <v-icon class="text-white font-size-8">mdi-close</v-icon>
          </v-btn>
        </div>
        <div class="w-9 bg-white d-flex flex-wrap">
          <h4 class="w-full mt-4">Búsqueda:</h4>
          <!-- Verifica si la lista de items está vacía -->
          <div v-if="items.length === 0 && !searchProductsLoader" class="w-full text-center">
            <p>No se encontraron productos relacionados con "{{ searchForm.sku }}"</p>
          </div>
          <div v-if="searchProductsLoader" class="w-full text-center">
            <p>
              <v-progress-circular indeterminate color="primary" class="mr-1"></v-progress-circular>
              <span>Buscando productos...</span>
            </p>

          </div>
          <!-- Muestra los result-items si hay resultados -->

          <result-item v-for="item in items" :key="item.MATNR" :item="{
            materialCode: item.MATNR.slice(-6),
            name: item.ARKTX,
            sku: item.MATNR.slice(-6),
          }" @checkboxtoggle="getSKU">
            <div style="width: 60px" class="mr-1">
              <img class="w-full" :src="item.image_url" alt="" />
            </div>
          </result-item>

        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="loading" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Cargando...
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
  </div>

</template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/html2pdf.js/0.8.1/html2pdf.bundle.min.js" integrity="sha512-vDKWohFHe2vkVWXHp3tKvIxxXg0pJxeid5eo+UjdjME3DBFBn2F8yWOE0XmiFcFbXxrEOR1JriWEno5Ckpn15A==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/PapaParse/5.3.0/papaparse.min.js"></script>

<script>
import html2pdf from "html2pdf.js";
import Papa from 'papaparse';
import axios from "@/plugins/axios";
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
import QuoterCard from "./componets/QuoterCard.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import ResultItem from "./componets/ResultItem.vue";
//import general from "@/mixins/general";
import moment from 'moment'; 

export default {
  name: "QuoterCart", 
  watch: {
    destinyType(value) {
      if (value == 2) {
         this.destiny = '';
      }
    },
    quoterType(value) {
      if (value == 2) {
         this.destinyType = 1;
      }
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      destinations: "quotecart/destinations",
      statecart: "quotecart/cart",
      statesubtotal: "quotecart/subtotal",
      stateiva: "quotecart/iva",
      statetotal: "quotecart/total",
      increase: "quotecart/increase",
    }),
    currentPeriod(){
      const today = new Date;
      let firstDay = 1; 
      let year = today.getFullYear();
      let currentMonth = today.toLocaleString('default', { month: 'long' }) 
       currentMonth=  currentMonth.charAt(0).toUpperCase() + currentMonth.slice(1);
      return ` del (${firstDay} de ${currentMonth} ${year} al ${today.getDate()} de ${currentMonth} del ${year})`;
    },
  },
  components: {
    QuoterCard,
    ResultItem,
    CoolLightBox, 
  },
  //mixins: [general],
  data: () => ({  
    destinyType:1,
    loadingDestinations:true,
    loadingCart:true,
    hasError: null,
    materialItem: {},
    subtotal: 0,
    subtotalPrint: 0,    
    loadupdateQuantity:false, 
    iva: 0,
    total: 0, 
    dialogLoading: false,
    customerDestiny: null,
    destiny:null ,
    order: "",
    cart: [],
    requestLock: 0,    
    sendMaterialNew: "",
    dialogPedidos: false,
    order_id: null,
    nu_tender: null,
    menu1: false,
    quoterType: 1, 
    radio_percent: 0,   
    customer_customer: null,
    showPrint:true,
    customer_seller: null,
    orderType: null,
    start_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    effectiveDatePicker:new Date(),
    today2: moment().format('YYYY/MM/DD'),
    catalogo_list: [], 
    orders_list: [],
    items:[ ],  
    customerInfo: {
      NAME1: null,
      KUNNR: null,
    },
    customerDestinyList: [],
    destiny: "",
    searchForm: {
      valid: false,
      sku: null,
      quantity: 1,
    },
    showNameField: false,
    actualDate: null,
    errors: {},
    orderItemsList: [],
    quantityDialog: {
      show: false,
      id: null,
      name: null,
      quantity: null,
      item: null,
    },
    searchProductDialog: false,
    searchProductsLoader:false,
    selectedProducts: [],
    loading: false,
    loadingCustomerinformation:true,
    showQuoterCard: false, // Controla la visibilidad del cuadro de texto flotante
    customer_code:[],
    customerInfo:[],
    items: [],
    increase_other: 0,
  }),
  
   async mounted() {   
    this.generarPDF();
    if (!this.$hasAnyRole(['Customer','CustomerPR'])){
      const currentCustomerInfo= JSON.parse(localStorage.getItem('currentCustomerInfo')) || [];
          if (currentCustomerInfo.length<1) {
          this.$router.push({name:'Home'})
          }    
          this.customerInfo = currentCustomerInfo;
          this.customer_code =  parseInt(currentCustomerInfo?.KUNNR,10) 
      }else{ 
        this.customer_code = this.user.custumer_code;
      } 


    this.getInfoCustomer();
    this.getOrderID();
    this.checkOrdersHistory(); 
    this.orderTypeItemsData();
    this.orderTypeItemsDataPR();
    this.actualDate = this.getCurrentDateTime(); 


    await this.loadCustomerDestinations({
      custumer_code: this.customer_code,
      VKORG: this.user.VKORG,
      VTWEG: this.user.VTWEG,
      VKBUR: this.user.VKBUR,
    });
    await this.loadQuoterOrder({
      custumer_code: this.customer_code,
      user_id: this.user.id,
      number_tender: this.nu_tender,
      VTWEG: this.user.VTWEG,
    });
    this.loadingCart = true;
    await this.loadCartItems({
      custumer_code: this.customer_code,
      VKORG: this.user.VKORG,
      VTWEG: this.user.VTWEG,
      VKBUR: this.user.VKBUR,
      doctype: this.orderType,
    });
    this.loadingCart = false; 
  },
  methods: { 
    ...mapMutations({
      updateState: "quotecart/UPDATE_STATE",
    }),
    ...mapActions({
      loadCustomerDestinations: "quotecart/loadCustomerDestinations",
      loadQuoterOrder: "quotecart/loadQuoterOrder",
      loadCartItems: "quotecart/loadCartItems",
      getMaterialInfo: "quotecart/getMaterialInfo",
      saveNewMaterialQuoter: "quotecart/saveNewMaterialQuoter",
      deleteMaterialOrder: "quotecart/deleteMaterialOrder",
      updateQuoterOrderIncrease: "quotecart/updateQuoterOrderIncrease",
      saveQuoterOrder: "quotecart/saveQuoterOrder",
      createPDF: "quotecart/createPDF", 
      updateMaterialQuantity: "quotecart/updateMaterialQuantity",
      sendToExcel: 'printer/sendToExcel',
      sendToPDF: 'printer/sendToPDF',
    }),
    generarPDF(){

            this.sendToPDF({active: true});
    },
    onEnterCode() {
        this.$refs["units"].focus();
    },
    closeDateMenu1() { 
      this.menu1 = false; 
      this.start_date = new Date(
        this.effectiveDatePicker - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10); 
    },
    formatPrice(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    toggleQuoterCard() {
      this.showQuoterCard = !this.showQuoterCard; // Alternar la visibilidad del cuadro de texto
    },
    consultarMaterial(){
   
    },
    getSKU(eventsku) {
  console.log(eventsku);

  // Obtener SKU
  const sku = eventsku.item.materialCode.slice(-6);

  // Obtener Name
  const name = eventsku.item.name; // Reemplaza 'name' con el nombre real del campo

  // Asignar valores a searchForm
  this.searchForm.sku = sku;
  this.searchForm.name = name;
  this.searchForm.url = eventsku.url
  this.showNameField = true;

  this.searchProductDialog = false;
}, 
     clearSku() {
      this.searchForm.sku = "";
      this.showNameField = false;
      
    }, 
    newMaterialSearch(item) {
      this.sendMaterialNew = item;
    },
    getOrderID() {
      let type = "CL";
      //let type = "VE"; // ENCASO DE PORTAL VENDEDOR ENVIAR ESTA VARIABLE
      axios
        .post("quoterOrder", {
          customer: this.customer_code,
          user_id: this.user.id,
          type: type,
          nu_tender: this.nu_tender,
          channel: this.user.VTWEG,
        })
        .then((response) => {
          this.order_id = response.data.order_id;  
          this.getOrderInfo(this.order_id);
        })
        .catch((error) => {
          //alert(error);
          console.log(error);
        })
        .finally();
    },
    getOrderInfo(id) {
      axios
        .post("getQuoterInfo", {
          id_order: id,
        })
        .then((response) => {
          this.order = response.data.name;
        })
        .catch((error) => {
          //alert(error);
          console.log(error);
        })
        .finally();
    },
    checkOrdersHistory() {
      axios
        .post("checkOrdersHistory", {
          customer: this.customer_code,
        })
        .then((response) => {
          this.orders_list = response.data;
        })
        .catch((error) => {
          //alert(error);
          console.log(error);
        })
        .finally();
    },
    /*
    saveOrderInfo() {
      axios
        .post("saveQuoterInfo", {
          order_id: this.order_id,
          orden_compra: this.order,
          destiny: this.quoterType === 1 ? this.destiny : this.customerDestiny.KUNNR,
          cart: this.cart,
          total: this.total,
        })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          //alert(error);
          console.log(error);
        })
        .finally();
    }, */
    orderTypeItemsData() {
      axios
        .post("orderTypeQuoter")
        .then((response) => { 
          this.orderItemsList = response.data;
          this.orderType = this.orderItemsList.type;  
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => (this.dialogLoading = false));
    },

    orderTypeItemsDataPR() {
      axios
        .post("orderTypePRQuoter")
        .then((response) => { 
          this.orderItemsListPR = response.data;
          this.orderType = this.orderItemsList.type; 
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => (this.dialogLoading = false));
    }, 
    format(value) {
      let result = Number(value).toFixed(2);
      return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getInfoCustomer() {
      this.loadingCustomerinformation =true;
      axios
        .post("infoCustomer", {
          customer: this.customer_code,
          VKORG: this.user.VKORG,
          VTWEG: this.user.VTWEG,
          VKBUR: this.user.VKBUR,
        })
        .then((response) => {
          this.customerInfo = response.data;
          this.saldo = this.customerInfo.SKFOR.replace(/,/g, "");
          this.limite = this.customerInfo.KLIMK.replace(/,/g, "");
          this.porcentaje =
            (parseInt(this.saldo) * 100) / parseInt(this.limite);
          this.porcentaje = parseInt(this.porcentaje);
        })
        .catch((error) => { 
          this.loadingCustomerinformation =false;
          console.log(error);
        })
        .finally(() => {
          this.loadingCustomerinformation =false;
        });
    },
    async addItemToCart() { 
      this.$refs.searchMaterialForm.validate();
      if (this.searchForm.sku == "" || this.searchForm.sku == null) {
        this.$toast.warning("El Código de material es requerido");
        return false;
      } 
      var ExpRegSoloNumeros="^[0-9]+$";
      if (! /^[0-9]+$/.test(this.searchForm.quantity)) {
        this.$toast.warning("La unidad debe ser un número válido");
        return false;
      }
      if (this.searchForm.quantity <= 0) {
        this.searchForm.quantity =1
      }
      let index = this.statecart.findIndex(
        (item) => item.codigo_material == this.searchForm.sku
      );
      if (index >= 0) {
        this.$toast.warning("El código de material ya está en el carrito");
        return false;
      }
      
      //Evaluación de Cadena Invalida de Solo Números     
     if( this.searchForm.sku.match(ExpRegSoloNumeros)==null){
        this.searchProductsLoader = true;
        this.searchProductDialog = true;
        axios.post("/materialComplete",{name:this.searchForm.sku}).then(response=>{
      
          this.items=response.data;
        })
        .catch(()=> {
        this.searchProductsLoader = false;
        })
        .finally(()  => {
        this.searchProductsLoader = false;
        })
     } else{
      try {
        this.loading = true;
        const product = await this.getMaterialInfo({
          sku: this.searchForm.sku,
          quantity: this.searchForm.quantity,
          custumer_code: this.customer_code,
          VKORG: this.user.VKORG,
          VTWEG: this.user.VTWEG,
          VKBUR: this.user.VKBUR,
          order_type: this.orderType,
        });
        await this.saveNewMaterialQuoter({
          order_id: this.order_id,
          item: product,
          percent: this.quoterType,
        });
        this.searchForm.sku = null;
        this.showNameField = false; 
        this.searchForm.quantity = null;
        this.loading = false;
        this.$refs.searchMaterialForm.reset();
        this.$refs.searchMaterialForm.resetValidation();  
        this.$toast.success("Producto agregado");
      } catch (error) {
        if (error.response?.data?.error_code) {
          this.$toast.warning(error.response?.data?.error_code); 
        } 
        this.loading = false;
        console.log(error);
      }
     }  
    },
    deleteItem(item) {
      this.deleteMaterialOrder({ order_id: this.order_id, item }).then(() => {
        this.$toast.success("Producto elminado del carrito");
      });
    },
    formatDestinyText(item) {
      return `${item.KUNNR.replace(/^0+/, '')} ${item.STRAS} ${item.ORT01} ${item.PSTLZ}`;
    },
    updateIncreaseState(event) {
      let value = event.target.value;
      this.updateState({ key: "increase", value });
      this.updateQuoterOrderIncrease({
        customer_code: this.customer_code,
        VKORG: this.user.VKORG,
        VTWEG: this.user.VTWEG,
        VKBUR: this.user.VKBUR,
      });
    },
    async finalizeQuote() { 
      const valid = await this.validateBeforeSave();
      if (!valid) {
        return false;
      }
       const destiny = (this.quoterType==2 && this.destinyType==2) ? this.destiny: this.destiny.STRAS;
      this.saveQuoterOrder({
        orderFolio: this.order,
        start_date: this.start_date,
        nu_tender: this.nu_tender,
        quoter_type: this.quoterType,
        increase: this.increase,
        customer_customer: this.customer_customer,
        customer_seller: this.customer_seller,
        orderType: this.customerInfo.VTWEG=="PR"?"PSIU":"PSDT",
        destiny: destiny
      }).then(() => {
        this.$swal
          .fire(this.order, "Cotización guardada.", "success")
          .then(() => {
            this.$router.replace({
              name: "QuoterHistory",
            });
          });
      });
    },
    validateBeforeSave() {
      return new Promise((resolve) => { 
        if (this.statecart.length <= 0){
          this.errors.quoter_type = ["Tiene que agregar un producto al carrito"];
          this.$toast.error("Tiene que agregar un producto al carrito");
          resolve(false);
        }
        if (this.destiny == null || this.destiny == "") {
          this.errors.destiny = ["El Destino es requerido"];
          this.$toast.error("El Destino es requerido");
          resolve(false);
        }
        if (this.quoterType !== 1 && this.quoterType !== 2) {
          this.errors.quoter_type = ["El Tipo de cotización es requerido"];
         this.$toast.error("El Tipo de cotización es requerido");
         resolve(false);
        } 
        if (this.quoterType === 2) {
          this.$refs.formQuoterType.validate();
        if (this.customer_customer == null || this.customer_customer == "") {
        this.errors.destiny = ["El Cliente es requerido"];
         this.$toast.error("El Cliente es requerido");
         resolve(false);
         }
        if (this.customer_seller == null || this.customer_seller == "") {
         this.errors.destiny = ["El Vendedor es requerido"];
         this.$toast.error("El Vendedor es requerido");
         resolve(false);
        }
         if (this.destinyType==2) {
          this.$refs.destinyForm.validate()
          if (this.destiny.length < 3 || this.destiny.length >= 500) {
          this.errors.destiny = ["El Destino debe contener almenos 3 caracteres"];
          this.$toast.error("El Destino debe contener almenos 3 caracteres");
          resolve(false);
        } 
        }
  }     
  resolve(true);
      });
    },
    resetIncrease() {
      this.updateIncreaseState(0);
    },
    editQuantity(item, cant) {
      let quantity = parseInt(cant);
    /*this.quantityDialog.quantity = quantity;
    this.quantityDialog.name = item.nombre_material;
    this.quantityDialog.id = item.id;
    this.quantityDialog.item = item;
    this.quantityDialog.show = true;*/
    this.saveQuantity(item,quantity);
    },
    cancelQuantity() {
      this.quantityDialog.quantity = null;
      this.quantityDialog.name = null;
      this.quantityDialog.id = null;
      this.quantityDialog.item = null;
      this.quantityDialog.show = false;
    },
    saveQuantity(item,quantity) {
      this.loadupdateQuantity = true;
      this.updateMaterialQuantity({
        /*codigo_material: this.quantityDialog.item.codigo_material,
        quantity: this.quantityDialog.quantity,
        precio_con_descuento: this.quantityDialog.item.precio_con_descuento,
        custumer_code: this.customer_code,*/
      codigo_material: item.codigo_material,
      quantity: quantity,
      precio_con_descuento:item.precio_con_descuento,
      custumer_code: this.customer_code
      })
        .then((data) => {
        
          this.$toast.success("Producto actualizado");
          this.cancelQuantity();
          this.loadupdateQuantity = false;
        })
        .catch((error) => {
          console.log(error);
          this.$toast.error(error);
          this.loadupdateQuantity = false;
        });
    },
    formatInt(value) {
      try {
        return parseInt(value);
      } catch (error) {
        return value;
      }
    },
    generateFiles() {
      //this.downloadPdf();
      this.exportToCSV();
    },
    async downloadPdf() {
      const valid = await this.validateBeforeSave();
      if (!valid) {
        return false;
      }
      this.loading = true;
      this.createPDF({
        customer_customer: this.customer_customer,
        customer_seller: this.customer_seller,
      })
        .then((pdf) => {
          const url = window.URL.createObjectURL(new Blob([pdf]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "cotizacion.pdf"); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.log(error);
          this.$toast.warning("Ocurrio un error al generar el PDF");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    exportToCSV() {
    const headers = ["Version", "Codigo de productos", "Orden de Compra"];
    const dataLine2 = ["1.3.0", "IUSA", "OC-0"];
    const data = this.statecart.map((item) => {
      return [
      `${this.formatInt(item.u_confirm)}`,
        //`${this.formatInt(item.u_confirm)} ${item.recordatorios !== 0 ? ` BACKORDER ${item.recordatorios}` : ""}`,
        item.codigo_material,
        item.nombre_material,
      ];
    });

    const csvData = [["Version", "Codigo de productos", "Orden de Compra"],["1.3.0", "IUSA", "OC-0"],['Cantidad','Código', 'Nombre' ]];
    csvData.push(...data);

    // Convertir a CSV
    const csv = Papa.unparse(csvData);

    // Descargar el archivo CSV
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'cotizacion.csv';
    a.click();
   },
   /*
    showSearchProductDialog() {
      this.searchProductDialog = true;
    },*/
    handleCheckboxToggle(payload) {
      console.log("handleCheckboxToggle", payload);
      if (payload.checked) {
        this.selectedProducts.push(payload.item);
      } else {
        const index = this.selectedProducts.find(
          (item) => item.materialCode == payload.item.materialCode
        );
        this.selectedProducts.splice(index, 1);
      }
    },
    goTo(path) {
      this.$router.push(path);
    },
    printPage(){  
      this.printing=true;
      this.showPrint = false;
      // Activa la vista de impresión
     //   window.print();
     // const pdfName = "Asesor"+this.user.employee_number+".pdf";
     var opt = {
        margin: 0,
        filename: "cotizacion.pdf",
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 1.5 },
        jsPDF: { unit: 'px', format: [1100,1200], orientation: 'landscape' }
      };
      html2pdf(this.$refs.printResultSellerView,opt) 
        .then(pdf => {
          this.showPrint =true;
          this.printing=false;
        });  
    }, 
    getCurrentDateTime() {
      const now = new Date();
    
      const day = String(now.getDate()).padStart(2, '0');
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const year = now.getFullYear();
    
      let hours = now.getHours();
      const ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12 || 12;
    
      const minutes = String(now.getMinutes()).padStart(2, '0');
    
      const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes} ${ampm}`;
    
      return formattedDateTime;
    },
    
  },
};
</script>

<style scoped>
.quoter-card {
  font-size: 0.9em;
  /* Tamaño de fuente deseado */
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40%;
}

.w-9 {
  width: 99%;
}

.font-size-7 {
  font-size: 0.7em;
}

.font-size-8 {
  font-size: 0.8em;
}

.font-size-9 {
  font-size: 0.9em;
}

.icon-button {
  background: #f5f5f5 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
  border: 1px solid #00000000;
  border-radius: 4px;
  opacity: 1;
  padding-left: 0.4em;
  padding-right: 0.4em;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  cursor: pointer;
}

.icon-button:hover {
  background-color: #ababab;
}

.button-block {
  box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
  border-radius: 5px;
  background: #ffffff;
}

.button-primary-red {
  background: transparent linear-gradient(180deg, var(--light-🌕-error-b00020) 0%, #580010 100%) 0% 0% no-repeat padding-box;
  border: 1px solid var(--gris-de-las-tablas);
  background: transparent linear-gradient(180deg, #b00020 0%, #580010 100%) 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
  border: 1px solid #dde1e6;
  border-radius: 27px;
}

.button-warning {
  background: transparent linear-gradient(180deg, var(--warning-hover-e0a800) 0%, #705400 100%) 0% 0% no-repeat padding-box;
  border: 1px solid var(--gris-de-las-tablas);
  background: transparent linear-gradient(180deg, #e0a800 0%, #705400 100%) 0% 0% no-repeat padding-box;
  border: 1px solid #dde1e6;
}

.button-green {
  background: transparent linear-gradient(180deg, var(--success-hover-218838) 0%, #11441c 100%) 0% 0% no-repeat padding-box;
  background: transparent linear-gradient(180deg, #218838 0%, #11441c 100%) 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
  border: 1px solid #707070;
}

.table-header {
  background: transparent linear-gradient(180deg, var(--light-🌕-error-b00020) 0%, #580010 100%) 0% 0% no-repeat padding-box;
  background: transparent linear-gradient(180deg, #b00020 0%, #580010 100%) 0% 0% no-repeat padding-box;
  color: white !important;
}

.new-card-red {
  background: #fff6f6 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
}

.new-card-red {
  background: transparent linear-gradient(180deg, #b00020 0%, #580010 100%) 0% 0% no-repeat padding-box;
  color: white !important;
  box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
}

.new-card-warning {
  background: var(--warning-hover-e0a800) 0% 0% no-repeat padding-box;
  border: 1px solid var(--gris-de-las-tablas);
  background: #e0a800 0% 0% no-repeat padding-box;
  border: 1px solid #dde1e6;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: 1em 1em;
  grid-auto-flow: row;
  grid-template-areas:
    "customer"
    "destination"
    "promotions"
    "type-1"
    "main"
    "search"
    "increments";
}

.customer {
  grid-area: customer;
}

.destination {
  grid-area: destination;
}

.type-1 {
  grid-area: type-1;
}

.main {
  grid-area: main;
}

.promotions {
  grid-area: promotions;
}

.increments {
  grid-area: increments;
}

.search {
  grid-area: search;
}

.btnSearch {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
  border: 1px solid #707070;
  border-radius: 5px;
}

.footer-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: 1em 1em;
  grid-auto-flow: row;
  grid-template-areas:
    "btn-total"
    /*"btn-order" */
    "btn-dashboard"
    "btn-files"
    "btn-save"
    "btn-quotations";
}

/*.btn-order {
  grid-area: btn-order;
}*/

.btn-dashboard {
  grid-area: btn-dashboard;
}

.btn-files {
  grid-area: btn-files;
}

.btn-quotations {
  grid-area: btn-quotations;
}

.btn-save {
  grid-area: btn-save;
}

.btn-total {
  grid-area: btn-total;
  margin-top: 2em;
}

@media (min-width: 1024px) {
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 0.3fr 0.3fr 0.3fr;
    gap: 1em 1em;
    grid-auto-flow: row;
    grid-template-areas:
      "customer customer customer customer customer promotions promotions"
      "type-1 type-1 destination destination destination promotions promotions"
      "main main search search search increments increments";
  }

  .footer-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 1em 1em;
    grid-auto-flow: row;
    grid-template-areas:
      "btn-files btn-save btn-total "
      "btn-quotations btn-dashboard btn-total "
      ". . btn-total";
  }

  .btn-total {
    grid-area: btn-total;
    margin-top: 0;
  }
  .color-percent-box{
    color: #b00020 !important;;
  }
}
</style>
