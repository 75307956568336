<template>
    <div>
        <div class="text-left pa-1 pl-3" style="background-color: #000; color:#fff">
            Histórico (VEP + PFD)
          </div>
          <v-simple-table dense>
            <template v-slot:default>
              <thead class="table-heading ">
                <tr>
                  <th class="text-center text-dark "><b>Periodo</b></th>
                  <th class="text-center text-dark "><b>(VEP&nbsp;+&nbsp;PFD)</b></th>
                  <th class="text-center text-dark "><b>VEP</b></th>
                </tr>
              </thead>
              <tbody class="custom-table-bordered">
                <tr v-for="historico, index in historicos" :key="index">
                  <td>{{historico.date.period}}</td>
                  <td class="font-weight-bold" :class="{'color-min': minPVEPPFD == historico.porcentVEPPFD,'color-max': maxPVEPPFD == historico.porcentVEPPFD &&  maxPVEPPFD!=0, }"><b>{{formatoSinDecimales(historico.porcentVEPPFD)}}%</b></td>
                  <td class="font-weight-bold" :class="{'color-max': maxVEP == historico.porcentVEP &&  maxVEP!=0,'color-min': minVEP == historico.porcentVEP}"><b>{{formatoSinDecimales(historico.porcentVEP)}}%</b></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
    </div> 
</template>
<script> 
export default({
    name:"PaymentHistory",
    props: ['historicos'],
    data(){
      return {        
        maxVEP: 0,    
        minVEP: 0,
        minPVEPPFD: 0,
        maxPVEPPFD: 0,
      }
    }, 
    watch:{
      historicos:{
        immediate: true,
        handler(newData) {
          const vepValues = newData.map(item => parseFloat(item.porcentVEP));
          this.maxVEP = Math.max(...vepValues);
          this.minVEP = Math.min(...vepValues);

          const pvepfdValues = newData.map(item => parseFloat(item.porcentVEPPFD));
          this.minPVEPPFD = Math.min(...pvepfdValues);
          this.maxPVEPPFD = Math.max(...pvepfdValues);
        }
      }   
    },
    methods:{      
      formatoSinDecimales(numeroDecimal){
        return numeroDecimal.toString().split('.')[0];      
      }
    }
    
});
</script>

<style scoped>
  .color-max{
    color: green !important;
  }
  
  .color-min{
    color: red !important;
  }
</style>