const rulesCheckListInit = [
  { text: '8 carácteres mínimo', state: 'undone' },
  { text: 'Letra mayúscula', state: 'undone' },
  { text: 'Letra minúscula', state: 'undone' },
  { text: 'Carácter especial', state: 'undone' },
  { text: 'Un número y no más de 3 consecutivos', state: 'undone' },
]

export default {
  data() {
    return {
      rulesCheckList: rulesCheckListInit
    }
  },

  methods: {
    validatePasswordRules(password){
      this.length(password) ? this.rulesCheckList[0].state = 'done' : this.rulesCheckList[0].state = 'undone'
      this.upperCase(password) ? this.rulesCheckList[1].state = 'done' : this.rulesCheckList[1].state = 'undone'
      this.lowerCase(password) ? this.rulesCheckList[2].state = 'done' : this.rulesCheckList[2].state = 'undone'
      this.specialCharacter(password) ? this.rulesCheckList[3].state = 'done' : this.rulesCheckList[3].state = 'undone'
      this.number(password) ? this.rulesCheckList[4].state = 'done' : this.rulesCheckList[4].state = 'undone'
    },

    passwordRequired(password){
      return !!password || 'Este campo es requerido'
    },

    length(password) {
      return password.length >= 8;
    },

    upperCase(password) {
      const passwordRules = /^(?=.*[A-Z])/;
      return passwordRules.test(password);
    },

    lowerCase(password) {
      const passwordRules = /^(?=.*[a-z])/;
      return passwordRules.test(password);
    },

    number(password) {
      const passwordRules = /^(?=.*\d)/;
      return passwordRules.test(password) && this.secuenceOfNumbers(password);
    },

    specialCharacter(password) {
      const passwordRules = /^(?=.*[@.#$!%*?&^])[A-Za-z\d@.#$!%*?&]/;
      return passwordRules.test(password);
    },
      
    passwordMatch(password, newPassword) {
      return password === newPassword || 'Las constraseñas no coinciden';
    },

    secuenceOfNumbers(password) {
      const findSecuence = (secuence, matchWord) => {
        let coincidences = 0;

        for (let i = 1; i < matchWord.length; i++) {
          let lastChar = matchWord.charAt(i - 1);
          let char = matchWord.charAt(i);

          let expectedChar = secuence.charAt(secuence.indexOf(lastChar) + 1);

          if (char == expectedChar) coincidences += coincidences ? 1 : 2;
          else coincidences = 0;

          if (coincidences == 3) return true;
        }

        return false;
      };

      if (findSecuence('0123456789', password) || findSecuence('987654321', password))
        return false;

      return true;
    },

    resetRulesCheckList(){
      this.validatePasswordRules('');
    }
  },
};
