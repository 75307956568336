<template>
    <v-row>
        <v-col class="d-flex justify-content-end mt-2" v-if="opt == 'byGroup'">
            
            <v-btn-toggle class="group-btn">
                <router-link :to="`/customer-account-statement/${opt}`">
                    <v-btn value="left" :class="{'active-button': validateRoute('/customer-account-statement')}">
                        Estado de Cuenta
                    </v-btn>
                </router-link>

                <router-link :to="`/order-status/${opt}`">
                    <v-btn value="center" :class="{'active-button': validateRoute('/order-status')}">
                        Estado de Pedidos
                    </v-btn>
                </router-link>

                <router-link :to="`/client-group/${opt}`">
                    <v-btn value="right" :class="{'active-button': validateRoute('/client-group')}">
                        Resumen de Grupo
                    </v-btn>
                </router-link>
            </v-btn-toggle>
            
        </v-col>
    </v-row>
</template>

<script>
export default {
    props:{
        opt: {
            default: null
        }
    },
    methods:{
        validateRoute(route){
            return this.$route.path === route + this.validateByClient()
        },
        validateByClient(){
            return this.opt == 'byGroup' ? '/' + this.opt : ''
        }
    }
}
</script>

<style scoped>
    .group-btn button{
        background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box !important;
        background: #FFFFFF 0% 0% no-repeat padding-box !important;
        box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029 !important;
        opacity: 1;
        text-transform: inherit !important;
        height: auto !important;
        min-height: 32px !important;
        padding: 10px !important;
    }

    .group-btn .active-button{
        background: transparent linear-gradient(180deg, var(--warning-disable-ffd75e) 0%, #806C2F 100%) 0% 0% no-repeat padding-box !important;
        background: transparent linear-gradient(180deg, #FFD75E 45%, #806C2F 85%) 0% 0% no-repeat padding-box !important;
        box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029 !important;
        opacity: 1;
        width: 201px;
        height: 32px;
    }
</style>