<template>
  <v-dialog v-model="showModal" width="700" persistent>
    <v-card>
      <modal-bar :title="barTitle" @on-close="showModal = false"/>

      <div class="px-4 py-7">
        <icon-title icon="mdi-cart-remove" :text="title" class="mb-4" title-class="text-subtitle-1 text-md-subtitle-1 black--text ml-4"/>
        <v-divider color="black" thickness="20px" />

        <h6 class="text-subtitle-2 pt-4">
          {{ subtitle }} {{ orderConcept }}
        </h6>

        <div class="form-container mt-7">

          <v-alert outlined color="error" class="mb-4" v-if="error">
            Sucedio un error al elimnar
          </v-alert>

          <v-alert outlined color="success" class="mb-4 error--text" v-if="deleted">
            Eliminado correctamente
          </v-alert> 

          <delete-reasons-form
            v-model="deleteForm"
            @on-cancel="showModal = false"
            @on-delete="$emit('delete-order', deleteForm)"
            :disabled="deleting || deleted"
          />
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import DeleteReasonsForm from '../forms/DeleteReasonsForm.vue';
import ModalBar from './ModalBar.vue';
import IconTitle from '../IconTitle.vue';

export default {
  name: 'DeleteOrderConfirmationModal',

  props: {
    value: {
      type: Boolean,
      default: true,
    },

    title: {
      type: String,
      default: ''
    },

    barTitle: {
      type: String,
      default: null
    },

    subtitle: {
      type: String,
      default:  'Orden de compra:',
    },

    orderConcept: {
      type: String,
      required: true,
    },

    deleting: Boolean,
    deleted: Boolean,
    error: Boolean,
  },

  components: { ModalBar, IconTitle, DeleteReasonsForm },

  data() {
    return {
      deleteForm: {
        firstReason: '',
        secondReason: '',
      }
    }
  },

  computed: {
    showModal: {
      set(newValue) {
        this.$emit('input', newValue);
      },
      get(){
        return this.value
      }
    }
  }
}
</script>

<style >
.form-container { 
  max-width: 400px;
  margin: auto;
}
</style>