 
  <script>
  import { Pie } from 'vue-chartjs';
  
  export default {
    extends: Pie,
    props: {
        chartdata: {
        type: Object,
        default: null
      },
      options: {
        type: Object,
        default: () => ({})
      }
    },
    mounted() {
      this.renderChart(this.chartdata, this.options);
    },
    watch:{
        chartdata (){
            
            this.renderChart(this.chartdata, this.options);
        }
    }
  };
  </script>
<style>
    .trimestre-client-group #pie-chart{
        width:100% !important;
    }
</style>