<template>
  <v-card class="m-4" elevation="0">
    <v-card-title>Solicitudes de Pagaré</v-card-title>
    <v-card-text>
      <div class="text-center">
        <v-text-field v-model="search" prepend-icon="mdi-magnify" @keyup="onKeyup" clearable @click:clear="clearSearch"
          label="Escribe para filtrar registros"></v-text-field>
      </div>
      <iel-table :headers="headers">
        <tr v-if="creditnotes.length <= 0">
          <iel-table-cell :cols="10">No hay registros</iel-table-cell>
        </tr>
        <tr v-for="request in creditnotes" :key="`request-${request.id}`">
          <iel-table-cell>{{ request.customer?.channel }}</iel-table-cell>

          <iel-table-cell>
            <a target="_blank" :href="`/credit/customers-request/${request.customer?.id}`">{{
              request.customer?.custumer_code }}</a>
          </iel-table-cell>

          <iel-table-cell>{{ request.customer?.company_name }}</iel-table-cell>

          <iel-table-cell>$ {{ request.amount | formatCurrency }}</iel-table-cell>

          <iel-table-cell>
            <a target="_blank" :href="`${url}/${request.promissory_notes_path}`">Pagaré</a>
          </iel-table-cell>

          <iel-table-cell>{{ request.name_endorsement }}</iel-table-cell>

          <iel-table-cell>
            <a target="_blank" :href="`${url}/${request.card_id_path}`">ID Aval</a>
          </iel-table-cell>

          <iel-table-cell>
            <a target="_blank" :href="`${url}/${request.endorsement_address_path}`">Comprobante Domicilio</a>
          </iel-table-cell>

          <iel-table-cell>
            <v-chip class="ma-2" small>
              {{ request.status.status }}
            </v-chip>
          </iel-table-cell>

          <iel-table-cell>
            <v-btn small icon color="warning" @click="openStatusDialog(request.id)" title="Cambiar estatus">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </iel-table-cell>
        </tr>
      </iel-table>
      <div class="text-center my-2">
        <v-pagination v-model="pagination.page" :length="pagination.to"></v-pagination>
      </div>
    </v-card-text>

    <v-dialog v-model="form.dialog" width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Validar pagaré</span>
        </v-card-title>
        <v-card-text>
          <v-row align="center">
            <v-col cols="6">
              <v-subheader>
                Estatus
              </v-subheader>
            </v-col>
            <v-col cols="6">
              <v-select v-model="form.status" :items="[{ label: 'Aceptar', value: 'aceptada' }, { label: 'Rechazar', value: 'rechazada-credito' }]" item-text="label" outlined dense
                item-value="value" label="Selecciona el estatus"></v-select>
            </v-col>
            <v-col cols="6" v-if="form.status == 'rechazada-credito'">
              <v-subheader>
                Comentario
              </v-subheader>
            </v-col>
            <v-col cols="6" v-if="form.status == 'rechazada-credito'">
              <v-textarea
                v-model="form.comment"
                outlined
                dense
                label="Comentario del rechazo"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="closeDialog" :disabled="loading">
            Cerrar
          </v-btn>
          <v-btn color="primary darken-1" @click="saveData" :disabled="loading">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { debounce } from "@/utilities/helpers"
import IelTable from "@/components/tables/IElTable.vue";
import IelTableCell from "@/components/tables/IELTableCell.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "",
  components: { IelTable, IelTableCell },

  data: () => ({
    loading: false,
    url: '',
    search: '',
    form: {
      dialog: false,
      creditnoteId: null,
      status: null,
    },
    headers: [
      { text: "Canal" },
      { text: "Número cliente" },
      { text: "Razón social" },
      { text: "Monto" },
      { text: "Pagaré" },
      { text: "Aval" },
      { text: "ID Aval" },
      { text: "Comprobante Domicilio" },
      { text: "Estatus" },
      { text: "Acciones" },
    ],
  }),

  computed: {
    ...mapGetters({
      creditnotes: "creditnotes/allCreditNotes",
      pagination: "creditnotes/pagination"
    }),
  },

  mounted() {
    this.url = this.$filesUrl;
    this.debounceFn = debounce(() => this.loadData(this.search), 800)
    this.loadData();
  },

  methods: {
    ...mapActions({
      loadCustomerRequest: "creditnotes/loadCustomerRequest",
      updateCreditNote: "creditnotes/updateCustomerRequest",
    }),
    onKeyup() {
      this.debounceFn()
    },
    loadData(search = "") {
      this.loadCustomerRequest(search);
    },
    openStatusDialog(creditnoteId) {
      this.form.dialog = true;
      this.form.creditnoteId = creditnoteId;
    },
    closeDialog() {
      this.form.dialog = false;
      this.form.creditnoteId = null;
      this.form.status = null;
    },
    clearSearch() {
      this.loadData();
    },
    saveData() {
      this.loading = true;
      this.updateCreditNote({ id: this.form.creditnoteId, form: this.form})
      .then(() => {
        if(this.form.status == 'aceptada') {
          this.$toast.success('Pagaré actualizado correctamente');
        } else {
          this.$toast.warning('Pagaré rechazado correctamente');
        }
        this.loadData();
        this.closeDialog();
      }).finally(() => this.loading = false);
    }
  },
};
</script>
