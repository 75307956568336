<template>
	<v-btn 
		@click="$emit('on-click')"
		class="card-shadow rounded-2" 
		:color="color" 
		:dark="dark" 
		:fab="!noFab"
		:x-small="!large"
	>
		<v-icon>{{ icon }}</v-icon> <slot />
	</v-btn>
</template>

<script>
export default {
	name: 'IconBtn',

	props: {
		icon: {
			type: String,
			default: '',
		},

		color: {
			type: String,
			default: 'white'
		},

		dark: Boolean,
		noFab: Boolean,
		large: Boolean,
	}
}
</script>