<template>
  <GmapMap :center="mapCenter" style="width: 100%; height: 350px" ref="gmap">
    <GmapMarker
      v-for="(location, index) in locationsWithIcons"
      :key="index"
      :position="{ lat: location.lat, lng: location.lng }"
      :icon="location.icon"
      @click="openInfoWindow(location)"
    />
    <GmapInfoWindow
      :position="infoWindowPosition"
      :opened="infoWindowOpened"
      @closeclick="closeInfoWindow"
    >
      <div style="color: #000">
        {{ infoWindowText }}
      </div>
    </GmapInfoWindow>
  </GmapMap>
</template>
  
<script>
import moment from 'moment';
export default {
  name: 'LocationCustomers',
  props: {
    locations: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      locationsWithIcons: [],
      mapCenter: { lat: 19.320556, lng: -99.4644442 },
      infoWindowPosition: null,
      infoWindowText: '',
      infoWindowOpened: false
    };
  },  
  mounted() {
    this.updateMarkerIcons(this.locations);
  },
  methods: {
    updateMarkerIcons(locations) {  
      this.locationsWithIcons = [];
      if (locations.length === 0) {
        locations.push(this.mapCenter);
      }

      this.infoWindowOpened = false;
      this.$gmapApiPromiseLazy().then((google) => {
        this.locationsWithIcons = locations.map((location) => ({
          ...location,
          icon: location === this.mapCenter ? null : this.getMarkerIcon(location.color, google),
        }));
        this.fitMapToMarkers();
      });
    },
    getMarkerIcon(color, google) {
      let size = !color ? 0 : 32; 
      const svgIcon = {
        url: `data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="${encodeURIComponent(color)}" stroke="black" stroke-width="1" d="M12 2C8.14 2 5 5.14 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.86-3.14-7-7-7zm0 9.75A2.75 2.75 0 1 1 12 6a2.75 2.75 0 0 1 0 5.75z"/></svg>`,
        scaledSize: new google.maps.Size(size, size),
      };
      return svgIcon;
    },
    fitMapToMarkers() {
      if (!this.locationsWithIcons.length) return;

      const bounds = new window.google.maps.LatLngBounds();
      this.locationsWithIcons.forEach((location) => {
        bounds.extend(
          new window.google.maps.LatLng(location.lat, location.lng)
        );
      });

      this.$refs.gmap.$mapObject.fitBounds(bounds);

      const center = bounds.getCenter();
      this.mapCenter = {
        lat: center.lat(),
        lng: center.lng(),
      };
    },
    openInfoWindow(location) {
      this.infoWindowPosition = { lat: location.lat, lng: location.lng };
      this.infoWindowText = `[${location.zone} | ${location.num_customer} - ${location.name}: ${location.address}] FNETA Trim Ant. ${this.previousQuarter()}: $${location.fact_neta}`;
      this.infoWindowOpened = true;
    },
    closeInfoWindow() {
      this.infoWindowOpened = false;
    },
    previousQuarter() {      
      const currentDate = moment();      
      const previousDate = currentDate.subtract(1, 'quarters');      
      const previousQuarter = previousDate.quarter();
      const previousYear = previousDate.year();
      
      return `${previousYear}/${previousQuarter}`;
    }
  },
  watch: {
    locations: {
      deep: true,
      inmediate: true,
      handler(newLocations) {        
        this.updateMarkerIcons(newLocations);
      },
    },
  }
};
</script>  
  