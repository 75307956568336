import store from "@/store";
import {hasAnyRole} from "./index"
import Refunds from "../views/refunds/Refunds";
export default [
    {
        path: "/customer-refunds",
        name: "Refunds",
        component: Refunds,
      beforeEnter: (to, from, next) => {
             if (!store.getters["auth/authenticated"]) { 
              return next({ name: "SignIn" });
            } 
            if (hasAnyRole(store.getters["auth/roles"], ["Guest","Manager","Administrator", 'Submanager', 'IELManager', 'IELSubManager'])) {
             
              return next({
                  name: "403",
                });
              } 
            next();
          } 
      }
]