<template>
  <div>
    <div class="expansion-panel">
      <div class="expansion-panel-header" >
        <div class="d-flex justify-sm-space-between pt-n2 mb-1">
          <div></div> 
          <div class="font-weight-black text-center d-flex d-inline ">
            <div @click="togglePanel()" style="cursor: pointer;">
            <v-icon color="black" size="29" class="font-weight-bold">mdi-{{ icon }}</v-icon>
                {{ title }}  
                <span v-if="buttonReturn && bReturn" @click="resetCustomer">
                    <v-icon color="black" class="icon-return">
                        mdi mdi-arrow-u-left-top
                    </v-icon>
                </span>
            </div> 
            <slot name="actions"></slot>
          </div> 
          <div class="text-left" @click="togglePanel()">
            <v-icon :class="'rotate-icon ' + (isRotated ? '' : 'rotated')">mdi-arrow-down-drop-circle-outline</v-icon>
          </div> 
        </div>      
      </div>
      <div class="expansion-panel-content" ref="panel" style="max-height:1000px">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      isRotated: false,
      buttonReturn: false
    }
  },
  name: 'CardTitle',
  props: {
    title: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    },
    bReturn:{
      type: Boolean,
      default: false
    }
  },
  mounted(){
    if(this.$hasAnyRole(['Customer', 'CustomerPR']) && this.$getLocalStorageCustomer() != null){
      this.buttonReturn = true;
    }
  },
  methods: {
    togglePanel() {
      var panel = this.$refs.panel;
      this.isRotated = !this.isRotated;
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null; 
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px"; 
      }

    },
    resetCustomer(){
      this.$removeLocalStorageCustomer();
      this.$router.go()
    }
  }
}
</script>
<style scoped>
/* Estilos para el panel de expansión */
.expansion-panel {
  border: 1px solid #ddd;
  overflow: hidden;
  box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
  opacity: 1;
}

.expansion-panel-header {
  padding: 10px; 
  user-select: none;
  background: #F2F2F2 0% 0% no-repeat padding-box !important;
  box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029 !important;
  opacity: 1;
}

.expansion-panel-content {
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

/* Estilo para el ícono */
.rotate-icon {
  transform: rotate(0deg);
  /* Inicialmente sin rotación */
  cursor: pointer;
  transition: transform 0.5s ease;
  /* Agregamos una transición para suavizar la animación */
}

/* Estilo cuando el ícono está girado */
.rotated {
  transform: rotate(180deg);
  /* Giramos 180 grados */
}
</style>

<style src="@/assets/css/customerHome.css" scoped></style> 
