<template>
<div id="contenedor" ref="History">
  <v-container fluid>
    <template>
      <v-row>
      <v-col>
      <v-card cols="12" class="pa-xs-2 pa-md-2 pa-lg-2 mt-xs-0 mt-md-0 mt-lg-0 mb-7">
        <v-card-title>HISTORIAL PEDIDO</v-card-title>
        <v-row>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12" md="4">
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
                >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="start_date"
                    label="Fecha Inicial"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    required
                  ></v-text-field>
                </template>
                    <v-date-picker
                    v-model="start_date"
                    @input="closeDateMenu1"
                    ></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" md="4">
              <v-menu
                v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="finish_date"
                            label="Fecha final"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            required
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="finish_date"
                          @input="closeDateMenu2"
                        ></v-date-picker>
                      </v-menu>
            </v-col>
            <v-col cols="12" md="4">
              <v-btn
                  :loading="loading3"
                  :disabled="loading3"
                  depressed
                  color="success"
                  block
                  @click="getPedidoHistory"
                  >
                  Ejecutar &nbsp;<v-icon> mdi-tarrow-right-bold-circle-outline</v-icon>
                  </v-btn>
            </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6">
            <v-row class="mx-1" justify="end">
              <search-input v-model="search" class="search-input-width" />
            </v-row>
          </v-col>
        </v-row>
        <br>
        <v-data-table
          dense
          :sort-by="['fecha']"
          :headers="headers"
          :items-per-page="10"
          :items="data"
          :search="search"
          :sort-desc="[true]"
          item-key="id"
          no-data-text="No hay datos disponibles"
        >
          <template v-slot:[`item.pedido`]="{ item }">
            <a @click="detallePedido(item)" style="color: blue">
              {{ item.pedido }}
            </a>
          </template>

        <template v-slot:[`item.entrega`]="{ item }">
          <a @click="deliveryDetail(item)" style="color: blue">
            {{ item.entrega }}
          </a>
        </template>
          
          <template v-slot:[`item.fecha_pedido`]="{ item }">
            <!--<v-tooltip bottom v-if="item.fecha_pedido != '0000-00-00'" >
              <template v-slot:activator="{ on, attrs }">
                <v-icon  v-bind="attrs" v-on="on">mdi-factory
                </v-icon>
              </template>
              <span>{{item.fecha_pedido}}</span>
            </v-tooltip>-->
              <v-tooltip bottom v-if="item.fecha_picking != '0000-00-00'" >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon  v-bind="attrs" v-on="on">mdi-package-variant-closed-check
                  </v-icon>
                </template>
                <span>{{item.fecha_picking}}</span>
              </v-tooltip>
              <v-tooltip bottom v-if="item.fecha_factura != '0000-00-00'" >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon  v-bind="attrs" v-on="on">mdi-receipt-text
                  </v-icon>
                </template>
                <span>{{item.fecha_factura}}</span>
              </v-tooltip>
              <v-tooltip bottom v-if="item.fecha_actual_inicio_trans != '0000-00-00'" >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon  v-bind="attrs" v-on="on">mdi-truck
                  </v-icon>
                </template>
                <span>{{item.fecha_actual_inicio_trans}} - {{item.fecha_actual_fin_trans}}</span>
              </v-tooltip>
              <v-tooltip bottom v-if="item.fecha_acuse_factura != '0000-00-00'" >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon  v-bind="attrs" v-on="on">mdi-account
                  </v-icon>
                </template>
                <span>{{item.fecha_acuse_factura}}</span>
              </v-tooltip>
          </template>
          
          <template v-slot:[`item.pdf_xml`]="{ item }">
            <a  
               v-if ="item.uuid != '' "
               @click="getPDFXMLInvoice(item.pdf_xml,1)"> 
              <v-icon  
                dense 
                large 
                color="red">
              mdi-file-pdf-box
            </v-icon></a>
            <a href="#" 
               v-if ="item.uuid != ''"
               @click="getPDFXMLInvoice(item.pdf_xml,2)" > 
              <v-icon  
                dense 
                large 
                color="blue">
              mdi-file-xml-box
            </v-icon></a>
            <a href="#" 
               v-if ="item.uuid != ''"
               @click="senDEmail(item)" > 
              <v-icon  
                dense 
                large 
                color="blue">
              mdi-email-arrow-right-outline
            </v-icon></a>
            <p href="#" 
               v-if ="item.uuid == '' && item.pdf_xml != ''"
                > 
              <v-icon  
                dense 
                color="red">
              mdi-alert
            </v-icon><span>  {{item.denrfbsk}}</span></p>
          </template>
          <template v-slot:[`item.pay_complement`]="{ item }">
            <a  
               v-if ="item.pay_complement != ''"
               @click=" getPDFXMLInvoice(item.pay_complement,1)"> 
              <v-icon  
                dense 
                large 
                color="red">
              mdi-file-pdf-box
            </v-icon></a>
            <a href="#" 
               v-if ="item.pay_complement != ''"
               @click="getPDFXMLInvoice(item.pay_complement,2)" > 
              <v-icon  
                dense 
                large 
                color="blue">
              mdi-file-xml-box
            </v-icon></a>
            <a href="#" 
               v-if ="item.pay_complement != ''"
               @click="senDEmailComp(item)" > 
              <v-icon  
                dense 
                large 
                color="blue">
              mdi-email-arrow-right-outline
            </v-icon></a>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
      <br />
      <v-card id="backorders" cols="12" class="pa-xs-2 pa-md-2 pa-lg-2 mt-xs-0 mt-md-0 mt-lg-0 mb-15">
        <v-card-title>HISTORIAL BACKORDER</v-card-title>
        <v-row justify="end" class="mx-1">
          <search-input v-model="searchReminder" class="search-input-width" />
        </v-row>
        <reminders-table
         v-model="selectedRemindersList"
         :items="remindersValidatedFiltered"
         :search="searchReminder"
         :loading="loadingReminders || validatingMaterial"
         :hide-headers="!$hasAnyRole(['Seller']) ? ['customer'] : []"
         height="auto"
         paginated
         @item-selected="validateItemSelected"
         @on-remove-item="setReminderToRemove"
         />
         <div class="d-sm-flex d-block mt-5 mt-md-10 gap-1 align-center">
          <material-status-helper />
           <v-spacer />
           <v-btn
             class="red-button w-xs-100"
             :disabled="validatingMaterial || selectedReminders <= 0"
             @click="goToCart"
             >
            Ir a carrito
          </v-btn>
        </div>
      </v-card>

      <!-- Request For quotation history table -->
      <v-card cols="12" class="pa-xs-2 pa-md-2 pa-lg-2 mt-xs-0 mt-md-0 mt-lg-0 mb-15">

        <v-card-title>COTIZAS BAJO PEDIDO</v-card-title>

        <div class="d-flex justify-space-between mx-4">
          <v-btn color="red" dark :to="{ name: 'QuotationTracking' }">
            Seguimiento Cotz.
            <v-icon>mdi-list-box-outline</v-icon>
          </v-btn>
          <search-input v-model="quotationSearch" class="search-input-width" />
        </div>

        <request-for-quotation-table 
          :items="requestForQuotationHistory"
          :search="quotationSearch"
          @onQuotationClick="getQuotationDetails"
        />
      </v-card>

      <products-order-modal 
        v-model="dialog"
        :products="pedidoInfo"
        :order-number="pedidoSelect"
        :total="pedidoImporte"      
      />
    
       <!-- Dialog -->
       <v-row justify="center">
      <v-dialog
        v-model="dialog_delivery"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar dark color="indigo">
            <v-btn icon dark @click="dialog_delivery = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Pedido:{{ pedidoSelect }}  Entrega: {{ deliverySelect }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <p></p>
          <template>
            <v-simple-table dense>
              <thead>
                <tr>
                  <th class="text-left">CODIGO</th>
                  <th class="text-left">DESCRIPCION</th>
                  <th class="text-left">CANTIDAD</th>
                  <th class="text-left">CENTRO</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in deliveryInfo" :key="item.MATNR">
                  <td class="text-left">{{ parseInt(item.MATNR)  }}</td>
                  <td class="text-left">{{ item.ARKTX }}</td>
                  <td class="text-left">{{ item.LFIMG }}</td>
                  <td class="text-left">{{ item.WERKS }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </template>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- /. Dialog -->
      <!-- Dialog loading -->
      <v-row justify="center">
        <v-dialog v-model="loading" hide-overlay persistent width="300">
          <v-card color="primary" dark>
            <v-card-text>
              Cargando
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
      <v-dialog v-model="dialog_email" width="500" persistent>
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Enviar por Correo
          </v-card-title>
          <v-card-text>
            <p></p>
            <h6 style="text-align: left; font-weight: 700">
              Factura: {{ invoice }}
            </h6>
            <p></p>
            <v-row>
              <v-col md="12">                
                <div class="form-group">
                  <label >Ingresar Email:</label>
                   <v-text-field
                   solo
                   v-model="emai_user"
                    >{{emai_user}}</v-text-field>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="error" text @click="close2()"> Cancelar </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="sendEmailXMLPDF(invoice)">
              Enviar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <delete-order-confirmation-modal
      v-model="showDeleteModal"
      title="Eliminar backorder"
      bar-title="Eliminar BackOrder"
      :key="orderToDelete.orderConcept || ''"
      :order-concept="orderToDelete.orderConcept || ''"
      :deleting="orderToDelete.loading || false"
      :deleted="orderToDelete.deleted || false"
      :error="orderToDelete.error || false"
      @delete-order="deleteReminder"
    />
    </template>
  </v-container>
</div>
</template>
<script>
import axios from "@/plugins/axios";
import {  mapGetters, mapActions, mapMutations  } from "vuex";
import RequestForQuotationTable from '@/components/tables/RequestForQuotationTable.vue';
import SearchInput from '@/components/SearchInput.vue';
import ProductsOrderModal from '@/components/modals/ProductsOrderModal.vue';
import RemindersTable from '@/components/tables/RemindersTable.vue';
import DeleteOrderConfirmationModal from '@/components/modals/DeleteOrderConfirmationModal';
import {
  saveToCartOrderReminderPR,
  saveToCartOrderReminderDT,
} from '@/repositories/Orders';

import moment from 'moment'
export default {
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    ...mapGetters('reminders', [
      'showRemindersModal',
      'remindersValidatedToSelect',
      'loadingReminders',
      'selectedReminders',
      'hasRemindersSelected',
      'firstReminderSelected',
    ]),
    ...mapGetters('selectedUser', ['loadCustomerError', 'noCustomerSelected']),

    selectedRemindersList: {
      set(newValue) {
        this.updateRemindersSelectionList(newValue);
      },

      get() {
        return this.selectedReminders;
      },
    },
    remindersValidatedFiltered (){
       return  this.remindersValidatedToSelect.filter((r) => {
        if (this.customer_code == null)
        return  true;
        return  r.customer.code == this.customer_code
      });
    }
  },
  components: {
    RequestForQuotationTable,
    SearchInput,
    ProductsOrderModal,
    RemindersTable,
    DeleteOrderConfirmationModal,

  },

  data: () => ({
    id_order: null,
    comments: "A1 = Canc. Rec. Por Falt. Inventario",
    dialogSpecilaOrder :false,
    comments_0: null,
    purchase_order: null,
    invoice: null,
    dialog_email:false,
    selected: [],
    orderShop: [],
    order_array: [],
    disabledCount: 0,
    data: [],
    dialog: false,
    dialog_delivery:false,
    search: "",
    menu1: false,
    menu2: false,
    message_order:"",
    emai_user: null,
    itemCSV:[],
    start_date: new Date( Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString().substr(0, 10),
    finish_date: new Date( Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    loading3: false,
    headers: [
      { text: "Fecha", align: "start", value: "fecha" },
      { text: "Consec", align: "start", value: "consec" },
      { text: "Usuario", align: "start", value: "user_create" },
      { text: "Orden de compra", value: "orden" },
      { text: "Pedido", sortable: false, value: "pedido" },
      { text: "Entrega", sortable: false, value: "entrega" },
      { text: "Factura", sortable: false, value: "factura" },
      { text: "Estatus", sortable: false, value: "estatus" },
      { text: "Tracking", sortable: false, value: "fecha_pedido"},
      { text: "PDF/XML", sortable: false, value: "pdf_xml" },
      { text: "Complemento de Pago", sortable: false, value: "pay_complement" },
    ],
    loading: false,
    pedidoSelect: null,
    pedidoInfo: [],
    pedidoImporte: null,
    item: {},
    customer_code:null,
    sellerOrderAviable: true,
    today2: moment().format('YYYY/MM/DD'),
    deliverySelect:null,
    deliveryInfo: null,
    deliveryAmount: null,
    status: false,
    material_val:[],
    requestForQuotationHistory: [],
    quotationSearch: '',
    searchReminder:'',
    validatingMaterial: false,
    showDeleteModal: false,
    orderToDelete: {},
  }),
  watch: {
    data: {
            immediate: true,
            handler() {
                this.generarExcel()
            },
    },
  },
  mounted() {
    if (this.$hasAnyRole(['Customer','CustomerPR'])) {
    this.customer_code =  this.user.custumer_code;
   }else{
    const currentCustomerCode = JSON.parse(localStorage.getItem('currentCustomerCode')) || [];
    if (currentCustomerCode.length<1) {
      this.$router.push({name:'Home'})
    }   
    const currentCustomerInfo= JSON.parse(localStorage.getItem('currentCustomerInfo')) || [];
          if (currentCustomerInfo.length<1) {
          this.$router.push({name:'Home'})
          }    
          this.customerInfo = currentCustomerInfo;
    this.customer_code =  currentCustomerCode;
   } 
    let self = this;
    self.getPedidoHistory();
    self.loadReminders();
    this.getRequestForQuoatationHistory();
    this.emai_user = this.user.email;
    this.generarPDF();
    
    // Slides to a specific id on the view, when comming from backorders blocking message it slides to backorders table
    if(this.$route.params.scrollTo) {  
      document.getElementById(this.$route.params.scrollTo).scrollIntoView();
    }
  },
  methods: {
    ...mapActions({
        sendToPDF: 'printer/sendToPDF',
        sendToExcel: 'printer/sendToExcel',
    }),
    ...mapActions('reminders', [
      'loadReminders',
      'changeRemindersModalState',
      'updateRemindersSelectionList',
      'validateItem',
      'removeReminder',
    ]),
    ...mapActions('selectedUser', ['loadCustomer', 'setSelectedCustomerCode']),

    ...mapMutations('reminders', ['SET_REMINDERS_SELECTED']),
    async validateItemSelected({ item, value }) {
      if (value) {
        this.validatingMaterial = true;
        const error = await this.validateItem(item);

        if (error !== null) this.error = error;

        this.validatingMaterial = false;
      }
    },
    setReminderToRemove(reminder) {
      this.showDeleteModal = true;
      this.orderToDelete = {
        ...reminder,
        loading: false,
        deleted: false,
        error: false,
      };
    },
    async deleteReminder(reasonsForm) {
      const deleteReminderData = {
        reminderData: this.orderToDelete,
        reasonsForm,
      };

      this.orderToDelete.loading = true;
      try {
        await this.removeReminder(deleteReminderData); // Removes reminder from store
        this.orderToDelete.deleted = true;

        setTimeout(() => {
          this.showDeleteModal = false;
        }, 3000);
      } catch (error) {
        this.orderToDelete.error = true;
      }

      this.orderToDelete.loading = false;
    },
    goToCart() {
      if (this.firstReminderSelected.isKit) {
        this.$router.push({
          name: 'CreateKitOrder',
          query: { backorders: true },
        });
      } else {
        if (this.channel === 'PR') {
          this.saveCartPR();
        } else {
          this.saveCartDT();
        }
      }
    },
    async saveCartDT() {
      try {
        const order = await saveToCartOrderReminderDT(
          this.firstReminderSelected.customer.code,
          this.user.id,
          this.firstReminderSelected.orderConcept,
          this.getSelectedReminders()
        );

        await this.loadReminderCustomer();
        this.SET_REMINDERS_SELECTED([]);

        this.$router.push({
          name: 'NewShoppingCartBack',
          params: { doctype: 'PSDT', idb: order.order_id },
        });
      } catch (error) {
        this.error = 'Sucedió un error, no es posible continuar al carrito';
      }
    },
    async saveCartPR() {
      this.creatingOrder = true;

      try {
        const order = await saveToCartOrderReminderPR(
          this.firstReminderSelected.customer.code,
          this.user.id,
          this.firstReminderSelected.orderConcept,
          this.getSelectedReminders()
        );

        await this.loadReminderCustomer();
        this.SET_REMINDERS_SELECTED([]);

        this.$router.push({
          name: 'NewShoppingCartBackPR',
          params: { doctype: 'PSIU', idb: order.order_id },
        });
      } catch (error) {
        this.error = 'Sucedió un error, no es posible continuar al carrito';
      }

      this.creatingOrder = false;
    },
    async loadReminderCustomer() {
      await this.setSelectedCustomerCode(this.firstReminderSelected.customer.code);
      await this.loadCustomer();

      if(this.noCustomerSelected || this.loadCustomerError) throw 'Error al cargar el cliente'
    },
    getSelectedReminders() {
      return this.selectedReminders.map((r) => {
        return {
          cantidad_pedida: r.requiredQty,
          codigo: r.materialId,
          id: r.id,
          medida: r.mesure,
          orden_compra: r.orderConcept,
          nombre: r.customer.name,
        };
      });
    },
    generarPDF(){
            this.sendToPDF({active: true});
    },
    generarExcel(){
      let newItems = []
      this.itemCSV = []

      for (let key in this.data) {                
                let item = this.data[key];
                newItems.push({
                        'Fecha': item.fecha,
                        'Consec': item.consec,
                        'Orden de compra': item.orden,
                        'Pedido': item.pedido,
                        'Entrega':item.entrega,
                        'Factura.': item.factura,
                        'Estatus': item.estatus,
                    });
              }
      this.itemCSV = newItems

      let objectCSV = {
                'name': `Hist. Pedidos-${this.today2}`,
                'items': this.itemCSV,
                'showLabels': true
            }

    this.sendToExcel(objectCSV)
    },

    getPedidoHistory() {
      this.loading3= true;
      let start_date = this.start_date;
      let finish_date = this.finish_date;
      axios
        .post("getPedidoHistory", {
          customer: this.customer_code,
          channel: this.user.VTWEG,
          date_start: start_date,
          date_end: finish_date
        })
        .then((response) => {
          this.data = response.data;
          this.loading3= false;
        })
        .catch((error) => {
          //alert(error);
          console.log(error);
          this.loading3= false;
        })
        .finally(() => (this.loading = false));
    },

    async getRequestForQuoatationHistory () {
      const history = await axios.get(`/request-for-quotation/customer-history/${this.customer_code}`);
      this.requestForQuotationHistory = history.data;
    },

    async getQuotationDetails (quotationId) {
      const quotation =  { pedido: quotationId };
      this.detallePedido(quotation);
    },

    detallePedido(item) {
      this.pedidoSelect = item.pedido;
      this.dialog = true;
      this.loading = true;
      axios
        .post("getInfoPedido", {
          id_pedido: item.pedido,
          //cliente: this.user.custumer_code,
          cliente:this.customer_code
        })
        .then((response) => {
          this.loading = false;
          this.pedidoInfo = response.data.prueba_Pedido;
          this.pedidoImporte = response.data.importe;
          this.pedidoInfo.pop();
        })
        .catch((error) => {
          this.loading = false;
          //alert(error);
          console.log(error);
        })
        .finally(() => (this.loading = false));
    },

    deliveryDetail(item) {
    this.deliverySelect = item.entrega;
    this.pedidoSelect = item.pedido;
    this.dialog_delivery = true;
    this.loading = true;
    axios
      .post("getDeliveryDetail", {
        delivery: item.entrega,
      })
      .then((response) => {
        this.loading = false;
        this.deliveryInfo = response.data.delivery_detail;
        this.deliveryAmount = response.data.importe;
        this.deliveryInfo.pop();
      })
      .catch((error) => {
        this.loading = false;
        //alert(error);
        console.log(error);
      })
      .finally(() => (this.loading = false));
  },
    
    closeDateMenu1() {
      this.menu1 = false;
      this.start_date= new Date(
        this.start_date - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
        console.log(this.start_date);
    },
   closeDateMenu2() {
      this.menu2 = false;
      this.finish_date = new Date(
        this.finish_date - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
    },

    senDEmail(item) {
      console.log(item);
      this.invoice = item.factura;
      //this.id_order = item.id;
      this.dialog_email = true;
    },
    senDEmailComp(item) {
      console.log(item);
      this.invoice = item.pay_complement;
      //this.id_order = itto em.id;
      this.dialog_email = true;
    },
    close2() {
      this.dialog_email = false;
    },
    getPDFXMLInvoice(invoice,type){
      this.dialog_email = false;
      console.log(invoice);
      console.log(this.emai_user);
      axios
        .post("InvoicePDFXML", {
          invoice: invoice,
          type: type,
        },
        )
        .then((response) => {
          this.url = response.data;
          if(type == 1){
            const link = "https://docs.google.com/gview?embedded=true&url="+this.url;
            window.open(link, "_blank");
          }else{
            window.open(this.url, "_blank");
          }
          
        })
        .catch((error) => {
          //alert(error);
          console.log(error);
        })
    },
    sendEmailXMLPDF(invoice){
     console.log(invoice);
     this.dialog_email = false;
      console.log(invoice);
      console.log(this.emai_user);
      axios
        .post("InvoicePDFXMLEmail", {
          invoice: invoice,
          email: this.emai_user,
        })
        .then((response) => {
          this.message = response.data;
           
          this.$swal({
                    title:response.data.message,
                    icon: "success",
                    type: "succes"
                }).then(function () {
                  //this.getRecordatoriosHistory();
                  
                }
                );
        })
        .catch((error) => {
          //alert(error);
          console.log(error);
        })
    },
    
  },
};

</script>

<style scoped>
.search-input-width {
  width: 350px;
}
</style>