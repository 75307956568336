<template>
  <div>
    <v-card class="mt-5"
      style="box-shadow: -1px 1px 7px 0px rgba(224,37,37,0.45);-webkit-box-shadow: -1px 1px 7px 0px rgba(224,37,37,0.45); -moz-box-shadow: -1px 1px 7px 0px rgba(224,37,37,0.45);">

      <v-card-text>
        <div v-if="loading">
          <span class="text-secondary">Cargando información...</span>
          <v-progress-linear :indeterminate="loading" color="teal"></v-progress-linear>
        </div>

        <div v-if="!loading">

          <v-simple-table class=" my-t">
            <template v-slot:default>
              <thead>
                <td colspan="2"> <!--Datos netos --> </td>
                <td colspan="6">
                  <v-card class="mx-auto rounded-lg mt-3 mb-3"
                    :style="'width: 95%; border: 4px solid #000; background-color: transparent; box-shadow: inset 0px 0px 4px 2px rgba(0,0,0,0.1);'">
                    <v-card-title style="
            color: #000;
            font-weight: bold;  
            justify-content: center;
            font-size: 0.9em;
          " class="py-0">
                      PROMEDIO&nbsp;DIARIO&nbsp;DE&nbsp;PEDIDOS (DÍAS&nbsp;HÁBILES)
                    </v-card-title>
                  </v-card>
                </td>
              </thead>
              <thead class="table-heading">
                <tr>
                  <th class="text-center text-dark "><b>Pedidos de <br> {{ months[0].full_name }}</b></th>
                  <th class="text-center text-dark " style="border-width: 0px 2px 0px 0px;">
                    <b>Promedio&nbsp;mens&nbsp;<br> de&nbsp;pedidos <br>
                     {{months[3].short_name  }}&nbsp;/&nbsp;{{months[2].short_name  }} &nbsp;/&nbsp;{{months[1].short_name  }} </b>
                  </th>
                  <th class="text-center text-dark "><b>{{ months[12].date }}&nbsp;-&nbsp;{{ months[1].date }}&nbsp;({{ NHAB12 }})</b></th>
                  <th class="text-center text-dark "><b>{{ subtractMonths(1, 1) }}&nbsp;-&nbsp;{{ subtractMonths(2, 1)
                  }}&nbsp;({{ NHMAMA }})</b></th>
                  <th class="text-center text-dark "><b>Pedidos&nbsp;del <br> día&nbsp;anterior</b></th>
                  <!--  <th class="text-center text-dark "><b> Crec.&nbsp;Histórico 2&nbsp;vs&nbsp;12&nbsp;meses
              Hábiles&nbsp;/&nbsp;Naturales</b></th> Cambi+o muckup despues de enrega de servicios -->
                  <th class="text-center text-dark "><b>Backorder <br> Cancelado <br> Del&nbsp;mes</b></th>
                  <th class="text-center text-dark "><b>Backorder <br> Cancelado Del <br>&nbsp;día&nbsp;ant.</b></th>
                </tr>
              </thead>
              <tbody>
                <tr class="text-center">
                  <td><b>${{ numberformat(averageOrders?.PMONOM) }}</b></td>
                  <td>${{ numberformat(averageOrders?.PMON3M) }}</td>
                  <td>${{ numberformat(parseFloat(averageOrders.PMON12) / NHAB12)}}</td>
                  <td>${{ numberformat(parseFloat(averageOrders.POMAMA) / NHMAMA)}}</td>
                  <td><b>${{ numberformat(averageOrders?.PMONOA) }}</b></td>
                  <!--  <td>{{ numberformat(averageOrders?.CH212H) }}% / {{ numberformat(averageOrders?.CH212N) }}%</td> -->
                  <td><b> ${{ numberformat(averageOrders?.BOCANM) }}</b></td>
                  <td> <b> ${{ numberformat(averageOrders?.BOCADA) }}</b></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-simple-table class="mt-4 my-table">
            <template v-slot:default>
              <thead class="table-heading">
                <tr>
                  <th colspan="3" style="height:0px;"></th>
                  <th colspan="4" style="height:0px;" class="mb-n10 text-center text-dark"> <b class="text-header">
                      Facturación neta </b></th>
                </tr>
                <tr>
                  <th rowspan="2" class="text-center text-dark"><b>%&nbsp;del&nbsp;Cte.&nbsp;Más <br>
                      Grande&nbsp;/&nbsp;%&nbsp;de <br>
                      pagos&nbsp;en&nbsp;Línea</b> </th>
                  <th rowspan="2" class="text-center text-dark"><b>#&nbsp;de&nbsp;Clientes <br>
                      Sistema&nbsp;/&nbsp;Activos <br>
                      Clientes&nbsp;Nuevos <br> 1 &nbsp;/&nbsp;3&nbsp;/&nbsp;6&nbsp;Meses</b> </th>
                  <th rowspan="2" class="text-center text-dark"><b>#&nbsp;de&nbsp;Clientes<br>Con&nbsp;compra <br>
                    {{months[4].short_name  }}&nbsp;/&nbsp;{{months[3].short_name  }}&nbsp;/&nbsp;{{months[2].short_name  }}&nbsp;/&nbsp;{{months[1].short_name  }}&nbsp;/&nbsp;{{months[0].short_name  }} 
                     &nbsp;/&nbsp; </b> </th>
                  <th rowspan="2" class="text-center text-dark"><b>Trim {{ getTrim(4) }}</b></th>
                  <th rowspan="2" class="text-center text-dark"><b>Trim {{ getTrim(3) }}</b></th>
                  <th rowspan="2" class="text-center text-dark"><b>Trim {{ getTrim(2) }}</b></th>
                  <th rowspan="2" class="text-center text-dark"><b>Trim {{ getTrim(1) }}</b></th>
                </tr>
              </thead>
              <tbody>
                <tr class="text-center">
                  <td><b>{{  numberformat(averageOrders?.MAXPCTM,false) }}%</b> / 
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{  numberformat(averageOrders?.MAXPPAM,false) }}%
                      </span>
                    </template>
                    <span class="text-left">
                      Monto pagos línea {{months[1].short_name }}: ${{averageOrders.PIUSALM | formatCurrency}} <br>
                      Monto pagos línea ultimo trimestre: ${{averageOrders.PIUSAL | formatCurrency}} <br>
                      Porcentaje de pagos en línea ultimo trimestre : {{  numberformat(averageOrders?.MAXPPA,false) }}%
                    </span>
                  </v-tooltip>
                  </td>
                  <td> {{ averageOrders.NTCS }} / {{ averageOrders.NTCA }} <br> {{ averageOrders.NTCN1 }} / {{
                    averageOrders.NTCN3 }} / {{ averageOrders.NTCN6 }}</td>
                  <td> 
                    {{ averageOrders?.NTCTE4 }} / {{averageOrders?.NTCTE3 }}/ {{ averageOrders?.NTCTE2 }}/ {{ averageOrders?.NTCTE1 }} /  {{ averageOrders?.NTCTEA }}
                     <br>
                     {{ averageOrders?.NCTEC4 }} / {{averageOrders?.NCTEC3 }} / {{ averageOrders.NCTEC2 }} / {{ averageOrders?.NCTEC1 }} / {{ averageOrders?.NCTECA }}
                  </td>
                  <td><span
                      :class="maxTrim == averageOrders?.FATR03 ? 'text-success' : (minTrim == averageOrders?.FATR03 ? 'text-danger' : '')">${{
                        numberformat(averageOrders?.FATR03) }}</span>
                  </td>
                  <td> <span
                      :class="maxTrim == averageOrders?.FATR02 ? 'text-success' : (minTrim == averageOrders?.FATR02 ? 'text-danger' : '')">${{
                        numberformat(averageOrders?.FATR02) }}</span>
                  </td>
                  <td><span
                      :class="maxTrim == averageOrders?.FATR01 ? 'text-success' : (minTrim == averageOrders?.FATR01 ? 'text-danger' : '')">${{
                        numberformat(averageOrders?.FATR01) }}</span>
                  </td>
                  <td>${{ numberformat(averageOrders?.FATRAC) }}</td>
               
               
              
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>

import axios from "@/plugins/axios"; 
import moment from "moment";
import MixinService from "@/mixins/service"
export default ({
  mixins:[MixinService],
  props:['seller'],
  watch: { 
    seller(){
      this.getData();
    }
  },
  name: 'AverageOrders',
  data() {
    return {
      loading: true,
      averageOrders: [],
      today: new Date(),
      maxTrim: 0,
      minTrim: 0,
      months:[],
    }
  },
  computed: { 
    NHAB12() { 
    var startDate =  moment().startOf('month').subtract(12, 'month').startOf('month'); 
    var finishDate =  moment().subtract(1, 'month').endOf('month');  
    return this.getBusinessDaysFromDates(startDate,finishDate) 
   //   return this.GetBusinessDays(this.subtractMonths(13, 0, 0, 1), this.subtractMonths(2, 0, 0, 1), true)
    },
    NHMAMA() {
      return this.GetBusinessDays(this.subtractMonths(2, 0, 0, 1), this.subtractMonths(1, 0, 0, 1), true)
    },
  },
  methods: {
    getWDays(trim, year, fullMonth = true) {
      var dh;
      let currentMonth = this.today.getMonth();
      let currentYear = this.today.getFullYear();
      let firstDayPrevMonth;
      let lastMonthPrevMonth
      switch (trim) {
        case 1:
          firstDayPrevMonth = new Date(year, 0, 1);
          lastMonthPrevMonth = ((currentMonth >= 0 && currentMonth <= 2) && currentYear == year) ?
            this.today : new Date(year, 2, 1);
          dh = this.GetBusinessDays(firstDayPrevMonth, lastMonthPrevMonth, fullMonth)
          break;
        case 2:
          firstDayPrevMonth = new Date(year, 3, 1);
          lastMonthPrevMonth = ((currentMonth >= 3 && currentMonth <= 5) && currentYear == year) ?
            this.today : new Date(year, 5, 1);
          dh = this.GetBusinessDays(firstDayPrevMonth, lastMonthPrevMonth, fullMonth)
          break;
        case 3:
          firstDayPrevMonth = new Date(year, 6, 1);
          lastMonthPrevMonth = ((currentMonth >= 6 && currentMonth <= 8) && currentYear == year) ?
            this.today : new Date(year, 8, 1)
          dh = this.GetBusinessDays(firstDayPrevMonth, lastMonthPrevMonth, fullMonth)
          break;
        case 4:
          firstDayPrevMonth = new Date(year, 9, 1);
          lastMonthPrevMonth = ((currentMonth >= 9 && currentMonth <= 11) && currentYear == year) ?
            this.today : new Date(year, 11, 1)
          dh = this.GetBusinessDays(firstDayPrevMonth, lastMonthPrevMonth, fullMonth)
          break;
        default:
          break;
      }
      return dh;
    },

    GetBusinessDays(startDate, endDate, fullMonth = false) {

      startDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1)
      if (fullMonth) {
        endDate = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0)
      }
      let currentDate = moment(startDate);
      const endDateMoment = moment(endDate); 
 
      let businessDays = 0;
      // Asegurarse de que la fecha de inicio sea un día hábil (lunes a viernes)
      if (currentDate.day() === 0) {
        currentDate.add(1, 'days'); // Si es domingo, avanza al lunes
      } else if (currentDate.day() === 6) {
        currentDate.add(2, 'days'); // Si es sábado, avanza al lunes
      }
      while (currentDate.isSameOrBefore(endDateMoment)) {
        // Comprueba si el día actual es de lunes a viernes (0 = domingo, 6 = sábado)
        if (currentDate.day() >= 1 && currentDate.day() <= 5) {
          businessDays++;
        }
        // Avanza al siguiente día
        currentDate.add(1, 'days');
      }
      return businessDays;
    },

    getData() {
      axios
        .post("/seller/home/orders", {
          seller_id: this.seller.PERNR,
          channel: this.seller.VTWEG
        },{
          noCancelRequest:true,
            cachable:true,
        })
        .then((response) => {
          if (response.data?.success == false) {
            console.log(response.data)
          } else {
            this.averageOrders = response.data[0];
            let trims = [this.averageOrders.FATR01, this.averageOrders.FATR02, this.averageOrders.FATR03];
            this.minTrim = Math.min.apply(null, trims);
            this.maxTrim = Math.max.apply(null, trims);
          }
        })
        .catch((e) => {
          console.log(e);
        }).finally(() => {
          this.loading = false;
        });
    },
    numberformat(number=0,round=true){ 
      let result = round? Math.trunc(number): Number(number).toFixed(2);
      return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }, 
    getCurrentTrim() {
      let currentMonth = new Date().getMonth()+1;
      return Math.ceil(currentMonth / 3);
    },
    getTrim(row) {
      let currentTrim = this.getCurrentTrim();
      let currentYear = new Date().getFullYear();
      for (let index = 2; index <= row; index++) {
        currentTrim = currentTrim - 1;
        if (currentTrim == 0) {
          currentTrim = 4;
          currentYear = currentYear - 1;
        }
      } 
      return currentTrim + "/" + currentYear + "(" + this.getWDays(currentTrim, currentYear, row == 1 ? false : true) + ")";
    },
    subtractMonths(months = 0, widthYear = 0, shortMonth = 1, withoutFormat = 0) {
      let today = new Date();
      today.setMonth(today.getMonth() - months);
      let year = today.getFullYear().toString();
      let month = today.toLocaleString('default', { month: shortMonth ? 'short' : 'long' });
      let formatedMonth = month.charAt(0).toUpperCase() + month.slice(1);
      if (widthYear) {
        return formatedMonth + '/' + year.slice(2, 4);
      }
      if (withoutFormat) {
        return today;
      }
      return formatedMonth;
    },
  }, 
  created(){
    this.months = this.calcularUltimosMeses(13);
  }
})
</script>

<style scoped> .text-header {
   display: flex;
   margin-top: 0px;
   margin-bottom: -10%;
   justify-content: center;
 }</style>