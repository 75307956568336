<template>
    <div>
        <v-row class="mt-2 mb-2" style="font-size: 14px;">
      <v-col cols="12" sm="3" class="text-left">
        <span>*Última fecha de actualización: {{ getDate }} </span>
      </v-col>
      <v-col cols="12" offset="0" offset-sm="2" sm="7" offset-md="2"  md="7" offset-lg="5" lg="4" class="text-right text-secondary" >
        <span> 
          Información confidencial Prohibida su reproducción o divulgación total o parcial, asi como 
          su uso o aprovechamiento sin autorización escrita de IUSA.
        </span>
      </v-col>
    </v-row>
       </div> 
</template>

<script>
export default({
    name:'LegacyFooter',
    computed:{
        getDate(){
            const today = new Date;
            const year = today.getFullYear();
            const day = today.getDate();
            const month = today.toLocaleString('default', { month:'short'});
            const time = today.toLocaleString('default', { hour: 'numeric', minute: 'numeric', hour12: true })
            const formatedMonth = month.charAt(0).toUpperCase() + month.slice(1);
            return  `${day}/${formatedMonth}/${year} ${time}`
        }
    }

});
</script>