<template>
  <div class="d-flex">
    <v-text-field
      v-model="plusMinusValue"
      name="units"
      type="number"
      dense
      hide-details
      :min="min"
      :max="max"
    />
    <div>
      <v-btn icon x-small @click="onUpdate('onPlus', incrementValue)">
        <v-icon size="13px">mdi-plus</v-icon>
      </v-btn><br>
      <v-btn icon x-small @click="onUpdate('onMinus', -decrementValue)"
        ><v-icon size="13px">mdi-minus</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "PlusMinusInput",

  props: {
    value: {
      type: Number,
      required: true,
    },

    min: {
      type: Number,
      default: 1,
    },

    max: {
      type: Number,
      default: null,
    },

    incrementValue: {
      type: Number,
      default: 1
    },

    decrementValue: {
      type: Number,
      default: 1
    }
  },

  methods: {
    onUpdate(event, value){
      const total = parseInt(this.plusMinusValue) + value;

      if(this.canUpdate(total)){
        this.plusMinusValue = total;
        this.$emit(event);
      }
    },

    canUpdate(newValue) {
      let newVal = parseInt(newValue) || 0;

      if (this.min !== null && newVal < this.min) 
          return false;
      else if (this.max !== null && newVal > this.max)
        return false;
      
      return true;
    }
  },

  computed: {
    plusMinusValue: {
      set(newValue) {
        if(this.canUpdate(newValue))
          this.$emit('input', newValue);
        else
          this.$emit('input', this.min);
      },

      get() {
        return this.value;
      },
    },
  },
};
</script>
<style scoped>
/* Removes + - buttons in numeric inputs */
::v-deep .v-text-field__slot > input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

::v-deep .v-text-field__slot > input::-webkit-outer-spin-button,
::v-deep .v-text-field__slot > input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
