<template>
  <div>
      <v-card elevation="0">
          <v-card-text>
              <div  v-if="paramSelected == 'PSMA' || paramSelected == 'PSPO'">
                  <v-icon  class="mt-20" :color="validateIconPSMA == false || materialVal || statetotal < 2500 || cart.length == 0 ? 'red' : 'green'" size="128" style="align-self: center;">
                  {{ validateIconPSMA == false ||  materialVal || statetotal < 2500 || cart.length == 0 ? 'mdi-close-circle-outline':'mdi-check-circle-outline'}} 
              </v-icon>
              </div>
              <div  v-if="paramSelected == 'PSNW'">
                  <v-icon  class="mt-20" :color="validateIconPSNW == false || materialPop || statetotal < 2500 || cart.length < 15 || cart.length == 0 ? 'red' : 'green'" size="128" style="align-self: center;">
                  {{ validateIconPSNW == false || materialPop || materialPNC || statetotal < 2500 || cart.length < 15 ? 'mdi-close-circle-outline':'mdi-check-circle-outline'}} 
              </v-icon>
              </div>
              <div v-if="paramSelected == 'PSDR'">
                  <v-icon  class="mt-20" :color="validateIconPSDR == false || materialPop || statetotal < 2500 || cart.length < 10 || cart.length == 0 ? 'red' : 'green'" size="128" style="align-self: center;">
                  {{ validateIconPSDR == false || materialPo || statetotal < 2500 || cart.length < 10 || cart.length == 0 ? 'mdi-close-circle-outline':'mdi-check-circle-outline'}} 
              </v-icon>
              </div>
              <div v-if="paramSelected == 'PSTN'">
                  <v-icon  class="mt-20" :color="validateIconPSTN == false || materialPop || statetotal < 2500 || cart.length < 100 || cart.length == 0 ? 'red' : 'green'" size="128" style="align-self: center;">
                  {{ validateIconPSTN == false || materialPop || statetotal < 2500 || cart.length < 100 || cart.length == 0 ? 'mdi-close-circle-outline':'mdi-check-circle-outline'}} 
              </v-icon>
              </div>
              <p class="mt-5 text-dialog">RECUERDA QUE:</p>
              <p>Para generar el pedido
                  <span v-if="paramSelected == 'PSMA'">APOYO DE MARCA</span>
                  <span v-if="paramSelected == 'PSNW'">PRODUCTOS NUEVOS</span>
                  <span v-if="paramSelected == 'PSPO'">ARTÍCULOS PROMOCIONALES</span>
                  <span v-if="paramSelected == 'PSDR'">PRODUCTOS DE REORDEN</span>
                  <span v-if="paramSelected == 'PSTN'">TIENDA NUEVA</span>
                  el cliente debe de tener:
              </p>
              <div v-for="(item, i) in validate_orders" :key="i">
                  <div v-if="paramSelected == 'PSMA'">
                      <div v-for="(item2, j) in item.psma" :key="j">
                          <p>{{item2.name}}</p>
                      </div>
                          <p v-if="validateIconPSMA == false" class="font-weight-bold">AÚN NO COMPLETA:</p>
                          <div v-if="statetotal < 2500">
                            <span class="font-weight-bold">El monto mínimo de compra es $ 2,500.00 MXP</span> 
                          </div>
                          <div v-if="validateIconPSMA == false">
                              <div v-for="(item2, j) in item.psma" :key="j">
                                  <span v-if="customerInfo.PSMA > 0 && item2.id == 1">{{item2.name}}</span>
                              </div>
                          </div>
                          <div v-if="materialVal">
                              <span  class="mt-10 mb-15">Estos Materiales NO Pertenecen a Pedido APOYO A LA MARCA:</span>
                              <!--<span>{{materialPrint}}</span>-->
                              <div v-for="(mat, k) in materialPrint" :key="k">
                                <span>Código: <span class="font-weight-bold">{{ mat.codigo_material }} - {{ mat.nombre_material }}</span></span>
                              </div>
                              <span class="mt-10 text-note">Nota: Se eliminaran al cambiar de Pedido.</span>
                          </div>
                          <div v-if="cart.length == 0">
                            <span class="font-weight-bold">El carrito de materiales no puede ir vacio</span> 
                          </div>
                  </div>
                  <div v-if="paramSelected == 'PSNW'">
                      <div v-for="(item2, j) in item.psnw" :key="j">
                          <p>{{item2.name}}</p>
                      </div>

                      <p v-if="validateIconPSNW == false" class="font-weight-bold">AÚN NO COMPLETA:</p>
                      <div class="font-weight-bold" v-if=" cart.length < 15"> El carrito debe de tener minimo 15 materiales</div>
                      <div v-if="statetotal < 2500">
                            <span class="font-weight-bold">El monto mínimo de compra es $ 2,500.00 MXP</span> 
                      </div>
                      <div  v-if="validateIconPSNW == false">
                          <div v-for="(item2, j) in item.psnw" :key="j">
                            <span v-if="antiguedad < 12 && item2.id == 1">{{item2.name}}</span>
                            <span v-if="customerInfo.PPDP > 36 && item2.id == 2">{{item2.name}}</span>
                            <span v-if="customerInfo.RTRIMA < 20 && item2.id == 3">{{item2.name}}</span>
                            <span v-if="customerInfo.PSNW > 0 && item2.id == 4">{{item2.name}}</span>
                            <span v-if="days_credit > 60 && item2.id == 6">{{item2.name}}</span>
                          </div>
                          <div v-if="materialPop" class="mt-5">
                              <span  class="mt-10 mb-15 font-weight-bold">Estos Materiales NO Pertenecen a Pedido PRODUCTOS NUEVOS:</span>
                              <!--<span>{{materialPrint}}</span>-->
                              <div v-for="(mat, k) in materialPrintPop" :key="k">
                                <span>Código: <span class="font-weight-bold">{{ mat.codigo_material }} - {{ mat.nombre_material }} </span></span>
                              </div>
                              <div v-for="(mat, k) in materialTipoPNC" :key="k">
                                <span>Código: <span class="font-weight-bold">{{ mat.codigo_material }} - {{ mat.nombre_material }} </span></span>
                              </div>
                              <span class="mt-10 text-note">Nota: Se eliminaran al cambiar de Pedido.</span>
                          </div>
                      </div>
                      <div v-if="cart.length == 0">
                         <span class="font-weight-bold">El carrito de materiales no puede ir vacio</span> 
                      </div>
                  </div>
                  <div v-if="paramSelected == 'PSPO'">
                      <div v-for="(item2, j) in item.pspo" :key="j">
                          <p>{{item2.name}}</p>
                      </div>
                      <p v-if="validateIconPSMA == false" class="font-weight-bold">AÚN NO COMPLETA:</p>
                          <div v-if="statetotal < 2500">
                            <span class="font-weight-bold">El monto mínimo de compra es $ 2,500.00 MXP</span> 
                          </div>
                          <div v-if="validateIconPSMA == false">
                              <div v-for="(item2, j) in item.psma" :key="j">
                                  <span v-if="customerInfo.PSMA > 0 && item2.id == 1">{{item2.name}}</span>
                              </div>
                          </div>
                          <div v-if="materialVal">
                              <span  class="mt-10 mb-15">Estos Materiales NO Pertenecen a Pedido ARTÍCULOS PROMOCIONALES:</span>
                              <!--<span>{{materialPrint}}</span>-->
                              <div v-for="(mat, k) in materialPrint" :key="k">
                                <span>Código: <span class="font-weight-bold">{{ mat.codigo_material }} - {{ mat.nombre_material }}</span></span>
                              </div>
                              <!--<span class="mt-10 text-note">Nota: Se eliminaran al cambiar de Pedido.</span>-->
                          </div>
                          <div v-if="cart.length == 0">
                            <span class="font-weight-bold">El carrito de materiales no puede ir vacio</span> 
                          </div>
                  </div>
                  <div v-if="paramSelected == 'PSDR'">
                      <div v-for="(item2, j) in item.psdr" :key="j">
                          <p>{{item2.name}}</p>
                      </div>

                      <div v-if=" cart.length < 10" class="font-weight-bold"> El carrito debe de tener minimo 10 materiales</div>
                      <p v-if="validateIconPSDR == false" class="font-weight-bold">AÚN NO COMPLETA:</p>
                      <div v-if="statetotal < 2500">
                            <span class="font-weight-bold">El monto mínimo de compra es $ 2,500.00 MXP</span> 
                      </div>
                      <div  v-if="validateIconPSDR == false">

                          <div v-for="(item2, j) in item.psdr" :key="j">
                            <span v-if="antiguedad < 6 && item2.id == 1">{{item2.name}}</span>
                            <span v-if="customerInfo.PPDP > 36 && item2.id == 2">{{item2.name}}</span>
                            <span v-if="customerInfo.RTRIMA < 20 && item2.id == 3">{{item2.name}}</span>
                            <span v-if="customerInfo.PSDR > 0 && item2.id == 4">{{item2.name}}</span>
                            <span v-if="days_credit > 60 && item2.id == 6">{{item2.name}}</span>
                            <span v-if="customerInfo.RSR6M  < 10 && item2.id == 7">{{item2.name}}</span>
                          </div>
                      </div>
                      
                      <div v-if="materialPop" class="mt-5">
                              <span  class="mt-10 mb-15 font-weight-bold">Estos Materiales NO Pertenecen a Pedido RODUCTOS DE REORDEN:</span>
                              <!--<span>{{materialPrint}}</span>-->
                              <div v-for="(mat, k) in materialPrintPop" :key="k">
                                <span>Código: <span class="font-weight-bold">{{ mat.codigo_material }} - {{ mat.nombre_material }} </span></span>
                              </div>
                              <!--<span class="mt-10 text-note">Nota: Se eliminaran al cambiar de Pedido.</span>-->
                      </div>
                      <div v-if="cart.length == 0">
                            <span class="font-weight-bold">El carrito de materiales no puede ir vacio</span> 
                      </div>
                  </div>
                  <div v-if="paramSelected == 'PSTN'">
                      <div v-for="(item2, j) in item.pstn" :key="j">
                          <p>{{item2.name}}</p>
                      </div>

                      <div v-if=" cart.length < 100" class="font-weight-bold"> El carrito debe de tener minimo 100 materiales</div>
                      <p v-if="validateIconPSTN == false" class="font-weight-bold">AÚN NO COMPLETA:</p>
                          <div v-if="statetotal < 2500">
                           <span class="font-weight-bold">El monto mínimo de compra es $ 2,500.00 MXP</span> 
                          </div>
                      <div  v-if="validateIconPSTN == false">

                          <div v-for="(item2, j) in item.pstn" :key="j">
                            <span v-if="antiguedad < 6 && item2.id == 1">{{item2.name}}</span>
                            <span v-if="customerInfo.PPDP > 36 && item2.id == 2">{{item2.name}}</span>
                            <span v-if="days_credit > 60 && item2.id == 6">{{item2.name}}</span>
                          </div>
                      </div>
                      
                      <div v-if="materialPop" class="mt-5">
                              <span  class="mt-10 mb-15 font-weight-bold">Estos Materiales NO Pertenecen a Pedido RODUCTOS TIENDA NUEVA:</span>
                              <!--<span>{{materialPrint}}</span>-->
                              <div v-for="(mat, k) in materialPrintPop" :key="k">
                                <span>Código: <span class="font-weight-bold">{{ mat.codigo_material }} - {{ mat.nombre_material }} </span></span>
                              </div>
                              <!--<span class="mt-10 text-note">Nota: Se eliminaran al cambiar de Pedido.</span>-->
                      </div>
                      <div v-if="cart.length == 0">
                            <span class="font-weight-bold">El carrito de materiales no puede ir vacio</span> 
                      </div>
                  </div>
              </div>
          </v-card-text>
          <v-layout justify-center>
          <v-card-actions class="justify-center">
              <div  v-if="paramSelected == 'PSMA' || paramSelected == 'PSPO'">
                  <v-btn :disabled="cart.length == 0 
                          || statetotal < 2500
                          || validateIconPSMA == false
                          || materialVal" class="button-dialog-text text-white font-weight-bold"
                          @click="gotoOrder(order_id,paramSelected,idq)">
                      CONTINUAR
                  </v-btn>
              </div>
              <div  v-if="paramSelected == 'PSNW'">
                  <v-btn :disabled="
                          cart.length == 0 
                          || cart.length < 15
                          || statetotal < 2500
                          || validateIconPSNW == false
                          || materialPop" class="button-dialog-text text-white font-weight-bold"
                           @click="gotoOrder(order_id,paramSelected,idq)">
                      CONTINUAR
                  </v-btn>
              </div>
              <div  v-if="paramSelected == 'PSDR'">
                  <v-btn :disabled="
                          cart.length == 0 
                          || cart.length < 10
                          || statetotal < 2500
                          || validateIconPSDR == false
                          || materialPop" class="button-dialog-text text-white font-weight-bold"
                           @click="gotoOrder(order_id,paramSelected,idq)">
                      CONTINUAR
                  </v-btn>
              </div>  
              <div  v-if="paramSelected == 'PSTN'">
                  <v-btn :disabled="
                          cart.length == 0 
                          || cart.length < 100
                          || statetotal < 2500
                          || validateIconPSTN == false
                          || materialPop" class="button-dialog-text text-white font-weight-bold"
                           @click="gotoOrder(order_id,paramSelected,idq)">
                      CONTINUAR
                  </v-btn>
              </div>               
          </v-card-actions>
      </v-layout>
      </v-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from 'moment';
import axios from "@/plugins/axios";
export default {
name: "SpecialOrderDilaog",
computed: {
  ...mapGetters({
    user: "auth/user",
  }),
},
props: ['paramSelected','customerInfo','cart','statetotal','order_id','idq'],
data: () => ({
  antiguedad:null,
  validate_psma:true,
  validateIconPSMA:true,
  validateIconPSNW:true,
  validateIconPSDR:true,
  validateIconPSTN:true,
  days_credit:null,
  textData: null,
  materialVal:null,
  materialPop:null,
  materialPrint:null,
  materialPrintPop:null,
  materialPNC:null,
  materialTipoPNC: null,
  validate_orders:[
      {
          psma: [
              { id: 1, name: 'Puede hacer un pedido al Trimestre' }],
              //{ id: 2, name: 'Sin saldos / facturas vencidas' }],
          psnw: [
              { id: 1, name: '1 año de Antiguedad' },
              { id: 2, name: 'Promedio Ponderado de 36 días' },
              { id: 3, name: '20 renglones en el último trimestre' },
              { id: 4, name: 'Puede hacer un pedido PNC al mes' },
              // {id:5, name:'Sin saldos / facturas vencidas'},
              { id: 6, name: '60 días de Crédito' }],
          pspo: [
              { id: 1, name: 'Puede hacer un pedido al Trimestre' },
             // { id: 2, name: 'Sin saldos / facturas vencidas' }
            ],
          psdr: [
              {id:1, name: '6 meses de Antiguedad'},
              {id:2, name: 'Promedio Ponderado de 36 días'},
              {id:3, name: '20 renglones en el último trimestre'},
              {id:4, name: 'Puede hacer un pedido PPR al mes'},
              //{id:5, name: 'Sin saldos / facturas vencidas'},
              {id:6, name: '60 días de Crédito'},
              {id:7, name: '10  Productos de Pedido de Reorden (PPR)'}, ],
          pstn:[
              {id:1, name: '6 meses de Antiguedad'},
              {id:2, name: 'Promedio Ponderado de 36 días'},
              {id:3, name: '60 días de Crédito'},
          ]
      }
  ]
}),
async mounted() {

  if (this.customerInfo.PSMA > 0) { 
      this.validateIconPSMA = false;
  }
  
  if (this.antiguedad < 12 || this.customerInfo.PPDP > 36 || this.customerInfo.PSNW > 0 || this.days_credit > 60 || this.customerInfo.RTRIMA < 20) {
      this.validateIconPSNW = false;
  }

 if (this.antiguedad < 6 || this.customerInfo.PPDP > 36 || this.customerInfo.PSDR > 0 || this.days_credit > 60 || this.customerInfo.RTRIMA < 20 || this.customerInfo.RSR6M < 10) {
      this.validateIconPSDR = false;
  }


 if (this.antiguedad < 6 || this.customerInfo.PPDP > 36 || this.days_credit > 60 ) {
      this.validateIconPSTN = false;
  }

},
methods: {
  gotoOrder(order_id,param,idq){

      axios
      .post("changeOrderType", {
        order_id: order_id,
        order_type: param,
      })
      .then((response) => {
          console.log(response);
         this.$router.push({ name: 'NewShoppingCart', params: { doctype: param, idq: idq } }).then(() => {
              this.$router.go(0)
              window.location.reload()
          }).catch((error) => {
              if (error.name != "NavigationDuplicated") {
                  throw error;
              }
          });
      }).catch((error) => {
        //alert(error);
        console.log(error);
        this.errormsg = true;
      }).finally();
  },
},
watch: {
    'customerInfo.ERDAT':{
      immediate: true,
      handler(newDate) {
        const fechaActual = moment();
        this.antiguedad = fechaActual.diff(newDate, 'month')               
      }
    },
    'customerInfo.VTEXT':{
      immediate: true,
      handler(newDays) {
          this.days_credit = parseInt(newDays.match(/\d+/g));

         /* if (newDays.split(" ")[1] == "LOCAL/FORANEO") {
              this.days_credit = parseInt(newDays.split(" ")[2]);
          } else {
              if(newDays.split(" ")[0] == "CONTADO"){
                this.days_credit = 0;
              }else{
                this.days_credit = parseInt(this.customerInfo.VTEXT.split(" ")[1]);
              }
          } */           
      }
    },
    'cart':{
      immediate: true,
      handler(materialAdd) {
          /*Validcacion para tipo de materiales que no se encuantran dentro de los grupos (MVGR4) para tipo de pedido POP Y PSMA */
          this.materialVal = materialAdd.some((item) => item.MVGR4 !== '1ZA' && item.MVGR4 !== '1ZB' && item.MVGR4 !== '1ZC' && item.MVGR4 !=='1YA');
          this.materialPrint = materialAdd.filter((item)=> item.MVGR4 !== '1ZA' && item.MVGR4 !== '1ZB' && item.MVGR4 !== '1ZC' && item.MVGR4 !=='1YA');
          
          /*Validacion para materiales que son tipo pop, tipo de pedido (PSNW, PSDR, PSNT)*/
          this.materialPop = materialAdd.some((item) => item.TIPOP == '1');
          this.materialPrintPop = materialAdd.filter((item)=> item.TIPOP == '1');
          /*Validar si no hay material tipo PNC  de Productos no comprados*/
          this.materialPNC = materialAdd.some((item) => item.PNC != 0);
          this.materialTipoPNC = materialAdd.filter((item)=> item.PNC != 0);

      }

    }
    
  },      

}
</script>
<style scoped> 
.text-dialog {
  align-self: center; 
  font-size: 30px;
  color: black; 
  font-weight: bolder;
}
.button-dialog-text{
  align-self: center !important; 
  border: 3px solid #DC3545;
  border-radius: 7px;
  background: #C8233375 !important;
  color: white;
}
.text-note{
  align-self: center !important; 
  font-size: 12px;
}
</style>