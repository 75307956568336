<template>
  <v-select
    :value="destiny"
    :items="customerDestinyList"
    :item-text="getItemTextDestinations"
    return-object
    dense
    outlined
    label="Destinatario de mercancía"
    @change="setValue($event)"
  >
  </v-select>
</template>
<script>
import axios from "@/plugins/axios";
import { mapGetters } from "vuex";
export default {
  name: "CustomerAddress",
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  data: () => ({
    customerDestinyList: [],
    destiny: "",
    customer_code:null,
  }),
  async mounted() {
    if (this.$hasAnyRole(['Customer','CustomerPR'])) {
  this.customer_code =  this.user.custumer_code;
 }else{
  const currentCustomerInfo= JSON.parse(localStorage.getItem('currentCustomerInfo')) || [];
        if (currentCustomerInfo.length<1) {
        this.$router.push({name:'Home'})
        } 
  this.customer_code =  parseInt(currentCustomerInfo?.KUNNR,10) 
 }
    let self = this;
    self.getDestinyCustomer();
  },
  methods: {
    getDestinyCustomer() {
      axios
        .post("destinyCustomer", {
          customer: this.customer_code,
          VKORG: this.user.VKORG,
          VTWEG: this.user.VTWEG,
          VKBUR: this.user.VKBUR,
        })
        .then((response) => {
          /*if(typeof(response.data) == 'object') {
            this.customerDestinyList.push(response.data);
          } else {
            this.customerDestinyList = response.data;
          }*/
          this.customerDestinyList = response.data;
            this.destiny = this.customerDestinyList;         
             //this.destiny = this.customerDestinyList;
          //console.log(this.destiny);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => (this.dialogLoading = false));
    },
    getItemTextDestinations(item) {
      return `${item.KUNNR} ${item.STRAS} ${item.ORT01}  ${item.PSTLZ} `;
    },
    setValue(value) {
        console.log(value);
        this.$emit("input", value);
    },
  },
};
</script>
<style>

</style>
