import Vue from "vue"
import VCalendar from "v-calendar";
import VDatePicker from "v-calendar";
import * as VueGoogleMaps from 'vue2-google-maps'
import Toast from "vue-toastification";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import moment from "moment"
import VueMoment from "vue-moment";
import VueMask from 'v-mask'
import "./filters";
// import Echo from 'laravel-echo';
// import Pusher from 'pusher-js';

require('moment/locale/es')
moment.locale('es')

Vue.use(VueMoment, {moment})
Vue.use(VueMask)

import "../src/assets/css/styles.css";
import "vue-toastification/dist/index.css";
import VueSweetalert2 from 'vue-sweetalert2';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';
import global from "./mixins/global";
Vue.use(VueSweetalert2);
require("@/store/subscriber");

Vue.prototype.$env = process.env.VUE_APP_ENV;
Vue.prototype.$baseUrl = process.env.VUE_APP_BASE_SERVER;
Vue.prototype.$baseApiUrl = process.env.VUE_APP_API_SERVER;
Vue.prototype.$filesUrl = process.env.VUE_APP_FILES_URL;
Vue.prototype.$googleSitekey = process.env.VUE_APP_GOOGLE_SITEKEY;
Vue.prototype.$googleMapsKey = process.env.VUE_APP_GOOGLE_MAPSKEY;
Vue.prototype.$pusherAppKey = process.env.VUE_APP_PUSHER_APP_KEY;

Vue.config.productionTip = process.env.VUE_APP_PRODUCTION;

Vue.use(Toast);
Vue.use(VDatePicker);
Vue.use(VCalendar);
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPSKEY,
    libraries: 'places'
  }
});

Vue.mixin(global);

// window.Pusher = Pusher;

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: '9dcd11d02ec57b758256',
//     wssHost: '172.16.19.73',
//     cluster:'us2',
//     wssPort: 443,
//     forceTLS: false,
// });

store.dispatch("auth/attempt", localStorage.getItem("token")).then(() => {
  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
});

export const eventBus = new Vue();
