<template>
    <v-card-text class="d-flex flex-column justify-center" style="height: 100%;">
      <h5 class="text-center"><b>Actualización IEL</b></h5>
      <br />
      <div v-html="notificationContent"></div>
      <v-card-actions class="d-flex justify-center">
        <v-checkbox v-model="localDontShowAgain" label="No volver a mostrar" class="text-center"></v-checkbox>
      </v-card-actions>
    </v-card-text>
  </template>
  
  <script>
  export default {
    name: 'SystemUpdatesList',
    props: {
      notificationContent: {
        type: String,
        required: true
      },
      dontShowAgain: {
        type: Boolean,
        default: false 
      },
    },
    computed: {
      localDontShowAgain: {
        get() {
          return this.dontShowAgain;
        },
        set(value) {
          this.$store.commit('userNotification/SET_DONT_SHOW_AGAIN', value);
        }
      }
    },
    mounted() {
      console.log(this.notificationContent);
    }
  }
  </script>
  
  <style scoped>
  .notification-content :deep(p) {
    margin-bottom: 0.5em;
  }
  
  .notification-content :deep(.is-align-left) {
    text-align: left !important;
  }
  
  .notification-content :deep(.is-align-center) {
    text-align: center !important;
  }
  
  .notification-content :deep(.is-align-right) {
    text-align: right !important;
  }
  
  h5 {
    color: #000000;
  }
  
  h6 {
    color: #00000099; 
  }
  </style>
  