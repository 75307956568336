<template>
    <div>
        <v-card elevation="0" flat> 

              <v-card-text class="d-flex flex-wrap pt-0 pr-1 pb-0 ">  
                <div class="d-flex flex-wrap ma-0 w-100" style="box-shadow: inset 0px 0px 8px #FE080829, 0px 0px 6px #F8090929;"> 
            <div class="pa-2 pl-10 d-flex flex-wrap">
              <div class="mt-2 mr-1 "> Agrupar por :</div>
              <div class="mt-2">
                <custom-gray-select :disabled="loading" @input="changeGroupBy"  v-model="group" :value="groupBy" :options="groupByOptions" />
              </div>
              
            </div>
            <div class="pa-2 pl-10 d-flex flex-wrap">
              <div class="mt-2 mr-1 ">Periodo:</div> 
              <div class="mt-2">
                <custom-gray-select :disabled="loading" @input="changePeriod" v-model="periodDate"  :options="optionPeriods" />
              </div> 
            </div>
          </div>
                <v-simple-table class="mt-0 my-table" dense>
                <template v-slot:default>
                  <thead class="table-heading">
                    <tr>
                      <th  rowspan="2"  class="text-center text-dark custom-border-right">
                        <b>{{headerTitle}}</b>
                        <span class="d-flex mt mb-n4 justify-center">
                            <v-btn icon color="disabled">
                              <v-icon>mdi-menu-down</v-icon>
                              <!-- <v-icon>mdi-menu-up</v-icon> -->
                            </v-btn>
                          </span>
                      </th>
                      <th rowspan="2" class="text-center text-dark custom-border-right">
                        <b>#&nbsp;de&nbsp;Clientes<br />Sist&nbsp;/&nbsp;Activos <br> 
                          Clientes nuevos<br />1/3/6 meses
                        </b>
                      </th>
                      <th rowspan="2" class="text-center text-dark custom-border-right">
                        <div class="d-flex justify-center">
                            <b>#&nbsp;de&nbsp;Clientes&nbsp;con&nbsp;compra<br />
                              Ctes&nbsp;con&nbsp;compras&nbsp;sem<br />
                          </b>
                        </div> 
                        <table class="w-100 custom-table-item-sm">
                          <tr>
                            <td> {{ getMonths }} </td>
                            <td > <b>Prom 3</b></td>   
                            <td>Dif</td>
                            <td>{{months[2]?.short_name}}</td>
                          </tr>
                        </table>
                      </th>
                      <th rowspan="2" class="text-center text-dark custom-border-right"> <b>%&nbsp;de&nbsp;clientes<br />con&nbsp;pedidos</b></th> 
                      <th rowspan="2" class="text-center text-dark custom-border-right"><b>Pedidos&nbsp;de <br>{{ months[0].full_name }}</b></th>
                      <th rowspan="2" class="text-center text-dark custom-border-right"><b>Pedidos&nbsp;del&nbsp; <br>dia anterior</b></th>
                      <th colspan="3" class="text-center text-dark custom-border-right">
                        <div class="d-flex d-inline-block justify-center">
                          <div class="mt-2"> Crecimientos por: </div>
                          <v-checkbox v-model="isBillingGrowth" dense :disabled="loading">
                          <template v-slot:label>
                            <div> <b class=" text-black small">Facturación</b></div>
                          </template>
                        </v-checkbox>
                        <v-checkbox  v-model="isOrderGrowth" dense :disabled="loading">
                          <template v-slot:label> 
                            <div> <b class=" text-black small">Pedidos</b> </div>
                          </template>   
                        </v-checkbox>
                        </div> 
                      </th>
                    </tr> 
                    <tr>
                      <th class="text-center text-dark"><b>Acum.<br />{{ months[0].date }}</b></th>
                      <th class="text-center text-dark"><b>Últ. 3 Meses</b></th>
                      <th class="text-center text-dark"><b>Últ. 3 Meses vs 3 <br>anteriores</b></th> 
                    </tr> 
                  </thead>
                  <tbody class="custom-table-bordered"  v-if="!loading">
                    <tr class="text-body-2" v-for="(manager,index) in managerList" :key="index">
                      <td>
                        <div class="d-flex d-inline-block">
                          <div>
                            <v-img class=" align-self-center" width="55px" src="/img/icono_maps.svg"></v-img>
                          </div>
                          
                          <div class="align-self-center mr-2 ml-2 text-decoration-underline" @click="gotoManager(manager.CODGER)">
                           <a> {{manager?.NAME1}} ({{manager?.BZIRK}})</a>
                          </div>   
                        </div> 
                      </td>
                      <td><b class="small">{{manager?.CTETOT}}/ {{manager?.CTESIS}} <br> {{manager?.CTENU1}}/{{manager?.CTENU3}}/{{manager?.CTENU6}} </b></td>
                      <td>
                        <table class="text-center w-100 custom-table-item-sm">
                          <tr  v-for="(item,index) in manager.CUSTOMERS" :key="index">
                            <td> 
                              <template v-if="index > 1">
                                  <a @click="actionMonth(index,0)">
                                    <span  class="text-decoration-underline">  {{number_format(item.CURRENT)}}</span>
                                  </a>&nbsp;/&nbsp;
                                  <a @click="actionMonth(index,1)">
                                    <span  class="text-decoration-underline">{{number_format(item.MONTH1)}}</span>
                                  </a> 
                                </template>
                                <template v-else> 
                                  <span  class=""> {{number_format(item.CURRENT)}}</span>
                                   &nbsp;/&nbsp; 
                                  <span  class="">{{number_format(item.MONTH1)}}</span> 
                                </template>
                            </td> 
                            <td >
                              <!-- Average 3/12--> 
                               <template v-if="index != 1">
                                  <a @click="actionAverage(index)"  class="text-decoration-underline">{{  number_format(item.AVERAGE3) }}</a>
                                </template>
                                <template v-else>
                                   {{  number_format(item.AVERAGE3) }} 
                                </template>
                              </td>
                            <td>
                              <span>{{ number_format(item.MONTH2 - item.AVERAGE3)}}</span></td>
                            <td>
                              <a @click="actionMonth(index,2)">
                                  <span :class="index > 1 ? 'text-decoration-underline':''">{{number_format(item.MONTH2)}}</span>
                                  </a>
                            </td>
                          </tr> 
                        </table>
                      </td>
                      <td>{{number_format(manager.PCTEPED,false)}}%</td>
                      <td > {{number_format(manager.PEDMES,1)}}</td>
                      <td>${{number_format(manager.PEDDANT,1)}}</td>
                      <template v-if="isBillingGrowth">
                      <td  style="border-width: 0px;"> <a @click="showCumulativeGrowthModal('',manager.CODGER)" class="text-decoration-underline text-black" >{{number_format(manager.CREACUFAC,false)}}%</a></td>
                      <td  style="border-width: 0px;"> <a @click="showCumulativeGrowthModal('_3M',manager.CODGER)" class="text-decoration-underline text-black">{{number_format(manager.CREACU3FAC,false)}}%</a></td>
                      <td  style="border-width: 0px;"> <a @click="showCumulativeGrowthModal('_1X3',manager.CODGER)" class="text-decoration-underline text-black font-weight-bold" >{{number_format(manager.CREACU1X3FAC,false)}}%</a></td>
                    </template> 
                    <template v-else>
                      <td  style="border-width: 0px;"> <a @click="showCumulativeGrowthModal('',manager.CODGER)" class="text-decoration-underline text-black" >{{number_format(manager.CREACU,false)}}%</a></td>
                      <td  style="border-width: 0px;"> <a @click="showCumulativeGrowthModal('_3M',manager.CODGER)" class="text-decoration-underline text-black" >{{number_format(manager.CREACU3,false)}}%</a></td>
                      <td  style="border-width: 0px;"> <a @click="showCumulativeGrowthModal('_1X3',manager.CODGER)" class="text-decoration-underline text-black font-weight-bold" >{{number_format(manager.CREACU1X3,false)}}%</a></td>
                    </template> 
                    </tr> 
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="9">
                        <v-progress-linear value="15" indeterminate />
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>    
              </v-card-text> 
          </v-card>

          <cumulative-growth 
            title="Detalle de crecimiento constante de Gerencia" 
            :isBilling = "isBillingGrowth"
            v-model = "commulativeGrowth" 
            :type ="growthType"
            :option="2"
            :period = "period"
            :employeNumber="managerSelected"
            :employeeChannel = "chnl" 
         /> 

         <payment-i-e-l-detail 
         :managerCode="managerSelected"
         :employeeChannel = "chnl" 
         v-model="showPaymentsViaIEL"
         :period = "period" 
         />
           

<!--
          <!- Reimplementación ->
          <v-dialog v-model="showModal" width="auto" :persistent="showPopulationInput" >
        <v-card class="pb-4">
          <div v-if="showPopulationInput" class="mb-3">
            <v-card-text>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field v-model="populationInput" :counter="10" :rules="populationRules" required ></v-text-field>
                <v-btn :disabled="!valid" color="success" class="mr-4" @click="validate" >
                  Guardar
                </v-btn>
                <v-btn color="warning" @click="resetValidation">
                  Cancelar
                </v-btn>
              </v-form>
            </v-card-text>
          </div>   
          <payment-info  v-if="paymentInfo" v-on:closeModal="closeModal" />
        </v-card>
          </v-dialog> 
        -->
    </div>
</template>
<script>  
import axios from '@/plugins/axios.js';
import MixinService from '@/mixins/service'
import MixinHelpers from '@/mixins/helpers';
import MixinDates from '@/mixins/dates';
import CustomGraySelect from "@/components/CustomGraySelect.vue";
import CumulativeGrowth from '@/components/modals/CumulativeGrowth.vue'
import PaymentIELDetail from '@/components/modals/manager/PaymentIELDetail.vue'; 
import { mapGetters } from "vuex";

export default({
    name:'ManagerList',
    mixins:[MixinService,MixinHelpers,MixinDates],
    props: {
      groupByOptions:{
        required:true
      },
      chnl: { 
        required:true,
        String
      },
      regionalManager:{
        required:true,
      }
    },
    components:{  
        CustomGraySelect,
        CumulativeGrowth,
        PaymentIELDetail
    },  
    data: () => ({
        valid: true,
        quarter3:false,
        showModal: false,
        checkbox: false, 
        showPopulationInput: false,
        populationInput: "", 
        paymentHistory: false,
        customerSales: false,
        customersBuyWeekly: false,
        paymentInfo: false, 
        populationRules: [
        (v) => !!v || "Este campo es requerido",
        (v) =>
            (v && v.length <= 10) || "Este campo debe ser menor a 10 caracteres",
        ],
        loading:true,
        managerList:[],
        isOrderGrowth:false,
        isBillingGrowth:true,
        months:[],
        groupBy:"",
        period:[], 
        periodDate:[],
        group:'',  
        growthType:'',
        commulativeGrowth:false, 
        managerSelected:0,
        showPaymentsViaIEL:false,
        isRegional:false,
        headerTitle:'Sub Directores'
  }),
    methods:{ 
      actionMonth(index,col){
        switch (index) {
          case 2: this.showcustomersBuyWeekly(col); break;
          case 3: 
            this.period = this.periodFormat(this.months[col]); 
            this.showPaymentsViaIEL=true; 
          break; 
        }
      },
    showInfo(type) {
      this.showModal = true;
      switch (type) { 
        case 2: 
          this.paymentInfo = true;  
          break; 
      }
    },
    showCumulativeGrowthModal(type='',manager)
      { 
          this.managerSelected=manager;
          this.growthType=type;
          this.commulativeGrowth = true; 
      },
    closeModal() {
      this.showModal = false;
    },
    validate() {
      this.$refs.form.validate();
      this.showPopulationInput = false;
      this.showModal = false;
    },
    resetValidation() {
      this.$refs.form.resetValidation();
      this.$refs.form.reset();
      this.showPopulationInput = false;
      this.showModal = false;
    },
    editPopulation(item) {
      this.customersBuyWeekly = false;
      this.paymentHistory = false;
      this.customerSales = false;
      this.paymentInfo = false;
      this.showPopulationInput = true;
      this.populationInput = item;
      this.showModal = true;
    },
    async loadManagers(isManager = false)
    { 
      this.loading=true;
      var params = { 
        'channel': this.chnl,
        'period':this.period,
      }
      if (isManager){
        params.rgManager = 'G';
      }else{
        if (this.regionalManager) params.rgManager = this.regionalManager;
      } 
        try { 
          const response = await axios.get("/daily-director/managers", {
                params: params
              });
          this.managerList = response.data;
        } catch (error) {
          console.error(error)
            this.loading=false;
        } 
        this.loading=false;
    },
    changeGroupBy(){
    const data = {
            groupBy:this.group,
            period:this.removeTimeFromDate(this.periodDate,'MMM YYYY','YYYYMM')
        }
        //En caso de listar gerentes o subgerentes, se debe enviar como id de gerente, letra G
        switch (this.group ) {
          case 'Gerentes':
          case 'SubGerentes':
            this.headerTitle = this.chnl == 'PR' ? 'SubGerentes':'Gerentes'
            this.loadManagers(true);
          break;
          case 'Gerencia Regional':
            this.loadManagers(false);
          break; 
        }
        this.$emit('groupByChange',data)    
    },
    changePeriod(){
      this.period = this.removeTimeFromDate(this.periodDate,'MMM YYYY','YYYYMM');
      this.changeGroupBy();
    },
    sendManagerHome(code) {
      this.$setLocalStorageManager(code);
      this.$router.push({ name: "ManagerHome" });
    },
    sendRegionalManagerHome(code) {
      this.$setLocalStorageRegional(code);
      this.$router.push({ name: "DailyRegionalManager" });
    },
    sendSubDirectorHome(code) {
      this.$setLocalStorageRegional(code);
      this.$router.push({ name: "DailySubDirector" });
    },
    functionSetHeaders(){ 
      const role = this.getnavRole();
      switch (role) {
        case "director": 
        case "subDirector":   
        this.headerTitle = 'Gerentes regionales' 
        this.group = 'Gerencia Regional';
        break;  
        case "regionalManager":    
        this.headerTitle = this.chnl == 'PR' ? 'Sub Gerentes':'Gerentes'
        this.group =  this.chnl == 'PR' ? 'SubGerentes' : 'Gerentes';
        break; 
      }
    },
    getnavRole(){
      switch (this.$route.meta.role) {
        case "Director":   
        case "IELDirector":   
          return "director";
        case "IELCommercialPR":   
        case "IELCommercialDT":   
        case "Subdirector":
          return "subDirector"  
        case "Regional": 
        case "RegionalManager":  
        case "IELRegManager": 
          return "regionalManager"; 
      } 
    },
    gotoManager(managerCode){ 
      const role = this.getnavRole();
      switch (role) {
        case "director": 
        case "subDirector":  
          if (this.group == 'SubGerentes' || this.group == 'Gerentes'){
            this.sendManagerHome(managerCode)
          }else{
            this.sendRegionalManagerHome(managerCode)
          }
        break;
        case "regionalManager":   
        this.sendManagerHome(managerCode);  
        break; 
      } 

     /*
      ! this.isRegional ? 
        this.sendRegionalManagerHome(managerCode) : 
        this.sendManagerHome(managerCode);
        */
      },
    },
    mounted(){
      this.periodDate = this.months[0].date;
      this.period = this.removeTimeFromDate(this.periodDate,'MMM YYYY','YYYYMM');
      this.functionSetHeaders();
      this.loadManagers();
    },
    computed:{ 
      ...mapGetters({ 
             user: "auth/user",
         }),
      optionPeriods(){ 
        return this.arrayPluck(this.months,'date');
      },
      getMonths(){ 
        var months = this.months;   
        return this.implode(this.arrayPluck(months.slice(0, 2),'short_name'),'/') 
      }, 
    },
    created(){
      this.months = this.calcularUltimosMeses(13);
    }, 
    watch:{
    
    isOrderGrowth(value){ 
      if (value) {
        this.isBillingGrowth=false;
      }else{
        this.isBillingGrowth=true;
      }
    }, 
    isBillingGrowth(value){ 
      if (value) {
        this.isOrderGrowth=false;
      }else{
        this.isOrderGrowth=true;
      }
    }
  },
})
</script>