<template>
  <div class="bg-white"> 
    <v-container fluid class="page__container--padding">
        <div class="cont-shadow bloq-height">
            <div class="d-flex align-items-center">
                <SearchCustomerSeller></SearchCustomerSeller>

                <div class="d-flex align-items-center ml-5">                           
                    <label class="filter-text">Agrupar por:</label>
                    <v-select
                        :items="optionGroups"
                        item-text="name"
                        item-value="id"
                        v-model="groupBy"
                        hide-details                                 
                        small
                        dense
                        solo 
                        class="ml-2" 
                        :disabled="isLoading" 
                        return-object                        
                    ></v-select>
                </div>
            </div>
        </div>

        <title-header-table class="mt-5">
            <span>DATOS GENERALES DE GRUPO</span>
        </title-header-table>
        <v-simple-table class="elevation-2 table__desing-general" > 
            <template v-slot:default>
                <thead style="background: rgb(245, 245, 245)">
                    <tr>
                        <th class="text-center">Grupo por canal</th>
                        <th class="text-center">N° de {{ groupBy.name }}</th>
                        <th class="text-center">Pedidos totales del mes</th>
                        <th class="text-center">Crec. acum. del grupo</th>
                        <th class="text-center">Crec. 3 vs 3 del grupo</th>
                        <th class="text-center" v-for="header, index in headerGeneral" :key="index">
                            {{header.text}}
                        </th>                
                    </tr>
                </thead>
                <tbody>                   
                    <template v-if="!isLoading">
                        <tr>
                            <td><span class="ml-auto">Minorista</span></td>  
                            <td><span class="ml-auto">{{items.length}}</span></td>  
                            <td><span class="ml-auto">${{averageGeneral.PEDACT}}</span></td>  
                            <td>
                                <span class="ml-auto" :class="validateNegativeOrPositive(averageGeneral.CRECACU)">
                                    {{averageGeneral.CRECACU}}%
                                </span>
                            </td>  
                            <td>
                                <span class="ml-auto" :class="validateNegativeOrPositive(averageGeneral.CREC3X3)">
                                    {{averageGeneral.CREC3X3}}%
                                </span>
                            </td>  
                            <td><span class="ml-auto">${{averageGeneral.FACNET6}}</span></td>                              
                            <td><span class="ml-auto">${{averageGeneral.FACNET5}}</span></td>                              
                            <td><span class="ml-auto">${{averageGeneral.FACNET4}}</span></td>                              
                            <td><span class="ml-auto">${{averageGeneral.FACNET3}}</span></td>                              
                            <td><span class="ml-auto">${{averageGeneral.FACNET2}}</span></td>                              
                            <td><span class="ml-auto">${{averageGeneral.FACNET1}}</span></td>                              
                            <td><span class="ml-auto">${{averageGeneral.FACNET}}</span></td>                              
                        </tr>
                    </template>                    
                </tbody>
            </template>
        </v-simple-table>

        <title-header-table class="mt-5">
            <span>{{this.groupBy.title}}</span>
        </title-header-table>
        <v-data-table 
            :headers="header"
            :items="itemsDataTable"
            :footer-props="footerProps"
            :options="dataTableOptions"     
            :mobile-breakpoint="null"   
            :loading="isLoading"        
            class="elevation-2 table__desing-general table__alternating-rows--grey">

            <template v-slot:header>
                <thead style="border-bottom: inherit">
                    <tr>
                        <th colspan="5"></th>
                        <th colspan="6" class="text-center">Facturción neta por trimestre (Miles)</th>
                        <th class="text-center">Proyección</th>
                    </tr>
                </thead>
            </template>

            <template v-slot:[`item.NAME`]="{ item }">
                <div class="d-flex justify-content-start align-items-center">
                    <v-tooltip bottom v-if="groupBy.id === 3">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon @click="openModalDivision(item.ID, 3)" v-bind="attrs"
                            v-on="on" color="#B71C1C">mdi-chart-pie</v-icon> 
                        </template>
                        <span>Participación / Divisiones</span>
                    </v-tooltip>
                    <span class="ml-1 table__link" @click="sendToHome(groupBy.toHome, item.ID)">{{ item.NAME }}</span>
                </div>                
            </template>

            <template v-slot:[`item.PEDACT`]="{ item }">
                ${{ item.PEDACT | removeDecimals }}
            </template>

            <template v-slot:[`item.CRECACU`]="{ item }">
                <span :class="validateNegativeOrPositive(item.CRECACU)">
                    {{ item.CRECACU | removeDecimals }}%
                </span>
            </template>
            
            <template v-slot:[`item.CREC3X3`]="{ item }">
                <span :class="validateNegativeOrPositive(item.CREC3X3)">
                    {{ item.CREC3X3 | removeDecimals }}%
                </span>
            </template>
            
            <template v-slot:[`item.FACNET6`]="{ item }">
                <span :class="validateNumberMaxMin(item, item.FACNET6)">
                    ${{ item.FACNET6 | removeDecimals }}
                </span>
            </template>

            <template v-slot:[`item.FACNET5`]="{ item }">
                <span :class="validateNumberMaxMin(item, item.FACNET5)">
                    ${{ item.FACNET5 | removeDecimals }}
                </span>
            </template>

            <template v-slot:[`item.FACNET4`]="{ item }">
                <span :class="validateNumberMaxMin(item, item.FACNET4)">
                    ${{ item.FACNET4 | removeDecimals }}
                </span>
            </template>

            <template v-slot:[`item.FACNET3`]="{ item }">
                <span :class="validateNumberMaxMin(item, item.FACNET3)">
                    ${{ item.FACNET3 | removeDecimals }}
                </span>
            </template>

            <template v-slot:[`item.FACNET2`]="{ item }">
                <span :class="validateNumberMaxMin(item, item.FACNET2)">
                    ${{ item.FACNET2 | removeDecimals }}
                </span>
            </template>
            
            <template v-slot:[`item.FACNET1`]="{ item }">
                <span :class="validateNumberMaxMin(item, item.FACNET1)">
                    ${{ item.FACNET1 | removeDecimals }}
                </span>
            </template>
            
            <template v-slot:[`item.FACNET`]="{ item }">
                <span :class="validateNumberMaxMin(item, item.FACNET)">
                    ${{ item.FACNET | removeDecimals }}
                </span>
            </template>
        </v-data-table>

        <LegacyFooter></LegacyFooter>
    </v-container>    
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from "vuex";
import axios from "@/plugins/axios";
import TitleHeaderTable from '@/components/tables/TitleHeaderTable';
import Service from "@/mixins/service.js";
import SendHomeByRole from "@/mixins/sendHomeByRole.js";
import LegacyFooter from "@/views/seller/components/LegacyFooter.vue"
import SearchCustomerSeller from '@/components/SearchCustomerSeller.vue';
import moment from "moment";
export default {
    components: { 
        TitleHeaderTable,
        SearchCustomerSeller,
        LegacyFooter
    },
    mixins: [Service, SendHomeByRole],
    data() {
        return {  
            header: [],   
            headerDefaul: [
                { text: 'Gerente Regional', value: 'NAME', align:"center" },
                { text: 'Pedidos Totales de mes', value: 'PEDACT', align:"center" },
                { text: 'Grupos', value: 'GRUPOS', align:"center" },
                { text: 'Crec. acum. del grupo', value: 'CRECACU', align:"center" },
                { text: 'Crec. 3 vs 3 del grupo', value: 'CREC3X3', align:"center" },
            ],  
            headerGeneral: [],
            items: [],
            isLoading: false,
            optionGroups: [
                { name: 'Gte. Regionales', id: 1, option: 'D', title: 'REGIONALES', toHome: 'RegionalManager' },
                { name: 'Gerentes', id: 2, option: 'R', title: 'GERENCIAS', toHome: 'Manager' },
                { name: 'Grupos de clientes', id: 3, option: 'G', title: 'GERENCIA DETALLE DE GRUPO DE CLIENTES', toHome: 'Seller' }
            ],
            groupBy:{},
            footerProps: {
                itemsPerPageOptions: [10, 14, 24, 34, { text: 'Todas', value: -1 }],
                itemsPerPageText: 'Mostrar en grupo de:',
                showCurrentPage: false,
                pageText: 'Desplegado {0} / {1} de {2}'           
            }, 
            dataTableOptions: {
                itemsPerPage: 24,
                pagination: {
                    enabled: true,
                    options: {
                        numeric: true,
                    },
                },
            }
        }
    },
    mounted(){
        this.generateTableHeader();
        this.validateOptionGroup();  
        this.getGeneralGroupData();
    },
    methods:{
        async getGeneralGroupData(){    
            this.isLoading = true;
            this.items = [];
            try{
                let response = await axios.get('/general-group-data-counters/get-results', {
                    params: {
                        P_USERID: this.$hasAnyRole(['RegionalManager']) ? this.user.employee_number : '',
                        P_OPC: this.groupBy.option,
                        P_VTWEG: this.user.VTWEG,
                        P_SPMON: this.formatSPMON()
                    }
                });
                this.items = response.data.data;
                this.isLoading = false;
            }catch(error){
                console.log(error);
                this.isLoading = false;
            }
        },  
        generateTableHeader(){
            this.header = this.headerDefaul;
            const fechasQuarters = this.calcularUltimosTrim(7, this.monthPeriod);
            const dates = []
            fechasQuarters.forEach(function(value, index){
                const number = (fechasQuarters.length - 1) - index;
                const objQuarter = { text: `Trim ${value.date}`, value: `FACNET${number > 0 ? number : ''}`, align: "center" }
                dates.push(objQuarter)
            });

            this.header = [...this.header, ...dates];          
            this.headerGeneral = dates;
        },
        validateOptionGroup(){
            const role = this.$route.meta.role;  
            if(role === 'Regional'){
                this.optionGroups = this.optionGroups.filter(option => option.id !== 1);
            }                    
            this.groupBy = this.optionGroups[0];
        },
        validateNegativeOrPositive(number){
            return number >= 0 ? 'green-color' : 'red-color';
        },
        formatSPMON() {
            const currentMonth = moment().month() + 1;
            const formatMonth = currentMonth.toString().padStart(2, '0');
            const currentYear = moment().year();
            return `${currentYear}${formatMonth}`;
        },
        validateNumberMaxMin(item, value){            
            return { 'green-color': item.max == value, 'red-color': item.min == value };
        }        
    },
    watch:{     
        groupBy(newValue){
            this.header[0].text = newValue.name;
            this.getGeneralGroupData();
        },
    },
    computed: {
        ...mapGetters({
          user: "auth/user",
        }),
        averageGeneral(){
            let items = {};
            items = this.items.reduce((acc, item, _, { length }) => {
                acc['PEDACT'] = (acc['PEDACT'] || 0) + parseFloat(item['PEDACT']) / length;
                acc['CRECACU'] = (acc['CRECACU'] || 0) + parseFloat(item['CRECACU']) / length;
                acc['CREC3X3'] = (acc['CREC3X3'] || 0) + parseFloat(item['CREC3X3']) / length;
                acc['FACNET'] = (acc['FACNET'] || 0) + parseFloat(item['FACNET']) / length;
                for (let i = 1; i <= 6; i++) {
                    acc[`FACNET${i}`] = (acc[`FACNET${i}`] || 0) + parseFloat(item[`FACNET${i}`]) / length;
                }
                return acc;
            }, {});

            return items;
        },
        itemsDataTable(){
            return this.items.map((item) => {
                const arrNumber = [
                    item.FACNET,
                    item.FACNET1,
                    item.FACNET2,
                    item.FACNET3,
                    item.FACNET4,
                    item.FACNET5,
                    item.FACNET6
                ];

                const numbersMinMax = this.validateNumbersMaxMin(arrNumber);
                return { ...item, ...numbersMinMax };
            });
        }     
    }
}
</script>

<style scoped>
    .cont-shadow{
        background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
        opacity: 1;
    }
    .bloq-height{
      flex: 1;
      padding: 8px 15px;
    }
    .green-color{
        color: #5fa96f;
    }
    .red-color{
        color: red;
    }
    .table__link{
        text-decoration: underline;
        font-weight: bold;
        cursor: pointer;
    }
</style>