<template>
    <div>
      <v-container fluid style="background-color: #fff"> 
        <manager-home v-if="groupBy!='Zona'" @groupByChange="setGroupBy" 
        :chnl="chnl"  
        :groupBy="groupBy" 
        :groupByOptions="groupByOptions"
        :period="period"
        :regionalManager="rgManager"
        />

        <zone-home v-else 
        @groupByChange="setGroupBy"
        :groupByOptions="groupByOptions"
        :groupBy="groupBy"  
        :chnl="chnl"  
        :period="period" 
        :regionalManager="rgManager" > 
      </zone-home>

        <confidentiality-notice  />
      </v-container>
    </div>
  </template>
  <script>    

  import ManagerHome from "./manager/Home.vue";
  import ZoneHome from './zone/Home.vue';
  import ConfidentialityNotice from "@/components/ConfidentialityNotice.vue";
  import { mapGetters } from "vuex";

  export default {
    name: "DirectorHome",
    components: {
      ManagerHome,
      ZoneHome,
      ConfidentialityNotice
    },
    data: () => ({    
      groupBy:'',
      period:'',
      chnl:"",
      rgManager:0,
      groupByOptions:[],
    }), 
    methods: {  
      setGroupBy(data){ 
        this.period = data.period
        this.groupBy = data.groupBy; 
      },
      setGroupByOptions(role){ 
      const userType =  this.chnl == 'PR' ? 'SubGerentes':'Gerentes'
      switch (role) {
        case "Director":   
        case "IELDirector":   
        case "IELCommercialPR":   
        case "IELCommercialDT":   
        case "Subdirector":   
        this.groupByOptions = ['Gerencia Regional','Zona',userType];
        this.groupBy = 'Gerencia Regional';
        break;
        case "Regional":  
        case "RegionalManager":  
        case "IELRegManager":  
        this.groupByOptions = [userType,'Zona']; 
        this.groupBy = userType;

        break; 
      } 
    },
    },  
    created(){ 
        this.chnl =   this.user.VTWEG; 
        const rgManager = this.$getLocalStorageRegional();
        
        if (rgManager && this.$route.meta.role == 'Regional' && !this.$hasAnyRole(["RegionalManager", "IELRegManager"])) {
          this.rgManager = `${rgManager}`;
        }  
        if (this.$hasAnyRole(["RegionalManager", "IELRegManager"])) {
          this.rgManager = `${localStorage.getItem("username")}`; 
        }  
        this.setGroupByOptions(this.$route.meta.role)
    },
    computed: {
    ...mapGetters({ 
      user: "auth/user",   
    }) 
  }

  };
  </script>
  <style>
  .custom-red-button {
    background: transparent linear-gradient(180deg, #A82222 0%, #FF0000 100%) 0% 0% no-repeat padding-box;
    opacity: 1; 
  }
  </style>