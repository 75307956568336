<template>
    <div>
      <template v-if="loading"> 
           <v-skeleton-loader class="mx-auto"  type="card" /> 
       </template>
        <v-simple-table v-else class="mt-0 my-table pb-0" dense>
            <template v-slot:default>
                <thead class="table-heading">
                    <tr>
                      <th rowspan="2" class="text-center text-dark custom-border-right font-weight-bold"> Canal </th>
                      <th rowspan="2" class="text-center text-dark custom-border-right">
                        <b>#&nbsp;de&nbsp;Clientes<br />Sist&nbsp;/&nbsp;Activos <br> 
                          Clientes nuevos<br />1/3/6 meses
                        </b>
                      </th>
                      <th rowspan="2" class="text-center text-dark custom-border-right">
                        <div class="d-flex justify-center">
                            <b>#&nbsp;de&nbsp;Clientes&nbsp;con&nbsp;compra<br />
                              Ctes&nbsp;con&nbsp;compras&nbsp;sem<br />
                          </b>
                        </div> 
                        <table class="w-100 custom-table-item-sm">
                          <tr>
                            <td> {{ getMonths }} </td>
                            <td > <b>Prom 3</b></td>   
                            <td>Dif</td>
                            <td>{{months[2]?.short_name}}</td>
                          </tr>
                        </table>
                      </th>
                      <th rowspan="2" class="text-center text-dark custom-border-right"> <b>%&nbsp;de&nbsp;clientes<br />con&nbsp;pedidos</b></th> 
                      <th rowspan="2" class="text-center text-dark custom-border-right"><b>Pedidos&nbsp;de <br>{{ months[0].full_name }}</b></th>
                      <th rowspan="2" class="text-center text-dark custom-border-right"><b>Pedidos&nbsp;del&nbsp; <br>dia anterior</b></th>
                      <th colspan="3" class="text-center text-dark custom-border-right">
                        <div class="d-flex d-inline-block justify-center">
                          <div class="mt-2"> Crecimientos por: </div>
                          <v-checkbox v-model="isBillingGrowth" dense :disabled="loading">
                          <template v-slot:label>
                            <div> <b class=" text-black small">Facturación</b></div>
                          </template>
                        </v-checkbox>
                        <v-checkbox  v-model="isOrderGrowth" dense :disabled="loading">
                          <template v-slot:label> 
                            <div> <b class=" text-black small">Pedidos</b> </div>
                          </template>   
                        </v-checkbox>
                        </div> 
                      </th>
                    </tr> 
                    <tr>
                      <th class="text-center text-dark"><b>Acum.<br />{{ months[0].date }}</b></th>
                      <th class="text-center text-dark"><b>Últ. 3 Meses</b></th>
                      <th class="text-center text-dark"><b>Últ. 3 Meses vs 3 <br>anteriores</b></th> 
                    </tr> 
                </thead> 

                <tbody class="custom-table-bordered">
                    <tr class="text-body-2">
                      <td>
                        <div class="d-flex d-inline-block">
                          <v-img class="img-fluid align-self-center" src="/img/icono_maps.svg" width="15px" />
                          <div class="align-self-center mr-2 ml-2"> {{chnl == 'PR' ? 'Privados' : 'Minorista'}} </div> 
                          <sales-policy class="align-self-center" :policyType="1" />
                          <sales-policy class="align-self-center" :policyType="2"/> 
                        </div> 
                      </td>
                      <td><b class="small">{{orders.CTETOT ?? 0 }}/ {{orders.NTCA?? 0}} 
                          <br> {{orders.NTCN1 ?? 0}}/{{orders.NTCN3 ?? 0}}/{{orders.NTCN6 ?? 0}} </b>
                      </td>
                      <td>
                        <table class="text-center w-100 custom-table-item-sm">
                          <tr  v-for="(item,index) in orders.CUSTOMERS" :key="index">
                            <td> 
                              <template v-if="index > 1">
                                  <a @click="actionMonth(index,0)">
                                    <span  class="text-decoration-underline">  {{number_format(item.CURRENT)}}</span>
                                  </a>&nbsp;/&nbsp;
                                  <a @click="actionMonth(index,1)">
                                    <span  class="text-decoration-underline">{{number_format(item.MONTH1)}}</span>
                                  </a> 
                                </template>
                                <template v-else> 
                                  <span  class=""> {{number_format(item.CURRENT)}}</span>
                                   &nbsp;/&nbsp; 
                                  <span  class="">{{number_format(item.MONTH1)}}</span> 
                                </template>
                            </td> 
                            <td >
                              <!-- Average 3/12--> 
                               <template v-if="index != 1">
                                  <a @click="actionAverage(index)"  class="text-decoration-underline">{{  number_format(item.AVERAGE3) }}</a>
                                </template>
                                <template v-else>
                                   {{  number_format(item.AVERAGE3) }} 
                                </template>
                              </td>
                            <td>
                              <span>{{ number_format(item.MONTH2 - item.AVERAGE3)}}</span></td>
                            <td>
                              <a @click="actionMonth(index,2)">
                                  <span :class="index > 1 ? 'text-decoration-underline':''">{{number_format(item.MONTH2)}}</span>
                                  </a>
                            </td>
                          </tr> 
                        </table>
                      </td>
                      <td>{{number_format(orders.PCPED,false)}}%</td>
                      <td > {{number_format(orders.PEDMES,1)}}</td>
                      <td>${{number_format(orders.PEDDANT,1)}}</td>
                      <template v-if="isBillingGrowth">
                      <td  style="border-width: 0px;"> <a @click="showCumulativeGrowthModal()" class="text-decoration-underline text-black" >{{number_format(orders.CREACUFAC,false)}}%</a></td>
                      <td  style="border-width: 0px;"> <a @click="showCumulativeGrowthModal('_3M')" class="text-decoration-underline text-black">{{number_format(orders.CREACU3FAC,false)}}%</a></td>
                      <td  style="border-width: 0px;"> <a @click="showCumulativeGrowthModal('_1X3')" class="text-decoration-underline text-black font-weight-bold" >{{number_format(orders.CREACU1X3FAC,false)}}%</a></td>
                    </template> 
                    <template v-else>
                      <td  style="border-width: 0px;"> <a @click="showCumulativeGrowthModal()" class="text-decoration-underline text-black" >{{number_format(orders.CREACU,false)}}%</a></td>
                      <td  style="border-width: 0px;"> <a @click="showCumulativeGrowthModal('_3M')" class="text-decoration-underline text-black" >{{number_format(orders.CREACU3,false)}}%</a></td>
                      <td  style="border-width: 0px;"> <a @click="showCumulativeGrowthModal('_1X3')" class="text-decoration-underline text-black font-weight-bold" >{{number_format(orders.CREACU1X3,false)}}%</a></td>
                    </template> 
                    </tr>
                    <tr> 
                    </tr>
                </tbody>
            </template>
          </v-simple-table> 
          
          <cumulative-growth 
            :title="`Detalle de crecimiento constante de Gerencia del mes: ${months[0].date}`" 
            :isBilling = "isBillingGrowth"
            v-model = "commulativeGrowth" 
            :type ="growthType"
            :option="3"
            :period = "getCumulativePeriod"
            :employeeChannel = "chnl" 
            :regionalManager ="regionalManager"
         /> 

         <payment-info 
         v-model="ShowPaymentViaIEL" 
         title="Dirección"
         :regionalManager ="regionalManager"
         />

    </div>
</template>

<script>

import PaymentInfo from './modals/PaymentInfo.vue'; 
import SalesPolicy from '@/components/policies/SalesPolicy.vue'
import CumulativeGrowth from '@/components/modals/CumulativeGrowth.vue'
import MixinService from '@/mixins/service'
import MixinHelpers from '@/mixins/helpers';
import axios from '@/plugins/axios';

export default ({
      name:'NetSales',
      components:{ 
      PaymentInfo,
      CumulativeGrowth,
      SalesPolicy,
  },
  mixins:[MixinService,MixinHelpers],
  props: {
        chnl: { 
          required: true,
          String
        },
        regionalManager: {
          required:true,
        }
    },
    data: () => ({
        customerSales:false,
        customersBuyWeekly:false,
        showModal:false,
        zoneWithSalesModal:false,
        paymentInfoModal:false,
        orders:[],
        loading:true,
        months:[],
        isOrderGrowth:false,
        isBillingGrowth:true,
        growthType:'',
        commulativeGrowth:false, 
        ShowPaymentViaIEL:false,
    }),
    computed:{
      getCumulativePeriod(){
        const date = this.months[0];
        const day =  date.month < 10 ?`0${date.month}`:date.month
        return `${date.year}${day}`;
      },
      getMonths(){ 
        var months = this.months;  
        return this.implode(this.arrayPluck(months.slice(0, 2),'short_name'),'/') 
      }, 
    },

  methods:{
    showCumulativeGrowthModal(type='')
      { 
          this.growthType=type;
          this.commulativeGrowth = true; 
      },
    actionMonth(index,col){
        switch (index) {
          case 2: this.showcustomersBuyWeekly(col); break;
          case 3: this.ShowPaymentViaIEL=true; break; 
        }
      },
      actionAverage(item){
        switch (item) {
          case 0:this.showCustomersWithSales(); break;
          case 2:this.showCustomersWithMonthlyBuy(); break;
          case 3: this.ShowPaymentViaIEL=true; break; 
        }
      },
      async loadOrders(){  
        this.loading=true;
        try { 
          var params = {
              channel: this.chnl, 
            }
          if (this.regionalManager) params.rgManager = this.regionalManager;
          const response = await axios.get("/daily-director/orders", {
                params:params
              });
          this.orders = response.data;
        } catch (error) {
          this.error = "No se pudo obtener la información";
          this.loading=false;
        } 
        this.loading=false;
    },  
    }, 
    created(){
      this.months = this.calcularUltimosMeses(13);
    }, 
    mounted(){
      this.loadOrders();
    },
  watch:{
    isOrderGrowth(value){ 
      if (value) {
        this.isBillingGrowth=false;
      }else{
        this.isBillingGrowth=true;
      }
    }, 
    isBillingGrowth(value){ 
      if (value) {
        this.isOrderGrowth=false;
      }else{
        this.isOrderGrowth=true;
      }
    },
  },
})
</script>