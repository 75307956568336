<template> 
    <div>
      <div class="d-flex">
            <div class="d-flex justify-start" v-if="showCashRegister">
              <v-icon :color="cashRegisterColor">mdi-{{ icon }}</v-icon>
            </div>
            <div class="d-flex justify-center w-100">
              <span v-if="showValue"> ${{ number_format (value)  }}&nbsp; </span>
              <span v-if="showPendingText"> {{pendingText}} </span>
            </div>
          </div>
          <div class="text-decoration-underline text-blue" v-if="showActionLink">
            <a @click="actionLink"> {{actionLinkText}} </a>
          </div> 
    </div>
  </template>
    
    <script> 
    import  helpers from '@/mixins/helpers.js';

    export default {
      name:"RegisterCash", 
      mixins:[helpers],
      props: {
        actionLinkText: {
          type: String, 
          default:'Detalles'
        }, 
        showValue: {
          type: Boolean, 
          default:true
        }, 
        showActionLink: {
          type: Boolean, 
          default:true
        },  
        value: {
          type: Number,
          default:0
        }, 
        cashRegisterColor: {
          type: String,
          default:'gray'
        }, 
        showCashRegister: {
          type: Boolean,
          default:true
        }, 
        showPendingText: {
          type: Boolean,
          default:true
        }, 
        pendingText: {
          type: String,
          default:'Pendiente'
        }, 
        icon: {
          type: String,
          default:'cash-register'
        }, 
        
      },
      methods: {
        actionLink() {
          this.$emit('actionLink',true) 
        },
      },
    };
    </script>
    
    <style scoped>
    /* Tus estilos aquí */
  
    /**  */
    </style>