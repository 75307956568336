import { render, staticRenderFns } from "./CustomerList.vue?vue&type=template&id=4c004567&scoped=true&"
import script from "./CustomerList.vue?vue&type=script&lang=js&"
export * from "./CustomerList.vue?vue&type=script&lang=js&"
import style0 from "../../../../public/css/seller.css?vue&type=style&index=0&id=4c004567&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c004567",
  null
  
)

export default component.exports