<template>
  <v-btn class="hide-on-print" icon color="black" :to="backRoute" @click="$router.go(-1)">
    <v-icon>mdi-arrow-u-left-top</v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    backRoute: {
      default: null
    }
  }
}
</script>