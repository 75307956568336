import { render, staticRenderFns } from "./UserNotificationsTable.vue?vue&type=template&id=72913fbe&scoped=true&"
import script from "./UserNotificationsTable.vue?vue&type=script&lang=js&"
export * from "./UserNotificationsTable.vue?vue&type=script&lang=js&"
import style0 from "./UserNotificationsTable.vue?vue&type=style&index=0&id=72913fbe&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72913fbe",
  null
  
)

export default component.exports