<template>
  <v-container fluid class="grey lighten-5">
        <div class="col-md-12" v-if="hasError">
          <v-alert text dense outlined type="error">
            <span>{{ hasError }}</span><router-link
                :to="{ name: 'HistoryOrders' }"
                style="color:#fff;"
              > aquí. </router-link>
          </v-alert>
        </div>
      <v-progress-linear :active="loadingOrderDeliveryTime" :indeterminate="loadingOrderDeliveryTime" top color="deep-purple accent-4"></v-progress-linear>
  </v-container>
</template>

<script>
import axios from "@/plugins/axios";

import { mapGetters } from "vuex";
export default {
  name: "ShoppingCartMPR",
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  props: {
    newMaterial: {
      type: String,
      default: null,
    },
    warnMaterial: {
      type: Function,
      default: null,
    },
    warnError: {
      type: Function,
      default: null,
    },
    warnDialogLoading: {
      type: Function,
      default: null,
    },
  },
  data: () => ({
    hasError: null,
    materialItem: {},
    subtotal: 0,
    iva: 0,
    total: 0,
    total2: "",
    customerDestiny: {},
    order:'',
    order_id: null,
    orderShop : [],
    material_code:'',
    uniti_code:'',
    loadingOrderDeliveryTime:true,
    id:'',
    orden_compra:'',
  }),
  async mounted() {
    this.order = this.$route.query.data[0].orden_compra;
    
    
    this.saveOrderInfoReminder(this.order);
    
    this.saveOrderDataRemider(this.$route.query.data[0].id,this.$route.query.data[0].orden_compra);
    this.$nextTick(function () {

   this.getOrderID();
  //this.order = this.$route.query.data[0].orden_compra;
  this.getRoute(this.$route.query.data);
  
  console.log(this.$route.query.data);
  console.log(this.$route.query.data[0].orden_compra);
 });
  

  },
  methods: {
      getRoute(value){    
      for (let i = 0; i < value.length; i++) {
        this.material_code = value[i].codigo;
        this.uniti_code = value[i].cantidad_pedida;
        this.id = value[i].id;
        this.orden_compra = value[i].orden_compra;
        console.log("Material:"+this.material_code);
        console.log(this.uniti_code);
        this.getMaterialInfo(this.material_code,this.uniti_code);
        console.log(this.uniti_code);
      }
    },
    saveOrderInfoReminder(order) {
      this.loadingOrderDeliveryTime = true;
      let type = "CL";
      //let type = "VE"; // ENCASO DE PORTAL VENDEDOR ENVIAR ESTA VARIABLE
      axios
        .post("save-order-info-reminder-pr", {
          customer_id: this.user.custumer_code,
          orden_compra: order,
          destiny: this.user.custumer_code,
          cart: this.cart,
          total: this.total,
          user_id: this.user.id,
          type: type,
        })
        .then((response) => {
          console.log(response);
          console.log("GETID CREATED");
          console.log(response.data.order_id);
        })
        .catch((error) => {
         // alert(error);
         console.log(error);
        })
        .finally();
    },

  getMaterialInfo(material_code,uniti_code) {

        axios
          .post("get-material-info-pr", {
            code: material_code,
            units: uniti_code,
            customer: this.user.custumer_code,
            VKORG: this.user.VKORG,
            VTWEG: this.user.VTWEG,
            VKBUR: this.user.VKBUR,
          })
          .then((response) => {
            console.log(response.data);
            // this.warnDialogLoading(false);
            // this.warnMaterial(response.data);
            console.log(response.data);
            this.saveNewMaterialOrder(response.data)
            this.material_code = null;
            this.uniti_code = null;
          })
          .catch((error) => {
            //this.warnDialogLoading(false);
            this.loadingOrderDeliveryTime = false;
            this.catchErrorMaterial(error.response.data.error_code);
            console.log(error.response.data[0]);
            console.log(error.response.data.error_code);
          })
          
      
    },
    getOrderID() {
      axios
        .post("get-order-id-reminder-pr", {
          customer: this.user.custumer_code,
        })
        .then((response) => {
          this.order_id = response.data.id;
          this.id_r = response.data.backorder_id;
          this.orden_comfirm = response.data.name;

        console.log("get id order: " +response.data.id);
        console.log("get back order: " +response.data.backorder_id);
        console.log("get name order: " +response.data.name);
        //this.getCartItems(this.order_id);
        //this.getOrderInfo(this.order_id);
      })
      .catch((error) => {
        //alert(error);
        console.log(error);
      })
      .finally();
  },

  saveNewMaterialOrder(item) {
  axios
    .post("save-new-material-order-reminder-pr", {
      order_id: this.order_id,
      item_cart: item,
    })
    .then((response) => {
      console.log(response);
      this.orderShop.push({
          id_r:this.id,
          orden_compra:this.orden_compra
      });
      console.log(this.id);
      console.log(this.orden_compra);
      console.log(this.orderShop);
      this.$router.push({
        name: "ShoppingCartReminderPR",
        query: {
          params: { order: "" } 
        },
      });
    })
    .catch((error) => {
      console.log(error);
    }).finally(() => (this.loadingOrderDeliveryTime = false));
},
saveOrderDataRemider(back_id, orden_compra) {
        axios
            .post("save-order-data-remider-pr", {
              back_id: back_id,
              orden_compra: orden_compra,
              customer_code: this.user.custumer_code,
            })
            .then((response) => {
               
                // this.warnDialogLoading(false);
                // this.warnMaterial(response.data);
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error.response.data[0]);
                console.log(error.response.data.error_code);
            })


      },
      catchErrorMaterial(data) {
      this.hasError = data;
      window.scroll(0, window.pageYOffset - 50);
    },

},
};
</script>
<style>
</style>
