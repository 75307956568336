<template>
	<v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <span class="in-line">
        <span class="mt-3"> {{label}}</span>
      <v-text-field
        v-model="date"
        readonly
        v-bind="attrs"
        v-on="on"
        required
				:error-messages="errors"
        class="search-input ml-3"
        solo
        dense
       >
       <template v-slot:append>  
       <v-icon color="black">mdi-calendar-blank</v-icon>
       </template>
      </v-text-field></span>
    </template>
    <v-date-picker
      v-model="date"
			:max-date="maxDate"
			:min-date="minDate"
      @input="menu = false"
			locale="es"
    />
  </v-menu>
</template>

<script>
import moment from 'moment';

export default {
  name: 'InputDatePicker',

  props: {
    value: {
      type: String,
      required: false,
    },

    label: {
      type: String,
      required: false,
    },

    dateFormat: {
      type: String,
      default: 'MM-DD-YYYY',
    },

    minDate: {
      type: Date,
      default: null,
    },

    maxDate: {
      type: Date,
      default: null,
    },

		errors: {
			type: [Array, String],
			default: () => []
		}
  },

  data() {
    return {
      menu: false,
      switchOn: false,
    };
  },

  methods: {
    emitDateSelection(date) {
      this.menu = false;
      this.$emit('onSelect', date);
    },
  },

  computed: {
    date: {
      set(newValue) {
        if (newValue !== null) {
          const date = moment(newValue).format(this.dateFormat);
          this.$emit('input', date);
        } else this.$emit('input', null);
      },

      get() {
        if (this.value !== null) {
          const date = moment(this.value, this.dateFormat, true).format('YYYY-MM-DD');
          return date;
        }

        return null;
      },
    },
  },
};
</script>
<style scoped>
.search-input {
  border-radius: 5px;
  padding-right: 0px;
}

.in-line{
  display:flex;
  color: black;
  font-weight: bold;
}

</style>
