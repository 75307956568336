<template>
  <div id="app">
    <v-app>
      <v-main>  
        <LoaderIusa></LoaderIusa>  
        <NavBar />
        <BreadcrumbRoles v-if="!$hasAnyRole(['CustomerPR', 'Customer'])"/>
        <CustomerMenu />
        <v-alert type="info" :value="alert" class="my-2">
          {{ message }}
        </v-alert>
        <div class="refresh-container" v-show="authenticated">
          <button class="refresh-button" ref="refreshButton">
            <span
              class="refresh-content"
              ref="refreshContent"
              @click="reloadWithoutCache"
              id="refreshContent"
            >
              <v-icon color="red" large>mdi-refresh-circle</v-icon>
              <span class="refresh-text" ref="refreshText" id="refreshText"
                >Recargar sin cache</span
              >
            </span>
          </button>
        </div>
        <!-- Key force view update when going to a same route but diferent parameters ex: /user/123 -> user/689 -->
        <router-view :key="$route.path"/> 
        <RightNavDrawer/>       
      </v-main>
      <modal-steps-after-login />
      <Footer />
    </v-app>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import Pusher from "pusher-js";

import { eventBus } from "@/main.js";
import generalMixin from "./mixins/general";
import userInactivityTime from "./mixins/userInactivityTime";
import NavBar from "@/template/partials/NavBar.vue";
import CustomerMenu from "@/template/partials/customerMenu/CustomerMenu.vue";
import BreadcrumbRoles from "./template/partials/life_line/BreadcrumbRoles.vue";
// import PRNavBar from "@/template/partials/PRNavBar.vue";
//import SideBar from "@/template/partials/SideBar.vue";
import RightNavDrawer from "@/template/partials/RightNavDrawer.vue";
import Footer from "@/template/partials/Footer.vue"; 
import LoaderIusa from '@/components/LoaderIusa.vue'
import ModalStepsAfterLogin from '@/template/partials/stepsAfterLogin/ModalStepsAfterLogin.vue'


export default {
  components: {
    NavBar,
    // PRNavBar,
    //SideBar,
    CustomerMenu,
    Footer,
    RightNavDrawer,
    BreadcrumbRoles,
    LoaderIusa,
    ModalStepsAfterLogin,
  },

  mixins: [generalMixin, userInactivityTime],  

  created () {
    this.launchModalSteps();
  },

  mounted() {
    // this.subscribe();
    
    // eventBus.$on('subscribe', () => { this.subscribe(); });
    // this.addListeners();
    // Detecta el final de la animación y aplica el estilo al elemento interno
    let vm = this;
    this.$refs.refreshButton.addEventListener("animationend", function () {
      vm.$refs.refreshText.classList.add("refresh-text-show");
      vm.$refs.refreshContent.classList.add("refresh-content-border");
    });

    var lastScrollTop = 0;
    // element should be replaced with the actual target element on which you have applied scroll, use window in case of no target element.
    window.addEventListener(
      "scroll",
      function () {
        // or window.addEventListener("scroll"....
        var st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
        if (st > lastScrollTop) {
          // downscroll code
        } else if (st < lastScrollTop) {
          // upscroll code
          document.getElementById('refreshContent').classList.remove("refresh-content-border");
          document.getElementById('refreshText').classList.remove("refresh-text-show");
        } // else was horizontal scroll
        lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
      },
      false
    );
  },

  computed: {
    ...mapGetters({
      token: "auth/token",
      message: "auth/message",
      alert: "auth/alert",
      user: "auth/user",
      authenticated: "auth/authenticated",
      userCode: "auth/userCode",
      hasSteps: "modalStepsAfterLogin/hasSteps",
      showModal: "modalStepsAfterLogin/showModal"
    }),
  },

  methods: {
    ...mapActions({
      signOutAction: "auth/signOut",
      signInVel: "auth/sigInVel",
      loadModalSteps: 'modalStepsAfterLogin/loadModalSteps',
      updateModalState: 'modalStepsAfterLogin/updateModalState'
    }),
    ...mapMutations({
      setVisibleAlert: "auth/SET_VISIBLE_ALERT",
      addNewNotification: "auth/ADD_NEW_NOTIFICATION",
      addDiary: "diary/ADD_DIARY",
      setDrawerState: "diary/SET_DRAWER_STATE",
    }),
    subscribe() {
      if(this.user && this.user.id) {
        var pusher = new Pusher(this.$pusherAppKey, { 
          cluster: 'us2',
          authEndpoint: this.$baseApiUrl + '/pusher/auth'
        });
        console.log('User ID', this.user.id);
        pusher.subscribe('private-users-' + this.user.id);
  
        pusher.bind('Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', data => {
          this.playSound();
          console.log(data);

          if(data.type == 'App\\Notifications\\CustomerRequestAccepted') {

            if(data.event_type == 'accepted') {
              this.$swal('Tu registro ha sido aceptado. Se te redigirá en 3 segundos.', { type: 'loading' });
              setTimeout(() => {
                  if(this.$route.name != 'Home') {
                      this.$router.push('/home').then(() => { location.reload(); });
                } else {
                    location.reload();
                }
              }, 5000);
            } else {
              this.addNewNotification(data);
            }

          } else {
            this.notify('Nuevo evento registrado');
            let event = { notifiable_type: 'App\\Models\\User', notifiable_id: this.user.id, data: data };
            eventBus.$emit('openDrawer', event);
          }
        });
      }
    },    
    reloadWithoutCache() {
      location.reload(true);
    },

    async launchModalSteps (authenticated) {
      await this.loadModalSteps();
      
      if(this.hasSteps && authenticated) this.updateModalState(true);
      else this.updateModalState(false)
    }
  },

  watch: {
    authenticated(newValue) {
      this.launchModalSteps(newValue);
    },

    showModal(newValue) {
      console.log('watch', newValue, this.authenticated)
      if(newValue && !this.authenticated) this.updateModalState(false);
    }
  },

  beforeDestroy() {
    document.removeEventListener('mousemove', this.resetInactivityTimer);
    document.removeEventListener('keydown', this.resetInactivityTimer);

    clearTimeout(this.inactivityTimeout);
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: rgb(255,255,255);
  background: radial-gradient(circle, rgba(255,255,255,1) 85%, rgba(139,139,139,1) 100%);
}
html, body {
    max-width: 100% !important;
    overflow-x: hidden !important;
}
.d-image {
  width: 100%;
}
.refresh-text {
  display: none;
  opacity: 1;
  transition: opacity 1s ease-in-out;
}
.refresh-button {
  position: fixed;
  animation: positioned linear both;
  animation-timeline: scroll();
  animation-range: 0 100vh;
  bottom: 5em;
  right: 5em;
  z-index: 100;
  overflow: hidden;
  transition: opacity 0.3s ease-in-out;
  opacity: 0.5;
}

.refresh-content {
  transition-property: width;
  transition-duration: 3s;
  cursor: pointer;
  padding: 1em;
}

.refresh-button:hover {
  opacity: 1;
}

.refresh-button:hover .refresh-content {
  width: auto;
}

.refresh-text {
  font-size: 1.1em;
  margin-left: 2px;
}

.refresh-button:hover .refresh-content {
  opacity: 1;
  border: 2px solid #ff0000;
  border-radius: 10em;
  padding: 0.3em;
  box-shadow: 0px 3px 6px #00000029;
}

.refresh-content-border {
  opacity: 1;
  border: 2px solid #ff0000;
  border-radius: 10em;
  padding: 0.3em;
  box-shadow: 0px 3px 6px #00000029;
}

.refresh-button:hover .refresh-text {
  opacity: 1;
  display: inline;
}

.refresh-text-show {
  opacity: 1;
  display: inline;
}

@keyframes positioned {
  to {
    top: calc(100vh - 6em);
    color: red;
  }
}
</style>
