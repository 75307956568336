<template>
<div class="container-fluid">
      <CardTitle title="HISTÓRICO DE BENEFICIOS DE CRECIMIENTO" icon="format-align-top">
        <template v-slot:content>  
          <v-progress-linear v-if="loading" indeterminate  color="primary"  ></v-progress-linear>
          <v-card v-else  class="mx-auto elevation-5 " outlined>
          <div>  
            <div class="elevation-5">
              <div class="card-body text-lg-center">
             
                <v-simple-table dense  class="d-none d-sm-block" >
                  <template v-slot:default>
                    <thead>
                      <tr class=" text-black custom-body-hs font-size-x"> 
                        <td class="box-shadow" style="width:42%">Concepto</td>
                        <td class="box-shadow" v-for="(item,index) in rebateHeader " :key="index">{{ item }}   </td> 
                      </tr>
                    </thead>
                    <tbody>
                      <tr class=" no-border custom-body-hs font-size-x">
                        <td class="">Objetivo de rebate</td> 
                        <td class="" v-for="(item,index) in rebateData" :key="index">${{  currencyFormat(item.FKIMGA) }}</td>    
                      </tr>
                      <tr class=" no-border custom-body-hs font-size-x">
                        <td class="">Bono rebate</td> 
                        <td class="" v-for="(item,index) in rebateData" :key="index">${{  currencyFormat(item.REBATE) }}</td>    
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>  
                <!-- Mobile view -->
                <v-simple-table dense  class=" d-sm-none" >
                  <template v-slot:default>
                    <thead>
                      <tr class="font-size-x" >  
                        <td class="box-shadow text-black">Concepto</td>
                        <td class="border">Rebate</td>
                        <td class="border">Objetivo de rebate</td>
                      </tr>
                      <tr class="font-size-x" v-for="(item,index) in rebateData" :key="index">
                        <td class="box-shadow text-black">{{ rebateHeader[index]  }}</td>
                        <td class="border">${{ currencyFormat(item.REBATE)  }}</td>   
                        <td class="border">${{ currencyFormat(item.FKIMGA)  }}</td>   
                      </tr>   
                    </thead> 
                  </template>
                </v-simple-table>                
                <!-- end Mobile view -->
              </div>
            </div>
          </div>
          </v-card> 
        </template>
      </CardTitle>
    </div>
</template>
<script>
import CardTitle from '@/components/CardTitle.vue'
import axios from "@/plugins/axios";
import mixinservice from '@/mixins/service';
export default({
  props: {
    customer:{},
  },
  mixins:[mixinservice],
  watch:{
    customer(){ 
      this.loadData()
    }
  },
  data () {
    return {
      rebateData:[],
      rebateHeader:[],
      loading:true,
      months:["",'Ene - Feb - Mar','Abr - May - Jun','Jul - Ago - Sept','Oct - Nov - Dic']
    }
  },
    name:'GrowthBenefitHistory',
    components:{
        CardTitle
    },
    mounted(){ 
      this.loadData()
    },
    methods:{
    async loadData(){
       let quarters = this.calcularUltimosTrim(4); 
        for (let index = 3; index >=0; index--) {
          let quarter = quarters[index]; 
          const params = {
              customer_id :this.customer,
              quarter: `0${quarter.trim}`,
              year: quarter.year
            } 
          try {
            const rebate = await axios.post(`/cover/rebate`,params);
            this.rebateData.push(rebate.data)
          } catch (error) {
            this.rebateData.push({
              'REBATE':0,
              'FKIMGA':0
            })
             console.error(error)
          } 
          this.rebateHeader.push(this.months[quarter.trim]);
          //this.loadRebate(quarter.year,quarter.trim)
        }  
        this.loading = false;
      }, 
    currencyFormat(number=0){ 
          let result = Number(number);
        return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
    }
});
</script>

<style src="@/assets/css/customerHome.css" scoped></style> 