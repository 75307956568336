<template>
    <div>
        <v-card>
          <v-toolbar :dark=true>
            <v-toolbar-title>Modificación del estado municipio</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn class="right" @click="close()" icon>
              <v-icon>mdi-close-circle</v-icon>
            </v-btn>
          </v-toolbar> 
          <v-card-text>
            <div class="mb-5 mt-3">
              <v-progress-circular v-if="loading  || loadingMunicipality"
                  indeterminate size="25" class="mr-2"
                  color="primary"
                ></v-progress-circular>
              <span class=" text-center ">Ingresa / modifica los valores:</span>
              
                <v-row>
                  <v-col cols="2">
                  <label class="font-weight-black text--black mt-1 text-right">Código postal:</label>
                </v-col> 
                <v-col cols="4"> 
                  <v-text-field v-model="postal_code" :rules="[validatePostalCode]"  :disabled="loading || loadingMunicipality" ></v-text-field>
                 <span v-if="formErrors?.postalCode" class="text-small text-danger">
                 <v-icon color="red">mdi-alert</v-icon> {{ formErrors.postalCode[0] }}
                 </span>
                </v-col>
                </v-row>
              <v-row class="mt-2" v-if="showSelects"> 
                <v-col cols="1">
                  <label class="font-weight-black text--black mt-1 text-right">Estado:</label>
                </v-col> 
                <v-col cols="4"> 
                  <CustomRedSelect :disabled="loading || loadingMunicipality" value="AGU" @change="getMunicipalities()" v-model="state" class="text-left" placeholder="opción" :options="states"></CustomRedSelect>
                  <span v-if="formErrors?.state" class="text-small text-danger">
                 <v-icon color="red">mdi-alert</v-icon> {{ formErrors.state[0] }}
                 </span>
                </v-col>
                <v-col cols="2">
                  <label class="font-weight-black text--black mt-1 text-right">Municipio:</label>
                </v-col>
                <v-col cols="4">
                  <CustomRedSelect :disabled="loading || loadingMunicipality"   v-model="municipality" class="text-left" placeholder="opción" :options="municipalities"></CustomRedSelect>
                  <span v-if="formErrors?.municipality" class="text-small text-danger">
                 <v-icon color="red">mdi-alert</v-icon> {{ formErrors.municipality[0] }}
                 </span>
                </v-col>
              </v-row>
            </div>
            <div class="mb-5" v-if="showSelects ">
              <v-btn :loading="loading" @click="saveAddress()" :disabled="loading || loadingMunicipality" color="success" class="ma-2 white--text"
                style="border-width: 2px; background: #4CAF50 0% 0% no-repeat padding-box; border: 2px solid #05741F !important; ">
                Confirmar
                <v-icon right dark>
                  mdi-check-bold
                </v-icon>
              </v-btn>
              <v-btn @click="close()" color="red" class="ma-2 white--text"
                style="border-width: 2px; background: #E20028 0% 0% no-repeat padding-box; border: 2px solid #890707 !important;">
                Cancelar
                <v-icon right dark>
                  mdi-close
                </v-icon>
              </v-btn>
            </div>
            <v-alert v-if="successInfo" dense type="success" dismissible>{{successInfo}}</v-alert>
          </v-card-text>
        </v-card>
    </div>
</template>

<script>
import axios from "@/plugins/axios";
import CustomRedSelect from "@/components/CustomRedSelect.vue";
export default({
    name:'ChangeTownForm',
    props:['customer'],
    components:{
      CustomRedSelect
    },
    watch: {
      state(value){
        this.getMunicipalities(value)
        if (this.address?.state_code){ 
        this.selectedState =  this.states.find(item => item.value == this.address?.state_code) ?? 0;        
        }else {
          this.selectedState =  this.states.find(item => item.value == value) ?? 0;        
        }
      },  
    
      municipality(value){
        if (value && this.municipalities.length > 0 ) { 
          var municipality = this.municipalities.find(item => item.municipality_id == value);
          if (municipality?.postal_code != this.postal_code) {
            this.postal_code = municipality.postal_code;
          }
        } 
      } 
  },
    data(){
      return {
        state: 0,
        postal_code:"",
        states:{},
        municipalities: [],
        showSelects:false,
        municipality: 0,
        loading:false,
        selectedMunicipality:[], 
        selectedState:[],
        formErrors:[],
        successInfo:null,
        address:[],
        loadingMunicipality:false
      } 
    },
    mounted(){
      this.getStates();  
      this.address = this.customer?.user?.customer_addresses??[];
      if (this.address) {
        this.postal_code = this.address.postal_code; 
      }  
   
    },  
    methods:{
      validatePostalCode(value) {
        this.formErrors = [];
      // Expresión regular para validar códigos postales de México
      const regex = /^[0-9]{5}$/;
      if (!regex.test(value)) {
        return 'Código postal no válido (ejemplo: 12345)';
      }
      this.searchPostalCode(value);
      this.showSelects=true;
      return true;
      },
       searchPostalCode(value){
        this.loading=true;
             axios.post("/address/postal-codes", {postal_code:`${value}`})
         .then((response) => {
           if (response.data) {    
            if (!response.data?.label) {
              this.formErrors.postalCode = {0:'No se ha encontrado el código postal. Por favor verifique que se encuentre escrito correctamente'}
            
             // this.selectedMunicipality =   this.municipalities.find(item => Number (item.id) ===  Number (this.address.municipality_id)) ?? 0;  
            }else{
              this.showSelects=true;
              this.selectedState =  this.states.find(item => item.id ==  Number (response.data.value)) ?? 0;  
              console.log(Number (response.data.value));
              this.state= this.selectedState.value??0; 
              this.selectedMunicipality = response.data.municipality;
              this.municipality =  response.data.municipality.value;
          
              /*if (this.municipalities) {
                this.selectedMunicipality =   this.municipalities.find(item => item.value ==  response.data.municipality.value) ?? 0;  
              } else{
                this.municipality =    response.data.municipality.value ;   
              }*/
               //this.municipality = this.selectedMunicipality.value ;
            } 
           } 
           this.loading=false;
         })
         .catch((error) => {
          this.loading=false;
           console.error(error);
         });
      },
        getStates(){
          this.loading=true;
             axios.get("/address/states")
         .then((response) => {
           if (response.data) {   
            this.states=  response.data;
            if(this.address?.state_code){ 
              this.state = this.address.state_code;
            }
           } 
           this.loading=false;
         })
         .catch((error) => {
          this.loading=false;
           console.error(error);
         });
     },
     getMunicipalities(state){

      state  = this.states.find(item => item.value == state)?.id; 
      this.loadingMunicipality=true;
      this.municipalities=[];
        var params = {
            stateId:state,
          }
             axios.post("/address/municipalities",params)
         .then((response) => {
           if (response.data) {   
            this.municipalities=  response.data.municipalities;   
           // this.selectedMunicipality = response.data.municipalities.find(item => item.municipality_id == this.selectedMunicipality.municipality_id)  
          
           // this.municipality = this.selectedMunicipality?.value;   
            this.loadingMunicipality=false;
           } 
         })
         .catch((error) => {
          this.loadingMunicipality=false;
           console.error(error);
         });  
     },
     saveAddress(){
      this.successInfo = false;
      this.formErrors=[];
      this.selectedMunicipality =  this.municipalities.find(item => item.value ==   this.municipality ) ?? 0;
      this.selectedState =  this.states.find(item => item.value ==   this.state ) ?? 0;
      var params = {
            'customerCode' : this.customer.KUNNR,
            'postalCode' : this.postal_code,
            'stateId' : this.selectedState.id,
            'municipalityId': this.selectedMunicipality.municipality_id,
            'addressId': this.selectedMunicipality.value,
            'municipality': this.selectedMunicipality.label, 
            'country': this.selectedMunicipality.country,
            'state': this.selectedState.label ,
            'stateCode': this.selectedState.value,
      }   
      this.loading=true;
             axios.post("/cover/address/save",params)
         .then((response) => {  
           this.loading=false; 
          this.successInfo = response.data.message;  
          this.$emit('addressUpdated',response.data.data);
         })
         .catch((error) => { 
          if (error.response.data?.errors) {
            this.formErrors= error.response.data?.errors;
          }
          this.loading=false;
           console.error(error.response.data?.errors);
         }); 
     },
     close(){
      this.$emit('closeTownForm',true)
     }

    }
})
</script>