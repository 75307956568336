<template>
    <div>
        <v-simple-table class="my-t" dense>
                  <template v-slot:default>
                    <thead>
                      <tr class="bordered">
                        <th rowspan="2"  class="text-center text-dark border-bottom"><b>Resumen</b>  </th>
                        <th rowspan="2" class="text-center text-dark border-bottom"><b>#&nbsp;clientes <br> sistema/activos <br />clientes&nbsp;nuevos <br>1/3/6&nbsp;meses </b>  </th>
                        <th rowspan="2" class="text-center text-dark border-bottom"><b>%&nbsp;de&nbsp;clientes<br>con&nbsp;Pedidos</b></th>
                        <th rowspan="2" class="text-center text-dark border-bottom"><b>Pedidos&nbsp;de&nbsp;<br>{{months[0].date}}</b></th>
                        <th colspan="3" class="text-center"> 
                          <div class="d-flex justify-center">
                            <span class="text-dark" >Promedio diario de pedidos (Días hábiles)</span> 
                          </div> 
                        </th>  
                        <th rowspan="2" class="text-center text-dark"><b> Backorder&nbsp;cancelado <br>del mes</b></th>
                        <th rowspan="2" class="text-center text-dark"><b> Backorder&nbsp;cancelado <br>del dia anterior</b></th>
                      </tr>
                      <tr class="bordered">   
                        <th class="text-center text-dark"><b>{{months[13].date}} / {{months[2].date}} <br> (254)</b></th> 
                        <th class="text-center text-dark"><b>{{months[1].date}} / {{months[0].date}} <br> (30)</b></th> 
                        <th class="text-center text-dark"><b>Pedidos&nbsp;del&nbsp;dia <br>anterior </b></th> 
                      </tr> 
                    </thead>
                    <tbody>
                      <tr class="bordered" v-if="!loading">
                        <td> Minoristas <br> {{ orders?.hab }} h $ {{orders.FACMES}} $/h</td>
                        <td> <b>{{formatNumber(orders.CTESIS,'',0)}} / {{formatNumber(orders.CTEACT,'',0)}} <br> 
                          {{formatNumber(orders.CTENUE1,'',0)}}/{{formatNumber(orders.CTENUE3,'',0)}}/{{formatNumber(orders.CTENUE6,'',0)}}</b> </td>
                        <td> <b>{{formatFloat(orders.PCTEPED)}}%</b> </td>
                        <td>{{formatNumber(orders.PEDACT,'$',0)}}</td>
                        <td><b>{{formatNumber(orders.PDPEDACU,'$',0)}}</b></td>
                        <td><b>{{formatNumber(orders.PDPED2M,'$',0)}}</b></td>
                        <td>{{formatNumber(orders.PEDDANT,'$',0)}}</td>
                        <td>{{formatNumber(orders.BOCMES,'$',0)}}</td>
                        <td>{{formatNumber(orders.BOCDIAA,'$',0)}}</td>
                      </tr>
                      <tr v-else>
                        <td colspan="9">
                          <v-progress-linear indeterminate color="cyan" />
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
    </div>
</template>
<script>
import numbersFormats from '@/mixins/numbersFormats';
import service from '@/mixins/service';
import axios from '@/plugins/axios';

export default({
    name:'AverageOrders',
    mixins:[numbersFormats,service],
    props: {
      chnl: { 
        required:true,
        String
      },
      regionalManager:{
        required:true,
      }
    },
    data: () => ({
        loading:true, 
        orders:[],
        months:[],
    }),
    methods:{
      async loadResumeOrders(){  
        this.loading=true;
        try { 
          const response = await axios.get("/daily-director/resume-orders", {
                params: { 
                  'channel': this.chnl ,
                  'rgManager':this.regionalManager
                }
              });
          this.orders = response.data;
        } catch (error) { 
          this.loading=false;
        } 
        this.loading=false;
    },
    },
    created(){
      this.months = this.calcularUltimosMeses(14);
    },
    mounted(){
      this.loadResumeOrders()
    }
})
</script>
<style scoped>
.border-bottom {
  border-width: 0px 0px 2px 0px !important;
  border: 1px solid #DDE1E6;
}
.bordered > td {
  border: 1px solid #DDE1E6;
}

.bordered > th { 
  background: #F2F2F2 0% 0% no-repeat padding-box;
box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
}
</style>
<style scoped src="../../../../../public/css/managercustom.css"></style>