<template>
    <div> 
    <div v-if="!loading" class="base" @click="dialog=true">
        <div class="pic1 zindex1 w-100p"></div>
        <div class="pic2 zindex1 w-100p"></div>
        <div class="pic3 zindex1 w-100p"></div>
       <div class="square1 zindex1"> 
        <div class="total-stop-light zindex1 circle-light " :class="trafficlightData.TRAFFICLIGHT == 3 ? 'total-stop-turnOn' : 'turn-off'"></div>
        <div class="stop-light zindex1 circle-light " :class="trafficlightData.TRAFFICLIGHT == 2 ? 'stop-turnOn' : 'turn-off'"></div>
        <div class="go-light zindex1 circle-light " :class="trafficlightData.TRAFFICLIGHT == 1 ? 'go-turnOn' : 'turn-off'"></div>
       </div>
    </div>
    <v-progress-circular v-else indeterminate color="primary" ></v-progress-circular>
       <v-dialog v-model="dialog" max-width="1500" >
        <v-card class="">
        <div class="text-h5  font-weight-bold pa-4"> 
            Métrica de productividad de {{month}}
            <v-divider></v-divider> 
        </div>
        <v-card-text>  
            <v-row>
                <v-col cols="12" md="6">
                    <v-card>
                        <v-row class="pa-2">
                            <template v-if="trafficlightData?.CUMPREL"> 
                                <v-col cols="4" class="d-flex pb-0 pt-0">
                                    <div class="align-self-center text-left">
                                        <span class="font-weight-bold  ">Cumplimiento al presupuesto</span> 
                                    </div>
                                </v-col>
                                <v-col sm="8"  cols="12" class="pb-0 pt-0"> 
                                    <ChartBar :color="trafficlightData.LIGHTS.CUMPREV" :chartValue="number_format(trafficlightData.CUMPREV??0,false)">
                                        <template v-slot:params>
                                        <div class="d-flex justify-end">
                                            <span class="mr-5">&#60; 89 % </span>
                                            <span class="mr-5"> 90 - 99 %</span>
                                            <span class="mr-5"> 100+ %</span> 
                                        </div>
                                        </template>
                                    </ChartBar>
                                </v-col>  
                            </template>  
                            <template v-if="trafficlightData?.COBCLIL">
                                <v-col sm="4" cols="12" class="d-flex pb-0 pt-0">
                                    <div class="align-self-center text-left">
                                        <span class="font-weight-bold  ">Cobertura clientes</span> 
                                    </div>
                                </v-col>
                                <v-col sm="8"  cols="12" class="pb-0 pt-0"> 
                                    <ChartBar :color="trafficlightData.LIGHTS.COBCLIV" :chartValue="number_format(trafficlightData.COBCLIV??0,false)">
                                        <template v-slot:params>
                                        <div class="d-flex justify-end">
                                            <span class="mr-5">&#60; 66 % </span>
                                            <span class="mr-5"> 66 - 75 %</span>
                                            <span class="mr-5"> > 75 %</span> 
                                        </div>
                                        </template>
                                    </ChartBar>
                                </v-col>  
                            </template>
                            <template v-if="trafficlightData?.COBFAML">
                                <v-col sm ="4"  cols="12" class="d-flex pb-0 pt-0">
                                    <div class="align-self-center text-left">
                                        <span class="font-weight-bold  ">Cobertura familias</span> 
                                    </div>
                                </v-col>
                                <v-col  cols="12" sm="8" class="pb-0 pt-0"> 
                                    <ChartBar :color="trafficlightData.LIGHTS.COBFAMV" :chartValue="number_format(trafficlightData.COBFAMV??0,false)">
                                        <template v-slot:params>
                                        <div class="d-flex justify-end">
                                            <span class="mr-5">&#60; 60 % </span>
                                            <span class="mr-5"> 60 - 79 %</span>
                                            <span class="mr-5"> > 80 %</span> 
                                        </div>
                                        </template>
                                    </ChartBar>
                                </v-col> 
                            </template>
                            <template  v-if="trafficlightData?.RENGLOL">
                                <v-col  cols="12" sm="4" class="d-flex pb-0 pt-0">
                                    <div class="align-self-center  text-left">
                                        <span class="font-weight-bold  ">Renglonaje</span> 
                                    </div>
                                </v-col>
                                <v-col  cols="12" sm="8" class="pb-0 pt-0" >
                                    <ChartBar :color="trafficlightData.LIGHTS.RENGLOV" :chartValue="number_format(trafficlightData.RENGLOV??0,false)">
                                        <template v-slot:params>
                                        <div class="d-flex justify-space-between">
                                            <span  >Debajo de reng. prom. </span>
                                            <span > El reng. prom.</span>
                                            <span> Arriba del reng. prom.</span> 
                                        </div>
                                        </template>
                                    </ChartBar>
                                </v-col>  
                            </template>
                            <template v-if="trafficlightData?.CLIEACL">
                                <v-col cols="12" sm="4" class="d-flex pb-0 pt-0" >
                                    <div class="align-self-center  text-left">
                                        <span class="font-weight-bold  ">Clientes activos</span> 
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="8" class="pb-0 pt-0"> 
                                    <ChartBar :color="trafficlightData.LIGHTS.CLIEACV" :chartValue="number_format(trafficlightData.CLIEACV??0,false)">
                                        <template v-slot:params>
                                        <div class="d-flex justify-end">                                        
                                            <span class="mr-5">&#60; 20 % </span>
                                            <span class="mr-5"> 21 - 30 %</span>
                                            <span class="mr-5"> >30 %</span> 
                                        </div>
                                        </template>
                                    </ChartBar>
                                </v-col> 
                            </template> 
                            <template v-if="trafficlightData?.CREACUL">
                                <v-col cols="12" sm="4"  class="d-flex pb-0 pt-0">
                                    <div class="align-self-center text-left">
                                        <span class="font-weight-bold">Crecimiento Acumulado</span> 
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="8" class="pb-0 pt-0"> 
                                    <ChartBar :color="trafficlightData.LIGHTS.CREACUV" :chartValue="number_format(trafficlightData.CREACUV??0,false)">
                                        <template v-slot:params>
                                        <div class="d-flex justify-space-between">                                        
                                            <span class="mr-5">0%</span>
                                            <span class="mr-5"> 1% - 19.99%</span>
                                            <span class="mr-5"> >= 20</span> 
                                        </div>
                                        </template>
                                    </ChartBar>
                                </v-col>
                            </template> 

                            <template v-if="trafficlightData?.PEDNNCL">
                                <v-col cols="12" sm="4"  class="d-flex pb-0 pt-0">
                                    <div class="align-self-center text-left">
                                        <span class="font-weight-bold  ">Pedidos de productos nuevos o no comprados</span> 
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="8" class="pb-0 pt-0"> 
                                    <ChartBar :color="trafficlightData.LIGHTS.PEDNNCV" :chartValue="number_format(trafficlightData.PEDNNCV??0,false)">
                                        <template v-slot:params>
                                        <div class="d-flex justify-space-between">                                        
                                            <span class="mr-5">0</span>
                                            <span class="mr-5"> 1</span>
                                            <span class="mr-5"> > 1</span> 
                                        </div>
                                        </template>
                                    </ChartBar>
                                </v-col>
                            </template>  
                        </v-row>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6" class="pa-0">
                    <v-card class="pt-8 h-100">
                        <span class="font-weight-bold ml-16">
                            Tu puntaje es :
                        </span>
                        <div class="d-flex justify-center">
                            <div style="display: flex;  flex-direction: row; margin-left: -1px;">
                                <div class="custom-circle" style="background-color: red !important;"> 
                                   <span class="font-weight-bold">0 - 60 </span>
                                </div>
                                <div class="custom-circle" style="margin-left: -11px;background: #FFD75E 0% 0% no-repeat padding-box !important;">
                                  <span class="font-weight-bold">  60.1 - 99  </span>
                                </div>
                                <div class="custom-circle" style="margin-left: -11px;">
                                   <span class="font-weight-bold"> 99.1 - 100  </span>
                                </div>
                                <div class="custom-circle mb-10" :style="'margin-left: 11px; width: 100px !important; height: 100px !important; '+trafficlightColorCircle">
                                   <span class="font-weight-bold text-black text-h6"> {{number_format(trafficlightData.TOTAL??0,false)}}  </span>
                                </div>
                            </div>
                        </div> 
                        <div class="text-left pa-3  mt-12 ">
                          <!-- #Comentarios que se mostrarán desde Gerente  
                            Has logrado que tus clientes sean recurrentes en sus compras y estén <br> activos en IEL.  <br>Te recomendamos priorizar este mes renglonaje. 
                          -->
                        </div>
                    </v-card>
                </v-col>
            </v-row>            
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer> 
          <v-btn color="green darken-1" text @click="dialog = false">
          Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
        </v-dialog> 
    </div> 

</template> 
<script>
import axios from '@/plugins/axios';
import ChartBar from './ChartBar.vue';
import MixinHelpers from '@/mixins/helpers';
import moment from 'moment';

    export default({
        mixins:[MixinHelpers],
        components:{ChartBar},
            data () {
                return {
                    dialog:false,
                    loading:true,
                    trafficlightData:{}
                }
            },
        props: {
            trafficlight: {default:0}, //no requerido
            seller:{},
            cancelToken:{}
        },
        name:'TrafficLight',  

        watch:{ 
            seller(){
                this.getTrafficlight();
            }
        },
        computed:{
            month(){
                return   moment().subtract(1, 'months').format('MMMM');
            },
            trafficlightColorCircle(){
                var style='';
                switch (this.trafficlightData.TRAFFICLIGHT) {
                    case 2: style="#FFD75E 0% 0% no-repeat padding-box !important;"; break;
                    case 3: style="red !important;"; break; 
                }
               return ' background:'+ style;
            }
        },
        methods:{
            getTrafficlight(){ 
                var params = {
                    seller_id :this.seller.PERNR,
                    channel :this.seller.VTWEG,
                }
            axios.post("seller/home/trafficlight", params,{
                cancelToken: this.cancelToken.token,
                noCancelRequest:true,
                cachable:true,
            })
          .then((response) => {
                this.trafficlightData = response.data
                this.loading=false;
            });
            }
        }
         
    });
</script>
<style scoped src="../../public/css/trafficLight.css"></style> 

