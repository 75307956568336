<template>
    <div>
      <v-form ref="form"   lazy-validation >
        <v-row  class="justify-text" justify="center">
              <v-col cols="12" md="6">
            <div class="text-left font-weight-bold pl-1 pl-md-10 mr-md-15">
              <div class=" flex-nowrap d-sm-flex d-inline-block mt-2 ">
                <label class="col-12 col-sm-4 pa-0">Título:</label>
                <GraySelect dense :disabled="loading" :value="1" class="text-left col-12 col-sm-8" v-model="professionalDegree" :options='professionalDegreeOptions'></GraySelect> 
              </div>
              <div class="flex-nowrap d-sm-flex d-inline-block justify-md-space-around  w-100">
                <label class="col-12 col-sm-4 pa-0">Nombre:</label>
                <input @input="rules('name',contactName,true)" v-model="contactName" :disabled="loading"  dense class="form-control  form-control-custom text-left col-12 col-sm-8 " placeholder="Escriba nombre" />
              </div>
              <small v-if="errors.name" class="text-danger"> <v-icon size="15" color="#E20028">mdi-alert</v-icon> {{ errors.name }}</small>
              <div class="flex-nowrap d-sm-flex d-inline-block justify-md-space-around mt-3 w-100">
                <label class="col-12 col-sm-4 pa-0">Apellido:</label>
                <input @input="rules('last_name',contactLastname,true)" v-model="contactLastname" :disabled="loading" class="form-control form-control-custom  text-left col-12 col-sm-8" placeholder="Escriba apellidos" />
             </div>   
             <small v-if="errors.last_name" class="text-danger"> <v-icon size="15" color="#E20028">mdi-alert</v-icon> {{ errors.last_name }}</small> 
             <div class="flex-nowrap d-sm-flex d-inline-block justify-md-space-around mt-3 w-100">
                <label class="col-12 col-sm-4 pa-0">Puesto:</label>
                <GraySelect :value="1" v-model="jobTitle" class="text-left" :options="jobTitlesOptions"  style="width: 90%;"></GraySelect>
             </div>      
             <div class="flex-nowrap d-sm-flex d-inline-block justify-md-space-around mt-3 w-100">
                <label class="col-12 col-sm-4 pa-0">Fecha de nacimiento:</label>  
                   <input type="date"  v-model="date" @input="updateDate()"  class="form-control form-control-custom" /> 
             </div>   
             <small v-if="errors.dayOfBirdth" class="text-danger"> <v-icon size="15" color="#E20028">mdi-alert</v-icon> {{ errors.dayOfBirdth }}</small>
             <div class="flex-nowrap d-sm-flex d-inline-block justify-md-space-around mt-3 w-100">
                <label class="col-12 col-sm-4 pa-0">E-mail:</label> 
                <input @input="rules('email',contactEmail,true,3)" v-model="contactEmail"  :disabled="loading" class="form-control form-control-custom  " placeholder="Escriba E-mail" />
             </div>   
             <small v-if="errors.email" class="text-danger"> <v-icon size="15" color="#E20028">mdi-alert</v-icon> {{ errors.email }}</small>
             <div class="flex-nowrap d-sm-flex d-inline-block justify-md-space-around mt-3 w-100">
                <label class="col-12 col-sm-4 pa-0">Teléfono:</label> 
                <input @input="rules('phone',contactPhone,true,2,10,10);" v-model="contactPhone"  :disabled="loading" class="form-control form-control-custom  " placeholder="Sólo 10 dígitos" />
              </div> 
              <small v-if="errors.phone" class="text-danger"> <v-icon size="15" color="#E20028">mdi-alert</v-icon> {{ errors.phone }}</small>
              <div class="flex-nowrap d-sm-flex d-inline-block justify-md-space-around mt-3 w-100">
                <label class=" col-12 col-sm-4 pa-0">Domicilio fiscal:</label> 
                 <v-textarea dense solo no-resize   @input="rules('fiscal_address',fiscalAddress,1,1,2,300)" rows="2" :disabled="loading"  v-model="fiscalAddress"></v-textarea>
              </div> 
              <small v-if="errors.fiscal_address" class="text-danger"> <v-icon size="15" color="#E20028">mdi-alert</v-icon> {{ errors.fiscal_address }}</small>
                <div >
                <v-checkbox :disabled="loading" v-model="contactIsMain" class="text-right">
                  <template v-slot:label>
                    <div class="text-black"> Es contacto principal </div>
                  </template>
                </v-checkbox>
                </div>   
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <div style="width: 100%; background: #EBEBEB 0% 0% no-repeat padding-box; height: 20px;"></div>
                <div class="d-sm-flex justify-md-space-around flex-nowrap pl-md-5 pr-md-5">
                  <div >
                    <v-checkbox dense :disabled="loading" v-model="orderCaptureConfirmation"  >
                      <template v-slot:label>
                        <div class="text-black text-subtitle-2">Confirmación de  <br> captura de pedido </div>
                      </template>
                    </v-checkbox>
                  </div>
                  <div >
                    <v-checkbox dense :disabled="loading" v-model="billOfLading"  >
                      <template v-slot:label>
                        <div class="text-black   text-subtitle-2">Aviso de embarque</div>
                      </template>
                    </v-checkbox>
                  </div>
                  <div >
                    <v-checkbox dense :disabled="loading" v-model="acknowledgmentOfReceipt" >
                      <template v-slot:label>
                        <div class="text-black  text-subtitle-2"> Acuse de recibo </div>
                      </template>
                    </v-checkbox>
                  </div>
                  <div >
                    <v-checkbox dense :disabled="loading" v-model="departureNotice"  >
                      <template v-slot:label>
                        <div class="text-black  text-subtitle-2">Aviso de <br> próxima entrega</div>
                      </template>
                    </v-checkbox>
                  </div>
                </div>  
              </v-col>
              <v-col cols="12">
                <div  >  
                  <v-btn v-if="!editMode"  class="ma-2" :loading="loading" :disabled="loading" color="success"  @click="saveContact()" >
                     Guardar
                  <template v-slot:loader>
                    <span>Guardando...</span>
                  </template>
                </v-btn> 
                <v-btn v-if="editMode"  class="ma-2" :loading="loading" :disabled="loading" color="warning"  @click="updateContact()" >
                     Actualizar
                  <template v-slot:loader>
                    <span>Guardando...</span>
                  </template>
                </v-btn>
                <v-btn  class="ma-2" :loading="loading" :disabled="loading" color="error"  @click="initContact()" >
                     Cancelar 
                </v-btn> 
                </div> 
              </v-col>
                    </v-row>
            </v-form> 
    </div>
</template>

<script>
import axios from "@/plugins/axios";
import moment from 'moment';
import GraySelect from "@/views/auth/modals/GraySelect.vue";

export default ({
    name:'CustomerContactForm',
    components: {GraySelect},
    props:['itemEditContact','userId'], 
    mounted(){
      if (this.itemEditContact?.client_id) this.editContact(this.itemEditContact) 
      if (!this.professionalDegreeOptions) this._getContactOptions(); 
    }, 
    watch: { 
    date (value) {  
        this.dayOfBirdth =  moment(value).format('DD-MM-YYYY');
        this.rules('dayOfBirdth',this.dayOfBirdth,true,4)
      }, 
    modal(){ 
        this.initContact() ;
    }
  },
    data() {
        return {
    menu: false,
      selectedDate: moment().format('DD-MM-YYYY'),
    loading:false, 
    editMode:false,
    contactSelected:{}, 
    contactOptions:{},
    professionalDegreeOptions: [
        {id: 1 ,  title: "C.P."},
        {id: 2 ,  title: "Lic."},
        {id: 3 , title: "Ing."},
        {id: 4 , title: "Sr."},
        {id: 5 , title: "Sra."},
        {id: 6 , title: "Srita."},
        {id: 7 , title: "Mtro."},
        {id: 8 , title: "Mtra."}, 
        ],
    jobTitlesOptions:[ 
        {id: 1, title: "Dueño"},
        {id: 2, title: "Dueña"},
        {id: 3, title: "Socio"},
        {id: 4, title: "Socia"},
        {id: 5, title: "Dir. Comercial"},
        {id: 6, title: "Gerente"},
        {id: 7, title: "Encargado de Pagos"},
        {id: 8, title: "Comprador"},
        {id: 9, title: "Empleado"},
        {id: 10, title: "Empleada"} 
    ],
    jobTitle:1,
    professionalDegree:1,
    contactName:"",
    contactLastname:"",
    contactEmail:"",
    contactPhone:"",
    contactIsMain:false,
    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    dayOfBirdth:moment().format('DD/MM/YYYY'), 
      menu1: false, 
    contacts:this.dataContact,
    //validation rules 
    errors:{
      name:'',
      last_name:'',
      email:'',
      phone:'', 
      dayOfBirdth:'', 
      order_capture_onfirmation:0,
      bill_of_lading:0,
      acknowledgment_of_receipt:0,
      departure_notice:0,
      fiscal_address:'', 
    },  
    orderCaptureConfirmation:0,
      billOfLading:0,
      acknowledgmentOfReceipt:0,
      departureNotice:0,
      fiscalAddress:'', 
  }
    },
    methods: { 
    updateDate() {
                    // Verifica si la entrada sigue el formato dd-mm-yyyy
                    if (/^\d{2}-\d{2}-\d{4}$/.test(this.dayOfBirdth)) {
                        const formattedDate = moment(this.dayOfBirdth, 'DD/MM/YYYY').format('DD/MM/YYYY');
                        this.dayOfBirdth = formattedDate;
                    }
                },
    birthdayForm(){    
      this.$emit('closeBF') 
    },
    editContact(item){   
      this.$emit('editContact');
        this.resetErrors();  
        this.date = moment(moment(item.date_of_birth,'D-M-YYYY')).format('YYYY-MM-D'); 
        this.editMode=true;
        this.contactSelected = item;
         this.contactName = item.name;
         this.contactLastname = item.last_name;
         this.contactEmail = item.email; 
         this.professionalDegree = item.professional_degree.id;
         this.jobTitle = item.job_title.id; 
         this.contactIsMain = item.main;
         this.contactPhone = item.phone; 
         this.fiscalAddress = item.fiscal_address; 
         this.orderCaptureConfirmation = item.order_capture_confirmation;
         this.billOfLading = item.bill_of_lading;
         this.acknowledgmentOfReceipt = item.acknowledgment_of_receipt;
         this.departureNotice = item.departure_notice;
      
    }, 
    updateContact(){

    var params = { 
          client_id:this.contactSelected.client_id,
          name : this.contactName,
          last_name :this.contactLastname,
          email : this.contactEmail,
          phone : this.contactPhone,
          main : this.contactIsMain,
          date_of_birth :this.dayOfBirdth,
          professional_degree :  this.professionalDegree,
          job_title :this.jobTitle, 
          order_capture_confirmation:this.orderCaptureConfirmation?1:0,
          bill_of_lading:this.billOfLading?1:0,
          acknowledgment_of_receipt:this.acknowledgmentOfReceipt?1:0,
          departure_notice: this.departureNotice?1:0,
          fiscal_address:this.fiscalAddress,
       }
       this.loading = true;
      axios.post("/contact-client/update/"+this.contactSelected.id,params)
            .then((response) => {
             this.loading=false;
             if (response.data?.result>1) {
                 console.log( response.data)
             } else {    
              this.$swal({
                    title: response.data.message,
                    icon: "success",
                    type: "succes"
                }) 
                this.initContact() 
                  this.$emit('updatedContactList',true)
               }  
           })
           .catch((e) => {
             this.loading=false;
            console.log(e); 
           });  

    },
    initContact(){ 
      this.$emit('closeForm',true); 
      this.contactSelected = {};
      this.editMode = false; 
         this.contactName = '';
         this.contactLastname = '';
         this.contactEmail = ''; 
         this.professionalDegree = 1;
         this.jobTitle = 1;   
         this.contactIsMain = false;
         this.contactPhone = ''; 
         this.fiscalAddress = ''; 
         this.orderCaptureConfirmation = 0;
         this.billOfLading = 0;
         this.acknowledgmentOfReceipt = 0;
         this.departureNotice =0;   
         this.date = moment(moment().subtract('Y',18)).format('YYYY-MM-D');
         this.resetErrors();
    },
    resetErrors(){
      this.errors.name='';
        this.errors.last_name='';
        this.errors.email='';
        this.errors.phone=''; 
        this.errors.dayOfBirdth='';
    },
    saveContact(){ 
      if(this.validate()){ 
        this.loading = true;
          var params = {
         name:this.contactName,
         last_name:this.contactLastname,
         email:this.contactEmail,
         client_id: this.userId,
         professional_degree:this.professionalDegree,
         job_title:this.jobTitle,
         date_of_birth:this.dayOfBirdth,
         main:this.contactIsMain,
         phone:this.contactPhone,
        order_capture_confirmation:this.orderCaptureConfirmation,
        bill_of_lading:this.billOfLading,
        acknowledgment_of_receipt:this.acknowledgmentOfReceipt,
        departure_notice:this.departureNotice,
        fiscal_address:this.fiscalAddress,
       }    
       axios.post("/contact-client/save",params)
            .then((response) => {
             this.loading=false;
             if (response.data?.result>1) {
                 console.log( response.data)
             } else {  
              this.initContact() 
              this.$swal({
                    title: response.data.message,
                    icon: "success",
                    type: "succes"
                }) 
                this.initContact() 
                  this.$emit('updatedContactList',true)
              // this.refreshContacs()
               }  
           })
           .catch((e) => {
             this.loading=false;
            console.log(e); 
           });  
      }  
    }, 
    /*
    refreshContacs(){
      this.loading
      axios
        .post("contact-client/list", {
          client: localStorage.getItem('id')
        })
        .then((response) => { 
          if (response.data.result == 1) {
            this.contacts = response.data.data;
            this.$emit('updateContactList', this.contacts)
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => (this.dialogLoading = false));
    }, */
   /* deleteContact(contact){
      this.loading=true;
      var params = {
        contact:contact.id, 
        client:contact.client_id, 
      }  
      axios
        .post("contact-client/delete", params)
        .then((response) => { 
          if (response.data.result == 1) {
            this.successText= response.data.message;
            //this.refreshContacs()
          } 
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => (this.loading = false));
    },*/
    _getContactOptions(){
      axios.post("/cover/contact-options", { customer_id:  this.customer })
           .then((response) => {
            if (response.data?.success==false) {
                console.log( response.data)
            } else { 
              this.professionalDegreeOptions = response.data.PROFESSIONAL_DEGREE.splice(1);
              this.jobTitles = response.data.JOB_TITLES.splice(1); 
            }  
          })
          .catch((e) => {
            console.log(e); 
          }); 
    },
    rules(fieldName,fieldData,required,type=1,min=2,max=30){//=false,type='1' string, 2 phone, 3 email, 4 date
      if(required){
          this.requiredField(fieldData,fieldName)   
        }
        switch (type) {
          case 1:this.validateMinMax(fieldName,fieldData,min,max); break;
          case 2:this.validatePhoneNumber(fieldName,fieldData); break; 
          case 3:this.validateEmail(fieldName,fieldData); break; 
          case 4:this.validateDate(fieldName,fieldData); break; 
        }
        return true;  
    },
    validate(){
       this.rules('name',this.contactName,true)
       this.rules('last_name',this.contactLastname,true);
       this.rules('email',this.contactEmail,true,3);
       this.rules('phone',this.contactPhone,true,2,10,10);
       this.rules('dayOfBirdth',this.dayOfBirdth,true,4);
       this.rules('fiscal_address',this.fiscalAddress,true,1,2,300);
       if(this.errors.name ) return false;
      if(this.errors.last_name ) return false;
      if(this.errors.email ) return false;
      if(this.errors.phone ) return false;
      if(this.errors.dayOfBirdth ) return false; 

      return true;
    }, 
    validateDate(fieldName,fecha) {  
      if (!moment(fecha, 'DD-MM-YYYY', true).isValid()) {
        this.errors[fieldName] = "La fecha no tiene el formato adecuado" 
      }else{
        const fechaIngresada = moment(fecha, 'DD-MM-YYYY');
        const fechaActual = moment();
        const fechaHace18Anos = fechaActual.clone().subtract(18, 'years');
        if(fechaIngresada.isBefore(fechaHace18Anos)){
          this.errors[fieldName] = "" 
        }else{
          this.errors[fieldName] = "La fecha de nacimiento debe ser para mayores de 18 años" 
        }
      }  
    }, 
    validatePhoneNumber(fieldName,phoneNumber) { 
            var phoneRegex =/^\d{10}$/; //teléfono tiene 10 dígitos
            this.errors[fieldName]=phoneRegex.test(phoneNumber)?'':'Este campo solo acepta numeros (10 digitos)' 
        },
    validateEmail(fieldName,email) {  
            var emailRegex = /.+@.+\..+/; 
            this.errors[fieldName] = emailRegex.test(email) ?'':"formato incorrecto de correo electronico"
        },
    validateMinMax(fieldName,fieldData,min,max){
      if( (fieldData.length < min || fieldData.length > max)){
        this.errors[fieldName]= fieldData.length < min ? 'Este campo requiere almenos '+min+' caracteres ':' Este campo debe ser menor a '+max+" caracteres";
             }else{  
            this.errors[fieldName]=''; 
            } 
    },
    requiredField(data,fieldName){ 
      this.errors[fieldName] = data === ""?'Este campo es requerido':'';
    }
  },
})
</script>