<template>
  <v-card>
    <v-toolbar :dark="true">
      <v-toolbar-title>Ubicación</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn class="right" @click="$emit('close')" icon>
        <v-icon>mdi-close-circle</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text class="pt-5">
      <div class="row d-flex align-items-center">
        <div class="col-12 col-md-8 text-left">
          <h5 class="font-weight-bold">Domicilio fiscal:</h5>
          <h3 style="font-size: 16px">
            {{ customerInformation.ORT01 }}
            {{ customerInformation.STREET }}
            {{ customerInformation.HOUSE_NUM1 }}
            {{ customerInformation.CITY2 }}
            CP. {{ customerInformation.POST_CODE1 }}
          </h3>
        </div>
        <div class="col-12 col-md-4 text-center">
          <v-btn
            v-if="locationData.showBtnCoordinates && !$hasAnyRole(['Customer', 'CustomerPR'])"
            class="text-capitalize custom-red-button font-size-x w-100"
            style="max-width: 350px"
            color="error"
            dark
            small
            rounded
            @click="$emit('setMapLocation')"
          >
            {{ locationData.updateLocation ? "Actualizar" : "Registrar" }} Coordenadas
          </v-btn>
        </div>
      </div>

      <div class="py-5">
        <div v-if="!locationData.showBtnCoordinates & (locationData.geolocation.lat == '') && locationData.geolocation.lng == ''">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
          <small class="text-secondary">Cargando mapa</small>
        </div>
        <div v-else-if="locationData.geolocation.lat != '' && locationData.geolocation.lng != ''">
          <GmapMap
            :center="locationData.geolocation"
            :zoom="18"
            style="width: 100%; height: 330px"
          >
            <GmapMarker :position="locationData.geolocation" @click="center = center" />
          </GmapMap>
        </div>
        <div v-else class="text-center">
          <small class="text-secondary">No se ha registrado una ubicación</small>
        </div>
      </div>

      <div class="text-left">
        <span v-if="locationData.geolocation.updatedAt != ''"
          >Última actualización {{ formatDate(locationData.geolocation.updatedAt) }}</span
        >
      </div>
      <div v-if="locationData.showBtnOptions" class="d-flex justify-content-center">
        <v-btn
          v-if="locationData.geolocation.lat != '' && locationData.geolocation.lng != ''"
          @click="$emit('acceptLocation')"
          color="success"
          class="ma-2 white--text"
          style="
            border-width: 2px;
            background: #4caf50 0% 0% no-repeat padding-box;
            border: 2px solid #05741f !important;
          "
        >
          Confirmar
          <v-icon right dark> mdi-check-bold </v-icon>
        </v-btn>
        <v-btn
          @click="$emit('cancelLocation')"
          color="red"
          class="ma-2 white--text"
          style="
            border-width: 2px;
            background: #e20028 0% 0% no-repeat padding-box;
            border: 2px solid #890707 !important;
          "
        >
          Cancelar
          <v-icon right dark> mdi-close </v-icon>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";
export default {
  name: "LocationMaps",
  props: {
    locationData: {
      type: Object,
      default: () => ({
        geolocation: {
          lat: '',
          lng: '',
          updatedAt: ''
        },
        updateLocation: false,
        showBtnCoordinates: false,
        showBtnOptions: false,
      }),
    },
    customerInformation:{
      type: Object,
      required: true
    }
  },
  methods: {
    formatDate(value) {
      return moment(value).format("DD-MMMM-YYYY");
    },
  },
};
</script>