<template>
  <div class="d-flex align-center" :class="`justify-${justify}`"> 
		<v-icon v-if="icon" :class="iconClass"  color="black" size="30px">{{ icon }}</v-icon>
		<h2 class="text-subtitle-2 text-center text-md-h5 ma-0 text-uppercase font-weight-bold" :class="titleClass">
			{{ text }}
		</h2>
	</div>
</template>
<script>
export default {
	name: 'iconTitle',

	props: {
		text: {
			type: String,
			required: true,
		},
		icon: {
			type: String,
			default: null,
		},
		iconClass: {
			type: String,
			default: ''
		},
		titleClass: {
			type: String,
			default: ''
		},

		justify: {
			type: String,
			default: 'center'
		}
	}
}
</script>