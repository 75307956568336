<template>
  <div class="container-sm mt-5">
    <v-container white lighten-5 fluid class="card-rounded">
      <v-data-table
        :headers="headers"
        :items="items"
        :footer-props="footerProps"
        :options="dataTableOptions"
        :mobile-breakpoint="null"
        :loading="isLoading"
        class="elevation-2 table-renglonaje"
      >
        <template v-slot:[`item.NOMGER`]="{ item }">
          <span style="cursor: pointer; text-decoration: underline" @click="validateRoleToSend(item.CODGER)">{{ item.NOMGER }}</span>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "@/plugins/axios";

export default {
  data() {
    return {
      headers: [{ text: "", value: "NOMGER", align: "center" }],
      items: [],
      isRegional: false,
      isLoading: true,
      footerProps: {
        itemsPerPageOptions: [10, 14, 24, 34, { text: "Todas", value: -1 }],
        itemsPerPageText: "Mostrar en grupo de:",
        showCurrentPage: false,
        pageText: "Desplegado {0} / {1}",
      },
      dataTableOptions: {
        itemsPerPage: 24,
        pagination: {
          enabled: true,
          options: {
            numeric: true,
          },
        },
      },
    };
  },
  mounted() {
    if (this.$route.meta.role === "Regional") {
      this.isRegional = true;
    }

    this.setHeader();
    this.getData();
  },
  methods: {
    async getData() {
      const paramsPetition = this.paramsGetDataPetition();
      try{
        let response = await axios.get(paramsPetition.path, {
          params: {
            VTWEG: this.user.VTWEG,
            USERID: paramsPetition.employeeNumber,
          },
        });
        this.items = response.data.data;
        this.isLoading = false;
        this.desactiveLoader();
      }catch(error){
        this.desactiveLoader();
      }
    },
    setHeader() {
      this.headers[0].text = "Gerentes Regionales";
      if (this.isRegional) {
        this.headers[0].text =
          this.user.VTWEG === "PR" ? "Subgerentes" : "Gerentes";
      }
    },
    paramsGetDataPetition() {
      let path = "/structure/director";
      let employeeNumber = "";
      if (this.isRegional) {
        employeeNumber = this.$hasAnyRole(['IELRegManager']) ? '' : "0" + this.user.employee_number;
        const currentCode = this.$getLocalStorageRegional();
        employeeNumber = currentCode ? currentCode : employeeNumber;
        path = "/structure/manager-regional";
      }
      return {'employeeNumber': employeeNumber, 'path': path}
    },
    sendManagerHome(code) {
      this.$setLocalStorageManager(code);
      this.$router.push({ name: "ManagerHome" });
    },
    sendRegionalManagerHome(code) {
      this.$setLocalStorageRegional(code);
      this.$router.push({ name: "StructureRegionalManager" });
    },
    validateRoleToSend(code) {
      !this.isRegional
        ? this.sendRegionalManagerHome(code)
        : this.sendManagerHome(code);
    },
    ...mapActions({
        desactiveLoader: 'loader/desactiveLoader',
        activeLoader: 'loader/activeLoader'
    }),
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  beforeRouteEnter(to, from, next) {
      //se ejecuta el loader solo si venimos desde el componente SignIn
      next(vm => {
          if (from.name === 'SignIn') {
              vm.activeLoader();
          }
      });
  },
};
</script>

<style>
</style>