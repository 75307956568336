<template>
    <div id="contenedor">
      <v-container fluid class="white lighten-5" style="padding-left: 3%;padding-right: 3%;">

        <v-row>
          <v-col md="1" style="margin-top: 2.2em;">
            <a href="/order-status"><v-icon color="black">mdi-arrow-u-left-top</v-icon></a>
          </v-col>
          <v-col md="11">
          
            <div class="d-flex align-items-center">
                <v-card class="mx-auto rounded-lg elevation-4 ma-3 w-100" outlined :style="'border: 4px solid black; background-color:transparent;' ">
                  <v-row>
                    <v-col cols="12" sm="12" md="12" lg="11">
                      <v-list-item-content>
                          <div class="text-general-module mb-0">
                            <v-icon color="black">mdi-card-bulleted</v-icon>
                            <span>&nbsp;ESTADO DE PEDIDOS</span>
                          </div>                    
                      </v-list-item-content>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="1">
                      <a href="/order-status"><img src="/img/alert-rhombus-outline.svg"></a>
                      <a href="/order-status"><img src="/img/Grupo2511.svg"></a>
                    </v-col>
                  </v-row>
                </v-card>

                <menu-print
                    :nameCSV="'cartera'"
                    :existExcel="false"
                    @generarDocumento="generarDocumento">
                </menu-print>
            </div>
          </v-col>
        </v-row>
        
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-card class="mt-5">
              <v-card-text>
                <v-simple-table class=" my-t">
                  <template v-slot:default>
                    <thead class="th-bg">
                      <tr>
                        <td style="text-align: left;">Pedido SAP: {{ facturaCurrent }}</td>
                        <td style="text-align: left;">Pedido de compra: MLVENT 06</td>
                        <td style="text-align: left;">Fecha: 07/Jun/2023 9:43 am</td>
                        <td style="text-align: left;">Monto neto s/IVA $29,817.75</td>
                        <td style="text-align: left;">Peso: 61.73 Kg</td>
                        <td style="text-align: left;">Volumenes: 0534 m3</td>
                        <td></td>
                        <td></td>
                      </tr>
                    </thead>
                    <thead class="table-heading">
                      <tr>                      
                        <th class="text-center text-dark t-shadow">Código</th>
                        <th class="text-center text-dark t-shadow">Descripción</th>
                        <th class="text-center text-dark t-shadow">Peso</th>
                        <th class="text-center text-dark t-shadow">Volumen</th>
                        <th class="text-center text-dark t-shadow">Clave</th>
                        <th class="text-center text-dark t-shadow">Cantidad Solicitada</th>
                        <th class="text-center text-dark t-shadow">Precio Neto s/IVA</th>
                        <th class="text-center text-dark t-shadow">Subtotal</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-if="!loadingFactura">
                        <tr v-for="(factura, index) in facturas" :key="index">
                          <td>
                            <v-row>
                                <v-col>{{ ( index + 1 ) }}</v-col>
                                <v-col>
                                    <a>{{ factura.MATNR }}</a>
                                </v-col>
                            </v-row>
                          </td>
                          <td>{{ factura.ARKTX }}</td>
                          <td>{{ factura.PNETO }} kg</td>
                          <td>{{ factura.VOLUM }} m3</td>
                          <td><a class="a-modal" @click="openModal(factura)">SKU {{ factura.MATNR }}</a></td>
                          <td>{{ factura.KWENG }}</td>
                          <td>${{ formatPrice( factura.NETWR1 ) }}</td>
                          <td>
                              <span class="capturado">${{ formatPrice( factura.SUBTO ) }}</span>
                          </td>
                        </tr>
                      </template>
                      <template v-else>
                        <tr>
                          <td colspan="8">
                            <br>
                            <v-progress-linear
                            indeterminate
                            color="cyan"
                            ></v-progress-linear><br>
                            Cargando... Por favor espere
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <LegacyFooter></LegacyFooter>
      </v-container>

      <!-- Model Detalles -->
      <v-dialog v-model="dialog" persistent max-width="1200px"> 
              <v-card>
                <v-card-title class="d-flex justify-content-end">
                    <v-card-actions>
                        <v-btn icon large color="red red-darken-4" @click="dialog = false; fileURL = ''">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card-title>

                <v-card-text>
                    <div class="row">
                        <div class="col-12 text-left">                            
                            <v-btn :disabled="fileURL == ''"  v-bind:href="fileURL" depressed class="elevation-1">
                                Consultar Ficha Técnica
                                <v-icon class="ml-2" color="red red-darken-4">
                                    mdi-folder-outline
                                </v-icon>
                            </v-btn>
                        </div>
                    </div>
                    <div id="divID">
                        <div class="row">
                            <div class="col-12 text-center">
                                <div>
                                    <v-progress-circular v-if="loader"
                                        indeterminate
                                        color="primary"
                                        class="mb-5"
                                    ></v-progress-circular>
                                    <img v-else :src="image" style="width:150px" class="mb-5" alt="">
                                </div>
                                <h6>{{selectMaterial.MATNR}} {{selectMaterial.ARKTX}}</h6>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <v-simple-table class="mt-5 elevation-2 table-renglonaje">
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-center" v-for="(detalles, index) in selectMaterial.DETXMES.slice().reverse()" :key="index"><b>{{formatMes(detalles.NUMMES)}} / {{detalles.NUMANO}}</b></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="text-center" v-for="(detalles, index) in selectMaterial.DETXMES.slice().reverse()" :key="index">{{detalles.FKIMG}}</td>                                                
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </div>
                        </div>
                        <div class="row">                        
                            <div class="col-12">
                                <v-simple-table class="mt-5 elevation-2 table-renglonaje">
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <td colspan="5" style="border-bottom: 1px solid #e0e0e0" class="text-center"><b>Diagramas, manuales e Información Técnica</b></td>
                                            </tr>
                                            <tr>
                                                <th class="text-center">Manual o diagrama de  Instalación</th>
                                                <th class="text-center">Diagrama de  Relaciones</th>
                                                <th class="text-center">Boletines de Servicio</th>
                                                <th class="text-center">Manual Técnico de Reparación</th>
                                                <th class="text-center">Hoja de Seguridad</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="text-center" style="width: 20%">                                                    
                                                    <v-icon style="font-size:45px">
                                                        mdi mdi-folder-download
                                                    </v-icon>
                                                </td>
                                                <td class="text-center" style="width: 20%">
                                                    <v-icon style="font-size:45px">
                                                        mdi mdi-folder-download
                                                    </v-icon>
                                                </td>
                                                <td class="text-center" style="width: 20%">
                                                    <v-icon style="font-size:45px">
                                                        mdi mdi-folder-download
                                                    </v-icon>
                                                </td>
                                                <td class="text-center" style="width: 20%">
                                                    <v-icon style="font-size:45px">
                                                        mdi mdi-folder-download
                                                    </v-icon>
                                                </td>
                                                <td class="text-center" style="width: 20%">
                                                    <v-icon style="font-size:45px">
                                                        mdi mdi-folder-download
                                                    </v-icon>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </div>
                        </div>
                        <div class="row d-flex justify-content-end">
                            <div class="col-xs-12 col-md-6">
                                <p class="text-conf">Información confidencial Prohibida su reproducción o divulgación total o parcial, así como su uso o aprovechamiento sin autorización escrita de IUSA.</p>
                            </div>
                        </div>
                    </div>
                </v-card-text>
              </v-card>
          </v-dialog>
          <!-- End Model Detalles -->
    </div>
  </template>
    
    <script>
    import axios from "@/plugins/axios";
    import { mapGetters } from "vuex";
    import html2pdf from 'html2pdf.js'
    import MenuPrint from '@/components/MenuPrint'
    import LegacyFooter from "@/views/seller/components/LegacyFooter.vue"
    
    export default {
      name: "OrderDetail",
      components: {LegacyFooter,MenuPrint},
      props: [],
      data() {
        return {
          dialog: false,
          facturas:[],
          selectMaterial: {},
          facturaCurrent:null,
          dateCurrent: null,
          isMobile: false,
          loadingFactura:true,
          months: [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre",
          ],
          monthCurrent: '',
          fileURL: '',
          image: '',
          loader: true,
        };
      },
    
      async mounted() {
  
        let current_date = new Date();

        this.facturaCurrent = this.$route.params.id;
      
        this.dateCurrent =
          current_date.getDate() +
          " de " +
          this.months[current_date.getMonth()] +
          " del " +
          current_date.getFullYear();
  
        this.monthCurrent = this.months[current_date.getMonth()].slice(0,3);
  
        window.addEventListener('resize', this.checkMobile);
        this._getDataFactura();
      },
    
      computed: {
        ...mapGetters({
          authenticated: "auth/authenticated",
          user: "auth/user",
        }),
      },
      beforeDestroy() {
        window.removeEventListener('resize', this.checkMobile);
      },
      methods: {
        checkMobile() {
          this.isMobile = window.innerWidth <= 600;
        },
        _getDataFactura() {
          
          axios
            .post("order-status/detail", {
              fact: this.facturaCurrent,
            })
            .then((response) => {
              this.facturas = response.data;
              this.loadingFactura = false;
            })
            .catch((error) => {
              console.error(error);
            })
            .finally(() => (this.dialogLoadingDataClient = false));
        },
        formatPrice(value) {
          return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        formatPor(value) {
          var valueXtra = value.split('.');
          return valueXtra[0] + '.' + valueXtra[1].slice(0,2);
        },
        generarDocumento(){
          var element = document.querySelector('html');
          var opt = {
              margin:       1,
              filename:     'detalle-de-pedido-'+this.facturaCurrent+'.pdf',
              image:        { type: 'jpeg', quality: 0.98 },
              html2canvas:  { scale: 2 },
              jsPDF: { unit: 'px', format: [1400,1600], orientation: 'landscape' }
          };

          html2pdf().set(opt).from(element).save(); 
        },
        openModal(material){
            if (material) { 
                this.selectMaterial = material 
                this.getImge(material.MATNR)
                this.getFileDownload(material.MATNR)
            }
            this.dialog = true
        },
        getImge(codigo_material) {
            this.loader = true
            axios.get("https://bancodeimagenes.iusa.com.mx/api/listDropboxNew?code="+codigo_material+"&quant=1&view=0", {
                headers: {
                    'Content-Type':'application/json',
                    'api-key':'$2y$10$PtV2yrGrt/CwoVlqGDroiOyoUSq581Pq.6NVyAX2vQJUBdE21gxKC',
                    'Authorization':'Bearer token',
                }
            })
            .then((response) => {
                if(response.data[0].path) {
                    this.image = response.data[0].path
                    this.loader = false
                }                
            })
        },
        getFileDownload(codigo_material) {
            axios.get("https://bancodeimagenes.iusa.com.mx/api/allFileDropboxPdf?code="+codigo_material+"&type=6", {
                headers: {
                    'Content-Type':'application/json',
                    'api-key':'$2y$10$PtV2yrGrt/CwoVlqGDroiOyoUSq581Pq.6NVyAX2vQJUBdE21gxKC',
                    'Authorization':'Bearer token',
                }
            }).then((response) => {
                if(response.data[0].path) {
                    this.fileURL = response.data[0].path
                }        
            }).catch((error) => {
                this.fileURL = ''
                console.log(error);                       
            });
        },
      },
      watch: {},
    };
    </script>
    
  <style scoped>
    .a-modal{
      cursor: pointer;
      text-decoration: underline;
    }
    .capturado{
      color: #E0A800;
    }
    .btn-danger{
        background-color:red !important;
        color:white !important;
    }
    .custom-input .v-input__control {
      border-radius: 2em; /* Ajusta este valor según tu preferencia */
      border: 2px solid #1976d2; /* Cambia el color del borde si es necesario */
    }

    .custom-input .v-label {
      transform: translateY(-10px) scale(0.75);
    }

    .custom-input .v-text-field__prepend-inner {
      color: #1976d2; /* Cambia el color si es necesario */
    }
    .modal-btn-close{
      background: white;
      border-radius: 50%;
      color: black;
      cursor:pointer;
      padding: 0.5em;
      font-weight:bold;
      text-decoration: none;
    }
    .modal-card .col{
      border:solid 1px black;
    }
    .modal-header{
      background:black !important;
      color:white !important;
    }
    .modal-title{
      background: rgb(245,245,245);
    }
  
  .btn-cg{
    font-weight: bold;
  }
  .btn-active-cg{
    background: yellow !important;
  }
  
  /*Lo nuevo*/
  .card-p {
    margin: 0;
    padding: 0;
    color: #000;
  }
  
  .my-table {
    width: 100%;
  }
  
  .table-heading {
    font-weight: bold;
    font-size: 0.75rem;
    background: rgb(245, 245, 245);
    color: #000;
    border-width: 1px 1px 3px 1px;
    border-color: rgb(228, 231, 234);
    border-bottom-color: rgb(112, 112, 112);
  }
  
  .table-heading tr {
    color: #000;
  }
  .menu-f button{
    text-transform: none !important;
  }
  td {
      padding: 10px;
    }
  .shadow-td{
    width: 7em;
  }
  .th-bg{
    background: linear-gradient(to bottom, #580010, #B00020);
    color: white;
  }
  </style>