<template>
  <div> 
    <v-container fluid>
   <template>
     <v-row>
     <v-col>
         <v-card class="mx-auto rounded-lg elevation-4 ma-3 mb-7" outlined
             :style="'border: 4px solid green; background-color:transparent;'">
             <v-list-item-content>
                 <div class="text-quoter-module" style="color:green">
                     <v-icon color="green">mdi-cart-arrow-down</v-icon>
                     Historial Cotizaciones
                 </div>
             </v-list-item-content>
         </v-card>
     <v-card cols="12" class="pa-xs-2 pa-md-2 pa-lg-2 mt-xs-0 mt-md-0 mt-lg-0 mb-7">
       <v-card-title>
           <v-btn 
             color="success"
             size="x-large"
             to="/cotizacion/crear">
             <v-icon light>mdi-cart-plus</v-icon>
             Crear Cotización
           </v-btn>
       </v-card-title>
       <v-row>
         <v-col md="6"></v-col>
         <v-col md="6">
           <v-text-field
             v-model="search"
             append-icon="mdi-magnify"
             label="Buscar"
             single-line
             hide-details
           ></v-text-field>
         </v-col>
       </v-row>
       <br>
       <v-data-table
         dense
         :headers="headers"
         :items-per-page="10"
         :items="data"
         :search="search"
         :loading="loadingHistoryOrders"
         loading-text="Cargando información"
         item-key="id"
         no-data-text="No hay datos disponibles"
       >
       <template v-slot:[`item.total`]="{ item }">
         <span>${{numberFormat(item.total)}}</span>
       </template>
       <template v-slot:[`item.name`]="{ item }">
         <a @click="getHistoryQuoters(item)">{{item.name}}</a>
       </template>
       <template v-slot:[`item.created_at`]="{ item }">
         <span>{{getFormattedDate(item.created_at)}}</span>
       </template>
       <template v-slot:[`item.estatus`]="{ item }">
         <span v-if="item.estatus == 2">Guardado</span>
       </template>
       <template v-slot:[`item.validation`]="{ item }">
         <span>{{getFormattedDate(item.validation)}}</span>
       </template>
       <template v-slot:[`item.quoter_type`]="{ item }">
         <span v-if="item.quoter_type == '1'">Cliente</span>
         <span v-if="item.quoter_type == '2'">Cliente/Cliente</span>
       </template>
       <template v-slot:[`item.id`]="{ item }">
         <v-tooltip top>
           <template v-slot:activator="{ on, attrs }">
           <v-btn
              style="margin-left: 5px"
              class="ma-1 elevation-0" 
              rounded
              color="error" 
              small
              v-bind="attrs" 
              v-on="on"
              @click="createPDF(item)"
              > 
             <v-icon>
                mdi-file-pdf-box
           </v-icon>
           </v-btn>
         </template>
         <span>Descargar Cotización PDF</span>
         </v-tooltip>
         <v-tooltip top>
           <template v-slot:activator="{ on, attrs }">
           <v-btn
              v-if ="item.id != ''"
              style="margin-left: 5px"
              class="ma-1 elevation-0" 
              rounded
              color="secondary" 
              small
              v-bind="attrs" 
              v-on="on"
              @click="senDEmail(item)" 

              > 
              <!--:disabled="item.quoter_type == '2'" -->
             <v-icon>
             mdi-email-arrow-right-outline
           </v-icon>
           </v-btn>
         </template>
         <span>Enviar por Correo</span>
         </v-tooltip>
         <v-tooltip top>
           <template v-slot:activator="{ on, attrs }">
            <v-btn 
              style="margin-left: 5px"
              class="ma-1 elevation-0" 
              rounded
              color="warning" 
              small
              v-bind="attrs" 
              v-on="on"
              @click="QuoterCartEdit(item.id,item.doctype)"
              >
               <v-icon>mdi-file-edit-outline</v-icon>
              </v-btn>
             </template>
             <span>Editar Cotización</span>
         </v-tooltip>
         <v-tooltip top v-if="user.VTWEG == 'DT'">
           <template v-slot:activator="{ on, attrs }">
              <v-btn
              style="margin-left: 5px"
              class="ma-1 elevation-0"
              color="success"
              rounded
              small
              v-bind="attrs" 
              v-on="on"
              @click="generateOrder(item)"
              >
             <v-icon>mdi-checkbox-marked-circle-plus-outline</v-icon>
           </v-btn>
         </template>
         <span>Generar Pedido</span>
         </v-tooltip>
         <v-tooltip top v-if="user.VTWEG == 'PR'">
           <template v-slot:activator="{ on, attrs }">
              <v-btn
              style="margin-left: 5px"
              class="ma-1 elevation-0"
              color="success"
              rounded
              small
              v-bind="attrs" 
              v-on="on"
              @click="generateOrderPR(item)"
              >
             <v-icon>mdi-checkbox-marked-circle-plus-outline</v-icon>
           </v-btn>
         </template>
         <span>Generar Pedido</span>
         </v-tooltip>
         <v-tooltip top>
           <template v-slot:activator="{ on, attrs }">
           <v-btn
                 style="margin-left: 5px"
                 class="ma-1 elevation-0"
                 dark
                 rounded
                 color="red darken-2"
                 small
                 @click="deleteItem(item)"
                 v-bind="attrs" 
                 v-on="on"
               >
                 <v-icon style="color: #fff !important">mdi-delete</v-icon>
               </v-btn>
             </template>
             <span>Eliminar Cotización</span>
         </v-tooltip>
         </template>
       </v-data-table>
     </v-card>
     <v-card>
       <v-card-title>
           Historial
       </v-card-title>
       <v-card-text>
         <v-row>
         <v-col md="6"></v-col>
         <v-col md="6">
           <v-text-field
             v-model="search"
             append-icon="mdi-magnify"
             label="Buscar"
             single-line
             hide-details
           ></v-text-field>
         </v-col>
       </v-row>
       <br>
       <v-data-table
         dense
         :headers="headers_2"
         :items-per-page="10"
         :items="data1"
         :search="search"
         item-key="id"
         :loading="loading"
         loading-text="Cargando información"
         no-data-text="No hay datos disponibles"
       >
       <template v-slot:[`item.created_at`]="{ item }">
         <span>{{getFormattedDate(item.created_at)}}</span>
       </template>
       <template v-slot:[`item.estatus`]="{ item }">
         <span v-if="item.estatus == 2 && item.validation >= new Date() ">Guardado</span>
         <span v-if="item.estatus == 3">Genero Pedido</span>
       </template>
       <template v-slot:[`item.validation`]="{ item }">
         <span>{{getFormattedDate(item.validation)}}</span>
       </template>
       </v-data-table>
       </v-card-text>
     </v-card>
   </v-col>
 </v-row>
     <!-- Dialog loading -->
     <v-row justify="center">
       <v-dialog v-model="loading" hide-overlay persistent width="300">
         <v-card color="primary" dark>
           <v-card-text>
             Cargando
             <v-progress-linear
               indeterminate
               color="white"
               class="mb-0"
             ></v-progress-linear>
           </v-card-text>
         </v-card>
       </v-dialog>
     </v-row>
     <loading-bar :capturedDialogLoading="dialogLoading"></loading-bar>
     <!-- Dialog loading -->
     <!-- Dialog loading -->
     <v-dialog
     v-model="dialog_del"
     persistent
     width="auto"
   >
     <v-card>
       <v-card-title class="text-h5">
       ¿ Realmente desea eliminar esta Cotización ?
       </v-card-title>
       <v-card-text></v-card-text>
       <v-card-actions>
         <v-spacer></v-spacer>
         <v-btn
           color="primary"
           variant="text"
           @click="dialog_del = false"
            >
           Cancelar
         </v-btn>
         <v-btn
           color="error"
           variant="text"
           @click="deleteOrderQuoter()"
         >
           Eliminar
         </v-btn>
       </v-card-actions>
     </v-card>
   </v-dialog>
   <!-- Dialog loading -->
   <!-- /. Dialog -->
   <v-dialog v-model="dialog_email" width="500" persistent>
       <v-card>
         <v-card-title class="text-h5 grey lighten-2">
           Enviar por Correo
         </v-card-title>
         <v-card-text>
           <p></p>
           <h6 style="text-align: left; font-weight: 700">
             Folio: {{ folio }}
           </h6>
           <p></p>
           <v-row>
             <v-col md="12">                
               <div class="form-group">
                 <label >Ingresar Email:</label>
                  <v-text-field
                  solo
                  v-model="emai_user"
                   >{{emai_user}}</v-text-field>
               </div>
             </v-col>
           </v-row>
         </v-card-text>
         <v-divider></v-divider>
         <v-card-actions>
           <v-btn color="error" text @click="close2()"> Cancelar </v-btn>
           <v-spacer></v-spacer>
           <v-btn color="success" text @click="sendEmailXMLPDF()">
             Enviar
           </v-btn>
         </v-card-actions>
       </v-card>
     </v-dialog>
     <!-- /. Dialog -->
       <!-- /. Dialog -->
   <v-dialog v-model="dialog_hist" width="auto" persistent>
       <v-card>
         <v-card-title class="text-h5 grey lighten-2">
           Historial 
         </v-card-title>
         <v-card-text>
           <p></p>
           <h6 style="text-align: left; font-weight: 700">
             Número de Cotización: {{ folio }}
           </h6>
           <p></p>
           <v-row>
             <v-col md="12">   
               <br>       
               <v-data-table 
                  dense 
                  :headers="headers_3" 
                  :items-per-page="10" 
                  :items="data2" 
                  :search="search" 
                  item-key="id"
                 no-data-text="No hay datos disponibles">
                 <template v-slot:[`item.created_at`]="{ item }">
                   <span>{{getFormattedDate(item.created_at)}}</span>
                 </template>
                 <template v-slot:[`item.validation`]="{ item }">
                   <span>{{getFormattedDate(item.validation)}}</span>
                 </template>
               </v-data-table>
             </v-col>
           </v-row>
         </v-card-text>
         <v-divider></v-divider>
         <v-card-actions>
           <v-spacer></v-spacer>
           <v-btn color="primary" text @click="close3()"> Cerrar </v-btn>
         </v-card-actions>
       </v-card>
     </v-dialog>
     <!-- /. Dialog -->
   </template>
 </v-container>

  </div>

</template>
<script>
import axios from "@/plugins/axios";
import LoadingBar from "./componets/LoadingBar.vue";
import {  mapGetters, mapActions } from "vuex";
import moment from 'moment'; 

export default {
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  components: {
    LoadingBar,
  },
  data: () => ({
    id_order: null,
    dialog_del: false,
    dialog_email:false,
    dialog_hist:false,
    selected: [],
    orderShop: [],
    order_array: [],
    disabledCount: 0,
    data: [],
    data1: [],
    data2: [],
    dialog: false,
    search: "",
    search1: "",
    message_order:"",
    buton_order:  false,
    emai_user: null,
    show: false,
    folio:null,
    dialogLoading: false,
    orderQuery:[],
    quoter_name:null,
    today2: moment().format('YYYY/MM/DD'),
    headers: [
      { text: "Fecha", align: "start", value: "created_at" },
      { text: "Número Cotización", align: "start", value: "name" },
      { text: "Número Partidas", sortable: false, value: "materials" },
      { text: "Importe Total", sortable: false, value: "total" },
      { text: "Estatus", sortable: false, value: "estatus" },
      { text: "Vigencia", sortable: false, value: "validation" },
      { text: "Tipo Cotización", sortable: false, value: "quoter_type" },
      { text: "Acciones", sortable: false, value: "id" },
    ],

    headers_2: [
      { text: "Fecha", align: "start", value: "created_at" },
      { text: "Número Cotización", align: "start", value: "name" },
      { text: "Orden de Compra", align: "start", value: "order_name" },
      { text: "Pedido", align: "start", value: "nu_pedido_o" },
      { text: "Número Partidas", sortable: false, value: "materials" },
      { text: "Importe Total", sortable: false, value: "total" },
      { text: "Estatus", sortable: false, value: "estatus" },
      { text: "Vigencia", sortable: false, value: "validation" },
    ],

    headers_3:[
      { text: "Fecha", align: "start", value: "created_at" },
      { text: "Número Cotización", align: "start", value: "name" },
      { text: "Número Partidas", sortable: false, value: "materials" },
      { text: "Importe Total", sortable: false, value: "total" },
      { text: "Vigencia", sortable: false, value: "validation" },
    ],

    loading: true,
    loadingHistoryOrders: true,
    pedidoSelect: null,
    pedidoInfo: null,
    pedidoImporte: null,
    item: {},
    customer_code:[],
    customerInfo:[],
    rutaPedidos: null,
    type:null,
  }),
  mounted() { 

    this.generarPDF();
    if (!this.$hasAnyRole(['Customer','CustomerPR'])){
      const currentCustomerInfo= JSON.parse(localStorage.getItem('currentCustomerInfo')) || [];
          if (currentCustomerInfo.length<1) {
          this.$router.push({name:'Home'})
          }    
          this.customerInfo = currentCustomerInfo;
          this.customer_code =  parseInt(currentCustomerInfo?.KUNNR,10);
           this.type = 'VE';
      }else{ 
        this.customer_code = this.user.custumer_code;
          this.type = 'CL';
      }


    let self = this;
    self.getPedidoHistory();
    self.getPedidoHistoryLast();
    //self.getSellerData();
   
    this.emai_user = this.user.email;
  },
  methods: {
    ...mapActions({
            sendToExcel: 'printer/sendToExcel',
            sendToPDF: 'printer/sendToPDF',
        }),
    numberFormat(value){ 
          let result = Number(value).toFixed(2);
        return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    generarPDF(){
            this.sendToPDF({active: true});
    },
    getFormattedDate(date) {
          return moment(date).format("YYYY-MM-DD")
    },
    getPedidoHistory() {
     // let type = "CL";
      axios
        .post("quoterHistory", {
         customer: this.customer_code,
         user_id: this.user.id,
         type:  this.type,
        })
        .then((response) => {
          this.data = response.data; 
        })
        .catch((error) => {
          //alert(error);
          this.loadingHistoryOrders = false
          console.log(error);
        })
        .finally(() => (this.loadingHistoryOrders = false));
    },
    getPedidoHistoryLast() {
      //let type = "CL";
      axios
        .post("quoterHistoryRead", {
         customer: this.customer_code,
         user_id: this.user.id,
         type:  this.type,
        })
        .then((response) => {
          this.data1 = response.data;

        })
        .catch((error) => {
          //alert(error);
          console.log(error);
        })
        .finally(() => (this.loading = false));
    },
    generarExcel(){

      let objectCSV = {
                'name': `renglonaje-${this.today2}`,
                'items': this.data,
                'showLabels': true
            }

            this.sendToExcel(objectCSV)
      
    },
    QuoterCartEdit(id,doctype){
    /* this.orderQuery.push({
        order_q:id
      }); */

      //this.$router.push("quoter-cart-edit/" + data);
      this.$router.push({
      name: "NewQuoterCartEdit",
      params: {quoter_id: id, doctype: doctype}
    });
    },
    deleteItem(item) {
    this.id_order = item.id;
    this.dialog_del = true;
    },
    deleteOrderQuoter(){
      //alert(this.id_order);
     axios
        .post("deleteOrderQuoter", {
          order_id:this.id_order
        })
        .then((response) => {
          this.data = response.data;
          this.dialog_del = false;
          this.$swal({
                  title: "Exitoso",
                  icon: "success",
                  type: "succes"
              }).then(function () {
                //this.getRecordatoriosHistory();
                location.reload();
              }
              );

        })
        .catch((error) => {
          this.dialog_del = false;
          //(error);
          console.log(error);
        })
    },
    createPDF(item){
      this.dialogLoading = true;
      axios
        .post("quoterPdf", 
        {
          order_id:item.id,
          customer: this.customer_code,
          VKORG: this.user.VKORG,
          VTWEG: this.user.VTWEG,
          VKBUR: this.user.VKBUR,
          quoter_type: item.quoter_type,
          customer_customer: item.customer_customer,
          customer_seller: item.customer_seller,
        },
       {responseType: 'blob'}
        )
        .then((response) => {
          //this.data = response.data;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'cotizacion.pdf'); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          //alert(error);
          console.log(error);
        })
        .finally(() => (this.dialogLoading = false, this.submitted= false));

    },
    senDEmail(item) {
      this.id_order = item.id;
      this.folio = item.name;
      this.quoter_type = item.quoter_type;
      this.dialog_email = true;
    },
    close2() {
      this.dialog_email = false;
    },
    close3() {
      this.dialog_hist = false;
    },
    getHistoryQuoters(item){
      this.id_order = item.id;
      this.folio = item.name;
      this.quoter_name = item.name;
      this.dialog_hist = true;
      this.getHistoryQuoterEdit();
    },
    getHistoryQuoterEdit(){
      axios
        .post("getHistoryQuoterEdit", {
         customer: this.customer_code,
         quoter_name: this.quoter_name,
        })
        .then((response) => {
          this.data2 = response.data;

        })
        .catch((error) => {
          //alert(error);
          console.log(error);
        })
        .finally(() => (this.loading = false));
    },
    sendEmailXMLPDF(){
      this.dialogLoading = true;
      this.dialog_email = false;
     axios
        .post("quoterPDFEmail", {
          email: this.emai_user,
          order_id:this.id_order,
          customer: this.customer_code,
          VKORG: this.user.VKORG,
          VTWEG: this.user.VTWEG,
          VKBUR: this.user.VKBUR,
          quoter_type: this.quoter_type,
        },
        )
        .then((response) => {

          this.message = response.data;
           
          this.$swal({
                    title:response.data.message,
                    icon: "success",
                    type: "succes"
                }).then(function () {
                  //this.getRecordatoriosHistory();
                  
                }
          );
        })
        .catch((error) => {
          //alert(error);
          console.log(error);
        })
        .finally(() => (this.dialogLoading = false, this.submitted= false));


    },
    generateOrder(item){
      //alert(item.doctype)
     axios
        .post("createOrder", {
          order_id: item.id,
          customer: item.customer_id,
          user_id: item.user_id,
          type: this.type,
          destiny: item.destiny,
          total: item.total,
          doctype: item.doctype,

        })
        .then(() => {
           this.$router.push({
            name: "NewShoppingCart",
            //name: "ShoppingCart",
            params: { doctype: 'PSDT', idq:item.id}
          });
        })
        .catch((error) => {
          //alert(error);
          console.log(error)
        })
        .finally();
     
    },
    generateOrderPR(item){
      
      axios
        .post("createOrderPR", {
          order_id: item.id,
          customer: item.customer_id,
          user_id: item.user_id,
          type: this.type,
          destiny: item.destiny,
          total: item.total,
          doctype: item.doctype,
        })
        .then(() => {
           this.$router.push({
            name: "NewShoppingCartPR",
            params: { doctype: 'PSIU', idq:item.id}
          });
        })
        .catch((error) => {
          //alert(error);
          console.log(error)
        })
        .finally();
     
    },
    /*getSellerData(){
      axios
        .post("getAsingSeller", {
          customer: this.customer_code,
          VKORG: this.user.VKORG,
          VTWEG: this.user.VTWEG,
        })
        .then((response) => {
          //this.data2 = response.data;
          console.log(response)

        })
        .catch((error) => {
        // alert(error);
        console.log(error);
        })
        .finally(() => (this.loading = false));
    },*/
    
  },
  watch: {
    items: {
            immediate: true,
            handler() {
                this.generarExcel()
            },
        },    
    },
};
</script>
  