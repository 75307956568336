<template>
<div>
    <v-card>
          <v-toolbar :dark="true" dense>
            <v-toolbar-title>CONTACTO</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn class="right" @click="close()" icon>
              <v-icon>mdi-close-circle</v-icon>
            </v-btn>
          </v-toolbar>
          <template v-if="dataContact != null">
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr class="custom-heading-contact">
                    <th class="text-left"> Nombre&nbsp;/&nbsp;email&nbsp;/&nbsp;Teléfono&nbsp;/&nbsp;Usuario</th>
                    <th class="text-left"> Confirmación&nbsp;de <br /> captura de pedido</th>
                    <th class="text-left">Aviso&nbsp;de <br />embarque</th>
                    <th class="text-left">Aviso&nbsp;de <br /> próxima entrega</th>
                    <th class="text-left">Acuse&nbsp;de&nbsp;recibo</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in dataContact" :key="index">
                    <td>
                      <div class="d-flex d-inline-block mb-1">
                        <span class="mr-1" v-if="item.main"> <v-icon color="#FFD75E">mdi-star</v-icon></span>
                        <span class="text-uppercase"> {{ item.name + " " + item.last_name }}</span>
                      </div>
                      <div class="d-flex d-inline-block mb-1">
                        <span class="mr-1"><v-icon style="color: red">mdi-email</v-icon></span>
                        <span>{{ item.email }} </span>
                      </div>
                      <div class="d-flex d-inline-block mb-1">
                        <span class="mr-1"> <v-icon style="color: red">mdi-cellphone-check</v-icon></span>
                        <span> {{ item.phone }}</span>
                      </div>
                      <div class="d-flex d-inline-block">
                        <span class="mr-1"><v-icon style="color: red">mdi-account</v-icon></span>
                        <span> {{ customerId }}</span>
                      </div>
                    </td>
                    <td> 
                      <v-icon v-if="item.order_capture_confirmation" color="green">mdi-email-check-outline</v-icon>
                      <v-icon v-else color="red">mdi-email-remove-outline</v-icon> 
                    </td>
                    <td>
                      <v-icon v-if="item.bill_of_lading" color="green">mdi-email-check-outline</v-icon>
                      <v-icon v-else color="red">mdi-email-remove-outline</v-icon>
                    </td>
                    <td>
                      <v-icon v-if="item.acknowledgment_of_receipt" color="green">mdi-email-check-outline</v-icon>
                      <v-icon v-else color="red">mdi-email-remove-outline</v-icon>
                    </td>
                    <td>
                      <v-icon v-if="item.departure_notice" color="green">mdi-email-check-outline</v-icon>
                      <v-icon v-else color="red">mdi-email-remove-outline</v-icon>
                    </td>
                  </tr>
                  <tr v-if="dataContact.length <= 0">
                    <td class="text-left pa-4" colspan="5">
                      <span> Sin resultados </span>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>
          <template v-else>
            <v-card-text>
              <v-progress-linear  indeterminate  color="cyan" ></v-progress-linear>
              Cargando contactos...</v-card-text>
          </template>
          <div class="pa-2 text-right">
            <v-btn color="error" @click="editContact()" :disabled="dataContact==null" class="mt-0 mt-sm-4 text-capitalize custom-red-button" dark   small >Agregar/Editar</v-btn>
          </div>
        </v-card>
</div>
</template>

<script> 
export default({
    name:'ChangeTownForm',
    props:['dataContact','customerId'],
    components:{ 
    }, 
    data(){
      return {
        state: 1, 
      } 
    },
    mounted(){ 
    },  
    methods:{
   
     close(){
      this.$emit('closeModal',true)
     },
     editContact(){
      this.$emit('editContact',true)
     }
    }
})
</script>