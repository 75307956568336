<template>
<div>
    <v-card>
        <v-skeleton-loader v-if="!showGraph"  type="card-avatar, article, actions"></v-skeleton-loader>
        <template v-else>
            <v-card-text>
                <p class="title">{{ "Tiempo de ejecución de servicios en "+this.modules[this.module-1].title }}</p>
                <apexchart ref="chart"  type="area" height="350" :options="chartOptions" :series="series"></apexchart> 
                <v-simple-table dense style="width: 30%;">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left" colspan="2"> Series </th>  
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,index) in series" :key="index"> 
                              <td>{{ item.name }}</td>
                              <td v-if="series.length>1"> 
                                <v-btn icon @click="deleteSerie(item)">
                                <v-icon color="red">mdi-trash-can-outline</v-icon>
                              </v-btn> 
                              </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>

            </v-card-text>
            <v-card-actions>
                <div class="d-flex flex-nowrap">
                    <div class="mr-3 pt-5">
                        <label for="">Fecha de inicio</label><br>
                        <input  :disabled="loading"  v-model="period.from" type="datetime-local">
                    </div>
                    <div class="mr-3 pt-5">
                      <label for="">Fecha final</label><br>

                        <input  :disabled="loading"  v-model="period.to" type="datetime-local">
                    </div>
                    <div class="mr-3 mt-3 ">
                        <v-select  :disabled="loading"  v-model="module" :items="modules" item-value="value" item-text="title"/>
                    </div>
                    <div class="pt-3">
                        <v-btn :disabled="loading || series.length == 8" :loading="loading" @click="getData()">
                            Agregar serie
                        </v-btn>
                    </div> 
                </div>
            </v-card-actions>
        </template>
    </v-card>
</div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import axios from '@/plugins/axios';
import moment from 'moment';
export default {
    components: {
        apexchart: VueApexCharts,
    },
    name:'frontMetrics',
    data() {
        return {
            series: [],
            module:1,
            modules:[
              {title:'Caratula',
              value:1},
              {
                title:'VAR',
              value:2
              },
            ],
            showGraph:false,
            chartOptions: {
                chart: {
                    type: 'line',
                    zoom: {
                        enabled: true,
                        type: 'x', // Permite el zoom horizontalmente
                        autoScaleYaxis: true
                    }
                },
                xaxis: {
                    type: 'category',
                    categories: [ ],
                },
                yaxis: {
                    title: {
                        text: 'Tiempo en Segundos'
                    }
                },
                title: {
                    text: ''
                }
            },
            loading: true,
            period: {
                from: moment().subtract(1,'hour').format('YYYY-MM-DD HH:mm'),
                to:  moment().format('YYYY-MM-DD HH:mm')
            },
            
        };
    },
    watch: { 
        module(){
            this.chartOptions.xaxis.categories = [];    
            this.series = [];
            this.getData();
        }
  },
    methods: {
      deleteSerie(item){
        this.series =  this.series.filter(serie => serie  != item); 
      },
        getData() { 
          this.loading=true;
            var params = {
                from: this.period.from.replace('T'," "),
                to: this.period.to.replace('T'," "),
                module: this.module,
            } 
       
            axios.post(`metrics/front/index`,params)
                .then((response) => {
                    this.chartOptions.xaxis.categories = response.data.MAPWS;  
                    this.series = [];
                    for (let index = 0; index < response.data.data.length; index++) {
                        this.series.push({
                        name: response.data.names[index],
                        data: response.data.data[index], 
                    }) 
                        
                    } 
                    
                })
                .catch((error) => {
                    this.loading = false;
                    this.showGraph=true;
                    console.log(error)
                })
                .finally(() => {
                    this.loading = false;
                    this.showGraph=true;
                }); 
        }
    },
    mounted() {
        this.getData();
    }
};
</script>
