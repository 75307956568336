<template>
    <v-card class="d-flex justify-center align-center">
      <div class="card--container p-4 text-center">
        <h5><b>Cierre de sesión</b></h5>
        <h6><b>Detectamos que IEL no está en uso</b></h6>
        <p class="h6">¿Quieres continuar con la sesión?</p>
        <div class="chronometer--container">
          <img src="@/assets/img/chronometer.jpg" class="w-100" alt="">
          <div class="chronometer--timer">
            {{ formattedTime }}
          </div>
        </div>
        <v-row class="justify-center">
          <v-col cols="auto">
            <button class="btn card--button card--button__color" @click="finishSession()">NO</button>
          </v-col>
          <v-col cols="auto">
            <button class="btn card--button green-button w-xs-100" @click="continueSession()">CONTINUAR</button>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </template>
  

<script>
export default {
    props: {
        timeInit: {
            type: Number,
            required: true
        },
        value: {
            type: Boolean,
            default: true,
        },
    },
    data(){
        return{           
            timer: null,
            time: this.timeInit
        }
    }, 
    mounted(){
        this.startTimer();

    },   
    methods: {
        startTimer() {  
            this.time = this.timeInit;          
            if (this.timer) clearInterval(this.timer);
            this.timer = setInterval(() => {
                if (this.time > 0) {
                    this.time--;
                } else {
                    this.finishSession();
                }
            }, 1000);
        },
        finishSession(){
            clearInterval(this.timer);
            this.isOpen = false;    
            this.$emit('finishSession');   
        },
        continueSession(){
            clearInterval(this.timer);
            this.isOpen = false;
            this.$emit('continueSession');          
        }
    },
    computed: {
        formattedTime() {
            const hours = Math.floor(this.time / 3600);
            const minutes = Math.floor((this.time % 3600) / 60);
            const seconds = this.time % 60;
            return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
        },
        isOpen:{
            get() {
                return this.value;
            },
            set(newVal) {
                this.$emit("input", newVal);
            }            
        }
    },
    watch:{
        isOpen(newVal){
            if(newVal){                
                this.startTimer();
            }
        }
    }
}
</script>
<style scoped>
  .btn {
    font-size: 14px !important;
  }
  .card--button {
    margin-top: 10px;
    font-weight: bold;
    color: #FFF;
  }
  .card--button__color {
    border: 1px solid #DC3545;
    background: transparent linear-gradient(180deg, #A82222 0%, #FF0000 100%) 0% 0% no-repeat padding-box;
  }
  .card--img {
    width: 100px;
    margin: 0 auto;
  }
  .chronometer--container {
    width: 150px;
    height: 150px;
    position: relative;
    margin: 0 auto;
  }
  .chronometer--timer {
    position: absolute;
    top: 40%;
    left: 0;
    right: 0;
    text-align: center;
    font-weight: bold;
  }
  .v-card {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  h6, p {
    color: #00000099;

}
</style>
