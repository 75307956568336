<template>    
        
    <v-container fluid class="white statement__container">

        <div class="d-flex align-items-center">            
            <v-card class="mx-auto rounded-lg elevation-4 ma-3 w-100" outlined :style="'border: 4px solid black; background-color:transparent;' ">
                <v-list-item-content>
                    <div class="text-general-module mb-0">
                        <v-icon color="black">mdi mdi-account-cash</v-icon>
                        ESTADO DE CUENTA DEL 
                        <span v-if="opt == 'byGroup'">GRUPO</span>   
                        <span v-else>CLIENTE</span>   
                    </div>
                </v-list-item-content>
            </v-card>
        </div>

        <!-- Menu -->
        <ButtonsrGroupCustomer :opt="opt"></ButtonsrGroupCustomer>        
        <!-- End Menu -->     

        <!-- Datos del cliente con grupo o sin grupo -->
        <DataCustomer class="mt-5" :dataClient="dataClient" :loader="loader"></DataCustomer>      

        <v-row>
            <v-col cols="12" class="pb-0">

                <div class="d-flex justify-content-between align-items-center cont-search">                    
                    <v-text-field style="max-width: 250px"
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Buscar Documento"
                        solo
                        hide-details>
                    </v-text-field>                    
                    
                    <div class="d-flex align-items-center">
                        <!-- <v-btn class="float-right mr-2 btn-multipago" @click="multipago = !multipago">
                            {{multipago ? 'Deshabilitar' : 'Habilitar'}} Multipago    
                        </v-btn> -->
                        <div class="mr-1">
                            <router-link :to="{ name: 'AccountStatus', params: { h: 1 } }">
                                <v-btn class="btn-multipago">
                                    Histórico de pagos
                                </v-btn>
                            </router-link> 
                        </div> 
                        <router-link to="/account-status">
                            <v-btn class="btn-multipago">
                                Habilitar Multipago    
                            </v-btn>
                        </router-link>                            
                    </div>                    
                </div>
                
                
                <div class="mb-0 h-title border-bootom-none py-2">
                    <div class="row d-flex align-items-center">
                       
                        <div class="col-12">
                            <span>Movimientos al {{dateCurrent}}</span>
                        </div>
                        
                    </div>                    
                </div>          
                                        
                <v-data-table                        
                        :headers="dataClient?.CTEGPO>0 ? headers : headers.slice(2,13)"
                        :items="filteredItems"
                        :search="search"
                        hide-default-header 
                        hide-default-footer                        
                        class="elevation-2 table-movimientos"     
                        :items-per-page="filteredItems.length"
                        :mobile-breakpoint="null"
                        :loading="loader"
                        loading-text="Cargando... Por favor espere"

                    > 
                    <template v-slot:header>
                        <thead>                                
                            <tr>
                                <th class="text-center py-2" v-for="header in ( dataClient?.CTEGPO>0 ? headers : headers.slice(2,13) )" :key="header.value" :align="header.align">
                                    {{ header.text }}   
                                    <v-select v-if="header.value == 'KUNNR'"
                                        :items="customersAll"
                                        label="Todos los Clientes"
                                        item-text="option"
                                        item-value="value"
                                        dense
                                        solo
                                        hide-details
                                        v-model="selectedKUNNR"
                                    ></v-select>

                                    <v-select v-if="header.value == 'STCD1'"
                                        :items="rfcAll"
                                        label="Todos los RFC"
                                        item-text="option"
                                        item-value="value"
                                        dense
                                        solo
                                        hide-details
                                        v-model="selectedSTCD1"
                                    ></v-select> 

                                    <v-select v-if="header.value == 'TIPO'"
                                        :items="documentsAll"
                                        label="Todos los Documentos"
                                        item-text="option"
                                        item-value="value"
                                        dense
                                        solo
                                        hide-details
                                        v-model="selectedTIPO"
                                    ></v-select>      
                                </th>
                            </tr>
                        </thead> 

                        <tfoot>
                            <tr>
                                <td :colspan=" dataClient?.CTEGPO>0 ? 11 : 9 " class="text-right foot-sub-total">
                                    <b>Subtotales:</b>
                                </td>
                                <td class="foot-sub-total">
                                    <b>${{sumPorVencer | formatCurrency}}</b>
                                </td>
                                <td class="foot-sub-total">
                                    <b style="color: red">${{sumVencido | formatCurrency}}</b>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="13" class="text-right foot-total">
                                    <b>Saldo total: ${{sumMonto | formatCurrency}}</b>
                                </td>
                            </tr>
                        </tfoot>
                    </template> 

                    <template v-slot:[`item.BELNR`]="{ item }">
                        <table>
                            <tbody>
                                <tr>                                    
                                    <td class="t-documento" width="90%">
                                        <!-- <input v-if="multipago" class="form-check-input mr-2" type="checkbox" v-model="selected" :value="item.BELNR" :id="`defaultCheck-${item.BELNR}`"> -->
                                        <label :for="`defaultCheck-${item.BELNR}`" :style="{'color': item.ESTAT == 'VENCIDO' ? 'red' : ''}">{{ item.BELNR }}</label>
                                    </td>
                                    <td class="t-documento" width="10%">
                                        <div class="d-flex align-items-center justify-content-center">
                                            <div class="icon-pnc" v-if="item.PNUEVOS != '0'">
                                                PNC
                                            </div>
                                            <!-- <v-icon style="color: #23036a" v-if="item.comun_cliente != '0'">
                                                mdi-card-account-phone
                                            </v-icon> -->
                                            <v-icon style="color: #e0a800" v-if="item.AMARCA != '0'">
                                                mdi-storefront-check-outline
                                            </v-icon>
                                            <v-icon style="color: #30009c" v-if="item.TNUEVA != '0'">
                                                mdi-clipboard-edit-outline
                                            </v-icon> 
                                        </div>                                                                               
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </template>

                    <template v-slot:[`item.ver`]="{ item }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">                                
                                <a  
                                    v-bind="attrs"
                                    v-on="on"
                                    v-if ="item.BELNR != '' "
                                    @click=" getPDFXMLInvoice(item.BELNR,1)"> 
                                    <v-icon  
                                        dense 
                                        large 
                                        color="red"
                                        style="font-size:22px">
                                    mdi-file-download
                                    </v-icon></a>                                
                            </template>
                            <span>Descargar PDF</span>
                        </v-tooltip>   

                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">                                
                                <a href="#" 
                                    v-bind="attrs"
                                    v-on="on"
                                    v-if ="item.BELNR != ''"
                                    @click="getPDFXMLInvoice(item.BELNR,2)" > 
                                    <v-icon  
                                        dense 
                                        large 
                                        color="blue"
                                        style="font-size:22px">
                                    mdi-file-download
                                    </v-icon></a>                               
                            </template>
                            <span>Descargar XML</span>
                        </v-tooltip> 
                    </template>  


                    <template v-slot:[`item.KUNNR`]="{ item }">
                        <span :style="{'color': item.ESTAT == 'VENCIDO' ? 'red' : ''}"> {{ item.KUNNR | formatCustomer }}</span>
                    </template>

                    <template v-slot:[`item.TIPO`]="{ item }">
                        <table>
                            <tbody>
                                <tr>
                                    <td class="t-documento" width="90%"><span :style="{'color': item.ESTAT == 'VENCIDO' ? 'red' : ''}">{{ item.TIPO }}</span></td>
                                    <td class="t-documento" width="10%">
                                        <div class="d-flex align-items-center justify-content-end">                                            
                                            <v-icon style="color: #e0a800" v-if="item.SPEI != '0'">
                                                mdi-check-circle-outline
                                            </v-icon>
                                            <v-icon :style="item.FPAGADA != '0' ? 'color: #218838;' : ''">
                                                mdi-text-box-check-outline
                                            </v-icon> 
                                        </div>                                                                               
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </template>                 
                    
                    <template v-slot:[`item.DMBTR`]="{ item }">
                        <span :style="{'color': item.ESTAT == 'VENCIDO' ? 'red' : ''}"> ${{item.DMBTR | formatCurrency}}</span>
                    </template> 
                    
                    <template v-slot:[`item.BUDAT`]="{ item }">
                        <span :style="{'color': item.ESTAT == 'VENCIDO' ? 'red' : ''}"> {{item.BUDAT}}</span>
                    </template> 
                    
                    <template v-slot:[`item.FVENC`]="{ item }">
                        <span :style="{'color': item.ESTAT == 'VENCIDO' ? 'red' : ''}"> {{item.FVENC}}</span>
                    </template> 
                    
                    <template v-slot:[`item.ORDCOM`]="{ item }">
                        <span :style="{'color': item.ESTAT == 'VENCIDO' ? 'red' : ''}"> {{item.ORDCOM}}</span>
                    </template> 
                    
                    <template v-slot:[`item.BSTNK`]="{ item }">
                        <span :style="{'color': item.ESTAT == 'VENCIDO' ? 'red' : ''}"> {{item.BSTNK}}</span>
                    </template> 

                    <template v-slot:[`item.STCD1`]="{ item }">
                        <span :style="{'color': item.ESTAT == 'VENCIDO' ? 'red' : ''}"> {{item.STCD1}}</span>
                    </template> 

                    <template v-slot:[`item.DVENC`]="{ item }">
                        <span :style="{'color': item.DVENC > 0 ? 'red' : ''}">{{item.DVENC}}</span>

                    </template>                 
                    
                    <template v-slot:[`item.por_vencer`]="{ item }">
                        <span :style="{'color': item.ESTAT == 'VENCIDO' ? 'red' : ''}">${{item.por_vencer | formatCurrency}}</span>
                    </template>                 
                    
                    <template v-slot:[`item.vencido`]="{ item }">
                        <span :style="{'color': item.ESTAT == 'VENCIDO' ? 'red' : ''}"> ${{item.vencido | formatCurrency}} </span>
                    </template>                 

                </v-data-table>    

                <dialog-glosary class="mt-5"></dialog-glosary>            
                
            </v-col>
        </v-row>

        <ConfidentialityNotice></ConfidentialityNotice>       

    </v-container>

</template>

<script>
/* eslint-disable */
import DialogGlosary from './components/DialogGlosary'
import ButtonsrGroupCustomer from '@/components/ButtonsrGroupCustomer'
import ConfidentialityNotice from '@/components/ConfidentialityNotice'
import moment from 'moment'
import axios from "@/plugins/axios";
import { mapGetters, mapActions } from "vuex";
import DataCustomer from '@/components/DataCustomer.vue';

export default {
    props: ['opt'],
    components: {DialogGlosary, ConfidentialityNotice, ButtonsrGroupCustomer, DataCustomer},
    data(){
        return{  
            loader: true,          
            dateCurrent: moment().format('D [de] MMMM [del] YYYY HH:mm:ss a'),
            today: moment().format('YYYY/MM/DD HH:mm:ss a'), 
            search: '',
            headers: [
                { text: 'N° de Cliente', value: 'KUNNR', align:"center" },
                { text: 'RFC', value: 'STCD1', align:"center" },
                { text: 'N° de documento', value: 'BELNR', align:"center" },
                { text: 'Orden de Compra', value: 'ORDCOM', align:"center" },
                { text: 'Descargas', value: 'ver', align:"center" },
                { text: 'Tipo de documento', value: 'TIPO', align:"center" },
                { text: 'Fecha de documento', value: 'BUDAT', align:"center" },
                { text: 'Fecha de vencimiento', value: 'FVENC', align:"center" },
                { text: 'Días vencido', value: 'DVENC', align:"center" },
                { text: 'Pedido', value: 'BSTNK', align:"center" },
                { text: 'Monto', value: 'DMBTR', align:"center" },
                { text: 'Saldo sin vencer', value: 'por_vencer', align:"center" },
                { text: 'Saldo Vencido', value: 'vencido', align:"center" }
            ],
            items: [],
            dataClient: {},
            selectedKUNNR: "",
            selectedSTCD1: "",
            selectedTIPO: "",
            selected: [],
            multipago: false,
            itemCSV:[],
            customerInfo : [], 
        }        
    },
    mounted(){  
        this.generarPDF()
        if (this.$getLocalStorageCustomer() != null) {

            const currentCustomerInfo= JSON.parse(localStorage.getItem('currentCustomerInfo')) || [];
                if (currentCustomerInfo.length<1) {
                this.$router.push({name:'Home'})
                }   
                this.customerInfo =  currentCustomerInfo; 
                const customer_code =  parseInt(this.customerInfo?.KUNNR,10)
                this.getDataClient(customer_code);
                this.getCustomersAccounts(customer_code); 
                 
             }else{ 
                const customer_code = this.user.custumer_code;
                this.getDataClient(customer_code);
                this.getCustomersAccounts(customer_code);
            }
    },
    computed: {
        currentPeriod(){
            const today = new Date;
            let firstDay = 1; 
            let year = today.getFullYear();
            let currentMonth = today.toLocaleString('default', { month: 'long' }) 
            currentMonth=  currentMonth.charAt(0).toUpperCase() + currentMonth.slice(1);
            return ` del (${firstDay} de ${currentMonth} ${year} al ${today.getDate()} de ${currentMonth} del ${year})`;
        },
        directorFullName(){
            return this.user.VTWEG =='DT'?'Minorista Nacional':'Privados Nacional';
            //return 'Minorista Nacional'
                // return this.seller.NACHND+" "+this.seller.NACH2D+" "+this.seller.VORNAD
            },
        sellerFullName(){ 
            return this.customerInfo.NAMEVEN;
        },
        ...mapGetters({
            authenticated: "auth/authenticated",
            user: "auth/user",
        }),
        filteredItems() {       
            // Aplica los filtros según las selecciones del usuario
            return this.items.filter(item => {
                const filterKUNNR = !this.selectedKUNNR || item.KUNNR === this.selectedKUNNR;
                const filterSTCD1 = !this.selectedSTCD1 || item.STCD1 === this.selectedSTCD1;
                const filterTIPO = !this.selectedTIPO || item.TIPO === this.selectedTIPO;

                 // Si las variables seleccionadas están vacías, muestra todos los registros
                // if (!this.selectedKUNNR && !this.selectedSTCD1 && !this.selectedTIPO) {
                //     return true;
                // }

                return filterKUNNR && filterSTCD1 && filterTIPO;
            });
        },        
        sumVencido(){
            return this.items.reduce((total, item) => {
                return total + parseFloat(item.vencido);
            }, 0);
        },
        sumPorVencer(){
            return this.items.reduce((total, item) => {
                return total + parseFloat(item.por_vencer);
            }, 0);
        },
        sumMonto(){
            return parseFloat(this.sumPorVencer) + parseFloat(this.sumVencido);
        },
        documentsAll() { 
            const documentos = this.items.map(item => ({'option': item.TIPO, 'value': item.TIPO}));       
            return [{ option: 'Todos los Documentos', value: ''}, ...documentos];
        },
        customersAll() {
            const documentos = this.items.map(item => ({'option': this.$options.filters.formatCustomer(item.KUNNR), 'value': item.KUNNR}));       
            return [{ option: 'Todos los Clientes', value: ''}, ...documentos];        
        },
        rfcAll() {   
            const documentos = this.items.map(item => ({'option': item.STCD1, 'value': item.STCD1}));       
            return [{ option: 'Todos los RFC', value: ''}, ...documentos];      
        },
    },
    watch:{
        dataClient(){
            this.generarExcel()
        },
        items(){
            this.generarExcel()
        }
    },
    methods:{
        ...mapActions({
            sendToExcel: 'printer/sendToExcel',
            sendToPDF: 'printer/sendToPDF',
        }), 
        async getDataClient(customer_code) {        
            try{
                let response = await axios.post("grupo-cliente/data-client", {
                    client: customer_code,
                    VKORG: this.user.VKORG,
                    VTWEG: this.user.VTWEG,
                    VKBUR: this.user.VKBUR,
                    P_OPCGPO: this.opt != 'byGroup' ? 0 : ''
                })

                this.dataClient = response.data.dataDetailClient
                console.log(response.data.dataDetailClient)
                this.loader = false
            }catch(error){
                console.log(error)
            }
        },
        async getCustomersAccounts(customer_code){
            try{
                let response = await axios.get("customers-account-statement", {
                    params:{
                        customer_code: customer_code,
                        P_VTWEG: this.user.VTWEG,
                        P_VKORG: this.user.VKORG,
                        P_OPCGPO: this.opt != 'byGroup' ? 0 : ''
                    }
                })

                this.items = response.data.data
            }catch(error){
                console.log(error)
            }
        },
        getPDFXMLInvoice(invoice,type){
            axios
                .post("InvoicePDFXML", {
                invoice: invoice,
                type: type,
                })
                .then((response) => {
                this.url = response.data;
                window.open(this.url, '_blank');
                
                })
                .catch((error) => {
                //alert(error);
                console.log(error);
                })
        },
        generarPDF(){
            this.sendToPDF({height: 1200})   
        },
        generarExcel(){
            this.itemCSV = []
            const maxCeldas = 11
            let objeto = {}
            
            for(let i = 1; i <= maxCeldas; i++){
                objeto['C' + i] = '';
            }
            
            const fila1 = { ...objeto }
            const fila2 = { ...objeto }
            const fila3 = { ...objeto }
            const fila4 = { ...objeto }
            const fila5 = { ...objeto }
            const fila6 = { ...objeto }
            const fila7 = { ...objeto }
            const fila8 = { ...objeto }
            const fila9 = { ...objeto }
            const fila10 = { ...objeto }
            const fila11 = { ...objeto }
            const fila12 = { ...objeto }

            fila1['C1'] = 'Datos del Cliente'

            fila2['C1'] = 'Nombre del Cliente'
            fila2['C2'] = 'RFC'
            fila2['C3'] = 'Número del Cliente'
            
            fila3['C1'] = this.dataClient.NAME1
            fila3['C2'] = this.dataClient.STCD1
            fila3['C3'] = this.dataClient.KUNNR

            fila5['C1'] = 'Cartera y Línea de crédito'

            fila6['C1'] = 'Límite de crédito'
            fila6['C2'] = 'Línea de crédito comprometida'
            fila6['C3'] = 'Línea de crédito disponible'
            fila6['C4'] = 'Plazo en días'
            fila6['C5'] = 'Saldo Vencido'
            fila6['C6'] = 'Saldo Total'
            fila6['C7'] = 'Notas de Crédito por aplicar'
            fila6['C8'] = 'Pagos pendientes por aplicar'

            fila7['C1'] = this.dataClient.KLIMK
            fila7['C2'] = `$${this.dataClient.SKFOR}`
            fila7['C3'] = `$${this.dataClient.CREDD}`
            fila7['C4'] = this.dataClient.VTEXT
            fila7['C5'] = `$${this.dataClient.SVENC}`
            fila7['C6'] = `$${this.dataClient.STOTA}`
            fila7['C7'] = `$${this.dataClient.DMBTRNC}`
            fila7['C8'] = `$${this.dataClient.DMBTRDZ}`

            fila9['C1'] = `Movimientos al ${this.dateCurrent}`

            fila10['C1'] = 'N° de Cliente'
            fila10['C2'] = 'RFC'
            fila10['C3'] = 'N° de documento'
            fila10['C4'] = 'Tipo de documento'
            fila10['C5'] = 'Fecha de documento'
            fila10['C6'] = 'Fecha de vencimiento'
            fila10['C7'] = 'Días vencido'
            fila10['C8'] = 'Pedido'
            fila10['C9'] = 'Monto'
            fila10['C10'] = 'Saldo sin vencer'
            fila10['C11'] = 'Saldo Vencido'

            this.itemCSV.push(fila1)
            this.itemCSV.push(fila2)
            this.itemCSV.push(fila3)
            this.itemCSV.push(fila4)
            this.itemCSV.push(fila5)
            this.itemCSV.push(fila6)
            this.itemCSV.push(fila7)
            this.itemCSV.push(fila8)
            this.itemCSV.push(fila9)
            this.itemCSV.push(fila10)

            let newItems = []
            for (let key in this.items) {                
                let item = this.items[key];
                newItems.push({
                    'C1': item.KUNNR,
                    'C2': item.STCD1,
                    'C3': item.BELNR,
                    'C4': item.TIPO,                    
                    'C5': item.BUDAT,                    
                    'C6': item.FVENC,                    
                    'C7': item.DVENC,                    
                    'C8': item.BSTNK,                    
                    'C9': `$${item.DMBTR}`,                    
                    'C10': `$${item.por_vencer}`,                    
                    'C11': `$${item.vencido}`,                    
                });
            }

            this.itemCSV = [...this.itemCSV, ...newItems]

            fila11['C9'] = 'Subtotales'
            fila11['C10'] = `$${this.sumPorVencer}`
            fila11['C11'] = `$${this.sumVencido}`

            fila12['C10'] = 'Saldo total'
            fila12['C11'] = `$${this.sumMonto}`

            this.itemCSV.push(fila11)
            this.itemCSV.push(fila12)

            /**
             * GENERAR EXCEL DESDE PRINTER
             */
            this.sendToExcel({items: this.itemCSV})

        }
    }
}
</script>

<style scoped>
    .statement__container{
        font-size: 14px !important;
        padding-left: 3%;
        padding-right: 3%;
    }    
    .foot-total{
        background: transparent linear-gradient(180deg, var(--light-🌕-error-b00020) 0%, #580010 100%) 0% 0% no-repeat padding-box;
        border: 1px solid var(--gris-de-las-tablas);
        background: transparent linear-gradient(180deg, #B00020 0%, #580010 100%) 0% 0% no-repeat padding-box;
        border: 1px solid #DDE1E6;
        opacity: 1;
        height: 28px !important;
        color: #FFF
    }
    .foot-sub-total{
        background: rgb(245,245,245);
        height: 30px !important;
    }    
    .modal-title{
        background: rgb(245,245,245);
    }
    .btn-multipago{
        background: transparent linear-gradient(180deg, #A82222 0%, #FF0000 100%) 0% 0% no-repeat padding-box;
        opacity: 1;
        color: #FFF !important;
        text-transform: inherit !important;        
        height: 20px !important;
        font-size: 12px !important;
    }

    .border-bootom-none{
        border-bottom: 1px solid #a5a5a5 !important
    }        

    .statement__container .close-icon {
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        margin: 8px;
    }

    .t-documento{
        height:0 !important; 
        padding:0 10px !important; 
        border:0 !important
    }

    .icon-pnc{
        background: #dc3545;
        border-radius: 5px;
        font-size: 11px;
        padding: 2px 3px;
        max-width: 30px;
        color: #FFF;
        margin-right: 2px;
    }
</style>

<style>
    .statement__container .table-movimientos thead th{
        font-weight: 500 !important;
        font-family: Roboto !important;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: inset 0px 3px 6px #00000029;
        opacity: 1;   
        border-bottom: 2px solid #707070 !important;
        font-size: 14px !important;
    }

    .statement__container .table-informacion thead th{
        font-weight: 500 !important;
        font-family: Roboto !important;
        border: 1px solid var(--gris-de-las-tablas);
        background: #F2F2F2 0% 0% no-repeat padding-box;
        box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
        border: 1px solid #DDE1E6;
        opacity: 1;
        border-bottom: 2px solid #707070 !important;
        font-size: 14px !important;
        height: 30px !important;
    }
    
    .statement__container table tbody td{
        background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
        border: 1px solid var(--gris-de-las-tablas);
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #DDE1E6;
        opacity: 1;
        height: auto !important;
        padding: 4px !important;
        width: 8%;
    }

    /* Style Input */
    .statement__container table thead .v-input__slot, .statement__container table thead .v-input__control{
        min-height: auto !important;  
        background: transparent linear-gradient(180deg, #A82222 0%, #FF0000 100%) 0% 0% no-repeat padding-box !important;
        opacity: 1;          
    }

    .statement__container table  thead .v-input__slot .v-icon{
        color: #FFF !important;
    }

    .statement__container table .v-input__slot .v-label, .statement__container table .v-input__slot .v-select__selection {
        font-size: 11px !important;
        color: #FFF !important;
    }

    .statement__container .cont-search{
        background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
        opacity: 1;
        padding: 7px 20px;
    }

    .statement__container .cont-search .v-input__control{
        min-height: auto !important;
    }
    
    .statement__container .cont-search .v-input__control .v-label{
        font-size: 14px;
    }
    /* End Style Input */

    .statement__container .h-title{
        background: #F5F5F5 0% 0% no-repeat padding-box;
        box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
        opacity: 1;
        border-bottom: 2px solid #707070 !important;
        font-weight: 500;
        font-family: Roboto;
    }
</style>