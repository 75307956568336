<template>
  <div class="my-select">
    <button :disabled="disabled" class="my-select-button" @click="showOptions = !showOptions">
      <span>{{ selectedLabel }}</span>
      <i class="fas fa-chevron-down arrow"></i>
    </button>
    <ul class="my-select-options" v-show="showOptions">
      <li
        class="my-select-item"
        v-for="(option, index) in options"
        :key="index"
      >
        <button @click="selectOption(option.value)">{{ option.label }}</button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "MySelect",
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      // type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: { 
      default: false,
    },
  },
  data() {
    return {
      showOptions: false,
    };
  },
  computed: {
    selectedLabel() {
      const selectedOption = this.options.find(
        (option) => option.value === this.value
      );
      return selectedOption ? selectedOption.label : this.placeholder;
    },
  },
  methods: {
    selectOption(optionValue) {
      this.$emit("input", optionValue);
      this.showOptions = false;
    },
  },
};
</script>

<style scoped>
.arrow{
  color: linear-gradient(#B6B6B6, #fa4848);
}
.my-select {
  position: relative;
  width: 100%;
}
.my-select-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.8em; 
  background: linear-gradient(#AD2020, #fa4848);
  cursor: pointer;
  color:#fff;
}
.my-select-button span {
  flex-grow: 1;
  margin-right: 5px;
}
.my-select-button i {
  margin-left: 0.5rem;
}
.my-select-options {
  margin-top: -9px;
    list-style: none;
    position: absolute;
    top: 100;
    background-color: white;
    width: 100%;
    height: auto;
    padding-left: 0;
    z-index: 1;
    border: 0.1px solid #000;
    padding-bottom: 0px;
    margin-bottom: 0px;
}
.my-select-item {
  width: 100%;
    padding-top: 0.7em;
    padding-bottom: 0.7em;
    padding-left: 0.7em; 
    box-shadow: chocolate; 
    -moz-box-shadow: -1px 1px 7px 0px rgba(224,37,37,0.45);
    margin-bottom: 0px; 
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 0.5px solid #707070;
    opacity: 1;
}
.my-select-item:hover {   
  background-color: #ebebeb;
}
</style>
