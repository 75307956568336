<template>
  <div class="bg-white">
    <v-container fluid class="page__container--padding">
      <div class="container-sm mt-5">
        <!-- search -->
        <div class="d-flex justify-content-end">            
            <search-input 
              v-model="search" 
              style="max-width: 250px">
            </search-input>
        </div>
        <!-- end search --> 
      
        <v-data-table
          :headers="headers"
          :items="filteredItems"
          :footer-props="footerProps"
          :options="dataTableOptions"
          :mobile-breakpoint="null"
          :loading="isLoading"
          class="elevation-2 table__desing-general table__alternating-rows--grey"
        >
          <template v-slot:[`item.SNAME`]="{ item }">
            <div class="py-1">
              <a href="#" class="text-decoration-underline font-weight-bold text-dark" @click.prevent="sendCustomerHome(item.KUNNR)">
                <span>{{ item.KUNNR | formatCustomer}}</span> <br>  
                <span>{{ item.SNAME }}</span>
              </a>
            </div> 
          </template>
        </v-data-table>
      </div>
      
      <confidentiality-notice class="my-3" />   
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "@/plugins/axios";
import ConfidentialityNotice from '@/components/ConfidentialityNotice';
import SearchInput from "@/components/SearchInput.vue";

export default {
  name: 'ResulstExpressSales',
  components: {ConfidentialityNotice, SearchInput},
  data() {
    return {
      headers: [
        { text: "Cliente", value: "SNAME", align: "center" }
      ],
      items: [],      
      isLoading: true,
      search: '',
      footerProps: {
        itemsPerPageOptions: [10, 14, 24, 34, { text: "Todas", value: -1 }],
        itemsPerPageText: "Mostrar en grupo de:",
        showCurrentPage: false,
        pageText: "Desplegado {0} / {1}",
      },
      dataTableOptions: {
        itemsPerPage: 14,
        pagination: {
          enabled: true,
          options: {
            numeric: true,
          },
        },
      },
    };
  },
  mounted() {    
    this.getData();
  },
  methods: {
    async getData() {
      try{
        let response = await axios.get('express-sales', {
          params: {
            VTWEG: this.user.VTWEG            
          },
          noCancelRequest: true,
          cachable: true,
          id: 'get-customers-' + parseInt(this.user.employee_number)
        });
        this.items = response.data.data;
        this.isLoading = false;
        this.desactiveLoader();
      }catch(error){
        this.desactiveLoader();
        console.log(error);
      }      
    },
    sendCustomerHome(code){      
      this.$setLocalStorageCustomer(this.removeZeros(code));
      this.$router.push({name: 'CustomerCoverDetails'});
    },
    removeZeros(value){
      return value.replace(/^0+/, '');
    },
    ...mapActions({
        desactiveLoader: 'loader/desactiveLoader',
        activeLoader: 'loader/activeLoader'
    }),
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    filteredItems() {
        const searchLowerCase = this.search.toLowerCase();
        return this.items.filter(item =>
            Object.values(item).some(
            value =>
                typeof value === 'string' &&
                value.toLowerCase().includes(searchLowerCase)
            )
        );
    },
  },
  beforeRouteEnter(to, from, next) {
      //se ejecuta el loader solo si venimos desde el componente SignIn
      next(vm => {
          if (from.name === 'SignIn') {
              vm.activeLoader();
          }
      });
  },
};
</script>