<template>
  <v-row>
		<h4 class="text-left text-h6">{{ title }}</h4>

		<v-col cols="12" md="6">
			<div class="text-left font-weight-bold mb-2">
				<label for="order-concept">*Orden de compra:</label>
			</div>
			<v-text-field
				v-model="form.orderConcept"
				name="order-concept"
				dense
				autocomplete="off"
				hide-details
				solo
				class="card-shadow rounded-lg"
			/>
		</v-col>
		<v-col cols="12" md="6">
			<div class="text-left font-weight-bold mb-2">
				<label for="ship-address">*Destino de mercancia:</label>
			</div>
			<v-select
				v-model="form.shippingAddressId"
				name="ship-address"
				:item-value="addresItemValue"
				:item-text="addressItemText"
				:items="addressListFormated"
				dense
				solo
				hide-details
				class="card-shadow rounded-lg"
				no-data-text="Sin destinos"
			/>
		</v-col>
  </v-row>
</template>

<script>
export default {
  name: "ShippingForm",

  props: {
    value: {
      required: true,
    },

    title: {
      type: String,
      default: 'Información del pedido'
    },

    addressList: {
      type: Array,
      default: () => [],
    },

    addresItemValue: {
      type: String,
      default: "KUNNR",
    },

    addressItemText: {
      type: String,
      default: "text",
    },

    noFormatItemText: Boolean,
  },

  computed: {
    form: {
      set(newValue) {
        this.$emit("input", this.value, ...newValue);
      },
      get() {
        return this.value;
      },
    },

    addressListFormated() {
      return !this.noFormatItemText
        ? this.addressList.map((d) => ({
            ...d,
            text: `${d.KUNNR} ${d.STRAS} ${d.ORT01} ${d.PSTLZ}`,
          }))
        : this.addressList;
    },
  },
};
</script>
