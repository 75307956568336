import axios from "@/plugins/axios";

export default {
  namespaced: true,
  state: {
    customerId: null,
    customerName: null,
    customerCode: null,
    promissorynotes: [],
    promissorynote: {
      id: null,
      promissory_note: null,
      card_id: null,
      endorsementaddress: null,
      name_endorsement: null,
      comment: null,
      card_id_path: null,
      endorsement_address_path: null,
      promissory_notes_path: null,
    },
  },
  getters: {
    promissorynotes: (state) => state.promissorynotes,
    promissorynote: (state) => state.promissorynote,
  },
  mutations: {
    SET_PROMISSORY_NOTES(state, payload) {
      state.promissorynotes = payload.data;
    },
    SET_PROMISSORY_NOTE_FORM(state, payload) {
      //   state.promissorynote.promissory_note = payload.promissory_note;
      //   state.promissorynote.card_id = payload.card_id;
      //   state.promissorynote.endorsementaddress = payload.endorsementaddress;
      state.promissorynote.id = payload.id;
      state.promissorynote.name_endorsement = payload.name_endorsement;
      state.promissorynote.comment = payload.comment;
      state.promissorynote.card_id_path = payload.card_id_path;
      state.promissorynote.endorsement_address_path =
        payload.endorsement_address_path;
      state.promissorynote.promissory_notes_path =
        payload.promissory_notes_path;
    },
    SET_VALUE(state, payload) {
      state.promissorynote[payload.key] = payload.value;
    },
    RESET_FORM(state) {
      state.promissorynote.id = null;
      state.promissorynote.promissory_note = null;
      state.promissorynote.card_id = null;
      state.promissorynote.endorsementaddress = null;
      state.promissorynote.name_endorsement = null;
      state.promissorynote.comment = null;
      state.promissorynote.card_id_path = null;
      state.promissorynote.endorsement_address_path = null;
      state.promissorynote.promissory_notes_path = null;
    },
    SET_CUSTOMER_CODE(state, payload) {
      state.customerCode = payload;
    },
    SET_CUSTOMER_ID(state, payload) {
      state.customerId = payload;
    },
  },
  actions: {
    async loadCustomerId({ commit }, customer_code) {
      commit("SET_PROMISSORY_NOTES", []);
      return axios
        .get(`/customers?filter[custumer_code]=${customer_code}`)
        .then((response) => {
          if (response.data.data[0]) {
            commit("SET_CUSTOMER_ID", response.data.data[0].id);
            return true;
          } else {
            return false;
          }
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },
    async loadPromissoryNotes({ state, commit }, user_id) {
      return axios
        .get(
          `/promissory-notes?filter[user_id]=${user_id}&filter[custumer_data_id]=${state.customerId}`
        )
        .then((response) => {
          commit("SET_PROMISSORY_NOTES", response.data);
          return true;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },
    async updatePromissoryNote({ state }) {
      let formData = new FormData();

      formData.append("id", state.promissorynote.id);
      formData.append("customer_id", state.customerId);
      formData.append("company_name", state.customerName);
      formData.append("customer_code", state.customerCode);
      formData.append("promissory_note", state.promissorynote.promissory_note);
      formData.append("card_id", state.promissorynote.card_id);
      formData.append(
        "name_endorsement",
        state.promissorynote.name_endorsement
      );
      formData.append("comment", state.promissorynote.comment);
      formData.append(
        "endorsementaddress",
        state.promissorynote.endorsementaddress
      );

      return axios.post(`/promissory-notes`, formData);
    },
  },
};
