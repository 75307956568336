import axios from "@/plugins/axios";

export default {
  namespaced: true,
  state: {
    sellers: [],
  },
  getters: {
    sellers: (state) => state.sellers,
  },
  mutations: {
    SET_SELLERS(state, payload) {
      state.sellers = payload;
    },
  },
  actions: {
    async loadSellers({ commit }, employeeNumber) {
      return axios
        .get(`/administrator/sellers?employee_number=${employeeNumber}`)
        .then((response) => {
          commit("SET_SELLERS", response.data);
          return true;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },
    async downloadExcel(_, payload) {
      return axios
        .get(
          `/administrator/users/excel?sortBy=${payload.sortBy}&sortDesc=${payload.sortDesc}&page=${payload.page}&perPage=${payload.itemsPerPage}&withTrashed=${payload.deleted}&search=${payload.search}`
        )
        .then((response) => response.data)
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },
    async restoreUser(_, payload) {
      return axios.put(`/administrator/users/${payload.id}/restore`)
    }
  },
};
