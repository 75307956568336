<template>
  <div>
    <div class="d-flex flex-wrap" style="background-color: #000; color:#fff">
      <div class="text-left ml-3 mt-1" >% Clientes con pago vía IEL</div> 
          <v-btn v-if="!loading" icon @click="closeModal()" class="ml-auto">
              <v-icon size="20" color="#fff" >mdi-close-circle</v-icon>
          </v-btn> 
      </div>  
        <v-row class="ml-1 mr-1 mt-1 "> 
          <v-col cols="12 ">
            <v-simple-table v-if="!loading" dense>
              <template v-slot:default>
                <thead>
                  <tr class="table-heading">
                    <th colspan="5" class="text-center text-dark "><b>{{ `${data.BZTXT} (${data.BZIRK})`}}</b></th> 
                  </tr>
                  <tr class="custom-tr-no-bordered">
                    <th class="text-center text-dark "><b>Dif:&nbsp;{{ number_format(data.DIFACT) }}</b></th>
                    <th colspan="4" class="text-center text-dark "><b>Promedio:&nbsp;{{number_format(data.PORCTE)}}%</b></th>  
                  </tr>
                  <tr class="custom-table-bordered bg-gray">
                    <th v-for="(item,index) in months" :key="index" class="text-center text-dark ">{{ item.date }}</th>  
                  </tr>
                </thead>
                <tbody class="custom-table-bordered">
                  <tr  v-for="(item,index) in data.PAYMENTS" :key="index">
                    <td :class="setPaymentClass(index)">{{paymentTypeFormat(index,item[0])}}  </td> 
                    <td :class="setPaymentClass(index)">{{paymentTypeFormat(index,item[1])}}  </td> 
                    <td :class="setPaymentClass(index)">{{paymentTypeFormat(index,item[2])}}  </td> 
                    <td :class="setPaymentClass(index)">{{paymentTypeFormat(index,item[3])}}  </td> 
                    <td :class="setPaymentClass(index)">{{paymentTypeFormat(index,item[4])}}  </td> 
                  </tr>
                </tbody>
              </template>
        </v-simple-table>

        <v-progress-linear v-else indeterminate color="cyan" ></v-progress-linear>
          </v-col>
        </v-row>
  </div> 
</template>
<script>
import MixinHelpers from '@/mixins/helpers';
import axios from "@/plugins/axios";

export default ({
//sellers/customers-via-iel
  name:'PaymentInfo'  ,
  props: {   
      seller: { default: 0},   
      period: { default: 0},  
      modal:{ default: true},
      months:{ default: true},
      channel:{ default: ''},
  }, 
  mixins: [MixinHelpers],
  data() {
      return {
          loading: false,
          data: [],
          refreshData:true,
      }
  }, 
  watch:{
      modal(value){ 
          if (value) {
              this.customersViaIEL();
          } 
      }, 
  },
  mounted(){
      this.customersViaIEL();
  },
  methods: {
      closeModal(){ 
          this.$emit('closeModal')
      },
      setPaymentClass(index){
        switch (index) {
          case 0: return 'font-weight-bold'
          case 2: return 'text-success font-weight-bold'
          case 3: return 'text-warning font-weight-bold'
          default: return ""
        }
      },
      customersViaIEL(){
      this.loading=true;
      var month = this.period.month.toString()
      if (month < 10) { month = `0${month}`}
      const period = `${this.period.year}${month}`
      var params ={ seller:this.seller, period: period,channel:this.channel} 
      axios.post('/manager/home/sellers/customers-via-iel',params).then((res) => { 
          res.data.PAYMENTS = res.data.PAYMENTS.slice(1)
          this.data = res.data; 
          console.log(this.data)
          this.loading = false;  
    })
    .catch((err) => { 
          console.log(err);
          this.loading = false;
        }) ;  
      },
      paymentTypeFormat(index,item){
        switch (index) {
          case 0:
            return  `${this.number_format(item)}%`          
          default:
            return `${this.number_format(item)}` 
        }
      }
  }
})
</script>

<style>

.card-p {
margin: 0;
padding: 0;
color: #000;
}

.my-table th,
.my-table td {
border: 0px 4px 0px 0px solid #dad9d9;

}

.my-table {
width: 100%;
}
.custom-border-right{
border-right-width: 2px;
}
.table-heading {
font-weight: bold;
font-size: 0.75rem;
background: rgb(245, 245, 245);
color: #000;
border-width: 1px 1px 3px 1px;
border-color: rgb(228, 231, 234);
border-bottom-color: rgb(112, 112, 112);
}
.custom-table-bordered  {
border-width: 2.5px;
border-color: rgb(224, 228, 232);
}
.custom-table-bordered>tr>td {
border-width: 2.5px;
border-color: rgb(224, 228, 232);
}

.custom-table-bordered>th {
border-width: 2.5px;
border-color: rgb(224, 228, 232);
}

.table-heading tr {
color: #000;
}

th.boder-x-none {
border-right: none;
border-left: none;
}

th.boder-l-none {
border-left: none;
}

.custom-table-item-sm > tr > td {
font-size: 0.8em; 
text-align: center;
min-width: 50px;
}  
.custom-td-header{
font-weight: 700;
background-color: rgb(245, 245, 245); 
} 
.custom-text-yellow{
color:rgb(224,168,0)
}
.custom-header-title{ 
background-color: rgb(245, 245, 245); 
}

.custom-white-table > tr > td {
background-color: white;
border-width: 1px;
border-color: lightgray;
}
.custom-table-no-bordered{  
background-color: white;

}
.border-bottom-0{   
border-bottom: 0px;
}
.bg-gray {

background-color: rgb(245,245,245);
}
.custom-tr-no-bordered{   
border-width: 0px 1px 0px 1px;
  border-color: lightgray;
} 
.font-size-85{
font-size:0.85em;
}
.font-size-8{ 
font-size:0.8em;
}
.font-size-9{ 
font-size:0.9em;
}
.no-border{ 
border-width:0px;
}
.bg-yellow{
background-color:rgb(255,193,7)
}
</style>