<template>
  <v-autocomplete
    v-model="destiny"
    :items="customerDestinyList"
    :item-text="getItemTextDestinations"
    return-object
    dense
    rounded
    label="Destino de Compra"
    style="background-color: #4a74ff !important; color: #fff !important"
  >
  </v-autocomplete>
</template>
<script>
import axios from "@/plugins/axios";
import { mapGetters } from "vuex";
export default {
  name: "CustomerDestiny",
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  props: {
    warnDestiny: {
      type: Function,
      default: null,
    },
    catchErrorConnection:{
      type: Function,
      default: null,
    }
  },
  data: () => ({
    customerDestinyList: [],
    destiny: "",
    errormsg: false,
  }),
  async mounted() {
    let self = this;
    self.getDestinyCustomer();
  },
  methods: {
    getDestinyCustomer() {
      axios
        .post("destinyCustomer", {
          customer: this.user.custumer_code,
          VKORG: this.user.VKORG,
          VTWEG: this.user.VTWEG,
          VKBUR: this.user.VKBUR,
        })
        .then((response) => {
          this.customerDestinyList = response.data;
          this.destiny = this.customerDestinyList;
          //console.log(this.destiny);
          //this.errormsg = true;
          //this.catchErrorConnection(this.errormsg);
        })
        .catch((error) => {
          console.error(error);
         // console.log(error);
          this.errormsg = true;
          this.catchErrorConnection(this.errormsg);
        })
        .finally(() => (this.dialogLoading = false));
    },
    getItemTextDestinations(item) {
      return `${item.KUNNR} ${item.STRAS} ${item.ORT01}  ${item.PSTLZ} `;
    },
  },
  watch: {
    destiny: function (val) {
      this.warnDestiny(val);
    },
    catchErrorConnection: function(val){
      this.errormsg = val;
    }
  },
};
</script>
<style>

</style>
