<template>
    <div>
        <div class="text-justify text-general-module ma-4 ml-15">
            <span class="ml-4 mr-4">HORARIOS DE RECEPCIÓN</span>
        </div>
        <div class="ma-4 ml-10 mb-4" > 
            <v-simple-table   v-if="!showForm"  class="mb-4 ml-10 mr-10">
                <tbody>
                    <tr>
                        <td class="text-table-profile">Destinatario de Mercancia</td>
                        <td class="text-table-profile">Horarios</td>
                        <td class="text-table-profile">Acciones {{  loadingReceptions  }}</td>
                    </tr> 
                    <template v-if="!loadingReceptions">
                        <tr v-for="(recep,index) in initReceptions" :key="index">
                            <td> {{recep.reception.ORT01+' '+recep.reception.PSTLZ+' '+recep.reception.STRAS}} <br>
                                <b>Comentarios:</b><br>
                                <span v-if="recep.days[0].documentos_requeridos == null">Sin Comentarios</span>
                                <span v-else>{{recep.days[0].documentos_requeridos}}</span> 
                            </td>
                            <td>
                                <template v-if="recep.days.length == 0">
                                    <span style="font-size: .875rem;">No ha cargado los horarios</span>
                                </template>
                                <template v-else>
                                    <v-simple-table class="table-profile mb-4 ml-10 mr-10">
                                        <tbody>
                                            <tr>
                                                <td class="text-table-profile">Día</td>
                                                <td class="text-table-profile">Horario</td>
                                            </tr>
                                            <tr v-for="d in recep.days" :key="d.id">
                                                <td>{{ daytext( d.day )}}</td>
                                                <td>
                                                    <template v-if="d.not_reception">
                                                        <span>Sin recepción</span>
                                                    </template>
                                                    <template v-else>
                                                    <template v-if="d.start == null">
                                                        Recepción de: <span>08:00:00 a 17:00:00</span>
                                                    </template>
                                                    <template v-else>
                                                        Recepción de: <span>{{ d.start }} a {{ d.end }}</span>
                                                    </template>
                                                    </template>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </v-simple-table>
                                </template>
                            </td>
                            <td>
                                <v-btn depressed @click="editReception(index)">
                                    <v-icon>mdi-square-edit-outline</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                    <template v-else>
                        <v-skeleton-loader type="article, actions" ></v-skeleton-loader>
                        <!-- 
                        <h3 style="color:red">Sin información de destinatarios de mercancía por parte de SAP, comunicarse con el área de crédito para dar de alta.</h3>
                        -->
                    </template>
                </tbody>
            </v-simple-table>

            <v-form v-else> 
                                <div class="text-justify text-general-module ma-4">
                                    <span class="ml-4 mr-4">Recepción del destinatario de mercancia:<u> {{ dataReceptionCurrent.name }} </u></span>
                                </div> 
                                <v-row dense> 
                                    <div class="ma-4 ml-10 mb-4">
                                        <v-simple-table class="table-profile mb-4 ml-10 mr-10">
                                            <tbody>
                                                <tr>
                                                    <td class="text-table-profile">Día</td>
                                                    <td class="text-table-profile">Sin recepción</td>
                                                    <td class="text-table-profile">Inicio</td>
                                                    <td class="text-table-profile">Fin</td>
                                                </tr>
                                              
                                                <tr v-for="(day, index)  in dataReceptionCurrent.days" :key="index">
                                                    <td>{{ daytext(day.day) }}</td> 
                                                    <td>
                                                        <v-checkbox :disabled="savingReception"
                                                        class="ml-10 mr-10"
                                                        v-model="day.isFreeDay"
                                                        label="Sin Recepción"
                                                        ></v-checkbox>
                                                    </td> 
                                                    <td>
                                                        <v-text-field :disabled="savingReception" class="ml-10 mr-10" v-model="day.openAt" type="time" solo></v-text-field>
                                                    </td>   
                                                    <td>
                                                        <v-text-field :disabled="savingReception" class="ml-10 mr-10" v-model="day.closeAt" type="time" solo></v-text-field>
                                                    </td>  
                                                </tr>
                                                 
                                            </tbody>
                                        </v-simple-table>
                                    </div>
                                </v-row>

                                <v-row>
                                    <v-col cols="10" md="10" offset="2">
                                        <v-row>
                                            <v-col cols="12" md="12">

                                                <label for="" class="text-table-profile ml-10 mr-10">Comentarios</label>

                                                <v-text-field class="ml-10 mr-10" :disabled="savingReception" v-model="dataReceptionCurrent.comments" label="Comentarios" solo>
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="12" md="4" offset="4">
                                        <v-row>
                                            <v-col cols="12" md="6">
                                                <v-btn :disabled="savingReception" :loading="savingReception" depressed color="success" class="justify-text" large @click="saveReception()">
                                                    Actualizar
                                                    <v-icon style="margin-left: 0.5em;">
                                                        mdi-check-bold
                                                    </v-icon>
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <v-btn :disabled="savingReception"  depressed color="error" class="justify-text" large
                                                    v-on:click="resetShowsForm()">
                                                    Cancelar
                                                    <v-icon style="margin-left: 0.5em;">
                                                        mdi-window-close
                                                    </v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
            </v-form> 
        </div>
    </div>
</template>

<script>
import axios from '@/plugins/axios.js';
import { mapGetters } from "vuex";

export default ({
    name:"ReceptionForm", 
    props:["profile"], 
    data() {
        return { 
            allReceptions: null,
            dataReceptionCurrent:[],
            currentDays:[],
            requiredDocuments: "",
            showForm:false,
            initReceptions:null,
            savingReception:false,
            loadingReceptions:false,
        }
    },
    watch:{ 
        profile(){
            if (!this.initReceptions) this._getReceptionList() 
        }, 
    },
    mounted(){
        if (!this.initReceptions) this._getReceptionList() 
    },
    computed: {
        ...mapGetters({ 
            user: "auth/user",
        }), 
    },
    methods:{
        daytext( day ){
            let dayText = '';
            switch( day ){
                case 0: dayText = 'Lunes'; break;
                case 1: dayText = 'Martes'; break;
                case 2: dayText = 'Miercoles'; break;
                case 3: dayText = 'Jueves'; break;
                case 4: dayText = 'Viernes'; break;
                case 5: dayText = 'Sabado'; break;
                case 6: dayText = 'Domingo'; break;
            }
            return dayText;
        },
        editReception(index){ 
            this.$emit('editReception') 
            //const cloneArr = { ...this.allReceptions };
           // this.editReceptionRollback = this.allReceptions; 
            var dataReceptionCurrent = this.allReceptions[index]; 
            var days = []; 
           for (let index = 0; index < 7; index++) {
            var day = dataReceptionCurrent.days[index]; 
            days.push({
                id:day.id,
                day: day.day,
                openAt:day.start,
                closeAt:day.end,
                isFreeDay:day.not_reception,
                comments:day.documentos_requeridos
            }) 
           }     
        
           this.dataReceptionCurrent = dataReceptionCurrent;
           this.dataReceptionCurrent.days = days; 
           this.dataReceptionCurrent['comments']= days[0].comments; 
           this.showForm =true; 
           console.log(this.dataReceptionCurrent);
        },
        _getReceptionList() { 
            this.loadingReceptions =true;
            axios
            .post("profile/reception-list", {  
                customer: this.profile.customer_code,
                vkorg: this.user.VKORG,
                vtweg: this.user.VTWEG,
                client: this.profile.customer_id
            })
            .then((response) => { 
                if( response.data.result == 1 ){
                    this.allReceptions = response.data.data; 
                    this.initReceptions = response.data.data;  
                }
            this.loadingReceptions =false;

            })
            .catch((error) => {
            this.loadingReceptions =false; 
                console.error(error);
            }) 
        }, 
        resetShowsForm(){ 
            //this.dataReceptionCurrent = this.editReceptionRollback;
             this.showForm=false;
        }, 
      
        saveReception(){   
      let form = new FormData();
      form.append("lzone", this.dataReceptionCurrent.reception.LZONE);
      form.append("client",  this.profile.customer_id);
      form.append("documentos_requeridos", this.dataReceptionCurrent.comments);
      form.append("zone_id", this.dataReceptionCurrent.reception.KUNNR);

      for (let d = 0; d < this.dataReceptionCurrent.days.length; d++) {
        if( !this.dataReceptionCurrent.days[d].isFreeDay ){
            if(
                ( this.dataReceptionCurrent.days[d].openAt && !this.dataReceptionCurrent.days[d].closeAt ) ||
                ( !this.dataReceptionCurrent.days[d].openAt && this.dataReceptionCurrent.days[d].closeAt )
            ){ 
                this.$swal({
                    title: "El dia debe tener hora inicio y fin",
                    icon: "error",
                }); 
                return false;
            } 

                if( this.dataReceptionCurrent.days[d].openAt > this.dataReceptionCurrent.days[d].closeAt){
                    this.$swal({
                        title: "El horario fin, no puede ser mayor al horario de inicio",
                        icon: "error",
                    }); 
                    return false;
                }
            }
            form.append("day[]", this.dataReceptionCurrent.days[d].id);
            form.append("not_reception[]",this.dataReceptionCurrent.days[d].isFreeDay?1:0);
            form.append("start[]", this.dataReceptionCurrent.days[d].openAt);
            form.append("end[]", this.dataReceptionCurrent.days[d].closeAt);
        } 
                this.savingReception = true;
                axios
                    .post("profile/reception-save", form)
                    .then((response) => {
                    if (response.data.result == 1) {
                        this.dataReceptionCurrent.sendFormReception = true;
                        this.$swal({
                        title: response.data.message,
                        icon: "success",
                        type: "succes",
                        })  
                        this.resetShowsForm();
                        this._getReceptionList();
                    } else {
                        this.$swal({ title: response.data.message, icon: "error" });
                        this.dataReceptionCurrent.sendFormReception = true;
                        
                    }
                    this.savingReception = false;
                    })
                    .catch((error) => {
                    console.error(error);
                    this.$swal({ title: error, icon: "error" });
                    this.savingReception = false;
                    this.dataReceptionCurrent.sendFormReception = true;
                    }); 
    }
    }

})
</script>