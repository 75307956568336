<template>
    <div>
        <v-dialog v-model="showDialog"  :persistent="true" :max-width="900"> 
            <v-card class="pa-1">
                <div class="d-flex flex-wrap" style="background-color: #000; color:#fff">
                    <div class="text-left ml-3 mt-1" >{{title}}</div> 
                        <v-btn v-if="!loading" icon @click="showDialog=false" class="ml-auto">
                            <v-icon size="20" color="#fff" >mdi-close-circle</v-icon>
                        </v-btn> 
                </div>
                <v-simple-table dense>
                    <template v-slot:default>
                        <thead class="" v-if="!loading">
                            <tr class="custom-table-bordered ">
                                <th class="text-center text-body-1 text-dark" style="background-color: #fff;" colspan="7"><b>{{isBilling?'Facturación':'Pedidos'}} actual</b></th>
                            </tr>
                            <tr class="custom-table-bordered ">
                                <td class="text-center table-heading text-body-2 ">Mes</td>
                                <td class="text-center table-heading text-body-2 ">Facturación</td>
                            </tr>
                        </thead>
                        <tbody v-show="!loading" class="custom-table-bordered">
                            <tr v-for="(item, index) in billing.FACACT?.FACACT" :key="index">
                                <td :style="index % 2 == 0 ? 'background-color: #fff;' : ''" class="table-heading small text-body-2">
                                    {{dateFormat(item.MESFAC)  }}
                                </td>
                                <td :style="index % 2 == 0 ? 'background-color: #fff;' : ''" class="table-heading text-body-2">
                                    ${{ number_format(item.MONFAC) }}
                                </td>
                            </tr>
                            <tr style="">
                                <td colspan="2" style=" " class="table-heading small text-body-2 billing-total">
                                    <v-row>
                                        <v-col cols="6" class="pa-3">
                                            <b class="ml-n9">Total=</b>
                                        </v-col>
                                        <v-col cols="6">
                                            <b class="ml-n9"> {{ number_format(billing.TOTALFACACT) }}</b>
                                        </v-col>
                                    </v-row>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-if="loading">
                            <tr>
                                <td colspan="7">
                                    <v-progress-linear class="mt-3" indeterminate color="teal"></v-progress-linear> <span class="text--disabled"> Cargando información</span>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <v-simple-table dense v-if="!loading">
                    <template v-slot:default>
                        <thead class="">
                            <tr class="custom-table-bordered ">
                                <th class="text-center text-body-1 text-dark" colspan="7"><b>{{isBilling?'Facturación':'Pedidos'}} Anterior</b></th>
                            </tr>
                            <tr class="custom-table-bordered ">
                                <td class="text-center table-heading text-body-2 ">Mes</td>
                                <td class="text-center table-heading text-body-2 ">Facturación</td>
                            </tr>
                        </thead>
                        <tbody v-if="!loading" class="custom-table-bordered">
                            <tr v-for="(item, index) in billing.FACANT?.FACANT" :key="index">
                                <td :style="index % 2 == 0 ? 'background-color: #fff;' : ''" class="table-heading small text-body-2">
                                {{ dateFormat(item.MESFAC)  }}
                                </td>
                                <td :style="index % 2 == 0 ? 'background-color: #fff;' : ''" class="table-heading text-body-2">
                                    ${{ number_format(item.MONFAC) }}
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" style="" class="table-heading small text-body-2 billing-total">
                                    <v-row>
                                        <v-col cols="6" class="pa-3">
                                            <b class="ml-n9">Total=</b>
                                        </v-col>
                                        <v-col cols="6">
                                            <b class="ml-n9"> ${{ number_format(billing.TOTALFACANT) }} </b>
                                        </v-col>
                                    </v-row>
                                </td>
                            </tr>
                            <tr class="resume-foot">
                                <td colspan="2">
                                    <div class="d-flex space-around justify-space-around flex-nowrap">
                                        <b> Crecimiento = </b>
                                        <b>{{ number_format(billing.PORCREC - 100,false) }}%</b>
                                    </div> 
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <div v-if="!loading" class="mt-3">
                    <v-btn @click="showDialog=false" dense class="success">
                        Aceptar
                    </v-btn>
                </div> 
            </v-card>
        </v-dialog>
    </div>
    </template>
    
    <script> 
    
    import MixinHelpers from '@/mixins/helpers';
    import axios from "@/plugins/axios";
    
    export default ({
     
        props: {
            title: { 
                default: 'Detalle de crecimiento acumulado' 
            },
            option: {
                default: 0 //1 Seller /2: Manager | 3  Director
            },
            type: {
                default: 1 //1: normal, 2: 3 vs 3 months, 3: 1 vs 3 months 
            },
            employeNumber: {
                default: 0
            },
            isBilling :{
                Boolean,
                default:true
            },
            period :{ 
                default: 202401
            },   
            employeeChannel :{ 
                default:''
            },  
            value: { 
                type: Boolean,
                default: false
            },
            regionalManager:{
                default:0,
            }
        }, 
        mixins: [MixinHelpers],
        name: "CumulativeGrowth",
        data() {
            return {
                loading: false,
                billing: [],
                refreshData:true,
                typesOfGrowth:{
                    1:'', // normal
                    2:'_3M' , // 3 months
                    3:'_1X3', // 1 vs 3 months 
                },
                rols:{
                    1:{ url:'/manager/home/cumulative-growth-details'}, // Asesor
                    2:{ url:'/daily-director/cumulative-growth'},  //gte
                    3:{ url:'/daily-director/cumulative-growth' }, // Director 
                },
            }
        }, 
        watch:{
            showDialog(value){
            if (value) this.cumulativeGrowthDetails(this.period)
            },
            period(value){
            if (value && this.employeeChannel && this.employeeNumber) {
                this.cumulativeGrowthDetails(value)
            }
            }
        },
        mounted(){
            //this.cumulativeGrowthDetails();
        },
        methods: { 
            dateFormat(date){  
                const month = date.substring(4);
                const year = date.substring(0, 4); 
                return `${month}/${year}`; 
                },
             
            cumulativeGrowthDetails(){ 
            this.loading=true; 
            const isSeller = this.option == 1;
            const period = `${this.period}`
            var params = { 
                        isSeller:isSeller ? 1 : 0,
                        isBilling:this.isBilling ? 1 : 0,
                        type:this.type ? this.type : "",
                        period: period,
                        channel:this.employeeChannel,
                        manager:this.employeNumber,
                        seller:this.employeNumber,
                        }; 
            if (this.regionalManager) params.rgManager = this.regionalManager
            axios.post(this.rols[this.option].url,params).then((res) => { 
                //Cuando solo viene un elemento
                if (res.data.FACACT.FACACT?.MESFAC) {
                    res.data.FACACT.FACACT = [res.data.FACACT.FACACT];
                }
                if (res.data.FACANT.FACANT?.MESFAC) {
                    res.data.FACANT.FACANT = [res.data.FACANT.FACANT];
                }
                this.billing = res.data;
                this.loading = false;  
          })
          .catch((err) => { 
                console.error(err);
                this.loading = false;
              }) ;  
            } 
        },
        computed: {
        showDialog: { 
            set(value) { 
            this.$emit('input', value);
            },
            get(){
            return this.value
            }
        }
  },
    
    })
    </script>
    
    <style scoped>
    .resume-foot{
        background: #FFD75E 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
    border: 1px solid #DDE1E6;
    }
    .billing-total {
        background-color: #fff;-webkit-box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.75);
    box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.75)
    }
    </style>
    