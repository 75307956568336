<template>
  <v-list class="dropdown-cointainer">
    <v-list-item-title class="submenu-title dropdown-text">{{ menuText }}</v-list-item-title>
    <div
      v-for="item, index in filteredItems" 
      :key="index"
      class="dropdown-child"
    >
      <v-list-item 
        v-if="!item.external"
        :class="{ 'no-link': !item.path && (!item.menu || item.menu.length <= 0 ) }"
        :to="item.path && { name: item.path, params: item.params }"
        class="py-0 dropdown-child-size"
      > 
        <v-list-item-title v-if="!item.menu" class="custom-padding dropdown-text">{{ item.text }}</v-list-item-title>
        <v-menu v-else :disabled="item.menu.length <= 0" right bottom offset-x offset-y rounded="0">
          <template v-slot:activator="{ on, attrs }">
            <v-list-item-title class="submenu-arrow custom-padding dropdown-text pr-1" v-bind="attrs" v-on="on">{{ item.text }}</v-list-item-title>
          </template> 
          <sub-menu :items="item.menu" :menu-text="item.text"/>
        </v-menu>
      </v-list-item>
      <v-list-item 
        v-else
        :class="{ 'no-link': !item.path }" 
        :href='item.path'
        target="_blank"
        class="dropdown-child-size"
      > 
        <v-list-item-title v-if="!item.menu" class="dropdown-text">{{item.text}}</v-list-item-title>
      </v-list-item>
    </div>
  </v-list>  
</template>

<script>
export default {
  name: 'sub-menu',

  props: {
    menuText: {
      type: String,
      default: '',
    },

    items: {
      type: Array,
      default: () => []
    },
  },

  computed: {
    filteredItems () {  
      if (!this.items) return null;
      
      return this.items;
    },
  }
}
</script>

<style scoped>
.dropdown-cointainer {
  border: 1px solid #000 !important;
  text-align: left;
  padding: 0;
}  

.dropdown-child:hover {
  background: rgba(255,0,0,0.2);
}

.dropdown-text {
  font-size: 14px;
}

.dropdown-child {
  border-bottom: 1px solid #000;
  min-width: 180px;
  cursor: pointer;
}

.dropdown-child-size {
  min-height: auto;
  padding: 5px;
}

.submenu-arrow::after{
  font-family: 'Material Design Icons';
  content: '\F0142' !important;
  font-size: 25px;
  line-height: 15px;
  position: absolute;
  color: #C51616;
  right: 0;
}

.dropdown-cointainer .dropdown-child:last-child{
  border-bottom: 0px;
}

.no-link, .no-link > div {
  cursor: no-drop;
}

.custom-padding {
  padding-top: 5px;
  padding-bottom: 5px;
}

.submenu-color {
  background: #CCC;
}

.submenu-title {
  padding: 5px;
  background: #f2f2f2;
  font-weight: bold;
  display: none;
}

@media (max-width:958px){
  .submenu-title {
    display: block;
  }
}
</style>