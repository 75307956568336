<template>
  <v-dialog
    v-model="showDialog"
    hide-overlay
    transition="dialog-bottom-transition"
    width="600px"
  >
    <v-card>
      <v-toolbar dark color="indigo">
        <v-btn icon dark @click="showDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>DETALLE COTIZA</v-toolbar-title>
      </v-toolbar>

      <v-subheader class="font-weight-bold mt-2">Cotiza: {{ orderNumber }}</v-subheader>

      <v-list class="text-left font-weight-bold px-4">
        <v-subheader class="list-header">TEXTOS CABECERA</v-subheader>
          <v-list-item v-for="(item, i) in inputs" :key="i">
            <v-list-item-content>
              <v-list-item-title class="">{{ getHeader(item) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
      </v-list>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
	name: 'OrderHeadersModal',

	props: {
		value: {
			type: Boolean,
			default: true
		},

		orderNumber: {
			type: String,
			default: null
		},

		headers: {
			type: Object,
			required: true
		},
	},

	data () {
		return {
			inputs: [
        {
          label: 'Tiempo de entrega',
          key: 'TIEMENT',
        },
        {
          label: 'Term. de entrega',
          key: 'TERMEMT',
        },
        {
          label: 'Destino final',
          key: 'DESTFIN',
        },
        {
          label: 'Vía de embarque',
          key: 'VIAEMBA',
        },
        {
          label: 'Linea naviera',
          key: 'LINENAV',
        },
        {
          label: 'Cargos fletes',
          key: 'CARGFLE',
        },
        {
          label: 'Cargos seg.',
          key: 'CARGSEG',
        },
        {
          label: 'Contenedor',
          key: 'CONTENE',
        },
        {
          label: 'Marcas',
          key: 'MARCAS',
        },
        {
          label: 'Origen',
          key: 'ORIGEN',
        },
        {
          label: 'Base metal',
          key: 'BASEMET',
        },
        {
          label: 'Condiciones pago',
          key: 'CONDPAG',
        },
        {
          label: 'Documentación',
          key: 'DOCUMEN',
        },
        {
          label: 'Validez oferta',
          key: 'VALIOFE',
        },
        {
          label: 'Empaque',
          key: 'EMPAQUE',
        },
        {
          label: 'Tolerancia',
          key: 'TOLERAN',
        },
        {
          label: 'Notas',
          key: 'NOTAS',
        },
        {
          label: 'Atención cotización',
          key: 'ATENCOT',
        },
      ],
		}
	},

  methods: { 
    getHeader (header) {
      const headerValue = this.headers[header.key] || '';

      return `${header.label}: ${headerValue}`;
    }
  },  

	computed: {
		showDialog: {
			set (newVal) {
				this.$emit('input', newVal)
			},

			get () {
				return this.value;
			}
		}
	}

}
</script>

<style scoped>
::v-deep .v-list-item:nth-child(even) {
  background: #f7f5f5
}

::v-deep .v-list-item:nth-child(odd) {
  background: #FFF
}

.list-header {
  background-color: #343A40;
  font-weight: bold;
  color: white !important;
}
</style>