<template>
    <div class="text-center glosary__content">
        <v-dialog
        v-model="dialog"
        width="850"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                v-bind="attrs"
                v-on="on"
                class="glosary__button"
                >
                Glosario
                    <v-icon class="ml-1">
                        mdi-alpha-g-box-outline
                    </v-icon>
                </v-btn>
            </template>

            <v-card>
                <v-card-title class="glosary__title">
                    Glosario        
                    <v-icon class="close-icon" @click="dialog = false">mdi-close-circle</v-icon>                   
                </v-card-title>

                <v-card-text class="text-left p-4 glosary__text">
                    <v-row>
                        <v-col cols="12" sm="6">
                            <h2>NOTAS DE CRÉDITO</h2>
                            NCR <br><br>

                            <p>201 - Descuentos por pronto pago</p> 
                            <p>202 - Descuentos por volumen</p> 
                            <p>203 - Descuento comercial</p> 
                            <p>204 - Cancelación de facturas mes sig a emis.</p> 
                            <p>205 - Devolución material - mal estado transp. </p>
                            <p>206 - Devolución material defectuoso p/fabric. </p>
                            <p>207 - Devolución por error al facturar </p>
                            <p>208 - Devolución por fuera de especificación </p>
                            <p>209 - Devolución por sobre inventario del cliente</p> 
                            <p>210 - Devolución de material en consignación</p> 
                            <p>211 - Material faltante </p>
                            <p>212 - Diferencia del precio</p> 
                            <p>213 - Aplicación anticipo de clientes</p> 
                            <p>214 - Intereses moratorios</p> 
                            <p>215 - Fletes</p> 
                            <p>216 - Pagos fuera de nómina y sobre no cobr.</p> 
                            <p>217 - Multas por atraso en entregas (Gob)</p> 
                            <p>218 - Subsidio por ventas de exportación</p> 
                            <p>219 - Por negoc. clientes fact, mismo ejerc.</p> 
                            <p>220 - Por negoc. clientes fact. ejerc. ant.</p> 
                            <p>221 - Muestras</p> 
                            <p>222 - Material por reposición</p> 
                            <p>223 - Comisión por cheque devuelto</p> 
                            <p>224 - 20% por concepto de devolución de material</p> 
                            <p>225 - Comisión bancaria</p> 
                            <p>226 - Devolución a petición del cliente</p> 
                            <p>227 - Devolución por material faltante EmpCdo.</p>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <h2>NOTAS DE CARGO</h2>
                            NCA <br><br>

                            <p>101 - Descuentos por pronto pago</p> 
                            <p>102 - Descuentos por volumen</p> 
                            <p>103 - Descuento comercial</p> 
                            <p>104 - Cancelación de facturas mes sig. a emis.</p> 
                            <p>105 - Devolución material - mal estado transp</p> 
                            <p>106 - Devolución material defectuoso p/fabric.</p> 
                            <p>107 - Devolución por error al facturar</p> 
                            <p>108 - Devolución por fuera de especificación</p> 
                            <p>109 - Devolución por sobre inventario del cliente</p> 
                            <p>110 - Devolución de material en consignación</p> 
                            <p>111 - Material faltante </p>
                            <p>112 - Diferencia en precio</p>       
                            <p>113 - Aplicación anticipo de clientes</p> 
                            <p>114 - Intereses moratorios</p> 
                            <p>115 - Fletes</p> 
                            <p>116 - Pagos fuera de nómina y sobres no cobr.</p> 
                            <p>117 - Multas por atraso en entregas (Gob)</p>       
                            <p>118 - Subsidio por ventas de exportación</p> 
                            <p>119 - Por negoc. clientes fact. ejerc. ant.</p> 
                            <p>120 - Por negoc. clientes fact. ejerc. ant.</p> 
                            <p>121 - Muestras</p>    
                            <p>122 - Material por reposición</p> 
                            <p>123 - Comisión por cheque devuelto</p> 
                            <p>124 - 20% por concepto de devolución de material</p> 
                            <p>125 - Comisión bancaria</p> 
                            <p>126 - Devolución a petición del cliente</p> 
                            <p>127 - Devolución por material faltante EmpCdoD.</p>
                        </v-col>
                    </v-row>
                </v-card-text>        
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    data(){
        return{
            dialog: false,
        }
    }
}
</script>

<style scoped>
    .glosary__title{
        background: #000 !important;
        color: #FFF;
        font-size: 12px !important;
        display: flex;
        justify-content: space-between;
        line-height: auto !important;
        padding: 5px 10px !important;
    }

    .glosary__title .v-icon{
        color: #FFF;
    }

    .glosary__text{
        font-size: 14px !important;
    }

    .glosary__text h2{
        font-size: 18px !important;
        margin-bottom: 0;
        color: #3874A8
    }

    .glosary__text p{
        margin-bottom: 0;
    }

    .glosary__button{
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
        border: 1px solid #707070;
        border-radius: 5px;
        opacity: 1;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        letter-spacing: -0.2px;
        color: #090909;
        text-transform: inherit;
        width: 100%;
        max-width: 300px;
    }

    .glosary__button .v-icon{
        font-size: 18px !important;
    }
</style>