<template>
  <div class="bg-white">
    <div class="card-title">
      <h1 class="font-weight-black">
        <v-icon large class="text-black"> mdi-cash</v-icon>
        SOLICITUDES DE ACTUALIZACIÓN DE PAGARÉS
      </h1>
    </div>
    <v-container>
      <v-row>
        <v-col cols="12" md="4"></v-col>
        <v-col cols="12" md="4" class="text-left">
          <label for="" class="text-left">Filtro por estado:</label>
          <v-select
            :items="status"
            item-text="status"
            item-value="id"
            multiple
            v-model="params.status"
          ></v-select>
        </v-col>
        <v-col cols="12" md="4" class="text-left">
          <label for="" class="text-left">Búsqueda:</label>
          <v-text-field v-model="params.search" clearable></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn
            color="primary"
            @click="loadPromissoryNotes"
            :loading="loading"
          >
            <v-icon>mdi-refresh</v-icon>
            ACTUALIZAR
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-data-table :headers="headers" :items="items" :options.sync="options" :server-items-length="total">
          <template v-slot:[`item.status`]="{ item }">
            {{ item.status?.status }}
          </template>
          <template v-slot:[`item.files`]="{ item }">
            <v-btn
              small
              dark
              @click="showFile(item.card_id_path)"
              class="mr-1"
              title="Identificación"
            >
              <v-icon>mdi-card-account-details-outline</v-icon>
            </v-btn>
            <v-btn
              small
              dark
              @click="showFile(item.promissory_notes_path)"
              title="Pagaré"
            >
              <v-icon>mdi-account-credit-card-outline</v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              color="success"
              fab
              x-small
              dark
              @click="showValidationModal(item)"
              class="mr-1"
              v-if="[2].includes(item.custumer_status_id)"
            >
              <v-icon>mdi-check</v-icon>
            </v-btn>
            <v-btn
              color="error"
              fab
              x-small
              dark
              v-if="[2].includes(item.custumer_status_id)"
              @click="cancel(item)"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.comments`]="{ item }">
            <v-btn color="info" @click="showComments(item)" small>
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="loadPromissoryNotes">
              Recargar
            </v-btn>
          </template>
        </v-data-table>
      </v-row>
    </v-container>
    <div class="w-full d-flex footer mt-10">
      <p class="text-left w-50">*Última fecha de actualización:</p>
      <p class="text-right w-50">
        Información confidencial. Prohibida su reproducción o divulgación total
        o parcial, así como su uso o aprovechamiento sin autorización escrita de
        IUSA.
      </p>
    </div>

    <v-dialog v-model="observationsModal" width="600px">
      <v-card>
        <v-card-text>
          <v-timeline align-top dense>
            <v-timeline-item
              color="primary"
              small
              v-for="observation in observations"
              :key="`obs-${observation.id}`"
            >
              <v-row class="pt-1">
                <v-col cols="3">
                  <strong>{{ observation.creado }} hrs.</strong>
                </v-col>
                <v-col>
                  <strong>{{ observation.user?.name }}</strong>
                  <div class="text-caption">
                    {{ observation.comment }}
                  </div>
                </v-col>
              </v-row>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="validationModal" width="600px">
      <v-card>
        <v-card-title>Validación del pagaré</v-card-title>
        <v-card-text>
          <v-row class="">
            <v-col cols="12">
              <label for="">Asigna línea de crédito del nuevo pagaré</label>
              <input class="form-control" type="text" v-mask="currencyMask" v-model="myInputModel" placeholder="">
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="cancelValidationModal">
            Cancelar
          </v-btn>
          <v-btn color="primary" @click="validate">
            Aceptar Pagaré
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "@/plugins/axios";
import { mapGetters } from 'vuex';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const currencyMask = createNumberMask({
  prefix: '$',
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: false,
});

export default {
  name: "PromissoryNotes",
  components: { },
  data: () => ({
    loading: false,
    items: [],
    observationsModal: false,
    validationModal: false,
    observations: [],
    options: {},
    total: 0,
    headers: [
      {
        text: "Asesor",
        align: "start",
        sortable: false,
        value: "seller",
      },
      { text: "Fecha (dd/mm/aaaa)", value: "created" },
      { text: "Nº Cliente", value: "customer_number" },
      { text: "Nombre del cliente", value: "customer_name" },
      { text: "Estatus", value: "status" },
      { text: "Observaciones del asesor", value: "comments", align: "center" },
      { text: "Archivos", value: "files" },
      { text: "Acciones", value: "actions" },
    ],
    breadcrumbsItems: [
      {
        text: "IEL",
        disabled: false,
        href: "/",
      },
    ],
    status: [],
    params: {
      status: [],
      search: null,
    },
    currencyMask,
    myInputModel: '',
    promissory: {},
  }),

  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },

  watch: {
    options: {
      handler () {
        this.loadPromissoryNotes()
      },
      deep: true,
    },
  },

  mounted() {
    this.breadcrumbsItems.push({
      text: this.user.VTWEG == "DT" ? "Minoristas" : "Privados",
      disabled: false,
      href: "/",
    });
    this.loadCustomerStatus();
  },
  methods: {
    async loadCustomerStatus() {
      return axios
        .get("customer-status")
        .then((response) => {
          this.status = response.data;
          return response.data;
        })
        .catch((error) => {
          this.$swal.fire({
            title: "Ocurrio un error al recuperar los estatus",
            body: error,
          });
          throw error;
        });
    },
    loadPromissoryNotes() {
      this.loading = true;
      let params = [];
      const { page, itemsPerPage } = this.options;

      params.push(`page=${page}`);
      params.push(`perPage=${itemsPerPage}`);

      if (this.params.status.length > 0) {
        params.push(`filter[custumer_status_id]=${this.params.status.join(",")}`)
      }

      if (this.params.search && this.params.search.length > 0) {
        params.push(`search=${this.params.search}`)
      }

      axios
        .get(`/promissory-notes?${params.join('&')}`)
        .then((response) => {
          this.total = response.data.total;
          this.items = response.data.data.map((item) => {
            return {
              ...item,
              seller: item.user?.name,
              created_at: item.created_at,
              customer_number: item.customer.custumer_code,
              customer_name: item.customer.company_name,
            };
          });
        })
        .catch((error) => {
          this.$swal({
            title: "Ocurrio un error al recuperar los pagarés",
            text: error,
            type: "warning",
          });
        })
        .finally(() => (this.loading = false));
    },
    async validate() {
      const amount = this.cleanCurrencyString(this.myInputModel)
      
      if(amount >= 100000000) {
        this.$toast.warning('El monto máximo es: 10 Millones');
        return;
      }

      try {
        let response = await axios.put(
          `/customers/${this.promissory.custumer_data_id}/promissory-notes/${this.promissory.id}`,
          {
            validated: true,
            amount: amount,
          }
        );
        if (response.data.value?.promissory_note) {
          let index = this.items.findIndex((element) => element.id == this.promissory.id);
          if (index > -1) {
            this.items[index].custumer_status_id =
              response.data.value?.promissory_note.custumer_status_id;
          }
          this.$swal.fire({
            title: `YA SE LIBERÓ A CRÉDITO Y COBRANZA`,
            icon: "success",
            type: "success",
            confirmButtonText: "Confirmar",
            confirmButtonColor: "#3085d6",
          });
          this.cancelValidationModal();
        }
      } catch(error)  {
        this.$swal.fire({
          title: `${error}`,
          icon: "warning",
          type: "warning",
          confirmButtonText: "Ok",
          confirmButtonColor: "#3085d6",
        });
      }
    },
    showComments(item) {
      this.observations = item.comments;
      this.observationsModal = true;
    },
    cancel(item) {
      this.$swal({
        title: "AGREGA TUS COMENTARIOS DEL POR QUÉ SE RECHAZA",
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        icon: "error",
        confirmButtonText: "Confirmar",
        confirmButtonColor: "#3085d6",
        showLoaderOnConfirm: true,
        preConfirm: async (comment) => {
          try {
            let response = await axios.put(
              `/customers/${item.custumer_data_id}/promissory-notes/${item.id}`,
              {
                validated: false,
                comment,
              }
            );
            let index = this.items.findIndex(
              (element) => element.id == item.id
            );
            console.log(index);
            if (index > -1) {
              this.items[index].custumer_status_id =
                response.data?.promissory_note.custumer_status_id;
            }
            this.$swal.fire({
              title: `SOLICITUD RECHAZADA CORRECTAMENTE`,
              icon: "success",
              type: "success",
              confirmButtonText: "ACEPTAR",
              confirmButtonColor: "#3085d6",
            });
            return response.data;
          } catch (error) {
            console.log(error);
          }
        },
        allowOutsideClick: () => !this.$swal.isLoading(),
      });
    },
    showFile(file) {
      window.open(`${this.$filesUrl}/${file}`, "_blank");
    },
    showValidationModal(promissory) {
      this.promissory = promissory;
      this.validationModal = true;
    },
    cancelValidationModal() {
      this.promissory = {};
      this.validationModal = false;
    },
    cleanCurrencyString(currencyString) {
      let amount = currencyString.replace(/[^\d.]/g, '');
      let newamount = parseFloat(amount)
      return newamount;
    },
  },
};
</script>

<style scoped>
.card-title {
  padding-left: 3em;
  padding-right: 3em;
  margin-top: 1em;
  margin-bottom: 1em;
}
.card-title h1 {
  border: 4px solid #000;
  border-radius: 5px;
  font-size: 1.2em;
  padding-top: 0.3em;
  padding-bottom: 0.2em;
}
.footer {
  padding-left: 3em;
  padding-right: 3em;
}
</style>
