<template>
    <div>
      <div class="d-flex flex-wrap" style="background-color: #000; color:#fff">
        <div class="text-left ml-3 mt-1 pl-4 pr-4" >% Clientes con pago vía IEL de {{ period?.full_name }}</div> 
            <v-btn v-if="!loading" icon @click="closeModal()" class="ml-auto">
                <v-icon size="20" color="#fff" >mdi-close-circle</v-icon>
            </v-btn> 
        </div>  
          <v-row class="ml-1 mr-1 mt-1 "> 
            <v-col cols="12 ">
              <v-simple-table v-if="!loading" dense>
                <template v-slot:default> 
                  <tbody class="custom-table-bordered">
                    <tr >
                      <th><b>Clientes <br>con&nbsp;pagos</b></th> 
                      <th><b>Clientes&nbsp;activos</b></th> 
                      <th><b>% de clientes con<br>pagos vía IEL</b></th> 
                    </tr>  
                    <tr>
                      <td>{{number_format(data.CTEPAG)}}  </td> 
                      <td>{{number_format(data.CTEACT)}} </td> 
                      <td>{{number_format(data.PAGIEL)}} </td>  
                    </tr>
                  </tbody>
                </template>
          </v-simple-table>

          <v-progress-linear v-else indeterminate color="cyan" ></v-progress-linear>
            </v-col>
          </v-row>
    </div> 
</template>
<script>
import MixinHelpers from '@/mixins/helpers';
import axios from "@/plugins/axios";

export default ({
  //sellers/customers-via-iel
    name:'PaymentInfo'  ,
    props: {   
        manager: { default: 0},   
        period: { default: 0},  
        modal:{ default: true},
        months:{ default: true},
        managerData:{ default: []}, 
    }, 
    mixins: [MixinHelpers],
    data() {
        return {
            loading: false,
            data: [],
            refreshData:true,
        }
    }, 
    watch:{
        modal(value){ 
            if (value) {
                this.customersViaIEL();
            } 
        }, 
    },
    mounted(){
        this.customersViaIEL();
    },
    methods: {
        closeModal(){ 
            this.$emit('closeModal')
        },
        setPaymentClass(index){
          switch (index) {
            case 0: return 'font-weight-bold'
            case 2: return 'text-success font-weight-bold'
            case 3: return 'text-warning font-weight-bold'
            default: return ""
          }
        },
        customersViaIEL(){
        this.loading=true;
        var month = this.period.month
        if (month < 10) { month = `0${month}`}
        const period = `${this.period.year}${month}`
        var params ={ 
          manager:this.managerData.PERNR, 
          period: period,
          channel: this.managerData.VTWEG
        } 
        axios.post('/manager/home/customers-via-IEL-Month',params).then((res) => { 
            res.data.PAYMENTS = res.data.PAYMENTS.slice(1)
            this.data = res.data; 
            console.log(this.data)
            this.loading = false;  
      })
      .catch((err) => { 
            console.log(err);
            this.loading = false;
          }) ;  
        },
        paymentTypeFormat(index,item){
          switch (index) {
            case 0:
              return  `${this.number_format(item)}%`          
            default:
              return `${this.number_format(item)}` 
          }
        }
    }
})
</script>

<style>

.card-p {
  margin: 0;
  padding: 0;
  color: #000;
}

.my-table th,
.my-table td {
  border: 0px 4px 0px 0px solid #dad9d9;
  
}

.my-table {
  width: 100%;
}
.custom-border-right{
  border-right-width: 2px;
}
.table-heading {
  font-weight: bold;
  font-size: 0.75rem;
  background: rgb(245, 245, 245);
  color: #000;
  border-width: 1px 1px 3px 1px;
  border-color: rgb(228, 231, 234);
  border-bottom-color: rgb(112, 112, 112);
}
.custom-table-bordered  {
  border-width: 2.5px;
  border-color: rgb(224, 228, 232);
}
.custom-table-bordered>tr>td {
  border-width: 2.5px;
  border-color: rgb(224, 228, 232);
}

.custom-table-bordered>th {
  border-width: 2.5px;
  border-color: rgb(224, 228, 232);
}

.table-heading tr {
  color: #000;
}

th.boder-x-none {
  border-right: none;
  border-left: none;
}

th.boder-l-none {
  border-left: none;
}
 
.custom-table-item-sm > tr > td {
  font-size: 0.8em; 
  text-align: center;
  min-width: 50px;
}  
.custom-td-header{
  font-weight: 700;
  background-color: rgb(245, 245, 245); 
} 
.custom-text-yellow{
  color:rgb(224,168,0)
}
.custom-header-title{ 
  background-color: rgb(245, 245, 245); 
}

.custom-white-table > tr > td {
  background-color: white;
  border-width: 1px;
  border-color: lightgray;
}
.custom-table-no-bordered{  
  background-color: white;

}
.border-bottom-0{   
  border-bottom: 0px;
}
.bg-gray {
  
  background-color: rgb(245,245,245);
}
.custom-tr-no-bordered{   
  border-width: 0px 1px 0px 1px;
    border-color: lightgray;
} 
.font-size-85{
  font-size:0.85em;
}
.font-size-8{ 
  font-size:0.8em;
}
.font-size-9{ 
  font-size:0.9em;
}
.no-border{ 
  border-width:0px;
}
.bg-yellow{
  background-color:rgb(255,193,7)
}
</style>