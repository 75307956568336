<template>
   
    <div class="d-flex align-items-center justify-content-center">
        <div> 
            <v-icon color="white">mdi-eye</v-icon> <span class="text-white">Vista</span>
        </div>
        <div class="ml-2" >
            <v-select @input="goto()" v-model="navigationRoute"  :items="navigationItems" class="py-1" style="max-width: 250px;"
            rounded solo
            item-text="text"
            item-value="value"
            hide-details="true"
            dense>
            </v-select>
        </div>
    </div> 

</template>

<script>
import { mapGetters } from "vuex";
export default {
data(){
    return{
        navigationRoute: {},
        navigationItems:[],
        showHeaderOptionsModal: false,
    }
},
mounted(){
    this.navigationRoute = this.$route.name;
},
watch: {
    '$route'() {
        //Especificamos en el select la ruta el cual estamos visitando si
        this.navigationRoute = this.$route.name;
    },
},
created(){ 
    this.generarMenu() 
},
computed:{
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
      userChannel: "auth/channel"
    })
},
methods:{
    goto(){   
      this.$router.push({ name: this.navigationRoute });
    },
    generarMenu(){
      if(this.$hasAnyRole(['Manager', 'IELManager', 'IELSubManager'])){
        this.navigationItems = [
            {text:'Diario', value:'ManagerHome'},
            {text:'Facturación' ,value:'InvoicingManager'},
            {text:'Planeador', value:'PlaneadorManager'},
            {text:'Solicitudes', value: 'Manager'},
            {text:'Solicitudes Pagaré' ,value:'PromissoryNotes'},
            {text:'Renglonaje ' ,value:'RenglonajeManager'},
            {text:'Días hábiles tiempo ciclo de venta' ,value:'DayCustomerDelivery'} 
          ]
    } else if(this.$hasAnyRole(['Submanager'])) {
        this.navigationItems = [
            {text:'Diario', value:'ManagerHome'},
            {text:'Facturación' ,value:'InvoicingManager'},
            {text:'Planeador', value:'PlaneadorManager'},
            {text:'Solicitudes Pagaré' ,value:'PromissoryNotes'},
            {text:'Renglonaje ' ,value:'RenglonajeManager'},
            {text:'Días hábiles tiempo ciclo de venta', value:'DayCustomerDelivery'} 
        ]    
    } else if(this.$hasAnyRole(['Credit'])) {
        this.navigationItems = [
            {text: 'Solicitud de Alta de Cliente', value: 'CreditIndex'},
            {text: 'Historial Altas', value: 'CreditHistory'},
            {text: 'Pagarés', value: 'PromissoryIndex'},
        ];
    } else if(this.$hasAnyRole(['Seller'])){
        this.navigationItems = [
            {text: 'Resultados', value: 'SellerResults'},
            {text:'Planeador', value:'Planeador'},
            {text:'Renglonaje' ,value:'RenglonajeSeller'},
            {text:'Cartera',value:'Briefcase'},
            // { text:'Solicitud de Alta de Cliente', value: 'RequestsSeller'},
            { text: 'Vínculos', value: 'Vinculos' },
            { text: 'Panel IUSASOL', value: 'Panelsol' },
            { text: 'Órdenes de Compra', value: 'OCPendientes' },
            { text: 'Presupuesto', value: 'QuotationStatistics' }
          ]

          if(this.userChannel == 'PR'){
            this.navigationItems.push({ text: 'Panel IUSASOL', value: 'Panelsol' },
                                     { text: 'Ord Com Merc', value: 'OCPendientes' },              
            )
            this.navigationItems.push({ text: 'Presupuesto', value: 'QuotationStatistics' })
           }
        } else if(this.$hasAnyRole(["Director", "IELDirector", "IELCommercialDT", "IELCommercialPR", "Subdirector"])){
            this.navigationItems = [
               {text:'Facturación' ,value:'InvoicingDirector'},
               {text:'Renglonaje' ,value:'RenglonajeDirector'},
               {text:'Estructura(Director)' ,value:'StructureDirector'},
               {text:'Diario' ,value:'DailyDirector'},
               {text:'Días hábiles de tiempo de ciclo de venta' ,value:'DayCustomerDeliveryDirector'},
               {text:'Mostradores' ,value:'GeneralGroupDataCountersDirector'}
            ]
        }//logistics
        else if(this.$hasAnyRole(["NotificationAdmin"])){
            this.navigationItems = [
               {text:'Notificaciones' ,value:'Notifications'},
            ]
        }//logistics
        else if(this.$hasAnyRole(['IELLogistics'])){
            this.navigationItems = [
               {text:'Ciclo de venta' ,value:'logistics_reports'},
             
            ]
        }else if(this.$hasAnyRole(['RegionalManager', 'IELRegManager'])){
            this.navigationItems = [
               {text:'Facturación' ,value:'InvoicingDirector'},
               {text:'Renglonaje' ,value:'RenglonajeDirector'},
               {text:'Estructura(Gte Regional)' ,value:'StructureRegionalManager'},
               {text:'Diario', value:'DailyRegionalManager'},
               {text:'Días hábiles de tiempo de ciclo de venta' ,value:'DayCustomerDeliveryRegional'},
               {text:'Mostradores' ,value:'GeneralGroupDataCountersRegional'}
            ];

            if(this.userChannel == 'PR'){
                this.navigationItems.push({ text: 'Presupuesto', value: 'QuotationStatistics' })
            }
        }
        else if(this.$hasAnyRole(['IELExpressSales'])){
            this.navigationItems = [
                {text:'Listado de Clientes' ,value:'ResultsExpressSales'},
             
            ]
        }
        this.navigationRoute = this.navigationItems[0];
    }
}    
}
</script>

<style>

</style>