<template>
  <v-container fluid class="grey lighten-3 d-flex flex-wrap justify-center">
    <div class="w-full d-flex justify-center">
      <!-- <v-img src="@/assets/img/logo_iusa_w.svg" style="max-width: 15%"></v-img> -->.
    </div>
    <div class="w-full text-center">
      <div class="w-full d-flex justify-content-center background-blue">
        <v-img style="max-width: 70%" :src="error403"></v-img>
      </div>
      <p>Ir a Solicitud Alta Cliente IUSA en Línea</p>
      <p><a href="/#/client-request" class="text-success">Click aquí</a></p>
    </div>
  </v-container>
</template>
    
<script>
import error403 from "@/assets/img/banner_cd.png";

export default {
    name: 'Forbidden',

    data() {
      return {
        error403: error403
      }
    }
};
</script>
    
<style>
.w-full {
  width: 100%;
}
</style>