import store from "@/store";
//import {hasAnyRole} from "./index"
import SellerResults from '../views/seller/Results.vue';
import HomeView from "../views/HomeView.vue"; 
import CreateRequestSeller from '../views/seller/CreateRequest.vue';
import RequestsSeller from '../views/seller/RequestsSeller.vue';

export const hasAnyRole = (storeroles, roles) => {
  return storeroles.some(role => roles.includes(role.name));
};

export default [
  
    { 
        path: "/resultados/:seller_id?",
        name: "SellerResults",
        component: SellerResults,
        meta: {role: 'Seller'},
        beforeEnter: (to, from, next) => {
          if (!store.getters["auth/authenticated"]) {
            return next({ name: "SignIn" });
          }
            if (hasAnyRole(store.getters["auth/roles"], ['Guest', 'Customer', 'CustomerPR'])) {
            return next({
              name: "403",
            });
          }
          next();
        },
      },    
      {
        //Caratula cliente desde el rol vendedor
        path: "/caratula/",
        name: "CustomerCoverDetails",
        component: HomeView,
        meta: {role: 'Customer'},
        props:true,
        beforeEnter: (to, from, next) => { 
          if (!store.getters["auth/authenticated"]) {
            return next({
              name: "SignIn",
            });
          }
          if (hasAnyRole(store.getters["auth/roles"], ['Guest', 'Customer', 'CustomerPR'])) {
            return next({
              name: "403",
            }); 
          } 
          next();  
        }, 
      },
    {
        path: "/solicitudes",
        name: "RequestsSeller",
        component: RequestsSeller,
        beforeEnter: (to, from, next) => {
          if (!store.getters["auth/authenticated"]) {
            return next({ name: "SignIn" });
          }
          next();
        },
      }, 
    {
        path: "/solicitudes/crear",
        name: "CreateRequestsSeller",
        component: CreateRequestSeller,
        beforeEnter: (to, from, next) => {
          if (!store.getters["auth/authenticated"]) {
            return next({ name: "SignIn" });
          }
          next();
        },
      }, 
]