<template>
    <v-select
        :items="asesores"
        outlined
        v-model="filtro"
        item-text="option"
        item-value="PERNR"
        label="Seleccionar Asesor / Zona"
        @input="$emit('changeSelectAsesor', filtro)"
    ></v-select>
</template>

<script>
export default {
    props:['filtroPERNR', 'clientes'],
    data(){
        return {
            filtro: ''
        }
    },
    watch: {
        filtroPERNR(newVal) {
            this.filtro = newVal;
        }
    },
    computed:{
        asesores(){
            const defaultOption = {
                option: 'Todos los asesores',
                PERNR: ''
            };
            const asesores = this.clientes.map(cliente => {
                return {
                    'option': `${cliente.NAMEV} / ${cliente.BZIRK}`,
                    'PERNR': cliente.PERNR
                };
            });

            asesores.sort((a, b) => a.PERNR - b.PERNR); // Ordenar de mayor a menor por PERNR

            return [defaultOption, ...asesores];
        }  
    }
}
</script>

<style>

</style>