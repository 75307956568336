<template>
  <div>
    <!-- search -->
    <div class="cont-search my-4">
        <v-text-field style="max-width: 250px"
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar"
            solo
            hide-details>
        </v-text-field>
    </div>
    <!-- end search -->  

    <v-data-table
        :headers="headers"
        :items="filteredItems"
        :footer-props="footerProps"
        :options="dataTableOptions"   
        :mobile-breakpoint="null"   
        :loading="isLoading"        
        class="elevation-2 table-renglonaje"                         
    >    
        <template v-slot:[`item.id`]="{ item }">
            {{ materials.indexOf(item) + 1 }}
        </template>

        <template v-slot:[`item.MATNR`]="{ item }">
            <b>{{item.MATNR}} {{item.ARKTX}}</b>
        </template>

        <template v-slot:[`item.ZCOSTO3`]="{ item }">
            <b>${{ item.ZCOSTO3 | formatCurrency}}</b>
        </template>
        
        <template v-slot:[`item.PORACU`]="{ item }">
            {{ item.PORACU | formatCurrency}}%
        </template>

        <template v-slot:[`item.PORACU12`]="{ item }">
            {{ item.PORACU12 | formatCurrency}}%
        </template>
        
        <template v-slot:[`item.PROULT4`]="{ item }">
            ${{ item.PROULT4 | formatCurrency}}
        </template>

        
        <template v-for="index in 10" v-slot:[`item.col${index}`]="{ item }">
            <a href="#" :key="index" @click="getCustomers(item, itemsDates[index-1])">{{ option === 2 ? '$' : '' }}{{ item['col' + index] | removeDecimals }}</a>
        </template>


        <template v-slot:header v-if="materials.length > 0">
            <tfoot>
                <tr>
                    <td></td>
                    <td>Total</td>
                    <td></td>
                    <td></td>
                    <td>{{calcularPromedio('PORACUP') | formatCurrency}}%</td>
                    <td>{{calcularPromedio('PORACU12P') | formatCurrency}}%</td>
                    <td>${{calcularPromedio('PROULT4P') | formatCurrency}}</td>
                    <td>{{option == 2 ? '$' : ''}}{{calcularSumaMes('col1') | formatCurrency}}</td>
                    <td>{{option == 2 ? '$' : ''}}{{calcularSumaMes('col2') | formatCurrency}}</td>
                    <td>{{option == 2 ? '$' : ''}}{{calcularSumaMes('col3') | formatCurrency}}</td>
                    <td>{{option == 2 ? '$' : ''}}{{calcularSumaMes('col4') | formatCurrency}}</td>
                    <td>{{option == 2 ? '$' : ''}}{{calcularSumaMes('col5') | formatCurrency}}</td>
                    <td>{{option == 2 ? '$' : ''}}{{calcularSumaMes('col6') | formatCurrency}}</td>
                    <td>{{option == 2 ? '$' : ''}}{{calcularSumaMes('col7') | formatCurrency}}</td>
                    <td>{{option == 2 ? '$' : ''}}{{calcularSumaMes('col8') | formatCurrency}}</td>
                    <td>{{option == 2 ? '$' : ''}}{{calcularSumaMes('col9') | formatCurrency}}</td>
                    <td>{{option == 2 ? '$' : ''}}{{calcularSumaMes('col10') | formatCurrency}}</td>
                </tr>
            </tfoot>
        </template>
    
    </v-data-table>

    <!-- Model Detalles -->
        <div class="text-center">
        <v-dialog
        v-model="showModal"
        width="850"
        >
            <v-card>
                <v-card-title class="modal-header">
                    Detalle de renglonaje        
                    <v-icon class="close-icon" @click="showModal = false">mdi-close-circle</v-icon>                   
                </v-card-title>

                <v-card-text class="text-left p-4">

                    <div class="modal-title mb-5">
                        <p class="mb-0"><b>Mes {{mesModal}} {{anioModal}}</b></p>
                        <p class="mb-0"><b>{{materialModal}}</b></p>
                    </div>

                    <v-data-table
                        :headers="detalles"
                        :items="customers"  
                        :mobile-breakpoint="null"   
                        :loading="loading"  
                        class="elevation-2 table-renglonaje"                         
                    >    
                        <template v-slot:header>
                            <tfoot>
                                <tr>                                    
                                    <td>Total</td>
                                    <td></td>                                    
                                    <td>{{sumaCantidad}}</td>
                                    <td>${{sumaMonto | formatCurrency}}</td>
                                </tr>
                            </tfoot>
                        </template>
    
                    </v-data-table>
                    
                </v-card-text>        
            </v-card>
        </v-dialog>
    </div>
          <!-- End Model Detalles -->
  </div>
</template>

<script>
import moment from 'moment'
import axios from "@/plugins/axios"
import Service from "@/mixins/service.js"
import { mapGetters } from "vuex";
export default {
    props: ['isLoading', 'items', 'option'],
    mixins: [Service],
    data(){
        return {
            details: [],
            itemsDates: [], 
            headers: [
                { text: 'id', value: 'id', align:"center" },
                { text: 'Código', value: 'MATNR', align:"left" },
                { text: 'Precio distribuidor c/IVA', value: 'ZCOSTO3', align:"center" },
                { text: 'Precio principal Competidor', value: 'NETWRC', align:"center" },
                { text: 'Acumulado', value: 'PORACU', align:"center" },
                { text: 'Crecimiento acumulado 12 meses', value: 'PORACU12', align:"center" },
                { text: 'Prom. últ. 4 meses', value: 'PROULT4', align:"center" },                
                { text: '', value: 'col1', align:"center" },                
                { text: '', value: 'col2', align:"center" },                
                { text: '', value: 'col3', align:"center" },                
                { text: '', value: 'col4', align:"center" },                
                { text: '', value: 'col5', align:"center" },                
                { text: '', value: 'col6', align:"center" },                
                { text: '', value: 'col7', align:"center" },                
                { text: '', value: 'col8', align:"center" },                
                { text: '', value: 'col9', align:"center" },                
                { text: '', value: 'col10', align:"center" },             
            ],
            detalles: [
                { text: 'Cliente', value: 'KUNAG', align:"center" },
                { text: 'Razón Social', value: 'NAME1', align:"center" },
                { text: 'Cantidad', value: 'TOTFAC', align:"center" },
                { text: 'Monto', value: 'TOTMON', align:"center" }          
            ], 
            footerProps: {
                itemsPerPageOptions: [10, 14, 24, 34, { text: 'Todas', value: -1 }],
                itemsPerPageText: 'Mostrar en grupo de:',
                showCurrentPage: false,
                pageText: 'Desplegado {0} / {1} de {2} Materiales'           
            }, 
            dataTableOptions: {
                itemsPerPage: 24,
                pagination: {
                    enabled: true,
                    options: {
                        numeric: true,
                    },
                },
            },
            materials: [],
            customers: [],
            showModal: false,
            mesModal: '',
            anioModal: '',
            materialModal: '',
            search: '',
            loading: false
        }
    },
    mounted(){
        this.calculate()
    },
    watch:{
        option(){
            this.fillMaterials()
        },
        items(){
            this.fillMaterials()
        },
        filteredItems(){
            this.generarExcel()
        }
    },
    computed:{
        ...mapGetters({
            user: 'auth/user'
        }),
        sumaCantidad(){
            return this.customers.reduce((acumulador, objeto) => acumulador + parseInt(objeto.TOTFAC), 0 )
        },
        sumaMonto(){
            return this.customers.reduce((acumulador, objeto) => acumulador + parseFloat(objeto.TOTMON), 0 )
        },
        filteredItems() {
            const searchLowerCase = this.search.toLowerCase();
            return this.materials.filter(item =>
                Object.values(item).some(
                value =>
                    typeof value === 'string' &&
                    value.toLowerCase().includes(searchLowerCase)
                )
            );
        }        
    },
    methods:{
        async getCustomers(material, fecha){
            this.mesModal = moment().month(fecha.month - 1).format('MMMM')
            this.anioModal = fecha.year
            this.materialModal = material.MATNR + ' ' + material.ARKTX
            this.customers = []
            this.showModal = true
            this.loading = true

            let response = await axios.get('renglonaje-director/clientes', {
                params: {
                    'P_MATNR': material.MATNR,
                    'P_SPMON': `${fecha.year}${fecha.month<10 ? 0 : ''}${fecha.month}`,
                    'P_VTWEG': this.user.VTWEG               
                }
            })

            if(response.data.data.length > 1){
                this.customers = response.data.data
            } 
            
             this.loading = false
        },
        calculate(){
            let currentMonth = moment().format('M');
            this.itemsDates = this.calcularUltimosMeses(10, currentMonth)
            
            const now2 = moment();
            const currentYear2 = now2.year()
            this.headers[4].text = `Acum. ${currentYear2} vs ${currentYear2 - 1}`
            this.headers[7].text = this.itemsDates[0].date
            this.headers[8].text = this.itemsDates[1].date
            this.headers[9].text = this.itemsDates[2].date
            this.headers[10].text = this.itemsDates[3].date
            this.headers[11].text = this.itemsDates[4].date
            this.headers[12].text = this.itemsDates[5].date
            this.headers[13].text = this.itemsDates[6].date
            this.headers[14].text = this.itemsDates[7].date
            this.headers[15].text = this.itemsDates[8].date
            this.headers[16].text = this.itemsDates[9].date
        },
        fillMaterials(){
            this.materials = this.items.map(material => {
                return {
                    MATNR: material.MATNR,
                    ARKTX: material.ARKTX,
                    ZCOSTO3: material.ZCOSTO3,
                    NETWRC: material.NETWRC,
                    PORACU: this.option == 1 ? material.PORACUP : material.PORACUM,
                    PORACU12: this.option == 1 ? material.PORACU12P : material.PORACU12M,
                    PROULT4: this.option == 1 ? material.PROULT4P : material.PROULT4M,
                    col1: this.option == 1 ? material.MATDET.MATDET[0].PIEZAS : material.MATDET.MATDET[0].MONTO,
                    col2: this.option == 1 ? material.MATDET.MATDET[1].PIEZAS : material.MATDET.MATDET[1].MONTO,
                    col3: this.option == 1 ? material.MATDET.MATDET[2].PIEZAS : material.MATDET.MATDET[2].MONTO,
                    col4: this.option == 1 ? material.MATDET.MATDET[3].PIEZAS : material.MATDET.MATDET[3].MONTO,
                    col5: this.option == 1 ? material.MATDET.MATDET[4].PIEZAS : material.MATDET.MATDET[4].MONTO,
                    col6: this.option == 1 ? material.MATDET.MATDET[5].PIEZAS : material.MATDET.MATDET[5].MONTO,
                    col7: this.option == 1 ? material.MATDET.MATDET[6].PIEZAS : material.MATDET.MATDET[6].MONTO,
                    col8: this.option == 1 ? material.MATDET.MATDET[7].PIEZAS : material.MATDET.MATDET[7].MONTO,
                    col9: this.option == 1 ? material.MATDET.MATDET[8].PIEZAS : material.MATDET.MATDET[8].MONTO,
                    col10: this.option == 1 ? material.MATDET.MATDET[9].PIEZAS : material.MATDET.MATDET[9].MONTO
                }
            })
        },
        calcularSuma(propiedad) {
            return this.items.reduce((acumulador, objeto) => acumulador + parseInt(objeto[propiedad]), 0);
        },
        calcularPromedio(propiedad) {
            const suma = this.calcularSuma(propiedad);
            return suma / this.filteredItems.length;
        },
        calcularSumaMes(tipo) {
            return this.filteredItems.reduce((acumulador, objeto) => acumulador + parseInt(objeto[tipo]), 0);
        },
        generarExcel(){
            let newItems = []
            this.itemCSV = []
            for (let key in this.materials) {                
                let materiales = this.materials[key];
                newItems.push({
                    [this.headers[0].text]: parseInt(key) + 1,
                    [this.headers[1].text]: materiales.MATNR + ' ' + materiales.ARKTX,
                    [this.headers[2].text]: materiales.ZCOSTO3,
                    [this.headers[3].text]: materiales.NETWRC,
                    [this.headers[4].text]: materiales.PORACU,
                    [this.headers[5].text]: materiales.PORACU12,
                    [this.headers[6].text]: materiales.PROULT4,
                    [this.headers[7].text]: materiales.col1,
                    [this.headers[8].text]: materiales.col2,
                    [this.headers[9].text]: materiales.col3,
                    [this.headers[10].text]: materiales.col4,
                    [this.headers[11].text]: materiales.col5,
                    [this.headers[12].text]: materiales.col6,
                    [this.headers[13].text]: materiales.col7,
                    [this.headers[14].text]: materiales.col8,
                    [this.headers[15].text]: materiales.col9,
                    [this.headers[16].text]: materiales.col10
                });
            }

            const sumaObjeto = {
                    [this.headers[0].text]: '',
                    [this.headers[1].text]: 'TOTAL',
                    [this.headers[2].text]: '',
                    [this.headers[3].text]: '',
                    [this.headers[4].text]: this.calcularPromedio('PORACUP'),
                    [this.headers[5].text]: this.calcularPromedio('PORACU12'),
                    [this.headers[6].text]: this.calcularPromedio('PROULT4'),
                    [this.headers[7].text]: this.calcularSumaMes('col1'),
                    [this.headers[8].text]: this.calcularSumaMes('col2'),
                    [this.headers[9].text]: this.calcularSumaMes('col3'),
                    [this.headers[10].text]: this.calcularSumaMes('col4'),
                    [this.headers[11].text]: this.calcularSumaMes('col5'),
                    [this.headers[12].text]: this.calcularSumaMes('col6'),
                    [this.headers[13].text]: this.calcularSumaMes('col7'),
                    [this.headers[14].text]: this.calcularSumaMes('col8'),
                    [this.headers[15].text]: this.calcularSumaMes('col9'),
                    [this.headers[16].text]: this.calcularSumaMes('col10')
            };

            this.itemCSV = [...newItems, {...sumaObjeto}]
            this.$emit('sendItemCSV', this.itemCSV)
        },
    }
}
</script>

<style scoped>
    .modal-header{
        background: #000 !important;
        color: #FFF;
        font-size: 12px !important;
        display: flex;
        justify-content: space-between;
        line-height: auto !important;
        padding: 5px 10px !important;
    }

    .modal-header .v-icon{
        color: #FFF;
    }

    tfoot{
        background: #f2f2f2;
        text-align: center;
        font-weight: bold;
    }

    .modal-title{
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
        opacity: 1;
        text-align: center;
        padding: 8px;
        border-bottom: 4px solid #c50000;
        font-size: 14px;
    }
</style>