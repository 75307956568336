<template>
    <section>
   
   
        
         
       
   
     <!--START TABLE-->
       <v-simple-table class="my-table w-full " style="margin-top: -8px;">
             <template v-slot:default>
   
               <thead class="table-heading">
            
                 <tr class="mt-n10">
   
                   <!-- Encabezados de tabla para elementos de primera vista -->
               
   
                   <template v-if="!optionsSelected.selectedOfice && !optionsSelected.searchInput && !optionsSelected.selectedVendedor">
                   <th class="text-center text-dark font boder-x-none"  >
                     <a  v-if="!optionsSelected.selectedGerente">
                       Gerente  
                     </a>
                     <a  v-if="optionsSelected.selectedGerente">
                       Vendedor / Zona
                     </a>
                   </th>
                  
                   <th class="text-center text-dark font boder-x-none" >
                     <a>
                       Total N° de pedido
                     </a>
                   </th>
                   <th class="text-center text-dark font boder-x-none">
                     <a>
                       Total pedido de compra
                     </a>
                   </th>
                   <th class="text-center text-dark font boder-x-none">
                     <a>
                       Total de partidas
                     </a>
                   </th>
                   <th class="text-center text-dark font boder-x-none">
                     <a>
                       Monto neto s/IVA del pedido
                     </a>
                   </th>
                   <th class="text-center text-dark font boder-x-none d-none">
                     <a>
                       Fecha captura
                     </a>
                   </th>
                   <th class="text-center text-dark font boder-x-none">
                     <a>
                       N° dias prom.
                     </a>
                   </th>
                   <th class="text-center text-dark font boder-x-none d-none">
                     <a>
                       Fecha Factura
                     </a>
                   </th>
                   <th class="text-center text-dark font boder-x-none">
                     <a>
                       N° dias prom.
                     </a>
                   </th>
                   <th class="text-center text-dark font boder-x-none d-none">
                     <a>
                       Fecha entrega
                     </a>
                   </th>
                   <th class="text-center text-dark font boder-x-none">
                     <a>
                       N° de totales dias promedios
                     </a>
                   </th>
   
         
   
   
                 <!--encabezado de elementos mensales-->
                    <template v-if="!optionsSelected.selectedOfice && optionsSelected.selectedTypePeriod == 'mensual'">
                     
                     
                     <th class="text-center text-dark font boder-x-none mountTh position-relative" v-for="(mounth,index) in mounts" :key="index">
                       <span class="position-absolute d-inline-block title-mount" v-if="index == 0" style="white-space: nowrap;">Dias {{ optionsSelected.selectedTypeDays }} tiempo de ciclo de venta </span>
   
                     <a>
                       {{ mounth.text.name }}
                     </a>
                   </th>
   
                   
                
                    </template>
   
                    <!--encabezado de elementos trimestrales-->
   
                    <template v-if="optionsSelected.selectedTypePeriod == 'trimestral' && !optionsSelected.selectedOfice">
                     
                     <th class="text-dark font boder-x-none mountTh position-relative" style="padding-top:25px" v-for="(trim,index) in trims" :key="index">
                       <span class="position-absolute d-inline-block title-mount" v-if="index == 0" style="white-space: nowrap;">Dias {{ optionsSelected.selectedTypeDays }}  tiempo de ciclo de venta </span>
   
                     <a class="">
                      <span class="d-block" style="margin-bottom: -5px;">Trim</span>
                      <span > {{ trim }}</span>
                     </a>
                   </th>
                
                    </template>
   
   
                   </template>
   
   
              
   
                   <!--fin de  Encabezados de tabla para elementos de primera vista -->
   
                 
                
                
                   <!-- encavezado para segunda seccion de la tabla -->
                   <template v-if="optionsSelected.selectedOfice || optionsSelected.searchInput || optionsSelected.selectedVendedor ">
                      
                        <th class="text-center text-dark font boder-x-none" v-if="!optionsSelected.selectedVendedor && !optionsSelected.searchInput" >
                        <a>
                          Oficina / Cedis
                        </a>
                      </th>
                        <th class="text-center text-dark font boder-x-none"  v-if="optionsSelected.selectedVendedor || optionsSelected.searchInput" >
                        <a>
                          Cliente
                        </a>
                      </th>
                      <th class="text-center text-dark font boder-x-none" >
                        <a>
                         N° de pedido
                        </a>
                      </th>
                      <th class="text-center text-dark font boder-x-none" >
                        <a>
                         Orden de compra
                        </a>
                      </th>
                      <th class="text-center text-dark font boder-x-none" v-if="optionsSelected.selectedVendedor || optionsSelected.searchInput">
                        <a>
                        CEDIS
                        </a>
                      </th>
                   
                      <th class="text-center text-dark font boder-x-none">
                        <a>
                          Referencia
                        </a>
                      </th>
                      <th class="text-center text-dark font boder-x-none">
                        <a>
                          Partidas
                        </a>
                      </th>
                      <th class="text-center text-dark font boder-x-none">
                        <a>
                         Monto neto s/IVA del pedido
                        </a>
                      </th>
                      <th class="text-center text-dark font boder-x-none">
                        <a>
                        Estado
                        </a>
                      </th>
                      <th class="text-center text-dark font boder-x-none">
                        <a>
                          N° de factura
                        </a>
                      </th>
                      <th class="text-center text-dark font boder-x-none">
                        <a>
                          Fecha de captura
                        </a>
                      </th>
                      <th class="text-center text-dark font boder-x-none">
                        <a>
                         N° dias promedio
                        </a>
                      </th>
   
                        <th class="text-center text-dark font boder-x-none">
                           <a>
                             Fecha de factura
                           </a>
                           </th>
                       <th class="text-center text-dark font boder-x-none">
                           <a>
                             N° de dias promedio  
                           </a>
                       </th>
                       <th class="text-center text-dark font boder-x-none">
                           <a>
                             Fecha de entrega    
                           </a>
                       </th>
                       <th class="text-center text-dark font boder-x-none">
                           <a>
                               N° de dias totales  promedio
                           </a>
                       </th>
   
                       
                      
                       <th class="text-center text-dark font boder-x-non position-relative" style="box-shadow: none!important;" >
                           <span class="position-absolute d-inline-block title-backorder" style="white-space: nowrap;">Backorder </span>
   
                           <a>
                              Art.
                           </a>
                       </th>
                       <th class="text-center text-dark font boder-x-none title-backorder hidden-shadow">
                           <a>
                               Monto 
                           </a>
                       </th>
                           
      
                    </template>
                 </tr>
              
              
               
               
             
               </thead>
               <tbody class="custom-table-bordered" v-if="!isLoading">
                 <tr v-for="(item, index) in paginatedItems" :key="index" >
   
                   <template v-if="!optionsSelected.selectedOfice && !optionsSelected.searchInput && !optionsSelected.selectedVendedor">
   
                   <td class="text-center">
                     <!-- show manager -->
                     <span v-if="!optionsSelected.selectedGerente" >  {{ item.SNAME }} ({{ item.items[0].PERNR2 }})</span> <!--SNAME CONTIENEN EL NOMBRE DEL GERENTE DEL ARRAY QUE AGRUPA-->
                     <!--show vendedor Zona-->
                    <span  v-if="optionsSelected.selectedGerente && !optionsSelected.selectedOfice" >  {{ item.SNAME }}  </span>
                    <span v-if="optionsSelected.selectedOfice && optionsSelected.selectedGerente">{{ item.CEDIS }}</span>
                   </td>
   
                   <td class="text-center">
                     <!--total de pedidos de gerente-->
                  
   
                     <span v-if="!optionsSelected.selectedVendedor">
                       {{totalPedidosGerente(item)}}
                     </span>
                  
                   </td>
   
                   <td class="text-center">
                          <!--total de pedidos en monto de gerente mas iva (pendiente de revisar)-->
                     ${{ !optionsSelected.selectedGerente ? totalPedidoscompraGerenteIva(item) : totalPedidoscompraGerenteIva(item) }}  
   
                     
                    </td>
   
                   <td class="text-center">
                  <!--total de partidas-->
                     {{!optionsSelected.selectedGerente ? getPartidasGerente(item) : getPartidasGerente(item)}} 
                  </td>
   
                 <td class="text-center">
                   <!--monto neto del pedido-->
                   ${{ !optionsSelected.selectedGerente ? totalPedidoscompraGerente(item) : totalPedidoscompraGerente(item) }}  
   
                  
                  </td>
   
                 <td class="text-center d-none">
                   <!--fecha de captura-->
                  {{ item.AUDAT }}
                  
                 </td>
   
                 <td class="text-center">
                <!--calcular   dias  promedio entre fecha de captura y fecha de factura -->
                     <!--    {{ !optionsSelected.selectedGerente ? averageDays(item) : averageDays(item) }}  --> 
                      {{ !optionsSelected.selectedGerente ? averageDaysCapFac(item,'capFac') : averageDaysCapFac(item,'capFac') }} <!--numero de de dias promedio entre fecha de captura y fecha de factura-->
                    
                 </td>
   
                 <td class="text-center d-none">
                   <!--fecha de <entrega-->
                    {{ item.FACUS }}
                    
                 </td>
   
                 <td class="text-center">
                     
                     {{ !optionsSelected.selectedGerente ? averageDaysCapFac(item,'facEnt') : averageDaysCapFac(item,'facEnt') }} <!--numero de de dias promedio entre fecha de captura y fecha de factura-->
   
                </td>
   
                 <td class="text-center d-none">
                   
                 </td>
   
                 <td class="text-center">
                   
                 {{!optionsSelected.selectedGerente ? averageDaysCapFac(item,'capEnt') : averageDaysCapFac(item,'capEnt') }}  <!--fecha de capturacion y fecha de entrega-->
                  
                 </td>
   
                 <!--mounts body-->
                 <template v-if="optionsSelected.selectedTypePeriod == 'mensual'">
                     <td v-for="month,index in mounts" :key="index" class="text-center">
                       {{ !optionsSelected.selectedGerente ? getDaysPerMonth(item, month.text) : getDaysPerMonth(item, month.text) }}
                    </td>
                </template>
   
            
               
                   <!--trim body-->
                 <template v-if="optionsSelected.selectedTypePeriod == 'trimestral'">
   
                   <td class="text-center" v-for="(trim,index) in trims" :key="index">
                     {{ !optionsSelected.selectedGerente ? getOrdersPerTrimester(item, index + 1) : getOrdersPerTrimester(item, index + 1) }}
   
                 </td>
               
                   
                 </template>
   
               </template>
   
             <!--seccion body segunta tabla-->
                <template v-if="optionsSelected.selectedOfice || optionsSelected.searchInput ||  optionsSelected.selectedVendedor ">
                 <td
                  v-if="!optionsSelected.selectedVendedor && !optionsSelected.searchInput">{{ item.CEDIS }}
                 </td>
                 <td v-if="optionsSelected.selectedVendedor || optionsSelected.searchInput">
                   <span >{{ item.NAME1 }} </span><span>{{ item.KUNNR }}</span>
                 </td>
                 <td>
                   <div class="d-flex align-items-center"> 
                      {{ item.VBELN }} 
                       <img src="/img/icon-iusa.svg" alt="logo iusa" style="width: 5%;" class=" mx-2 " v-if="item.CAPIUSA == '1'"> 
                       <img src="/img/icon-vendedor.svg" alt="icon vendedor" style="width: 5%;" class=" mx-2" v-if="item.CAPCTE == '1'"> 
                       <img src="/img/icon-nocomprado.svg" alt="icon vendedor" style="width: 5%;" class=" mx-2 " v-if="item.PNC == '1'"> 
                        <div class="text-pnc mx-2 d-none" >PNC </div>
                   </div>
   
                   <div class="d-flex justify-content-between">
                      <span class="fw-bold">Peso:</span>{{ item.PNETO }}kg  <span class="fw-bold ml-2">Volumen:</span>{{ item.VOLUM }}m3</div>
                  </td>
                  <td>{{ item.BSTNK}}</td>   <!--cedis y orden de compra-->
   
                 <td v-if="optionsSelected.selectedVendedor || optionsSelected.searchInput">{{ item.CEDIS }}</td>   <!--cedis-->
                 <td><!--referencia-->{{ item.BSTNK }}</td>
                 <td>
                   <!--numero de partidas-->
                   {{ item.NUMPART }}</td>
                 <td>
                   
                     ${{ item.NETWR1 ? parseFloat(item.NETWR1).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00' }}
   
                  </td>
                 <td class="text-center fw-medium"  :class="{'text-danger': item.STATUS === 'CANCELADO', 'text-primary': item.STATUS === 'ENTREGADO','text-success' : item.STATUS === 'FACTURADO','purple-text' : item.STATUS === 'BLOQUEADO','text-info': item.STATUS === 'PEDIDO GENERADO'}">{{ item.STATUS }}
                 </td>
                 <td>
                   {{ item.VBELN_VF ? item.VBELN_VF : '' }}  <img   src="/img/book-open-variant.svg" alt="icon-factura" class="mr-1"  v-if="!item.VBELN_VF">
                </td>
                 <td>
                   {{ item.AUDAT }}<!--fecha captura-->
                 </td>
                 <td>{{!optionsSelected.selectedGerente ? getDaysPromedio(item.AUDAT,item.BSTDK) :  getDaysPromedio(item.AUDAT,item.BSTDK)}}</td>
                 <td>
                   {{ item.BSTDK }}<!--fecha factura-->
                 </td>
                 <td>{{ !optionsSelected.selectedGerente ? getDaysPromedio(item.BSTDK,item.FACUS) : getDaysPromedio(item.BSTDK,item.FACUS) }} </td>
                 <td>
                   {{ item.FACUS }}<!--fecha entrega-->
                 </td>
                 <td>{{!optionsSelected.selectedGerente ? getDaysPromedio(item.AUDAT,item.FACUS) : getDaysPromedio(item.AUDAT,item.FACUS) }}</td>
                 <td>0</td> <!--art-->
                 <td>0</td>  <!--monto-->
                </template>
   
   
   
   
                 </tr>
   
   
   
                 
                
             
               </tbody>
               <tfoot v-if="!isLoading && !items.length < 1 ">
             <tr>
   
               <template v-if="!optionsSelected.selectedOfice && !optionsSelected.searchInput && !optionsSelected.selectedVendedor" >
               <td class="text-center"><span v-if="!optionsSelected.selectedGerente" >Total Direccion: {{ numGerentes() }}</span> <span  v-if="optionsSelected.selectedGerente" >Total gerencias {{ items.length }}</span></td>
               <td class="text-center">
                
                 <span v-if="!optionsSelected.selectedVendedor">
                   {{totalNPedido()}}
                 </span>
               </td> <!--numero total de pedidos-->
               <td class="text-center">${{ !optionsSelected.selectedGerente ? getTotalesPedidoCompraGerentes() : getTotalesPedidoCompraGerentes() }}</td>  <!--monto toal de pedido coniva ( revisar )-->
               <td class="text-center">{{!optionsSelected.selectedGerente ?  getTotalPartidasGerente() : getTotalPartidasGerente() }}</td>   <!--total partidas-->
               <td class="text-center">${{ !optionsSelected.selectedGerente ? getTotalesPedidoCompraGerentesSIva() : getTotalesPedidoCompraGerentesSIva() }}</td>
               <td class="text-center d-none"></td>
               <td class="text-center">{{!optionsSelected.selectedGerente ? getTotalesDiasCicloVida('capFac') : getTotalesDiasCicloVida('capFac')}}</td>  <!--totales dias promedio entre fecha captura y fecha factura-->
               <td class="text-center d-none"></td>
               <td class="text-center">{{!optionsSelected.selectedGerente ? getTotalesDiasCicloVida('facEnt') : getTotalesDiasCicloVida('facEnt')}}</td>
               <td class="text-center d-none"></td>
               <td class="text-center">{{!optionsSelected.selectedGerente ? getTotalesDiasCicloVida('capEnt') : getTotalesDiasCicloVida('capEnt')}}</td>
   
               <!--mounths-->
               <template v-if="optionsSelected.selectedTypePeriod == 'mensual'">
                 <td class="text-center mountTd" v-for="(month,index) in mounts" :key="index">
                    {{!optionsSelected.selectedGerente ? totalDaysPromedio(month.text) : totalDaysPromedio(month.text) }}
                    
                    
               </td>
               </template>
              
             <template v-if="optionsSelected.selectedTypePeriod == 'trimestral'">
               <td class="text-center mountTd" v-for="(trim,index) in trims" :key="index">
   
                 {{!optionsSelected.selectedGerente ? totalDaysPromedioTrim(index+1) : totalDaysPromedioTrim(index+1) }}
   
               </td>
             </template>
   
           </template>
   
          
               <!--end mounths-->
   
               <!--section two-->
   
               <template v-if="optionsSelected.selectedOfice || optionsSelected.searchInput || optionsSelected.selectedVendedor " >
                 <td class="text-center">Total Gerencia</td>
                 <td class="text-center">{{ items.length }}</td>
                
                 <td class="text-center"><span>{{ items.length }}</span></td>
                 <td class="text-center"></td>
                 <td class="text-center" v-if="optionsSelected.selectedVendedor || optionsSelected.searchInput  "></td>
                 <td class="text-center">{{ getTotalPartidas() }}<!--sumatoria total de total partidas de cada pedido individial--></td>
                 <td class="text-center">${{ getTotalMontoNeto() }}</td>
                 <td class="text-center"></td>
                 <td class="text-center"></td>
                 <td class="text-center"></td>
                 <td class="text-center">{{getTotalDias('capFac')}}</td> <!--se obtiene la sumatoria de los dias pomedio-->
                 <td class="text-center"></td>
                 <td class="text-center">{{getTotalDias('facEnt')}}</td>
                 <td class="text-center"></td>
                 <td class="text-center">{{getTotalDias('capEnt')}}</td>
                 <td class="text-center"> 0 </td>
                 <td class="text-center"> 0 </td>
               </template>
            
             </tr>
           </tfoot>
             </template>
           </v-simple-table>
         <div style="border: solid 2px #eaecef">
          <div v-if="isLoading == true">
             Cargando datos ...
           </div>
           <div v-if="paginatedItems.length < 1 && isLoading == false">
             No hay datos para mostrar
           </div>
            <!-- Controles de paginación -->
         <div class="d-flex">
           <div style="margin-left: auto" class="me-5 py-2 mr-5 d-flex align-items-center"> 
                   <div class="me-1">
                     Mostrar en grupo de: 
                   </div>   
                   <div style="width:80px">
                       <v-select :hide-details="true"  v-model="itemsPerPage" placeholder="10" :items="[10,15,30,50]" solo small></v-select>
                   </div> 
               </div>
   
       <div class="ml-5 d-flex align-items-center justify-content-center" > 
                   <div class="ma-1  font-weight-bold">
                     {{ currentPage}} de : {{ totalPages }}
                   </div>   
                   <div class="d-flex">
                       <v-btn :disabled="currentPage==0" icon @click="prevPage">
                         <v-icon>mdi-chevron-left</v-icon>
                         </v-btn>
                         <v-btn  :disabled="totalPages==(currentPage)" icon @click="nextPage">
                         <v-icon>mdi-chevron-right</v-icon>
                         </v-btn>
                   </div>  
               </div>
   
         </div>
        
         </div>
       
   
    </section>
   </template>
   
   <script>
   import moment from 'moment';
   export default {
    // props: ['items','selectedView','searchTerm','isLoading'],
    props: {
       items: Array,
       selectedView: String,
       optionsSelected: Object,
       isLoading: Boolean,
      
     },
     debounceTimeout: null,
     data() {
       return {
         mounts: [
         
         ],
         // Array de nombres de meses
      nombresMeses : [
      { "posicion": 1, "name": "Ene", "nombrecompleto": "January" },
       { "posicion": 2, "name": "Feb", "nombrecompleto": "February" },
       { "posicion": 3, "name": "Mar", "nombrecompleto": "March" },
       { "posicion": 4, "name": "Abr", "nombrecompleto": "April" },
       { "posicion": 5, "name": "May", "nombrecompleto": "May" },
       { "posicion": 6, "name": "Jun", "nombrecompleto": "June" },
       { "posicion": 7, "name": "Jul", "nombrecompleto": "July" },
       { "posicion": 8, "name": "Ago", "nombrecompleto": "August" },
       { "posicion": 9, "name": "Sep", "nombrecompleto": "September" },
       { "posicion": 10, "name": "Oct", "nombrecompleto": "October" },
       { "posicion": 11, "name": "Nov", "nombrecompleto": "November" },
       { "posicion": 12, "name": "Dic", "nombrecompleto": "December" }
   ],
         trims: [
          
         ],
         itemsPerPage: 10, // Número de elementos por página
         currentPage: 1, // Página actual
         
       
       };
     },
     computed:{
     totalPages() {
      
       const numItems = Object.keys(this.items).length;
     
       return Math.ceil(numItems / this.itemsPerPage);
   
       
     },
     paginatedItems() {
         const startIndex = (this.currentPage - 1) * this.itemsPerPage;
         const endIndex = startIndex + this.itemsPerPage;
         return this.items.slice(startIndex, endIndex);
       },
   
   },
   watch:{
     // where change items currentPage = 1
     items(){
       this.currentPage = 1;
     }
   },
   
     methods:{
       nextPage() {
         if (this.currentPage <= this.totalPages) {
           this.currentPage++;
          
         }
         
       },
       prevPage() {
         if (this.currentPage > 1) {
           this.currentPage--;
         }
       },
       filterItems() {
         // Esto se activará cada vez que cambie el valor del campo de búsqueda
         this.currentPage = 1; // Restablecer la página actual al filtrar
       },
       numGerentes(){
         const gerentesUnicos = new Set();
         this.items.forEach(item => {
           gerentesUnicos.add(item.SNAME);
         });
         return gerentesUnicos.size;
       },
       totalPedidosGerente(gerente){
        
        return gerente.items.length;
      },
       totalNPedido(){
         let totalPedidos = 0 ;
         for (let item of this.items) {
           totalPedidos += this.totalPedidosGerente(item);
         }
         return totalPedidos;
       },
       averageDays(item) {
      const numitems = item.items.length;
     let totalDays = 0;
     for (let pedido of item.items) {
       if (pedido.AUDAT && pedido.FACUS) {
         
         const fechaInicio = moment(pedido.AUDAT); // Convertir la fecha de inicio a objeto Moment
         const fechaEntrega = moment(pedido.FACUS); // Convertir la fecha de entrega a objeto Moment
   
          if (fechaInicio.isValid() && fechaEntrega.isValid()) {
           const diferenciaDias = fechaEntrega.diff(fechaInicio, 'days');
         
           totalDays += diferenciaDias; // Sumar la diferencia de días al total
         }
       }
     }
   return (totalDays / numitems).toFixed(2);
   
   },
   averageDaysCapFac(pedido, OP) {
         let result = 0;
         let count = 0;
         let diferencia = 0;
   
         for (let item of pedido.items) {
           let init, finish;
   
   
   
             if (OP === 'capFac') {            //recupera las fechas de captura y facturacion
               init = moment(item.AUDAT, 'DD/MM/YYYY');  
               finish = moment(item.BSTDK, 'DD/MM/YYYY');  
             } else if (OP === 'facEnt') {  //recupera fechas de facturacion y entrega
               init = moment(item.BSTDK, 'DD/MM/YYYY');  
               finish = moment(item.FACUS, 'DD/MM/YYYY'); 
                
             }else if(OP === 'capEnt'){   //recupera fechas de captura y entrega que es el ciclo completo de un pedido
               init = moment(item.AUDAT, 'DD/MM/YYYY');  
               finish = moment(item.FACUS, 'DD/MM/YYYY');  
                 if (!finish || !finish.isValid()) {    // si no se tiene una fecha de entrega se toma el dia de hoy
                   finish = moment(); // Establecer finish como la fecha actual
                 }
   
   
             }
   
           if (init.isValid() && finish.isValid()) {    // una ves se tiene la fecha de inicio y fin del calculo se hace la operacion
   
             //identificamos si el calculo se ara con dias naturales o dias habiles
            
              if(this.optionsSelected.selectedTypeDays === 'habiles'){
                 //   console.log('se selecciono dias habiles')
                    diferencia =  this.getDaysHabiles(init,finish)
              }else{
                   //console.log('se selecciono dias naturales')
                    diferencia = finish.diff(init, 'days');
              }
             result += diferencia;
             count++;
   
            
           }
         }
   
           result = result/count;
           result = (result) ? Math.round(result) : 0;
      /// console.log(count)
         return result; //15.54   777
       },
     totalPedidoscompraGerente(gerente){
       let totalPedidosCompra = 0; // Inicializar el total de pedidos de compra        
       for (let item of gerente.items) {
        let monto = item.NETWR1 ? item.NETWR1 : 0;
         monto = parseFloat(monto);
         totalPedidosCompra += monto; 
       } 
   
       return totalPedidosCompra.toLocaleString('en-US', { maximumFractionDigits: 2 });
     },
     totalPedidoscompraGerenteIva(item){  //calcula la sumatoria de los pedidos de cada gerente
   
        let res = 0;
        let iva = 0;
     
         res = this.totalPedidoscompraGerente(item);
         res = res.replace(/[^0-9.-]/g, ''); 
         res = parseFloat(res);
         iva = res*0.16;
         res = res + iva 
         
         
   
         return res.toLocaleString('en-US', { maximumFractionDigits: 2 });  
   
     },
     getDaysPerMonth(item, month) {
      
       const orders = item.items.filter(order => {
           // Separar la fecha en partes (día, mes, año) utilizando el carácter "/"
           const parts = order.AUDAT.split('/');
           // Obtener el mes de la segunda parte (índice 1)
           const orderMonth = parseInt(parts[1], 10);
           // Comparar el mes de la orden con el mes asociado a monthInfo
           return orderMonth === month.posicion;
       });
       let sum = 0; // Corregir aquí, inicializar sum como 0
   
       if(orders.length > 0){
       
           for (let order of orders) {
            
        
             const fechaInicio = moment(order.AUDAT, 'DD/MM/YYYY');
             let fechaEntrega = moment(order.FACUS, 'DD/MM/YYYY');
   
             if(!fechaEntrega.isValid()){
   
               fechaEntrega = moment();
   
             }
     
             if (fechaInicio.isValid() && fechaEntrega.isValid()) {
   
               if(this.optionsSelected.selectedTypeDays == 'habiles'){
   
                    sum += this.getDaysHabiles(fechaInicio,fechaEntrega);
            
               }else{
            
                 const diferenciaDias = fechaEntrega.diff(fechaInicio, 'days');
                 sum += diferenciaDias; // Sumar la diferencia de días al total
   
               }
                 
                
   
               
               
             }
       }
       }
   
       let res = sum / orders.length;
       res = Math.round(res);
   
   
       return  sum ? res : 0; // Calcular el promedio de días o devolver 0 si no hay órdenes
   },
   
   getDaysHabiles(init, finish){
     let result = 0 ;
     let dateInit = moment(init);
     let dateFinish = moment(finish);
     while(dateInit.isBefore(dateFinish)){
       if (dateInit.day() !== 0 && dateInit.day() !== 6) {
         result++;
       }
       dateInit.add(1, 'days');
     }
   
    
   
     return result;
   
   },
   getOrdersPerTrimester(item, trimester) {
    // console.log('el trimeste actual es :'+trimester)
       const orders = item.items.filter(order => {
           // Separar la fecha en partes (día, mes, año) utilizando el carácter "/"
           const parts = order.AUDAT.split('/');
           // Obtener el mes de la segunda parte (índice 1)
           const orderMonth = parseInt(parts[1], 10);
           // Obtener el trimestre correspondiente a ese mes
           const orderTrimester = Math.floor((orderMonth - 1) / 3) + 1;
           // Comparar el trimestre de la orden con el trimestre proporcionado
           return orderTrimester === trimester;
       });
   
       let sum = 0; // Corregir aquí, inicializar sum como 0
       if(orders.length > 0){
   
   
       
       for (let order of orders) {
        
    
         const fechaInicio = moment(order.AUDAT, 'DD/MM/YYYY');
         const fechaEntrega = moment(order.FACUS, 'DD/MM/YYYY');
         let diferenciaDias
   
         if (fechaInicio.isValid() && fechaEntrega.isValid()) {
   
          if(this.optionsSelected.selectedTypePeriod == 'natulales'){
             diferenciaDias = fechaEntrega.diff(fechaInicio, 'days')
          }else{
   
         diferenciaDias =  this.getDaysHabiles(fechaInicio,fechaEntrega);
   
          }
           
             
            
   
             sum += diferenciaDias; // Sumar la diferencia de días al total
            
           
         }
   }
   }
   
   
   return  Math.floor(sum / orders.length) || 0; // Calcular el promedio de días o devolver 0 si no hay órdenes
   
   }
   ,
   totalDaysPromedio(month) {
     let total = 0 ;
   
     for (let item of this.items) {
       total += parseFloat(this.getDaysPerMonth(item, month)) ;
      
   
     }
     if(total % 1 !== 0){
       let result = total/this.items.length
       return Math.round(result);
     }else{
       let result = total/this.items.length
       return Math.round(result);
     }
   
         
   },
   totalDaysPromedioTrim(trim) {
     let totaltrim = 0 ;
   
     for (let item of this.items) {
       totaltrim += this.getOrdersPerTrimester(item,trim);
     
   
      
   
     }
   
         return totaltrim;
   },
   getTotalPartidas(){
     let totalPartidas = 0;
     for (let item of this.items) {  
       totalPartidas += parseInt(item.NUMPART);
      
     }
     return totalPartidas;
     
   },
   getTotalMontoNeto(){
     let totalMontoNeto = 0;
     for (let item of this.items) {
       let monto = item.NETWR1 ? item.NETWR1 : 0;
       monto = parseFloat(monto);
       totalMontoNeto += monto; 
     } 
     return totalMontoNeto.toLocaleString('en-US', { minimumFractionDigits: 2 });  
   },
   getTotalesPedidoCompraGerentes(){  //calcula la sumatoria de los totales de pedido de compra con iva (revisar)
     let total = 0;
   
     for (let item of this.items) {
       let num = this.totalPedidoscompraGerenteIva(item).replace(/,/g, '');  // coniva
   
        num = parseFloat(num);
       
       total += num;
      // console.log(total)
   
     
     }
     return total.toLocaleString('en-US', { minimumFractionDigits: 2 });  
   
   
   },
   getTotalesPedidoCompraGerentesSIva(){   //sin iva
     let total = 0;
   
   for (let item of this.items) {
     let num = this.totalPedidoscompraGerente(item).replace(/,/g, '');
   
      num = parseFloat(num);
     
     total += num;
    // console.log(total)
   
   
   }
   return total.toLocaleString('en-US', { minimumFractionDigits: 2 });  
   },
   getTotalesDiasCicloVida(OP){
   
     let total = 0;
   
    // console.log(OP);
     for(let item of this.items){
   
       let num = parseFloat(this.averageDaysCapFac(item,OP))
       total += num;
       
   
     }
    
     let result = total/this.items.length
     
   return Math.round(result) ;
   
   },
   getPartidasGerente(gerente){ //partidas agrupadas de cada gerente
   
     let totalPartidas = 0;
     for (let item of gerente.items) {
       totalPartidas += parseInt(item.NUMPART);
       //console.log(item.NUMPART)
   
     }
     return totalPartidas;
   },
   
     getTotalPartidasGerente(){
       let totalPartidas = 0;
       for (let item of this.items) {
         totalPartidas += this.getPartidasGerente(item);
       }
       
       return totalPartidas;  
   
     },
     getDaysPromedio(init,finish){
       let initDate =  moment(init, 'DD/MM/YYYY');
       let finishDate =  moment(finish, 'DD/MM/YYYY');
       let result;
   
       if(!finishDate.isValid()){
            finishDate = moment()
       }
   if(this.optionsSelected.selectedTypeDays === 'habiles'){
   
      result = this.getDaysHabiles(initDate,finishDate);
   
   }else{
     result = finishDate.diff(initDate,'days');
   
   }
       result = result/2;
       result = Math.round(result);
       
       return (result) ? result : 0;
   
     },
     getTotalDias(op){  //suma todos los dias resultantes de la diferencia de dos fechas/no
   
       let total = 0;
   
       for(let item of this.items){
   
         if(op === 'capFac'){
           total+= this.getDaysPromedio(item.AUDAT,item.BSTDK)
   
         }else if(op === 'facEnt'){
           total+= this.getDaysPromedio(item.BSTDK,item.FACUS)
         }else if(op === 'capEnt'){
           total+= this.getDaysPromedio(item.AUDAT,item.FACUS)
         }
   
       }
       let result = total/this.items.length;
       result = Math.round(result)
       return result ? result : 0 ;
   
     },
   
   
   
   
   
   
   
   
   
   
       getMounts(){
   
         const fechaActual = new Date();
         // Obtener el mes actual
         let mesActual = fechaActual.getMonth();  
   
      for (let i = 0; i < 8; i++) {
         const mesIndex = (mesActual - i + 12) % 12; // Asegurar que el índice del mes esté dentro del rango [0, 11]
         this.mounts.push({ text: this.nombresMeses[mesIndex]});
     }
   
       },
       getTrimesters() {
       const fechaActual = new Date();
       let year = fechaActual.getFullYear(); // Obtener el año actual
       let trimester = Math.floor(fechaActual.getMonth() / 3) + 1; // Obtener el trimestre actual
       
       for (let i = 0; i < 6; i++) { // Recuperar 6 trimestres (5 anteriores al actual y el propio actual)
           if (trimester === 0) { // Si el trimestre es 0, significa que estamos en el último trimestre del año anterior
               trimester = 4;
               year--;
           }
           this.trims.push(`${year}/${trimester}`);
           trimester--;
       }
   }
   
       
     },
   
     mounted(){
     
       this.getMounts();
       this.getTrimesters();
     }
   };
   </script>
   
   
   <style scoped>
   
   .text-pnc{
     background-color:#ae001f;
       font-size: 10px;
       color: white;
       display: flex;
       align-items: center;
       padding: 0px 5px;
       max-height: 40px!important;
   }
   
   .breadcrumbroles--path .v-input{
     height: 44px!important;
   }
   
   .title-backorder{
     
     
     
     padding: 2px 10px;
     font-size: 15px;
     top: 0px!important;
     left: 13px!important;
     font-weight: 800;
     }


     /*----------- */
     .my-table a, .my-table tfoot td{
          font-weight:bolder!important;
        
        }
    
        .my-table thead,.my-table tfoot{
          box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
          background-color: #f2f2f2;
          font-weight: bold;
        }
    
        .my-table th,.my-table tfoot td{
          box-shadow: inset 0px 0px 6px #00000029, 0px 0px 6px #00000029;
          
        }
    
        .mountTh,.mountTd{
          box-shadow: none!important;
        
         
        }
        .mountTh{
         
          vertical-align: bottom;
         
        }
    
        b{
          font-size: 14px;
        }
    
        .title-mount{
        background-color: red;
        color: white;
        border-radius:15px;
        padding: 2px 10px;
        font-size: 10px;
        top: 5px ;
    
        }
    
        .title-backorder{
      
      
      
        padding: 2px 10px;
        font-size: 15px;
        top: 5px;
        left: 30px;
        font-weight: 800;
        }
    
        .hidden-shadow{
          box-shadow: inset 3px 0px 1px #f2f2f2, 54px 105px 121px #00000029!important;
    
        }
        
    
        @media (max-width: 600px) {
          .table-heading tr th{
            padding: 0px!important;
           }
           .title-backorder{
            left:-1px!important;
            font-size:11px;
           }
    }
    
    .section-info{
      width: 99%;
      margin-top: 150px;
      padding:5px 0px ;
    }
    .v-text-field__details{
      display: none!important;
    }
    
    /* Estilo para hacer que el select se vea similar a uno de Vuetify */
    .v-select {
    
      font-size: 14px;
      border: 1px solid #ced4da; 
      border-radius: 4px; 
      padding: 2px 10px;
      width: 100%; 
      background-color: #fff; 
      color: #495057; 
      outline: none; 
    }
    
    /* Estilo para el hover del select */
    .v-select:hover {
      border-color: #80bdff; 
    }
    
    /* Estilo para el foco del select */
    .v-select:focus {
      border-color: #007bff;
    }
   
   </style>