<template>
    <v-container fluid>
      <template>
        <v-row>
        <v-col>
        <v-card cols="12" class="pa-xs-2 pa-md-2 pa-lg-2 mt-xs-0 mt-md-0 mt-lg-0 mb-7">
          <v-card-title>REPORTE EXISTENCIAS</v-card-title>
          <v-row>
            <v-col md="6" >
            </v-col>
            <v-col md="6">
              <v-row>
                <v-col md="4"></v-col>
                <v-col md="7">
                  <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <br>
          <v-data-table
            dense
            :headers="headers"
            :items-per-page="10"
            :items="data"
            :search="search"
            :sort-desc="[true]"
            item-key="mateiral_code"
            no-data-text="No hay datos disponibles"
          >
          </v-data-table>
          <br>
          <v-row class="mb-5">
            <v-col md="6">
            </v-col>
            <v-col md="6" class="text-right">
  
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
          <!-- Dialog loading -->
    <v-row justify="center">
        <v-dialog v-model="loading" hide-overlay persistent width="300">
          <v-card color="primary" dark>
            <v-card-text>
              Cargando
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>

      </template>
    </v-container>
  </template>
  <script>
  import axios from "@/plugins/axios";
  import { mapGetters } from "vuex";
  export default {
    computed: {
      ...mapGetters({
        user: "auth/user",
      }),
    },
    data: () => ({
      id_order: null,
      data:[],
      loading: false,
      search: "",
      dialog: false,
      seller: [],
      headers: [
        { text: "Código", align: "start", value: "mateiral_code" },
        { text: "Descripción", align: "start", value: "description" },
        { text: "UMB", align: "start", value: "meins" },
        { text: "Centro", value: "center" },
        { text: "Grupo de materiales 4", value: "group_4" },
        { text: "Libre Utilizacion del centro de suministro", sortable: false, value: "stock" },
        { text: "Libre Utilizacion del CDPT", sortable: false, value: "stock_cdpt" },
        { text: "Empaque", sortable: false, value: "pack" },
      ],
    }),
    mounted() {
      let self = this;
      self.getCustomerInformation();

      this.email_user = this.user.email;
      this.cartItems = this.cartIte;
    },
    methods: {
        getCustomerInformation() {
      axios
        .post("getCustomerInformation", {
          client_code: this.user.custumer_code,
          society: "217",
          organization: "IUS2",
          channel: "DT",
        })
        .then((response) => {
          this.seller = response.data.PERNR;
          this.getReportStock(response.data.PERNR);
        });
    },
    getReportStock(seller) {
            this.loading = true;
        axios
          .post("getReportStock", {
            VTWEG: this.user.VTWEG,
            VKORG: this.user.VKORG,
            seller: seller,
          })
          .then((response) => {
            this.data = response.data;
          })
          .catch((error) => {
            //alert(error);
            console.log(error);
          })
          .finally(() => (this.loading = false));
      },
  },
  };
  
  
  
  </script>
    