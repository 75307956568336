<template>
  <div>
    <v-btn
      @click="showModal = true"
      class="text-capitalize button-red-round"
      dark
      small
      :block="!noFull"
    >
      Cambiar password
    </v-btn> 

    <update-password-modal 
      v-model="showModal"
      :no-persistent="noPersistent"
    />
  </div>
</template>

<script>
import UpdatePasswordModal from './modals/UpdatePasswordModal.vue';

export default {
  name: 'ChangePasswordControl',

  components: { UpdatePasswordModal },

  props: {
    noPersistent: Boolean,
    noFull: Boolean,
  },

  data() {
    return {
      showModal: false
    }
  }
}
</script>