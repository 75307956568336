<template> 
    <div>
        <div class="text-left pl-3" style="background-color: #000; color:#fff">
                Zonas con venta
              </div>
              <div class="text-right mt-n6 mr-1" style="background-color: #000; color:#fff">
                <v-icon size="20" color="#fff" @click="closeModal()">mdi-close-circle</v-icon>
              </div>
              <v-row class="ma-1 "> 
                <v-col cols="12">
                  <v-simple-table dense class="my-table">
                <template v-slot:default>
                  <thead>
                    <tr class="custom-table-bordered-outine">
                      <th   class="text-center font-weight-bold" >
                        <span >
                        Dif:&nbsp;12
                        </span> 
                       </th> 
                      <th colspan="5"  class="text-center font-weight-bold">
                        <span  >
                        Promedio:&nbsp;39
                        </span> 
                       </th> 
                    </tr>
                    <tr  class="custom-table-bordered text-center  bg-gray">
                      <th class="text-primary">May/23</th>
                      <th>Abr/23</th>
                      <th>Mar/23</th>
                      <th>Feb/23</th>
                      <th>Ene/23</th> 
                    </tr>
                  </thead>
                  <tbody class="custom-table-bordered">               
                    <tr class=" text-center">
                      <td class="text-primary">27</td>
                      <td>30</td>
                      <td>34</td>
                      <td>45</td>
                      <td>48</td> 
                    </tr>                     
                  </tbody>
                </template>
              </v-simple-table>
                </v-col>
              </v-row> 
    </div> 
    </template>
    <script>
    export default ({
      methods: {
        closeModal(){
          this.$emit('closeModal')
        }
      }, 
        name:'CustomerSales',
    });
    </script >
    <style scoped>
    .my-table th,
    .my-table td {
      border: 0px 4px 0px 0px solid #dad9d9;
      
    }
    .bg-gray {
      
      background-color: rgb(245,245,245);
    }
    .my-table {
      width: 100%;
    }
    .custom-table-bordered  {
      border-width: 2.5px;
      border-color: rgb(224, 228, 232);
    }
    .custom-table-bordered-outine{
        border-width: 2.5px ;
      border-color: rgb(224, 228, 232);
    }
    .custom-table-bordered>tr>td {
      border-width: 2.5px;
      border-color: rgb(224, 228, 232);
    }
    
    .custom-table-bordered>th {
      border-width: 2.5px;
      border-color: rgb(224, 228, 232);
    }
    
    
    </style>