import axios from '@/plugins/axios';

export const getMaterialInfoPR = async (materialId, requiredQty, { customer, VKORG, VTWEG, VKBUR }) => {
  const materialData = {
    code: materialId,
    units: requiredQty,
    customer,
    VKORG,
    VTWEG,
    VKBUR,
  };

  const response = await axios.post('get-material-info-pr', materialData);

  return response.data;
}

export const getMaterialInfoKit = async (materialId, requiredQty, { customer, VKORG, VTWEG, VKBUR }) => {
  const materialData = {
    materialId,
    requiredQty,
    customer,
    VKORG,
    VTWEG,
    VKBUR,
  };

  const response = await axios.post('/kit/get-material', materialData);

  return response.data;
}