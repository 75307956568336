<template>   
    <div class="bg-white"> 
        <v-container fluid class="page__container--padding">                

        <div class="cont-shadow bloq-height">
            <div class="d-flex align-items-center">
                <SearchCustomerSeller></SearchCustomerSeller>

                <div class="ml-auto">
                    <a href="#"><img src="img/notebook.svg"></a>
                </div>
            </div>
        </div>        

        <div class="cont-shadow py-2 d-flex justify-content-center align-items-center" style="background: rgb(245, 245, 245)">
            <div style="max-width:250px !important" class="mr-4">
                <v-select
                    :items="optionsSelect"
                    item-text="name"
                    item-value="value"
                    dense
                    solo
                    hide-details
                    v-model="optionSelected"
                    class="select__input--red"
                ></v-select>
            </div>
            
            <v-radio-group v-model="optTrimOrMonth" :disabled="isLoading"  hide-details class="mt-0">
                <div class="d-flex align-items-center">
                    <div class="mr-4">
                        <v-radio                                                 
                            label="Trimestral"
                            color="primary"
                            :value="1"      
                            hide-details                                             
                        ></v-radio>
                    </div>                            
                    <div>
                        <v-radio                                                          
                            label="Mensual"
                            color="primary"
                            :value="2" 
                            hide-details                   
                        ></v-radio> 
                    </div>                            
                </div>
            </v-radio-group>                     
        </div>
        
        <v-simple-table class="elevation-2 table__desing-general" > 
            <template v-slot:default>
                <thead style="background: rgb(245, 245, 245)">
                    <tr>
                        <th class="text-center">Canal(*)</th>
                        <th class="text-center" v-for="header, index in headerGeneral" :key="index">
                            {{header.text}}
                        </th>                
                    </tr>
                </thead>
                <tbody>                   
                    <template v-if="!isLoading">
                        <tr>
                            <td>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon @click="openModalDivisionGeneral()" v-bind="attrs"
                                        v-on="on" color="#B71C1C">mdi-chart-pie</v-icon> 
                                    </template>
                                    <span>Participación / Divisiones</span>
                                </v-tooltip>
                                <span class="ml-2">Minorista</span>
                            </td>  
                            <td v-for="(average, index) in averageGeneral" :key="index">
                                {{validateFormatItem(average)}}
                            </td> 
                        </tr>
                    </template>                    
                </tbody>
            </template>
        </v-simple-table>        

        <div class="cont-shadow bloq-height">
            <div class="row">
                <div class="col-xs-12 col-md-4 col-lg-2">
                    <div class="d-flex align-items-center">                           
                        <label class="filter-text">Agrupar por:</label>
                        <v-select
                            :items="optionGroups"
                            item-text="name"
                            item-value="id"
                            v-model="groupBy"
                            hide-details                                 
                            small
                            dense
                            solo 
                            class="ml-2" 
                            :disabled="isLoading" 
                            return-object                        
                        ></v-select>
                    </div>
                </div> 

                <div class="col-md-4 col-12 col-lg-2">
                    <div class="d-flex align-items-center">                           
                        <label class="filter-text">Periodo:</label>
                        <v-select
                            :items="optionMonths"
                            item-text="month"
                            item-value="number"
                            v-model="monthPeriod"
                            hide-details                                 
                            small
                            dense
                            solo
                            placeholder="Mes"  
                            class="ml-2"  
                            :disabled="isLoading"                                   
                        ></v-select>

                        <v-select
                            :items="optionYears"
                            v-model="yearPeriod"
                            hide-details                                 
                            small
                            dense
                            solo
                            placeholder="Año"
                            class="ml-2"  
                            :disabled="isLoading"                                      
                        ></v-select>
                    </div>
                </div> 

                <div class="col-md-4 col-lg-8 d-flex align-items-center justify-content-end">
                    <v-checkbox
                    v-model="showMoreColums"
                    :label="`Mostrar más ${optTrimOrMonth == 1 ? 'trimestres' : 'meses'}`"
                    hide-details
                    class="mt-0"
                    ></v-checkbox>
                </div>
            </div>
        </div>

        <!-- table -->
        <v-data-table    
            :headers="header"
            :items="assignItems"
            :footer-props="footerProps"
            :options="dataTableOptions"   
            :mobile-breakpoint="null"   
            :loading="isLoading"        
            class="elevation-2 table__desing-general table__alternating-rows--grey"> 

            <template v-slot:[`item.FULLNAME`]="{ item }">
                <div class="d-flex justify-content-start align-items-center" v-if="groupBy.id === 2">
                    <a href="#" @click="getCustomersLocation(item.ID)" class="mt-1">
                        <v-img class="img-fluid mt-n1" src="/img/icono_maps.svg" width="40px" />
                    </a>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon @click="openModalDivision(item.ID, 'Manager')" v-bind="attrs"
                            v-on="on" color="#B71C1C">mdi-chart-pie</v-icon> 
                        </template>
                        <span>Participación / Divisiones</span>
                    </v-tooltip>
                    <span class="ml-1 table__link" @click="sendToHome('Manager', item.ID)">{{ item.FULLNAME }}</span>
                </div>
                <div class="d-flex align-items-center" v-if="groupBy.id === 3">
                    <div><v-icon color="#218838">mdi-car</v-icon></div>
                    <div class="py-2 px-3">
                        <div class="table__link" @click="sendToHome('Seller', item.ID)">{{ item.FULLNAME }} ({{ item.BZIRK }})</div>
                        <div class="d-flex align-items-center">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon @click="openModalDivision(item.ID, 'Seller')" v-bind="attrs"
                                    v-on="on" color="#B71C1C">mdi-chart-pie</v-icon> 
                                </template>
                                <span>Participación / Divisiones</span>
                            </v-tooltip>
                            <span class="ml-2"><b>{{ item.BZTXT }}</b></span>
                        </div>
                    </div>
                </div>
                <div class="d-flex align-items-center" v-if="groupBy.id === 1">
                    <!-- <a href="#" @click="getCustomersLocation(item.ID)" class="mt-1">
                        <v-img class="img-fluid mt-n1" src="/img/icono_maps.svg" width="40px" />
                    </a> -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon @click="openModalDivision(item.ID, 'RegionalManager')" v-bind="attrs"
                            v-on="on" color="#B71C1C">mdi-chart-pie</v-icon> 
                        </template>
                        <span>Participación / Divisiones</span>
                    </v-tooltip>
                    <span class="ml-1 table__link" @click="sendToHome('RegionalManager', item.ID, 'DayCustomerDeliveryRegional')">{{ item.FULLNAME }}</span>
                </div>
            </template>

            <template v-slot:[`item.col1`]="{ item }">
                {{ validateFormatItem(item.col1) }}
            </template>

            <template v-slot:[`item.col2`]="{ item }">
                {{ validateFormatItem(item.col2) }}
            </template>

            <template v-slot:[`item.col3`]="{ item }">
                {{ validateFormatItem(item.col3) }}
            </template>

            <template v-slot:[`item.col4`]="{ item }">
                {{ validateFormatItem(item.col4) }}
            </template>

            <template v-slot:[`item.col5`]="{ item }">
                {{ validateFormatItem(item.col5) }}
            </template>
            
            <template v-slot:[`item.col6`]="{ item }">
                {{ validateFormatItem(item.col6) }}
            </template>
            
            <template v-slot:[`item.col7`]="{ item }">
                {{ validateFormatItem(item.col7) }}
            </template>
            
            <template v-slot:[`item.col8`]="{ item }">
                {{ validateFormatItem(item.col8) }}
            </template>
        
        </v-data-table>
        <!-- end table -->
        
        <div class="text-left mt-5">
            <span style="color: black">*Días hábiles</span>
        </div>   
        
        <!-- Modal Location -->
        <customer-locations-map 
          v-model="isOpenMap"
          :type="'byManagers'"
          :customers="customersLocation">
        </customer-locations-map>
        <!-- End Modal Location -->

        <!-- modal participación / divisiones -->
         <billing-by-area-extend
            v-model="isOpenDivision"
            :typeDivision="typeDivision"
            :userCode="userCode"
            :monthPeriod="monthPeriod">
         </billing-by-area-extend>    

        <LegacyFooter></LegacyFooter>
        </v-container>
    </div>    
  </template>
    
  <script>
    import { mapGetters, mapActions } from "vuex";
    import moment from 'moment';
    import axios from "@/plugins/axios";
    import Service from "@/mixins/service.js";
    import SendHomeByRole from "@/mixins/sendHomeByRole.js";
    import UserAuthOrStorage from "@/mixins/userAuthOrStorage.js";
    import LegacyFooter from "@/views/seller/components/LegacyFooter.vue";    
    import SearchCustomerSeller from '@/components/SearchCustomerSeller.vue';
    import CustomerLocationsMap from '@/components/modals/customers_locations_map/CustomerLocationsMap.vue';
    import BillingByAreaExtend from '@/components/modals/BillingByAreaExtend.vue';
    
    export default {
      name: "DayCustomerDelivery",
      components: {LegacyFooter, SearchCustomerSeller, CustomerLocationsMap, BillingByAreaExtend},
      mixins: [Service, SendHomeByRole, UserAuthOrStorage],
      data() {
        return {  
            optionsSelect: [                
                { name: 'Facturación neta', value: 1 },
                { name: 'Facturación neta por día hábil', value: 2 },
                { name: 'Pedidos por día hábil', value: 3 },
                { name: 'Días hábiles tiempo ciclo de venta', value: 4 }
            ],
            optionSelected: 1,   
            items:[], 
            header: [],   
            headerDefaul: [{ text: 'Gerente', value: 'FULLNAME', align:"center", sortable: false }],           
            headerGeneral: [],            
            optionMonths: [
                { month: 'Enero', number: 1 },
                { month: 'Febrero', number: 2 },
                { month: 'Marzo', number: 3 },
                { month: 'Abril', number: 4 },
                { month: 'Mayo', number: 5 },
                { month: 'Junio', number: 6 },
                { month: 'Julio', number: 7 },
                { month: 'Agosto', number: 8 },
                { month: 'Septiembre', number: 9 },
                { month: 'Octubre', number: 10 },
                { month: 'Noviembre', number: 11 },
                { month: 'Diciembre', number: 12 },
            ],
            optionGroups: [
                { name: 'GTE Regional', id: 1, option: 'S' },
                { name: 'Gerente', id: 2, option: 'G' },
                { name: 'Zona', id: 3, option: 'A' }
            ],
            groupBy: {},
            showMoreColums: false,
            isLoading: false,
            monthPeriod: moment().month() + 1,
            yearPeriod: moment().year(),
            optionYears: [],
            optTrimOrMonth: 1,
            isOpenMap: false,
            customersLocation: [],
            today: moment().format('YYYY-MM-DD'),
            isOpenDivision: false,
            userCode: '',
            typeDivision: 'RegionalManager', 
            footerProps: {
                itemsPerPageOptions: [10, 14, 24, 34, { text: 'Todas', value: -1 }],
                itemsPerPageText: 'Mostrar en grupo de:',
                showCurrentPage: false,
                pageText: 'Desplegado {0} / {1} de {2}'           
            }, 
            dataTableOptions: {
                itemsPerPage: 24,
                pagination: {
                    enabled: true,
                    options: {
                        numeric: true,
                    },
                },
            },
            isRouteRegional: false                       
        };
      },    
      mounted() { 
          this.isRouteRegional = this.$route.meta.role === 'Regional';
          this.groupBy = !this.isRouteRegional ? this.optionGroups[0] : this.optionGroups[1];
          this.optionYears = this.generarListaDeAnios(7);
          this.validateOptionGroup();       
          this.getDayCustomerDeliveryData();        
      },
      methods: { 
        ...mapActions({
          sendToPDF: 'printer/sendToPDF'
        }), 
        async getDayCustomerDeliveryData(){   
            this.isLoading = true;     
            this.items = [];  
            this.generateHeader();  
            try{
                const response = await axios.get('/day-customer-delivery-director/get-results', {
                    params: {
                        P_USERID: this.$route.meta.role === 'Regional' ? this.validateRegionalManagerCode() : '',
                        P_USEROP: this.groupBy.option,
                        P_VTWEG: this.user.VTWEG,
                        P_SPMON: this.formatSPMON(),
                        P_REPOP: this.optionSelected,
                    }                    
                });
                
                const data = response.data.data;  
                if(data[0].MYERR !== '1'){
                    this.items = data;
                }
                
                this.isLoading = false;
            }catch(error){
                this.isLoading = false;
                console.log(error);
            }        
        },
        validateOptionGroup(){ 
            if(this.$hasAnyRole(['RegionalManager','IELRegManager'])){
                this.optionGroups = this.optionGroups.filter(option => option.name !== 'GTE Regional');
            }                    
        },
        validateFormatItem(value){
            const formatValue = this.$options.filters.formatCurrency(value); 
            return this.optionSelected !== 4 ? `$${formatValue}` : formatValue;
        },
        formatSPMON() {
            const month = this.monthPeriod.toString().padStart(2, '0');
            return `${this.yearPeriod}${month}`;
        },
        generateHeaderQuarters(){
          this.header = this.headerDefaul;
          const fechasQuarters = this.calcularUltimosTrim(8, this.monthPeriod, this.yearPeriod)          
          const dates = []
          fechasQuarters.forEach(function(value, index){
            const objQuarter = { text: `Trim ${value.date}`, value: `col${fechasQuarters.length - index}`, align: "center" }
            dates.unshift(objQuarter)
          });

          this.mergeArrayDates(dates);         
        },
        generateHeaderMonth(){
          this.header = this.headerDefaul;   
          const datesMonths = this.calcularUltimosMeses(8, this.monthPeriod, this.yearPeriod)
          const dates = []
          datesMonths.forEach(function(value, index){
              const objMonth = { text: `${value.date}`, value: `col${datesMonths.length - index}`, align: "center" }
              dates.unshift(objMonth)
          });

          this.mergeArrayDates(dates);
        },
        mergeArrayDates(dates){
            if(!this.showMoreColums){
                dates.splice(0, 2);
            }
            
            this.header = [...this.header, ...dates];          
            this.headerGeneral = dates;
        },
        generateHeader(){
            this.optTrimOrMonth === 1 ? this.generateHeaderQuarters() : this.generateHeaderMonth();
        },
        openModalDivision(userCode, typeDivision){
            this.isOpenDivision = true;
            this.userCode = this.validateDigitsCode(userCode);
            this.typeDivision = typeDivision;
        },
        openModalDivisionGeneral(){
            this.isRouteRegional ? this.openModalDivision(this.validateRegionalManagerCode(), 'RegionalManager') : this.openModalDivision('', 'Director');
        },
        async getCustomersLocation(employeeNumber){
            try{
                this.isOpenMap = true;                
                let response = await axios.get('/manager/home/maps-location-customers',{
                    params:{
                        inicio: this.today,
                        fin: this.today,
                        P_VTWEG: this.user.VTWEG,
                        P_VKORG: this.user.VKORG,
                        employee_number: employeeNumber
                    }
                });
                this.customersLocation = response.data.data;
            }catch(error){
                this.isOpenMap = false;
                console.log(error);
            }
        },
        validateDigitsCode(code){
            return code ? code.toString().padStart(8, '0') : code;
        }      
      },
      computed: {
        ...mapGetters({
          user: "auth/user",
        }),
        assignItems(){
            if(this.items.length > 0){
                return this.items.map((item) => {
                    if(item.MES.MES.length > 0 &&  item.TRIMESTRE.TRIMESTRE.length > 0){
                        const trimOrMonth = this.optTrimOrMonth === 1 ? item.TRIMESTRE.TRIMESTRE : item.MES.MES;
                        trimOrMonth.forEach((value, index) => {
                            item[`col${trimOrMonth.length - index}`] = value.TOTAL;
                        });
                        return item;
                    }
                });
            }
            return [];
        },
        averageGeneral(){
            let items = {};
            items = this.assignItems.reduce((acc, item, _, { length }) => {
                for (let i = 1; i <= 8; i++) {
                    acc[`col${i}`] = (acc[`col${i}`] || 0) + parseFloat(item[`col${i}`]) / length;
                }
                return acc;
            }, {});

            if(!this.showMoreColums){
                const entries = Object.entries(items);
                const updatedEntries = entries.slice(2);
                items = Object.fromEntries(updatedEntries);
            }

            return items;
        }         
      }, 
      watch:{
        monthPeriod: 'getDayCustomerDeliveryData',
        yearPeriod: 'getDayCustomerDeliveryData',
        optionSelected: 'getDayCustomerDeliveryData',
        optTrimOrMonth: 'generateHeader',    
        showMoreColums: 'generateHeader',
        groupBy(newValue){
            if(newValue.id === 1 && this.isRouteRegional){
                this.$router.push({ name: 'DayCustomerDeliveryDirector' });
            }
            
            this.header[0].text = newValue.name;
            this.getDayCustomerDeliveryData();
        },
      }
    }
    </script>

  <style scoped>  
    .cont-shadow{
        background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
        opacity: 1;
    }
    .bloq-height{
      flex: 1;
      padding: 8px 15px;
    }
    .filter-text{
      font-size: 14px;
    }
    .table__link{
        text-decoration: underline;
        font-weight: bold;
        cursor: pointer;
    }
    ::v-deep .select__input--red.v-text-field--solo.v-input--dense > .v-input__control{
        min-height: auto;      
        opacity: 1;
        border-radius: 20px
    }
    ::v-deep .select__input--red.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot{
        background: transparent linear-gradient(180deg, #A82222 0%, #FF0000 100%) 0% 0% no-repeat padding-box;   
    }
    ::v-deep .select__input--red .v-input__slot .v-icon{
        color: #FFF;
    }
    ::v-deep .select__input--red .v-input__slot .v-select__selection--comma {
        color: #FFF !important;
        font-size: 14px;
    }   
  </style>