<template>
  <div>
    <h2 class="mt-10 text-subtitle-2 text-center text-md-h5 ma-0 text-uppercase font-weight-bold">{{ title }}</h2>
    <v-card class="pa-5 mb-0 my-5 card-shadow-red" :class="cardClass" rounded="0">
      <v-row v-if="customerData && !loading" class="text-left text-body-2">
        <v-col cols="12" sm="7" md="4" class="font-weight-bold col-item-space">
          <div>Cliente: ({{customerData.KUNNR}}) {{customerData.NAME1}}</div>
          <div>RFC: {{ customerData.STCD1 }}</div>
          <div>Nivel: {{ customerData.KDGRP }}</div>
          <div><v-icon color="blue">mdi-home</v-icon> <span>{{ customerChannel }}</span></div>
          <div><v-icon color="yellow">mdi-credit-card-search</v-icon> <span>{{ customerData.VKBUR }} {{ customerData.VTEXT }} </span></div>
        </v-col>
        <v-col cols="12" sm="5" md="4" class="col-item-space">
          <div class="font-weight-bold">Estus crediticio</div>
          <div>Límite de crédito: ${{ customerData.KLIMK }}</div>
          <div>Saldo al corte: ${{ customerData.SKFOR }}</div>
          <div>Crédito disponible: ${{ customerData.CREDD }}</div>
          <div>Monto vencido: {{ pastDueBalance }}</div>
          
        </v-col>
        <v-col cols="12" sm="12" md="4" class="col-item-space">
          <div class="font-weight-bold">Estus crediticio con pedido actual</div>
          <div>Estatus folio: {{ currentOrderStatus }}</div>
          <div>Monto total del pedido: {{ currentOrderTotal }} MXN</div>
          <div>Crédito disponible: {{ availableCredit }} MXN</div>
        </v-col>
      </v-row>
      <v-row v-if="loading && !hideLoading" class="py-5">
        <v-progress-linear
          color="lighten-2"
          buffer-value="0"
          stream
        />
        <small class="grey--text mt-4">{{ loadingText }}</small>
      </v-row>
      <v-row v-if="error" class="py-5">
        <small class="error--text mt-4">{{ errorText }}</small>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'CustomerOrderInformation',

  props: {
    title: {
      type: String, 
      default: 'Datos del cliente'
    },

    customerData: {
      required: true,
    },

    customerChannel: {
      required: true,
    },

    currentOrderStatus: {
      type: String,
      required: true,
    },

    currentOrderTotal: {
      type: String,
      required: true,
    },

    availableCredit: {
      type: String,
      required: true,
    },

    pastDueBalance: {
      type: String,
      required: true,
    },

    cardClass: {
      type: String,
      default: '',
    },

    loadingText: {
      type: String,
      default: 'Cargando datos del cliente',
    },

    errorText: {
      type: String,
      default: 'No se pudo cargar la información del cliente',
    },

    hideCurrentOrderDetails: Boolean,
    loading: Boolean,
    hideLoading: Boolean,
    error: Boolean
  }
}
</script>

<style scoped>
.col-item-space > * {
  margin-bottom: 8px;
}

.col-item-space > *:last-child {
  margin-bottom: 0px;
}
</style>