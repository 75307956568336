<template>
  <v-form @submit.prevent="validateForm" :disabled="disabled">
    <label for="reason-1">Motivo de rechazo: </label>
    <v-select 
      v-model="form.firstReason"
      :items="firstReasonOptions" 
      class="card-shadow mb-4"
      name="reason-1"
      placeholder="Seleccionar" 
      hide-details
      solo
    />

    <label for="reason-2">2do. Motivo de rechazo:</label>
    <v-textarea 
      v-model="form.secondReason"
      class="card-shadow mb-4"
      name="reason-2" 
      placeholder="......"
      hide-details
      solo 
    />

    <v-alert
      v-if="errors.length > 0"
      dense
      outlined
      color="red"
    >
      <div v-for="(e, index) in errors" :key="index">{{ e }}</div>
    </v-alert>
          
    <div class="d-flex mt-7">
      <v-btn v-if="!hideCancel" class="red-button red-button--light" width="130px" text @click="$emit('on-cancel')" :disabled="disabled"> 
        Cancelar
      </v-btn>
      <v-spacer />
      <v-btn v-if="!hideDelete" class="red-button" width="130px" text type="submit" :disabled="disabled">
        Eliminar
      </v-btn>
    </div>
  </v-form>
</template>

<script>
export default {
  name: 'deleteReasonsForm',

  props: {
    value: {
      type: Object,
      default: () => ({ firstReason: '', secondReason: '' }),
    },

    firstReasonOptions: {
      type: Array,
      default: () => [
        "A1 = Canc. Rec. Por Falt. Inventario",
        "A2 = Canc. Rec. Por Tiempo de Entrega",
        "A3 = Canc. Rec. Error del Cte.",
        "A4 = Canc. Rec. Por Error de Captura",
        "A5 = Pedido de Reemplazo",
        "A6 = Previa Cancelación x Lim. Cred."
      ]
    } ,
    
    noValidateFirsReason: Boolean,
    noValidateSecondReason: Boolean,
    hideCancel: Boolean,
    hideDelete: Boolean,
    disabled: Boolean,
  },

  data() {
    return { 
      errors: []
    }
  },

  methods: {
    validateForm() {
      this.errors = [];

      if(!this.noValidateFirsReason && this.form.firstReason === '')
        this.errors.push('Completa el primer motivo de rechazo');

      if(!this.noValidateSecondReason && this.form.secondReason === '')
        this.errors.push('Completa el segundo motivo de rechazo');

      if(this.errors.length === 0)
        this.$emit('on-delete', this.form);
    }
  },

  computed: {
    form: {
      set(newVal) {
        this.$emit('input', { ...newVal })
      },

      get() {
        return this.value;
      }
    },
  }
}
</script>