<template>
	<div class="container-fluid grey lighten-5 pt-4">
		
		<card>
			<h2 class="h2">Crear Contacto</h2>
            <div class="alert alert-danger" role="alert" v-if="hasError" style="font-weight: 500">{{ msg }}</div>
            <div class="alert alert-success" role="alert" v-if="hasSuccess" style="font-weight: 500">{{ msg }}</div>
            <v-progress-linear :active="loading" :indeterminate="loading" top color="deep-purple accent-4"></v-progress-linear>
            <br>
            <div class="card-body text-lg-center">
                <form @submit.prevent="submitContact">
                    <v-row align="center" justify="center">
                      <v-col cols="12" md="6" align="left" justify="left">
                        <label for="" class="mb-2">Nombre *</label>
                        <v-text-field solo placeholder="Ingrese Nombres" type="text" v-model="form.name" required></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" align="left" justify="left">
                        <label for="" class="mb-2">Apellido *</label>
                        <v-text-field solo placeholder="Ingrese Apellidos" type="text" v-model="form.last_name" required></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mt-n10">
                      <v-col cols="12" sm="6" align="left" justify="left">
                        <label for="" class="mb-2">E-mail *</label>
                        <v-text-field
                          solo
                          type="email"
                          placeholder="Ingrese E-mail"
                          v-model="form.email"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" align="left" justify="left">
                        <label for="" class="mb-3">Tipo de contacto</label>
                        <v-select v-model="form.contactType_id" solo label="Tipo de contacto" :items="contactTypes" item-text="name" item-value="id"></v-select>
                      </v-col>
                    </v-row>
					<v-row align="center" justify="center">
                      <v-col cols="12" md="6" align="left" justify="left">
                        <label for="" class="mb-2">Zona</label>
                        <v-text-field solo placeholder="Ingrese zona" type="text" v-model="form.zone"></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" align="left" justify="left">
                        <label for="" class="mb-2">Número de Empleado</label>
                        <v-text-field solo placeholder="Ingrese Número de Empleado" type="text" v-model="form.no_employee"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mt-n10">
                      <v-col cols="12" sm="6" align="left" justify="left">
                        <label for="" class="mb-2">Teléfono *</label>
                        <v-text-field
                          solo
                          placeholder="Ingrese Teléfono"
                          type="tel"
                          v-model="form.phone"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        align="left"
                        justify="left"
                        class="mt-n7">
                        <v-container class="px-0" fluid>
                          <v-switch v-model="form.whatsapp">
                            <template v-slot:label>
                              ¿El número tiene WhatsApp? &nbsp;
                              <v-icon> mdi-whatsapp </v-icon>
                            </template>
                          </v-switch>
                        </v-container>
                      </v-col>
                    </v-row>
                    <v-row class="mt-n10">
                      <v-col cols="12" sm="6" align="left" justify="left">
                        <label for="" class="mb-3">Foto</label>
                        <v-file-input
                          placeholder="Seleccionar archivo"
                          outlined
                          dense
                          append-outer-icon="mdi-image"
                          prepend-icon=""
                          v-model="form.photo"></v-file-input>
                      </v-col>
                    </v-row>
                    
                    <v-col cols="12" align="left" justify="left">
                      <div class="alert alert-danger" role="alert" v-if="hasError" style="font-weight: 500">{{ msg }}</div>
                      <div class="alert alert-success" role="alert" v-if="hasSuccess" style="font-weight: 500">{{ msg }}</div>
                      <v-progress-linear :active="loading" :indeterminate="loading" top color="deep-purple accent-4"></v-progress-linear>
                      <br>
                      <div class="text-center">
                        <v-btn
                          type="submit"
                          rounded
                          block
                          style="
                            background-color: #2fd410;
                            color: #fff;
                            text-transform: none;
                            font-weight: 800;">
                          {{ loading ? "Validando..." : "Registrar" }}
                        </v-btn>
                      </div>
                    </v-col>
                  </form>
			</div>
		</card>
	</div>
  </template>
  
  <script>
  // @ is an alias to /src
  import axios from "@/plugins/axios";
  export default {
    name: "ContactCreate",
    components: {},
    data() {
      return {
        contactTypes: [],
        dataContactType: [],
        loading: false,
        msg: null,
        hasError: false,
        hasSuccess: false,
        timeout: 8000,
        form: {
            name: "",
            last_name: "",
            email: "",
            phone: "",
            whatsapp: false,
            photo: [],
            created_by: 1,
            contactType_id: "",
			no_employee: "",
			zone: ""
        },
      };
    },
    methods: {
        getContactTypes(){

            axios
			.get("contact-type/list")
			.then((response) => {
                if( response.data.result == 1 ){

                    this.contactTypes = response.data.data;
                }
                else{

                    alert();
                }
			})
			.catch((error) => {
				if (error.response.data.errors) {
                    console.warn( error.response.data.errors );
				} else {
                    console.warn(error);
				}
			});
        },
		submitContact() {
			this.loading = true;
      this.msg = "";
			axios
			.post("contact/save", this.form,{
        headers: {'Content-Type': 'multipart/form-data'}
      })
			.then((response) => {
				if( response.data.result == 1 ){
                    this.form = {};
                    this.hasSuccess = true;
                    this.msg = response.data.message;
                }
                else{
                    if( Array.isArray(response.data.data) ){

                        for( var m = 0; m < response.data.data.length; m++ ){

                            this.msg += response.data.data[m];
                        }
                    }
                    else{
                        
                        this.msg = response.data.message;
                    }
                    this.hasError = true;
                }
                setTimeout(() => {
                    this.hasError = false;
                    this.hasSuccess = false;
                }, 8000);
			})
			.catch((error) => {
				if (error.response.data.errors) {
                    this.msg = error.response.data.errors;
                    this.hasError = true;
                    setTimeout(() => {
                        this.hasError = false;
                    }, 8000);
				}
                else {
                    alert(error);
				}
			})
			.finally(() => (this.loading = false));
		},
    },
	mounted(){
		this.getContactTypes();
	}
  };
</script>
  
 <style scoped>
	.label {
		font-weight: 700;
	}
</style>