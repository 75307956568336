<template>
  <v-container fluid class="white lighten-5 py-7" ref="printReport">
    <v-card class="pa-5 mb-0 ma-5 card-shadow" rounded="0" v-if="currentLevelLabel && this.currentLevelMatch(['family', 'material'])">
      <h2 class="text-h5 ma-0 text-uppercase font-weight-bold">{{ currentLevelLabel }}</h2>
    </v-card>

    <v-card class="pa-5 my-0 ma-5 card-shadow" rounded="0">
      <v-row no-gutters>
        <v-col cols="9" class="d-flex gap-2 align-center">
          <v-btn class="hide-on-print" icon color="black" @click="prevLevel()" :disabled="currentLevelIndex <= 0 || loading">
            <v-icon>mdi-arrow-u-left-top</v-icon>
          </v-btn>
          <v-icon class="hide-on-print" color="black" size="30px">mdi-chart-pie</v-icon>
          <h1 class="text-subtitle-2 text-left text-md-h5 ma-0 text-uppercase font-weight-bold">{{ levelTittle }}</h1>
        </v-col>
        <v-col cols="3" class="d-flex justify-end hide-on-print">
          <v-icon color="black" size="30px">mdi-book</v-icon>
        </v-col>
      </v-row>

      <donut-chart
        :render="showGraph"
        :items="graphData"
      />
    </v-card>

    <v-card class="pas-5 mt-8 ma-5 card-shadow" rounded="0">
      <v-data-table
        id="borderless-table"
        dense
        :headers="headers"
        :items="sellData"
        :loading="loading"
        loading-text="Cargando información..."
        no-data-text="No se encontro informacíon"
        :footer-props="{ itemsPerPageText: 'Mostrar en grupo de', itemsPerPageAllText: 'Todos', pageText: '{0}-{1} de {2}' }"
      >
        <template v-slot:[`item.ID`]="{ item }">
          <div v-if="!higherLevelReached && !item.notSales" class="text-decoration-underline indigo--text cursor-pointer" @click="nextLevel(item.originalId, item.LABEL)">
            {{ item.ID }} 
          </div>
          <div v-else>
            {{ item.ID }} 
          </div>
        </template>
        <template slot="body.append" >
          <tr class="card-shadow table-row table-text-right" v-if="!isMobile && sellDataTotals && sellData.length > 0 && currentLevelMatch(['root', 'comertialDivision', 'family'])">
            <th></th>
            <th v-if="currentLevel == 'family'"></th>
            <th>{{ formatNumber(sellDataTotals.sales) }}</th>
            <th>{{ formatNumber(sellDataTotals.budget) }}</th> 
            <th>{{ getPercentage(sellDataTotals.sales, sellDataTotals.budget) }}</th>
            <th>{{ formatNumber(sellDataTotals.salesUnit, '', 2) }}</th>
            <th>{{ formatNumber(sellDataTotals.budgetUnit, '', 2) }}</th> 
            <th>{{ getPercentage(sellDataTotals.salesUnit, sellDataTotals.budgetUnit) }}</th>
            <th>{{ formatNumber(sellDataTotals.weight, '', 3) }}</th>
          </tr>
          <tr class="table-text-right" v-else-if="!isMobile && sellDataTotals && currentLevelMatch(['material'])">
            <th></th><th></th>
            <th>{{ formatNumber(sellDataTotals.salesUnit, '', 2) }}</th> 
            <th>{{ formatNumber(sellDataTotals.sales) }}</th>
            <th>{{ formatNumber(sellDataTotals.weight, '', 3) }}</th>
          </tr>
        </template>
      </v-data-table>
    </v-card>
		
		<!-- Alerts -->
		<v-snackbar
			v-model="error"
			top
			right
		>
			{{ errorMessage }}
		</v-snackbar>
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import api from '@/plugins/axios';
  import DonutChart from '@/components/DonutChart.vue';
  import numbersFormat from '@/mixins/numbersFormats';

  const formInit = {
    comertialDivision: '',
    family: '',
    material: '',
    bill: '',
    billDetails: '',
  }

  const levelsPropsGeneralToParticular = ['root', 'comertialDivision', 'family', 'material', 'bill', 'billDetails']; // This array must have the same props as formInit

  const mainHeaderBase = [ // for root, comertialDivision, family levels tables
    { text: 'Venta $', value: 'NETWR1', align: 'end' },
    { text: 'PPTO', value: 'TPPTO', align: 'end' },
    { text: 'Var. %', value: 'varPercent', align: 'end'},
    { text: 'Venta Unid', value: 'FKIMG', align: 'end' },
    { text: 'PPTO Unid', value: 'MENGE', align: 'end' },
    { text: 'Var. %', value: 'varUnitPercent', align: 'end' },
    { text: 'PESO NETO.', value: 'NTGEW2', align: 'end' },
  ]

  const secondaryHeaderBase = [ // for material, bill, billDetails tables
    { text: 'Descripción', value: 'LABEL' },
    { text: 'Unidades', value: 'FKIMG', align: 'end' },
    { text: 'Importe $', value: 'NETWR1', align: 'end' },
    { text: 'PESO NETO.', value: 'NTGEW2', align: 'end' },
  ]

  export default {
    name: 'QuotationStatistics',

    components: {
      DonutChart,
    },
    
    mixins: [numbersFormat],

    data() {
      return {
        graphData: [], //saves the data fetched from backend
        sellData: [], //saves the data fetched from backend
        sellDataTotals: null, //saves the data fetched from backend

        currentLevelIndex: 0, // Keeps the index of the current level, when use

        levelsForm: { ...formInit }, // this form is sent to backend when changing the level
        levelsLabel: { ...formInit }, // stores the level labels

        loading: false,
        errorMessage: '',
      }
    },
    
    mounted(){
      this.fetchSellStatisticsData();
      
      setTimeout(() => {
        this.sendToPDF({ active: true });
      }, 1000)
    },

    methods: {
      ...mapActions('printer', ['sendToPDF']),

      async fetchSellStatisticsData(){ // Gets de levels data usgin levelsForm
        this.loading = true;

        this.sellData = [];
        this.graphData = [];
        this.sellDataTotals = null;
				
        try {
          const { data } = await api.post(`/quotation/sell-statistics`, { ...this.levelsForm, user: this.userCode, VTWEG: this.channel });
          this.sellData = this.formatSellsData(data.sales);
          this.sellDataTotals = data.totals;
          this.graphData = data.graphData || [];
        } catch (error) {
          this.errorMessage = 'Succedió un problema al recuperar la información.';
        }

        this.loading = false;
      },

      nextLevel(itemId, itemLabel){ //set in levelsForm the value of the next level to fetch 
        if(this.currentLevelIndex < this.higherLevelIndex){
          this.currentLevelIndex += 1;
          this.levelsForm[this.currentLevel] = itemId;
          this.levelsLabel[this.currentLevel] = itemLabel;

          this.fetchSellStatisticsData();
        }
      },

      prevLevel(){ //removes in levelsForm the value of the current level and does a refetch 
        if(this.currentLevelIndex > 0){
          this.levelsForm[this.currentLevel] = '';
          this.levelsForm[this.currentLevel] = '';
          this.currentLevelIndex -= 1;

          this.fetchSellStatisticsData();
        }
      },

      getPercentage(dividend, divisor){ // 2 / 10 -> 20%, 0 / 10 -> --%
        if(divisor == 0 || dividend == 0 || divisor === undefined || dividend === undefined) return '--%';

        const result = ((dividend / divisor) * 100);
        return result === 0 ? '--%' : `${result.toFixed(2)}%`;
      },

      currentLevelMatch(levels){ // Gets an array and returns true if one of the elements match with the current level
        if(!levels) return false;

        return levels.includes(this.currentLevel);
      },

      formatSellsData(sales){
        const cleanSells = sales.filter(s => s!== null && JSON.stringify(s) !== '[]' && JSON.stringify(s) !== '{}');

        if(this.currentLevelMatch(['root', 'comertialDivision', 'family'])){
          return cleanSells.map(s => {
            return {
              ...s,
              originalId: s.ID,
              TPPTO: this.formatNumber(s.TPPTO),
              NETWR1: this.formatNumber(s.NETWR1),
              MENGE: this.formatNumber(s.MENGE, '', 2),
              FKIMG: this.formatNumber(s.FKIMG, '', 2),
              NTGEW2: this.formatNumber(s.NTGEW2, '', 3),
              varPercent: this.getPercentage(s.NETWR1, s.TPPTO),
              varUnitPercent: this.getPercentage(s.FKIMG, s.MENGE),
              notSales: this.currentLevel == 'family' && s.NETWR1 == 0, // Disable fetch a family level if it has 0 sales
            }
          })
        } else {
          return cleanSells.map(s => {
            return {
              ...s,
              NETWR1: this.formatNumber(s.NETWR1),
              MENGE: this.formatNumber(s.MENGE, '', 2),
              FKIMG: this.formatNumber(s.FKIMG, '', 2),
              NTGEW2: this.formatNumber(s.NTGEW2, '', 3),
              originalId: this.currentLevel == 'material' ? s.ID : s.VBELN, // Switch the ID's to keep the same estructure
              VBELN: this.removeLeftZeros(s.ID),
              ID: this.currentLevel == 'material' ? this.removeLeftZeros(s.ID) : this.removeLeftZeros(s.VBELN),
            }
          })
        }
      },
    },
		
    computed: {
      higherLevelIndex(){
        return levelsPropsGeneralToParticular.length - 1 | 0;
      },

      higherLevelReached(){
        return this.higherLevelIndex === this.currentLevelIndex;
      },

      currentLevel() {
        return levelsPropsGeneralToParticular[this.currentLevelIndex];
      },

      currentLevelLabel() {
        return this.levelsLabel[this.currentLevel];
      },

      levelTittle(){ // returns the title based on the current level 
        switch (this.currentLevel) {
          case 'root': return 'Análisis venta por división comercial'
          case 'comertialDivision': return 'Análisis venta por subdivisión'
          case 'family': return 'Análisis venta por familia'
          case 'material': return 'Análisis venta por material'
          case 'bill': return 'Análisis venta por factura'
          case 'billDetails': return 'Análisis venta por detalle factura'
          default:
            break;
        }
        return "Análisis venta por división comercial"
      },

      headers(){ // returns a table header based on the current level 
        switch (this.currentLevel) {
          case 'root': 
          case 'comertialDivision': return [{ text: 'DIV.COM', value: 'ID' }, ...mainHeaderBase];
          case 'family': return [{ text: 'Clave', value: 'ID' }, { text: 'Familia', value: 'LABEL' }, ...mainHeaderBase];
          case 'material': return [{ text: 'Código material', value: 'ID' }, ...secondaryHeaderBase];
          case 'bill': 
          case 'billDetails': return [{ text: 'ID Factura', value: 'ID' }, { text: 'Fecha factura', value: 'FKDAT' }, { text: 'Código material', value: 'VBELN' }, ...secondaryHeaderBase];
          default: return mainHeaderBase;
        }
      },

      showGraph(){
        return this.graphData.length > 0 && this.currentLevelMatch(['root', 'comertialDivision', 'family']);
      },

      isMobile(){
        return this.$vuetify.breakpoint.name === 'xs';
      },

      error() {
        return this.errorMessage.length > 0;
      },

      ...mapGetters('auth', ['channel', 'userCode'])
    },
  }
</script>

<style scoped>
  .table-row {
    height: 60px;
    background-color: rgb(240, 240, 240);
  }

  .table-text-right > th {
    text-align: end !important;
  }

  .v-data-table >>> .v-data-table-header {
    box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029 !important;
    height: 60px;
    border-bottom: 2px solid black;
    background-color: rgb(240, 240, 240);
  }
  
  #borderless-table >>> div > table > tbody > tr > td {
    border-bottom: 0px;
  }
</style>