<template>
<div> 
    <v-card>
       
      <v-toolbar :dark=true dense>
            <v-toolbar-title style="font-size: 15px;">{{ title }} </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn class="right" @click="closeModal()" icon>
              <v-icon>mdi-close-circle</v-icon>
            </v-btn>
        </v-toolbar>
        
        <v-card-text>
            <div v-if="downloadble" class="d-flex justify-center custom-donwloads-button">
              <div class="pt-2 pb-2 pl-4 pr-4" > 
                <v-btn :disabled="loading"> 
                  
                        <vue-json-to-csv :show-labels="true" :json-data="paymentDetails" :labels="csvLabelExport" :csv-title="'Detalle de pago'" class="d-flex align-items-center">            
                          <span >Excel</span>
                          <v-icon style="color: green">
                                mdi-file-excel
                            </v-icon>            
                        </vue-json-to-csv> 
                    </v-btn> 
              </div>
              <div class="pt-2 pb-2 pl-4 pr-4">
                <v-btn :disabled="loading" @click="downloadPDF"> 
                  PDF  <v-icon style="color: red">
                              mdi-file-download
                            </v-icon>            
                    </v-btn>  
              </div>
            <!-- <div class="pt-2 pb-2 pl-4 pr-4">
                Imprimir <v-icon color="black">mdi-printer-outline</v-icon>
              </div> -->
            </div>
 
          <v-row class="mt-4"> 
            <v-col cols="12" md="12" class="d-flex justify-center" >
              <div class="w-100" >
              <v-text-field class="custom-input" v-model="search"  label="Buscar" single-line
                hide-details>
                <template v-slot:append>
                 <v-icon class="custom-icon">mdi-magnify</v-icon>
              </template>
              </v-text-field>
                </div>
            </v-col>
          </v-row> 
          <v-row>
            <v-col md="12"> 
                <v-data-table dense  
                :headers="paymentDetailsHeader" 
                :items="paymentDetails" 
                :items-per-page="10" 
                :search="search"
                :loading="loading"   
                loading-text="Cargando... Por favor espere" 
                no-data-text="No hay datos disponibles" 
                class="custom-table"  >   

                <template v-slot:[`header.IMPXIVA`] = "{ header }">
                  <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <span  
                          v-bind="attrs"
                          v-on="on"
                        >  {{  header.text }}  
                      </span>
                      </template>
                    <span>Importe</span>
                  </v-tooltip> 
              </template> 
                <template v-slot:[`item.IMPXIVA`]="{ item }"> 
                   ${{ format(item.IMPXIVA) }} 
                </template> 
                <template v-slot:[`item.WRBTR`]="{ item }"> 
                   ${{ format(item.WRBTR) }}
                </template> 
                <template v-slot:[`item.IMPTENC`]="{ item }"> 
                   ${{ format(item.IMPTENC) }}
                </template> 
                <template v-slot:[`item.SKFBT`]="{ item }"> 
                   ${{ format(item.SKFBT)}}
                </template> 
                <template v-slot:[`item.INDDPP`]="{ item }"> 
                   {{ item.INDDPP==1?'Contado':'Pronto pago'}} 
                </template>
              </v-data-table>  
              <div class="d-flex justify-end">
              <v-btn outlined color="success" text @click="closeModal()" > Cerrar </v-btn>
            </div>
            </v-col> 
          </v-row>
        </v-card-text> 
      </v-card>
</div>

</template>

<script>
import jsPDF from 'jspdf';
import VueJsonToCsv from 'vue-json-to-csv'
export default ({
  name:'PaymentBonusDetailsTable',
  components: {VueJsonToCsv},
    data () {
    return {
        search:"",
        paymentDefaultHeader:[         
        { header: "Banco", text: "Banco", align: "center", value: "BANCO" ,dataKey: "BANCO" }, 
        { header: "Cliente",text: "Cliente", align: "center", value: "KUNNR" ,dataKey: "KUNNR" }, 
        { header: "Factura",text: "Factura", align: "center", value: "REBZG" ,dataKey: "REBZG" },
        { header: "Importe",text: "Importe", align: "center", value: "WRBTR" ,dataKey: "WRBTR" },
        { header: "Importe sin IVA", text: "Importe sin IVA", align: "center", value: "IMPXIVA" ,dataKey: "IMPXIVA" },
        { header: "Fecha", text: "Fecha", align: "center", value: "BUDAT" ,dataKey: "BUDAT" }, 
      ],
      csvLabelExport:{  
        BANCO: { title: 'Banco' },   
        KUNNR: { title: 'Cliente' },   
        REBZG: { title: 'Factura' },   
        WRBTR: { title: 'Importe' },   
        IMPXIVA: { title: 'Importe sin IVA' },   
        BUDAT: { title: 'Fecha' },   
      }
    }
  },
  methods: {
    downloadPDF(){ 
      var pymentDetails=[];
      for (let index = 0; index < this.paymentDetails.length; index++) {
       const item = {
            WRBTR :`$${this.format(this.paymentDetails[index]['WRBTR'])}`,
            IMPXIVA :`$${this.format(this.paymentDetails[index]['IMPXIVA'])}`, 
            BANCO :`${this.paymentDetails[index]['BANCO']}`,   
            KUNNR :`${this.paymentDetails[index]['KUNNR']}`,   
            REBZG :`${this.paymentDetails[index]['REBZG']}`,   
            BUDAT :`${this.paymentDetails[index]['BUDAT']}`,    
          }
        pymentDetails.push(item) 
        } 
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "in",
        format: "letter",
      });  
        doc.text(3, 0.5, 'Detalle de pagos');  
       doc.autoTable({
        theme:"striped", 
         columns: this.paymentDefaultHeader, 
        body: pymentDetails,
        columnStyles: {
          title: { fontStyle: "bold" },
        },
        margin: { left: 0.5, top: 1.5 },
      });    
       pymentDetails=[]
      doc.save("Detalle-de-pago.pdf");
  
    },  
      dividirArrayEnPaginas(array, filasPorPagina) 
      {  // Función para dividir el array en páginas
        const paginas = [];
        for (let i = 0; i < array.length; i += filasPorPagina) {
          paginas.push(array.slice(i, i + filasPorPagina));
        }
        return paginas;
      },
    format(value) {
      let result =  Math.trunc(value)  ;
      return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    closeModal(){
        this.$emit('closeModal',true)
    }
  },
    props:{
        paymentDetails:{},
        downloadble:{
          default:true
        },
        loading:{},
        title:{},
        paymentDetailsHeader:{
          type: Array,
          default: () => [
          { text: "Banco", align: "center", value: "BANCO" ,sortable: true , class: 'custom-header'}, 
          { text: "Cliente", align: "center", value: "KUNNR" ,sortable: true , class: 'custom-header'}, 
          { text: "Factura", align: "center", value: "REBZG" ,sortable: true , class: 'custom-header'},
          { text: "Importe", align: "center", value: "WRBTR" ,sortable: true , class: 'custom-header'},
          { text: "Importe sin IVA", align: "center", value: "IMPXIVA" ,sortable: true , class: 'custom-header'},
          { text: "Fecha", align: "center", value: "BUDAT" ,sortable: true , class: 'custom-header'},
          ]
          }
      },
    
})
</script>

<style scoped>
.custom-donwloads-button > div{ 
  background: #FFFFFF 0% 0% no-repeat padding-box;
 /* box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;*/
  color:black;
  cursor: pointer;
}
.custom-donwloads-button > div:hover{ 
  /*background: #e7e7e7 0% 0% no-repeat padding-box; */
}

.custom-input{
    font-size: 12px !important;
    
    padding: 0px;
  padding-left: 4px !important;
    /* background: green !important; */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
  } 
  /*
.custom-icon {
  background: red; 
  padding-top:0px !important;
  padding-bottom: 0px;
  height: max-content !important;
}

 .custom-header{
  background: red !important;
 }
 */

</style>