<template>
  <v-container fluid>
    <v-card>
      <v-card-title>ADMINISTRADOR</v-card-title>
      <v-card-title>
        <div class="col-md-8" style="text-align: left">
          <v-btn
            color="teal"
            style="color: #ffffff; text-transform: none"
            @click="dialog = true"
          >
            <v-icon>mdi-account-multiple-plus</v-icon>
            &nbsp;Crear Usuario</v-btn
          >
          <v-btn color="green" dark class="ml-2" @click="generateExcel">
            <v-icon>mdi-file-excel</v-icon>
            Descarga XLSX
          </v-btn>
        </div>
        <div class="col-md-4">
          <v-text-field
            dense
            v-model="search"
            append-icon="mdi-magnify"
            label="Búsqueda"
            clear-icon="mdi-close-circle"
            clearable
            @click:clear="clearSearch"
          ></v-text-field>
        </div>
      </v-card-title>
      <v-spacer></v-spacer>
      <v-card-text>
        <div class="row">
          <div class="col-sm-12">
            <v-data-table
              :headers="headers"
              :loading="loading"
              :items="data"
              :options.sync="options"
              :server-items-length="options.total"
              :search="search"
            >
              <template v-slot:[`header.deleted_at`]="{}">
                <v-checkbox
                  v-model="options.deleted"
                  :label="`Bajas`"
                ></v-checkbox>
              </template>
              <!-- <template v-slot:[`item.custumer_code`]="{ item }">
                <span style="font-weight: 500">
                  {{ item.custumer_code || item.employee_number }}
                </span>
              </template> -->
              <template v-slot:[`item.role`]="{ item }">
                <span v-for="rol in item.roles" :key="rol.id">
                  <v-chip class="ma-2" :color="rol.color" text-color="white">
                    {{ rol.alias }}
                  </v-chip>
                  <!-- <v-chip
                    v-if="rol.pivot.role_id == 1"
                    class="ma-2"
                    color="green"
                    text-color="white"
                  >
                    Cliente
                  </v-chip>
                  <v-chip
                    v-else-if="rol.pivot.role_id == 5"
                    class="ma-2"
                    color="red"
                    text-color="white"
                  >
                    Administrador
                  </v-chip>
                  <v-chip
                    v-else-if="rol.pivot.role_id == 3"
                    class="ma-2"
                    color="blue"
                    text-color="white"
                  >
                    Gerente
                  </v-chip>
                  <v-chip
                    v-else-if="rol.pivot.role_id == 2"
                    class="ma-2"
                    color="purple"
                    text-color="white"
                  >
                    Invitado
                  </v-chip>
                  <v-chip v-else>
                    {{ rol.name }}
                  </v-chip> -->
                </span>
                <!-- <v-chip
                  v-if="item.role_id == 1"
                  class="ma-2"
                  color="green"
                  text-color="white"
                >
                  Cliente
                </v-chip>
                <v-chip
                  v-if="item.role_id == 5"
                  class="ma-2"
                  color="red"
                  text-color="white"
                >
                  Admin
                </v-chip>
                <v-chip
                  v-if="item.role_id == 3"
                  class="ma-2"
                  color="blue"
                  text-color="white"
                >
                  Gerente
                </v-chip>
                <v-chip
                  v-if="item.role_id == 2"
                  class="ma-2"
                  color="purple"
                  text-color="white"
                >
                  Invitado
                </v-chip> -->
              </template>
              <template v-slot:[`item.deleted`]="{ item }">
                {{ item.deleted_at }}
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn color="accent" icon @click="setEditUser(item)">
                  <v-icon> mdi-pencil </v-icon>
                </v-btn>

                <v-tooltip top v-if="item.deleted_at == null">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      color="red"
                      icon
                      @click="showDeleteUserDialog(item.id)"
                    >
                      <v-icon> mdi-delete-alert </v-icon>
                    </v-btn>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>

                <v-tooltip top v-else>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      color="success"
                      icon
                      @click="activeUser(item)"
                    >
                      <v-icon>mdi-backup-restore</v-icon>
                    </v-btn>
                  </template>
                  <span> Reactivar Usuario </span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      color="info"
                      icon
                      @click="showSellers(item.employee_number)"
                    >
                      <v-icon> mdi-eye </v-icon>
                    </v-btn>
                  </template>
                  <span> Ver Asesores relacionados </span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      color="cyan"
                      icon
                      @click="openPasswordDialog(item)"
                    >
                      <v-icon> mdi-lock-alert </v-icon>
                    </v-btn>
                  </template>
                  <span> Gestionar Contraseña </span>
                </v-tooltip>
              </template>
            </v-data-table>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <v-dialog v-model="showUpdateDialog" persistent max-width="600px">
      <update-item
        v-if="Object.keys(user).length > 0"
        :user="user"
        :rolOptions="rolOptions"
        @userUpdated="getUsers"
        :items="managers"
        :onCloseDialog="handleCloseDialog"
        :cleanUser="handleCleanUser"
      ></update-item>
    </v-dialog>

    <v-dialog v-model="dialog" persistent width="600px" scrollable>
      <v-card style="overflow-y: auto;">
        <v-card-title>
          <span class="text-h5">Crear Usuario</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="formEvent" v-model="form.valid">
            <v-tabs v-model="tab" centered dark icons-and-text>
              <v-tabs-slider></v-tabs-slider>
              <v-tab href="#tab-1">
                General
                <v-icon>mdi-card-account-details</v-icon>
              </v-tab>
              <!-- <v-tab href="#tab-2">
                Perfil
                <v-icon>mdi-account</v-icon>
              </v-tab> -->
            </v-tabs>
            <template>
              <v-alert
                text
                outlined
                color="deep-orange"
                icon="mdi-alert"
                v-if="hasError"
                style="font-size: 12px"
              >
                <ul
                  style="text-align: left"
                  v-for="error in hasError"
                  :key="error.id"
                  class="mt-n2"
                >
                  <li>{{ error[0] }}</li>
                </ul>
              </v-alert>
            </template>
            <v-tabs-items v-model="tab">
              <v-tab-item value="tab-1">
                <v-container>
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        label="Nombre *"
                        prepend-icon="mdi-pen"
                        placeholder="Ingrese Nombre"
                        :rules="[(v) => !!v || 'El nombre es requerido']"
                        v-model="form.name"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        label="E-mail *"
                        prepend-icon="mdi-email"
                        placeholder="Ingrese E-mail"
                        :rules="[(v) => !!v || 'El e-mail es requerido']"
                        v-model="form.email"
                        type="email"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="Contraseña *"
                        prepend-icon="mdi-lock"
                        placeholder="Ingrese Contraseña"
                        :rules="[(v) => !!v || 'La contraseña es requerida']"
                        v-model="form.password"
                        type="password"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        :items="rolOptions"
                        v-model="form.role_id"
                        multiple
                        label="Rol *"
                        item-text="alias"
                        item-value="id"
                        prepend-icon="mdi-account-group"
                        :rules="[(v) => !!v || 'El rol es requerido']"
                        @change="
                          form.employee_number = null;
                          form.custumer_code = null;
                        "
                        :menu-props="{ top: true, offsetY: true, 'max-width': '350px'}"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" v-if="showCode">
                      <v-text-field
                        label="Código empleado"
                        prepend-icon="mdi-badge-account-horizontal"
                        placeholder="Ingrese Código de Empleado"
                        v-model="form.employee_number"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" v-if="custumerCode">
                      <v-text-field
                        label="Código cliente"
                        prepend-icon="mdi-badge-account-horizontal"
                        placeholder="Ingrese Código de Cliente"
                        v-model="form.custumer_code"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" v-if="isSeller">
                      <v-select
                        label="Selecciona el gerente"
                        item-value="id"
                        item-text="label"
                        :items="managers"
                        v-model="form.manager_id"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
              <v-tab-item value="tab-2"> </v-tab-item>
            </v-tabs-items>
            <small>*Indica campo requerido</small>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            :disabled="loading"
            @click="closeDialog"
          >
            Cancelar
          </v-btn>
          <v-btn color="success darken-1" :disabled="loading" @click="addUser">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="messageDialog" width="500">
      <v-card :color="typeDialog">
        <v-card-text :class="['text-h5', typeDialog]">
          {{ titleDialog }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="messageDialog = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showSellersDialog">
      <v-card>
        <v-card-title>Asesores a su cargo</v-card-title>
        <v-card-text>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">Nombre</th>
                  <th class="text-center">Email</th>
                  <th class="text-center">Número empleado</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in sellers" :key="`seller-${item.id}`">
                  <td>{{ item.name }}</td>
                  <td>{{ item.email }}</td>
                  <td>{{ item.employee_number }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="showSellersDialog = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDeleteDialog" persistent max-width="500">
      <delete-item
        :userId="userId"
        :onCancel="handleCancelDelete"
        @userDeleted="handleConfirmDelete"
      ></delete-item>
    </v-dialog>

    <v-dialog v-model="showPasswordDialog" persistent max-width="500">
      <manage-password
        :user="user"
        :onCancel="handleCancelPassword"
        @passwordChanged="handleConfirmPassword"
      ></manage-password>
    </v-dialog>

    <v-btn fab fixed bottom right color="teal" dark @click="dialog = true">
      <v-icon dark> mdi-plus </v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import axios from "@/plugins/axios";
import DeleteItem from "./DeleteItem.vue";
import UpdateItem from "./UpdateItem.vue";
import ManagePassword from "./ManagePassword.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AdministratorView",
  components: {
    DeleteItem,
    UpdateItem,
    ManagePassword,
  },
  data() {
    return {
      data: [],
      search: "",
      oldsearch: "",
      idUser: null,
      form: {
        valid: false,
        name: null,
        email: null,
        password: null,
        role_id: [],
        employee_number: null,
        custumer_code: null,
        manager_id: null,
      },
      loading: false,
      headers: [
        { text: "Nombre", value: "name" },
        { text: "Número Empleado", value: "employee_number" },
        { text: "Código Cliente", value: "custumer_code" },
        { text: "Email", value: "email" },
        { text: "Rol", value: "role" },
        { text: "Baja", value: "deleted_at" },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      /* rolOptions: [
        { type: "Invitado", value: 2 },
        { type: "Gerente", value: 3 },
        { type: "Administrador", value: 5 },
      ], */
      options: {
        page: 1,
        itemsPerPage: 15,
        total: 0,
        sortBy: [],
        descending: [false],
        options: [25, 50, 100],
        deleted: false,
      },
      dialog: false,
      menu: false,
      menu2: false,
      tab: null,
      messageDialog: false,
      titleDialog: "",
      typeDialog: "success",
      hasError: null,
      rolOptions: [],
      managers: [],
      showUpdateDialog: false,
      user: {},
      showSellersDialog: false,
      userId: null,
      showDeleteDialog: false,
      showPasswordDialog: false,
    };
  },
  mounted() {
    // this.getUsers();
    this.getRoles();
  },
  computed: {
    ...mapGetters({
      sellers: "administrator/sellers",
    }),
    showCode() {
      const rolesAccepted = this.rolOptions.filter((item) =>
        ["Manager", "Administrator", "Director", "Seller"].includes(item.name)
      );

      return this.form.role_id.some((role) =>
        rolesAccepted.map((item) => item.id).includes(role)
      );
    },
    custumerCode() {
      let customerRole = this.rolOptions.find(
        (item) => item.name == "Customer"
      );
      let customerPrRole = this.rolOptions.find(
        (item) => item.name == "CustomerPR"
      );
      for (let i = 0; i < this.form.role_id.length; i++) {
        if (
          this.form.role_id[i] == customerRole.id ||
          this.form.role_id[i] == customerPrRole.id
        ) {
          return true;
        }
      }
      return false;
    },
    isSeller() {
      const sellerRole = this.rolOptions.find((item) => item.name == "Seller");
      if (sellerRole && Array.isArray(this.form.role_id)) {
        return this.form.role_id.includes(sellerRole.id);
      }
      return false;
    },
  },
  watch: {
    search(value) {
      if (value && value.length >= 3) {
        this.getUsers(value);
      }
    },
    options: {
      handler() {
        this.getUsers();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      loadSellers: "administrator/loadSellers",
      downloadExcel: "administrator/downloadExcel",
      restoreUser: "administrator/restoreUser",
    }),
    getUsers() {
      if (this.loading) return;

      this.showUpdateDialog = false;
      this.loading = true;

      if (this.search != this.oldsearch) {
        this.options.page = 1;
        this.oldsearch = this.search;
      }

      const { sortBy, sortDesc, page, itemsPerPage, deleted } = this.options;

      axios
        .get(
          `getUsers?sortBy=${sortBy}&sortDesc=${sortDesc}&page=${page}&perPage=${itemsPerPage}&withTrashed=${deleted}&search=${this.search}`
        )
        .then((response) => {
          this.data = response.data.data;
          this.options.page = response.data.current_page;
          this.options.itemsPerPage = response.data.per_page;
          this.options.total = response.data.total;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getRoles() {
      axios
        .get(`getRoles`)
        .then((response) => {
          this.rolOptions = response.data;
          let managerRole = this.rolOptions.find(
            (item) => item.name == "Manager"
          );

          if (managerRole) {
            this.getManagers(managerRole.id);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getManagers(roleId) {
      axios
        .get(`/roles/${roleId}/users`)
        .then((response) => {
          this.managers = response.data.map((item) => {
            item.label = `${item.name} - ${item.email}`;
            return item;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addUser() {
      this.$refs.formEvent.validate();
      if (!this.form.valid) {
        return false;
      }
      axios
        .post(`/createUser`, this.form)
        .then((response) => {
          if (response.status == 201) {
            //this.alertDialog("success", response.data.message);
            this.dialog = false;
            this.$refs.formEvent.resetValidation();
            this.resetForm();
            this.hasError = null;
            this.getUsers();
            return this.$toast.success(response.data.message);
          }
        })
        .catch((error) => {
          this.hasError = error.response.data.errors;
          //alert(error);
          /* this.alertDialog(
            "danger",
            "Error al guardar.\n" + error.response.data.message
          ); */
        })
        .finally(() => {
          this.loading = false;
        });
    },
    closeDialog() {
      this.dialog = false;
      this.$refs.formEvent.resetValidation();
    },
    deleteItem(item) {
      this.idUser = item;
    },
    alertDialog(type, message) {
      this.messageDialog = true;
      this.titleDialog = message;
      this.typeDialog = type == "success" ? "green" : "amber";
      setTimeout(() => {
        this.messageDialog = false;
        this.titleDialog = "";
        this.typeDialog = "success";
      }, 2000);
    },
    resetForm() {
      this.form.valid = false;
      this.form.name = null;
      this.form.email = null;
      this.form.password = null;
      this.form.role_id = [];
      this.form.employee_number = null;
      this.form.custumer_code = null;
      this.form.manager_id = null;
    },
    setEditUser(item) {
      this.user = item;
      this.showUpdateDialog = true;
    },
    handleCloseDialog() {
      this.showUpdateDialog = false;
      this.user = {};
    },
    showSellers(employeeNumber) {
      this.loadSellers(employeeNumber)
        .then(() => {
          this.showSellersDialog = true;
        })
        .catch((error) => {
          this.$toast.warning(
            error.response.data.message || "Ocurrio un error"
          );
        });
    },
    clearSearch() {
      this.search = "";
      this.getUsers();
    },
    generateExcel() {
      const { sortBy, sortDesc, page, itemsPerPage, deleted } = this.options;

      window.open(
        `${this.$baseApiUrl}/administrator/users/excel?sortBy=${sortBy}&sortDesc=${sortDesc}&page=${page}&perPage=${itemsPerPage}&withTrashed=${deleted}&search=${this.search}`,
        "_blank"
      );
    },
    activeUser(user) {
      this.restoreUser(user)
        .then(() => {
          this.$toast.success("Usuario reactivado correctamente");
          this.getUsers();
        })
        .catch((error) => {
          console.log(error);
          this.$toast.warning("Ocurrio un error");
        });
    },
    showDeleteUserDialog(userId) {
      this.userId = userId;
      this.showDeleteDialog = true;
    },
    handleCancelDelete() {
      this.userId = null;
      this.showDeleteDialog = false;
    },
    handleConfirmDelete() {
      this.getUsers();
    },
    handleCancelPassword() {
      this.user = {};
      this.showPasswordDialog = false;
    },
    handleConfirmPassword() {
      this.showPasswordDialog = false;
    },
    openPasswordDialog(user) {
      this.user = user;
      this.showPasswordDialog = true;
    },
    handleCleanUser() {
      this.user = {};
    }
  },
};
</script>

<style>
</style>
