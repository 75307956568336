<script>
import { Pie } from 'vue-chartjs'

export default {
  extends: Pie,
  props: {
    chartdata: {
      type: Object,
      default: null
    }, 
  },
  data: () => ({
    /*chartData: {
       // labels: ['VueJs', 'EmberJs', 'ReactJs', 'AngularJs'],
        datasets: [
          {
            backgroundColor: ['#746EE5', '#FF9F00', '#00B99D'],
            data: [10, 10, 80]
          } 
        ]
      },*/
    options: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
            display: true,
            position: 'bottom',
        },
      tooltips: {
            callbacks: {
                label: function(tooltipItem , data) {
                    var label = data.datasets[0].data[tooltipItem.index];
                  //  var indexPorcentaje = tooltipItem.index;
                   // var porcentaje = data.datasets[0].porcentaje[indexPorcentaje];
                  //  label += '-';
                  //  label += porcentaje;
                    label += '%';
                    return label;
                }
            }
        },

        yAxes: [{
          ticks: {
            beginAtZero: true
          }
        }]
    }
  }),
  mounted () {
    this.renderChart(this.chartdata, this.options)
  },
  watch:{
    chartdata (){
      this.renderChart(this.chartdata, this.options)
    }
    
  }
}

</script>
