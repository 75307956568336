<template>
  <v-container fluid>
    <template>
      <v-row>
      <v-col>
      <v-card cols="12" class="pa-xs-2 pa-md-2 pa-lg-2 mt-xs-0 mt-md-0 mt-lg-0 mb-7">
        <v-card-title>PRODUCTOS CON DESCUENTO DE LIQUIDACIÓN {{id_order_s}}</v-card-title>
        <v-row>
          <v-col md="6" >
            <v-row>
              <v-col md="4" class="text-right">
              <v-btn
              :loading="loading3"
              :disabled="loading3"
              color="success"
              dark
              @click="excelDownload()"
              >
              Descargar Excel
              </v-btn>
              </v-col>
              <v-col md="4" class="text-left">
                <v-btn
                :loading="loading4"
                 :disabled="loading4"
                 color="blue"
                 dark
                 @click="openDialogEmail()"
                >
              Enviar Correo
            </v-btn>
              </v-col>
            </v-row>

          </v-col>
          <v-col md="6">
            <v-row>
              <v-col md="4"></v-col>
              <v-col md="7">
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <br>
        <v-data-table
          dense
          :headers="headers"
          :items-per-page="10"
          :items="data"
          :search="search"
          item-key="order"
          no-data-text="No hay datos disponibles"
          @update:options="loadUsersWithOptions"
        >
        <template v-slot:[`item.material_image`]="{ item }">
              <v-img :src="item.material_image" v-if="item.material_image != ''"  width="100"
               class="imageCarItem" />
               <v-img src="img/img-default.svg" v-if="item.material_image == 'Not Found'" width="100"
                class="imageCarItem" />
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-btn class="ma-1 elevation-0" color="success" fab x-small outlined @click="openDialog(item)">
              <v-icon >
                  mdi-cart-plus
              </v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.units`]="{ item }">
          <v-text-field
            v-model="item.units"
            ></v-text-field>
        </template>
        <template v-slot:[`item.discount`]="{ item }">
          <div> {{seePorcent(item.discount)}} %</div>
        </template>
        </v-data-table>
        <br>
        <v-row class="mb-5">
          <v-col md="6">
          </v-col>
          <v-col md="6" class="text-right">

          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
        <!-- Dialog loading -->
  <v-row justify="center">
      <v-dialog v-model="loading" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Cargando
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- /. Dialog -->
    <v-dialog v-model="dialog" width="700" persistent>
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Agregar Material al Carrito
          </v-card-title>
          <v-card-text>
            <p></p>
            <div class="col-md-12" v-if="hasError">
            <v-alert text dense outlined type="error">
              {{ warnerror }}
            </v-alert>
          </div>
            <p></p>
            <v-row>
              <v-col md="3">                
                  <label >Imagen:</label>
                  <v-img :src="itemSelect.material_image" v-if="itemSelect.material_image != ''"  width="100"
               class="imageCarItem" />
              </v-col>
              <v-col md="3">                
                  <label >Material:</label>
                   <v-text-field
                   solo
                   readonly
                   v-model="m_code"
                    >{{m_code}}</v-text-field>
              </v-col>
              <v-col md="3">
                <div class="form-group">
                  <label >Empaque:</label>
                   <v-text-field
                   solo
                   readonly
                   v-model="pack"
                    >{{pack}}</v-text-field>
                </div>
              </v-col>
              <v-col md="3">
                <div class="form-group">
                  <label >Cantidad Confirmada:</label>
                   <v-text-field
                   solo
                   readonly
                   v-model="confirm_quantity"
                    >{{confirm_quantity}}</v-text-field>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="error" text @click="closeDialog()"> Cancelar </v-btn>
            <v-spacer></v-spacer>
            <v-btn :disabled="hasError" color="success" text @click="sendtoCart()">
              Enviar a Carrito
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
            <!-- /. Dialog -->
      <v-dialog v-model="dialog_email" width="500" persistent>
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Enviar por Correo
          </v-card-title>
          <v-card-text>
            <p></p>
            <h6 style="text-align: left; font-weight: 700">
              Materiales Descontinuados
            </h6>
            <p></p>
            <v-row>
              <v-col md="12">                
                <div class="form-group">
                  <label >Ingresar Email:</label>
                   <v-text-field
                   solo
                   v-model="email_user"
                    >{{email_user}}</v-text-field>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="error" text @click="close2()"> Cancelar </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="sendEmail()">
              Enviar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-container>
</template>
<script>
import axios from "@/plugins/axios";
import { mapGetters,mapActions } from "vuex";
import moment from 'moment';
export default {
  name: "OrderCart",
  computed: {
    ...mapGetters({
      user: "auth/user",
      
    }),
  },
  data: () => ({
    customer_code: null,
    id_order: null,
    id_order_s: null,
    data:[],
    loading: false,
    search: "",
    dialog: false,
    m_code: null,
    pack: null,
    quantity : null,
    confirm_quantity:null,
    units: null,
    warnerror: null,
    hasError: false,
    loading3: false,
    loading4: false,
    dialog_email: false,
    email_user: null,
    cartItems: null,
    itemSelect: {},
    itemsMaterial: [],
    options: {},
    totalMaterial: 0,
    today2: moment().format('YYYY/MM/DD'), 
    itemCSV:[],
    headers: [
      { text: "Código", align: "start", value: "material_image" },
      { text: "", align: "start", value: "material_code" },
      { text: "Descripción", align: "start", value: "description" },
      { text: "Centro", value: "center" },
      { text: "Existencia", sortable: false, value: "stock" },
      { text: "Importe", sortable: false, value: "amount" },
      { text: "Descuento ZK14", sortable: false, value: "discount" },
      { text: "Cantidad", sortable: false, value: "units" },
      { text: "Agregar a Carrito",align: "center", sortable: false, value: "action" },
    ],
    type:null,
  }),
  watch: {
    data: {
            immediate: true,
            handler() {
                this.generarExcel()
            },
    },
  },
  mounted() {

    if (this.$hasAnyRole(['Customer','CustomerPR'])) {
  this.customer_code =  this.user.custumer_code;
  this.type = 'CL';
 }else{
  const currentCustomerInfo= JSON.parse(localStorage.getItem('currentCustomerInfo')) || [];
        if (currentCustomerInfo.length<1) {
        this.$router.push({name:'Home'})
        } 
  this.customerInfo_1 = currentCustomerInfo;
  this.customer_code =  parseInt(currentCustomerInfo?.KUNNR,10) 
  this.type = 'VE';
 } 

    let self = this;
    self.getDiscontinuedMat();
    this.email_user = this.user.email;
    //console.log(self.orderShop);
    this.countMaxValues(this.data).then(() => {
      this.loadingTime = (Date.now() - this.timerStart) / 1000;
    });
    this.generarPDF();
  },
  methods: {
    ...mapActions({
        sendToPDF: 'printer/sendToPDF',
        sendToExcel: 'printer/sendToExcel',
    }),
    generarPDF(){
            this.sendToPDF({active: true});
    },
    generarExcel(){
      let newItems = []
      this.itemCSV = []

      for (let key in this.data) {                
                let item = this.data[key];
                newItems.push({
                        'Código': item.material_code,
                        'Descripción': item.description,
                        'Centro': item.center,
                        'Existencia': item.stock,
                        'Importe':item.amount,
                        'Descuento ZK14.': item.discount,
                    });
              }
      this.itemCSV = newItems

      let objectCSV = {
                'name': `Mat.Desc.-${this.today2}`,
                'items': this.itemCSV,
                'showLabels': true
            }

    this.sendToExcel(objectCSV)
    },
      getDiscontinuedMat() {
          this.loading = true;
      axios
        .post("get-discontinued-mat", {
          VTWEG: this.user.VTWEG,

        })
        .then((response) => {
          this.data = response.data;
          this.getImages();
        })
        .catch((error) => {
          //alert(error);
          console.log(error);
        })
        .finally(() => (this.loading = false));
    },
    seePorcent(value) {
    return parseFloat(value * 100).toFixed(2);
  },
    openDialog(item) {
    console.log(item);
    this.invoice = item.pay_complement;
    this.units = item.units;
    this.itemSelect = item;
    //this.id_order = itto em.id;
    this.materialInfoDT(this.units,item);
    this.getImge(item);
    this.dialog = true;
    },
    materialInfoDT(uni,item){
      axios
    .post("get-material-info-pr", {
      code: item.material_code,
      units: uni,
      customer: this.customer_code,
      VKORG: this.user.VKORG,
      VTWEG: this.user.VTWEG,
      VKBUR: this.user.VKBUR,
    })
    .then((response) => {
      this.material_info = response.data;
      this.m_code = this.material_info.codigo_material;
      this.pack = this.material_info.empaque;
      this.quantity = this.material_info.u_pedidas;
      this.confirm_quantity = this.material_info.u_confirm;
    })
    .catch((error) => {
      console.log("Error:"+error.response.data[0]);
      this.hasError = true;
      this.warnerror = error.response.data.error_code;
    })
    .finally();
    },
  closeDialog() {
    
    this.hasError = false;
    this.m_code= null,
    this.pack= null,
    this.quantity = null,
    this.confirm_quantity=null,
    this.dialog = false;
  },
  sendtoCart(){
  //let type = "CL";
    //let type = "VE"; // ENCASO DE PORTAL VENDEDOR ENVIAR ESTA VARIABLE
    axios
      .post("get-order-id-pr", {
        customer: this.customer_code,
        user_id: this.user.id,
        type: this.type,
        doctype: 'PSIU'
      })
      .then((response) => {
        this.order_id = response.data;

        console.log("getOrderIDPR");
        console.log(this.order_id);
        this.getCartMaterials(this.order_id,this.m_code);

      })
      .catch((error) => {
        ///alert(error);
        console.log(error);
      })
      .finally();
  },

  getCartMaterials(order_id,m_code){
           //////////////
  axios
      .post("get-cart-materials-pr", {
        order_id:  order_id,
        material_code: m_code,
      })
      .then((response) => {
        console.log("get-cart-materials-pr");
        console.log(response.data);
        this.saveNewMaterialOrder(order_id,this.material_info)
      })
      .catch((error) => {
        ///alert(error);
        if(error.response.status == 404){
        //alert(error.response.data.error_code);
          this.$swal({
            title: error.response.data.error_code,
            icon: "error",
            type: "error",
          }).then((result) => {
            console.log(result);
           // location.reload();
            this.hasError = false;
            this.m_code = null,
            this.pack = null,
            this.quantity = null,
            this.confirm_quantity = null,
            this.dialog = false;
          });
        }else{
          //alert(error);
          console.log(error);
        }
      })
      .finally();
       /////////////

  },
  saveNewMaterialOrder(order_id,material_info){
    axios
      .post("save-new-material-order-pr", {
        order_id: order_id,
        item_cart: material_info,
      })
      .then((response) => {
        console.log(response);

        this.$swal({
            title: "Se agrego correctamente",
            icon: "success",
            type: "success",
          }).then((result) => {
            console.log(result);
           // location.reload();
            this.hasError = false;
            this.m_code = null,
            this.pack = null,
            this.quantity = null,
            this.confirm_quantity = null,
            this.dialog = false;
          });
      })
      .catch((error) => {
        console.log(error);

      })
      .finally(() => (this.dialogLoading = false));
  },
  excelDownload(){
    this.loading3 = true;
   axios
      .post("export-material-desc-exc", {
        VTWEG: this.user.VTWEG,
      },
      {responseType: 'blob'})
      .then((response) => {
        console.log(response.data);
        const current = new Date();
        const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'materiales_descontinuados'+'_'+date+'.xlsx'); //or any other extension
          document.body.appendChild(link);
          link.click();
          //this.loading3= false;
      })
      .catch((error) => {
        ///alert(error);
        console.log(error);
        this.loading3= false;
      })
      .finally(this.loading3= false);
  },
  openDialogEmail(){
   this.dialog_email = true;
  },
  close2() {
      this.dialog_email = false;
  },
  sendEmail(){
    this.loading4 = true;
   axios
      .post("export-material-desc-exc-email", {
        VTWEG: this.user.VTWEG,
        email: this.email_user,
      })
      .then((response) => {
        console.log(response.data);
        this.$swal({
          title: response.data.message,
          icon: "success",
          type: "succes"
        }).then((result) => {
          //this.getRecordatoriosHistory();
          console.log(result);
          this.dialog_email = false;

        }
        );
        })
      .catch((error) => {
        ///alert(error);
        console.log(error);
        this.loading4= false;
      })
      .finally(this.loading4= false);
  },
  getImages() {
    for(let i = 0; i < this.data.length; i++) {
      axios.get(`https://bancodeimagenes.iusa.com.mx/api/listDropboxNew?code=${this.data[i].material_code}&quant=1&view=0`, {
        headers: {
          'Content-Type':'application/json',
          'api-key':'$2y$10$PtV2yrGrt/CwoVlqGDroiOyoUSq581Pq.6NVyAX2vQJUBdE21gxKC',
          'Authorization':'Bearer token',
        }
      }).then(response => {
        this.data[i].material_image = response.data[0].path;
      })
    }
  },
  getImge(item) {
   // this.warnDialogLoading(true);
    axios
      .get("https://bancodeimagenes.iusa.com.mx/api/listDropboxNew?code="+item.material_code+"&quant=1&view=0", {
        headers: {
          'Content-Type':'application/json',
          'api-key':'$2y$10$PtV2yrGrt/CwoVlqGDroiOyoUSq581Pq.6NVyAX2vQJUBdE21gxKC',
          'Authorization':'Bearer token',
        }
      })
      .then((response) => {
        if(response.data[0].path) {
          let index = this.data.findIndex(element => element.material_code == item.material_code);

          this.data[index].material_image = response.data[0].path
          console.log('index', index);
        }
        console.log('dropbox', response.data[0].path);
        //this.validacionCredicticia();
        //this.warnCart(this.cart);
      })
  },
  async countMaxValues(array) {
      this.timerStart = Date.now();
      for (const item of array) {
        await this.countMaxValue(item).catch(() => {
          //Even when one request throws error we should not stop others
        })
      }
    },
    async countMaxValue(item) {
      await new Promise(resolve => setTimeout(resolve, 50)).then(() => {
        let maxVal = Math.random() * 100;
        item.maxValue = maxVal < 20 ? null : maxVal;
        this.data.splice(item.id, 1, item);
        
      });
    },
  async loadUsersWithOptions({page, itemsPerPage, sortBy, sortDesc}) {
  console.log("page"+page);
  console.log("itemsPerPage"+itemsPerPage);
  console.log("sortBy"+sortBy);
  console.log("sortDesc"+sortDesc);

  console.log(this.data);
  const pageLess = page -1;
  this.callMaterialFromPage(pageLess, itemsPerPage, this.data);
  },


    callMaterialFromPage(page, size, data){
      let from = page * size;
      let to = from + size;
      console.log(data.slice(from,to));
      //let item = data.slice(from,to);
      for (const item of data.slice(from,to)) {
        console.log("item"+ item.material_code);
        this.getImge(item);
      }
    },
},
};



</script>
  