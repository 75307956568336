import moment from "moment";
import helpers from "./helpers";
export default {
    mixins:[helpers],
    methods: {        
        /** Función para calcular los últimos trimestres a partir de una fecha de inicio 
        y un número dado de trimestres hacia atrás.  */
        calcularUltimosTrim(numTrim, inicioMes = 0, initYear = moment().year()) {
            const trimestre = Math.ceil(inicioMes / 3);
            let fechaInicioTrim = (inicioMes === 0) ? moment() : moment({ year: initYear, month: (trimestre - 1) * 3, day: 1 });
            
            // Extrae el año y el mes de la fecha resultante
            const anioInicio = fechaInicioTrim.year();
            const mesInicio = fechaInicioTrim.format('MM');

            // Crea un objeto Moment.js con el año y mes obtenidos, ajustando el índice de mes
            const fechaInicio = moment({ year: anioInicio, month: mesInicio - 1 });

            // Inicializa un array para almacenar los trimestres calculados
            let arrayTrimestres = [];

            // Itera a través del número especificado de trimestres
            for (let i = 0; i < numTrim; i++) {
                // Clona la fecha de inicio y resta meses para obtener el inicio de cada trimestre
                const trimestreInicio = fechaInicio.clone().subtract(i * 3, 'months');

                // Calcula el número de trimestre y el año correspondiente
                const trimestreNum = Math.floor(trimestreInicio.month() / 3) + 1;
                const trimestreAnio = trimestreInicio.year();

                // Agrega un objeto al array con la información del trimestre
                arrayTrimestres.push({ date: `${trimestreNum}/${trimestreAnio}`, trim: trimestreNum, year: trimestreAnio });
            }

            // Retorna el array con los trimestres calculados
            return arrayTrimestres;
        },
        calcularUltimosMeses(numMeses, inicioMes = 0,anio=null) {
            var fechaInicioMes =''
            if (inicioMes === 0) {
                 fechaInicioMes =  moment()
            }else{
                 const year = anio??moment().year()
                 fechaInicioMes =  moment({ year:  year, month: inicioMes - 1 }) 
            }
            // Obtén la fecha actual            
         //   let fechaInicioMes = (inicioMes === 0) ? moment() :            
          
            // Extrae el año y el mes de la fecha resultante
            const anioInicio = fechaInicioMes.year();
            const mesInicio = fechaInicioMes.format('MM');
          
            // Crea un objeto Moment.js con el año y mes obtenidos, ajustando el índice de mes
            const fechaInicio = moment({ year: anioInicio, month: mesInicio - 1 });
          
            // Inicializa un array para almacenar los meses calculados
            let arrayMeses = [];
          
            // Itera a través del número especificado de meses
            for (let i = 0; i < numMeses; i++) {
              // Clona la fecha de inicio y resta meses para obtener el inicio de cada mes
              const mesInicio = fechaInicio.clone().subtract(i, 'months');

               // Obtiene el nombre del mes en formato de texto
                let nombreMes = mesInicio.format('MMM');
                const fullNombreMes =  mesInicio.format('MMMM');
                nombreMes = this.obtenerMesAbreviado(nombreMes)
              // Calcula el número de mes y el año correspondiente
              
              const mesNum = mesInicio.month() + 1; // Ajusta el índice de mes
              const mesAnio = mesInicio.year();
              
              // Agrega un objeto al array con la información del mes
              arrayMeses.push({ 
                date: `${nombreMes} ${mesAnio}`, 
                month: mesNum, 
                year: mesAnio,
                short_name: nombreMes,
                full_name:this.capitalize(fullNombreMes)
            });
            }
          
            // Retorna el array con los meses calculados
            return arrayMeses;
        },
        generarListaDeAnios(numAnios) {
            const anioActual = moment().year();
            const listaDeAnios = [];
            
            for (let i = 0; i < numAnios; i++) {
                listaDeAnios.push(anioActual - i);
            }
            
            return listaDeAnios;
        },
        obtenerMesAbreviado(mes) {
            mes = mes.slice(0, -1) //quitamos el punto final
            return mes.charAt(0).toUpperCase() + mes.slice(1);
        }, 
        //Retorna un string con los meses indicados 
        /**
         * @param {Number} numberOfmonths 
         * @param {String} type //short_name,full_name,year,month,date
         * @param {String} separator 
         * @returns string
         */
        getMonthsInStringWithSeparator(numberOfmonths=0,type='short_name',separator=','){
            var months = this.calcularUltimosMeses(numberOfmonths); 
            months = this.implode(this.arrayPluck(months,type),separator)
            return months;
          }, 
          getMonthsFromYear(year) {
            const startDate = moment(`${year}-01-01`); 
            var endDate = moment(); // Fecha actual
            if (year!=endDate.format('YYYY')) {
                endDate =  moment(`${year}-12-01`); 
            }
            const months = []; 
            while (startDate.isBefore(endDate) || startDate.isSame(endDate, 'month')) {
              const monthName = startDate.format('MMMM');
              const monthNumber = startDate.format('M');
              const year = startDate.format('YYYY'); 
              months.push({ label: this.capitalize(monthName), value: monthNumber, year: year }); 
              startDate.add(1, 'month');
            } 
            return months;
          },
          getYearsList(numbersOfYears) { 
            var year =moment().year();
            const years = []; 
            for (let i = 0; i <= numbersOfYears; i++) { 
                years.push({ label: year-i, value: year-i});
            }  
            return years;
          },
        getNumberOfMonthsFromDate(finishDate="",startDate="")
        { 
            if (!finishDate) {
                return { 
                   diff:0, 
                   startDate: startDate, 
                   finishDate: finishDate, 
                   };     
                }
                var dateIni = (!startDate)?moment():(moment(startDate, 'DD/MM/YYYY', true).format())
                var lastDate = moment(finishDate, 'DD/MM/YYYY', true).format()
                const monthDifference =  moment(dateIni).diff(lastDate, 'months', true);  
                return { 
                    diff: monthDifference?parseInt(monthDifference):0, 
                    startDate: dateIni, 
                    finishDate: lastDate, 
                    };        
        },
        getCurrentPeriodBreadcrumb(){
            const today = new Date;
            let firstDay = 1; 
            let year = today.getFullYear();
            let currentMonth = today.toLocaleString('default', { month: 'long' }) 
             currentMonth=  currentMonth.charAt(0).toUpperCase() + currentMonth.slice(1);
            return ` del (${firstDay} de ${currentMonth} ${year} al ${today.getDate()} de ${currentMonth} del ${year})`;
          },
        getBusinessDaysFromDates(startDate,endDate){
            //startDate = moment().startOf('month');
            //endDate = moment().subtract(1, 'month').endOf('month');
            let businessDays = 0;  
            let start = moment(startDate);
            let end = moment(endDate); 
            while (start.isBefore(end, 'day')) { 
                if (start.day() !== 0 && start.day() !== 6) {
                    businessDays++;
                } 
                start.add(1, 'days');
            } 
            return businessDays;
        },
        getQuartersList(maxQuarters = 8,separator="-"){  //Lista numero de trimestres a partir de un número desde el actual hacia atrás  
            var today =  moment().format('MM'); 
            const trimestre = Math.ceil(today / 3);
                let startQuarter = (today === 0) ? moment() : moment({ year: moment().year(), month: (trimestre - 1) * 3, day: 1 });
                const startYear = startQuarter.year();
                const startmonth = startQuarter.format('MM'); 
                 var startDate = moment({ year: startYear, month: startmonth - 1 }); 
                 let quarters = []; 
                for (let i = 0; i < maxQuarters; i++) { 
                    const quarter = startDate.clone().subtract(i * 3, 'months'); 
                    const currentquarter = Math.floor(quarter.month() / 3) + 1;
                    const quarterYear = quarter.year();
                    const period = `${quarterYear}${separator}${currentquarter}`; //Current quarter en ese momento del calculo
                    quarters.push({ value: period,label:period });
                } 
                return quarters; 
      },
      /**
       * 
       * @param {[]} arrNumbers - Arreglo de números a validar.
       * @returns {{min: number, max: number}} Un objeto con el valor mínimo y máximo del arreglo.
       */
      validateNumbersMaxMin(arrNumbers){
        return { 
            min: Math.min(...arrNumbers), 
            max: Math.max(...arrNumbers) 
        };
      }          
    }
}

