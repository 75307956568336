<template>
    <!-- Menu Print -->
    <div class="btn-print px-2" v-if="(excel.items).length > 0 || pdf.active">        
        <v-menu bottom offset-y>
            <template v-slot:activator="{ on, attrs }">          
                <v-icon 
                    class="btn-print-icon"
                    v-bind="attrs"
                    v-on="on">
                    mdi mdi-printer-outline
                </v-icon>
            </template>
            <div class="modal-print elevation-2">
                <v-row>
                    <v-col>
                        <p class="mb-2"><b>Descargar en:</b></p>   
                        <v-btn-toggle>                                
                            <v-btn v-if="(excel.items).length > 0" class="btns-print-group " outlined color="grey">
                                <vue-json-to-csv :show-labels="excel.showLabels" :json-data="excel.items" :csv-title="nameExcel()">                                            
                                    Excel
                                    <v-icon color="green" class="ml-2" small>mdi mdi-file-download</v-icon>            
                                </vue-json-to-csv> 
                            </v-btn>                                     
                            <v-btn class="btns-print-group" outlined color="grey" @click="generarPDFWithDelay">
                                PDF
                                <v-icon color="red" class="ml-2" small>mdi mdi-file-download</v-icon>
                            </v-btn>                        
                        </v-btn-toggle>
                    </v-col>
                </v-row>
            </div>
      </v-menu>
    </div>
    <!-- End Menu Print -->
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions } from "vuex";
import VueJsonToCsv from 'vue-json-to-csv'
import html2pdf from 'html2pdf.js'
import moment from 'moment'
export default {
    components: {VueJsonToCsv},
    computed:{
        ...mapGetters({
            excel: 'printer/getExcel',
            pdf: 'printer/getPDF'
        })      
    },
    watch: {
        '$route'() {
            this.resetPrinter();
        },
    },
    data(){
        return{
            today: moment().format('YYYY/MM/DD'),
        }
    },
    mounted(){
    },
    methods: {
        ...mapActions({
            resetPrinter: 'printer/resetPrinter',
        }),
        generarPDFWithDelay() {
            // Agrega un retraso de 1 segundo (1000 milisegundos) antes de ejecutar la función
            setTimeout(() => {
                this.generarPDF();
            }, 1000);
        },
        generarPDF(){
            var element = document.querySelector('html');
            var disabled_pdf = document.querySelector(`.${this.pdf.disabledClass}`);
            if (disabled_pdf) {
                disabled_pdf.style.display = 'none';
            }

            var opt = {
                margin:       1,
                filename:     this.namePDF(),
                image:        { type: 'jpeg', quality: 0.98 },
                html2canvas:  { scale: 2 },
                jsPDF: { unit: 'px', format: [window.innerWidth, this.pdf.height], orientation: 'landscape' }
            };

            html2pdf(element, opt).then(function () {
                // Mostrar los radio buttons después de la exportación
                if (disabled_pdf) {
                    disabled_pdf.style.display = 'block';  
                }
            });
        },
        namePDF(){
            return this.pdf.name != null  ? `${this.excel.name}-${this.today}` : `${this.$route.name}-${this.today}`;
        },
        nameExcel(){
            return this.excel.name != null  ? `${this.excel.name}-${this.today}` : `${this.$route.name}-${this.today}`;
        }
    },
}
</script>

<style scoped>
    .v-menu__content {
        overflow-y: inherit !important;
        overflow-x: inherit !important;
        contain: inherit !important;
        min-width: auto !important;
    }
    .btn-print .btn-print-icon {
        font-size: 30px !important;
        color: #FFF  !important;
    } 
    .modal-print{
        right: -30px !important;      
        min-width: 200px;
    }
    .btns-print-group{        
        font-size: 12px !important;
        height: 40px !important;
        width: 100px;
    }
</style>