<template>
  <g-chart
    v-if="render"
    type="PieChart"
    :data="[header, ...positiveItems]"
    :options="{ pieHole: donutHole, height, legend: { position }, ...options }"
    :create-chart="drawChart"
  />
</template>

<script>
import { GChart } from "vue-google-charts/legacy";

export default { // Can also be used pie chart passing donutHole prop with 0
  
  name: "DonutChart",

  components: {
    GChart,
  },

  props: {
    header: {
      type: Array,
      default: () => ["Label", "Valor"],
    },

    items: {
      type: Array,
      required: true,
    },
    
    donutHole: {
      type: Number,
      default: 0.4,
    },

    height: {
      type: [Number, String],
      default: 400,
    },

    position: {
      type: String,
      default: "bottom",
    },

    options: {
      type: Object,
      default: () => {},
    },

    render: {
      type: Boolean,
      default: true,
    },

    onlyPositiveItems: {
      type: Boolean,
      default: true,
    }
  },
  
  methods: {
    drawChart(el, google) {
      // Fixes a Google Charts bug that throws Container is not defined
      if (el === undefined) {
        return;
      }
      return new google.visualization.PieChart(el);
    },
  },

  computed: {
    positiveItems() { // google chart won't render if a negative value is given
      return this.onlyPositiveItems ? this.items.filter(i => i[1] >= 0) : this.items;
    },
  }
};
</script>