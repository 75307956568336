<template>
    <div class="row d-flex justify-content-between confidential__cont">
        <div class="col-xs-12 col-md-6 col-lg-6 text-left">
            <p class="mb-0">*Ultima fecha de actualización:{{today}} </p>
        </div>
        <div class="col-xs-12 col-md-6 col-lg-6">
            <p class="text-right text-conf">Información confidencial Prohibida su reproducción o divulgación total o parcial, así como su uso o aprovechamiento sin autorización escrita de IUSA.</p>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
export default {
    data(){
        return{
            today: moment().format('YYYY/MM/DD HH:mm:ss a'), 
        }
    }
}
</script>

<style scoped>
    .confidential__cont p{
        font-size: 14px;
    }

    .text-conf{
        color: #BDBDBD;
        text-align: right;
    }

    @media (max-width: 965px){
        .confidential__cont p{
           text-align: center !important;
        }
    }
</style>