<template>
    <div>
      <v-dialog  v-model="showDialog"  :max-width="1000" :persistent="loading">  
        <v-card class="pa-1">
          <div class="text-left pl-3"  style="background-color: #000; color:#fff">
              {{ title }}           </div>
            <div class="text-right mt-n6 mr-1" style="background-color: #000; color:#fff">
              <v-icon size="20" color="#fff" @click="showDialog = false" :disabled="loading">mdi-close-circle</v-icon>
            </div>
            <v-simple-table dense>
              <template v-slot:default >
                <thead class="">
                  <tr class="custom-table-bordered ">
                    <th class="text-center text-dark " colspan="8"><b>{{customer.KUNNR}} {{customer.NAME1}}</b></th>
                  </tr>
                  <tr class="custom-table-bordered ">
                    <th class="text-center text-dark ">
                      <span class="text-secondary" v-if="!paymentAmount.total>0 && !loading">No se ha encontrado información</span>
                    </th>
                    <th v-show="paymentAmount.total > 0" class="text-center text-dark " v-for="(item,index) in paymentAmount.headers" :key="index">
                      {{ dateFormat(item) }}</th>
                  </tr>
                </thead>
                <tbody  v-if="!loading && paymentAmount.total>0" class="custom-table-bordered">
                  <tr >
                    <td class="table-heading small text-body-2">Monto&nbsp;total<br>de&nbsp;pagos</td>
                    <td v-for ="(item, index) in paymentAmount.totalPayments" :key="index" ><b>  {{ formatNumber(item ?? 0,"$",0)}}</b></td>
                  </tr> 
                  <tr>
                    <td class="table-heading small text-body-2">% de pagos IEL</td>
                    <td  v-for ="(item, index) in paymentAmount.percentIEL" :key="index"><b>{{formatFloat(item ?? 0,2)}}%</b></td>
                  </tr>

                  <tr>
                    <td class="table-heading small text-body-2">% de pagos <br>SPEI/Otros pagos</td>
                    <td  v-for ="(item, index) in paymentAmount.percentOtersSPEI" :key="index"><b>{{formatFloat(item ?? 0,2)}}%</b></td>
                </tr>
                </tbody>
                <tbody v-if="loading">
                <tr>
                    <td colspan="7"> <v-progress-linear class="mt-3" indeterminate color="teal"
        ></v-progress-linear> <span class="text--disabled"> Cargando información</span></td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          <v-btn class="ma-2" @click="showDialog = false" :disabled="loading">Cerrar</v-btn> 
          </v-card>
        </v-dialog>
    </div>    
</template>
<script>

import axios from "@/plugins/axios"; 
import moment from "moment";
import numbersFormats from "@/mixins/numbersFormats";


export default({
  name:"PaymentInfo",
  mixins:[numbersFormats],
  props: {
    customer:{ 
      type: Object,
      }, 
      title: {
      type: String,
      default:  '% Pago vía IEL'
      }, 
      value: { 
        type: Boolean,
        default: false
      },
  },   
    data(){
      return {
        loading:true,
        paymentAmount:[]
      }
    },
    mounted(){
       this.getPaymentInfo(this.customer.KUNNR);
    },
    methods: {
      getPaymentInfo(customer = 0){
        this.loading=true;
        axios
          .post("seller/home/customer-payments-by-month", {
            customer_id: customer, 
          })
          .then((response) => { 
            if (response.data?.success==false) {
                console.log( response.data)
            } else {
              this.paymentAmount = response.data;   
            } 
            this.loading =false;
          }).finally( ()=> {
            this.loading=false;
          })
          .catch((e) => {
            console.log(e);
            this.loading =false;
          }); 
      },
   
    dateFormat(date) //format YYYYMM 
    { 
      let month = moment(date,'YYYYMM').format('MMM / YYYY'); 
      return  `${month.toUpperCase()}`;
    }
  },
  computed: {
    showDialog: { 
        set(value) { 
          this.$emit('input', value);
        },
        get(){
          return this.value
        }
      }
  },
  watch: {
    customer(value)
    {
      this.getPaymentInfo(value?.KUNNR)
    }
  },
  
});
</script>
 