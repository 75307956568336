<template>
    <v-card class="m-4" elevation="0">
        <v-card-title>Cliente {{ customer.company_name }}</v-card-title>
        <v-card-text>
            <div>
                <iel-table :headers="customerheders">
                    <tr>
                        <iel-table-cell>{{ customer.person_type }}</iel-table-cell>
                        <iel-table-cell>{{ customer.channel }}</iel-table-cell>
                        <iel-table-cell>{{ customer.custumer_code }}</iel-table-cell>
                        <iel-table-cell>{{ customer.rfc }}</iel-table-cell>
                        <iel-table-cell>{{ customer.company_name }}</iel-table-cell>
                    </tr>
                </iel-table>
            </div>
            <p>Datos Dirección</p>
            <div>
                <iel-table :headers="addressheders">
                    <tr>
                        <iel-table-cell>{{ customer.a_street }}</iel-table-cell>
                        <iel-table-cell>{{ customer.a_number }}</iel-table-cell>
                        <iel-table-cell>{{ customer.interior_number }}</iel-table-cell>
                        <iel-table-cell>{{ customer.a_suburb }}</iel-table-cell>
                        <iel-table-cell>{{ customer.a_town }}</iel-table-cell>
                        <iel-table-cell>{{ customer.a_postal_code }}</iel-table-cell>
                        <iel-table-cell>{{ customer.a_estate }}</iel-table-cell>
                    </tr>
                </iel-table>
            </div>
            <p>Datos Fiscales</p>
            <div class="fiscal-data">
                <div class="fiscal-data-sales">
                    <iel-table :headers="[{text: 'Zona'}, { text: 'Clave' }]">
                        <tr>
                            <th class="boder-x-none text-dark">
                                Oficina de Ventas
                            </th>
                            <iel-table-cell>{{ customer.fiscal.sales_office }}</iel-table-cell>
                        </tr>
                        <tr>
                            <th class="boder-x-none text-dark">
                                Zona de Ventas
                            </th>
                            <iel-table-cell>{{ customer.fiscal.sales_area }}</iel-table-cell>
                        </tr>
                        <tr>
                            <th class="boder-x-none text-dark">
                                Centro de suministro
                            </th>
                            <iel-table-cell>{{ customer.fiscal.supply_center }}</iel-table-cell>
                        </tr>
                        <tr>
                            <th class="boder-x-none text-dark">
                                Código de ramo
                            </th>
                            <iel-table-cell>{{ customer.fiscal.branch_code }}</iel-table-cell>
                        </tr>
                        <tr>
                            <th class="boder-x-none text-dark">
                                Zona de transporte
                            </th>
                            <iel-table-cell>{{ customer.fiscal.transport_area }}</iel-table-cell>
                        </tr>
                    </iel-table>
                </div>
                <div class="fiscal-data-data">
                    <iel-table :headers="fiscalpayment">
                            <iel-table-cell>{{ customer.fiscal.fiscal_regimen }}</iel-table-cell>
                            <iel-table-cell>{{ customer.fiscal?.way_pay?.code }} <br>{{ customer.fiscal?.way_pay?.name }}</iel-table-cell>
                            <iel-table-cell>{{ customer.fiscal.payment_method }}</iel-table-cell>
                            <iel-table-cell>
                                {{ customer.fiscal?.cfdi?.code }} <br>
                                {{ customer.fiscal?.cfdi?.name }}
                            </iel-table-cell>
                            <iel-table-cell>{{ customer.fiscal.iva == 1 ? '16 %' : '' }}</iel-table-cell>
                    </iel-table>
                    <iel-table :headers="fiscalheders">
                        <tr>
                            <iel-table-cell>{{ customer.fiscal.request_credit }}</iel-table-cell>
                            <iel-table-cell>{{ customer.fiscal.payment_condition }}</iel-table-cell>
                            <iel-table-cell>{{ customer.fiscal.delivery_fiscal_addres }}</iel-table-cell>
                            <iel-table-cell>{{ customer.fiscal.fiscal_rep_name }}</iel-table-cell>
                            <iel-table-cell>{{ customer.fiscal.line_request_credit }}</iel-table-cell>
                            <iel-table-cell>{{ customer.fiscal.month_sales }}</iel-table-cell>
                            <iel-table-cell>{{ customer.fiscal.business_size }}</iel-table-cell>
                            <iel-table-cell>{{ customer.fiscal.line_bussines }}</iel-table-cell>
                            <iel-table-cell>{{ customer.fiscal.own_facilities }}</iel-table-cell>
                        </tr>
                    </iel-table>
                </div>
            </div>
            <p>Documentación</p>
            <div class="wrapper">
                <div>
                    <a @click.prevent="openImage('Foto Fachada', customer.document.facade_photo)">
                        <img class="customer-image" :src="`${url}/${customer.document.facade_photo}`" alt="">
                    </a>
                    <p>Foto Fachada</p>
                </div>
                <div>
                    <a @click.prevent="openImage('Foto Adentro', customer.document.facade_photo)">
                        <img class="customer-image" :src="`${url}/${customer.document.indoor_photo}`" alt="">
                    </a>
                    <p>Foto Adentro</p>
                </div>
                <div>
                    <a @click.prevent="openImage('Foto Barra', customer.document.bar_photo)">
                        <img class="customer-image" :src="`${url}/${customer.document.bar_photo}`" alt="">
                    </a>
                    <p>Foto Barra</p>
                </div>
                <div>
                    <v-btn @click="openPdf(customer.document.evid_tax_registration)" elevation="0">
                        <v-icon>mdi-file-pdf-box</v-icon>
                    </v-btn>
                    <p>Constancia de Situación Fiscal</p>
                </div>
                <div>
                    <v-btn @click="openPdf(customer.document.official_id)" elevation="0">
                        <v-icon>mdi-file-pdf-box</v-icon>
                    </v-btn>
                    <p>Identificación oficial de persona Fisica o representante
                    legal</p>
                </div>
                <div>
                    <v-btn @click="openPdf(customer.document.proof_recidency)" elevation="0">
                        <v-icon>mdi-file-pdf-box</v-icon>
                    </v-btn>
                    <p>Comprobante de domicilio dirección fiscal</p>
                </div>
                <div>
                    <v-btn @click="openPdf(customer.document.proof_recidency_distinct)" elevation="0">
                        <v-icon>mdi-file-pdf-box</v-icon>
                    </v-btn>
                    <p>Comprobante de domicilio para entregas</p>
                </div>
                <div>
                    <v-btn @click="openPdf(customer.document.shcp_opening)" elevation="0">
                        <v-icon>mdi-file-pdf-box</v-icon>
                    </v-btn>
                    <p>Formato de Alta de Apertura ante SAT</p>
                </div>
                <div>
                    <v-btn @click="openPdf(customer.document.charter)" elevation="0">
                        <v-icon>mdi-file-pdf-box</v-icon>
                    </v-btn>
                    <p>Acta constitutiva</p>
                </div>
                <div>
                    <v-btn @click="openPdf(customer.document.power_attorney)" elevation="0">
                        <v-icon>mdi-file-pdf-box</v-icon>
                    </v-btn>
                    <p>Poder notarial para ser el representante legal de la
                    persona moral o Física</p>
                </div>
                <div>
                    <v-btn @click="openPdf(customer.document.bank_reference)" elevation="0">
                        <v-icon>mdi-file-pdf-box</v-icon>
                    </v-btn>
                    <p>Referencias Bancarias</p>
                </div>
                <div>
                    <v-btn @click="openPdf(customer.document.promissory_note)" elevation="0">
                        <v-icon>mdi-file-pdf-box</v-icon>
                    </v-btn>
                    <p>Pagaré</p>
                </div>
                <div>
                    <v-btn @click="openPdf(customer.document.official_id_endors)" elevation="0">
                        <v-icon>mdi-file-pdf-box</v-icon>
                    </v-btn>
                    <p>Identificación oficial de Aval</p>
                </div>
                <div>
                    <v-btn @click="openPdf(customer.document.proof_recidency_endors)" elevation="0">
                        <v-icon>mdi-file-pdf-box</v-icon>
                    </v-btn>
                    <p>Comprobante de domicilio de Aval</p>
                </div>
            </div>
            <div>
                <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                    @submit.prevent="updateRequest"
                >
                    <h4>Validar solicitud</h4>

                    <v-select
                    v-model="form.status"
                    :items="[{id: 5, label: 'Aceptar'}, { id: 6, label: 'Rechazar'}]"
                    :rules="[v => !!v || 'El estado es requerido']"
                    label="Actualizar estado de solicitud"
                    required
                    item-text="label"
                    item-value="id"
                    ></v-select>

                    <v-text-field
                    v-model="form.observations"
                    label="Observaciones"
                    required
                    v-if="form.status == 6"
                    ></v-text-field>

                    <v-btn type="submit" color="primary">Guardar</v-btn>
                </v-form>
            </div>
        </v-card-text>
        <v-dialog
            v-model="image.dialog"
            >
            <v-card>
                <v-card-title>
                    <span class="text-h5">Imagen {{ image.name }}</span>
                </v-card-title>
                <v-card-text>
                    <img :src="image.src" alt="" v-if="image.src">
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="closeDialog"
                    >
                        Cerrar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import IelTable from "@/components/tables/IElTable.vue";
import IelTableCell from "@/components/tables/IELTableCell.vue";
import { mapActions, mapGetters } from 'vuex';
export default {
    name: "CreditDetail",

    data: () => ({
        url: '',
        customerId: null,
        valid: false,
        form: {
            status: null,
            observations: null,
        },
        image: {
            dialog: false,
            name: null,
            src: null,
        },
        customerheders: [{ text: 'Tipo', }, { text: 'Canal', }, { text: 'No. Cliente', }, { text: 'RFC', }, { text: 'Nombre', },],
        addressheders: [{ text: 'Calle', }, { text: 'No. Ext', }, { text: 'No. Int', }, { text: 'Colonia', }, { text: 'Municipio/Delegacion',}, { text: 'Código Postal'}, { text: 'Estado'}],
        fiscalheders: [{ text: '¿Solicita Crédito?', }, { text: 'Condición de pago', }, { text: '¿Entrega en domicilio fiscal?', }, { text: 'Representante Legal', }, { text: 'Línea de crédito solicitada',}, { text: 'Ingresos mensuales'}, { text: 'Tamaño del negocio'}, { text: 'Giro del negocio'}, { text: 'Las instalaciones son propias?'},],
        fiscalpayment: [{ text: 'Regimen fiscal'}, { text: 'Forma Pago' }, { text: 'Método de pago'}, { text: 'CFDI' }, { text: 'IVA'}],
    }),

    components: { IelTable, IelTableCell},

    mounted() {
        this.url = this.$filesUrl;
        this.customerId = this.$route.params.customerId;
        this.loadCustomerDetail(this.$route.params.customerId);
    },

    computed: {
        ...mapGetters({
            customer: "customersrequest/customer",
        }),
    },

    methods: {
        ...mapActions({
            loadCustomerDetail: "customersrequest/loadCustomerDetail",
            updateCustomerRequest: "customersrequest/updateCustomerRequest",
        }),
        openPdf(path) {
            window.open(`${this.url}/${path}`);
        },
        updateRequest() {
            this.updateCustomerRequest({id: this.customerId, form: this.form }).then(() => {
                this.$toast.success("Solicitud actualizada correctamente");
                this.$router.replace('/credit/customers-request');
            });
        },
        openImage(name, path) {
            this.image.name = name;
            this.image.src = `${this.url}/${path}`;
            this.image.dialog = true;
        },
        closeDialog() {
            this.image.dialog = false;
            this.img.src = null;
            this.img.name = null;
        }
    },


}
</script>

<style>
.wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  grid-auto-rows: minmax(100px, auto);
}
.customer-image {
    width: 100px;
}
.fiscal-data-sales {
    width: 100%;
}
.fiscal-data-data {
    width: 100%;
}

@media (min-width: 768px) {
    .fiscal-data {
        display: flex;
        gap: 1em;
        justify-content: space-between;
    }
    .fiscal-data-sales {
        width: 35%;
    }
    .fiscal-data-data {
        width: 65%;
    }
}
</style>