<template>
<div>
    <div class="d-flex flex-wrap" style="background-color: #000; color:#fff">
        <div class="text-left ml-3 mt-1" >{{title}}</div> 
            <v-btn v-if="!loading" icon @click="closeModal()" class="ml-auto">
                <v-icon size="20" color="#fff" >mdi-close-circle</v-icon>
            </v-btn> 
    </div>
    <v-simple-table dense>
        <template v-slot:default>
            <thead class="" v-if="!loading">
                <tr class="custom-table-bordered ">
                    <th class="text-center text-body-1 text-dark" style="background-color: #fff;" colspan="7"><b>{{isBilling?'Facturación':'Pedidos'}} actual</b></th>
                </tr>
                <tr class="custom-table-bordered ">
                    <td class="text-center table-heading text-body-2 ">Mes</td>
                    <td class="text-center table-heading text-body-2 ">Facturación</td>
                </tr>
            </thead>
            <tbody v-show="!loading" class="custom-table-bordered">
                <tr v-for="(item, index) in billing.FACACT?.FACACT" :key="index">
                    <td :style="index % 2 == 0 ? 'background-color: #fff;' : ''" class="table-heading small text-body-2">
                        {{dateFormat(item.MESFAC)  }}
                    </td>
                    <td :style="index % 2 == 0 ? 'background-color: #fff;' : ''" class="table-heading text-body-2">
                        ${{ number_format(item.MONFAC) }}
                    </td>
                </tr>
                <tr style="">
                    <td colspan="2" style=" " class="table-heading small text-body-2 billing-total">
                        <v-row>
                            <v-col cols="6" class="pa-3">
                                <b class="ml-n9">Total=</b>
                            </v-col>
                            <v-col cols="6">
                                <b class="ml-n9"> {{ number_format(billing.TOTALFACACT) }}</b>
                            </v-col>
                        </v-row>
                    </td>
                </tr>
            </tbody>
            <tbody v-if="loading">
                <tr>
                    <td colspan="7">
                        <v-progress-linear class="mt-3" indeterminate color="teal"></v-progress-linear> <span class="text--disabled"> Cargando información</span>
                    </td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>
    <v-simple-table dense v-if="!loading">
        <template v-slot:default>
            <thead class="">
                <tr class="custom-table-bordered ">
                    <th class="text-center text-body-1 text-dark" colspan="7"><b>{{isBilling?'Facturación':'Pedidos'}} Anterior</b></th>
                </tr>
                <tr class="custom-table-bordered ">
                    <td class="text-center table-heading text-body-2 ">Mes</td>
                    <td class="text-center table-heading text-body-2 ">Facturación</td>
                </tr>
            </thead>
            <tbody v-if="!loading" class="custom-table-bordered">
                <tr v-for="(item, index) in billing.FACANT?.FACANT" :key="index">
                    <td :style="index % 2 == 0 ? 'background-color: #fff;' : ''" class="table-heading small text-body-2">
                    {{ dateFormat(item.MESFAC)  }}
                    </td>
                    <td :style="index % 2 == 0 ? 'background-color: #fff;' : ''" class="table-heading text-body-2">
                        ${{ number_format(item.MONFAC) }}
                    </td>
                </tr>
                <tr>
                    <td colspan="2" style="" class="table-heading small text-body-2 billing-total">
                        <v-row>
                            <v-col cols="6" class="pa-3">
                                <b class="ml-n9">Total=</b>
                            </v-col>
                            <v-col cols="6">
                                <b class="ml-n9"> ${{ number_format(billing.TOTALFACANT) }} </b>
                            </v-col>
                        </v-row>
                    </td>
                </tr>
                <tr class="resume-foot">
                    <td colspan="2">
                        <div class="d-flex space-around justify-space-around flex-nowrap">
                            <b> Crecimiento = </b>
                            <b>{{ number_format(billing.PORCREC - 100,false) }}%</b>
                        </div> 
                    </td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>
    <div v-if="!loading" class="mt-3">
        <v-btn @click="closeModal()" dense class="success">
            Aceptar
        </v-btn>
    </div> 
</div>
</template>

<script> 

import MixinHelpers from '@/mixins/helpers';
import axios from "@/plugins/axios";

export default ({
 
    props: {
        title: { default: 'Detalle de crecimiento acumulado' }, 
        isBilling: { default: true}, 
        manager: { default: 0}, 
        seller: { default: 0},  
        isSeller: { default: false}, 
        period: { default: 0}, 
        type: { default: ''},
        modal:{ default: true},
        channel:{ default: ''},
    }, 
    mixins: [MixinHelpers],
    name: "CumulativeGrowth",
    data() {
        return {
            loading: false,
            billing: [],
            refreshData:true,
        }
    }, 
    watch:{
        modal(value){ 
            if (value) {
                this.cumulativeGrowthDetails();
            } 
        }, 
    },
    mounted(){
        this.cumulativeGrowthDetails();
    },
    methods: { 
        dateFormat(date){  
      const month = date.substring(4);
      const year = date.substring(0, 4); 
      return `${month}/${year}`; 
        },
        closeModal() { 
            this.$emit('closeModal', true)
        },
        cumulativeGrowthDetails(){
        this.loading=true;
        var month = this.period.month.toString()
        if (month < 10) {
            month = `0${month}`
        }
        const period = `${this.period.year}${month}`
        var params = this.isSeller ? 
        {
            seller:this.seller,
            is_seller:this.isSeller,
            is_billing:this.isBilling,
            type:this.type,
            period: period,
            channel:this.channel
        } :
        {
            manager:this.manager,
            is_seller:this.isSeller,
            is_billing:this.isBilling,
            type:this.type,
            period: period,
            channel:this.channel
        } 
        axios.post('/manager/home/cumulative-growth-details',params).then((res) => { 
            if (res.data.FACACT.FACACT?.MESFAC) {
                res.data.FACACT.FACACT = [res.data.FACACT.FACACT];
            }
            if (res.data.FACANT.FACANT?.MESFAC) {
                res.data.FACANT.FACANT = [res.data.FACANT.FACANT];
            }
            this.billing = res.data;
            this.loading = false;  
      })
      .catch((err) => { 
            console.log(err);
            this.loading = false;
          }) ;  
        } 
    },

})
</script>

<style scoped>
.resume-foot{
    background: #FFD75E 0% 0% no-repeat padding-box;
box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
border: 1px solid #DDE1E6;
}
.billing-total {
    background-color: #fff;-webkit-box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.75);
-moz-box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.75);
box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.75)
}
</style>
