export default {
  methods: {
    sendToHome(option, employeeNumber, componentName = null) {
      let component = '';
      switch(option){
        case 'RegionalManager':
          this.$setLocalStorageRegional(employeeNumber);
          component = componentName || "StructureRegionalManager";      
          break;
        case 'Manager': 
          this.$setLocalStorageManager(employeeNumber);
          component = componentName || "ManagerHome";
          break;
        case 'Seller':
          this.$setLocalStorageSeller(employeeNumber);
          component = componentName || "SellerResults";
          break;
        case 'Customer':
          this.$setLocalStorageSeller(employeeNumber);
          component = componentName || "CustomerCoverDetails";
          break;        
      }
      this.$router.push({ name: component });
    }
  }
};
