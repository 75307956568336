<template>
  <div class="container-fluid grey lighten-5 pt-4">
    <h2 class="h2">
      <v-icon style="color: #2c3e50; font-size: 32px; margin-top: -5px"
        >mdi-shield-account</v-icon
      >
      Solicitud Alta Cliente
    </h2>
    <h4>Editar Información</h4>
    <p></p>
    <div
      class="alert alert-danger"
      role="alert"
      v-if="hasError"
      style="font-weight: 500"
    >
      {{ msg }}
    </div>
    <p></p>
    <!-- Dialog loading -->
    <v-dialog v-model="loading" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Por favor espere...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- /. Dialog -->
    <v-stepper v-model="e1">
      <v-stepper-header>
        <v-stepper-step
          :complete="e1 > 1"
          step="1"
          append-icon="mdi-chevron-right"
        >
          Información General
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="e1 > 2" step="2">
          Información Fiscal y de Negocio
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="3"> Documentación Solicitada </v-stepper-step>
      </v-stepper-header>
      <form @submit.prevent="submit" enctype="multipart/form-data">
        <v-row class="">
          <v-col class="d-flex justify-end" style="text-align: center">
            <v-btn
              color="success"
              v-on:click="comments()"
              style="text-transform: none"
              class="text-h4 p-4 mt-4"
            >
              Seguimiento de Alta
              <v-icon dark right class="text-h5 ml-2"> mdi-comment-text-multiple </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="mt-7">
          <v-col md="4"></v-col>
          <v-col md="4" style="text-align: center">
            <v-icon color="warning" large> mdi-progress-clock </v-icon>
            <span v-if="status[0].custumer == true">
              Estado de Solicitud:
              <span style="font-weight: 700"> {{ status[0].status }}</span
              >.
            </span>
          </v-col>

        </v-row>
        <v-stepper-items>
          <v-stepper-content step="1">
            <div class="card">
              <div class="card-body text-lg-left">
                <div class="row">
                  <div class="input-group">
                    <input
                      class="form-control"
                      placeholder="id"
                      v-model="form.id"
                      type="hidden"
                    />
                  </div>
                  <div class="input-group">
                    <input
                      class="form-control"
                      placeholder="id"
                      v-model="form.user_id"
                      type="hidden"
                    />
                  </div>
                  <div class="col-md-4">
                    <label for="" class="mb-2 ml-2 label">Razón Social</label>
                    <div class="input-group">
                      <input
                        class="form-control"
                        placeholder="Razón Social"
                        v-model="form.company_name"
                        autofocus
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label for="" class="mb-2 ml-2 label">RFC</label>
                    <div class="input-group">
                      <input
                        class="form-control"
                        placeholder="RFC"
                        v-model="form.rfc"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label for="" class="mb-2 ml-2 label">Calle</label>
                    <div class="input-group">
                      <input
                        class="form-control"
                        placeholder="Calle"
                        v-model="form.a_street"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <label for="" class="mb-2 ml-2 label">Número</label>
                    <div class="input-group">
                      <input
                        class="form-control"
                        placeholder="Número"
                        v-model="form.a_number"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label for="" class="mb-2 ml-2 label">CP</label>
                    <div class="input-group">
                      <input
                        class="form-control"
                        placeholder="Buscar.."
                        v-model="form.a_postal_code"
                      />
                      <v-btn
                        icon
                        @click="getAddress()"
                        style="background-color: #ced4da"
                      >
                        <v-icon style="color: black">mdi-magnify</v-icon>
                      </v-btn>
                      <span color="red">{{ msg }}</span>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label for="" class="mb-2 ml-2 label">Estado</label>
                    <div class="input-group">
                      <input
                        class="form-control"
                        placeholder="Estado"
                        v-model="form.a_estate"
                      />
                    </div>
                  </div>
                </div>
                <p></p>
                <div class="row">
                  <div class="col-md-4">
                    <label for="" class="mb-2 ml-2 label"
                      >Delegación o Municipio</label
                    >
                    <div class="input-group">
                      <input
                        class="form-control"
                        placeholder="Delegación o Municipio"
                        v-model="form.a_town"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label for="" class="mb-2 ml-2 label">Colonia</label>
                    <!--<div class="input-group">
                      <input
                        class="form-control"
                        placeholder="Colonia"
                        v-model="form.a_suburb"
                      />
                    </div>-->
                    <div class="input-group">
                      <select
                        class="form-select"
                        aria-label="multiple select example"
                        placeholder="Seleccionar"
                        v-model="form.a_suburb"
                      >
                        <option
                          v-for="suburb in suburbs"
                          v-bind:key="suburb"
                          v-bind:value="suburb"
                        >
                          {{ suburb }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <label for="" class="mb-2 ml-2 label">Teléfono</label>
                    <div class="input-group">
                      <span class="input-group-text" id="basic-addon1"
                        ><i class="fa fa-phone"></i
                      ></span>
                      <input
                        class="form-control"
                        placeholder="Teléfono"
                        v-model="form.phone"
                      />
                    </div>
                  </div>
                </div>
                <p></p>
                <div class="row">
                  <div class="col-md-4">
                    <label for="" class="mb-2 ml-2 label"
                      >Nombre de Contacto</label
                    >
                    <div class="input-group">
                      <span class="input-group-text" id="basic-addon1"
                        ><i class="fa fa-user"></i
                      ></span>
                      <input
                        class="form-control"
                        placeholder="Nombre de Contacto"
                        v-model="form.contact_name"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label for="" class="mb-2 ml-2 label">Email</label>
                    <div class="input-group">
                      <span class="input-group-text" id="basic-addon1"
                        ><i class="fa fa-envelope"></i
                      ></span>
                      <input
                        class="form-control"
                        placeholder="Email"
                        v-model="form.email"
                      />
                    </div>
                  </div>
                  <p></p>
                  <hr />
                  <v-row no-gutters>
                    <v-col md="4" offset-md="8" style="text-align: right">
                      <v-btn
                        color="primary"
                        @click="e1 = 2"
                        style="text-transform: none"
                        :disabled="
                          !form.company_name ||
                          !form.rfc ||
                          !form.a_street ||
                          !form.a_number ||
                          !form.a_suburb ||
                          !form.a_town ||
                          !form.a_estate ||
                          !form.a_postal_code ||
                          !form.phone ||
                          !form.contact_name ||
                          !form.email
                        "
                      >
                        Siguiente
                        <v-icon dark right> mdi-chevron-right </v-icon></v-btn
                      >
                    </v-col>
                  </v-row>
                </div>
                <p></p>
              </div>
            </div>
          </v-stepper-content>
          <v-stepper-content step="2">
            <div class="card">
              <div class="card-body text-lg-left">
                <p></p>
                <p></p>
                <div class="row">
                  <div class="col-md-4">
                    <label for="" class="mb-2 ml-2">Regimén Fiscal</label>
                    <div class="input-group">
                      <input
                        class="form-control"
                        placeholder="Regimén Fiscal"
                        v-model="form.fiscal_regimen"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label for="" class="mb-2 ml-2">Método de Pago</label>
                    <div class="input-group">
                      <span class="input-group-text" id="basic-addon1"
                        ><i class="fa fa-credit-card"></i
                      ></span>
                      <input
                        class="form-control"
                        placeholder="Método de Pago"
                        v-model="form.payment_method"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label for="" class="mb-2 ml-2">Uso de CFDI</label>
                    <div class="input-group">
                      <select
                        class="form-select"
                        aria-label="multiple select example"
                        v-model="form.cfdi_use"
                      >
                        <option value="1">PDD</option>
                        <option value="2">PUE</option>
                      </select>
                    </div>
                  </div>
                </div>
                <p></p>
                <div class="row"></div>
                <p></p>
                <div class="row">
                  <div class="col-md-4">
                    <label for="" class="mb-2 ml-2"
                      >¿Solicitas línea de crédito?</label
                    >
                    <div class="input-group">
                      <select
                        class="form-select"
                        aria-label="multiple select example"
                        v-model="form.request_credit"
                      >
                        <option value="Si">Si</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label for="" class="mb-2 ml-2"
                      >¿Entregas en Dirección Fiscal?</label
                    >
                    <div class="input-group">
                      <select
                        class="form-select"
                        aria-label="multiple select example"
                        v-model="form.delivery_fiscal_addres"
                      >
                        <option value="Si">Si</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div>
                      <label for="" class="mb-2 ml-2"
                        >Nombre de Representante Legal</label
                      >
                      <div class="input-group">
                        <span class="input-group-text" id="basic-addon1"
                          ><i class="fa fa-user"></i
                        ></span>
                        <input
                          class="form-control"
                          placeholder="Nombre de Representante Legal"
                          v-model="form.fiscal_rep_name"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <p></p>
                <div class="row">
                  <div class="col-md-4">
                    <label for="" class="mb-2 ml-2"
                      >Línea de Crédito Solicitada</label
                    >
                    <div class="input-group">
                      <span class="input-group-text" id="basic-addon1"
                        ><i class="fa fa-dollar-sign"></i
                      ></span>
                      <input
                        class="form-control"
                        placeholder="Línea de Crédito Solicitada"
                        v-model="form.line_request_credit"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label for="" class="mb-2 ml-2"
                      >Ventas Mensuales del Negocio</label
                    >
                    <div class="input-group">
                      <span class="input-group-text" id="basic-addon1"
                        ><i class="fa fa-dollar-sign"></i
                      ></span>
                      <input
                        class="form-control"
                        placeholder="Ventas Mensuales del Negocio"
                        v-model="form.month_sales"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label for="" class="mb-2 ml-2"
                      >Tamaño del Negocio (m2)</label
                    >
                    <div class="input-group">
                      <input
                        class="form-control"
                        placeholder="Tamaño del Negocio (m2)"
                        v-model="form.business_size"
                      />
                    </div>
                  </div>
                </div>
                <p></p>
                <div class="row">
                  <div class="col-md-4">
                    <label for="" class="mb-2 ml-2">Giro del Negocio</label>
                    <div class="input-group">
                      <input
                        class="form-control"
                        placeholder="Giro del Negocio"
                        v-model="form.line_bussines"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label for="" class="mb-2 ml-2"
                      >¿Las instalaciones son propias?</label
                    >
                    <div class="input-group">
                      <select
                        class="form-select"
                        aria-label="multiple select example"
                        v-model="form.own_facilities"
                      >
                        <option value="Si">Si</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label for="" class="mb-2 ml-2">Via de Pago</label>
                    <div class="input-group">
                      <input
                        class="form-control"
                        placeholder="Via de Pago"
                        v-model="form.pay_service"
                      />
                    </div>
                  </div>
                  <p></p>
                  <hr />
                  <v-row no-gutters>
                    <v-col md="4" offset-md="8" style="text-align: right">
                      <v-btn
                        color="primary"
                        @click="e1 = 3"
                        style="text-transform: none"
                        :disabled="
                          !form.fiscal_regimen ||
                          !form.payment_method ||
                          !form.cfdi_use ||
                          !form.request_credit ||
                          !form.line_request_credit ||
                          !form.delivery_fiscal_addres ||
                          !form.fiscal_rep_name ||
                          !form.month_sales ||
                          !form.business_size ||
                          !form.line_bussines ||
                          !form.own_facilities ||
                          !form.pay_service
                        "
                      >
                        Siguiente
                        <v-icon dark right> mdi-chevron-right </v-icon></v-btn
                      >
                    </v-col>
                  </v-row>
                </div>
                <p></p>
              </div>
            </div>
          </v-stepper-content>
          <p></p>
          <v-stepper-content step="3">
            <div class="card">
              <div class="card-header" style="font-weight: 700">
                Documentación Solicitada
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-4">
                    <div style="text-align: left; font-weight: 700">
                      <label for="">Foto 1 (Fachada) <br> Tipo archivo: (.jpg .png) <v-icon>mdi-image</v-icon></label>
                      <div class="input-group">
                        <input
                          type="file"
                          class="form-control"
                          placeholder="Foto 1 (Fachada)"
                          @change="select_facade_photo"
                        />
                      </div>
                      <v-btn text color="primary" @click="openImage(form.facade_photo)" small>
                        <v-icon>mdi-eye</v-icon>
                        {{ extracName(form.facade_photo) }}
                      </v-btn>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div style="text-align: left; font-weight: 700">
                      <label for="">Foto 2 (Interior) <br> Tipo archivo: (.jpg .png) <v-icon>mdi-image</v-icon></label>
                      <div class="input-group">
                        <input
                          type="file"
                          class="form-control"
                          placeholder="Foto 2 (Interior)"
                          @change="select_indoor_photo"
                        />
                      </div>
                      <v-btn text color="primary" @click="openImage(form.indoor_photo)" small>
                        <v-icon>mdi-eye</v-icon>
                        {{ extracName(form.indoor_photo) }}
                      </v-btn>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div style="text-align: left; font-weight: 700">
                      <label for="">Foto 3 (Mostrador/Bodega) <br> Tipo archivo: (.jpg .png) <v-icon>mdi-image</v-icon></label>
                      <div class="input-group">
                        <input
                          type="file"
                          class="form-control"
                          placeholder="Foto 3 (Mostrador/Bodega)"
                          @change="select_bar_photo"
                        />
                      </div>
                      <v-btn text color="primary" @click="openImage(form.bar_photo)" small>
                        <v-icon>mdi-eye</v-icon>
                        {{ extracName(form.bar_photo) }}
                      </v-btn>
                    </div>
                  </div>
                </div>
                <p></p>
                <div class="row">
                  <div class="col-md-4">
                    <div style="text-align: left; font-weight: 700">
                      <label for=""
                        >Constancia de Situación Fiscal no mayor a dos
                        meses <br> Extensión archivo: (.pdf) <v-icon>mdi-file-pdf-box</v-icon></label
                      >
                      <div class="input-group">
                        <input
                          type="file"
                          class="form-control"
                          placeholder="Constancia de Situación Fiscal no mayor a dos meses"
                          @change="select_evid_tax_registration"
                        />
                      </div>
                      <v-btn text color="primary" @click="openPDF(form.evid_tax_registration)" small>
                        <v-icon>mdi-eye</v-icon>
                        {{ extracName(form.evid_tax_registration) }}
                      </v-btn>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div style="text-align: left; font-weight: 700">
                      <label for=""
                        >Identificación oficial de persona Fisica o
                        representante legal (ambos lados) <br> Extensión archivo: (.pdf) <v-icon>mdi-file-pdf-box</v-icon></label
                      >
                      <div class="input-group">
                        <input
                          type="file"
                          class="form-control"
                          placeholder="Identificación oficial de persona Fisica o representante legal (ambos lados)"
                          @change="select_official_id"
                        />
                      </div>
                      <v-btn text color="primary" @click="openPDF(form.official_id)" small>
                        <v-icon>mdi-eye</v-icon>
                        {{ extracName(form.official_id) }}
                      </v-btn>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div style="text-align: left; font-weight: 700">
                      <label for=""
                        >Comprobante de domicilio dirección fiscal no mayor a
                        dos meses <br> Extensión archivo: (.pdf) <v-icon>mdi-file-pdf-box</v-icon></label
                      >
                      <div class="input-group">
                        <input
                          type="file"
                          class="form-control"
                          placeholder="Comprobante de domicilio dirección fiscal no mayor a dos meses"
                          @change="select_proof_recidency"
                        />
                      </div>
                      <v-btn text color="primary" @click="openPDF(form.proof_recidency)" small>
                        <v-icon>mdi-eye</v-icon>
                        {{ extracName(form.proof_recidency) }}
                      </v-btn>
                    </div>
                  </div>
                </div>
                <p></p>
                <div class="row">
                  <div class="col-md-4">
                    <div style="text-align: left; font-weight: 700">
                      <label for=""
                        >En caso de que las entregas sean en domicilio diferente
                        al fiscal, comprobante de domicilio no mayor a dos
                        meses <br> Extensión archivo: (.pdf) <v-icon>mdi-file-pdf-box</v-icon></label
                      >
                      <div class="input-group">
                        <input
                          type="file"
                          class="form-control"
                          placeholder="En caso de que las entregas sean en domicilio diferente al fiscal, comprobante de domicilio no mayor a dos meses"
                          @change="select_proof_recidency_distinct"
                        />
                      </div>
                      <v-btn text color="primary" @click="openPDF(form.proof_recidency_distinct)" small>
                        <v-icon>mdi-eye</v-icon>
                        {{ extracName(form.proof_recidency_distinct) }}
                      </v-btn>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div style="text-align: left; font-weight: 700">
                      <label for="">Aviso de Protección de datos <br> Extensión archivo: (.pdf) <v-icon>mdi-file-pdf-box</v-icon></label>
                      <div class="input-group">
                        <input
                          type="file"
                          class="form-control"
                          placeholder="Aviso de Protección de datos"
                          @change="select_regulation_data_protect"
                        />
                      </div>
                      <v-btn text color="primary" @click="openPDF(form.regulation_data_protect)" small>
                        <v-icon>mdi-eye</v-icon>
                        {{ extracName(form.regulation_data_protect) }}
                      </v-btn>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div style="text-align: left; font-weight: 700">
                      <label for="">Formato de Buró de Crédito <br> Extensión archivo: (.pdf) <v-icon>mdi-file-pdf-box</v-icon></label>
                      <div class="input-group">
                        <input
                          type="file"
                          class="form-control"
                          placeholder="Formato de Buró de Crédito"
                          @change="select_credit_bureau"
                        />
                      </div>
                      <v-btn text color="primary" @click="openPDF(form.credit_bureau)" small>
                        <v-icon>mdi-eye</v-icon>
                        {{ extracName(form.credit_bureau) }}
                      </v-btn>
                    </div>
                  </div>
                </div>
                <p></p>
                <div class="row">
                  <div class="col-md-4">
                    <div style="text-align: left; font-weight: 700">
                      <label for=""
                        >En caso de que las entregas sean en un domicilio
                        distinto al Fiscal, formato de Alta de Apertura ante
                        SHCP <br> Extensión archivo: (.pdf) <v-icon>mdi-file-pdf-box</v-icon></label
                      >
                      <div class="input-group">
                        <input
                          type="file"
                          class="form-control"
                          placeholder="En caso de que las entregas sean en un domicilio distinto al Fiscal, formato de Alta de Apertura ante SHCP"
                          @change="select_shcp_opening"
                        />
                      </div>
                      <v-btn text color="primary" @click="openPDF(form.shcp_opening)" small>
                        <v-icon>mdi-eye</v-icon>
                        {{ extracName(form.shcp_opening) }}
                      </v-btn>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div style="text-align: left; font-weight: 700">
                      <label for="">Acta constitutiva <br> Extensión archivo: (.pdf) <v-icon>mdi-file-pdf-box</v-icon></label>
                      <div class="input-group">
                        <input
                          type="file"
                          class="form-control"
                          placeholder="Acta constitutiva"
                          @change="select_charter"
                        />
                      </div>
                      <v-btn text color="primary" @click="openPDF(form.charter)" small>
                        <v-icon>mdi-eye</v-icon>
                        {{ extracName(form.charter) }}
                      </v-btn>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div style="text-align: left; font-weight: 700">
                      <label for=""
                        >En caso de que exista un poder notarial para ser el
                        representante legal de la persona moral o Física <br> Extensión archivo: (.pdf) <v-icon>mdi-file-pdf-box</v-icon></label
                      >
                      <div class="input-group">
                        <input
                          type="file"
                          class="form-control"
                          placeholder="En caso de que exista un poder notarial para ser el representante legal de la persona moral o Física"
                          @change="select_power_attorney"
                        />
                      </div>
                      <v-btn text color="primary" @click="openPDF(form.power_attorney)" small>
                        <v-icon>mdi-eye</v-icon>
                        {{ extracName(form.power_attorney) }}
                      </v-btn>
                    </div>
                  </div>
                </div>
                <p></p>
                <div class="row">
                  <div class="col-md-4">
                    <div style="text-align: left; font-weight: 700">
                      <label for="">Pagaré <br> Extensión archivo: (.pdf) <v-icon>mdi-file-pdf-box</v-icon></label>
                      <div class="input-group">
                        <input
                          type="file"
                          class="form-control"
                          placeholder="Pagaré"
                          @change="select_promissory_note"
                        />
                      </div>
                      <v-btn text color="primary" @click="openPDF(form.promissory_note)" small>
                        <v-icon>mdi-eye</v-icon>
                        {{ extracName(form.promissory_note) }}
                      </v-btn>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div style="text-align: left; font-weight: 700">
                      <label for="">Comprobante de domicilio de Aval <br> Extensión archivo: (.pdf) <v-icon>mdi-file-pdf-box</v-icon></label>
                      <div class="input-group">
                        <input
                          type="file"
                          class="form-control"
                          placeholder="Comprobante de domicilio de Aval"
                          @change="select_proof_recidency_endors"
                        />
                      </div>
                      <v-btn text color="primary" @click="openPDF(form.proof_recidency_endors)" small>
                        <v-icon>mdi-eye</v-icon>
                        {{ extracName(form.proof_recidency_endors) }}
                      </v-btn>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div style="text-align: left; font-weight: 700">
                      <label for=""
                        >Identificación oficial de Aval (ambos lados) <br> Extensión archivo: (.pdf) <v-icon>mdi-file-pdf-box</v-icon></label
                      >
                      <div class="input-group">
                        <input
                          type="file"
                          class="form-control"
                          placeholder="Identificación oficial de Aval (ambos lados)"
                          @change="select_official_id_endors"
                        />
                      </div>
                      <v-btn text color="primary" @click="openPDF(form.official_id_endors)" small>
                        <v-icon>mdi-eye</v-icon>
                        {{ extracName(form.official_id_endors) }}
                      </v-btn>
                    </div>
                  </div>
                </div>
                <p></p>
                <div class="row">
                  <div class="col-md-4">
                    <div style="text-align: left; font-weight: 700">
                      <label for=""
                        >Referencias Bancarias (carpatula de estado de
                        cuenta) <br> Extensión archivo: (.pdf) <v-icon>mdi-file-pdf-box</v-icon></label
                      >
                      <div class="input-group">
                        <input
                          type="file"
                          class="form-control"
                          placeholder="Referencias Bancarias (carpatula de estado de cuenta)"
                          @change="select_bank_reference"
                        />
                      </div>
                      <v-btn text color="primary" @click="openPDF(form.bank_reference)" small>
                        <v-icon>mdi-eye</v-icon>
                        {{ extracName(form.bank_reference) }}
                      </v-btn>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div style="text-align: left; font-weight: 700">
                      <label for="">Políticas de Crédito Nacionales <br> Extensión archivo: (.pdf) <v-icon>mdi-file-pdf-box</v-icon></label>
                      <div class="input-group">
                        <input
                          type="file"
                          class="form-control"
                          placeholder="Políticas de Crédito Nacionales"
                          @change="select_credit_policies"
                        />
                      </div>
                      <v-btn text color="primary" @click="openPDF(form.credit_policies)" small>
                        <v-icon>mdi-eye</v-icon>
                        {{ extracName(form.credit_policies) }}
                      </v-btn>
                    </div>
                  </div>
                  <div class="col-md-4"></div>
                  <p></p>
                  <hr />
                  <v-row no-gutters>
                    <v-col md="4" offset-md="8" style="text-align: right">
                      <v-btn
                        color="success"
                        @click="e1 = 2"
                        style="text-transform: none"
                        type="submit"
                      >
                        Enviar
                        <v-icon dark right>
                          mdi-check-circle-outline
                        </v-icon></v-btn
                      >
                    </v-col>
                  </v-row>
                </div>
                <p></p>
              </div>
            </div>
          </v-stepper-content>
          <p></p>
        </v-stepper-items>
      </form>
    </v-stepper>
    <p></p>
    <div class="text-center ma-2">
      <v-snackbar v-model="snackbar" :timeout="timeout">
        {{ msg }}
        <template v-slot:action="{ attrs }">
          <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
    <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        v-model="imageDialog"
      >
          <v-card>
            <v-toolbar
              color="primary"
              dark
            >Imagen guardada</v-toolbar>
            <v-card-title class="text-h5" v-if="imageSelected">
              {{ extracName(imageSelected) }}
            </v-card-title>
            <v-card-text>
              <v-img :src="imageSelected"></v-img>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                text
                @click="imageDialog = false"
              >Cerrar</v-btn>
            </v-card-actions>
          </v-card>
      </v-dialog>
  </div>
</template>

<script>
import axios from "@/plugins/axios";
import { mapGetters } from "vuex";
import generalMixin from "@/mixins/general";

export default {
  name: "ClientRequest",
  components: {},

  mixins: [generalMixin],

  data() {
    return {
      data: null,
      loading: false,
      e1: 1,
      snackbar: false,
      msg: null,
      hasError: false,
      timeout: 8000,
      suburbs: null,
      status: null,
      imageDialog: false,
      imageSelected: null,
      form: {
        id: "",
        user_id: "",
        company_name: "",
        rfc: "",
        a_street: "",
        a_number: "",
        a_suburb: "",
        a_town: "",
        a_estate: "",
        a_postal_code: "",
        phone: "",
        contact_name: "",
        email: "",
        agent_number: "",
        sales_office: "",
        sales_area: "",
        branch_code: "",
        supply_center: "",
        fiscal_regimen: "",
        payment_method: "",
        cfdi_use: "",
        transport_area: "",
        iva: "",
        request_credit: "",
        delivery_fiscal_addres: "",
        fiscal_rep_name: "",
        line_request_credit: "",
        month_sales: "",
        business_size: "",
        line_bussines: "",
        own_facilities: "",
        pay_service: "",
        line_credit_suggest: "",
        facade_photo: [],
        indoor_photo: [],
        bar_photo: [],
        evid_tax_registration: [],
        official_id: [],
        proof_recidency: [],
        proof_recidency_distinct: [],
        regulation_data_protect: [],
        credit_bureau: [],
        shcp_opening: [],
        charter: [],
        power_attorney: [],
        promissory_note: [],
        proof_recidency_endors: [],
        official_id_endors: [],
        bank_reference: [],
        credit_policies: [],
      },
      tax_regimes: [],
      tax_regimes_all: [],
      way_pays:[],
      cfid_uses:[],
      payment_methods:[],
    };
  },
  async mounted() {
    let self = this;
    self.CustumerRequestDetailUpdate();
    self.getCustumerStatus();
    this.getGeneralData();
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
  },
  methods: {
    select_facade_photo(event) {
      this.form.facade_photo = event.target.files[0];
    },
    select_indoor_photo(event) {
      this.form.indoor_photo = event.target.files[0];
    },
    select_bar_photo(event) {
      this.form.bar_photo = event.target.files[0];
    },
    select_evid_tax_registration(event) {
      this.form.evid_tax_registration = event.target.files[0];
    },
    select_official_id(event) {
      this.form.official_id = event.target.files[0];
    },
    select_proof_recidency(event) {
      this.form.proof_recidency = event.target.files[0];
    },
    select_proof_recidency_distinct(event) {
      this.form.proof_recidency_distinct = event.target.files[0];
    },
    select_regulation_data_protect(event) {
      this.form.regulation_data_protect = event.target.files[0];
    },
    select_credit_bureau(event) {
      this.form.credit_bureau = event.target.files[0];
    },
    select_shcp_opening(event) {
      this.form.shcp_opening = event.target.files[0];
    },
    select_charter(event) {
      this.form.charter = event.target.files[0];
    },
    select_power_attorney(event) {
      this.form.power_attorney = event.target.files[0];
    },
    select_promissory_note(event) {
      this.form.promissory_note = event.target.files[0];
    },
    select_proof_recidency_endors(event) {
      this.form.proof_recidency_endors = event.target.files[0];
    },
    select_official_id_endors(event) {
      this.form.official_id_endors = event.target.files[0];
    },
    select_bank_reference(event) {
      this.form.bank_reference = event.target.files[0];
    },
    select_credit_policies(event) {
      this.form.credit_policies = event.target.files[0];
    },
    CustumerRequestDetailUpdate() {
      axios
        .post("CustumerRequestDetailUpdate", {
          user_id: localStorage.getItem("id"),
        })
        .then((response) => {
          this.data = response.data;
          console.log(response.data);
          this.form = response.data[0];
          let a_postal_code = response.data[0].a_postal_code;
          //this.suburbs = response.data[0];
          this.getSelectedAddress(a_postal_code);

        })
        .catch((e) => {
          console.log(e);
        });
    },
    submit() {
      this.loading = true;
      this.e1 = 3;
      let form = new FormData();
      for (let key in this.form) {
        form.append(key, this.form[key]);
      }
      axios
        .post("updateCustomerDetail", form)
        .then((response) => {
          this.notify('Datos de solicitud actualizados');

          this.$router.push({
            name: "CustomerStatus",
            query: {
              data: response.data[0].id,
            },
          });
        })
        .catch((error) => {
          if (error.response.data.errors) {
            this.msg = error.response.data.errors;
            this.hasError = true;
            this.snackbar = true;
            setTimeout(() => {
              this.hasError = false;
            }, 8000);
          } else {
            alert(error);
          }
        })
        .finally(() => (this.loading = false));
    },
    getCustumerStatus() {
      axios
        .get("getCustumerStatus")
        .then((response) => {
          //this.data = response.data;
          this.status = response.data;

          if (response.data[0].custumer == true) {
            this.CustumerRequestDetailUpdate();
          }

          console.log(response.data[0].custumer);
          console.log(response.data[0].status);
        })
        .catch((error) => {
          if (error.response.data.errors) {
            this.msg = error.response.data.errors;
            this.hasError = true;
            this.snackbar = true;
            setTimeout(() => {
              this.hasError = false;
            }, 8000);
          } else {
            alert(error);
          }
        })
        .finally(() => (this.loading = false));
    },
    getAddress() {
      let form = new FormData();
      form.append("postal_code", this.form.a_postal_code);
      axios
        .post("searchPostalCode", form)
        .then((response) => {
          this.data = response.data;
          this.$set(this.form, "a_estate", response.data.a_estate);
          this.$set(this.form, "a_town", response.data.a_town);
          this.suburbs = response.data.a_suburb;
          //this.msg = response.data.mesage

          console.log(response.data);
          // console.log(response.data[0].mesage);
        })
        .catch((error) => {
          if (error.response.data.errors) {
            this.msg = error.response.data.errors;
            this.hasError = true;
            this.snackbar = true;
            setTimeout(() => {
              this.hasError = false;
            }, 8000);
          } else {
            alert(error);
          }
        })
        .finally(() => (this.loading = false));
    },
    getSelectedAddress(a_postal_code) {
      axios
        .post("searchPostalCode", { postal_code: a_postal_code })
        .then((response) => {
          //this.data = response.data;
          this.suburbs = response.data.a_suburb;

          console.log(response.data);
        })
        .catch((error) => {
          if (error.response.data.errors) {
            this.msg = error.response.data.errors;
            this.hasError = true;
            this.snackbar = true;
            setTimeout(() => {
              this.hasError = false;
            }, 8000);
          } else {
            alert(error);
          }
        })
        .finally(() => (this.loading = false));
    },
    comments() {
      let form = new FormData();
      console.log(form.id);
      this.$router.push({
        name: "CustomerStatus",
        query: {
          data: this.form.custumer_id,
        },
      });
    },
    openPDF(path) {
      console.log(path)
      window.open(this.$baseUrl + '/' + path);
    },
    extracName(path) {
      if(typeof(path) == 'string') {
        const arrayPath = path.split('/');
  
        let name = arrayPath[arrayPath.length - 1];

        if(name.length > 40) {
          return name.substring(0, 40) + '...';
        }
        return name;
      }
      return '';
    },
    openImage(path) {
      this.imageSelected = this.$baseUrl + '/' + path;
      this.imageDialog = true;
    },
    getGeneralData() {
      axios
        .get("request-data")
        .then((response) => {
          console.log(response.data);
          this.tax_regimes_all = response.data.tax_regimes;
          this.way_pays = response.data.way_pays;
          this.payment_methods = response.data.payment_methods;
          this.cfid_uses = response.data.cfid_uses;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style scoped>
.label {
  font-weight: 700;
}
</style>
