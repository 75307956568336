<template>
    <v-row>
      <v-col cols="12" md="3">
        <CustomerGuestInformation></CustomerGuestInformation>
      </v-col>
    </v-row>
  
  </template>
  
  <script>
  //import slide1 from "@/assets/img/slide_01@2x.png";
  import slide2 from "@/assets/img/slide_02@2x.png";
  import slide3 from "@/assets/img/banner_cd@2x.png";
  import CustomerGuestInformation from "@/views/auth/CustomerGuestInformation.vue";
  
  export default {
  name: "GuestView",
  
  components: { CustomerGuestInformation },
  
  data() {
    return {
      items: [
        /*{
          src: slide1,
          href: '#'
        },*/
        {
          src: slide2,
          href:<a href="https://iusa.com.mx/catalogo_construccion/" target="_blank" />
        },
        {
          src: slide3,
          href: '/#/client-request'
        },
      ],
    };
  },
  
  methods: {
    goTo(name) {
      console.log(name);
    }
  }
  };
  </script>