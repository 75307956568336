<template>
    <v-container fluid class="grey lighten-5">
        <v-card class="mx-auto rounded-lg elevation-4 ma-3 mb-7" outlined
              :style="'border: 4px solid green; background-color:transparent;'">
              <v-list-item-content>
                  <div class="text-quoter-module" style="color:green">
                      Editar Cotizaciones
                  </div>
              </v-list-item-content>
          </v-card>
      <v-card>
        <customer-info
          :catchTotal="total"
          :warnLock="catchRequestLock"
        ></customer-info>
        <p></p>
        <div class="col-12" style="text-align: left">
          <span class="text-ped">Captura de Cotizaciones </span>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-md-4">
              <div class="input-group">
                <v-radio-group v-model="quoter_type"  row class="text-ped">
                  <v-radio color="success" label="Cotización Cliente" v-model="qt_cliente"></v-radio>
                  <v-radio color="success" label="Cotización Cliente/Cliente" v-model="qt_cliente_2" ></v-radio>
                </v-radio-group>
                <v-text-field
                  class="text-ped"
                  v-model="order"
                  label="Folio Cotizaciones"
                  required
                  :disabled="isFolioDisabled"
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
                >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="start_date"
                    label="Fecha Vigencia"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    required
                  ></v-text-field>
                </template>
                    <v-date-picker
                    v-model="start_date"
                    :min-date='new Date()'
                    @input="closeDateMenu1"
                    ></v-date-picker>
                </v-menu>
                <v-spacer></v-spacer>
                <v-text-field
                  class="text-ped"
                  v-model="nu_tender"
                  label="Num. Licitación:"
                  required
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-text-field
                  v-if="quoter_type == 2"
                  class="text-ped"
                  v-model="customer_customer"
                  label="Cliente:"
                  required
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-text-field
                  v-if="quoter_type == 2"
                  class="text-ped"
                  v-model="customer_seller"
                  label="Vendedor:"
                  required
                ></v-text-field>
              </div>
              <v-autocomplete 
                v-if="user.VTWEG == 'DT'"
                 v-model="orderType" 
                 :items="orderItemsList" 
                 :item-text="getItemTextOrderType" 
                 item-value="type"
                 return-object dense
                 label="Tipo de Pedido"
                 rounded
                 color="#212529"
                 class=".v-select__selection"
                 @change="updateRegion"
                 style="background-color: red !important; font-size: 18px !important; font-weight: bold !important;"
                 >
              </v-autocomplete>
              <v-autocomplete 
                 v-if="user.VTWEG == 'PR'"
                 v-model="orderType" 
                 :items="orderItemsListPR" 
                 :item-text="getItemTextOrderType" 
                 item-value="type"
                 return-object dense
                 label="Tipo de Pedido"
                 rounded
                 color="#212529"
                 class=".v-select__selection"
                 style="background-color: red !important; font-size: 18px !important; font-weight: bold !important;"
                 >
              </v-autocomplete>
                <v-spacer></v-spacer>
            </div>
            <div class="col-md-6">
              <customer-destiny :warnDestiny="cacthDestiny"></customer-destiny>
            </div>
            <div class="col-md-2"></div>
            <div class="col-md-3">
              <h2
                class="text-ped"
                style="text-align: center"
                @click="dialogCatalogo = true"
              >
                Mas de 4,200 productos
              </h2>
            </div>
            <div class="col-md-3">
              
            </div>
          </div>
        </div>
        <div class="col-12" style="text-align: end; font-size: 12px">
          <a @click="dialogPromociones = true">
            <v-icon class="text-ped-links">mdi-bookmark-check</v-icon>Promociones
          </a>
          <a @click="dialogLiquidaciones = true">
            <v-icon class="text-ped-links">mdi-bookmark-check</v-icon
            >Liquidaciones
          </a>
          <!--<a>
            <v-icon class="text-ped-links">mdi-bookmark-check</v-icon>Instrucciones de captura
          </a>
          <a>
            <v-icon class="text-ped-links">mdi-bookmark-plus</v-icon>Información del pedido
          </a>-->
        </div>
        <div class="card-body" ref="errorMaterial">
          <div class="row">
            <div class="col-md-12" v-if="hasError">
              <v-alert text dense outlined type="error">
                {{ hasError }}
              </v-alert>
            </div>
            <div class="col-md-8">
              <material-into-cart
                :warnMaterial="catchMaterialItem"
                :warnError="catchErrorMaterial"
                :warnDialogLoading="catchDialogLoading"
                :newMaterial="sendMaterialNew"
                :capturedDocType="orderType"
              ></material-into-cart>
            </div>
            <div class="col-md-4"></div>
          </div>
        </div>
        <br />
        <list-cart
          :capturedMaterialItem="materialItem"
          :warnTotal="catchTotal"
          :warnError="catchErrorMaterial"
          :warnDialogLoading="catchDialogLoading"
          :warnCart="catchCart"
          :capturedDocType="orderType"
          :capturedIdOrder="order_id"
          :captureQuoter="quoter_type"
          :captureRadioPercent="catchRadioPercent"
          :captureRadioDataBase="radio_percent"
        ></list-cart>
        <v-row v-if="cart.length != 0" class="justify-end">
           <v-col md="2" class="align-self-end">
            <label
              >Subtotal <strong>$ {{ formatPrice(subtotalPrint)}}</strong></label
            ><br />
            <label
              >IVA 16% <strong>$ {{ formatPrice(iva) }}</strong></label
            ><br />
            <label
              >Total
              <v-card-title style="color: #000; font-weight: 800"
                >$ {{ formatPrice(total) }}
              </v-card-title></label
            ><br />
            <v-btn
              style="background: #2fd410 !important; color: #fff !important"
              rounded
              block
              :disabled="cart.length == 0 || submitted"
              @click="saveQuoterCart"
            >
              <v-icon style="color: #fff !important">mdi-content-save-check-outline</v-icon>Guardar
              
            </v-btn>
          </v-col>
        </v-row>
        <br />
        <div class="col-12" style="text-align: initial; font-size: 12px">
        <!-- <a>
            <v-icon class="text-ped-links">mdi-bookmark-check</v-icon>Cargar
            archivo
          </a>-->
          <!--<a>
            <v-icon class="text-ped-links">mdi-bookmark-check</v-icon>Convertir a pedido apoyo a
            marca
          </a>
          <a>
            <v-icon class="text-ped-links">mdi-book-open-variant</v-icon>Convertir a pedido
            especial
          </a>
          <a>
            <v-icon class="text-ped-links">mdi-bookmark-check</v-icon>Convertir a Pedido de
            Tienda Nueva
          </a>-->
          <a>
            <v-icon class="text-ped-links">mdi-bookmark-plus</v-icon>Datos
            Generales
          </a>
          <a
          >
            <v-icon class="text-ped-links">mdi-bookmark-check</v-icon>Generar
            Cotizacion
          </a>
        </div>
        <loading-bar :capturedDialogLoading="dialogLoading"></loading-bar>
      </v-card>
      <br />
      <v-row>
        <v-col sm="12">
          <!--<div class="col-12" style="text-align: left">
            <span class="text-ped">La mejor sugerencia</span> / en base a tus
            compras.
          </div>
          <v-slide-group active-class="success" show-arrows>
            <v-slide-item
              v-for="(item, i) in items_product"
              :key="i"
              style="margin: 10px !important"
            >
              <v-card
                class="mx-auto pedidos-card-sugerencias"
                max-width="280"
                style="text-align: left"
              >
                <v-card-text>
                  <strong>{{ item.name_product }}</strong>
                </v-card-text>
                <v-card-subtitle> SKU: {{ item.sku }} </v-card-subtitle>
                <v-img :src="item.src" width="180px"></v-img>
                <v-card-subtitle style="text-align: center">
                  Empaque de 1 pza
                </v-card-subtitle>
                <v-card-title>
                  <span style="text-align: center"> A {{ item.price }}</span>
                </v-card-title>
                <v-card-text>
                  <v-btn
                    color="primary"
                    rounded
                    block
                    style="background: #079FFF color: #fff !important"
                  >
                    <v-icon style="color: #fff !important">mdi-plus</v-icon
                    >Agregar al Carrito
                  </v-btn>
                </v-card-text>
                <v-card-text>
                  <v-btn
                    style="background: #2fd410 !important; color: #fff !important"
                    rounded
                    block
                  >
                    <v-icon style="color: #fff !important">mdi-check</v-icon
                    >Comprar Ahora
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-slide-item>
          </v-slide-group>-->
        </v-col>
      </v-row>
      <v-dialog v-model="dialogBackOrder" persistent scrollable>
        <v-card>
          <v-card-title>Confirmar BackOrder</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
          <p><b>Seleccionar los backorders confirmados</b></p>
          <v-row v-for="(item, i) in cart" :key="i">
            <v-checkbox v-model="item.reminder_check">
              <template v-slot:label>
                <div>
                  {{ item.codigo_material }}-{{ item.nombre_material }}
                  <strong>Backorder: {{ item.recordatorios }}</strong>
                </div>
              </template>
            </v-checkbox>
          </v-row>
        </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <!--<button class="btn btn-danger" @click="dialogBackOrder = false">
               Cancelar
             </button>
             <button class="btn btn-success" @click="orderGenerate">
               Aceptar junto con Pedido
             </button>-->
             
             <v-btn
              color="error"
              @click="dialogBackOrder = false"
            >
              Cancelar 
            </v-btn>
           
            <v-btn
              color="success"
              :disabled="submitted"
            >
              Enviar Pedido
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-row justify="center">
        <v-dialog
          v-model="dialogCatalogo"
          hide-overlay
          width="800"
          transition="dialog-bottom-transition"
          justify="center"
        >
          <v-card justify="center">
            <div class="text-ped" style="text-align: center">CATALOGO</div>
            <div class="col-12 row">
              <v-text-field
                v-model="material_code_search"
                label="Buscar nombre / SKU"
                append-icon="mdi-magnify"
                style="padding: 15px"
              ></v-text-field>
              <v-data-table
                :headers="headersCatalogo"
                :items="catalogo_list"
                :items-per-page="5"
                :search="material_code_search"
                class="elevation-1"
              >
                <template v-slot:[`item.MATNR`]="{ item }">
                  {{ parseInt(item.MATNR) }}
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-btn
                    color="primary"
                    rounded
                    block
                    @click="newMaterialSearch(parseInt(item.MATNR))"
                    style="background: #079FFF color: #fff !important"
                  >
                    <v-icon style="color: #fff !important">mdi-cart</v-icon>Add
                  </v-btn>
                </template>
              </v-data-table>
            </div>
            <v-card-actions class="justify-end">
              <v-btn text @click="dialogCatalogo = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <!-- /. Dialog -->
      <!-- Dialog Pedidos -->
      <v-row justify="center">
        <v-dialog
          v-model="dialogPedidos"
          hide-overlay
          width="800"
          transition="dialog-bottom-transition"
          justify="center"
        >
          <v-card justify="center">
            <div class="text-ped" style="text-align: center">Ordenes de Compra Abiertas</div>
            <!--<div class="text-ped" style="text-align: initial">
              <v-btn
                style="margin-left: 10px"
                dark
                rounded
                color="primary"
                elevation="2"
                small
                @click="generateNewOrder()"
              >
                <v-icon style="color: #fff !important">mdi-plus</v-icon> Crear
                Nuevo Pedido
              </v-btn>
            </div>-->
  
            <div class="col-12 row">
              <v-data-table
                :headers="headersPedido"
                :items="orders_list"
                :items-per-page="5"
                class="elevation-1"
              >
                <template v-slot:[`item.name`]="{ item }">
                  <!--<a @click="loadOrder(item)">{{ item.name }}</a>-->
                  <p>{{ item.name }}</p>
                </template>
                <template v-slot:[`item.total`]="{ item }">
                  {{ formatPrice(item.total) }}
                </template>
                <template v-slot:[`item.type`]="{ item }">
                  <p v-if="item.type == 'CL'">Cliente</p>
                  <p v-if="item.type == 'VE'">Vendedor</p>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-btn
                    style="margin-left: 10px"
                    dark
                    rounded
                    color="red darken-2"
                    elevation="2"
                    small
                    @click="deleteOrder(item)"
                  >
                    <v-icon style="color: #fff !important">mdi-delete</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </div>
            <v-card-actions class="justify-end">
              <v-btn text @click="dialogPedidos = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <!-- /. Dialog -->
    </v-container>
  </template>
  
  <script>
  import axios from "@/plugins/axios";
  import LoadingBar from "./componets/LoadingBar.vue";
  import CustomerInfo from "./componets/CustomerInfo.vue";
  import MaterialIntoCart from "./componets/MaterialIntoCart.vue";
  import ListCart from "./componets/ListCart.vue";
  import CustomerDestiny from "./componets/CustomerDestiny.vue";
  import { mapGetters } from "vuex";
  import moment from 'moment';

  export default {
    name: "ShoppingCart",
    computed: {
      ...mapGetters({
        user: "auth/user",
      }),
    },
    components: {
      CustomerInfo,
      MaterialIntoCart,
      ListCart,
      LoadingBar,
      CustomerDestiny,
    },
    data: () => ({
      material_code_search: "",
      submitted: false,
      hasError: null,
      materialItem: {},
      subtotal: 0,
      subtotalPrint:0,
      iva: 0,
      total: 0,
      total2: "",
      dialogLoading: false,
      customerDestiny: {},
      order: "",
      cart: [],
      requestLock: 0,
      dialogBackOrder: false,
      order_response: "",
      dialogPromociones: false,
      dialogLiquidaciones: false,
      dialogCatalogo: false,
      sendMaterialNew: "",
      dialogPedidos: false,
      order_id: null,
      order_q:null,
      menu1:false,
      quoter_type: null,
      radio_percent: null,
      qt_cliente:"1",
      qt_cliente_2:"2",
      nu_tender:null,
      isFolioDisabled:true,
      customer_customer: null,
      customer_seller: null,
      orderType: null,
      doctype: null,
      start_date: new Date( Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      headersCatalogo: [
        {
          text: "Material",
          align: "start",
          sortable: false,
          value: "MATNR",
        },
        { text: "Descripción", value: "MAKTX" },
        { text: "actions", value: "actions" },
      ],
      catalogo_list: [],
      headersPedido: [
        { text: "ID", value: "id" },
        {
          text: "Orden de compra",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Num. Partidas", value: "materials" },
        { text: "Importe Total", value: "total" },
        { text: "Usuario Creador", value: "type" },
        { text: "", value: "actions" },
      ],
      orders_list: [],
      items_product: [
        {
          src: "img/267925_1.jpg",
          href: "https://iusa.com.mx/catalogo_construccion/",
          percent: "18%",
          price: "$4,155.00",
          less: "$3,777.00",
          name_product: "CALENTADOR IUSA DE RÁPIDA RECUPERACIÓN, 5L, GAS LP",
          sku: "308773",
        },
        {
          src: "img/lbl-iusa-26_principal.jpg",
          href: "https://iusa.com.mx/catalogo_construccion/",
          percent: "25%",
          price: "$8,522.00",
          less: "$7,747.00",
          name_product: "CALENTADOR SOLAR PRIMO DE TUBOS AL VACÍO",
          sku: "308773",
        },
        {
          src: "img/617035_01.jpg",
          href: "https://iusa.com.mx/catalogo_construccion/",
          percent: "20%",
          price: "$8,236.00",
          less: "$7,100.00",
          name_product: "MOTOBOMBA A GASOLINA PARA ALTA CARGA 6.5 HP",
          sku: "308773",
        },
        {
          src: "img/268458_1.jpg",
          href: "https://iusa.com.mx/catalogo_construccion/",
          percent: "38%",
          price: "$14,800.00",
          less: "$17,168.00",
          name_product: "AIRE ACONDICIONADO MINI SPLIT, MASTERFRIZ 2023",
          sku: "308773",
        },
        {
          src: "img/619049_01.jpg",
          href: "https://iusa.com.mx/catalogo_construccion/",
          percent: "10%",
          price: "$14,800.00",
          less: "$17,168.00",
          name_product: "ROTOMARTILLO 750 W",
          sku: "308773",
        },
      ],
    }),
    async mounted() {
      //console.log(this.$route.query.data);
      console.log("pass"+this.$route.query.data[0].order_q);
      this.getOrderID();
      this.checkOrdersHistory();
       this.getCatalogo();
       
      //this.getCatalogoRandom();
    },
    methods: {
      getFormattedDate(date) {
          return moment(date).format("YYYY-MM-DD")
    },
    closeDateMenu1() {
      this.menu1 = false;
      this.start_date= new Date(
        this.start_date - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
        console.log(this.start_date);
    },
      formatPrice(value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      getCatalogo() {
        axios
          .get("getCatalogo")
          .then((response) => {
            this.catalogo_list = response.data;
          })
          .catch((error) => {
            //(error);
            console.log(error);
          })
          .finally();
      },
      getCatalogoRandom() {
        axios
          .get("getCatalogoRandom")
          .then((response) => {
            this.items_product = response.data;
          })
          .catch((error) => {
            //alert(error);
            console.log(error);
          })
          .finally();
      },
      newMaterialSearch(item) {
        this.sendMaterialNew = item;
      },
     getOrderID() {
        //let type = "CL";
        //let type = "VE"; // ENCASO DE PORTAL VENDEDOR ENVIAR ESTA VARIABLE
        axios
          .post("quoterOrderEdit", {
            customer: this.user.custumer_code,
            quoter_id: this.$route.query.data,
            nu_tender: this.nu_tender,
           // quoter_id: this.$route.query.data[0].order_q,
          })
          .then((response) => {
           // this.order_id = response.data;
            //this.getCartItems(this.order_id);
            this.order_id = response.data.order_id;
            this.quoter_type = response.data.quoter_type;
            this.radio_percent = parseInt(response.data.percent);
            this.doctype = response.data.doctype;
            console.log("soy yo edit");
            console.log(this.quoter_type);

            if(this.user.VTWEG == "DT"){
              this.orderTypeItemsData(response.data.doctype);
            }else if(this.user.VTWEG == "PR"){
              this.orderTypeItemsDataPR(response.data.doctype);
            }
            
            this.getOrderInfo(this.order_id);
          })
          .catch((error) => {
            //alert(error);
            console.log(error);
          })
          .finally();
      },
      getOrderInfo(id) {
        axios
          .post("getQuoterInfo", {
            id_order: id,
          })
          .then((response) => {
            this.order = response.data.name;
            this.start_date = this.getFormattedDate(response.data.validation);
            this.nu_tender = response.data.nu_tender;
            this.customer_customer = response.data.customer_customer;
            this.customer_seller = response.data.customer_seller;
            this.doctype = response.data.doctype;

            if(this.user.VTWEG == "DT"){
              this.orderTypeItemsData(response.data.doctype);
            }else if(this.user.VTWEG == "PR"){
              this.orderTypeItemsDataPR(response.data.doctype);
            }
            
          })
          .catch((error) => {
            //alert(error);
            console.log(error);
          })
          .finally();
      },
      checkOrdersHistory() {
        axios
          .post("checkOrdersHistory", {
            customer: this.user.custumer_code,
          })
          .then((response) => {
            this.orders_list = response.data;
          })
          .catch((error) => {
            //alert(error);
            console.log(error);
          })
          .finally();
      },
      saveOrderInfo() {
        axios
          .post("saveQuoterInfo", {
            order_id: this.order_id,
            orden_compra: this.order,
            destiny: this.customerDestiny.KUNNR,
            cart: this.cart,
            total: this.total,
            nu_tender: this.nu_tender,
            quoter_type: this.quoter_type,
            percent: this.radio_percent
  
          })
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
           // alert(error);
           console.log(error);
          })
          .finally();
      },
      loadOrder(item) {
        this.dialogLoading = true;
        this.dialogPedidos = false;
        this.order_id = item.id;
        //this.getCartItems(this.order_id);
        this.getOrderInfo(this.order_id);
      },
      deleteOrder(item) {
        this.dialogLoading = true;
        this.dialogPedidos = false;
        axios
          .post("orderDelete", {
            order_id: item.id,
          })
          .then((response) => {
            console.log(response);
            this.dialogPedidos = true;
           // this.checkOrdersHistory();
          })
          .catch((error) => {
            //alert(error);
            console.log(error);
          })
          .finally();
      },
      generateNewOrder() {
        this.dialogLoading = true;
        this.dialogPedidos = false;
        axios
          .post("generateNewOrder", {
            customer: this.user.custumer_code,
          })
          .then((response) => {
            this.order_id = response.data;
            this.checkOrdersHistory();
          })
          .catch((error) => {
            //alert(error);
            console.log(error);
          })
          .finally();
      },
      catchMaterialItem(data) {
        console.log(data);
        this.materialItem = data;
      },
      catchErrorMaterial(data) {
        this.hasError = data;
        window.scroll(0, window.pageYOffset - 50);
      },
      catchTotal(data) {
        this.subtotalPrint =  parseFloat(data).toFixed(2);
        this.subtotal = parseFloat(data);
        this.iva = parseFloat((this.subtotal * 0.16).toFixed(2));
        this.total = this.subtotal + this.iva;
        this.total = this.total.toFixed(2);
      },
      catchDialogLoading(data) {
        this.dialogLoading = data;
      },
      cacthDestiny(data) {
        console.log("Destiny"+data);
        this.customerDestiny = data;
      },
      catchCart(data) {
        this.cart = data;
        this.saveOrderInfo();
      },
      catchRequestLock(data) {
        console.log(data);
        this.requestLock = data;
      },
      catchRadioPercent(data){
        console.log(data);
        this.radio_percent = data;
      },
      saveQuoterCart(){
        let type = "CL";
        axios
          .post("saveEditQuoterCart", {
            order_id: this.order_id,
            quoter_folio: this.order,
            validation: this.start_date,
            customer: this.user.custumer_code,
            user_id: this.user.id,
            type: type,
            total: this.total,
            nu_tender: this.nu_tender,
            quoter_type: this.quoter_type,
            percent: this.radio_percent,
            customer_customer: this.customer_customer,
            customer_seller: this.customer_seller,
            doctype: this.orderType
          })
          .then((response) => {
            this.order_id = response.data;

            this.$swal.fire(response.data, 'Se guardo Correctamente','success');
            this.$router.replace({
            name: "QuoterHistory",
          });

          })
          .catch((error) => {
            //alert(error);
            console.log(error);
          })
          .finally();
      },
      orderTypeItemsData(doctype) {
        axios
          .post("orderType", {
            doctype: doctype,
          })
          .then((response) => {
            //this.orderType = response.data.type;
           // this.getItemTextOrder = response.data.name;
            this.orderItemsList = response.data;
            this.orderType = this.orderItemsList[0].type;
  
            //this.destiny = this.customerDestinyList;
            console.log("Tipo PEDIDO");
            console.log(this.orderItemsList);
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => (this.dialogLoading = false));
      },
      orderTypeItemsDataPR(doctype) {
        axios
          .post("order-type-pr", {
            doctype: doctype,
          })
          .then((response) => {
            //this.orderType = response.data.type;
           // this.getItemTextOrder = response.data.name;
            this.orderItemsListPR = response.data;
            this.orderType = this.orderItemsListPR[0].type;
  
            //this.destiny = this.customerDestinyList;
            console.log("Tipo PEDIDO");
            console.log(this.orderItemsList);
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => (this.dialogLoading = false));
      },
      getItemTextOrderType(item) {
        return `(${item.type}) ${item.name}`;
      },
    }, 
   
  };
  </script>
  <style>
  </style>