export default {
    namespaced: true,
    state: {
      excel: {   
        name: null,     
        showLabels: false,
        items: [],
      },
      pdf: { 
        name: null,  
        height: window.innerHeight,
        disabledClass: 'disabled-pdf',
        active: false
      },
    },
    getters: {
      getExcel: (state) => state.excel,
      getPDF: (state) => state.pdf,
      getPrinterActive: (state) => state.printerActive,
    },
    mutations: {
      SET_EXCEL(state, { name, showLabels, items } = {}) {        
        state.excel.name = name !== null && name !== undefined ? name : state.excel.name;
        state.excel.showLabels = showLabels !== null && showLabels !== undefined ? showLabels : state.excel.showLabels;
        state.excel.items = items !== null && items !== undefined ? items : state.excel.items;
      },
      SET_PDF(state, { name, height, disabledClass, active } = {}) {
        state.pdf.name = name !== null && name !== undefined ? name : state.pdf.name;
        state.pdf.height = height !== null && height !== undefined ? height : state.pdf.height;
        state.pdf.disabledClass = disabledClass !== null && disabledClass !== undefined ? disabledClass : state.pdf.disabledClass;
        state.pdf.active = active !== null && active !== undefined ? active : state.pdf.active;
      }
    },
    actions: {
      sendToExcel({ commit }, data) {
        commit('SET_EXCEL', data);
      },
      sendToPDF({commit}, data){
        commit('SET_PDF', data);
      },
      resetPrinter({ commit }) {
        const excelDefaults = { name: null, showLabels: false, items: [] };
        const pdfDefaults = { name: null, height: window.innerHeight, disabledClass: 'disabled-pdf', active: false };

        commit('SET_EXCEL', excelDefaults);
        commit('SET_PDF', pdfDefaults);
      }
    },
  };