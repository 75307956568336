<template>
  <v-dialog
    v-model="showStepsModal"
    width="1050px"
    scrollable
    :persistent="!allStepsDone"
  >
    <v-card height="500px">
      <v-card-text class="pa-0">
        <steper
          v-model="currentStepValue"
          :steps="steps"
          :finished="allStepsDone"
          hide-back-btn
          @step-selected="markAsVisited"
          @on-finish="finishSteps"
        >
          <div class="modal_step_size" v-if="currentStep">
            <v-row justify="center" no-gutters>
              <div class="w-auto mb-3">
                <iusa-logo no-link />
              </div>
            </v-row>
            <div v-if="currentStep.key === 'notifications'">
              <notification-step />
            </div>
            <div v-else-if="currentStep.key === 'reminders'">
              <reminder-step @close-modal="showStepsModal = false" />
            </div>
            <div v-else-if="currentStep.key === 'inactivity'">
              <inactivity-step />
            </div>
            <div v-else-if="currentStep.key === 'updateData'">
              <data-update-step />
            </div>
          </div>
        </steper>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Steper from '@/components/steper/Steper.vue';
import ReminderStep from './ReminderStep.vue';
import NotificationStep from './NotificationStep.vue';
import InactivityStep from './InactivityStep.vue';
import DataUpdateStep from './DataUpdateStep.vue';
import IusaLogo from '@/components/IusaLogo.vue';

import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'ModalStepsAfterLogin',

  components: {
    Steper,
    ReminderStep,
    NotificationStep,
    InactivityStep,
    DataUpdateStep,
    IusaLogo,
  },

  methods: {
    ...mapActions('modalStepsAfterLogin', ['updateModalState', 'updateStepStatus', 'findStepByKey', 'changeCurrentStepValue']),
    ...mapActions('userNotification', ['markNotificationsAsViewed']), // map
    ...mapGetters('userNotification', ['dontShowAgain']),
 
    markAsVisited(index) {
      const step = this.steps[index];
      step.visited = true;
      this.updateStepStatus(step);
    },

    finishSteps() {
    if (this.dontShowAgain() === true) {
      this.markNotificationsAsViewed();
    }
    this.showStepsModal = false;
    },
  },

  computed: {
    ...mapGetters('modalStepsAfterLogin', ['showModal', 'steps', 'currentStepVal', 'inactivityTime']),

    showStepsModal: {
      set(newValue) {
        this.updateModalState(newValue);
      },
      get() {
        return this.showModal;
      },
    },

    currentStepValue: {
      set(newValue) {
        this.changeCurrentStepValue(newValue);
      },
      get() {
        return this.currentStepVal;
      },
    },

    currentStep() {
      return this.steps.find((s) => s.value === this.currentStepValue) || null;
    },

    currentStepDone() {
      return this.currentStep?.done || false;
    },

    allStepsDone() {
      const anyStepUndone = this.steps.find(
        (s) => s.doneRequired !== false && s.done !== true
      );
      return anyStepUndone === undefined;
    },
  },
};
</script>

<style scoped>
* {
  text-align: left;
}

.v-dialog {
  max-width: 90vw;
}
</style>
