import { mapGetters, mapActions } from 'vuex';

export default {

  data () {
    return {
      userIsBlockedByBackorders: false,
    }
  },

  methods: {
    ...mapActions('reminders', ['getUserAvailableReminders']),

    async validateUserBlockedByBackorders () {
      if (this.loggedinUserIsCustomer) {
        this.userIsBlockedByBackorders = this.blockedUser;
      }
      else {
        const selectedCustomer = this.customer.customer;
        
        const reminders = await this.getUserAvailableReminders(selectedCustomer);
        this.userIsBlockedByBackorders = reminders.length > 0;
      }
    },

  },
  
  computed: {
    ...mapGetters('selectedUser', ['customer', 'loggedinUserIsCustomer']),

    ...mapGetters('reminders', [
      'selectedReminders',
      'firstReminderSelected',
      'firstReminderSelectedIsKit',
      'blockedUser',
    ]),

    ...mapGetters('reminders', { backorders: 'reminders'})
  },

  watch: {
    backorders() { // Every time that reminders change we have to revalidate for any block
      this.validateUserBlockedByBackorders();
    }
  }
}