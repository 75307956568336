<template>
  <div>
    <v-row>
      <v-col cols="12" sm="4" md="4">
        <div class="col-12 row">
          <div class="col-6">
            <v-avatar cols="6" size="120" style="border: solid 2px #ff000a">
              <img
                src="img/icon_default_user.svg"
                alt="IUSA"
                style="width: 90%; height: 90%"
              />
            </v-avatar>
          </div>
          <div class="col-6" style="align-items: left">
            <span style="text-align: left; font-weight: 800; color: #000">
              Cliente: {{ customerInfo.NAME1 }} 
            </span>
            <br /><br />
            <span style="text-align: left; font-weight: 400; color: #000">
              ID: {{ customerInfo.KUNNR }}
            </span>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="4" md="4">
        <v-card-subtitle class="pa-lg-2">
          <p style="text-align: center">
            LIMITE DE CRÉDITO:
            <span style="font-weight: 800">${{ customerInfo.KLIMK }}</span>
          </p>
          <v-progress-linear v-model="porcentaje" height="25">
            <strong style="color: #fff">{{ porcentaje }}%</strong>
          </v-progress-linear>
          <v-row>
            <v-col cols="6" sm="12" md="6">
              <span style="font-size: 12px"
                >SALDO AL CORTE:
                <span style="font-weight: 800; font-size: 14px"
                  >${{ customerInfo.SKFOR }}</span
                ></span
              >
            </v-col>
            <v-col cols="6" sm="12" md="6">
              <span style="font-size: 12px"
                >CREDITO DISPONIBLE:
                <span style="font-weight: 800; font-size: 14px"
                  >${{ customerInfo.CREDD }}
                </span></span
              >
            </v-col>
          </v-row>
          <p></p>
        </v-card-subtitle>
      </v-col>
      <v-col cols="12" sm="4" md="4">
        <v-card-subtitle>
          <div style="text-align: center">
            <span style="font-size: 28px">$ {{ formatPrice(total || catchTotal) }}</span>
            <br />
            MONTO TOTAL DEL PEDIDO 
          </div>
          <!-- CREDITO DISPONIBLE:
              {{ parseFloat(customerInfo.CREDD) }} -->
        </v-card-subtitle>
      </v-col>
    </v-row>
   <div v-if="doctype == 'PSNW'">
    <v-alert
      icon="mdi-close-octagon"
      prominent
      type="error"
      dismissible
      v-if="year_validation < 12 "
    >
      <v-row align="center">
        <v-col class="grow">
          <span style="font-weight: 700; font-size: 16px"
            >Para generar el pedido el cliente debe de tener 1 año de antiguedad</span
          >
        </v-col>

      </v-row>
    </v-alert>
  </div>
  <div v-if="doctype == 'PSDR'">
    <v-alert
      icon="mdi-close-octagon"
      prominent
      type="error"
      dismissible
      v-if="year_validation < 6 "
    >
      <v-row align="center">
        <v-col class="grow">
          <span style="font-weight: 700; font-size: 16px"
            >Para generar el pedido el cliente debe de tener 6 meses de antiguedad </span
          >
        </v-col>
        <!--Si el cliente tiene una atiguedad menor a 6 meses se debe de mostrar la alerta-->
      </v-row>
    </v-alert>
  </div>
  <div v-if="doctype == 'PSDR' || doctype == 'PSNW'">
    <v-alert
      icon="mdi-close-octagon"
      prominent
      type="error"
      dismissible
      v-if="customerInfo.PPDP > 36"
    >
      <v-row align="center">
        <v-col class="grow">
          <span style="font-weight: 700; font-size: 16px"
            >Para generar el pedido el Cliente debe de tener un Promedio Ponderado de Días de Pago de 36 días</span
          >
        </v-col>
        <!-- Si es mayor a 36 dias se muestra el mensaje ya que se pertite realizar el pedido si el ppdp es menor o igual a 36 dias -->
      </v-row>
    </v-alert>
  </div>
  <div v-if="doctype == 'PSDR'">
    <v-alert
      icon="mdi-close-octagon"
      prominent
      type="error"
      dismissible
      v-if="customerInfo.PSDR > 0"
    >
      <v-row align="center">
        <v-col class="grow">
          <span style="font-weight: 700; font-size: 16px"
            >El cliente ya tiene generado un Pedido de Reorden este Mes</span
          >
        </v-col>
        <!-- Si Ya se realizo un pedido de reordense muestra el mensaje de alerta, se valora iditificando si la variable es mayor a cero -->

      </v-row>
    </v-alert>
  </div>
  <div v-if="doctype == 'PSNW'">
    <v-alert
      icon="mdi-close-octagon"
      prominent
      type="error"
      dismissible
      v-if="customerInfo.PSNW > 0"
    >
      <v-row align="center">
        <v-col class="grow">
          <span style="font-weight: 700; font-size: 16px"
            >El cliente ya tiene generado un Pedido de Nuevos Productos este Mes</span
          >
        </v-col>
        <!-- <v-col class="shrink">
                <v-btn>Take action</v-btn>
            </v-col> -->
      </v-row>
    </v-alert>
  </div>
  <div v-if="doctype == 'PSMA'">
    <v-alert
      icon="mdi-close-octagon"
      prominent
      type="error"
      dismissible
      v-if="customerInfo.PSMA > 0"
    >
      <v-row align="center">
        <v-col class="grow">
          <span style="font-weight: 700; font-size: 16px"
            >El cliente ya tiene generado un Pedido Apoyo a la Marca este Trimestre, puede realizar un nuevo pedido hasta el siguiente trimestre.</span
          >
        </v-col>
        <!-- Si Ya se realizo un pedido de productos nuevos se muestra el mensaje de alerta, se valora iditificando si la variable es mayor a cero -->
      </v-row>
    </v-alert>
  </div>
  <div v-if="doctype == 'PSPO'">
    <v-alert
      icon="mdi-close-octagon"
      prominent
      type="error"
      dismissible
      v-if="customerInfo.PSPO > 0"
    >
      <v-row align="center">
        <v-col class="grow">
          <span style="font-weight: 700; font-size: 16px"
            >El cliente ya tiene generado un Pedido POP este Trimestre, puede realizar un nuevo pedido hasta el siguiente trimestre.</span
          >
        </v-col>
        <!-- Si Ya se realizo un pedido de productos nuevos se muestra el mensaje de alerta, se valora iditificando si la variable es mayor a cero -->
      </v-row>
    </v-alert>
  </div>
  <v-alert
      type="info"
      dismissible
      v-if="customerInfo.AUFSD == 1"
    >
      <v-row align="center">
        <v-col class="grow">
          <span style="font-weight: 700; font-size: 16px"
            >Estimado cliente usted tiene un bloqueo de partidas vencidas, favor
            de realizar su pago en línea <router-link
                :to="{ name: 'AccountStatus' }"
                style="color:#fff;"
              > aquí. </router-link>Se generará su pedido.</span
          >
        </v-col>
        <!-- <v-col class="shrink">
                <v-btn>Take action</v-btn>
            </v-col> -->
      </v-row>
    </v-alert>
    <v-alert
      icon="mdi-close-octagon"
      prominent
      type="error"
      dismissible
      v-if="total > this.aviable || catchTotal > this.aviable"
    >
      <v-row align="center">
        <v-col class="grow">
          <span style="font-weight: 700; font-size: 16px"
            >Ha superado el Limite de crédito, El pedido será bloqueado por crédito</span
          >
        </v-col>
      </v-row>
    </v-alert>
    <v-alert
      icon="mdi-close-octagon"
      prominent
      type="error"
      dismissible
      v-if="total < 5000 || catchTotal < 5000"
    >
      <v-row align="center">
        <v-col class="grow">
          <span style="font-weight: 700; font-size: 16px"
            >El monto mínimo de compra es $ 5,000.00 MXP</span
          >
        </v-col>
        <!-- <v-col class="shrink">
                <v-btn>Take action</v-btn>
            </v-col> -->
      </v-row>
    </v-alert>
    <div v-if="doctype <= 'PSNW'">
      <v-alert
        icon="mdi-close-octagon"
        prominent
        type="error"
        dismissible
        v-if="days_credit > 60"
      >
        <v-row align="center">
          <v-col class="grow">
            <span style="font-weight: 700; font-size: 16px"
              >Para generar el pedido el cliente debe de tener 60 días de
              Credito</span
            >
          </v-col>
          <!-- Si es mayor a 60 dias de credito se muestra la alerta (Para poder realizar el pedido este debe de ser menor o igual a 60 dias de credito)-->
        </v-row>
      </v-alert>
    </div>
    <div v-if="doctype == 'PSDR'">
      <v-alert
        icon="mdi-close-octagon"
        prominent
        type="error"
        dismissible
        v-if="days_credit > 60"
      >
        <v-row align="center">
          <v-col class="grow">
            <span style="font-weight: 700; font-size: 16px"
              >Para generar el pedido el cliente debe de tener 60 días de
              Credito</span
            >
          </v-col>
          <!-- Si es mayor a 60 dias de credito se muestra la alerta (Para poder realizar el pedido este debe de ser menor o igual a 60 dias de credito)-->
        </v-row>
      </v-alert>
    </div>
  <div v-if="doctype == 'PSNW'">
    <v-alert
      icon="mdi-close-octagon"
      prominent
      type="error"
      dismissible
      v-if="customerInfo.RTRIMA < 20"
    >
      <v-row align="center">
        <v-col class="grow">
          <span style="font-weight: 700; font-size: 16px"
            >El cliente debe de haber facturado al menos 20 renglones en el ultimo trimestre, para poder realizar este tipo de pedido</span
          >
        </v-col>
        <!-- Si el valor de renglonaje del cliente es de menos de 20  se muestra la alerta ya que la condicion dice que debe de ser mayor o igual a 20 renglones el el trimestre -->
      </v-row>
    </v-alert>
  </div>
  <div v-if="doctype == 'PSDR'">
    <v-alert
      icon="mdi-close-octagon"
      prominent
      type="error"
      dismissible
      v-if="customerInfo.RTRIMA < 20"
    >
      <v-row align="center">
        <v-col class="grow">
          <span style="font-weight: 700; font-size: 16px"
            >El cliente debe de haber facturado al menos 20 renglones en el ultimo trimestre, para poder realizar este tipo de pedido</span
          >
        </v-col>
         <!-- Si el valor de renglonaje del cliente es de menos de 20  se muestra la alerta ya que la condicion dice que debe de ser mayor o igual a 20 renglones el el trimestre -->
      </v-row>
    </v-alert>
  </div>
  <div v-if="doctype == 'PSDR'">
    <v-alert
      icon="mdi-close-octagon"
      prominent
      type="error"
      dismissible
      v-if="customerInfo.RSR6M  < 10 "
    >
      <v-row align="center">
        <v-col class="grow">
          <span style="font-weight: 700; font-size: 16px"
            >Para generar el pedido deberá disponer de al menos 10  Productos de Pedido de Reorden (PPR)</span
          >
        </v-col>
        <!-- La alerta se muestra si PPR Productos de Reorden son menores a 10 (debetener 10 o mas de 10 par apoder realizar el pedido)-->
      </v-row>
    </v-alert>
  </div>
  <div v-if="doctype == 'PSNW'">
    <v-alert
      icon="mdi-close-octagon"
      prominent
      type="error"
      dismissible
      v-if="cart < 15 "
    >
      <v-row align="center">
        <v-col class="grow">
          <span style="font-weight: 700; font-size: 16px"
            >Para generar el pedido deben ser minimo 15 renglones (Partidas) dentro del carrito</span
          >
        </v-col>
        <!-- La alerta se muestra si los productos dentro del carrito son menores a 15-->
      </v-row>
    </v-alert>
  </div>
  <div v-if="doctype == 'PSDR'">
    <v-alert
      icon="mdi-close-octagon"
      prominent
      type="error"
      dismissible
      v-if="cart < 10 "
    >
      <v-row align="center">
        <v-col class="grow">
          <span style="font-weight: 700; font-size: 16px"
            >Para generar el pedido deben ser minimo 10 renglones (Partidas) dentro del carrito</span
          >
        </v-col>
        <!-- <v-col class="shrink">
                <v-btn>Take action</v-btn>
            </v-col> -->
      </v-row>
    </v-alert>
  </div>
  <v-alert
      icon="mdi-close-octagon"
      prominent
      type="error"
      dismissible
      v-if="customerInfo.DOCVENC  > 1 "
    >
      <v-row align="center">
        <v-col class="grow">
          <span style="font-weight: 700; font-size: 16px"
            >El pedido será bloqueado por crédito</span
          >
        </v-col>
        <!-- Si los DOCVENC es mayor a 1 se envia alerta de que sera bloeueado por credito-->
      </v-row>
    </v-alert>
  </div>
</template> 
<script>
import axios from "@/plugins/axios";
import { mapGetters } from "vuex";
export default {
  name: "CustomerInfo",
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  props: {
    catchTotal: {
      type: Number,
      default: null,
    },
    warnLock: {
      type: Function,
      default: null,
    },
    catchLimit:{
      type: Function,
      default: null,
    },
    catchAviableCredit:{
      type: Function,
      default: null,
    },
    catchErrorConnection:{
      type: Function,
      default: null,
    },
    catchCustumerInfoMetodo:{
      type: Function,
      default: null,
    },
    catchCustumerInfoVPago:{
      type: Function,
      default: null,
    },
    catchCustumerInfoUCFDI:{
      type: Function,
      default: null,
    },
    catchCustumerZTERM:{
      type: Function,
      default: null,
    },
    capturedCart: {
    type: Number,
    default: null,
    },
    capturedDocType: {
    type: String,
    default: null,
    },
    catchYearValidation:{
      type: Function,
      default: null
    },
    catchPPDP:{
      type: Function,
      default: null
    },
    catchPSDR:{
      type: Function,
      default: null
    },
    catchPSNW:{
      type: Function,
      default: null
    },
    catchRTRIMA:{
      type: Function,
      default: null
    },
    catchCreditDays: {
      type: Function,
      default: null,
    },
    catchPSMA:{
      type: Function,
      default: null
    },
    catchPSPO:{
      type: Function,
      default: null
    },
    catchRSR6M:{
      type: Function,
      default: null
    },
    purValidation:{
      type: Function,
      default: null
    },
  },
  data: () => ({
    customerInfo: [],
    total: 0,
    porcentaje: 0,
    aviable: "",
    errormsg: false,
    custumerInfoMetodo:[],
    custumerInfoVPay:[],
    custumerInfoUseCfdi:[],
    custumerInfoZterm: null,
    cart: 0,
    current_date: new Date( Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    year_validation: null,
    doctype: null,
    ppdp_val: null,
    psdr_val: null,
    psnw_val: null,
    rtrima_val: null,
    days_credit: null,
    psma_val: null,
    pspo_val: null,
    ppr_val: null,
    purshaseVal: null,
  }),
  async mounted() {
    let self = this;
    self.getInfoCustomer();
  },
  methods: {
    getInfoCustomer() {
      axios
        .post("info-customer-pr", {
          customer: this.user.custumer_code,
          VKORG: this.user.VKORG,
          VTWEG: this.user.VTWEG,
          VKBUR: this.user.VKBUR,
        })
        .then((response) => {
          this.customerInfo = response.data.customer_data;

          this.custumerInfoMetodo = response.data.metodos_de_pago;
          this.custumerInfoVPay = response.data.via_de_pago;
          this.custumerInfoUseCfdi = response.data.uso_cfdi;
          this.custumerInfoZterm = response.data.customer_data.ZTERM;

          this.saldo = this.customerInfo.SKFOR.replace(/,/g, "");
          this.limite = this.customerInfo.KLIMK.replace(/,/g, "");
          this.porcentaje =
            (parseInt(this.saldo) * 100) / parseInt(this.limite);
          this.porcentaje = parseInt(this.porcentaje);
          this.aviable = this.customerInfo.CREDD.replace(/,/g, "");
          this.aviable = (this.aviable*1);
        
          
          
          this.days_credit = parseInt(this.customerInfo.VTEXT.split(" ")[1]);
          console.log("VTEX");
          console.log(this.customerInfo.VTEXT.split(" ")[1]);

          if(this.customerInfo.VTEXT.split(" ")[1] == "LOCAL/FORANEO"){
            this.days_credit = parseInt(this.customerInfo.VTEXT.split(" ")[2]); 
          }

          
         // this.porcentaje = 130;
          //this.aviable = -1112605.57; 
          this.warnLock(this.customerInfo.AUFSD);
          this.purValidation(this.customerInfo.DOCVENC);
          this.catchLimit(this.porcentaje);
          this.catchAviableCredit(this.aviable);


          this.catchCustumerInfoMetodo(this.custumerInfoMetodo);
          this.catchCustumerInfoVPago(this.custumerInfoVPay);
          this.catchCustumerInfoUCFDI(this.custumerInfoUseCfdi);
          this.catchCustumerZTERM(this.custumerInfoZterm);

          this.year_validation = parseInt(this.differenceInMonths(this.current_date,this.customerInfo.ERDAT));
          console.log("validate_moths"+ this.differenceInMonths(this.current_date,this.customerInfo.ERDAT));
          
          this.catchPSDR(this.customerInfo.PSDR);
          this.catchPPDP(this.customerInfo.PPDP);
          this.catchRTRIMA(this.customerInfo.RTRIMA);
          this.catchCreditDays(this.days_credit);
          this.catchYearValidation(this.year_validation);
          this.catchRSR6M(this.customerInfo.RSR6M);
          
          this.catchPSNW(this.customerInfo.PSNW);
          this.catchPSMA(this.customerInfo.PSMA);
          this.catchPSPO(this.customerInfo.PSPO);
        
 
         // this.errormsg = true;
          //this.catchErrorConnection(this.errormsg);
        })
        .catch((error) => {
          //alert(error);
          console.log(error);
          this.errormsg = true;
          this.catchErrorConnection(this.errormsg);
        })
        .finally();
    },
    formatPrice(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  differenceInMonths(date1, date2) { 
   return (new Date (date1).getFullYear() - new Date (date2).getFullYear()) * 12 + (new Date (date1).getMonth() - new Date (date2).getMonth()) + 1
  }
  },
  watch: {
    catchTotal: function (val) {
      this.total = val;
    },
    catchLimit: function (val) {
      this.porcentaje = val;
    },
    catchAviableCredit: function(val){
      this.aviable = val;
    },
    catchErrorConnection: function(val){
      this.errormsg = val;
    },
    catchCustumerInfoMetodo: function(val){
      this.custumerInfoMetodo = val;
    },
    catchCustumerInfoVPago: function(val){
      this.custumerInfoVPay = val;
    },
    catchCustumerInfoUCFDI: function(val){
      this.custumerInfoUseCfdi = val;
    },
    catchCustumerZTERM: function(val){
      this.custumerInfoZterm = val;
    },
    capturedCart: function(val){
      this.cart = val;
    },
    capturedDocType: function(val){
      this.doctype = val
    },
    catchYearValidation: function(val){
      this.year_validation = val
    },
    catchPPDP: function(val) {
       this.ppdp_val = val;
    },
    catchPSDR: function(val) {
       this.psdr_val = val;
    },
    catchPSNW: function(val) {
       this.psnw_val = val;
    },
    catchRTRIMA: function(val){
      this.rtrima_val = val;
    },
    catchCreditDays: function (val) {
      this.days_credit = val;
    },
    catchPSMA: function(val) {
       this.psma_val = val;
    },
    catchPSPO: function(val) {
       this.pspo_val = val;
    },
    catchRSR6M: function(val) {
       this.ppr_val = val;
    },
    purValidation: function(val) {
       this.purshaseVal = val;
    },
  },
};
</script>