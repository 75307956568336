<template>
    <div class="d-sm-flex d-block gap-4">
      <div v-for="(stat, index) in stats" :key="index" class="d-flex flex-row-reverse flex-sm-row gap-2 align-center mb-2 mb-sm-0">
        <pill-status :status="stat.value" width="32px" />
        <v-spacer />
        <span class="text-left">{{ stat.text }}</span>
    </div>
  </div>
</template>

<script>
import PillStatus from './PillStatus.vue';

export default {
  name: 'MaterialStatusHelper',

  components: { PillStatus },

  data: () => ({
    stats: [
      { value: 'available', text: 'Con existencia 100%' },
      { value: 'partial', text: 'Con existencias paraciales' },
      { value: 'unavailable', text: 'No hay existencias' },
    ],
  }),
}
</script>