<template>
  <div>
    <span class="span__1-red">{{semana.intervalo}} / Semana {{semana.semana}}</span>        
    <h4 class="mt-2"><b>{{title}}</b></h4>
  </div>
</template>

<script>
export default {
    props: ['semana', 'title']
}
</script>

<style>
.span__1-red{
    color: red;
}
</style>