<template>
<v-container fluid>
    <template>
        <v-row>
            <v-col>
                <v-card cols="12" class="pa-xs-2 pa-md-2 pa-lg-2 mt-xs-0 mt-md-0 mt-lg-0 mb-7">
                    <v-card-title>Timming {{ data.length }} </v-card-title>
                    <div class="pa-2">
                        <v-btn @click="clean()"> limpiar</v-btn>
                    </div>
                    <div class="pa-2">
                        <v-btn @click="refresh()"> refresh </v-btn>
                    </div>
                    <div>
                    </div>
                    <div class="pa-2">
                        <vue-json-to-csv :json-data="data" filename="timming">
                            <button>Export to CSV</button>
                        </vue-json-to-csv>
                    </div> 
                </v-card>
            </v-col>
        </v-row> 
    </template>
</v-container>
</template> 
<script>
 import VueJsonToCsv from 'vue-json-to-csv';
 export default {
     data: () => ({
         data: {},
         fields: [
             "duration",
             "method",
             "origin",
             "request_data",
             "start",
             "finish",
         ]
     }),
     components: {
         VueJsonToCsv
     },
     mounted() {
         this.data = JSON.parse(localStorage.getItem('timming_services')) || []; 
     },
     methods: {
         clean() {
             localStorage.setItem('timming_services', JSON.stringify([]));
             this.refresh();
         },
         refresh() {
             this.data = JSON.parse(localStorage.getItem('timming_services')) || [];
         }
     },
 };
</script>
