<template>
  <div class="my-select">
    <button class="my-select-button" @click="showOption">
      <span>{{ selectedLabel }}</span>
      <i class="fas fa-chevron-down"></i>
    </button>
    <ul class="my-select-options" v-show="showOptions">
      <li
        class="my-select-item"
        v-for="(option, index) in options"
        :key="index"
        @click="selectOption(option.value)"
      >
        <button >{{ option.label.toString() }}</button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "MySelect",
  props: {
    disabled:{
      default:false
    },
    options: {
      type: Array,
      required: true,
    },
    value: {
      required: true,
    },
    placeholder: {
      default: "",
    },
  },
  data() {
    return {
      showOptions: false,
    };
  },
  computed: {
    selectedLabel() {
      const selectedOption = this.options.find(
        (option) => option.value === this.value
      );
      return selectedOption ? selectedOption.label : this.placeholder;
    },
  },
  methods: {
    showOption(){
      if (!this.disabled) { 
        this.showOptions = !this.showOptions
       }
    },
    selectOption(optionValue) {
      
        this.$emit("input", optionValue);
        this.showOptions = false;
    
    
    },
  },
};
</script>

<style scoped>
.my-select {
  position: relative;
  width: 100%;
}
.my-select-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.8em;
  background-color: #ebebeb;
  cursor: pointer;
}
.my-select-button span {
  flex-grow: 1;
  margin-right: 5px;
}
.my-select-button i {
  margin-left: 0.5rem;
}
.my-select-options {
  margin-top: -9px;
    list-style: none;
    position: absolute;
    top: 100;
    background-color: white;
    width: 100%;
    min-height: 100px;
    max-height: 180px !important;
    padding-left: 0;
    border: 1px solid #ebebeb;
    z-index: 2;
    max-height: 200px;
    overflow: auto;
    cursor: pointer;
}
.my-select-item {
  width: 100%;
    padding-top: 0.7em;
    padding-bottom: 0.7em;
    padding-left: 0.7em; 
    box-shadow: chocolate; 
    -moz-box-shadow: -1px 1px 7px 0px rgba(224,37,37,0.45);
    margin-bottom: 0px; 
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 0.5px solid #707070;
    opacity: 1; 
}
.my-select-item:hover {   
  background-color: #ebebeb;
}
</style>
