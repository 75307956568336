<template>
  <div>
    <client-request :userloaded="userloaded" :customerRequest="customerRequest"></client-request>
    <!-- <customer-update v-if="status.id_status == 2 || status.id_status == 1"></customer-update> -->
  </div>
</template>

<script>
import axios from "@/plugins/axios";
import { mapGetters } from "vuex";
import ClientRequest from "./ClientRequest.vue";
// import CustomerUpdate from "./CustomerUpdate.vue";
//import CustomerStatus from "./CustomerStatus.vue";
export default {
  name: "CustomerRegistration",
  components: {
    ClientRequest,
    // CustomerUpdate,
    //CustomerStatus,
  },
  data() {
    return {
      status: null,
      userloaded: null,
      customerRequest: null
    };
  },

  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
  },

  async mounted() {
    let self = this;
    self.getCustumerStatus();
  },
  methods: {
    getCustumerStatus() {
      axios
        .get("getCustumerStatus")
        .then((response) => {
          this.status = response.data[0];
          this.userloaded = response.data.user;
          console.log(this.status);
          if(this.status.id_status == 1 || this.status.id_status == 2) {
            this.loadPreviusRequest();
          }
        })
        .catch((error) => console.log(error))
        .finally(() => (this.loading = false));
    },
    loadPreviusRequest() {
      axios
        .post("CustumerRequestDetailUpdate", {
          user_id: this.user.id,
        })
        .then((response) => {
          this.customerRequest = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    }
  },
};
</script>

<style>
</style>