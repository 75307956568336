<template>
    <div class="my-select">
      <button class="my-select-button" @click="showOption">
        <span>{{ selectedLabel }}</span>
        <i class="fas fa-chevron-down icon"></i>
      </button>
      <ul class="my-select-options" v-show="showOptions">
        <li
          class="my-select-item"
          v-for="(option, index) in options"
          :key="index"
          @click="selectOption(option.value)"
        >
          <button >{{ option.label }}</button>
        </li>
      </ul>
    </div>
  </template>

<script>
export default {
    name: "CustomRedSelect",
    props: {
        disabled: {
            default: false
        },
        options: { 
            required: true,
        },
        value: {
            required: true,
        },
        placeholder: {
            default: "",
        },
    },
    data() {
        return {
            showOptions: false, 
        };
    },
    computed: {
      selectedLabel() {
        for (let index = 0; index <  this.options.length; index++) {
            if(this.options[index]['value']==this.value){
                return this.options[index]['label']
            }
        } 
       return this.placeholder;       
     },
  },
    methods: {
        showOption() {
            if (!this.disabled) {
                this.showOptions = !this.showOptions
            }
        },
        selectOption(optionValue) { 
            this.$emit("input", optionValue);
            this.showOptions = false;


        },
    },
};
</script>

<style scoped>
.my-select {
    position: relative;
    width: 100%;
}

.my-select-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding: 0rem 1rem; */
    cursor: pointer;
    /* border-color: #858585 !important; */
    /* border: 0px; */
    box-shadow: 0px 3px 6px #00000029, 0px 3px 6px #00000029;
}

.my-select-button span {
    text-align: left;
    padding-left: 5%;
    flex-grow: 1;
    margin: 0px;
    color: gray;
    
}

.icon{ 
    margin-right: 0px;
    background-color: #C62333 !important;
    color: honeydew; 
    font-size: xx-large;
    padding-left: 1%;
    padding-right: 1%;
}

.my-select-options {
    margin-top: -4px;
    list-style: none;
    position: absolute;
    top: 100;
    background-color: white;
    width: 100%; 
    max-height: 50pt;
    padding-left: 0;
    border: 1px solid #ebebeb;
    z-index: 40 !important;
    overflow: auto;
}

.my-select-item {
    width: 100%;
    /* padding-top: 0.7em; */
    padding-bottom: 0em;
    padding-left: 0.7em;
    box-shadow: chocolate;
    -moz-box-shadow: -1px 1px 7px 0px rgba(224, 37, 37, 0.45);
    margin-bottom: 0px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029; 
    opacity: 1;
    color: gray; 
    cursor: pointer;
}

.my-select-item:hover {
    background-color: #ebebeb;
}
</style> 