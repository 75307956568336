<template>
  <v-menu bottom left>
    <template v-slot:activator="{ on, attrs }">
      <v-btn dark icon @click="launchStep" v-bind="attrs" v-on="on">
        <v-badge :content="remindersCountString" color="transparent" :offset-x="availableRemindersCount < 10? '19px': '21px'" offset-y="21px" overlap>
           <v-img src="@/assets/img/backorder-icon.svg" width="37px" /> 
        </v-badge>
      </v-btn>
    </template>
    <v-card v-if="!hasAvailableReminders">
      <v-card-text>
        No tienes backorders activos
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'ReminderStepLauncher',

  data() {
    return {
      intervalId: null,
      showEvery: 15 * 60 * 1000,
    }
  },

  mounted() {
    this.intervalId = setInterval( async () => {
      await this.loadSteps();
      this.launchStep();
    }, this.showEvery); 
  },  

  methods: {
    ...mapActions('modalStepsAfterLogin', [
      'updateModalState',
      'findStepByKey',
      'changeCurrentStepValue',
      'updateStepStatus',
      'syncSteps',
      'loadSteps'
    ]),

    async launchStep () {
      if (this.hasAvailableReminders) {
        this.syncSteps();

        const reminderStep = await this.findStepByKey('reminders');
        
        if(reminderStep) {
          reminderStep.visited = true;
        
          this.updateStepStatus(reminderStep);
          this.changeCurrentStepValue(reminderStep.value);
          this.updateModalState(true);
        }

      }
    }
  },

  computed: {
    ...mapGetters('reminders', [
      'hasAvailableReminders',
      'availableRemindersCount',
    ]),

    remindersCountString() { // This is because it does not display 0 when is INT
      return this.availableRemindersCount.toString();
    }
  },

  beforeDestroy() {
    clearInterval(this.intervalId);
    this.intervalId = null;
  },
};
</script>