import { render, staticRenderFns } from "./HelpBanner.vue?vue&type=template&id=de36cbb2&scoped=true&"
var script = {}
import style0 from "./HelpBanner.vue?vue&type=style&index=0&id=de36cbb2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "de36cbb2",
  null
  
)

export default component.exports