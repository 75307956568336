<template>
  <v-row>
    <v-col cols="12" md="3">
      <CustomerGuestInformation></CustomerGuestInformation>
      <ContactDesk></ContactDesk>
    </v-col>
    <v-col cols="12" md="9">
      <v-carousel hide-delimiters>
        <v-carousel-item
          v-for="(item, i) in items"
          :key="i"
          :src="item.src"
          :href="item.href"
        ></v-carousel-item>
      </v-carousel>
      <CustomerProducts></CustomerProducts>
    </v-col>
  <v-card width="100%" height="500px">
    <div class="d-flex align-center my-3">
      <h2><b>Listas de Precios </b></h2>
      <h4 class="ml-1">/ Construcción, Energía e Industria</h4>
    </div>
    <iframe
      class="iframe-list-price"
      src="https://www.iusa.com.mx/listas_de_precios"
      width="100%"
      height="400px;"
    ></iframe>
  </v-card>
  </v-row>

</template>

<script>
//import slide1 from "@/assets/img/slide_01@2x.png";
import slide2 from "@/assets/img/slide_02@2x.png";
import slide3 from "@/assets/img/banner_cd@2x.png";
import ContactDesk from "@/components/ContactDesk.vue";
import CustomerGuestInformation from "@/views/auth/CustomerGuestInformation.vue";
import CustomerProducts from "@/views/auth/CustomerProducts.vue";

export default {
name: "GuestView",

components: { CustomerGuestInformation, CustomerProducts, ContactDesk },

data() {
  return {
    items: [
      /*{
        src: slide1,
        href: '#'
      },*/
      {
        src: slide2,
        href:<a href="https://iusa.com.mx/catalogo_construccion/" target="_blank" />
      },
      {
        src: slide3,
        href: '/#/client-request'
      },
    ],
  };
},

methods: {
  goTo(name) {
    console.log(name);
  }
}
};
</script>