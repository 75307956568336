import { getBackorders, deleteBackorder } from "@/repositories/Backorders";
import { getMaterialInfoPR, getMaterialInfoKit } from "@/repositories/Materials";

export default {
  namespaced: true,

  state: {
    reminders: [],
    selectedReminders: [],
    loading: false,
    error: null,

    roles: ['Seller', 'Customer', 'CustomerPR'] // Roles that can use this module
  },

  getters: {
    reminders: (state) => state.reminders,
    remindersCount: (state) => state.reminders.length,
    hasReminders: (state, getters) => getters.remindersCount > 0,
    loadingReminders: (state) => state.loading,
    selectedReminders: (state) => state.selectedReminders,
    hasRemindersSelected: (state) => state.selectedReminders.length > 0,

    firstReminderSelected: (state, getters) => {
      if (getters.hasRemindersSelected) {
        return state.selectedReminders[0];
      }

      return null;
    },

    firstReminderSelectedIsKit: (state, getters) => {
      if (getters.firstReminderSelected !== null) {
        return getters.firstReminderSelected.isKit;
      }

      return false;
    },

    remindersValidatedToSelect: (state, getters) => {
      return state.reminders.map((r) => {
        const noMatch = !matchFirstReminderSelected(
          getters.firstReminderSelected,
          r
        );
        
        const isSelectable = !noMatch && reminderHasStock(r.status) && !r.hasError && !r.backorderProgrammedDisabled;

        return {
          ...r,
          isSelectable,
          noMatch,
        };
      });
    },

    availableReminders: (getters) => {
      return getters.reminders.filter(r => reminderHasStock(r.status) && r.backorderProgrammedDisabled == false)
    },

    availableRemindersCount: (state, getters) => getters.availableReminders.length,
    hasAvailableReminders: (state, getters) => getters.availableRemindersCount > 0,

    blockedUser: (state, getters, rootState, rootGetters) => {
      const roles = rootGetters['auth/roles'];
      const isSeller = roles.some((role) => role.name === 'Seller');

      if(isSeller) return false;

      return getters.availableReminders.length > 0;
    }
  },

  mutations: {
    SET_REMINDERS(state, reminders) {
      const remindersWidthNoReels = reminders.filter(r => r.isReel === false);
      state.reminders = remindersWidthNoReels;
    },

    UPDATE_REMINDER(state, reminderUpdated) {
      const reminderIndex = state.reminders.findIndex(
        (r) => r.id === reminderUpdated.id
      );

      if (reminderIndex >= 0)
        state.reminders.splice(reminderIndex, 1, reminderUpdated);
    },

    SET_REMINDERS_SELECTED(state, reminders) {
      state.selectedReminders = reminders;
    },

    REMOVE_ITEM_SELCETED(state, reminder) {
      const reminderIndex = state.selectedReminders.findIndex((r) => r.id === reminder.id);

      if (reminderIndex >= 0) state.selectedReminders.splice(reminderIndex, 1);
    },

    UDPATE_ITEM_SELCETED(state, reminder) {
      const reminderIndex = state.selectedReminders.findIndex((r) => r.id === reminder.id);

      if (reminderIndex >= 0) state.selectedReminders.splice(reminderIndex, 1, reminder);
    },

    SET_LOADING(state, loading) {
      state.loading = loading;
    },

    SET_ERROR(state, error = null) {
      state.error = error;
    },
  },

  actions: {
    async loadReminders({ commit, rootGetters, dispatch, state }) {
      const userId = rootGetters['auth/userCode'];
      const hasRoles = await dispatch('auth/hasAnyRole', state.roles, { root: true });
      
      if (userId && hasRoles) {
        commit('SET_LOADING', true);
        commit('SET_ERROR');

        const channel = rootGetters['auth/channel'];
        const isSeller = await dispatch('auth/hasAnyRole', 'Seller', { root: true });

        try {
          const response = await getBackorders(userId, channel, isSeller);
          commit('SET_REMINDERS', response);
        } catch (error) {
          commit('SET_ERROR', 'Hubo un error al recuperar los recordatorios');
        }

        commit('SET_LOADING', false);
      } else {
        commit('SET_REMINDERS', []); // If user is null reminders list is cleaned up
        commit('SET_REMINDERS_SELECTED', []);
      }
    },

    async removeReminder({ state, commit }, { reminderData, reasonsForm }) {
      const reminderIndex = state.reminders.findIndex(
        (r) => r.id === reminderData.id
      );

      if (reminderIndex >= 0) {
        await deleteBackorder(
          reminderData.customer.code,
          reminderData.id,
          reasonsForm
        );
        state.reminders.splice(reminderIndex, 1);
      }

      commit('REMOVE_ITEM_SELCETED', { id: reminderData.id }); // In case that the deleted reminder was selected
    },

    cleanReminders({ commit }) {
      commit('SET_REMINDERS', []);
      commit('SET_REMINDERS_SELECTED', []);
    },

    updateRemindersSelectionList({ commit }, newList) {
      commit('SET_REMINDERS_SELECTED', newList);
    },

    async validateItem({ getters, commit, rootGetters }, reminder) {
      if (!matchFirstReminderSelected(getters.firstReminderSelected, reminder)) {
        commit('REMOVE_ITEM_SELCETED', reminder);
      } else {
        const authUser = rootGetters['auth/user'];

        const customer = {
          customer: reminder.customer.code,
          channel: authUser.VTWEG,
          VTWEG: authUser.VTWEG,
          VKBUR: authUser.VKBUR,
          VKORG: authUser.VKORG,
        };

        try {
          if (reminder.isKit) {
            await getMaterialInfoKit(reminder.materialId, reminder.requiredQty, customer); // Just to try catch any
            // commit('UDPATE_ITEM_SELCETED', reminder);
          } else {

            const material = await getMaterialInfoPR(reminder.materialId, reminder.requiredQty, customer);
            const newMaterial = {
              ...reminder,
              reminders: material.recordatorios,
              priceList: material.precio_lista,
              discountPrice: material.precio_con_descuento,
              productPrice: material.importe_producto,
            };

            commit('UDPATE_ITEM_SELCETED', newMaterial);
          }

        } catch (error) {
          const defaultErrorMessage = 'No se pudo seleccionar el backorder';

          commit('REMOVE_ITEM_SELCETED', reminder);

          reminder.hasError = true;
          commit('UPDATE_REMINDER', reminder);

          if (error?.response?.status == 404) {
            return error.response.data?.error_code || defaultErrorMessage;
          }
          if (error?.response?.status == 400) {
            return error.response.data?.message || defaultErrorMessage;
          } else {
            return defaultErrorMessage;
          }
        }
      }

      return null;
    },

    getUserReminders: ({ getters }, userId) => {
      return getters.reminders.filter(r => r.user.code == userId);
    },

    getUserAvailableReminders: ({ getters }, userId) => {
      return getters.reminders.filter(r => r.customer.code == userId && reminderHasStock(r.status) && r.backorderProgrammedDisabled == false);
    },

  },
};

const reminderHasStock = (status) => {
  return status === 'AMARILLO' || status === 'VERDE';
}

const matchFirstReminderSelected = (firstSelectedReminder, newReminder) => {
  if (!firstSelectedReminder) return true;

  if(firstSelectedReminder.isKit && !newReminder.isKit) return false; // If the first material is KIT, the rest should be kits

  if(!firstSelectedReminder.isKit && newReminder.isKit) return false; // If the first material is not KIT, the rest should not be kits

  if (firstSelectedReminder.customer.code === newReminder.customer.code) return true; // Seller can add materials only for the same customer

  return false;
};