<template>
  <div>
    <v-container fluid class="grey lighten-5">
      <v-card
        class="mx-auto rounded-lg elevation-4 ma-3 ml-5 mr-5"
        outlined
        :style="'border: 4px solid black; background-color:transparent;'"
      >
        <v-list-item-content>
          <div class="text-general-module">
            <v-icon color="black">mdi-calendar-clock-outline</v-icon>
            Planeador IEL
          </div>
        </v-list-item-content>
      </v-card>

      <!-- Filtro Asesor-->
      <v-card class="p-5 ml-5 mr-5 rounded-lg">
        <v-row
          class="d-flex justify-content-center"
          v-if="role === 'Manager'"
        >
          <v-col xl="2" md="4" sm="8">
            <filter-asesor
              :clientes="clientes"
              :filtroPERNR="filtroPERNR"
              @changeSelectAsesor="
                (data) => {
                  selectAsesorFilter = data;
                  filtroAsesor();
                }
              "
            ></filter-asesor>
          </v-col>
        </v-row>
        <!-- End Filtro Asesor-->

        <!-- Titulo -->
        <v-row v-if="cumplimiento">
          <v-col cols="12">
            <title-planner
              :semana="selectWeek"
              :title="'Cumplimiento de la Semana'"
            ></title-planner>
          </v-col>
        </v-row>
        <!--End Titulo -->

        <!-- Cumplimiento -->
        <v-row class="d-flex justify-content-center" v-if="cumplimiento && selecAsesor.length > 0">
          <v-col xl="10" lg="12" class="text-center">
            <cumplimiento-semana              
              :cumplimientoAsesor="selecAsesor"
              :dateArray="dateArray"
            ></cumplimiento-semana>
          </v-col>
        </v-row>
        <!-- End Cumplimiento -->

        <!-- Slider Trimestres -->
        <v-row class="d-flex justify-content-center">
          <v-col xl="5" lg="8" md="12">
            <weeks
              :disableSendWeek="loader"
              @dateRange="(data) => (dateRange = data)"
              @sendWeek="sendWeek"
            ></weeks>
          </v-col>
        </v-row>
        <!-- END Slider Trimestres -->

        <!-- Resumen semana Gerente -->
        <resumen
          :semana="selectWeek"
          :clientes="ordenarClientes"
          :loader="loader"
          :filtroPERNR="filtroPERNR"
          :currentP="currentP"
          @selectCustomerResume="selectCustomerResume"
          @selectCustomerEdit="selectCustomerEdit"
          @currentPage="(data) => (this.currentP = data)"
          @confirmarPresentacion="confirmarPresentacion"
          @dateArray="(data) => (dateArray = data)"
        ></resumen>
        <!-- End Resumen Semana Gerente -->

        <!-- Model Eventos -->
        <v-dialog v-model="dialog" persistent max-width="600px">
          <v-card>
            <v-card-title class="d-flex justify-content-center">
              <span class="text-h6">
                <b v-if="!edit">Asignar</b>
                <b v-else>Editar</b>
                visita
              </span>
            </v-card-title>

            <v-card-text>
              <v-form ref="formEvent" v-model="form.valid">
                <v-container>
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        v-model="form.NAME1"
                        label="Cliente"
                        disabled
                      ></v-text-field>
                    </v-col>

                    <v-col cols="6">
                      <v-text-field
                        v-model="form.PERNR"
                        label="Asesor"
                        disabled
                      ></v-text-field>
                    </v-col>

                    <v-col cols="6">
                      <v-select
                        :items="[
                          { frecuecia: 'semanal' },
                          { frecuecia: 'quincenal' },
                          ,
                          { frecuecia: 'mensual' },
                        ]"
                        label="Frecuencia"
                        item-text="frecuecia"
                        item-value="frecuecia"
                        prepend-icon="mdi-playlist-check"
                        v-model="form.frequency"
                        chips
                        required
                        :rules="[(v) => !!v || 'Requerido']"
                      ></v-select>
                    </v-col>

                    <v-col cols="6">
                      <v-select
                        :items="[
                          { dias: 'lunes' },
                          { dias: 'martes' },
                          { dias: 'miércoles' },
                          { dias: 'jueves' },
                          { dias: 'viernes' },
                          { dias: 'sábado' },
                        ]"
                        label="Dia de la semana"
                        item-text="dias"
                        item-value="dias"
                        prepend-icon="mdi-playlist-check"
                        v-model="form.day"
                        chips
                        required
                        :rules="[(v) => !!v || 'Requerido']"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-btn
                depressed
                color="error"
                @click="finalizarEvento"
                v-show="edit && !evalInitEvent()"
              >
                Finalizar
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="cancelEvent">
                Cancelar
              </v-btn>
              <v-btn v-if="!edit" color="primary darken-1" @click="saveEvent">
                Guardar
              </v-btn>
              <div v-else>
                <v-btn
                  v-if="evalInitEvent()"
                  color="primary darken-1"
                  @click="updateEvent"
                >
                  Actualizar
                </v-btn>
              </div>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- End Model Eventos -->
      </v-card>
    </v-container>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from "vuex";
import Weeks from "./components/Weeks";
import Resumen from "./components/Resumen";
import TitlePlanner from "./components/TitlePlanner";
import FilterAsesor from "./components/FilterAsesor";
import CumplimientoSemana from "./components/CumplimientoSemana";
import axios from "@/plugins/axios";
import moment from "moment";

export default {
  components: {
    Weeks,
    Resumen,
    TitlePlanner,
    FilterAsesor,
    CumplimientoSemana
  },
  data() {
    return {
      dateRange: "",
      selectWeek: "",
      clientes: [],
      dialog: false,
      edit: false,
      loader: true,
      planner: "",
      filtroPERNR: "",
      currentP: 1,
      cumplimiento: false,
      selecAsesor: [],
      dateArray: [],
      selectAsesorFilter: "",
      form: {
        frequency: "",
        day: "",
        employee_number: "",
        KUNNR: "",
        NAME1: "",
        PERNR: "",
        status: "",
        id: "",
        planner_id: "",
        start_date: "",
        manager_number: "",
        original_asesor: "",
        original_starDate: "",
      },   
      role: this.$route.meta.role   
    };
  },
  mounted() {
    this.cumplimiento = this.role === 'Seller';
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
    ordenarClientes() { 
      const diasSemana = [
        "lunes",
        "martes",
        "miércoles",
        "jueves",
        "viernes",
        "sábado",
        "domingo",
      ];

      return this.selecAsesor.sort((a, b) => {
        const diaSemanaA =
          a.planner && a.planner.length > 0 ? a.planner[0].day : "";
        const diaSemanaB =
          b.planner && b.planner.length > 0 ? b.planner[0].day : "";

        const indiceDiaA = diasSemana.indexOf(diaSemanaA);
        const indiceDiaB = diasSemana.indexOf(diaSemanaB);

        // Si el día de la semana no está definido, se asigna un valor alto para que se coloque al final
        const ordenA = indiceDiaA !== -1 ? indiceDiaA : diasSemana.length;
        const ordenB = indiceDiaB !== -1 ? indiceDiaB : diasSemana.length;

        return ordenA - ordenB;
      });
    },
  },
  watch: {
    "form.day": function (nuevoDia, viejoDia) {
      if (nuevoDia !== viejoDia) {
        console.log("entró aqui");
        this.calcularFechaProxima();
      }
    },
    selecAsesor(newData) {
      this.validatePNC(newData);
    },
  },
  methods: {
    async getCustomers() {
      try {
        this.loader = true;
        this.clientes = [];
        this.selecAsesor = [];
        let employeeNumber = this.validateEmployeeNumber();        

        const params = {
          inicio: this.selectWeek.inicio,
          fin: this.selectWeek.fin,
          P_VTWEG: this.user.VTWEG,
          P_VKORG: this.user.VKORG,
          employee_number: employeeNumber
        };

        let ruta = (this.role === 'Seller') ? 'planeador-asesor' : 'planeador';
        const response = await axios.get(ruta, { params });

        this.clientes = response.data.data;
        this.loader = false;

        if (this.role === 'Seller') {
          this.selecAsesor = this.clientes;
        } else {
          this.filtroAsesor();
        }        
      } catch (error) {
        console.error("Error al obtener los clientes:", error);
      }
    },
    async saveEvent() {
      try {
        let response = await axios.post(`/planeador`, this.form);
        this.dialog = false;

        //Buscamos al cliente para cambiar los valores
        const cliente = this.clientes.find(
          (cliente) => cliente.id === this.form.id
        );
        if (cliente) {
          cliente.planner.unshift(response.data);

          if (cliente.PERNR !== this.form.employee_number) {
            cliente.PERNR = this.form.employee_number;
            cliente.assigned_to = this.form.employee_number;
          }
        }

        this.resetForm();
        this.$refs.formEvent.resetValidation();
      } catch (error) {
        console.error("Error al obtener los clientes:", error);
      }
    },
    async updateEvent() {
      try {
        await axios.put(`/planeador/${this.form.planner_id}`, this.form);
        this.dialog = false;

        //Buscamos al cliente para cambiar los valores
        const cliente = this.clientes.find(
          (cliente) => cliente.id === this.form.id
        );
        if (cliente) {
          const planner = cliente.planner[0];
          planner.day = this.form.day;
          planner.frequency = this.form.frequency;
          planner.start_date = this.form.start_date;
        }

        this.resetForm();
        this.$refs.formEvent.resetValidation();
      } catch (error) {
        console.error("Error al obtener los clientes:", error);
      }
    },
    async finalizarEvento() {
      try {
        await axios.delete(`/planeador/${this.form.planner_id}`);

        //Buscamos al cliente para cambiar los valores
        const cliente = this.clientes.find(
          (cliente) => cliente.id === this.form.id
        );
        if (cliente) {
          const planner = cliente.planner[0];
          planner.finish_date = moment();
          planner.status = 0;
        }

        this.dialog = false;
        this.resetForm();
        this.$refs.formEvent.resetValidation();
      } catch (error) {
        console.error("Error al obtener los clientes:", error);
      }
    },
    async confirmarPresentacion(data) {
      try {
        const { item, date } = data;
        await axios.post(`planeador-asesor/presentation`, {
          date_presentation: date,
          id: item.planner[0].id,
        });

        //Buscamos al cliente para cambiar los valores
        const cliente = this.clientes.find((cliente) => cliente.id === item.id);
        if (cliente) {
          cliente.planner[0].presentation = {
            date_presentation: date,
          };
        }
      } catch (error) {
        console.error("Error al obtener los clientes:", error);
      }
    },
    sendWeek(value) {
      if(this.role === 'Manager'){
        this.cumplimiento = false;
      }
      this.selectWeek = value;
      this.getCustomers();
    },
    selectCustomerResume(values) {
      this.dialog = true;
      this.edit = false;

      this.form.employee_number = values.PERNR;
      this.form.NAME1 = values.NAME1;
      this.form.PERNR = values.PERNR;
      this.form.KUNNR = values.KUNNR;
      this.form.id = values.id;
      this.form.manager_number = this.user.id;
      this.form.original_asesor = values.PERNR;
    },
    selectCustomerEdit(values) {
      this.dialog = true;
      this.edit = true;

      this.form.employee_number = values.planner[0].PERNR;
      this.form.NAME1 = values.NAME1;
      this.form.PERNR = values.PERNR;
      this.form.KUNNR = values.KUNNR;
      this.form.frequency = values.planner[0].frequency;
      this.form.day = values.planner[0].day;
      this.form.employee_number = values.planner[0].employee_number;
      this.form.status = values.planner[0].status;
      this.form.planner_id = values.planner[0].id;
      this.form.id = values.id;
      this.form.manager_number = this.user.id;
      this.form.original_starDate = values.planner[0].start_date;
    },
    cancelEvent() {
      this.dialog = false;
      this.resetForm();
      this.$refs.formEvent.resetValidation();
    },
    resetForm() {
      this.form = {
        frequency: "",
        day: "",
        employee_number: "",
        KUNNR: "",
        NAME1: "",
        PERNR: "",
        status: "",
        id: "",
        planner_id: "",
        start_date: "",
        original_asesor: "",
        original_starDate: "",
      };
    },
    calcularFechaProxima() {
      const hoy = moment();
      const ultimoDiaMes = moment().endOf("month").date();
      const tresDiasAntes = ultimoDiaMes - 2; // Calculamos el tercer día antes del último día del mes

      const diaSemana = this.form.day;
      const diaSemanaActual = hoy.day();
      // Obtener el número del día de la semana seleccionado
      const diaSemanaSeleccionado = moment().day(this.form.day).day();

      // Calcular la cantidad de días que faltan para el próximo día seleccionado
      let diasFaltantes;
      if (hoy.date() >= tresDiasAntes && hoy.date() <= ultimoDiaMes) {
        diasFaltantes = 7 - diaSemanaActual + diaSemanaSeleccionado;
      } else {
        //Si el día seleccionado no ha pasado se asigna, si ya pasó se asigna la próxima semana
        diaSemanaSeleccionado > diaSemanaActual
          ? (diasFaltantes = diaSemanaSeleccionado - diaSemanaActual)
          : (diasFaltantes = 7 - diaSemanaActual + diaSemanaSeleccionado);
      }

      this.form.start_date = moment()
        .add(diasFaltantes, "days")
        .format("YYYY-MM-DD");
    },
    ultimosDiasDelMes() {
      const hoy = moment();
      const ultimoDiaDelMes = moment().endOf("month");
      const tresDiasAntesDelUltimoDia = ultimoDiaDelMes
        .clone()
        .subtract(3, "days"); //3 ultimos días del mes

      return hoy.isBetween(
        tresDiasAntesDelUltimoDia,
        ultimoDiaDelMes,
        null,
        "[]"
      );
    },
    filtroAsesor() {
      this.currentP = 1;
      if (this.selectAsesorFilter == "") {
        this.cumplimiento = false;
        this.selecAsesor = this.clientes;
      } else {
        const cliente = this.clientes.filter(
          (cliente) => cliente.PERNR === this.selectAsesorFilter
        );
        if (cliente) {
          this.selecAsesor = cliente;
        }
        this.cumplimiento = true;
      }
    },
    validatePNC(newData) {
      newData.forEach((item) => {
        const fechaActual = moment();
        let antiguedad = fechaActual.diff(
          moment(item.DET_NPRODUCTO.ERDAT).format("YYYY-MM-DD"),
          "month"
        );
        item.DET_NPRODUCTO.evalAntiguedad = antiguedad >= 6 ?? false;
        item.DET_NPRODUCTO.evalPonderado =
          parseInt(item.DET_NPRODUCTO.PPOND) <= 36 ?? false;
        item.DET_NPRODUCTO.evalRenglonaje =
          parseInt(item.DET_NPRODUCTO.RENGA) >= 20 ?? false;
        item.DET_NPRODUCTO.evalPedidoPPN =
          parseInt(item.DET_NPRODUCTO.PPN) == 0 ?? false;     
        item.DET_NPRODUCTO.evalVSALD =
          parseInt(item.DET_NPRODUCTO.VSALD) == 0 ?? false;   

        item.PNC = false;
        if (
          item.DET_NPRODUCTO.evalAntiguedad &&
          item.DET_NPRODUCTO.evalPonderado &&
          item.DET_NPRODUCTO.evalRenglonaje &&
          item.DET_NPRODUCTO.evalPedidoPPN &&
          item.DET_NPRODUCTO.evalVSALD
        ) {
          item.PNC = true;
        }
      });
    },    
    evalInitEvent() {
      let today = moment();
      let startDate = moment(this.form.original_starDate, "YYYY-MM-DD");
      return today.isBefore(startDate);
    },
    validateEmployeeNumber(){
      let code = '';
      if(this.role === 'Manager' && this.$getLocalStorageManager()){
        code = this.$getLocalStorageManager();
      }else if(this.role === 'Seller' && this.$getLocalStorageSeller()){
        code = this.$getLocalStorageSeller();
      }else{
        code = this.user.employee_number;
      }
      return code;
    }
  },
};
</script>
