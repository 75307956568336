<template>
  <v-container fluid class="grey lighten-5">
    <p></p>
    <v-card class="mx-auto rounded-lg elevation-4 ma-3 ml-5 mr-5" outlined
      :style="'border: 4px solid black; background-color:transparent;' ">
      <v-list-item-content>
        <div class="text-general-module">
          <v-icon color="black">mdi-shield-account</v-icon>
          Estado de Solicitud Alta Cliente
        </div>
      </v-list-item-content>
    </v-card>
    <!--<v-alert
      icon="mdi-alert-octagon"
      prominent
      type="info"
      border="right"
      style="margin-bottom: 5px"
    >
      <v-row align="center">
        <v-col class="grow">
          <span style="font-weight: 700; font-size: 18px"
            >Estimado usuario su solicitud de alta fue enviada correctamente; en
            un lapso no mayor a 24 hrs. recibirá respuesta.{{id}}</span
          >
        </v-col>
        <v-col class="shrink">
          <v-btn>Take action</v-btn>
        </v-col> 
      </v-row>
    </v-alert>-->
    <v-card class="mx-auto rounded-xl elevation-5 ma-4 ml-4 mr-4" outlined>
      <v-card-text>
        <v-timeline align-top dense>
          <v-timeline-item 
              v-for="firsts in first"
              v-bind:key="firsts" 
              v-bind:value="firsts"
               fill-dot 
               class="mb-6 mr-10" 
               color="#00b347" 
               large>
            <template v-slot:icon>
              <span class="white--text" style="font-size: 20px;">{{firsts.word}}</span>
            </template>
            <v-row >
              <v-col cols="7" class="text-left">
                {{ firsts.observations}} 
                <br>
                <span class="text-timeline-user">{{firsts.user}}</span>
              </v-col>
              <v-col class="text-right" cols="5">
                <p>{{ firsts.date}} </p>
                <v-btn depressed color="primary" @click="$router.push({ name: 'CustomerRegistration' })">Ir</v-btn>
              </v-col>
            </v-row>

          </v-timeline-item>

          <v-timeline-item   
              v-for="coments in data"
              v-bind:key="coments" 
              v-bind:value="coments"
              class="mb-4 mr-10" 
              color="blue" 
              icon-color="grey lighten-2" 
              small>
              <template v-slot:icon>
                <span class="white--text" style="font-size: 12px;">{{coments.word}}</span>
              </template>
            <v-row justify="space-between">
              <v-col cols="7" class="text-left">
                {{ coments.observations}} 
                <br>
                <span class="text-timeline-user">{{coments.user}}</span>
              </v-col>
              <v-col class="text-right" cols="5">
                {{ coments.date}} 
              </v-col>
            </v-row>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>

import axios from "@/plugins/axios";
import { mapGetters } from "vuex";

export default {
  name: 'CustomerStatus',
  data() {
    return {
      data: null,
      first: null
    }
  },
  mounted() {
    console.log('Params: ', this.$route.query);
    console.log('Params: ', this.$route.query.data);
    let self = this;
    self.getObservationsLasts(this.$route.query.data);
    self.getObservationsFisrt(this.$route.query.data);
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
  },
  methods: {
    getObservationsLasts(id) {
      axios.post('getObservationsLasts', { id: id }, {
        headers: {
          'Content-Type': 'application/json',
        }
      }).then((response) => {
        this.data = response.data;
        console.log(response.data)

      }).catch((err) => {
        console.log(err)
      })

    },
    getObservationsFisrt(id) {
      axios.post('getObservationsFisrt', { id: id }, {
        headers: {
          'Content-Type': 'application/json',
        }
      }).then((response) => {
        this.first = response.data;
        console.log(response.data)

      }).catch((err) => {
        console.log(err)
      })

    }
  },

}
</script>