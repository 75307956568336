<template>
    <div >
      <v-dialog v-model="showDialog" :max-width="1024"> 
        <v-card > 
          <v-card-text>

        
          <div class="text-left pl-3"  style="background-color: #000; color:#fff">
            {{ title }}           
          </div>
          <div class="text-right mt-n6 " style="background-color: #000;"  >
            <v-icon size="20" color="white" @click="showDialog = false" :disabled="loading">mdi-close-circle</v-icon>
          </div>
          <v-row class="ml-1 mr-1 mt-1" v-if="!loading">
            <v-col cols="5">
              <v-simple-table dense>
            <template v-slot:default>
              <thead  class="custom-table-bordered">
                <tr>
                  <td class="bg-gray">Facturacion total</td>
                  <td class="text-black">{{formatNumber(products.FACTOT,'$',0)}}</td> 
                </tr>
                <tr>
                  <td>Facturación nuevos</td> 
                  <td  class="text-black bg-gray">{{formatNumber(products.FACNUE,'$',0)}}</td>
                </tr>
                <tr>
                  <td class="bg-gray">%&nbsp;de&nbsp;Venta&nbsp;de&nbsp;Prod&nbsp;Nuevos</td>
                  <td class="text-black ">{{formatFloat(products.PORNUE)}}%</td> 
                </tr> 
              </thead> 
            </template>
          </v-simple-table>
            </v-col>
            <v-col cols="12 mt-n6">
              <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr class="table-heading">
                  <th colspan="8" class="text-center text-dark "><b>Facturación de productos Nuevos por División</b></th> 
                </tr>
                <tr class="custom-tr-no-bordered">
                  <th colspan="2" class="text-center text-dark "><b>Familia</b></th>
                  <th class="text-center text-dark " v-for="(item,index) in dates" :key="index"><b>{{item.date}}</b></th>  
                </tr>
              </thead>
              <tbody style="border-width: 1px;">
                <tr class="custom-tr-no-bordered"  v-for="(item,index) in products.PN_DIVISIONES.PN_DIVISION" :key="index">
                  <td class="border-bottom-0">{{ index }}</td>
                  <td class="border-bottom-0">{{item.DIVI}}</td>
                  <td class="border-bottom-0">{{formatNumber(item.NETWR1,'$',0)}}</td>
                  <td class="border-bottom-0">{{formatNumber(item.NETWR1_1,'$',0)}} </td>
                  <td class="border-bottom-0">{{formatNumber(item.NETWR1_2,'$',0)}} </td>
                  <td class="border-bottom-0">{{formatNumber(item.NETWR1_3,'$',0)}}</td>
                  <td class="border-bottom-0">{{formatNumber(item.NETWR1_4,'$',0)}}</td>
                  <td class="border-bottom-0">{{formatNumber(item.NETWR1_5,'$',0)}}</td>
                </tr>  
              </tbody>
            </template>
          </v-simple-table>
            </v-col>
          </v-row> 
          <v-row v-else class="ma-2">
            <v-progress-linear class=" " indeterminate color="teal"/>  
          </v-row>
        </v-card-text>
        </v-card>
      </v-dialog>
    </div> 
</template>
<script>
import axios from '@/plugins/axios.js'
import numbersFormats from '@/mixins/numbersFormats';
import MixinService from '@/mixins/service';

export default({
    name:'NewProducts',
    mixins:[numbersFormats,MixinService],
    props:{  
      chnl: {
          type: String, 
          required:true,
        }, 
        period: {
          type: String,  
          required:true,
        }, 
        title: {
          type: String,
          default:  'Facturación de productos nuevos'
        }, 
        value: { 
            type: Boolean,
            default: false
          },
        isGenN:{
          Boolean
        },
        regionalManager:{
          default:0,
        }
      },   
    data: () => ({  
        loading:true, 
        products:[],
        dates:[],
    }), 
    methods: {
      async loadProducts(){  
        this.loading=true;
        try { 
          var params = 
          { 
             'channel': this.chnl,
            'period':this.period,
             'genN':this.isGenN, 
          };
          if (this.regionalManager) params.rgManager = this.regionalManager
          const response = await axios.get("daily-director/detail-new-products", {
                params:params
              });
          this.products = response.data; 
        } catch (error) {
          this.error = "No se pudo obtener la información";
          this.loading=false;
        } 
        this.loading=false;
    }, 
    },
    computed: {
      showDialog: { 
          set(value) { 
            this.$emit('input', value);
          },
          get(){
            return this.value
          }
        }
  },
  watch:{
    showDialog(value){
     if (value) this.loadProducts(this.period)
      }, 
  },
  created(){
    this.dates = this.calcularUltimosMeses(6)
  }

})
</script>
<style scoped>
 
.table-heading {
  font-weight: bold;
  font-size: 0.75rem;
  background: rgb(245, 245, 245);
  color: #000;
  border-width: 1px 1px 3px 1px;
  border-color: rgb(228, 231, 234);
  border-bottom-color: rgb(112, 112, 112);
}
.custom-table-bordered  {
  border-width: 2.5px;
  border-color: rgb(224, 228, 232);
}
.custom-table-bordered>tr>td {
  border-width: 2.5px;
  border-color: rgb(224, 228, 232);
}

.custom-table-bordered>th {
  border-width: 2.5px;
  border-color: rgb(224, 228, 232);
}

.table-heading tr {
  color: #000;
}

th.boder-x-none {
  border-right: none;
  border-left: none;
}

th.boder-l-none {
  border-left: none;
}
  
.border-bottom-0{   
  border-bottom: 0px;
}
.bg-gray { 
  background-color: rgb(245,245,245);
}
.custom-tr-no-bordered{   
  border-width: 0px 1px 0px 1px;
    border-color: lightgray;
} 
 
.no-border{ 
  border-width:0px;
} 
</style>