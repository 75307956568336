<template>
  <v-card>
    <v-card-title class="headline">Eliminar Usuario</v-card-title>
    <v-card-text style="text-align: left"
      >¿Está seguro de que desea eliminar este usuario?</v-card-text
    >
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="red darken-1"
        text
        :disabled="loading"
        @click="cancelDelete"
      >
        Cancelar
      </v-btn>
      <v-btn color="success darken-1" :disabled="loading" @click="deleteItem">
        Eliminar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from "@/plugins/axios";
export default {
  props: ["userId", "onCancel"],
  data() {
    return {
      data: null,
      loading: false,
    };
  },
  methods: {
    cancelDelete() {
      this.onCancel();
    },
    deleteItem() {
      axios
        .post(`/deleteUser`, {
          id: this.userId,
        })
        .then((response) => {
          if (response.status == 200) {
            this.$emit("userDeleted");
            this.cancelDelete();
            return this.$toast.success(response.data.message, {
              timeout: 3000,
            });
          }
        })
        .catch((error) => {
          return this.$toast.error(error.response.data.message, {
            timeout: 3000,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
