<template>
        <v-dialog v-model="showDialog" width="auto" :persistent="loading"> 
                  <v-card class="pb-4">   
                    <div class="d-flex flex-wrap" style="background-color: #000; color:#fff">
                        <div class="text-left ml-5 mt-1 mr-5" >{{title}}</div> 
                            <v-btn v-if="!loading" icon @click="showDialog=false" class="ml-auto">
                                <v-icon size="20" color="#fff" >mdi-close-circle</v-icon>
                            </v-btn> 
                      </div>  
                    <v-simple-table dense class="my-table" v-if="!loading">
                      <template v-slot:default>
                        <thead> 
                          <tr  class="custom-table-bordered text-center">
                            <th>Número de clientes con pago</th>
                            <th>Clientes con pagos IEL</th> 
                            <th>% clientes con pagos IEL</th> 
                          </tr>
                        </thead>
                        <tbody class="custom-table-bordered">               
                          <tr class=" text-center">
                            <td> {{ formatNumber(customersViaIELData.CTEPAG,"",2) }}</td>
                            <td> {{ formatNumber(customersViaIELData.PAGIEL,"",2) }}</td> 
                            <td> {{ formatFloat(customersViaIELData.PCTEPAG) }}%</td> 
                          </tr>                   
                        </tbody>
                      </template>
                    </v-simple-table> 
                      <template v-else>
                        <v-card-actions> 
                            <v-progress-linear class="w-100" indeterminate color="teal" ></v-progress-linear> 
                        </v-card-actions> 
                      </template> 
                  </v-card>
              </v-dialog>
</template>

<script>
import numbersFormats from "@/mixins/numbersFormats";
import axios from '@/plugins/axios.js';

export default({ 
    name:'CustomersPaymentIEL',
    mixins:[numbersFormats], 
    props:{  
        title: {
          type: String,
          default:  ''
          
        }, 
        value: { 
            type: Boolean,
            default: false
          }, 
        managerCode:{ 
          required:true, 
        },
        employeeChannel:{ 
          required:true, 
          type: String
        },
        period:{
          required:true,
          type: String
        }
        },  
    data: () => ({ 
        loading:true, 
        customersViaIELData:[],
    }),  
    mounted() {
      
    },
    methods:{ 
      async loadPaments(period){
        this.loading=true; 
        try { 
          const response = await axios.post('/manager/home/customers-via-IEL-details',{
            manager: this.managerCode,
            period:period,
            channel:this.employeeChannel
            });
          this.customersViaIELData = response.data;
          this.loading = false;
        } catch (error) {
          console.error(error);
          this.loading = false;
        }
        },
    },
    computed: {
    showDialog: { 
        set(value) { 
          this.$emit('input', value);
        },
        get(){
          return this.value
        }
      }
  },
  watch:{
    showDialog(value){
      if (value) this.loadPaments(this.period)
      }, 
  }
})
</script>