<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="!noCloseOnSelection"
      transition="scale-transition"
      min-width="auto"
      top
    >
      <template v-slot:activator="{}">
        <v-switch
          v-model="switchOn"
          color="grey darken-3"
          :label="label"
          :dark="!light"
          class="py-0 mt-0"
          :class="messages !== null ? 'mb-0' : 'mb-n5'"
          :messages="messages"
          :disabled="disabled"
        />
      </template>
      <v-date-picker
        v-model="date"
        :min-date="minDate"
        @change="emitDateSelection"
        locale="es"
        header-color="red"
        :header-date-format="dateFormat"
        elevation="10"
        :width="200"
      />
    </v-menu>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'SwitchDatePicker',

  props: {
    value: {
      type: String,
      required: false,
    },

    label: {
      type: String,
      required: false,
    },

    dateFormat: {
      type: String,
      default: 'MM-DD-YYYY',
    },

    selectedDateFormatText: {
      type: String,
      default: 'dddd DD [de] MMMM YYYY',
    },

    selectedDatePrefix: {
      type: String,
      default: '',
    },

    minDate: {
      type: Date,
      default: () => new Date(),
    },

    noCloseOnSelection: Boolean,
    hideDateSelectionText: Boolean,
    light: Boolean,
    disabled: Boolean,
  },

  data() {
    return {
      menu: false,
      switchOn: false,
    };
  },

  methods: {
    emitDateSelection(date) {
      this.menu = false;
      this.$emit('onSelect', date);
    },
  },

  computed: {
    date: {
      set(newValue) {
        if (newValue !== null) {
          const date = moment(newValue).format(this.dateFormat);
          this.$emit('input', date);
        } else this.$emit('input', null);
      },

      get() {
        if (this.value !== null) {
          const date = moment(this.value, this.dateFormat, true).format('YYYY-MM-DD');
          return date;
        }

        return null;
      },
    },

    formatedDate() {
      if (this.date) {
        const date = moment(this.date);
        return `${this.selectedDatePrefix} ${date.format(
          this.selectedDateFormatText
        )}`;
      }

      return null;
    },

    messages() {
      return this.date !== null && !this.hideDateSelectionText
        ? [this.formatedDate]
        : null;
    },
  },

  watch: {
    switchOn(newVal) {
      // If switch state is false, date is reseted
      if (!newVal) this.date = null;

      this.menu = newVal; // Menu depends on switch switch state
    },

    menu(newVal) {
      // When user closes the menu a date has to be selected if not, switchs turns off
      if (!newVal && this.date === null && this.switchOn) this.switchOn = false;
    },
  },
};
</script>
