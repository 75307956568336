<!-- Colors are duplicated in class and style this way, they can pass a vuetify color or a native color in hex or rgb -->
<template>
  <div 
    class="pl-4 pr-2 d-flex align-center" 
    :class="[color]" 
    :style="{ backgroundColor: color }"
  >
    <div 
      v-if="title"
      class="font-weight-bold" 
      :class="[`${textColor}--text`]" 
      :style="{ color: textColor }"
    >
      {{title}}
    </div>
    <v-spacer />
    <v-btn v-if="!hideClose" :disabled="disabled" :dark="disabled" :color="buttonColor" @click="$emit('on-close')" icon>
      <v-icon>
        mdi-close-circle
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'ModalBar',

  props: {
    title: {
      type: String,
      default: null,
    },

    color: {
      type: String,
      default: 'black',
    },

    textColor: {
      type: String,
      default: 'white',
    },

    buttonColor: {
      type: String,
      default: 'white',
    },

    disabled: Boolean,
    hideClose: Boolean,
  }
}
</script>