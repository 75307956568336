<template>
    <div>
        <div class="text-left pl-3 pr-10" style="background-color: #000; color:#fff">
           Clientes con compra semanal
          </div>
          <div class="text-right mt-n6 mr-1" style="background-color: #000; color:#fff">
            <v-icon size="20" color="#fff" @click="closeModal()">mdi-close-circle</v-icon>
          </div>
          <v-row class="ma-1 "> 
            <v-col cols="12">
              <v-simple-table dense class="my-table">
            <template v-slot:default> 
              <tbody class="custom-table-bordered" v-if="!loading">        
                <tr :class="' text-center' " v-for="(item,index) in customers.WEEKS" :key="index">
                  <template v-if="index==0">
                    <td :rowspan="customers.MAXWEEK" class="font-weight-bold text-black"> <span class="text-capitalize"> {{period.date}} </span> <br> Promedio:&nbsp;{{ number_format(customers.AVERAGE,false) }}</td>   
                  </template>
                  <td>{{item.TITLE}}</td> 
                  <td>{{item.DATE}}</td> 
                  <td>{{item.CUSTOMERS}}</td> 
                </tr>           
                <tr class=" text-left bg-gray">
                  <td class="font-weight-bold text-black">Promedio total</td>  
                  <td colspan="2" class="text-center" style="border-width:0px;"></td>  
                  <td class="text-center no-border font-weight-bold text-black" style="border-width:0px;">{{ number_format(customers.AVERAGE,false) }}</td>  
                </tr>
              </tbody> 
              <tbody v-else>
                <tr>
                  <td colspan="4">
                    <v-progress-linear indeterminate color="cyan"  ></v-progress-linear> 
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
            </v-col>
          </v-row> 
    </div> 
</template>
<script>
import axios from "@/plugins/axios";
import moment from 'moment';
import MixinHelpers from '@/mixins/helpers';
export default ({
    props: ['manager', 'managerData','period'],
    mixins: [MixinHelpers], 
    name:'CustomersBuyWeekly',
    data: () => ({ 
        customers:{},
        loading:true, 
    }),
    watch:{
      loading(value){
        this.$emit('loadingCustomerBuyWeekly',value)
      }
    },
    mounted(){
      this.loadCustomers()
    },
    computed:{
      today(){ 
      return moment().format('MMM/YYYY')
      },

    },
    methods: {
    closeModal(){ 
        this.$emit('closeModal')
    },
  
    loadCustomers(){  
      this.loading=true;
      var month = this.period.month
        if (month < 10) { month = `0${month}`}
        const period = `${this.period.year}${month}`
        var params ={ 
          manager:this.managerData.PERNR, 
          period: period,
          channel: this.managerData.VTWEG
        }  
        console.log(params)
      axios.post('/manager/home/weekly-shopping',params).then((res) => { 
          this.customers = res.data;
          this.loading = false;
      })
      .catch((err) => { 
            console.log(err);
            this.loading = false;
          }) ;  
    }

  }, 
})
</script>
<style scoped> 
   
.custom-table-bordered  {
  border-width: 2.5px;
  border-color: rgb(224, 228, 232);
}
.custom-table-bordered>tr>td {
  border-width: 2.5px;
  border-color: rgb(224, 228, 232);
}

.custom-table-bordered>th {
  border-width: 2.5px;
  border-color: rgb(224, 228, 232);
}   
  
.bg-gray {
  
  background-color: rgb(245,245,245);
}    
.no-border{ 
  border-width:0px;
}
.bg-yellow{
  background-color:rgb(255,193,7)
}
</style>