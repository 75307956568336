<template>
    <div class="seller--card ">
        <v-card class="mt-5 rounded-xl pb-8 pb-sm-1 pt-8 pa-2 card-border-red pb-md-11 " style="box-shadow: -1px 1px 7px 0px rgba(224,37,37,0.45);-webkit-box-shadow: -1px 1px 7px 0px rgba(224,37,37,0.45); -moz-box-shadow: -1px 1px 7px 0px rgba(224,37,37,0.45);">
          <v-row>
            <v-col cols="7" sm="8" class="text-right  pb-0 pt-0">
                <p class="card-p"> 
                  
                    <OrdersSchedules v-if="orderStats && orderStats.pedidosMes" :orders="orderStats.pedidosMes" :title="'Mes'"></OrdersSchedules>
                    <OrdersSchedules v-if="orderStats && orderStats.pedidosMes" :orders="orderStats.pedidoSemana" :title="'Semana'"></OrdersSchedules>  
                
                    <b
                    class="text-subtitle-2 font-weight-bold ">{{seller.VTWEG=='PR'?'Of. Vta':'Zona'}} ({{this.cardSeller.zona}})</b></p>
                <p class="card-p">{{this.cardSeller.detalle_zona}}</p>             
            </v-col>
            <v-col cols="4" sm="2" class="text-left pb-0 mb-0 mb-sm-5 mb-md-16 pt-0">
              <TrafficLight :seller="seller" :cancelToken="CancelToken"></TrafficLight> 
            </v-col>
            <v-col cols="12" class="text-left pb-0 pt-0">
              <p class="card-p font-weight-medium">{{this.seller.VORNA}} {{this.seller.NACHN}} ({{this.seller.PERNR}})</p>
              <change-password-control 
                class="mb-4"
              />
            </v-col>

            <v-col cols="5" sm="6" md="5" lg="5" class="text-left mt-n4">
              <p class="card-p mb-3 text-subtitle-2"><b>Antiguedad:</b></p>
              <p class="card-p text-body-2 mb-0">IUSA: {{this.cardSeller.antiguedad_iusa}} Meses</p>
              <p class="card-p text-body-2 mb-0">ZONA: {{this.cardSeller.antiguedad_zona}} Meses</p>
            </v-col>
            <v-col cols="7" sm="12" md="7" lg="7" class="text-left text-sm-center mt-n5 mt-md-n2">
              <v-tooltip bottom class="mt-2"> 
                    <template v-slot:activator="{ on, attrs }"> 
                      <a @click="goTo('Planeador')">
                        <v-icon color="#0069D9" v-bind="attrs" v-on="on" alt="" >mdi-calendar-check-outline</v-icon>
                      </a>
                      </template>
                    <span>Programación del vendedor</span>
              </v-tooltip>
              <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">                         
                    <a href="https://iusaenlinea.com/multimedia-video" target="_blank">  
                      <img  class="ml-1 mr-1" v-bind="attrs" v-on="on" src="/img/icon-metro-video-camera.svg" width="21px" alt=""> 
                    </a>
                    </template>
                    <span>Videoteca</span>
              </v-tooltip>
              <v-tooltip bottom>                    
                    <template v-slot:activator="{ on, attrs }"> 
                      <a href="#" @click.prevent="handleFirstLinkClick">
                        <img  v-bind="attrs" v-on="on" src="/img/ico_capacita.svg" width="38px" alt=""> 
                      </a>
                    </template>
                    <span>IUSA Capacita</span>
              </v-tooltip>        
              <v-row v-if="loadSellerDetails">
                <v-col>                  
                    <v-progress-circular
                        indeterminate
                        color="primary"
                        class="mx-auto"
                      ></v-progress-circular>
                </v-col>
              </v-row>
              <v-row v-else class="mt-1">                
                <v-col cols="6" sm="6"   lg="6"> 
                  <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs"
                            v-on="on" color="green">mdi-account-supervisor-circle</v-icon> 
                        </template>
                        <span>Población</span>
                  </v-tooltip>  
                  <p class="text-body-2" v-if="!editHab" @click="evalFactAnt">{{number_citizen | removeDecimals }} H.</p>
                  <div v-else>
                    <div class="text-left" style="font-size: 12px">Editar</div>
                    <div class="d-flex align-items-center">
                        <v-text-field                      
                          hide-details="auto"
                          v-model="number_citizen"
                          solo
                          style="width:100%; min-width:90px"
                          @input="filterNumber(number_citizen)"
                        ></v-text-field>
                        <v-icon class="ml-1" @click="updateSellerDetails">mdi mdi-send-circle</v-icon>
                    </div>
                  </div>
                </v-col>
                <v-col cols="6" sm="5" lg="6">                  
                  <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs"
                            v-on="on" color="blue">mdi-account-multiple-check</v-icon> 
                        </template>
                        <span>Venta por habitante</span>
                  </v-tooltip>   
                  <p class="text-body-2 ">{{billing_per_citizen | formatCurrency}} $/H</p>
                </v-col>
              </v-row>  
            </v-col> 
          </v-row> 
        </v-card>
    </div>
</template>

<script> 
import { mapGetters } from "vuex";
import TrafficLight from '/src/components/TrafficLight.vue';
import axios from "@/plugins/axios";
import moment from "moment";
import OrdersSchedules from "../components/OrdersSchedules.vue"
import ChangePasswordControl from "@/components/ChangePasswordControl.vue";

export default({
    name:"CustomerCard",
    props: ['cardSeller', 'CancelToken','seller'],
    components:{
      TrafficLight,
      OrdersSchedules,
      ChangePasswordControl
    },
    data(){
      return{        
        editHab: false,   
        idSellerDetails: '',     
        number_citizen: 0,
        trafficlight: 0,
        loadSellerDetails:true,
        orderStats:['pedidoSemana','pedidosMes']
      }
    }, 
    watch:{
      seller(){
        this.getSellerDetails()
        this.getOrderStats();
      }
    },
    computed: {
      ...mapGetters({
        authenticated: "auth/authenticated",
        user: "auth/user",
      }),
      billing_per_citizen(){        
        let number_citizen = parseInt(this.number_citizen)
        if(number_citizen > 0){
          return (this.cardSeller.fact_men_anterior / number_citizen).toFixed(2)
        }else{
          return 0
        }
      },
    },
    methods:{
      goTo(routeName){
        this.$router.push({ name: routeName });
      },
      async getOrderStats (){
        try {
          let response = await axios.get('/seller/home/planner-orders-schedule',{ 
          params: {
              employee_number: this.seller.PERNR,
              inicio:  moment().startOf('month').format('YYYY-MM-DD'),
              fin:  moment().endOf('month').format('YYYY-MM-DD'),
              // channel: this.user.VTWEG
              P_VKORG: this.user.VKORG,
              P_VTWEG: this.seller.VTWEG,
            },  
            noCancelRequest:true,
            cachable:true,}
          ) 
            this.orderStats = response.data.data;
        } catch (error) {
          console.log(error)
        }
      },

      async getSellerDetails(){
        try {
          this.loadSellerDetails = true;
          let response = await axios.get('/seller/home/seller-details', {
            params:{
              employee_number: Number (this.seller.PERNR),
              channel: this.seller.VTWEG
            }, 
          })
          if(response.data){ 
            this.idSellerDetails = response.data.id
            this.number_citizen = response.data.number_citizen
           this.trafficlight = response.data.trafficlight
          }else{
            this.postSellerDetails()
          }
          this.loadSellerDetails = false;
        } catch (error) {
          console.log(error)
          this.loadSellerDetails = false;
        }
      },
      async postSellerDetails(){
        try {
          let response = await axios.post('/seller/home/store', {            
              employee_number: this.seller.PERNR,
              channel: this.seller.VTWEG
          },)
          if(response.data){
            this.idSellerDetails = response.data.id
            this.number_citizen = response.data.number_citizen
            this.trafficlight = response.data.trafficlight
          }
        } catch (error) {
          console.log(error)
        }
      },
      async updateSellerDetails(){
        try {
          if(this.number_citizen != ''){
            let response = await axios.put(`/seller/home/update/${this.seller.PERNR}`, {
                number_citizen: this.number_citizen,
                channel: this.seller.VTWEG
            })
            if(response.data){
              this.editHab = false
            }
          }
        } catch (error) {
          console.log(error)
        } 
      },
      evalFactAnt(){
        this.editHab = true
      },
      handleFirstLinkClick() {
        window.open(`https://capacita.iusa.com.mx/acceso.valida.php?u=${this.seller.PERNR}`, '_blank') 
      },
      filterNumber(number_citizen) {
        //Evitamos escribir todon lo que no sea números
        this.$nextTick(() => {
          this.number_citizen = number_citizen.replace(/\D/g, "")
        });
      }
    }    
})
</script>

<style>
  .seller--card .v-input__slot, .seller--card .v-text-field.v-text-field--solo .v-input__control{
    min-height: auto !important;
  }

  .seller--card .v-text-field input{
    font-size: 14px !important;
    padding: 2px;
  }
</style>