<template>
  <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
            <v-icon v-if="orders.hrLaboral > orders.hrNoLaboral"
                v-bind="attrs"
                v-on="on"  
                color="warning" 
                class="mr-2" 
                size="18">
                mdi-white-balance-sunny
            </v-icon>
            
            <v-icon v-else
                v-bind="attrs"
                v-on="on"  
                color="#23036A" 
                class="mr-2" 
                size="18">
                mdi-weather-night
            </v-icon>
        </template>
        <span>{{title}} actual ({{ orders.totalPedidos }} pedidos) <br>  
            {{ orders.hrLaboral }} pedidos en horario laboral ({{ orders.porcenthrLaboral | formatCurrency}}%) <br> 
            {{ orders.hrNoLaboral }} pedidos en horario no laboral ({{ orders.porcenthrNoLaboral | formatCurrency}} %) 
        </span>
    </v-tooltip>
</template>

<script>
export default {
    props: {
        orders: {
            type: Object,
            required: true
        },
        title:{
            required: true
        }
    }
}
</script>

<style>

</style>