import Vue from "vue";
import moment from "moment";

Vue.filter('formatDate', function (value) {
    if (!value) return ''
    value = value.toString()
    return moment(value).format('dd Do MMM');
})

Vue.filter('formatCurrencyMiles', function (value) {
    let formattedValue = parseFloat(value).toFixed(2); 
    formattedValue = new Intl.NumberFormat('es-MX', { currency: 'MXN' }).format(formattedValue);
    let parts = formattedValue.split(',');

    if(parts.length > 1){
        let integerPart = parts[0].replace(/,/g, ''); // Remover comas de la parte entera
        let result = parseInt(integerPart, 10);
        return result;
    }else{
        return (formattedValue / 1000).toFixed(1)
    }
});

Vue.filter('formatCurrency', function (value) {
    let formattedValue = parseFloat(value).toFixed(2);
    formattedValue = new Intl.NumberFormat('es-MX', { minimumFractionDigits: 2 }).format(formattedValue);
    return formattedValue;
});

Vue.filter('formatMonthText', function (value){
    const nombreMesAbreviado = moment().month(value - 1).format('MMM');
    return nombreMesAbreviado.charAt(0).toUpperCase() + nombreMesAbreviado.slice(1).replace(/\.$/, '');
})

Vue.filter('removeDecimals', function(value) {
    if (!value) return 0;
    const intValue = Math.floor(value);
    return intValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
});

Vue.filter('formatCustomer', function(value) {
    return (value.length >= 6) ? value.slice(-6) : value;
});