<template>
  <ul class="check-list">
    <li v-for="(c, index) in checks" :key="index" >
      <template v-if="c.state === 'done'">
        <v-icon size="10px" class="mr-2" color="success">mdi-check</v-icon>
        <small class="success--text">{{ c.text }}</small>
      </template>
      <template v-else>
        <v-icon size="10px" class="mr-2" color="error">mdi-close</v-icon>
        <small class="error--text">{{ c.text }}</small>
      </template>
    </li>
    </ul>
</template>


<script>
export default {
  name: 'PasswordInput',

  props: {
    checks: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style scoped>
.check-list {
  list-style-type: none;
  margin: 0; 
  padding: 0;
}

.check-list > li {
  height: 17px;
}
</style>