<template>
    <div style="width:100%">
      <div class="d-flex flex-wrap" style="background-color: #000; color:#fff">
        <div class="text-left ml-5 mt-1 mr-5" > Detalle de Gerencia del periodo {{ period}}</div> 
            <v-btn v-if="!loader" icon @click="closeModal()" class="ml-auto">
                <v-icon size="20" color="#fff" >mdi-close-circle</v-icon>
            </v-btn> 
      </div>  
          <v-row v-if="!loader" class="ml-1 mr-1 ">
            <v-col cols="5">
              <v-simple-table dense>
            <template v-slot:default>
              <thead  class="custom-table-bordered">
                <tr>
                  <td class="bg-gray">Facturacion total  </td>
                  <td class="text-black">${{number_format(data.FACTOT)}}</td> 
                </tr>
                <tr>
                  <td>Facturación nuevos</td> 
                  <td  class="text-black bg-gray">${{number_format(data.FACNUE)}}</td>
                </tr>
                <tr>
                  <td class="bg-gray">%&nbsp;de&nbsp;Venta&nbsp;de&nbsp;Prod&nbsp;Nuevos</td>
                  <td class="text-black ">{{number_format(data.PORNUE,false)}}%</td> 
                </tr> 
              </thead> 
            </template>
          </v-simple-table>
            </v-col>
            <v-col cols="12 mt-n6">
              <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr class="table-heading">
                  <th colspan="7" class="text-center text-dark "><b>Facturación de productos Nuevos por División</b></th> 
                </tr>
                <tr class="custom-tr-no-bordered">
                  <th colspan="2" class="text-center text-dark "><b>Familia</b></th>
                  <th class="text-center text-dark " v-for="(date,index) in date.slice(0,5)" :key="index"><b>{{date.date}}</b></th>  
                </tr>
              </thead>
              <tbody style="border-width: 1px;">
                <tr v-for="(item,index) in data.PN_DIVISIONES.PN_DIVISION" :key="index" class="custom-tr-no-bordered">
                  <td class="border-bottom-0">{{ index+1 }}</td>
                  <td class="border-bottom-0">{{item.DIVI}}</td>
                  <td class="border-bottom-0">${{number_format(item.NETWR1) }}</td>
                  <td class="border-bottom-0">${{number_format(item.NETWR1_1)}}</td>
                  <td class="border-bottom-0">${{number_format(item.NETWR1_2)}}</td>
                  <td class="border-bottom-0">${{number_format(item.NETWR1_3)}}</td>
                  <td class="border-bottom-0">${{number_format(item.NETWR1_4)}}</td>
                </tr>  
              </tbody>
            </template>
          </v-simple-table>
            </v-col>
          </v-row> 
          <v-row v-else>
            <v-col cols="12">
              <div class="d-flex d-nowrap justify-center pt-4">
                <v-progress-circular indeterminate color="primary" ></v-progress-circular>
                <small class="text-secondary mt-2"> Cargando</small>
              </div> 
            </v-col>
          </v-row>
          <div v-if="!loader" class="mt-3">
        <v-btn @click="closeModal()" dense class="success">
            Aceptar
        </v-btn>
    </div> 
    </div> 
</template>
<script>
import MixinHelpers from '@/mixins/helpers';
export default({
    name:'PaymentHistory', 
    props: ['data','date','loader','period'],  
    mixins: [MixinHelpers], 
    methods: {
        closeModal(){ 
            this.$emit('closeModal')
        }
    }
})
</script>
<style>

.card-p {
  margin: 0;
  padding: 0;
  color: #000;
}

.my-table th,
.my-table td {
  border: 0px 4px 0px 0px solid #dad9d9;
  
}

.my-table {
  width: 100%;
}
.custom-border-right{
  border-right-width: 2px;
}
.table-heading {
  font-weight: bold;
  font-size: 0.75rem;
  background: rgb(245, 245, 245);
  color: #000;
  border-width: 1px 1px 3px 1px;
  border-color: rgb(228, 231, 234);
  border-bottom-color: rgb(112, 112, 112);
}
.custom-table-bordered  {
  border-width: 2.5px;
  border-color: rgb(224, 228, 232);
}
.custom-table-bordered>tr>td {
  border-width: 2.5px;
  border-color: rgb(224, 228, 232);
}

.custom-table-bordered>th {
  border-width: 2.5px;
  border-color: rgb(224, 228, 232);
}

.table-heading tr {
  color: #000;
}

th.boder-x-none {
  border-right: none;
  border-left: none;
}

th.boder-l-none {
  border-left: none;
}
 
.custom-table-item-sm > tr > td {
  font-size: 0.8em; 
  text-align: center;
  min-width: 50px;
}  
.custom-td-header{
  font-weight: 700;
  background-color: rgb(245, 245, 245); 
} 
.custom-text-yellow{
  color:rgb(224,168,0)
}
.custom-header-title{ 
  background-color: rgb(245, 245, 245); 
}

.custom-white-table > tr > td {
  background-color: white;
  border-width: 1px;
  border-color: lightgray;
}
.custom-table-no-bordered{  
  background-color: white;

}
.border-bottom-0{   
  border-bottom: 0px;
}
.bg-gray {
  
  background-color: rgb(245,245,245);
}
.custom-tr-no-bordered{   
  border-width: 0px 1px 0px 1px;
    border-color: lightgray;
} 
.font-size-85{
  font-size:0.85em;
}
.font-size-8{ 
  font-size:0.8em;
}
.font-size-9{ 
  font-size:0.9em;
}
.no-border{ 
  border-width:0px;
}
.bg-yellow{
  background-color:rgb(255,193,7)
}
</style>