<template>
    <v-row justify="center">
        <v-dialog v-model="showDialog" hide-overlay persistent width="300">
            <v-card color="white" dark>
                <v-card-text>
                    <span style="color: #4A148C;" class="mb-7">Procesando.... ¡NO actualizar la página el pedido se duplicará!</span>
                    <v-progress-linear color="#4A148C" indeterminate rounded height="6" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
export default {
    name: "LoadingBar",
    props: {
        value: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        showDialog: {
			set (newVal) {
				this.$emit('input', newVal)
			},
			get () {
				return this.value;
			}
		}
    },
};
</script>