<template>
  <div>
    <div class="text-left pt-1 pb-1 pl-4 pr-16" style="background-color: #000; color:hsl(0, 0%, 100%)">
      Detalle de Crecimiento acumulado del cliente {{ customer }}
    </div>
    <v-simple-table dense>
      <template v-slot:default>
        <thead class="" v-if="!loading">
          <tr class="custom-table-bordered ">
            <th class="text-center text-body-1 text-dark" colspan="7"><b>Facturación actual</b></th>
          </tr>
          <tr class="custom-table-bordered ">
            <td class="text-center table-heading text-body-2 ">Mes</td>
            <td class="text-center table-heading text-body-2 ">Facturación</td>
          </tr>
        </thead>
        <tbody v-if="!loading" class="custom-table-bordered">
          <tr v-for="(item, index) in billing.FACACTUAL?.FAC_ACTUAL" :key="index">
            <td :style="index % 2 == 0 ? 'background-color: #fff;' : ''" class="table-heading small text-body-2">{{
              formatDate(item.SPMON) }}</td>
            <td :style="index % 2 == 0 ? 'background-color: #fff;' : ''" class="table-heading text-body-2">
              ${{ number_format(item.FACNETA,true) }}</td>
          </tr>
          <tr style="">
            <td colspan="2" style="background-color: #fff;-webkit-box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.75);
-moz-box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.75);
box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.75)" class="table-heading small text-body-2">
              <v-row>
                <v-col cols="6" class="pa-3">
                  <b class="ml-n9">Total=</b>
                </v-col>
                <v-col cols="6">
                  <b class="ml-n9"> {{ number_format(billing.TOTALACT,true) }}</b>
                </v-col>
              </v-row>
            </td>
          </tr>
        </tbody>
        <tbody v-if="loading">
          <tr>
            <td colspan="7"> <v-progress-linear class="mt-3" indeterminate color="teal"></v-progress-linear> <span
                class="text--disabled"> Cargando información</span></td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-simple-table dense v-if="!loading">
      <template v-slot:default>
        <thead class="">
          <tr class="custom-table-bordered ">
            <th class="text-center text-body-1 text-dark" colspan="7"><b>Facturación Anterior</b></th>
          </tr>
          <tr class="custom-table-bordered ">
            <td class="text-center table-heading text-body-2 ">Mes</td>
            <td class="text-center table-heading text-body-2 ">Facturación</td>
          </tr>
        </thead>
        <tbody  class="custom-table-bordered">
          <tr v-for="(item, index) in billing.FACANT?.FAC_ANT" :key="index">
            <td :style="index % 2 == 0 ? 'background-color: #fff;' : ''" class="table-heading small text-body-2">{{
              formatDate(item.SPMON) }}</td>
            <td :style="index % 2 == 0 ? 'background-color: #fff;' : ''" class="table-heading text-body-2">
              ${{ number_format(item.FACNETA,true) }}</td>
          </tr>
          <tr>
            <td colspan="2" style="background-color: #fff;-webkit-box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.75);
-moz-box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.75);
box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.75)" class="table-heading small text-body-2">
              <v-row>
                <v-col cols="6" class="pa-3">
                  <b class="ml-n9">Total=</b>
                </v-col>
                <v-col cols="6">
                  <b class="ml-n9"> ${{ number_format(billing.TOTALANT,true) }} </b>
                </v-col>
              </v-row>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div class="pa-3"  v-if="!loading" >
      <v-row>
        <v-col cols="6" class="pa-3">
          <b class="ml-n9">Crecimiento=</b>
        </v-col>
        <v-col cols="6">
          <b class="ml-n9"> {{ numberformat(billing.PROMCREC-100) }}% </b>
        </v-col>

      </v-row>
    </div>
  </div>
</template>
<script>

import axios from "@/plugins/axios";3
import mixinService from "@/mixins/service";
import mixinHelpers from "@/mixins/helpers";
export default ({
  watch: {
    type() {
      this.getPaymentInfo(this.customer);
    },
    quarter() {
      this.getPaymentInfo(this.customer);
    },
  },
  mixins:[mixinService,mixinHelpers],
  props: {
    customer: {
      default: 0
    },
    type: {
      default: 0
    },
    quarter: {
      default: 0
    }
  },

  name: "ComulativeClientGrowth",
  data() {
    return {
      loading: false,
      billing: [],
      months : [],
    }
  },
  created(){
   this.months = this.calcularUltimosMeses(12);
   console.log(this.months);
  },
  mounted() {
    this.getPaymentInfo(this.customer);
  },
  methods: {
    getCurrentTrim() {
      let currentMonth = new Date().getMonth() + 1;
      return Math.ceil(currentMonth / 3);
    },
    /*
    getTrim(row) {
      let currentTrim = this.getCurrentTrim();
      let currentYear = new Date().getFullYear();
      for (let index = 2; index <= row; index++) {
        currentTrim = currentTrim - 1;
        if (currentTrim == 0) {
          currentTrim = 4;
          currentYear = currentYear - 1;
        }
      }
      return {
        trim: currentTrim,
        year: currentYear,
      }
    }, */
    getPaymentInfo(customer) {
      this.loading = true;
      var url;

      var params;
      if (this.type == 1) {
        url = '/cover/cumulative-growth-indicators-details'
        params = {
          customer_id: customer,
          quarter: this.quarter,
        }
      } else {
        url = '/cover/growth-indicators-details'
        var month =[];
        switch (this.quarter) {
          case 1: month = this.months[9];break;
          case 2:  month = this.months[6]; break;
          case 3:  month = this.months[3]; break;
          default: month = this.months[0]; break;
        }
        params = {
          customer_id: customer,
          month: month.month,
          year: month.year
        }

      }
      axios
        .post(url, params)
        .then((response) => {
          if (response.data?.success == false) {
            console.log(response.data)
          } else {
            this.billing = response.data;
          }
          this.loading = false;
        }).finally(() => {
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    numberformat(number = 0) {
      let result = Number(number).toFixed(2);
      return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    formatDate(value) {
      const data = value.toString();
      const year = data.slice(0, 4);
      const month = data.slice(-2);
      return month + "/" + year;
    },
    closeModal() {
      this.$emit('closeModal', true)
    }
  },


})
</script>
<style scoped></style>