<template>
  <div>
    <v-container fluid class="white lighten-5 py-7" ref="printReport">
      <v-card class="pa-5 mb-0 ma-5 card-shadow" rounded="0" v-if="currentLevelLabel && this.currentLevelMatch(['family', 'material'])">
        <h2 class="text-h5 ma-0 text-uppercase font-weight-bold">{{ currentLevelLabel }}</h2>
      </v-card>

      <v-col md="7" class="row-containerPAnelSol">
        <v-text-field v-model="search" label="Buscar" single-line hide-details>
          <template v-slot:append>
            <div class="red-icon-background-PanelSol">
              <v-icon style="font-size: 20px;">mdi-magnify</v-icon>
            </div>
          </template>
        </v-text-field>
      </v-col>

      <v-card class="pas-5 mt-8 ma-5 card-shadow" rounded="0">
        <v-data-table
          id="borderless-table"
          dense
          :headers="headers"
          :items="sortedAndFilteredSellData"
          :loading="loading"
          loading-text="Cargando información..."
          no-data-text="No se encontró información"
          :footer-props="{ itemsPerPageText: 'Mostrar en grupo de', itemsPerPageAllText: 'Todos', pageText: '{0}-{1} de {2}' }"
        >
        </v-data-table>
        <v-row class="product-information-container">
          <v-col class="product-information-container-left">
            <p>TIPOS DE PRODUCTO T1 - Modelo activo que puede solicitarse en cualquier cantidad, bajo volumen o alto volumen. T2 - Modelo que son bajo pedido, verificar con personal de planta el tiempo de fabricación. T3 - Modelo descontinuado, únicamente esta disponible la cantidad en inventario.</p>
          </v-col>
          <v-col class="product-information-container-rigth">
            <p>TIEMPOS DE ENTREGA Para cantidades disponibles en inventario el tiempo de entrega es inmediato. En producto tipo II: Para cantidades mayores al inventario, consultar tiempo de entrega con personal de planta. En producto tipo I, considerar los siguientes tiempos de entrega para cantidades mayores al inventario: 1 a 500: Una semana después de recibir pedido. 500 a 3000: Entregas a partir de la segunda semana después de recibir pedido. Mayores a 3,000: Ocho semanas después de recibir pedido.</p>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    
    <!-- Alerts -->
    <v-snackbar
      v-model="error"
      top
      right
    >
      {{ errorMessage }}
    </v-snackbar>
  </div>
</template>

<script>
import api from '@/plugins/axios'; // Import Axios from plugin Axios
import Document from '@/mixins/document';
import Service from '@/mixins/service';
import { mapActions } from "vuex";

const mainHeaderBase = [ // for root, comertialDivision, family levels tables
  { text: 'CENTRO', value: 'WERKS' },
  { text: 'ALM.', value: 'LGORT' },
  { text: 'TIPO PROD', value: 'MVGR2' },
  { text: 'CÓDIGO', value: 'MATNR' },
  { text: 'DESCRIPCIÓN', value: 'MAKTX' },
  { text: 'Existencia', value: 'LABST' },
];

export default {
  name: 'Panelsol',

  mixins: [Document, Service],

  data() {
    return {
      headers: mainHeaderBase,
      loading: false,
      sellData: [],
      errorMessage: '',
      search: '',
    };
  },
  
  mounted() {
    this.fetchReporteSolData();
  },

  computed: {
    sortedAndFilteredSellData() {
      return this.filteredSellData
        .map(item => {
          return {
            ...item,
            MATNR: item.MATNR.replace(/^0+/, ''), // Delete 0 left
            LABST: Math.floor(item.LABST) // Delete decimals
          };
        })
        .sort((a, b) => b.LABST - a.LABST); // Sort from highest to lowest by "existence"
    },
    filteredSellData() {
      return this.sellData.filter(item =>
        Object.values(item).some(val =>
          typeof val === 'string' && val.toLowerCase().includes(this.search.toLowerCase())
        )
      );
    },
  },

  methods: {
    ...mapActions({
      sendToPDF: 'printer/sendToPDF'
    }),  

    async fetchReporteSolData() {
      this.loading = true;

      try {
        const { data } = await api.post(`${process.env.VUE_APP_API_SERVER}/iusasol/reporte-panel`, {});
        this.sellData = data; // Assign the data obtained from the API to sellData
      } catch (error) {
        this.errorMessage = 'Ocurrió un problema al recuperar la información.';
        console.error('Error fetching ordenes Pendientes:', error);
      }

      this.loading = false;
      this.sendToPDF({ active: true });
    },
  },
};
</script>
  
<style scoped>
.table-row {
  height: 60px;
  background-color: rgb(240, 240, 240);
}
.v-data-table >>> .v-data-table-header {
  box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029 !important;
  height: 60px;
  border-bottom: 2px solid black;
  background-color: rgb(240, 240, 240);
}

#borderless-table >>> div > table > tbody > tr > td {
  border-bottom: 0px;
}

.product-information-container {
  top: 10px;
  left: 56px;
  max-width: 100%;
  height: auto;
  background: #FFFFFF;
  box-shadow: inset 0px 3px 6px #FF000029, 0px 3px 6px #FF000029;
  opacity: 1;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.product-information-container-left, .product-information-container-rigth {
  padding: 20px;
  font-size: 1em;
  background-color: white;
  flex: 1;
}

@media (max-width: 768px) {
  .product-information-container {
    flex-direction: column;
    height: auto;
    overflow-x: hidden;
  }

  .product-information-container-left, .product-information-container-rigth {
    flex: none;
    width: auto;
  }
}

.row-containerPAnelSol {
  display: flex;
  align-items: center;
}
</style>
