<template>
  <div>
    <v-container fluid>
      <div class="mt-4 mb-10 text-left">
        <v-btn @click="showDialog(1)" color="primary" :disabled="loading" dark> <v-icon>mdi-plus</v-icon> Nuevo empleado </v-btn>
      </div>
      <v-card elevation="2">
        <v-card-title>
          <div class="text-left d-flex">
            <span class="text-body-2 text--disabled mt-2 mr-1"> Mostrar </span>
            <v-select :disabled="loading" v-model="perPage" value="15"  style="width: 50px;" :items="['15', '20', '25', '30', '50']"
              dense></v-select> <span class="text-body-2 text--disabled mt-2 mr-1"> Registros </span>
          </div>
          
        </v-card-title>
        <v-card-text>
          <div class="d-flex">
            <v-text-field
              v-model="searchInput"
              solo style="max-width: 400px;"
              label="Búsqueda por número de empleado"
              clearable >
          </v-text-field>  
          </div> 
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center"> ID </th>
                  <th class="text-center"> Número</th>
                  <th class="text-center"> Nombre</th>
                  <th class="text-center"> Apellido paterno</th>
                  <th class="text-center"> Apellido Materno</th>
                  <th class="text-center">Rol </th>
                  <th class="text-center">Canal </th>
                  <th class="text-center">Usuario </th>
                  <th class="text-center" colspan="2">Acción </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in employees.data" :key="item.id">
                  <td>{{ item.id }}</td>
                  <td>{{ item.number }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.last_name }}</td>
                  <td>{{ item.maiden_name }}</td>
                  <td>{{ item.job }}</td>
                  <td>{{ item.channel }}</td>
                  <td>{{ (item.user)?item.user.email:'Sin usuario' }}</td>
                  <td>
                    <v-btn @click="showDialog(2,item)" :disabled="loading" color="warning ma-2" fab x-small dark>
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>

                    <v-btn @click="deleteEmployee(item)" :disabled="loading" color="red ma-2" fab x-small dark>
                      <v-icon>mdi-delete-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-progress-linear :active="loading" :indeterminate="loading" top color="deep-purple accent-4"></v-progress-linear>
        </v-card-text>
      </v-card>
      <div v-if="!loading" class="text-center mb-10">
        <v-pagination v-model="page" :length="last_page" circle></v-pagination>
      </div> 
      <v-dialog  v-model="dialog" persistent max-width="500" >
        <v-card>  
          <v-form ref="form" v-model="valid" lazy-validation >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" >
                  <v-text-field :disabled="loading"  :rules="numberRules" v-model="number" label="Número*" required></v-text-field>
                </v-col>
                <v-col  cols="12"  sm="6" >
                  <v-text-field :disabled="loading"  :rules="nameRules" v-model="name"  label="Nombre*" required></v-text-field>
                </v-col> 
                <v-col  cols="12"  sm="6" >
                  <v-text-field :disabled="loading"  :rules="nameRules" v-model="last_name"  label="Apellido paterno*" required></v-text-field>
                </v-col> 
                <v-col  cols="12"  sm="6" >
                  <v-text-field :disabled="loading"  :rules="nameRules" v-model="maiden_name"  label="Apellido Materno*" required></v-text-field>
                </v-col> 
                
                <v-col  cols="12" sm="6">
                  <v-select :disabled="loading"  v-model="job"
                    :items="['Asesor']"
                    value="Asesor"
                    label="Rol*" 
                    required
                  ></v-select>
                </v-col>
                <v-col  cols="12" sm="6">
                  <v-select :disabled="loading"  v-model="channel"
                    :items="['PR']" 
                    label="Canal*"
                    value="PR"
                    required
                  ></v-select>
                </v-col>
                <v-col>
                  <v-checkbox :disabled="loading"   v-model="has_user"  label="Usuario" ></v-checkbox>
                </v-col> 
                <v-col v-if="has_user" cols="12">
                  <v-text-field :disabled="loading"  v-model="email" label="Email*"   :rules="emailRules" ></v-text-field>
                  <v-text-field
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="nameRules"
              :type="showPassword ? 'text' : 'password'" 
              label="Password*" 
              class="input-group--focused"
              @click:append="showPassword = !showPassword"
               v-model="password" 
               :disabled="loading"
               required
            ></v-text-field> 
                </v-col> 
              </v-row>
            </v-container>
            <small>*Campos requeridos</small> <br>
            <small class="text-danger">{{ errorMessage }}</small>
          </v-card-text> 
          <v-card-actions>  
              <v-btn  color="danger" :disabled="loading"  @click="dialog = false;"  >
              Cancelar
            </v-btn> 
            <v-btn v-if="!editMode"  :disabled="!valid" :loading="loading" color="success"  @click="save()" >
              Aceptar
            </v-btn> 
            <v-btn v-else :disabled="(!valid || loading)" :loading="loading" color="warning"  @click="update()" >
              Actualizar  
            </v-btn>  
          </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>

    </v-container>
  </div>
</template>

<script>
import axios from "@/plugins/axios";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      token: "auth/token",
	})},
  watch: { 
    searchInput(value){
      if(value){
        this.getEmployees(0,value);
      }else {
        this.getEmployees();
      }
    },
    page(page) {
      this.getEmployees(page);
    },
    perPage() {
      this.page = 1;
      this.getEmployees();
    }
  },
  data() {
    return {
      employees: {},
      page: 1,
      showPassword:true,
      valid:false,
      loading:false,
      perPage:15,
      itemId:0,
      searchInput:"",
      has_user:false,
      last_page: 1,
      showForm:true,
      dialog:false,     
      editMode:false,
      errorMessage:"",
      selectedItem:{}, 
      email: '',
      name:'',
      last_name:'',
      maiden_name:'',
      job:"Asesor",
      channel:"PR",
      number:"",
      password:"",
      nameRules: [
        v => !!v || 'Este campo es requerido',
        v => (v && v.length <= 25) || 'Este campo debe tener almenos 10 carácteres',
      ],
      numberRules: [
        v => !!v || 'Este campo es requerido',
        v => (v && v.length <= 25) || 'Este campo debe tener almenos 10 carácteres',
      ],
    emailRules: [
      v => !!v || 'Este campo es requerido',
      v => /.+@.+\..+/.test(v) || 'El correo debe ser un campo válido',
    ],
    }
  },
  name: "AdministratorView",
  methods: {  
    update(){ 
      if(this.$refs.form.validate()) {
        this.loading=true; 
        axios.put("employees/"+this.itemId,this.setItems()).then(() => { 
          this.$refs.form.reset()
          this.$refs.form.resetValidation() 
          this.job="Asesor",
          this.channel="PR", 
          this.getEmployees()          
          this.dialog=false;
          this.$swal({
              title: '¡El registro se ha actualizado correctamente!',
              icon: "success",
              type: "succes",
              }) 
          this.setForm({});
          this.loading=false;
          this.selectedItem={};
      }).catch((e) => { 
          console.log(e);
          this.loading=false;
        }); 
       
      }  
    },
    deleteEmployee(item) {
      
      this.loading=true;
      this.$swal
        .fire({
          title: "¿Estás seguro de eliminar?",
          text: "No podrás revertir esta acción",
          icon: "warning",
          showCancelButton: true,
          confirmButton: "#3085d",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Si, eliminar!",
        })
        .then((result) => {
          if (result.isConfirmed) {
          this.loading=true; 
        axios.delete("employees/"+item.id).then(() => {
          this.getEmployees();   
           this.$swal({
              title: '¡El empleado se ha eliminado correctamente!',
              icon: "success",
              type: "succes",
              })
              
          this.loading=false;
          this.dialog=false;
      }).catch((e) => { 
          console.log(e);
          this.loading=false;
        });  
            
          } 
          this.loading=false;
        });  
    },
    save(){ 
      if(this.$refs.form.validate()) {
        this.loading=true; 
        
        axios.post("employees",this.setItems(),{
          headers: {
            'Content-Type':'application/json', 
            'Authorization': 'Bearer ' + this.token,
            }
        })
        .then(() => {   
          this.$refs.form.reset()
          this.$refs.form.resetValidation() 
          this.job="Asesor",
          this.channel="PR", 
          this.getEmployees()
          this.$swal({
              title: '¡El usuario se ha agregado correctamente!',
              icon: "success",
              type: "succes",
              })
          this.dialog=false;
          this.loading=false;
      }).catch((e) => { 
         this.errorMessage="Error "+e.response.data.message;
          this.loading=false;
        }); 
      }
    }    ,
    showDialog(type,item = {}){ 
      this.dialog=true;
      switch (type) {
        case 1: //save
                this.showForm=true;
                this.editMode=false; 
                this.setForm({}); 
          break; 
          case 2:
               //editMode
              this.editMode=true; 
              this.setForm(item);
              this.setItems(item);
            break;
        default:
          //editMode
              this.editMode=true;
              this.selectedItem= item;
          break; 
      }
      this.job="Asesor";
      this.channel="PR";
    },
    setForm(item){
      this.selectedItem = item;
      this.itemId = item?.id;
      this.number = item?.number;
      this.name = item?.name;
      this.last_name = item?.last_name;
      this.job = item?.job;
      this.channel = item?.channel;
      this.has_user = item?.user?item.user:0;
      this.email = item?.user?item.user.email:null;
      this.password = item?.user?item.user.string_password:null;   
      this.maiden_name = item?.maiden_name;
    },
    setItems(){ 
     const data = { 
            number: this.number,
            name: this.name,
            last_name: this.last_name,
            job: this.job,
            channel: this.channel,
            email:this.email,
            user:this.has_user?true:false,
            password:this.password,
            maiden_name:this.maiden_name
        };    
      return data;  
    },
    getEmployees(newPage = 0,search = 0) {
      this.loading = true;
      var page = newPage ? newPage : this.page;
      var params = search ? { page: page, per_page: this.perPage,'with[0]':'user', 'filter[number]': this.searchInput } : { page: page, per_page: this.perPage,'with[0]':'user' }
      this.employees = {};
      axios.get("employees", {
        params: params 
      }).then((response) => {
        if (response.data) {
          this.employees = response.data;
          this.last_page = this.employees.last_page;
          this.employeesTotal = this.employees.total; 
        }
        this.loading = false;
      })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });   
    },
  },
  mounted() {  
    this.getEmployees();  
  }
}
</script>