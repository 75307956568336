import axios from '@/plugins/axios';

export const userNotification = {
  namespaced: true,

  state: {
    showModalUserNotification: false,
    notificationContent: null,
    dontShowAgainUserNotification: false,
    currentNotificationId: null,
    unseenNotifications: [],
  },

  mutations: {
    SET_SHOW_MODAL(state, value) {
      state.showModalUserNotification = value;
    },
    SET_NOTIFICATION_CONTENT(state, content) {
      state.notificationContent = content;
    },
    SET_DONT_SHOW_AGAIN(state, value) {
      state.dontShowAgainUserNotification = value;
    },
    SET_UNSEEN_NOTIFICATIONS(state, notifications) {
      state.unseenNotifications = notifications;
    },
    SET_CURRENT_NOTIFICATION_ID(state, id) {
      state.currentNotificationId = id;
    },
  },

  actions: {
    async checkUserNotification({ commit, rootGetters }) {
      try {
        const userId = rootGetters['auth/user'].id;
        let userRole = rootGetters['auth/user'].roles[0]?.name || '';
        const userChannel = rootGetters['auth/user'].VTWEG;

        const viewedResponse = await axios.get(`/user/createviewnotification?user_id=${userId}`);
        const viewedNotifications = viewedResponse.data.map(v => v.id_notification);

        const response = await axios.get(`/user/createnotification`);
        const updates = response.data;

        if (updates && updates.length > 0) {
          let relevantUpdates = updates.filter(update => {

            if (update.role === 'customer') {
              const roles = update.role.split(',');
    
              if (roles.includes('DT') && roles.includes('PR')) {
                update.role = 'Customer, CustomerPR';
              } else if (roles.includes('DT')) {
                update.role = 'Customer';
              } else if (roles.includes('PR')) {
                update.role = 'CustomerPR';
              }
            }

            const userHasRole = (update.role.includes(userRole) || update.role === "Todos");
            console.log('role', userHasRole)
            const userHasChannel = (update.channel.includes(userChannel));
              
            return (
              userHasRole === true &&
              userHasChannel === true && 
              !viewedNotifications.includes(update.id) === true
            );
          });
          
          if (relevantUpdates.length > 0) {

            relevantUpdates.sort((a, b) => new Date(b.published_at) - new Date(a.published_at));

            const notificationContent = relevantUpdates.map(n => `
              <div style="text-align: center;">
                <h6><b>Hemos actualizado tu aplicación ${n.version}</b></h6>
                <p class="h6">Nos complace trabajar para que tengas la mejor experiencia en IEL</p>
              </div>
              <div>
                ${n.content}
              </div>
            `).join("\n\n");

            commit('SET_NOTIFICATION_CONTENT', notificationContent);
            commit('SET_SHOW_MODAL', true);
            commit('SET_UNSEEN_NOTIFICATIONS', relevantUpdates);
          }
        }
      } catch (error) {
        console.error('Error fetching system updates:', error);
      }
    },

    async markNotificationsAsViewed({ state, rootGetters, commit }) {
      const userId = rootGetters['auth/user'].id;
      try {
        for (const notification of state.unseenNotifications) {
          await axios.post('/createviewnotification', {
            id_user: userId,
            id_notification: notification.id
          });
        }

        commit('SET_UNSEEN_NOTIFICATIONS', []);
      } catch (error) {
        console.error('Error marking notifications as viewed:', error);
      }
    },

  },

  getters: {
    showModal: state => state.showModalUserNotification,
    notificationContent: state => state.notificationContent,
    dontShowAgain: state => state.dontShowAgainUserNotification,
    hasNotifications: state => state.unseenNotifications.length > 0,
  },
};

export default userNotification;
