<template>
  <v-row justify="center">
    <v-col :cols="cols" :md="colsMd">
      <div class="text-left font-weight-bold mb-2"><label for="payment-method">*Método de pago</label></div>
      <v-select
        v-model="form.paymentMethod"
        name="payment-method"
        :items="paymentMethods"
        item-value="id"
        item-text="des"
        max-height="300px"
        dense
				solo
				hide-details
				class="card-shadow rounded-lg"
      />
    </v-col>
    <v-col :cols="cols" :md="colsMd">
      <div class="text-left font-weight-bold mb-2"><label for="way-to-pay">*Via de pago</label></div>
      <v-select
        v-model="form.wayToPay"
        name="way-to-pay"
        :items="waysToPay"
        item-value="id"
        item-text="des"
        dense
				solo
				hide-details
				class="card-shadow rounded-lg"
      />
    </v-col>
    <v-col :cols="cols" :md="colsMd">
      <div class="text-left font-weight-bold mb-2"><label for="cfdi-use">*Uso de CDFI</label></div>
      <v-select
        v-model="form.cfdiUse"
        name="cfdi-use"
        :items="cfdiUses"
        item-value="id"
        item-text="des"
        dense
				solo
				hide-details
				class="card-shadow rounded-lg"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
	name: 'TaxDataForm',

	props: {
		value: {
			required: true,
		},

		paymentMethods: {
			type: Array,
			default: () => [],
		},

		waysToPay: {
			type: Array,
			default: () => [],
		},

		cfdiUses: {
			type: Array,
			default: () => [],
		},

    cols: {
      type: String,
      default: '12'
    },

    colsMd: {
      type: String,
      default: '4'
    }
	},

	computed: {
		form: {
			set(newValue){
				this.$emit('input', this.value, ...newValue);
			},
			get(){
				return this.value;
			}
		}
	}
}
</script>