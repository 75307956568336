<template>
  <v-form @submit.prevent="onAddMaterial" :disabled="disabled">
		<h4 class="text-left font-weight-bold text-h6">{{ title }}</h4>
    
    <v-slide-y-transition>
      <v-alert text color="error" v-if="hasErrors" dense> {{ error }}</v-alert>
    </v-slide-y-transition>

    <v-row justify="center" align="end">
      <v-col cols="12" md="6" class="d-block d-md-flex align-end gap-4" :class="materialInputClass">
        <div class="text-left font-weight-bold mb-2"><label for="material-id">Código de material</label></div>
        <v-autocomplete
          v-model="form.materialId"
          v-if="!noSelectableMaterial" 
          name="material-id"
          class="card-shadow rounded-lg"
          :items="materials"
          solo
          dense
          hide-details
          no-data-text="Sin resultados"
        />
        <v-text-field
          v-model="form.materialId"
          v-else
          name="material-id"
          class="card-shadow-input rounded-lg"
          solo
          dense
          hide-details
          autocomplete="off"
        />
      </v-col>
      <v-col cols="12" md="3" class="d-block d-md-flex align-end gap-4" :class="qtyInputClass">
        <div class="text-left font-weight-bold mb-2"><label for="units">Unidades: </label></div>
        <v-text-field
          v-model="form.requiredQty"
          name="units"
          class="card-shadow rounded-lg"
          type="number"
          solo
          dense
          step="1"
          hide-details
          autocomplete="off"
        />
      </v-col>
      <v-col cols="12" md="3" :class="buttonInputClass">
        <v-btn type="submit" class="card-shadow text-capitalize" block :dark="!disabled && !unCompletedForm" :color="buttonColor" :disabled="disabled || unCompletedForm">
          {{ !loading ? buttonText : loadingText }}
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
	name: 'TaxDataForm',

	props: {
		value: {
      default: () => ( { materialId: '', requiredQty: null } )
		},

    materials: {
      type: Array,
      default: () => [],
    },

    title: {
      type: String,
      default: ''
    },

    loadingText: {
      type: String,
      default: 'Validando material...'
    },

    buttonText: {
      type: String,
      requred: true
    },

    materialInputClass: {
      type: String,
      default: ''
    },

    qtyInputClass: {
      type: String,
      default: ''
    },

    buttonInputClass: {
      type: String,
      default: ''
    },

    buttonColor: {
      type: String,
      default: '#007D36'
    },    

    materialLength: {
      type: Number,
      default: 0
    },

    disabled: Boolean,
    loading: Boolean,
    noSelectableMaterial: Boolean,
	},

  data() {
    return { 
      error: null
    }
  },

  methods: {
    onAddMaterial(){
      if(this.materialId.length >= this.materialLength ){
        this.$emit('onAddMaterial', this.value)
      }
      else {
        this.error = `Código de material debe de tener mínimo ${this.materialLength} caracteres`
      }
    }
  },

	computed: {
		form: {
			set (newValue){
				this.$emit('input', this.value, ...newValue);
			},
			get(){
				return this.value;
			}
		},

    materialId(){
      return this.value.materialId
    },

    unCompletedForm() {
      return this.form.materialId.length <= 0 || this.form.requiredQty <= 0;
    },

    hasErrors(){
      return this.error !== null;
    }
	},

  watch: {
    materialId() {
      if(this.hasErrors){
        this.error = null;
      }
    }
  }
}
</script>