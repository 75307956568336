<template>
    <div class="my-select">
        <div class="my-select-button" @click="showOption">
            <span>{{ selectedLabel }}</span>
            <i class="fas fa-caret-down"></i>
        </div>
        <ul class="my-select-options" v-show="showOptions">
        <li
          class="my-select-item"  v-for="(option, index) in options" :key="index" @click="selectOption(option.id)" >
          <div >{{ option.title }}</div>
        </li>
      </ul>
    </div>
</template>

<script>
export default {
    name: "GraySelect",
    props: {
        disabled: {
            default: false
        },
        options: {
            type: Array,
            required: true,
        },
        value: {
            required: true,
        },
        placeholder: {
            default: "",
        },
    },
    data() {
        return {
            showOptions: false,
        };
    },
    computed: {
        selectedLabel() { 
            const selectedOption = this.options.find(
                (option) => option.id === this.value
            );
            return selectedOption ? selectedOption.title : this.placeholder;
            }, 
    },
    methods: {
        showOption() {
            if (!this.disabled) {
                this.showOptions = !this.showOptions
            }
        },
        selectOption(optionValue) {

            this.$emit("input", optionValue);
            this.showOptions = false;


        },
    },
};
</script>

<style scoped>
.my-select {
    position: relative;
    width: 100%;
}

.my-select-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0rem 1rem;
    /* border: none; */
    border-radius: 0.4em;
    /* background-color: #ebebeb; */
    cursor: pointer;
    border-color: #858585 !important;
    border: 1px;
    border-style: solid;
    box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
}

.my-select-button span {
    flex-grow: 1;
    margin-right: 5px;
    color: gray;
}

.my-select-button i {
    margin-left: 0.5rem;
}

.my-select-options {
    margin-top: -4px;
    list-style: none;
    position: absolute;
    top: 100;
    background-color: white;
    width: 100%;
    /* min-height: 150px; */
    /*height: 180px; */
    padding-left: 0;
    border: 1px solid #ebebeb;
    z-index: 2;
    max-height: 200px;
    overflow: auto;
}

.my-select-item {
    width: 100%;
    padding-top: 0.7em;
    padding-bottom: 0.7em;
    padding-left: 0.7em;
    box-shadow: chocolate;
    -moz-box-shadow: -1px 1px 7px 0px rgba(224, 37, 37, 0.45);
    margin-bottom: 0px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 0.5px solid #707070;
    opacity: 1;
    cursor:pointer;
}

.my-select-item:hover {
    background-color: #ebebeb;
}
</style> 