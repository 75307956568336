import moment from 'moment';

export default {
	methods: { 
		removeTimeFromDate (date, inputFormat = 'DD/MM/YYYY hh:mm:ss', outputFormat='DD/MM/YYYY') {
			const newDate = moment(date, inputFormat);
			
			if(!newDate.isValid()){
				return ''
			}

			return newDate.format(outputFormat);
		}, 
		periodFormat(period){ //Para objetos tipo calcularUltimosMeses de service mixin 
			const month = period.month < 10 ? `0${period.month}` : period.month; 
			return `${period.year}${month}`;
		}, 
	}
}