export default {

  data(){
    return {
      recaptcha: null,

      recaptchaInDevMode: false,
    }
  },

  created() {
    this.recaptchaInDevMode = this.$env === 'development';

    if(this.recaptchaInDevMode){
      this.recaptcha = 'devMode'
    }
  },

  methods: {
    onVerify: function (response) {
      this.recaptcha = response;
    },

    onExpired: function () {
      this.recaptcha = null;
    },

    resetRecaptcha ()  {
      this.recaptcha = null;
    }
  },

  computed: {
    recaptchaVerified () {
      return this.recaptcha !== null
    }
  }
}