import html2pdf from "html2pdf.js";

export default {
  data(){
    return {
      buildingDocument: false,
      errorMessage: '',

      options: {
        margin: 0,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2, },
        jsPDF: { unit: 'px', format: [1400, 1700], orientation: 'landscape' }
      }
    }
  },

  methods: {
    // HideClase is a css class to hide un necessary elements, from DOM, whitle generating the pdf
    async generatePdfFromView(refName, documentName = 'Caratula.pdf', hideClass){
      const el = this.$refs[refName];
      this.buildingDocument = true;

      if(!el)
        this.errorMessage = 'Sucedió un error al generar la impresión';
      else {
        this.changeElementsVisibility(hideClass, false);

        try {
          await html2pdf(el, { ...this.options, filename: documentName });
          this.changeElementsVisibility(hideClass)
        } catch (error) {
          this.errorMessage = 'Sucedió un error al generar la impresión';
        }
      }

      this.buildingDocument = false;
  },

    changeElementsVisibility(hideClass, visible = true){
      const elementsToHide = document.getElementsByClassName(hideClass);
      const visibilityState = visible ? 'visible' : 'hidden';

      if(elementsToHide){
        for(let i = 0; i < elementsToHide.length; i++) {
          elementsToHide[i].style.transition ='all 1s ease-out'; // Prevents user to notice when the element disapears from DOM
          elementsToHide[i].style.visibility = visibilityState; 
        }
      }
    }
  },

  computed: {
    error: {
      set: function() {
        this.errorMessage = '';
      },
      get: function() {
        return this.errorMessage !== '';
      }
    }
  },
}