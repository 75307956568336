export function flattenObject(obj, parentKey = '') {
    let result = {};

    for (let key in obj) {
        let newKey = parentKey ? `${parentKey}.${key}` : key;

        if (typeof obj[key] === 'object' && obj[key] !== null) {
            // Si la propiedad es un objeto, llamamos recursivamente
            Object.assign(result, flattenObject(obj[key], newKey));
        } else {
            result[newKey] = obj[key];
        }
    }

    return result;
}

export function flattenSimpleObject(obj) {
    let result = {};

    for (let key in obj) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
            // Si la propiedad es un objeto, llamamos recursivamente
            Object.assign(result, flattenSimpleObject(obj[key]));
        } else {
            if(!result[key]) {
                result[key] = obj[key];
            }
        }
    }

    return result;
}