<template>
	<div class="container-fluid grey lighten-5 pt-4">
		
        <h2 class="h2">Archivo de precios </h2>

		<br>
		<card>
			<iframe class="iframe-list-price" src="https://www.iusa.com.mx/listas_de_precios"></iframe>
		</card>
	</div>
  </template>
  
  <script>
  // @ is an alias to /src
  import axios from "@/plugins/axios";
  export default {
    name: "MulimediaCenter",
    components: {},
    data() {
      return {
        dataCatalogue: [],
        loadingCatalogue: true,
		dataPromotion: [],
        loadingPromotion: true,
		dataVideo: [],
        loadingVideo: true,
        hasError: false,
        timeout: 8000,
      };
    },
    methods: {
		getCatalogue() {
			this.loadingCatalogue = true;
		
			axios
			.get("catalogsPDF")
			.then((response) => {
				
				this.dataCatalogue = response.data;
			})
			.catch((error) => {
				if (error.response.data.errors) {
				this.msg = error.response.data.errors;
				this.hasError = true;
				setTimeout(() => {
					this.hasError = false;
				}, 8000);
				} else {
					console.warn(error);
				}
			})
			.finally(() => (this.loadingCatalogue = false));
		},
		getPromotion() {
			this.loadingPromotion = true;
		
			axios
			.get("promotionsPDF")
			.then((response) => {
				
				this.dataPromotion = response.data;
			})
			.catch((error) => {
				if (error.response.data.errors) {
				this.msg = error.response.data.errors;
				this.hasError = true;
				setTimeout(() => {
					this.hasError = false;
				}, 8000);
				} else {
					console.warn(error);
				}
			})
			.finally(() => (this.loadingPromotion = false));
		},
		getVideo() {
			this.loadingVideo = true;
		
			axios
			.get("videoMultimedia")
			.then((response) => {
				
				this.dataVideo = response.data;
			})
			.catch((error) => {
				if (error.response.data.errors) {
				this.msg = error.response.data.errors;
				this.hasError = true;
				setTimeout(() => {
					this.hasError = false;
				}, 8000);
				} else {
					console.warn(error);
				}
			})
			.finally(() => (this.loadingVideo = false));
		},
    },
	mounted(){
		this.getCatalogue();
		this.getPromotion();
		this.getVideo();
	}
  };
</script>
  
 <style scoped>
	.v-image{
		height: 15em;
	}
	iframe{
		width: 100%;
	}
	.iframe-list-price{
		height: 70em;
	}
	.v-card-title{
		text-align: center;
	}
	.v-card-title a{
		text-decoration: none !important
	}
</style>