<template>
    <v-row v-if="refundsList.length > 0">
        <v-simple-table dense>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-center"> Numero de factura </th>
                        <th class="text-center"> Devolución </th>
                        <th class="text-center"> Fecha de solicitud </th>
                        <th class="text-center"> Productos </th>
                        <th class="text-center"> Estatus </th>
                        <th class="text-center"> Detalles </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="invoice in refundsList" :key="invoice.invoice_id">
                        <td>{{ invoice.invoice_id }}</td>
                        <td>{{ invoice.refund_type }}</td>
                        <td>{{ dateFormat(invoice.created_at) }}</td>
                        <td>{{ invoice.items.length }}</td>
                        <td>{{ invoice.status }}</td>
                        <td> <v-btn @click.stop="showDetails(invoice.items)" class="mb-1 mt-1" elevation="2"
                                icon><v-icon>mdi mdi-eye</v-icon> </v-btn></td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <v-dialog v-model="showModalInfo" max-width="900px">
            <v-card>
                <v-toolbar flat color="primary" dark>
                    <v-toolbar-title>Productos</v-toolbar-title>
                </v-toolbar>
                <v-tabs vertical>
                    <v-tab v-for="item in invoiceItems" :key="item.i">
                        <v-icon left>
                            mdi-package-variant
                        </v-icon>
                        {{ item.SHORT_TEXT.slice(0, 20) + '...' }}
                    </v-tab>

                    <v-tab-item v-for="item in invoiceItems" :key="item.i">
                        <v-card flat>
                            <v-card-text>
                                <p> {{ item.MATERIAL + " | " + item.SHORT_TEXT }} </p>
                                <p>
                                    <v-row >
                                        <v-col cols="12" sm="6">
                                            <div  class="justify-center">
                                                <v-img class="d-block ml-auto mr-auto"  lazy-src="https://picsum.photos/id/11/10/6" max-height="150"
                                                max-width="250" :src="urlServer + '/' + item.image_primary"></v-img>
                                            </div> 
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <div class="justify-center">
                                                <v-img lazy-src="https://picsum.photos/id/11/10/6" max-height="150"
                                                max-width="250" :src="urlServer + '/' + item.image_secondary"></v-img>
                                            </div>
                                           
                                        </v-col>
                                    </v-row>
                                </p>
                                <p class="mb-0">
                                    <b> Factura: {{ item.invoice_id }} </b> <br>
                                    <b> Unidades: {{ item.productQuantity ? item.productQuantity : 1 }} </b> <br>
                                    <b> Falla:</b> {{ item.refund_description }}
                                </p>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import axios from "@/plugins/axios";
export default ({
    props: ["customer"],
    methods: {
        dateFormat(date) {
            const options = { year: 'numeric', month: 'long', day: 'numeric' }
            return new Date(date).toLocaleDateString('es', options)
        },
        showDetails(items) {
            this.showModalInfo = true;
            this.invoiceItems = items
            console.log(items)
        } 
    },
    data() {
        return {
            urlServer: process.env.VUE_APP_BASE_SERVER,
            invoiceItems: [],
            showModalInfo: false,
            refundsList: [],
        }
    },
    created() {
        let formData = new FormData();
        formData.append('customer_id', this.customer);
        axios.post("/refunds", formData)
            .then((response) => {
                this.refundsList = response.data.data;
            })
            .catch((e) => {
                if (e.response.data.errors) {
                    this.$swal({
                        title: e.response.data.details,
                        icon: "warning",
                        type: "warning"
                    });
                }
            });
    },
    name: "RefundsTable"
});
</script>