<template>
        <div>
            <!-- loader -->
            <div v-if="loader">
                <div class="row d-flex justify-content-center">
                    <div class="col-md-12 col-lg-6 col-xl-5">
                        <div class="row d-flex justify-content-center">
                            <div class="col-4" v-for="iteration in 3" :key="iteration">
                                <v-skeleton-loader
                                max-width="auto"
                                max-height="120"
                                type="card"
                                ></v-skeleton-loader>
                            </div>                    
                        </div>  
                    </div>
                </div>              
            </div>
            <!-- End loader -->           

            <!-- Divisiones -->
            <div class="row d-flex justify-content-center" v-else id="menu-divisiones">
                <div class="col-xl-9 pb-0">
                    <v-sheet class="d-flex justify-content-center">
                        <v-slide-group multiple show-arrows style="padding: 0 50px">
                            
                                <v-slide-item>
                                    <div class="filter--button" :class="[{'active-divi': activeDIVI === 'todos'}]" elevation="2" @click="filterDIVIs('todos')">
                                        <div class="button--img">
                                            <img src="/img/renglonaje/Icon_material-all_out.svg" alt="" style="width: 58px">
                                        </div>
                                        <div class="button--title">
                                            <p class="mb-0 mt-2">TODAS LAS <br> DIVISIONES</p>
                                        </div>
                                    </div>
                                </v-slide-item>

                                <v-slide-item v-for="segmento, index in segmentos" :key="index">                                    
                                    <div class="filter--button" :class="{'active-divi': divisiones.includes(segmento.ws_name) && activeDIVI === segmento.ws_name, 'disabled-divi': !divisiones.includes(segmento.ws_name)}" elevation="2" @click="filterDIVIs(segmento.ws_name)">
                                        <div class="button--img">
                                            <img :src="`/img/renglonaje/${segmento.image}`" alt="" :style="`width: ${segmento.size}`">
                                        </div>
                                        <div class="button--title">
                                            <p class="mb-0 ">{{segmento.name}}</p>
                                        </div>
                                    </div>
                                </v-slide-item>      
                            
                        </v-slide-group>
                    </v-sheet>
                </div>
            </div>
            <!-- End Divisiones -->

            <!-- Model DIVISION -->
            <v-dialog v-model="dialog" persistent max-width="600px"> 
              <v-card> 
                <v-card-text class="pb-0 pt-4">                
                    <h5 v-if="view == 'box-tied'">Sin Renglonaje Caja / atado del Trimestre al Día</h5>               
                    <h5 v-else-if="view == 'new-lines'">Sin Renglones Nuevos en el trimestre al día</h5>               
                    <h5 v-else-if="view == 'without-reorder'">Sin Renglones sin reorden en los últimos 6 Meses </h5> 
                    <h5 v-else-if="view == 'special-orders-pnc'">Sin Renglones disponibles para pedido especial de PNC</h5> 
                    <h5 v-else>Sin Renglonaje Caja / atado del Trimestre al Día</h5>                 
                </v-card-text>

               
                <v-card-actions class="d-flex justify-content-center">                    
                    <v-btn
                        color="primary"
                        text
                        @click="dialog = false"
                    >
                        Enterado
                    </v-btn>
                </v-card-actions>
              
              </v-card>
          </v-dialog>
          <!-- End Model DIVISION -->
    </div>
  
</template>

<script>
import { segmentos } from './divi.js'
export default {
    props:['loader', 'activeDIVI', 'divisiones', 'view'],
    data(){
        return {
            dialog: false,
            segmentos                
        }
    },
    methods:{
        filterDIVIs(data){
            if(!this.divisiones.includes(data) && data != 'todos'){
                this.dialog = true
                return false
            }

            this.$emit('filterDIVIs', data)
        }
    }
}
</script>

<style scoped>
    .filter--button{   
        padding: 10px 10px 2px;
        display: flex !important;
        flex-wrap: wrap;
        cursor: pointer;
        margin: 10px 5px;
        width: 140px;
        font-size: 12px !important;
        font-weight: bold;
        box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3) !important;
        background: #FFF;
        border-radius: 20px;
    }
    .button--img, .button--title{ 
        width: 100%;   
    }
    .button--title{
        display: flex;
        align-items: center;
        justify-content: center;
    }   
    .text-conf{
        color: #BDBDBD;
        text-align: right;
    }
    .active-divi{
        background: rgba(255, 0, 0, 0.2) !important;
    }
    .disabled-divi{
        background: rgba(0,0,0,0.1) !important;
    }
</style>

<style>
    #menu-divisiones .v-slide-group__content {
        white-space: inherit !important;
    }
</style>