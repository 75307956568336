<template>
  <td :colspan="cols">
    <span style="display: grid">
      <span class="font-weight-bold" style="font-size: 0.7em" v-if="subtitle">{{
        subtitle
      }}</span>
      <span class="font-weight-bold font-size-85">
        <slot></slot>
      </span>
    </span>
  </td>
</template>

<script>
export default {
  name: "IELTableCell",

  props: {
    subtitle: {
      type: String,
    },
    cols: {
      type: Number,
      default: 1,
    },
  },
};
</script>
