<template>
    <div style="width:100%" >
        <div class="text-left pl-3" style="background-color: #000; color:#fff">
            Detalle de la zona {{info?.zona }}
          </div>
          <div class="text-right mt-n6 mr-1" style="background-color: #000; color:#fff">
            <v-icon v-if="!loading" size="20" color="#fff" @click="closeModal()">mdi-close-circle</v-icon>
          </div>
          <v-row class="ma-0 ml-md-1 mr-md-1 " v-if="!loading">
            <v-col cols="12" md="6" >
              <v-simple-table dense>
            <template v-slot:default>
              <thead  class="custom-table-bordered">
                <tr>
                  <td class="bg-gray">Facturacion total</td>
                  <td class="text-black">${{ (productosNuevos.FACTOT!=""?productosNuevos.FACTOT:0) | formatCurrency}}</td> 
                </tr>
                <tr>
                  <td>Facturación nuevos</td> 
                  <td  class="text-black bg-gray">${{ productosNuevos.FACNUE!=""?productosNuevos.FACNUE:0 | formatCurrency}}</td>
                </tr>
                <tr>
                  <td class="bg-gray">%&nbsp;de&nbsp;Venta&nbsp;de&nbsp;Prod&nbsp;Nuevos</td>
                  <td class="text-black ">{{ productosNuevos.PORNUE!=""?productosNuevos.PORNUE:0 | formatCurrency}}%</td> 
                </tr> 
              </thead> 
            </template>
          </v-simple-table>
            </v-col>
            <v-col cols="12 mt-n6">
              <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr class="table-heading">
                  <th colspan="8" class="text-center text-dark "><b>Facturación de productos Nuevos por División</b></th> 
                </tr>
                <tr class="custom-tr-no-bordered">
                  <th colspan="2" class="text-center text-dark "><b>División</b></th>
                  <th class="text-center text-dark " v-for="(month, index) in last6Months" :key="index"><b>{{month}}</b></th>                   
                </tr>
              </thead>
              <tbody style="border-width: 1px;">
                <tr class="custom-tr-no-bordered" v-for="producto, index in productosNuevos.PN_DIVISIONES?.PN_DIVISION" :key="index">
                  <td class="border-bottom-0">{{index+1}}</td>
                  <td class="border-bottom-0">{{producto.DIVI}}</td>
                  <td class="border-bottom-0">${{producto.NETWR1 | formatCurrency}}</td>
                  <td class="border-bottom-0">${{producto.NETWR1_1 | formatCurrency}}</td>
                  <td class="border-bottom-0">${{producto.NETWR1_2 | formatCurrency}}</td>
                  <td class="border-bottom-0">${{producto.NETWR1_3 | formatCurrency}}</td>
                  <td class="border-bottom-0">${{producto.NETWR1_4 | formatCurrency}}</td>
                  <td class="border-bottom-0">${{producto.NETWR1_5 | formatCurrency}}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col>
              <div class="text-disabled mt-5">
                Cargando información por favor espere...
                </div>
              <v-progress-linear
              indeterminate
              color="primary darken-2"
            ></v-progress-linear>
            </v-col>
          </v-row> 
    </div> 
</template>
<script> 
import axios from "@/plugins/axios";
import moment from 'moment'
export default({
    props: ['info','seller'],
    name:'ManagerPaymentHistory', 
    data(){
      return {
        productosNuevos: [],
        last6Months: [],
        loading:true,
      }
    },  
    mounted(){
      this.getLast6Months()
      this.getProductosNuevos()
    },
    computed:{ 
      period(){
        return moment().format("MMM / YYYY")
      },
    },
    methods: {
      async getProductosNuevos(){
          let response = await axios.get('seller/home/detalles-productos-nuevos',{
            params:{
              employee_number: this.seller.PERNR,
              channel: this.seller.VTWEG,
              number_month: moment().format("M"),
              number_year: moment().format("YYYY")
            }
          }).finally(
          //  this.loading=false
          ) 
          console.log(response.data.data[0]);
          this.loading=false;
          this.productosNuevos = response.data.data[0]; 
      },
      getLast6Months() {
        const today = moment();
        for (let i = 0; i < 6; i++) {
          const month = today.clone().subtract(i + 1, 'months');
          const formattedMonth = month.format('MMM/YYYY');
          this.last6Months.push(this.capitalizeFirstLetter(formattedMonth));
        }
      },
      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
      closeModal(){ 
          this.$emit('closeModal')
      }
    }
})
</script>
<style>

.card-p {
  margin: 0;
  padding: 0;
  color: #000;
}

.my-table th,
.my-table td {
  border: 0px 4px 0px 0px solid #dad9d9;
  
}

.my-table {
  width: 100%;
}
.custom-border-right{
  border-right-width: 2px;
}
.table-heading {
  font-weight: bold;
  font-size: 0.75rem;
  background: rgb(245, 245, 245);
  color: #000;
  border-width: 1px 1px 3px 1px;
  border-color: rgb(228, 231, 234);
  border-bottom-color: rgb(112, 112, 112);
}
.custom-table-bordered  {
  border-width: 2.5px;
  border-color: rgb(224, 228, 232);
}
.custom-table-bordered>tr>td {
  border-width: 2.5px;
  border-color: rgb(224, 228, 232);
}

.custom-table-bordered>th {
  border-width: 2.5px;
  border-color: rgb(224, 228, 232);
}

.table-heading tr {
  color: #000;
}

th.boder-x-none {
  border-right: none;
  border-left: none;
}

th.boder-l-none {
  border-left: none;
}
 
.custom-table-item-sm > tr > td {
  font-size: 0.8em; 
  text-align: center;
  min-width: 50px;
}  
.custom-td-header{
  font-weight: 700;
  background-color: rgb(245, 245, 245); 
} 
.custom-text-yellow{
  color:rgb(224,168,0)
}
.custom-header-title{ 
  background-color: rgb(245, 245, 245); 
}

.custom-white-table > tr > td {
  background-color: white;
  border-width: 1px;
  border-color: lightgray;
}
.custom-table-no-bordered{  
  background-color: white;

}
.border-bottom-0{   
  border-bottom: 0px;
}
.bg-gray {
  
  background-color: rgb(245,245,245);
}
.custom-tr-no-bordered{   
  border-width: 0px 1px 0px 1px;
    border-color: lightgray;
} 
.font-size-85{
  font-size:0.85em;
}
.font-size-8{ 
  font-size:0.8em;
}
.font-size-9{ 
  font-size:0.9em;
}
.no-border{ 
  border-width:0px;
}
.bg-yellow{
  background-color:rgb(255,193,7)
}
</style>