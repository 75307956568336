<!-- eslint-disable vue/valid-v-slot -->
<template>
  <v-data-table
    v-model="remindersSelected"
    :headers="header"
    :items="reminders"
    :search="search"
    :loading="loading"
    :item-key="selectItemKey"
    :show-select="!noSelectable"
    :item-class="rowTableClass"
    :height="height"
    @item-selected="emitSelection"
    dense
    fixed-header
    checkbox-color="blue"
    disable-pagination
    hide-default-footer
    loading-text="Cargando información..."
    no-data-text="No se encontro información"
    :mobile-breakpoint="0"
  >

    <template v-slot:header.data-table-select /> 

    <template v-slot:[`item.IDORREC`]="{ item }">
      <div v-if="!isSelected(item.IDORREC)">{{ item.IDORREC }}</div>
      <small v-else>RECORDATORIO SELECCIONADO</small>
    </template>

    <template v-slot:[`item.DOCRE`]="{ item }">
      {{ getDate(item.DOCRE) }}
    </template>

    <template v-slot:[`item.CANT`]="{ item }">
      <v-chip v-if="!isSelected(item.IDORREC)" label pill class="card-shadow required-qty-pill my-1" dark :color="requiredQtyStyle(item.STAT)">
        {{ item.CANT }}
      </v-chip>
    </template>

    <template v-slot:[`item.actions`]="{ item, index }">
      <v-btn fab x-small color="error" @click="$emit('onDeleteItem', item, index)">
        <v-icon  dense >
          mdi-delete
        </v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>

<script> 
  const remindersHeader = [
    { text: 'Id rec', value: 'IDORREC' },
    { text: 'Orden', value: 'PURCHC' },
    { text: 'Fecha', value: 'DOCRE' },
    { text: 'Código', value: 'MATNR' },
    { text: 'Nombre', value: 'MAKTX' },
    { text: 'Medida', value: 'MEINS' },
    { text: 'Cantidad', value: 'CANT' },
  ];

	export default {
		props: {
			value: {
				type: Array,
				default: () => [],
			},

			reminders: {
				type: Array,
				required: true,
			},

			search: {
				type: String,
				default: '',
			},

			selectItemKey: {
				type: String,
				default: 'IDORREC',
			},

			height: {
				type: String,
				default: '160px',
			},

			noSelectable: Boolean,
			loading: Boolean,
      fullDate: Boolean,
      showActions: Boolean,
		},

		methods: {
      isSelected(itemId) {
        const reminderIndex = this.value.findIndex(({ IDORREC }) => IDORREC == itemId);
        return reminderIndex >= 0;
      },

			rowTableClass(item){
        if(this.isSelected(item.IDORREC))
          return 'table-row selected-reminder-table'
        
        return 'table-row';
      },

      requiredQtyStyle(rowsResume){
        switch (rowsResume) {
          case 'AMARILLO': return 'warning'
          case 'VERDE': return 'green'
          default: return '';
        }
      },

      getDate(date) {
        if(this.fullDate) {
          return date;
        } else {
          const shortDate = date.split(' ')[0] || ''; // 10/10/2024 12:20:22 a.m -> 10/10/2024
          return shortDate;
        }
      },

			emitSelection(item){
				this.$emit('item-selected', item);
			}
		},

		computed: {
			header() {
        return this.showActions ? [...remindersHeader, { text: '', value: 'actions' }] : remindersHeader;
      },

			remindersSelected: {
				set(newValue){
					this.$emit('input', newValue);
				},

				get(){
					return this.value;
				}
			}
		}
	}

</script>

<style>
.selected-reminder-table  {
  background: rgb(181, 237, 195) !important;
}

.selected-reminder-table > td:nth-child(n+3) {
  color: transparent;
  padding: 10px 0px 10px 0px !important;

}

.table-row {
  white-space: nowrap;
}

.table-row > td {
  border-bottom: 0px !important;
}

.required-qty-pill {
  width: 60px;
}
</style>