<template>
<div>
    <v-simple-table class=" my-table" dense>
            <template v-slot:default>
              <thead class="table-heading ">
                <tr class="custom-table-bordered">
                  <th class="text-center boder-x-none"></th>
                  <th class="text-center boder-x-none text-dark"><b>Clientes</b></th>
                  <th class="text-center boder-x-none text-dark"><b>Porcentaje</b></th>
                  <th class="text-center boder-x-none text-dark"><b>Facturación</b></th>
                </tr>
              </thead>
              <tbody class="custom-table-bordered">
                <tr>
                  <td class="table-heading">Activos</td>
                  <td>{{customersActive.NCTEA}}</td>
                  <td>{{customersActive.PCTEA | formatCurrency}}%</td>
                  <td>{{customersActive.FCTEA | formatCurrency}}</td>
                </tr>
                <tr>
                  <td class="table-heading">No Activos</td>
                  <td>{{customersActive.NCTEI}}</td>
                  <td>{{customersActive.PCTEI | formatCurrency}}%</td>
                  <td>{{customersActive.FCTEI | formatCurrency}}</td>
                </tr>
                <tr>
                  <td class="table-heading">Total</td>
                  <td>{{totalClientes}}</td>
                  <td>{{totalProcentaje}}</td>
                  <td>{{totalFacturacion}}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <p class="small text--secondary text-left">
            <b>Nota: </b>La facturación corresponde al periodo que se muestra
          </p>
</div>    
</template>
<script> 
export default({
  name: 'OrdersPerDay',
  props: ['customersActive'],
  computed:{
    totalClientes(){
      let result = parseInt(this.customersActive.NCTEA) + parseInt(this.customersActive.NCTEI)
      if (isNaN(result)) {
        return 0
      }
      return result
    },
    totalProcentaje(){
      let result = parseFloat(this.customersActive.PCTEA) + parseFloat(this.customersActive.PCTEI)
      if (isNaN(result)) {
        return 0
      }
      return result
    },
    totalFacturacion(){
      let result = parseFloat(this.customersActive.FCTEA) + parseFloat(this.customersActive.FCTEI)
      if (isNaN(result)) {
        return 0
      }
      return result
    }
  }
});
</script> 