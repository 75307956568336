<!-- eslint-disable vue/valid-v-slot -->
<template>
  <v-data-table
    v-model="reelsSelected"
    :headers="reelsTableHeader"
    :items="reels"
    :search="search"
    :loading="loading"
    :item-key="selectItemKey"
    :show-select="!noSelectable"
    :item-class="rowTableClass"
    :height="height"
    @item-selected="emitSelection"
    dense
    fixed-header
    checkbox-color="blue"
    disable-pagination
    hide-default-footer
    loading-text="Cargando información..."
    no-data-text="No se encontro información"
    :mobile-breakpoint="0"
  >
    <template v-slot:header.data-table-select /> 
  </v-data-table>
</template>

<script>
  const reelsTableSimpleHeader = [
    { text: 'Pedido Vta', value: 'PEDVTA' },
    { text: 'No. carrete', value: 'NCARR' },
    { text: 'Accept', value: 'ACEPT' },
    { text: 'Material', value: 'matnr' },
    { text: 'Texto', value: 'MAKTX' },
    { text: 'Cantidad Pedida', value: 'kwmeng' },
    { text: 'UMB', value: 'MEINS' },
    { text: 'Centro de suministro', value: 'WERKS' },
    { text: 'Posición cotizacion', value: 'POSCOT' },
    { text: 'Número cotizacion', value: 'COTIZ' },
    { text: 'Fecha', value: 'ERDAT' },
    { text: 'Usuario', value: 'ERNAM' },
  ];

  const reelsTableFullHeader = [
    ...reelsTableSimpleHeader,
    { text: 'Entrega', value: 'VBELN' },
    { text: 'Pos.', value: 'POSNR' },
    { text: 'Fe.Reparto', value: 'EDATU' },
    { text: 'Numero de aviso', value: 'NOAVI' },
    { text: 'Cant. Carret.', value: 'CCARR' },
    { text: 'Fecha', value: 'ERDAT' },
    { text: 'Hora', value: 'ERZET' },
    { text: 'Usuario', value: 'ERNAM' },
    { text: 'Libre', value: 'LIBRE' },
  ];

	export default {
		props: {
			value: {
				type: Array,
				default: () => [],
			},

			reels: {
				type: Array,
				required: true,
			},

			search: {
				type: String,
				default: '',
			},

			selectItemKey: {
				type: String,
				default: 'NCARR',
			},

			height: {
				type: String,
				default: '200px',
			},

			fullTable: Boolean,
			noSelectable: Boolean,
			loading: Boolean
		},

		methods: {
			rowTableClass(item){
        const status = item.status;

        switch (status) {
          case 'selected': return 'table-row selected-reel-table'
          case 'error': return 'table-row error-reel-table'
          default: return 'table-row';
        }
      },

			emitSelection(item){
				this.$emit('item-selected', item);
			}
		},

		computed: {
			reelsTableHeader() {
        return !this.fullTable ? reelsTableSimpleHeader : reelsTableFullHeader;
      },

			reelsSelected: {
				set(newValue){
					this.$emit('input', newValue);
				},

				get(){
					return this.value;
				}
			}
		}
	}

</script>

<style>
.error-reel-table {
  background-color: rgb(248, 87, 87) !important;
}

.selected-reel-table {
  background-color: rgb(181, 237, 195) !important;
}

.table-row {
  white-space: nowrap;
}

.table-row > td {
  border-bottom: 0px !important;
}
</style>