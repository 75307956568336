var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',{ref:"printReport",staticClass:"white lighten-5 py-7",attrs:{"fluid":""}},[( 
        _vm.currentLevelLabel && this.currentLevelMatch(['family', 'material'])
      )?_c('v-card',{staticClass:"pa-5 mb-0 ma-5 card-shadow",attrs:{"rounded":"0"}},[_c('h2',{staticClass:"text-h5 ma-0 text-uppercase font-weight-bold"},[_vm._v(" "+_vm._s(_vm.currentLevelLabel)+" ")])]):_vm._e(),_c('v-col',{staticClass:"row-containerPAnelSol",attrs:{"md":"7"}},[_c('v-text-field',{attrs:{"label":"Buscar","single-line":"","hide-details":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticClass:"red-icon-background-PanelSol"},[_c('v-icon',{staticStyle:{"font-size":"20px"}},[_vm._v("mdi-magnify")])],1)]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card',{staticClass:"pas-5 mt-8 ma-5 card-shadow",attrs:{"rounded":"0"}},[_c('v-data-table',{attrs:{"id":"borderless-table","dense":"","headers":_vm.headers,"items":_vm.filteredSellData,"loading":_vm.loading || _vm.loadingShowOptions,"loading-text":"Cargando información...","no-data-text":"No se encontró información","footer-props":{
          itemsPerPageText: 'Mostrar en grupo de',
          itemsPerPageAllText: 'Todos',
          pageText: '{0}-{1} de {2}',
        }},on:{"pagination":_vm.handleShowOptionsChange}})],1)],1),_c('v-snackbar',{attrs:{"top":"","right":""},model:{value:(_vm.error),callback:function ($$v) {_vm.error=$$v},expression:"error"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }