<template>
  <v-card>
    <v-card-title class="headline"> Gestionar Contraseña </v-card-title>
    <v-card-text style="text-align: left">
      <v-alert
        text
        outlined
        color="deep-orange"
        icon="mdi-alert"
        v-if="hasError"
        style="font-size: 12px"
      >
        <ul
          style="text-align: left"
          v-for="error in hasError"
          :key="error.id"
          class="mt-n2"
        >
          <li v-for="item in error" :key="item.index">
            {{ item }}
          </li>
        </ul>
      </v-alert>
      <v-col>
        <v-btn color="teal" dark @click="getPasswordByUser" v-if="!password">
          <v-icon dark> mdi-database-eye-outline </v-icon>
        </v-btn>
        <span style="font-weight: 700; font-size: 16px" v-else>
          <span> {{ password }} </span
          ><v-icon color="red" @click="password = false">mdi-close</v-icon>
        </span>
      </v-col>
      <!-- <v-col>
            <span style="font-weight: 700;font-size:14px;"> {{ password }} </span>
          </v-col> -->
      <v-form ref="formEvent" v-model="form.valid">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Nueva contraseña *"
                prepend-icon="mdi-lock-alert-outline"
                placeholder="Ingrese contraseña"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[(v) => !!v || 'La contraseña es requerida']"
                :type="show ? 'text' : 'password'"
                v-model="form.password"
                @click:append="show = !show"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Confirmar contraseña *"
                prepend-icon="mdi-lock-check-outline"
                placeholder="Confirme contraseña"
                :append-icon="shown ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[(v) => !!v || 'La confirmación es requerida']"
                :type="shown ? 'text' : 'password'"
                v-model="form.password_confirmation"
                @click:append="shown = !shown"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-spacer></v-spacer>
          <small>*Indica campo requerido</small>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text :disabled="loading" @click="closeDialog">
        Cancelar
      </v-btn>
      <v-btn
        color="success darken-1"
        :disabled="loading"
        @click="updatePassword"
      >
        Guardar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from "@/plugins/axios";
export default {
  props: ["user", "onCancel"],
  data() {
    return {
      data: null,
      loading: false,
      form: {},
      show: false,
      shown: false,
      hasError: null,
      password: null,
    };
  },
  methods: {
    closeDialog() {
      this.$refs.formEvent.resetValidation();
      this.form = {};
      this.hasError = null;
      this.password = null;
      this.onCancel();
    },
    updatePassword() {
      this.$refs.formEvent.validate();
      if (!this.form.valid) {
        return false;
      }
      axios
        .put(`/updatePassword`, {
          id: this.user.id,
          password: this.form.password,
          password_confirmation: this.form.password_confirmation,
        })
        .then((response) => {
          if (response.status == 200) {
            this.$emit("passwordChanged");
            this.$refs.formEvent.resetValidation();
            this.form = {};
            this.hasError = null;
            this.password = null;
            this.$toast.success(response.data.message, {
              timeout: 3000,
            });
          }
        })
        .catch((error) => {
          this.hasError = error.response.data.errors;
          /* return this.$toast.error(error.response.data.message, {
            timeout: 3000,
          }); */
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getPasswordByUser() {
      axios
        .post(`/getPasswordByUser`, {
          id: this.user.id,
        })
        .then((response) => {
          if (response.status == 200) {
            this.password = response.data;
          }
        })
        .catch((error) => {
          return this.$toast.error(error.response.data.message, {
            timeout: 3000,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
