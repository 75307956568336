<template>
    <div>
        <v-container fluid fill-height>
            <template>
                <v-row style="display: flex;" class="flex justify-center mt-10">
                    <v-col cols="10" sm="6" md="4" lg="3">
                        <v-alert outlined color="black" dense>
                            <div class="text-h5 ">
                                <v-icon size="30">mdi-package-variant-closed</v-icon> DEVOLUCIONES
                            </div>
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12">
                        <div class="pa-xs-2 pa-md-2 pa-lg-2 mt-xs-0 mt-md-0 mt-lg-0 mb-10 col-12">
                            <v-card-title style="display: flex;" class="flex justify-center">
                                <v-row justify="center">
                                    <v-col cols="12" sm="9" md="4" lg="3" class="">
                                        <v-row>
                                            <v-col cols="12" sm="12" class="text-left">
                                                <h6 class=" font-weight-bold text-left text--secondary">Registro de
                                                    solicitud
                                                </h6>
                                            </v-col>
                                            <v-col cols="8" sm="9" class="mt-n4">
                                                <v-form ref="formSearchInvoce" v-model="validInputSearchInvoice"
                                                    lazy-validation>
                                                    <v-text-field v-model="invoiceCode" solo required
                                                        :rules="searchInvoceRules"></v-text-field>
                                                </v-form>
                                            </v-col>
                                            <v-col cols="2" sm="3" class="mt-n3">
                                                <v-btn @click="searchInvoce()" aria-disabled="true" color="#00B8D4"
                                                    :loading="loading" class="text-white" rounded>
                                                    {{ loading ? "Buscando..." : "Buscar" }}
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="showInvoceData">
                                            <v-col cols="12" sm="9" class="text-left mt-n10">
                                                <h6 class="font-weight-bold">Generar</h6>
                                                <v-select solo :items="refundType"
                                                    :rules="[v => !!v || 'Este campo es requerido']" item-value="id"
                                                    item-text="name" label="Seleccione una opción"
                                                    @change="selectedOption($event)">
                                                </v-select>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-card-title>
                            <!-- Component -->
                            <div v-if="showInvoceData" class="pl-15 pr-15 ">
                                <v-row>

                                    <v-col cols="12" class="text-left mt-10">
                                        <h6 class="font-weight-bold">Información de la Factura:</h6>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-simple-table>
                                            <thead class="font-weight-bold ">
                                                <tr>
                                                    <td class="font-size-20 " v-for="item in headers" :key="item.i"
                                                        :colspan="item.colspan">&nbsp;&nbsp;&nbsp;{{ item.text
                                                        }}&nbsp;&nbsp;&nbsp;</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="item in data.InvoiceItems" :key="item.i" class="white">
                                                    <td>
                                                        <input type="checkbox" class="mb-n4 mt-n1 ml-1"
                                                            @click="showForm(item, $event.target)" v-if="isCreditNote">
                                                    </td>
                                                    <td>{{ item.MATERIAL }}</td>
                                                    <td>
                                                        <span class="mt-4">
                                                            {{ item.SHORT_TEXT }}
                                                        </span>
                                                    </td>
                                                    <td>{{ item.REQ_QTY }}</td>
                                                    <td>{{ item.MEINS }}</td>
                                                    <td>$ {{ item.KZWI1 }}</td>
                                                </tr>
                                            </tbody>
                                        </v-simple-table>
                                    </v-col>
                                    <v-col cols="12" class=" mt-10">
                                        <v-row class="mb-4" justify-md="center" justify="end">
                                            <!--
                                        <v-col cols="12" sm="6" md="3" lg="4" v-if="showDocument">
                                            <h6 class="font-weight-bold">Ingresar documento 83</h6>
                                            <v-text-field solo required></v-text-field>
                                        </v-col>
                                    -->
                                            <v-col cols="8" sm="6" md="3">
                                                <div class=" ">
                                                    <v-btn color="success"
                                                        :disabled="(productsToRefunded.length > 0 ? false : true)"
                                                        @click="sendInvoice" :loading="loading" class="text-white mt-8  "
                                                        rounded>
                                                        <v-icon>mdi-check</v-icon> Enviar solicitud
                                                    </v-btn>
                                                </div>
                                            </v-col>
                                            <v-col cols="4" sm="3" md="1">
                                                <div class="text-left">
                                                    <v-btn color="red" class="text-white mt-8 "
                                                        @click="showInvoceData = false" rounded>
                                                        Cancelar
                                                    </v-btn>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </div>
                            <p class="small" v-if="ReciptNotFound">No se ha encontrado información de la factura</p>
                            <!-- Component -->
                            <!-- Component -->
                            <RefundsTable v-if="!showInvoceData" :customer="user.customer_data_id"></RefundsTable>
                            <!-- Component -->
                        </div>
                    </v-col>
                </v-row>
                <v-dialog v-model="loading" hide-overlay persistent width="300">
                    <v-card color="primary" dark>
                        <v-card-text> Por favor, espere. Procesando solicitud...
                            <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                        </v-card-text>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="ModalForm" persistent max-width="600px">
                    <v-card>
                        <v-card-title>
                            <span class="text-h6">{{ product?.SHORT_TEXT }}</span>
                        </v-card-title>
                        <v-card-text>
                            <v-form ref="formAdditem">
                                <v-container>
                                    <v-row>
                                        <small class="gray mt-2 mb-n3">Seleccione dos imágenes donde
                                            muestre el producto defectuoso*</small>
                                        <v-col cols="12" sm="6">
                                            <v-file-input v-model="productImage1" :rules="imageRules"
                                                accept="image/png, image/jpeg, image/bmp" prepend-icon="mdi-camera"
                                                label="imagen"></v-file-input>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-file-input v-model="productImage2" :rules="imageRules"
                                                accept="image/png, image/jpeg, image/bmp" prepend-icon="mdi-camera"
                                                label="imagen"></v-file-input>
                                        </v-col>
                                        <v-col cols="12" sm="3" class="mt-n5">
                                            <small class="gray mb-n3">Productos a devolver*</small>
                                            <div class="input-group">
                                                <select v-model="productQuantity" class="form-control"
                                                    placeholder="Selecciona el estado">
                                                    <option disabled value="">Selecciona cantidad</option>
                                                    <option v-for="i  in  parseInt(product.REQ_QTY ? product.REQ_QTY : 0)"
                                                        :key="i" :value="i">{{ i }}</option>
                                                </select>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" sm="6" class="mt-n5">
                                            <small class="gray mb-n3">Razón*</small>
                                            <div class="input-group">
                                                <select v-model="refundReason" :value="product?.refundReason"
                                                    class="form-control" placeholder="Selecciona una opción">
                                                    <option v-for="reason  in refundReasons" :key="reason.i"
                                                        :value="reason.id">
                                                        {{ reason.name }}</option>
                                                </select>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" sm="12" class="">
                                            <small class="gray mb-n3">Descripción de la falla*</small>
                                            <v-textarea :rules="descriptionRules" class="text-uppercase" counter="200"
                                                v-model="refundDescription" solo placeholder="" no-resize
                                                rows="2"></v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <small>*campos requeridos</small>
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="cancelItem">
                                Descartar
                            </v-btn>
                            <v-btn color="blue darken-1" text @click="addItem">
                                Guardar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </template>
        </v-container>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "@/plugins/axios";
import RefundsTable from "./includes/RefundsTable.vue";
export default {
    components: { RefundsTable },
    watch: {
        refundDescription() {
            this.refundDescription = this.refundDescription.toUpperCase();
        },
        showInvoceData() {
            if (this) {
                this.productsToRefunded = [];
                this.isCreditNote = false;
            }
        }
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
    },
    data: () => ({

        invoiceCode: '9211656088',
        productQuantity: 1,
        refundReason: 1,
        productImage1: null,
        productImage2: null,
        itemSelected: null,
        refundDescription: "",
        search: "",
        validInputSearchInvoice: true,
        loading: false,
        showDocument: false,
        showInvoceData: false,
        ReciptNotFound: false,
        isCreditNote: false,
        ModalForm: false,
        productsToRefunded: [],
        selectedItem: [],
        product: [],
        data: [],
        refundReasons: [
            { name: 'Producto defectuoso', id: 1 },
            { name: 'Producto erroneo', id: 2 }
        ],
        headers: [
            { text: "", colspan: 0 },
            { text: "Código", colspan: 1 },
            { text: "Descripción", colspan: 0 },
            { text: "Unidades", colspan: 0 },
            { text: "Unidad de medida", colspan: 0 },
            { text: "Venta", colspan: 0 },
        ],
        refundType: [
            { 'name': 'Nota de crédito', 'id': 1 }],
        searchInvoceRules: [
            v => !!v || 'ESTE CAMPO ES REQUERIDO',
            v => (v && v.length <= 11) || 'ESTE CAMPO DEBE SER MENOR A 11 CARACTERES ',
            v => {
                if (!/^([0-9])*$/.test(v)) {
                    return "SÓLO SE ADMITEN NUMEROS PARA ESTE CAMPO";
                }
                return true;
            },

        ],
        imageRules: [
            value => !!value || 'ESTE CAMPO ES REQUERIDO',
            value => {
                if (!['image/png', 'image/jpg', 'image/pjp', 'image/pjpej', 'image/jpeg', 'image/bmp', 'image/jfif'].includes(value?.type)) {
                    return "SÓLO SE ADMITEN IMAGENES PARA ESTE CAMPO";
                }
                if (value?.size > 10485760) {
                    return "EL TAMAÑO MAXIMO PARA ESTE ARCHIVO ES DE 10 MB"
                }
                return true;
            },
        ],
        descriptionRules: [
            v => !!v || 'Este campo es requerido',
            v => (v && v.length <= 200) || 'Este campo debe ser menor a 200 caracteres ',
        ]
    }),
    methods: {
        sendInvoice() {
            this.loading = true;

            let formData = new FormData();
            this.prepareForm(formData);
            axios.post("/refunds/store", formData, { headers: { 'Content-Type': 'multipart/form-data' } })
                .then((response) => {
                    this.loading = false;
                    this.$swal({
                        title: 'La solicitud de devolución ha sido enviada correctamente',
                        icon: "success",
                        type: "succes",
                    });
                    this.showInvoceData = false;
                    console.log(response)

                })
                .catch((e) => {
                    console.log(e.response);
                    if (e.response.data.errors) {
                        this.$swal({
                            title: e.response.data.details,
                            icon: "warning",
                            type: "warning"
                        });
                    }
                    this.loading = false;
                });
        },
        cancelItem() {
            this.itemSelected.checked = false;
            this.ModalForm = false;
        },
        prepareForm(formData) {
            for (let i = 0; i < this.productsToRefunded.length; i++) {
                formData.append('items[' + i + '][BSTRF]', this.productsToRefunded[i].BSTRF);
                formData.append('items[' + i + '][CUM_CF_QTY]', this.productsToRefunded[i].CUM_CF_QTY);
                formData.append('items[' + i + '][ITM_NUMBER]', this.productsToRefunded[i].ITM_NUMBER);
                formData.append('items[' + i + '][KONDM]', this.productsToRefunded[i].KONDM);
                formData.append('items[' + i + '][SPART]', this.productsToRefunded[i].SPART);
                formData.append('items[' + i + '][WERKS]', this.productsToRefunded[i].WERKS);
                formData.append('items[' + i + '][S_UNIT_DLV]', this.productsToRefunded[i].S_UNIT_DLV);
                formData.append('items[' + i + '][MEINS]', this.productsToRefunded[i].MEINS);
                formData.append('items[' + i + '][KZWI1]', this.productsToRefunded[i].KZWI1);
                formData.append('items[' + i + '][MAABC]', this.productsToRefunded[i].MAABC);
                formData.append('items[' + i + '][MABST]', this.productsToRefunded[i].MABST);
                formData.append('items[' + i + '][image_primary]', this.productsToRefunded[i].image_primary);
                formData.append('items[' + i + '][image_secondary]', this.productsToRefunded[i].image_secondary);
                formData.append('items[' + i + '][SHORT_TEXT]', this.productsToRefunded[i].SHORT_TEXT);
                formData.append('items[' + i + '][refund_description]', this.productsToRefunded[i].refund_description);
                formData.append('items[' + i + '][MATERIAL]', this.productsToRefunded[i].MATERIAL);
                formData.append('items[' + i + '][REQ_QTY]', this.productsToRefunded[i].REQ_QTY);
                formData.append('items[' + i + '][MATERIAL]', this.productsToRefunded[i].MATERIAL);
                formData.append('items[' + i + '][NETVA]', this.productsToRefunded[i].NETVA);
                formData.append('items[' + i + '][NETPR]', this.productsToRefunded[i].NETPR);
                formData.append('items[' + i + '][is_refunded]', 1);
                formData.append('items[' + i + '][productQuantity]', this.productsToRefunded[i].productQuantity)
            }
            formData.append('invoice_id', this.invoiceCode);
            formData.append('customer_id', this.user.customer_data_id);
            formData.append('has_refunds', 1);
            formData.append('refund_type', 1);
        },
        addItem() {
            if (this.$refs.formAdditem.validate()) {
                this.product.image_primary = this.productImage1;
                this.product.image_secondary = this.productImage2;
                this.product.refund_description = this.refundDescription;
                this.product.productQuantity = this.productQuantity;
                this.product.refundReason = this.refundReason;
                this.product.is_refunded = true;

                this.productsToRefunded.push(this.product);
                this.itemSelected.checked = true;
                this.ModalForm = false;
                this.product = [];
                // this.productImage1=null;
                this.productImage2 = null;
                this.refundDescription = "";
                this.productQuantity = 1;
                this.refundReason = 1;
            }

        },
        showForm(item, checkbox) {
            this.product = item;
            this.itemSelected = checkbox;
            if (checkbox.checked) {
                if (this.$refs.formAdditem) {
                    this.$refs.formAdditem.resetValidation();
                }
                this.productImage1 = null;
                this.productImage2 = null;
                this.refundDescription = '';
                this.productQuantity = 1;
                this.refundReason = 1;
                console.log(this.productsToRefunded);
            } else {
                this.productImage1 = item.image_primary;
                this.productImage2 = item.image_secondary;
                this.refundDescription = item.refund_description;
                this.productQuantity = item.productQuantity;
                this.refundReason = item.refundReason;
                this.productsToRefunded = this.productsToRefunded.filter(x => x.ITM_NUMBER !== item.ITM_NUMBER);
                console.log(this.productsToRefunded);
            }
            this.ModalForm = true;
        },
        selectedOption(value) {
            this.showDocument = (value == 2) ? true : false;
            this.isCreditNote = (value == 1) ? true : false;
        },
        searchInvoce() {
            this.$refs.formSearchInvoce.validate()
            if (this.validInputSearchInvoice) {
                this.loading = true;
                // this.invoiceCode //Send invoce code  
                const params = {
                    invoice: this.invoiceCode,
                    KUNNR: this.user.custumer_code,
                    VTWEG: this.user.VTWEG,
                }
                // console.log(params)
                axios.post("/refunds/show", params)
                    .then((response) => {
                        this.loading = false;
                        this.data = response.data;
                        if (this.data.items == 0) {
                            this.showInvoceData = false;
                            this.ReciptNotFound = true;
                        } else {
                            this.showInvoceData = true;
                            this.ReciptNotFound = false;
                        }
                    })
                    .catch((e) => {
                        console.log(e.response);
                        if (e.response.data.errors) {
                            this.$swal({
                                title: e.response.data.details,
                                icon: "warning",
                                type: "warning"
                            });
                        }
                        this.loading = false;
                        this.showInvoceData = false;
                    });
            }
        }
    },
};
</script>