<template>
    <div>
      <v-simple-table class="cumplimiento__table">
          <template v-slot:default>
        
            <thead class="cumplimiento__thead--color">
              <tr>
                <th class="cumplimiento__thead--border text-center">Zona</th>
                <th class="cumplimiento__thead--border text-center"></th>
                <th class="cumplimiento__thead--border text-center">VEP + PFD Total</th>
                <th class="cumplimiento__thead--border text-center">VEP Total</th>
                <th class="cumplimiento__thead--border text-center">PFD Total</th>                
                <th class="cumplimiento__thead--border text-center">PNC</th>
                <th class="cumplimiento__thead--border text-center">PNC</th>
                <th class="cumplimiento__thead--border text-center">Detalles de Envío de Pedidos</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowspan="2" class="cumplimiento__tbody--border">
                  <p class="mb-0 cumplimiento__asesor">
                  {{ cumplimientoAsesor && cumplimientoAsesor.length > 0 ? cumplimientoAsesor[0].BZIRK : '' }} <br>
                  {{ cumplimientoAsesor && cumplimientoAsesor.length > 0 ? cumplimientoAsesor[0].NAMEV : '' }}<br>
                  {{ cumplimientoAsesor && cumplimientoAsesor.length > 0 ? cumplimientoAsesor[0].PBZIRK : '' }}<br>                  
                  Antiguedad: meses<br>
                  Clientes de la zona: {{numClientesZona}}</p>
                </td>
                <td class="cumplimiento__tbody--border"># de Clientes</td>
                <td class="cumplimiento__tbody--border">{{sumaVEPPDF}}</td>
                <td class="cumplimiento__tbody--border">{{VEP}}</td>
                <td class="cumplimiento__tbody--border">{{PFD}}</td>
                <td rowspan="2" class="p-0" style="height: 151px">
                  <table class="w-100 h-100">
                    <tr style="height:33%">
                      <td class="cumplimiento__tbody--borderBottom ">Viables</td>                      
                    </tr>
                    <tr style="height:33%">
                      <td class="cumplimiento__tbody--borderBottom ">Con Pedido</td>                      
                    </tr>
                    <tr style="height:33%">
                      <td class="">Sin Pedido</td>                      
                    </tr>
                  </table>
                </td>
                <td rowspan="2" class="cumplimiento__tbody--border p-0" style="height: 151px">
                  <table class="w-100 h-100">
                    <tr style="height:33%">                      
                      <td class="cumplimiento__tbody--borderBottom">{{viablePNC}}</td>
                    </tr>
                    <tr style="height:33%">                      
                      <td class="cumplimiento__tbody--borderBottom">{{conPedidoPNC}}</td>
                    </tr>
                    <tr style="height:33%">                      
                      <td >{{sinPedidoPNC}}</td>
                    </tr>
                  </table>
                </td>
                <td rowspan="2" class="cumplimiento__tbody--border">
                  <div class="row">
                    <div class="col-sm-12 col-lg-5">
                      <div class="chart-container">
                        <GChart
                          type="PieChart"
                          :data="chartData"
                          :options="chartOptions"
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-lg-7">
                        <a href="" @click.prevent="openMap = true"><img src="img/Google-Maps.png" style="width: 55px" alt=""></a>

                        <div class="p-3">
                          <table class="w-100 ">
                            <tbody>
                              <tr>
                                <td class="cumplimiento__tbody--border d-flex align-center justify-content-center"><span class="color1 bullet-color mr-3"></span> {{chartData[1][0]}}</td>
                                <td class="cumplimiento__tbody--border">{{hor1}} pedidos</td>
                              </tr>
                              <td class="cumplimiento__tbody--border d-flex align-center justify-content-center"><span class="color2 bullet-color mr-3"></span> {{chartData[2][0]}}</td>
                              <td class="cumplimiento__tbody--border">{{hor2}} pedidos</td>
                            </tbody>
                            <tfoot class="cumplimiento__tfooter--color">
                              <tr>
                                <td class="cumplimiento__tbody--border">Total:</td>
                                <td class="cumplimiento__tbody--border">{{sumaPedidos}} pedidos</td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                    </div>  
                  </div>
                </td>
              </tr>
              <tr>
                <td class="cumplimiento__tbody--border">Porcentaje</td>
                <td class="cumplimiento__tbody--border">{{porcentageSumaVEPPDF}}%</td>
                <td class="cumplimiento__tbody--border">{{porcentageVEP}}%</td>
                <td class="cumplimiento__tbody--border">{{porcentagePFD}}%</td>
              </tr>
            </tbody>
          
          </template>
      </v-simple-table>

      <customer-locations-map 
        v-model="openMap" 
        :customers="cumplimientoAsesor">
      </customer-locations-map>
    </div>

</template>

<script>
import { GChart } from 'vue-google-charts/legacy'
import moment from "moment"
import CustomerLocationsMap from '@/components/modals/customers_locations_map/CustomerLocationsMap.vue';
export default {
  name: 'ComplianceWeek',  
  props: ['cumplimientoAsesor', 'dateArray'],
  components: {
    GChart,
    CustomerLocationsMap
  },
  data(){
    return{
      openMap: false,
      chartData: [
        ['Horarios', 'Cantidad'],
        ['8:00 a 18:30', 1],
        ['18:31 a 8:00', 1]
      ],
      hor1: 0,
      hor2: 0,
      sumaPedidos: 0,
      chartOptions: {
        legend: 'none',
        colors: ['#5f3fff', '#ff6384'],
        height: 150,
        width: 150,
        chartArea: {              
          width: '80%', 
          height: '80%',     
        }, 
      }
    }
  },
  computed:{
    VEP(){
      let count = 0  
      this.cumplimientoAsesor.forEach((cliente) => { 
        cliente.pedidos.forEach(pedido => {
          if (pedido != '' && pedido.TVPP == true) {
            count++
          }
        })
      });
      return count
    },
    PFD(){
      let count = 0  
      this.cumplimientoAsesor.forEach((cliente) => { 
        let flagPFD = 0
        let flagTVPP = 0
        cliente.pedidos.forEach(pedido => { 
          if (pedido != '' && pedido.TVPP == true) {
            flagTVPP = 1
          }         
          if (pedido != '' && pedido.PFD == true) {
            flagPFD = 1
          }
        })
        if(flagPFD == 1 && flagTVPP == 0){
          count++
        }
      });
      return count
    },
    sumaVEPPDF(){
      return this.VEP + this.PFD
    },
    numClientesZona(){
      return this.cumplimientoAsesor.length
    },    
    porcentagePFD(){
      let result = ((this.PFD * 100) / this.numClientesZona).toFixed(2)
      if(result == 0.00 || isNaN(result)){
        return 0
      }
      return result
    },
    porcentageVEP(){
      let result = ((this.VEP * 100) / this.numClientesZona).toFixed(2)
      if(result == 0.00 || isNaN(result)){
        return 0
      }
      return result
    },
    porcentageSumaVEPPDF(){
      let result = 0
      result = (parseFloat(this.porcentagePFD) + parseFloat(this.porcentageVEP)).toFixed(2)
      if(result == 0.00 || isNaN(result)){
        return 0
      }
      return result
    },
    conPedidoPNC(){
      let count = 0       
      this.cumplimientoAsesor.forEach((objeto) => {        
        if (objeto.pedidos.length > 0 && objeto.PNC == true){
          count++
        }
      });
      return count
    },
    sinPedidoPNC(){
      let count = 0       
      this.cumplimientoAsesor.forEach((objeto) => {        
        if (objeto.pedidos.length == 0 && objeto.PNC == true){
          count++
        }
      });
      return count
    },    
    viablePNC(){
      let count = 0       
      this.cumplimientoAsesor.forEach((objeto) => {        
        if (objeto.PNC == true){
          count++
        }
      });
      return count
    }
  },
  watch:{
    'cumplimientoAsesor':{
      deep: true,
      immediate: true, 
      handler(newVal) {
        this.hor1 = 0,
        this.hor2 = 0,
        this.sumaPedidos = 0        
        const horaInicio = moment('08:00', 'HH:mm');
        const horaFin = moment('18:30', 'HH:mm');           

        let count1 = 0 
        let count2 = 0
        newVal.forEach((objeto) => {                    
          if(objeto.pedidos.length > 0){                         
            objeto.pedidos.forEach((pedido) =>{
              pedido.detalles.forEach(detalle => {
                let horaComparar = moment(detalle.FHORA, "HH:mm") 
                if (horaComparar.isBetween(horaInicio , horaFin)) { 
                  count1++
                }else{
                  count2++                
                }
              })
            })            
          }                   
        })
        
        this.hor1 = count1
        this.hor2 = count2
        this.sumaPedidos = this.hor1 + this.hor2

        if(count1 == 0 && count2 == 0){
          count1 = 1
          count2 = 1
         }
         this.$set(this.chartData[1], 1, count1);
         this.$set(this.chartData[2], 1, count2);
      }      
    },  
  }
}
</script>

<style scoped>
  .cumplimiento__thead--border, .cumplimiento__tbody--border{
      border: thin solid rgba(0,0,0,.12);
  }
  .cumplimiento__table td{
      font-weight: bold;
      padding: 0 4px;
  }
  .cumplimiento__thead--color, .cumplimiento__tfooter--color{
      background: #f2f2f2
  }
  .cumplimiento__tbody--borderBottom{
      border-bottom: thin solid rgba(0,0,0,.12);
  }
  .cumplimiento__asesor{
    font-size: 14px;
  }
  .chart-container {
    width: 150px;
    height: 150px;
    margin: 0 auto;
  }
  .bullet-color{
    height: 8px;
    width: 15px;
    display: block;
  }
  .color1{
    background: #5f3fff;
  }
  .color2{
    background: #ff6384;
  }  
</style>