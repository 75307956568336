import axios from '@/plugins/axios';
import recaptcha from '@/mixins/recaptcha';

export default {
  mixins: [recaptcha],

  data() {
    return {
      formRef: 'passwordForm',
      inputsRef: 'inputsRef',

      passwordForm: {
        currentPassword: '',
        password: '',
        passwordConfirmation: '',
      },

      validForm: false,

      updatingPassword: false,
      passwordUpdated: false,

      hasError: false,
      message:  '',
      messages: [],
    }
  },

  methods: {
    async updatePassword (username, successCallback) {
      await this.$refs[this.formRef].validate();  

      if (!this.validForm) {
        return false;
      }

      this.updatingPassword = true;

      var params = {
        username,
        current_password: this.passwordForm.currentPassword,
        password_confirmation: this.passwordForm.passwordConfirmation,
        password: this.passwordForm.password,
      }  

      try {
        await axios.post('/user/updatePassword', params)
        this.resetForm()

        this.passwordUpdated = true;
        this.$toast.success('La contraseña se ha actualizado correctamente', {
          timeout: 3000,
        });

        successCallback();

      } catch ({ response }) {
        this.catchError(response)
      }
          
      this.updatingPassword=false;
    },

    async passwordReset(token, tokenM) {
      await this.$refs[this.formRef].validate();  

      if (this.validForm) {
        this.updatingPassword = true;

        if (this.recaptchaVerified) {
          try {
            const form = {
              ...this.passwordForm,
              token,
              tokenM,
            };

            await axios.post(`${process.env.VUE_APP_BASE_SERVER}/auth/password-recovery-confirm`, form)
            this.passwordUpdated = true;
            this.resetForm();

          } catch ({ response }) {
            this.catchError(response);
          }
        } else {
          this.showError('El campo CAPTCHA es obligatorio.');
        }

        this.updatingPassword = false;
      }
    },

    catchError(error){
      if (error){
        if(error.status == 422)
          this.showErrors(error.data.errors);
        else if(error.status == 400){
          this.showError(error.data.message);
        }
      }
      else {
        this.showError('Hubo un error al restablecer la contraseña.')
      }
    },

    showError(message) {
      this.hasError = true;
      this.message = message;

      setTimeout(() => {
        this.hasError = false;
      }, 8000);
    },

    showErrors(errors) {
      const errorKeys = Object.keys(errors);
      this.messages = errorKeys.reduce((accum, k) => [...accum, ...errors[k]], [])

      setTimeout(() => {
        this.messages = [];
      }, 8000);
    },

    resetForm(){
      this.passwordForm.currentPassword = '';
      this.passwordForm.password = '';
      this.passwordForm.passwordConfirmation = '';

      this.$refs[this.formRef].resetValidation();
      this.$refs[this.inputsRef].resetRulesCheckList();

      this.errors = [];
      this.hasError = false;
    },
  },
}