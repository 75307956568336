<template>
   <v-dialog v-model="isOpen" :persistent="true" max-width="900">
        <v-card>
            <v-card-title class="d-flex py-0 px-3" style="background-color: #000; color:#fff;">
                <h6 class="mb-0">Participación / Divisiones - {{selectedDivision.title}}</h6>
                <v-card-actions class="ml-auto p-0" style="height: inherit">
                    <v-btn icon dark @click="isOpen = false">
                        <v-icon color="white">mdi-close-circle</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card-title>

            <v-card-text class="pt-4">
                <div class="row">
                    <div class="col col-sm-5">
                        <div class="text-left">
                            <span>Trimestre:</span>
                            <v-select
                                :items="quarters"
                                item-text="date"
                                v-model="quarterSelected"
                                outlined
                                class="mt-1"
                                return-object
                                @change="getDivisionData()"
                            ></v-select>
                        </div>
                        <pie-chart v-if="!loader" :chartdata="chartdata4" :options="chartOptions" style="height: 230px;"/>
                    </div>
                    <div class="col col-sm-7">
                        <v-simple-table dense class="table__desing-general table__alternating-rows--grey">
                            <thead>
                                <tr class="custom-table-bordered">
                                    <th class="text-center" style="color:#00B99D !important;">Facturación Bruta</th>
                                    <th class="text-center" style="color:#FF9F00 !important;">% de participación</th>
                                    <th class="text-center" style="color:#746EE5 !important;">División</th>
                                </tr>
                            </thead>
                            <tbody class="custom-table-bordered">
                                <template v-if="loader">
                                <tr>
                                    <td colspan="3">
                                    <v-progress-linear
                                        indeterminate
                                        color="cyan"
                                    ></v-progress-linear><br>
                                    Cargando... Por favor espere
                                    </td>
                                </tr>
                                </template>
                                <template v-else>
                                <tr v-for="dpd in dataDivision" :key="dpd.DIVI">
                                    <td>${{ dpd.FACNETA | formatCurrency }}</td>
                                    <td>%{{ dpd.PORPART | formatCurrency}}</td>
                                    <td>{{ dpd.DIVI }}</td>
                                </tr>
                                </template>
                            </tbody>
                        </v-simple-table>
                    </div>                            
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import PieChart from "@/components/PieChart.vue";
import axios from "@/plugins/axios";
import Service from "@/mixins/service.js";
import { mapGetters } from "vuex";
export default {
    props:{
        value: { 
            type: Boolean,
            default: false
        },
        typeDivision:{
            type: String,
            required: true
        },
        userCode:{
            type: String,
            required: true
        },
        monthPeriod:{
            type: Number,
            required: true
        }
    },
    components: { PieChart },
    mixins: [Service],
    data(){
        return {
            loader: false,
            dataDivision: {},
            chartdata4: null,
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false
            },
            quarterSelected: {},
            quarters: []
        }
    },
    methods:{
        async getDivisionData(){       
          try{         
              this.loader = true;
              let response = await axios.get('/dayCustomerDelivery/' + this.selectedDivision.url, {
                  params:{
                    CODE: this.userCode,
                    VKORG: this.user.VKORG,
                    VTWEG: this.user.VTWEG,
                    P_TRIM:this.quarterSelected.trim,
                    P_YEAR:this.quarterSelected.year
                  }
              })            
                  
              this.dataDivision = response.data.data;
              this.chartData();
              this.loader = false;
          }catch(error){
              console.error(error);
          }
        },        
        chartData(){
            if(this.dataDivision.length > 0){       
                let colors = ["#FF9F00","#746EE5", "#00B99D"];              
                let label = []             
                let data = []             
                let background = []             

                this.dataDivision.forEach(function(value, index){                
                    const letters = '0123456789ABCDEF';
                    let color = '#';
                    if(index > 2){
                        for (let i = 0; i < 6; i++) {
                          color += letters[Math.floor(Math.random() * 16)];
                        }
                        colors.push( color ); 
                    }

                    label.push(value.DIVI)
                    data.push(value.PORPART)
                    background.push(colors[index])
                });

                this.chartdata4 = {
                  labels: label,
                  datasets: [
                    {
                      backgroundColor: background,
                      data: data,
                      fill: false,
                      tension: 0.1,
                    },
                  ],
                };
            }
        }      
    },
    computed: {
        ...mapGetters({
          user: "auth/user",
        }), 
        isOpen: {
            get(){
                return this.value;
            }, 
            set(value) { 
                this.$emit('input', value);
            }            
        },
        selectedDivision(){
            const divisionMap = {
                Customer: {url: 'participacion-divisiones-cte', title: 'Cliente'},
                Seller: {url: 'participacion-divisiones-asr', title: 'Asesor'},
                Manager: {url: 'participacion-divisiones-gte', title: 'Gerente'},
                RegionalManager: {url: 'participacion-divisiones-gte-reg', title: 'Gerente Regional'},
                Director: {url: 'participacion-divisiones-dir', title: 'Canal'},
            };
            return divisionMap[this.typeDivision] || divisionMap['Customer'];
        }
    },
    watch:{
        isOpen(newVal){
            if(newVal){
                this.quarters = this.calcularUltimosTrim(3, this.monthPeriod); //inicia desde el trimestre anterior del actual        
                this.quarterSelected = this.quarters[0];
                this.getDivisionData();
            }
        }
    }
}
</script>

<style>

</style>