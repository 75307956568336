<template>
    <div> 
      <v-simple-table dense>
        <template v-slot:default>
          <thead class="">
            <tr class="custom-table-bordered ">
              <th class="text-center text-body-1 text-dark" colspan="7"><b>Detalle de pagos</b></th>
            </tr>
            <tr class="custom-table-bordered ">
                <td></td>
              <td class="text-center table-heading text-body-2 ">Último trimestre cerrado</td>
              <td class="text-center table-heading text-body-2 ">Trimestre Actual</td>
            </tr>
          </thead>
          <tbody class="custom-table-bordered" v-if="!loading">
            <tr>
              <td class="table-heading small text-body-2">  Porcentaje de pagos VIA IEL  </td>
              <td class="table-heading text-body-2"> {{formatFloat(billing.PPIELM ?? 0,2)}} %  </td>
              <td class="table-heading text-body-2"> {{formatFloat(billing.PPIEL ?? 0,2)}} % </td>
            </tr>     
            <tr>
              <td class="table-heading small text-body-2">  Pagos VIA IEL  </td>
              <td class="table-heading text-body-2"> {{formatNumber(billing.MPIELM ?? 0,"$",0)}}  </td>
              <td class="table-heading text-body-2"> {{formatNumber(billing.MPIEL ?? 0,"$",0)}}   </td>
            </tr>  
              
            <tr>
              <td class="table-heading small text-body-2">  Total de pagos </td>
              <td class="table-heading text-body-2"> {{formatNumber(billing.totalCurrent ?? 0,"$",0)}}  </td>
              <td class="table-heading text-body-2"> {{formatNumber(billing.totalLast ?? 0, "$",0)}}   </td>
            </tr>   
          </tbody>
          <tbody v-else >
            <tr>
              <td colspan="7"> <v-progress-linear class="mt-3" indeterminate color="teal"></v-progress-linear> <span
                  class="text--disabled"> Cargando información</span></td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>  
    </div>
  </template>
  <script>
  
  import axios from "@/plugins/axios"; 
  import numbersFormats from "@/mixins/numbersFormats";
  
  export default ({
    name:'CustomerPaymentTypes',
    mixins:[numbersFormats],
    watch: {  
        customer(){ 
          this.getPaymentInfo();       
      }
  
    },
    props: {
      customer: {
        default: 0
      },    
    },
    computed: {   
    }, 
    data() {
      return {
        historicos: [],
        loading: false,
        billing: [],
        refreshData:false,
      }
    },  
    methods: {
      getPaymentInfo() {
        this.loading = true; 
        axios
          .post("seller/home/customer-payment-types", {
            customer_id: this.customer 
          })
          .then((response) => {
            if (response.data?.success == false) {
              console.log(response.data)
            } else {
              this.billing = response.data; 
            }
            this.loading = false;
          }).finally(() => {
            this.loading = false;
          })
          .catch((e) => {
            console.log(e);
            this.loading = false;
          });   
      },  
    },
    mounted(){
        this.getPaymentInfo()
    } 
  })
  </script>
  <style scoped> .yellow-tr {
     background: #FFD75E 0% 0% no-repeat padding-box;
     box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
     border: 1px solid #DDE1E6;
     opacity: 1;
   }</style>