<template>
    <div>
      <inactivity-time-modal 
        :value="true"
        :timeInit="timeInitChronometer"
        @finishSession="signOut"
        @continueSession="handleContinueSession"
      />
    </div>
  </template>
  
  <script>
  import InactivityTimeModal from '@/components/modals/InactivityTimeModal.vue';
  import userInactivityTime from '@/mixins/userInactivityTime';
  import { mapActions } from "vuex";
  
  export default {
    mixins: [userInactivityTime],
  
    components: {
      InactivityTimeModal
    },
  
    methods: {
      ...mapActions({
        signOutAction: "auth/signOut",
        removeStep: 'modalStepsAfterLogin/removeStep' 
      }),
  
      async handleContinueSession() {
        this.resetInactivityTimer(); 
        await this.removeStep('inactivity'); 
      },
  
      signOut() {
        this.signOutAction().then(() => {
          this.$router.replace({
            name: "SignIn",
          });
        });
      },
    },
  
  }
  </script>
  