<template>
  <div
    class="w-full bg-white d-flex flex-wrap"
    style="padding-left: 1em; padding-right: 1em"
  >
    <h5 class="w-full text-left mt-4" style="border-bottom: 3px solid #344c8b">
      DATOS DEL AVAL
    </h5>
    <v-row class="mt-10">
      <v-col cols="12" md="6" class="d-flex flex-wrap">
        <label for="" class="w-full text-left font-weight-black mb-4"
          >PDF del pagaré:</label
        >
        <button
          class="custom-button font-weight-bold"
          @click="$refs.promissorynote.click()"
        >
          Seleccionar archivo
        </button>
        <input
          type="file"
          style="display: none"
          ref="promissorynote"
          accept="application/pdf"
          @change="selectPromissoryNote"
          v-if="form.reset"
        />
        <span class="ml-2 font-weight-black" v-if="form.promissory_note">{{
          form.promissory_note.name
        }}</span>
        <span class="ml-2 font-weight-black" v-else>Sin selección</span>
      </v-col>
      <v-col cols="12" md="6" class="d-flex flex-wrap">
        <label for="" class="w-full text-left font-weight-black mb-4"
          >INE del aval*:</label
        >
        <button
          class="custom-button font-weight-bold"
          @click="$refs.endorsementid.click()"
        >
          Seleccionar archivo
        </button>
        <input
          type="file"
          style="display: none"
          ref="endorsementid"
          accept="application/pdf"
          @change="selectEndorsementId"
          v-if="form.reset"
        />
        <span class="ml-2 font-weight-black" v-if="form.card_id">{{
          form.card_id.name
        }}</span>
        <span class="ml-2 font-weight-black" v-else>Sin selección</span>
      </v-col>
      <v-col cols="12" md="6" class="d-flex flex-wrap">
        <label for="" class="w-full text-left font-weight-black mb-4"
          >Comprobante Domicilio del aval*:</label
        >
        <button
          class="custom-button font-weight-bold"
          @click="$refs.endorsementaddress.click()"
        >
          Seleccionar archivo
        </button>
        <input
          type="file"
          style="display: none"
          ref="endorsementaddress"
          accept="application/pdf"
          @change="selectEndorsementAddress"
          v-if="form.reset"
        />
        <span class="ml-2 font-weight-black" v-if="form.endorsementaddress">{{
          form.endorsementaddress.name
        }}</span>
        <span class="ml-2 font-weight-black" v-else>Sin selección</span>
      </v-col>
      <v-col cols="12">
        <label for="" class="w-full text-left font-weight-black"
          >Nombre del aval*:</label
        >
        <v-text-field v-model="form.name_endorsement"></v-text-field>
      </v-col>
      <v-col cols="12">
        <label for="" class="w-full text-left font-weight-black mb-1"
          >Comentarios*:</label
        >
        <v-textarea solo v-model="form.comment" class="comments"></v-textarea>
      </v-col>
    </v-row>
    <div class="w-full text-center mb-10">
      <button class="cancel-button" @click="cancel">Cancelar</button>
      <button class="success-button" @click="send">Enviar</button>
    </div>
  </div>
</template>

<script>
import axios from "@/plugins/axios";

export default {
  name: "PromissoryNoteForm",
  props: ["customerCode", "customerName"],
  data: () => ({
    form: {
      reset: true,
      promissory_note: null,
      card_id: null,
      name_endorsement: "",
      comment: "",
      endorsementaddress: null,
    },
  }),
  methods: {
    selectPromissoryNote(event) {
      this.form.promissory_note = event.target.files[0];
    },
    selectEndorsementId(event) {
      this.form.card_id = event.target.files[0];
    },
    selectEndorsementAddress(event) {
      this.form.endorsementaddress = event.target.files[0];
    },
    reset() {
      this.form = {
        reset: true,
        promissory_note: null,
        card_id: null,
        name_endorsement: "",
        comment: "",
        endorsementaddress: null,
      };
    },
    send() {
      this.$swal
        .fire({
          title: "Confirmar el envío del Pagaré",
          icon: "warning",
          showCancelButton: true,
          confirmButton: "#66BB6A",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Si, enviar!",
        })
        .then((result) => {
          if (result.value) {
            this.submit();
          }
        });
    },
    async getCustomerId() {
      return axios
        .get(`/customers?filter[custumer_code]=${this.customerCode}`)
        .then((response) => {
          if (response.data.data.length == 1) {
            this.customerId = response.data.data[0].id;
          } else {
            this.$swal.fire({
              title: "No se encontró el número del cliente seleccionado",
              icon: "warning",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal.fire({
            title: "Error al consultar al cliente",
            icon: "warning",
          });
        });
    },
    submit() {
      this.getCustomerId().then(() => {
        let formData = new FormData();

        formData.append("customer_id", this.customerId);
        formData.append("company_name", this.customerName);
        formData.append("customer_code", this.customerCode);
        formData.append("promissory_note", this.form.promissory_note);
        formData.append("card_id", this.form.card_id);
        formData.append("name_endorsement", this.form.name_endorsement);
        formData.append("comment", this.form.comment);
        formData.append("endorsementaddress", this.form.endorsementaddress);

        axios
          .post(`/promissory-notes`, formData)
          .then(() => {
            this.$swal({
              title: "Enviado a validación con el gerente",
              type: "success",
            });
            this.form.reset = false;
            this.cancel();
            this.reset();
          })
          .catch((error) => {
            this.$swal({
              title: "Ocurrio un error al enviar el pagaré",
              text: error,
              type: "warning",
            });
          });
      });
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style scoped>
.custom-button {
  background: #f1edec 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
  border: 1px solid #707070;
  opacity: 1;
  padding-left: 3em;
  padding-right: 3em;
  font-size: 0.7em;
}
.cancel-button {
  background: var(--danger-hover-c82333) 0% 0% no-repeat padding-box;
  background: #c82333 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
  border-radius: 5px;
  opacity: 1;
  width: 300px;
  color: #fff;
  margin-right: 0.5em;
  padding: 0.4em;
}
.success-button {
  background: #66bb6a 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
  border-radius: 5px;
  opacity: 1;
  width: 300px;
  color: #fff;
  margin-left: 0.5em;
  padding: 0.4em;
}
</style>