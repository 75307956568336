import axios from "axios";
import { setupCache } from 'axios-cache-interceptor';

import store from "@/store";
import moment from "moment";
import router from "@/router";

// Set config defaults when creating the instance
const Axios = axios.create({
  baseURL: `${process.env.VUE_APP_API_SERVER}`,
});

const customaxios = setupCache(Axios, {
  methods: ['get', 'post'], // Cache only get and post requests
  ttl: (1200 * 1000), // Time to expire data in cache, 20 minutes sets as default
  interpretHeader: false, // As backend returns no-cache, this is set to false, otherwise it will no cache the requests
  cachePredicate: {
    statusCheck: (status) => [200, 203].includes(status) // It will only cache request with 200 or 203 code responses
  },
});

customaxios.interceptors.request.use(function (config) {
  config.metadata = { startTime: performance.now() };

  if (!config.cachable) { // In api call set cachable: true if the request needs to be cached
    config.cache = false; 
  }

  // If signal is setted, request will be cancelled apart, user can pass noCancelRequest flag if he wants a no abortable request
  if (!config.signal && !config.noCancelRequest){ 
    config.signal = store.state.cancellableRequest.controller.signal;
  }

  return config;
}, function (error) {
  return Promise.reject(error);
});

customaxios.interceptors.response.use(function (response) {
  response.config.metadata.endTime = performance.now();
  /* Temporal medir rendimiento */
  if (response.config.url != '/metrics/front/save' && process.env.VUE_APP_PRODUCTION) {

    var req = JSON.parse(localStorage.getItem('timming_services')) || [];
    var time_ms = response.config.metadata.endTime - response.config.metadata.startTime
    req.push({
      time_ms: time_ms,
      method: response.config.method,
      request_url: response.config.baseURL + response.config.url, // Origin of the request 
      module: response.config.url, // Origin of the request 
      request_data: `${response.config?.data}`,
      host_ip: "",
      time_seconds: time_ms > 0 ? time_ms / 1000 : 0,
      created_at: moment().format('Y-M-D HH:mm:ss')
    });
    if (req.length > 50) {
      var data = req;
      req = [];
      localStorage.setItem('timming_services', JSON.stringify([]));
      customaxios.post(`/metrics/front/save`, data).catch((error) => {
        console.log(error)
      });
    } else {
      localStorage.setItem('timming_services', JSON.stringify(req));
    }
  }
  /* Temporal medir rendimiento */
  return response;
}, function (error) {
  return Promise.reject(error);
});

customaxios.interceptors.request.use(
  (config) => {
    let token = store.state.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers['Cache-Controls'] = 'public'
    return config;
  },
  (config) => config,
  (error) => Promise.reject(error)
);
customaxios.interceptors.response.use(
  (response) => response,
  (error) => {
    let originalRequest = error.config;

    if (error?.code) {
      throw error;
    }
    else if ([403].includes(error.response.status) && !originalRequest._retry) {
      originalRequest._retry = true;
      localStorage.setItem('token', null);
      localStorage.setItem('id', null);
      return router.replace("/login");
    }
    throw error;
  }
);

export default customaxios;
