<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">Editar Usuario</span>
    </v-card-title>
    <v-card-text>
      <v-form ref="formEvent" v-model="form.valid">
        <v-tabs v-model="tab" centered dark icons-and-text>
          <v-tabs-slider></v-tabs-slider>
          <v-tab href="#tab-1">
            General
            <v-icon>mdi-card-account-details</v-icon>
          </v-tab>
          <!-- <v-tab href="#tab-2">
                Perfil
                <v-icon>mdi-account</v-icon>
              </v-tab> -->
        </v-tabs>
        <template>
          <v-alert
            text
            outlined
            color="deep-orange"
            icon="mdi-alert"
            v-if="hasError"
            style="font-size: 12px"
          >
            <ul
              style="text-align: left"
              v-for="error in hasError"
              :key="error.id"
              class="mt-n2"
            >
              <li>{{ error[0] }}</li>
            </ul>
          </v-alert>
        </template>
        <v-tabs-items v-model="tab">
          <v-tab-item value="tab-1">
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    label="Nombre *"
                    prepend-icon="mdi-pen"
                    placeholder="Ingrese Nombre"
                    :rules="[(v) => !!v || 'El nombre es requerido']"
                    v-model="form.name"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="E-mail *"
                    prepend-icon="mdi-email"
                    placeholder="Ingrese E-mail"
                    :rules="[(v) => !!v || 'El e-mail es requerido']"
                    v-model="form.email"
                    type="email"
                    required
                  ></v-text-field>
                </v-col>
                <!-- <v-col cols="12">
                      <v-text-field
                        label="Contraseña *"
                        prepend-icon="mdi-lock"
                        placeholder="Ingrese Contraseña"
                        :rules="[(v) => !!v || 'La contraseña es requerida']"
                        v-model="form.password"
                        type="password"
                        required
                      ></v-text-field>
                    </v-col> -->
                <v-col cols="12">
                  <v-select
                    :items="rolOptions"
                    v-model="form.role_id"
                    multiple
                    label="Rol *"
                    item-text="alias"
                    item-value="id"
                    prepend-icon="mdi-account-group"
                    :rules="[(v) => !!v || 'El rol es requerido']"
                  ></v-select>
                </v-col>
                <v-col cols="12" v-if="showCode">
                  <v-text-field
                    label="Código empleado"
                    prepend-icon="mdi-badge-account-horizontal"
                    placeholder="Ingrese Código de Empleado"
                    v-model="form.employee_number"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" v-if="custumerCode">
                  <v-text-field
                    label="Código cliente"
                    prepend-icon="mdi-badge-account-horizontal"
                    placeholder="Ingrese Código de Cliente"
                    v-model="form.custumer_code"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" v-if="isSeller">
                  <v-select
                    label="Selecciona el gerente"
                    item-value="id"
                    item-text="label"
                    :items="items"
                    v-model="form.manager_id"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item value="tab-2"> </v-tab-item>
        </v-tabs-items>
        <small>*Indica campo requerido</small>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="red darken-1"
        text
        :disabled="loading"
        @click="onCloseDialog"
      >
        Cancelar
      </v-btn>
      <v-btn color="success darken-1" :disabled="loading" @click="updateUser">
        Guardar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from "@/plugins/axios";
export default {
  name: "EditUser",
  props: {
    user: {
      type: Object,
      required: true,
    },
    rolOptions: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    onCloseDialog: {
      type: Function,
      required: true,
    },
    cleanUser: {
      type: Function,
    }
  },
  data() {
    return {
      show: false,
      loading: false,
      tab: null,
      hasError: null,
      form: {
        id: null,
        valid: false,
        name: null,
        email: null,
        role_id: [],
        employee_number: null,
        custumer_code: null,
        manager_id: null,
      },
    };
  },
  computed: {
    showCode() {
      const rolesAccepted = this.rolOptions.filter((item) =>
        ["Manager", "Administrator", "Director", "Seller"].includes(item.name)
      );

      return this.form.role_id.some((role) =>
        rolesAccepted.map((item) => item.id).includes(role)
      );
    },
    custumerCode() {
      let customerRole = this.rolOptions.find(item => item.name == 'Customer');
      let customerPrRole = this.rolOptions.find(item => item.name == 'CustomerPR');
      for(let i = 0; i < this.form.role_id.length; i++) {
        if(this.form.role_id[i] == customerRole.id || this.form.role_id[i] == customerPrRole.id) {
          return true;
        }
      }
      return false;
    },
    isSeller() {
      const sellerRole = this.rolOptions.find((item) => item.name == "Seller");
      if (sellerRole && Array.isArray(this.form.role_id)) {
        return this.form.role_id.includes(sellerRole.id);
      }
      return false;
    },
  },
  mounted() {
    this.form.id = this.user.id;
    this.form.valid = false;
    this.form.name = this.user.name;
    this.form.email = this.user.email;
    this.form.role_id = this.user.roles.map((item) => item.id);
    this.form.employee_number = this.user.employee_number;
    this.form.custumer_code = this.user.custumer_code;
    this.form.manager_id = this.user.manager.length > 0 ? this.user.manager[0].id : null;
  },
  methods: {
    showItem() {
      console.log(this.user);
    },
    updateUser() {
      this.$refs.formEvent.validate();
      if (!this.form.valid) {
        return false;
      }

      if(this.custumerCode) {
        this.form.employee_number = null;
      } else {
        this.form.custumer_code = null;
      }

      axios
        .put(`/updateUser`, this.form)
        .then((response) => {
          if (response.status == 200) {
            this.$emit("userUpdated");
            //this.alertDialog("success", response.data.message);
            this.$refs.formEvent.resetValidation();
            this.hasError = null;
            this.resetForm();
            this.cleanUser();
            this.$toast.success('Usuario actualizado correctamente', {
              timeout: 3000,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.hasError = error.response?.data?.errors;
          if(error?.response?.data?.message) {
            this.$toast.error(error?.response?.data?.message);
          }
          //alert(error);
          /* this.alertDialog(
            "danger",
            "Error al guardar.\n" + error.response.data.message
          ); */
        })
        .finally(() => {
          this.loading = false;
        });
    },
    closeDialog() {
      this.$refs.formEvent.resetValidation();
    },
    resetForm() {
      this.form.id = null;
      this.form.valid = false;
      this.form.name = null;
      this.form.email = null;
      this.form.role_id = [];
      this.form.employee_number = null;
      this.form.custumer_code = null;
      this.form.manager_id = null;
    }
  },
};
</script>

<style></style>
