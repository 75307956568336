

<template>

    <v-container white lighten-5 fluid class="card-rounded renglonaje-director">
    
    
    
    
    
       
      <v-card class="mb-5">
       <div class="filter--containe mt-5">
            <div class="">
            <div class="row">
            <!-- bloque direccion / canal -->
            <div class="col-sm-12 col-md-6 col-xl-6 filter--container px-3 py-4" >
              <div class="row">
    
                <div class="col-12 col-md-6 pb-0" >
                <div class="d-flex align-items-center filter--bloq">
                   <b class="text-end" style="width: 200px;">Dirección / Canal :</b>
                   <v-select
                       v-model="selectedVTWEG"
                      :items="optionsCanal"
                       item-text="text"
                       item-value="value"
                       dense
                       solo
                       hide-details
                       class="ml-2"
                       @change="changeVTWEG"
                  
                      
                   ></v-select>
                   
                </div>
               </div>
                <div class="col-12 col-md-6 pb-0"  >
                <div class="d-flex align-items-center filter--bloq">
                   <b class="text-end" style="width: 200px;">Oficina / Cedis:</b>
                   <v-select
                      v-model="optionsSelected.selectedOfice"
                      :items="ofices"
                       item-text="text"
                       item-value="value"
                       dense
                       solo
                       hide-details
                       class="ml-2"
                       @change="changeOfice"
                  
                      
                   ></v-select>
                   
                </div>
               </div>
                <div class="col-12 col-md-6">
                <div class="d-flex align-items-center filter--bloq">
                   <b class="text-end" style="width: 200px;" >Gerente:</b>
                   <v-select
                      v-model="optionsSelected.selectedGerente"
                      :items="gerentes"
                       item-text="text"
                       item-value="value"
                       dense
                       solo
                       hide-details
                       class="ml-2"
                       @change="changeGerente"
                       
                   
                  
                      
                   ></v-select>
    
                   <select name="opciones" v-model="optionsSelected.selectedGerente" @change="changeGerente" class="v-select ml-2 d-none">
                      <option v-for="(item, index) in gerentes" :value="item.value" :key="index">{{ item.text }}</option>
                  </select>
    
                   
                </div>
               </div>
                <div class="col-12 col-md-6">
                <div class="d-flex align-items-center filter--bloq">
                   <b class="text-end" style="width: 200px;">Vendedor / Zona:</b>
                   <v-select
                      v-model="optionsSelected.selectedVendedor"
                      :items="vendedores"
                       item-text="text"
                       item-value="value"
                       dense
                       solo
                       hide-details
                       class="ml-2"
                  
                      
                   ></v-select>
                   
                </div>
               </div>
    
               <div class="col-12 col-md-6 p-0">
                <div class="d-flex align-items-center filter--bloq item-filte">
                   <b style="width: 170px;" class="text-end">Cliente / Pedido:</b>
    
               <div class="d-flex mx-1 align-items-center">
              <input type="text" placeholder="Buscar"   v-model="optionsSelected.searchInput" style="padding: 8px;outline: none; max-width:200px;border: solid 2px #eaecef;height: 28px;border-radius: 4px;"> 
             <!--   <v-text-field
                 v-model="optionsSelected.searchInput"
                 class=""
                 style="padding: 3px;outline: none;"
                 placeholder="Buscar"
                ></v-text-field>-->
                <button class="my-2" style="background-color: #c52232;width: 25px;padding: 3px;" >
                                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                  <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                  <g id="SVGRepo_iconCarrier">
                                  <g clip-path="url(#clip0_15_152)">
                                    <rect width="24" height="24" fill="#c52232"></rect>
                                    <circle cx="10.5" cy="10.5" r="6.5" stroke="#ffffff" stroke-linejoin="round" stroke-width="2"></circle>
                                     <path d="M19.6464 20.3536C19.8417 20.5488 20.1583 20.5488 20.3536 20.3536C20.5488 20.1583 20.5488 19.8417 20.3536 19.6464L19.6464 20.3536ZM20.3536 19.6464L15.3536 14.6464L14.6464 15.3536L19.6464 20.3536L20.3536 19.6464Z" fill="#ffffff" stroke="#ffffff" stroke-width="2"></path>
                                 </g>
                                   <defs>
                                    <clipPath id="clip0_15_152">
                                     <rect width="24" height="24" fill="white"></rect>
                                   </clipPath>
                                 </defs>
                              </g>
                            </svg>
                </button>
               </div>
    
              
                  
                   
                </div>
               </div>
    
               
    
               <div class="col-12 col-md-6" v-if="optionsSelected.selectedOfice || optionsSelected.selectedVendedor || optionsSelected.searchInput">
                <div class="d-flex align-items-center filter--bloq ">
                   <b class="text-end" style="width: 200px;" >Estado:</b>
                   <v-select
                      v-model="optionsSelected.selectedStatus"
                      :items="status"
                       item-text="text"
                       item-value="value"
                       dense
                       solo
                       hide-details
                       class="ml-2"
                    
                  
                      
                   ></v-select>
                   
                </div>
               </div>
               
              </div>
            </div>        
            <!-- end bloque direccion canal -->
    
    
            <div class="col-sm-12 col-md-6 col-xl-6 filter--container px-4 py-3" >
          
           <div class="d-flex justify-content-start mt-3">
            <b style="width: 200px;text-align: left;">Mostrar N° Días en:</b>
           </div>
              
          <div class="row mt-3" >
             <div class="col-12 col-md-6 col-xl-5 d-flex" style="border: 15px green;">
                <div>
                 
                  <div class="d-flex justify-content-left" >
                   <input type="radio" name="days" value="habiles" v-model="optionsSelected.selectedTypeDays" id="habiles"> 
                   <label  class="mx-2 text-left" for="habiles" >Días Habiles</label>
                  </div>
    
                  <div class="d-flex justify-content-left mt-2" v-if="!optionsSelected.selectedOfice && !optionsSelected.selectedVendedor">
                   <input type="radio" name="period" value="trimestral" id="Trimestral" v-model="optionsSelected.selectedTypePeriod" @change="changeVisualizationType">
                   <label for="Trimestral" class="mx-2">Trimestral</label>
                  </div>
    
    
                </div>
                <div>
    
                   <div class="d-flex justify-content-left">
                   <input type="radio" name="days" value="naturales" v-model="optionsSelected.selectedTypeDays" id="naturales">
                   <label for="naturales" class="mx-2 text-left">Días Naturales</label>
                  </div>
    
                  <div class="d-flex justify-content-left mt-2" v-if="!optionsSelected.selectedOfice && !optionsSelected.selectedVendedor">
                   <input type="radio" name="period" value="mensual" id="Mensual" v-model="optionsSelected.selectedTypePeriod" @change="changeVisualizationType" >
                   <label for="Mensual" class="mx-2">Mensual</label>
                  </div>
                </div>
             </div>
    
             <div  class="col-12 col-md-6 col-xl-7 d-flex flex-column align-items-start justify-content-left" >
                <b  class="px-0 mx-0">Pedidos Actualizados al {{currentDate}} {{ clock }}</b>
                
                <div class="d-flex align-items-center justify-content-left filter--bloq mx-0 mt-2" >
                   <b class="text-end" style="">Periodo:</b>
                   <v-select
                      v-model="optionsSelected.selectedPeriod"
                      :items="periods"
                       item-text="name"
                       item-value="value"
                       dense
                       solo
                       hide-details
                       class="ml-2"
                       @change="changePeriod"
                  
                      
                   ></v-select>
                
                   
                </div>
                
            
             </div>
          </div>
    
            
            </div>    
            
        
    
        
    
            </div>
            </div>
        </div>
    
    
      
    
    
       
            
    
    
    
         
        
    
      </v-card>

      <div class="row">
        <dir class="col-12 p-0">
          <tableLogisticOne :items="filteredItems"   :optionsSelected="optionsSelected" :isLoading="isLoading"/>
        </dir>
      </div>
    
        <!--table-->
    
        <section class="section-info" >
             
       
    
          <div class="row mt-5">
            <div class="col-12 col-md-6 p-0">
              <p class="text-sm-center text-md-start m-0" style="font-size: 12px;">*Última fecha de actualización: 15/Dic/2023 1:10:51 am</p>
            </div>
            <div class="col-12 col-md-6 p-0">
              
              <p class="text-sm-center text-md-end m-0" style="font-size: 12px;">
            Información confidencial. Prohibida su reproducción o divulgación total o parcial, así como <br> su uso o aprovechamiento sin autorización escrita de IUSA.</p>
            </div>
          
          </div>
         
         </section>
      
      
    
       </v-container>
       
    </template>
    
    
    <script>
    import axios from "@/plugins/axios"
    import tableLogisticOne from './table-logistic-one.vue';
    import moment from 'moment';
    
    import { mapGetters, mapActions } from "vuex";
    
    //import moment from 'moment'
    
    
    export default {
      components: { tableLogisticOne },
      data() {
        return {
          selectedView:'v1',
          selectedYear: '',     
          selectedVTWEG:'PR',     
          selectedCanal: 'Mostrar Todo',
          selectedVisualizationType:"mensual",
          optionsCanal: [
      
          { text: 'Minorista', value: 'DT' }, 
          { text: 'Distribución', value: 'PR' } 
          
          ],
          selectedPeriod: 'Mostrar Todo',
          periods:[],
          'gerentes': [],
          'vendedores': [],
          'ofices': [],
          'status': [],
          'searchInput': '',
          'currentDate': '',
          'clock':'',
        /*  'selectedGerente':"",
          
          'selectedOfice':"",
         
          'selectedVendedor':"",  */
        
          'optionsSelected': {
            selectedGerente: "",
            selectedOfice: "",
            selectedVendedor: "",
            searchInput: "",
            selectedPeriod: "",
            selectedTypePeriod: "mensual",
            selectedTypeDays: "naturales",
          },
         
         
        
        
          'items': [],
          'isLoading': false,
         
    
          
        };
      },
    
      mounted() {
        this.selectedYear =  moment().format("YYYYMM")
      //  alert(this.selectedYear)
        this.getData()
        this.currentDate = moment().format("D [de] MMMM [del] YYYY");
        setInterval(() => this.clock = moment().format("h:mm a"), 1000)
      },  
      computed: {
            ...mapGetters({
              authenticated: "auth/authenticated",
              user: "auth/user",
            }),
            filteredItems: function() {
        if (!this.optionsSelected.selectedGerente && !this.optionsSelected.selectedOfice && !this.optionsSelected.selectedVendedor && !this.optionsSelected.selectedStatus && !this.optionsSelected.searchInput) {
            // Si no se selecciona ningún gerente, mostrar todos los elementos pero sin repetir los nombres de gerentes
            const uniqueNames = Array.from(new Set(this.items.map(item => item.GERENTE)));
    
            const result = uniqueNames.map(name => {
                return {
                    SNAME: name,
                    items: this.items.filter(item => item.GERENTE === name)
                };
            });
    
     
        return result;
        }
    
        // Si se selecciona un gerente, aplicar de mostrar los vendedores o zonas de  ese gerente
        if (this.optionsSelected.selectedGerente && !this.optionsSelected.selectedOfice && !this.optionsSelected.selectedVendedor && !this.optionsSelected.selectedStatus && !this.optionsSelected.searchInput) {
           // Filtra los elementos por el gerente seleccionado
        const filteredItems = this.items.filter(item => item.GERENTE === this.optionsSelected.selectedGerente);
        
        // Obtiene las zonas únicas asociadas con el gerente seleccionado
        const uniqueZonas = Array.from(new Set(filteredItems.map(item => item.SNAME)));
    
        // Agrupa los pedidos por zona
        const result = uniqueZonas.map(vendedor => {
            return {
                SNAME: vendedor,
                items: filteredItems.filter(item => item.SNAME === vendedor)
            };
        });
    
        return result;
        }
    
        // Si se selecciona un gerente una oficina, aplicar el filtro normal
        return this.items.filter(item => {
            let searchField;
            if (!isNaN(this.optionsSelected.searchInput)) {
                // Si se ingresa un número, buscar tanto en VBELN como en KUNNR
                searchField = ['VBELN', 'KUNNR'];
            }
            return (!this.optionsSelected.selectedGerente || item.GERENTE.toUpperCase() === this.optionsSelected.selectedGerente.toUpperCase()) &&
                (!this.optionsSelected.selectedOfice || item.CEDIS.toUpperCase() === this.optionsSelected.selectedOfice.toUpperCase()) &&
                (!this.optionsSelected.selectedVendedor || item.BZIRK.toUpperCase() === this.optionsSelected.selectedVendedor.toUpperCase()) &&
                (!this.optionsSelected.selectedStatus || item.STATUS.toUpperCase() === this.optionsSelected.selectedStatus.toUpperCase()) &&
                (!this.optionsSelected.searchInput || 
                    searchField.some(field => 
                        item[field].toString().toUpperCase().includes(this.optionsSelected.searchInput.toString().toUpperCase())
                    )
                );
        });
    }
    
    //end filter
        },
      methods: {
        async getData() {
        
            this.isLoading = true;
            try {
                 
          
             const months = this.getmonths();
              const responses = await Promise.all(months.map(async month => {
            const yearMonth = month; // Concatenar el año y el mes
            const response = await axios.get('logistics-reports', {
                params: {
                    'P_SPMON': yearMonth,
                    'P_VTWEG': this.selectedVTWEG,
                },
                cachable:true,
            });
            return response.data.data; // Devolver solo los datos de la respuesta
        }));
    
        const jsonArray = responses.map(response => JSON.parse(response)); // Parsear cada respuesta a JSON
        this.items = jsonArray.flat(); // Aplanar el array de arrays y asignarlo a this.items
              //  this.getGerentes();
              this.getGerentes1();   //obtiene los gerentes reales
              
            
               this.getdataSelects(this.items);    
                this.getPeriods();
                this.sendToPDF({active: true}) 
              
    
    
             
                this.desactiveLoader();
               
            } catch (error) {
              this.desactiveLoader();
                console.error('Error fetching data:', error);
            }
            this.isLoading = false;
        },
        changeGerente(){
        
           if(!this.optionsSelected.selectedGerente){
    
          
            this.getdataSelects(this.items);
    
           } else{
            this.optionsSelected.selectedStatus = '';
            this.optionsSelected.selectedOfice = '';
           this.optionsSelected.selectedVendedor = '';
            const itemsGerente = this.items.filter(item => {
           
          
            return (item.GERENTE.toUpperCase() === this.optionsSelected.selectedGerente.toUpperCase())
               
        });
    
        this.getdataSelects(itemsGerente);
           }
    
            
        },
    
        getdataSelects(items){
                this.getCedis(items);
                this.getVendedores(items); //vendedor/zona
                this.getStatus(items);
        },
    
        changeVTWEG(){
         
          this.optionsSelected.selectedGerente = "";
          this.optionsSelected.selectedOfice = "";
         this.optionsSelected.selectedVendedor = "";
         this.optionsSelected.searchInput = "";    
        this.optionsSelected.selectedPeriod = "";
        this.optionsSelected.selectedStatus = "";
        this.optionsSelected.selectedTypePeriod = "mensual";
        this.optionsSelected.selectedTypeDays = "naturales";
    
    
          this.getData();
          this.actualizarVTWEG()
        },
        actualizarVTWEG() { 
                this.actualizarUsuario(this.selectedVTWEG).then(() => {  
                    localStorage.setItem("channel", this.selectedVTWEG);
                   // this.$router.push({ name: 'Home' });
                });
            },
        //
       
        changeView(){
        //  console.log(this.selectedView)
        },
        changeVisualizationType(){
        //  console.log(this.selectedVisualizationType)
          if(this.selectedVisualizationType === 'mensual'){
            this.selectedView = 'v1'
    
          }else if(this.selectedVisualizationType === 'trimestral'){
            this.selectedView = 'v3'
          }
        },
        getGerentes1(){  //obtener los gerentes reales
     // Utilizamos un conjunto para almacenar los nombres de los gerentes únicos
     const gerentesSet = new Set();
    
    // Agregamos la opción para mostrar todos
    this.gerentes = [{ text: 'Mostrar Todo', value: '' }];
    
    // Iteramos sobre los elementos items para obtener los nombres de los gerentes únicos
    this.items.forEach(item => {
        if (item.GERENTE && !gerentesSet.has(item.GERENTE)) {
            // Agregamos el nombre del gerente al conjunto y al array de opciones
            gerentesSet.add(item.GERENTE);
            this.gerentes.push({ text: item.GERENTE, value: item.GERENTE });
        }
    });
        },
        getGerentes() {   //obtener los vendedores reales
        // Utilizamos un conjunto para almacenar los nombres de los gerentes únicos
        const gerentesSet = new Set();
    
        // Agregamos la opción para mostrar todos
        this.gerentes = [{ text: 'Mostrar Todo', value: '' }];
    
        // Iteramos sobre los elementos items para obtener los nombres de los gerentes únicos
        this.items.forEach(item => {
            if (item.SNAME && !gerentesSet.has(item.SNAME)) {
                // Agregamos el nombre del gerente al conjunto y al array de opciones
                gerentesSet.add(item.SNAME);
                this.gerentes.push({ text: item.SNAME, value: item.SNAME });
            }
        });
    },
    
          getCedis(items){
            this.ofices = [];
            this.ofices.push({ text: 'Mostrar Todo', value: '' });
            items.forEach(item => {
              if(item.CEDIS){
                this.ofices.push({ text: item.CEDIS, value: item.CEDIS });
              }
            })
          },  
          getVendedores(items){
    
            this.vendedores = [];
            this.vendedores.push({ text: 'Mostrar Todo', value: '' });
            items.forEach(item => {
              if(item.BZIRK){
                this.vendedores.push({ text: item.BZIRK, value: item.BZIRK });
              }
            })
          },
          getStatus(items){
            this.status = [];
            this.status.push({ text: 'Mostrar Todo', value: '' });
            items.forEach(item => {
              if(item.STATUS){
                this.status.push({ text: item.STATUS, value: item.STATUS });
              }
            })
          },  
          getPeriods(){
          ///  console.log(this.items)
            this.periods.push({name:'Mostrar todo',value:''})
           // this.periods.push({ name: 'Últimos 30 días', value: 30 });
    
            const months = this.getmonths()
    
         //   console.log(months)
    
            months.forEach(month => {
                let name = moment(month).format('MMMM / YYYY')
                this.periods.push({name:name,value:month})
            });
    
    
       
          },
        changeOfice(){
          if(!this.optionsSelected.selectedOfice){
              this.optionsSelected.selectedStatus = "";
    
             
          }
         
        },
        getmonths(){
          const months = []
          let currentDate = moment(); // Obtener la fecha actual
               
    
                           for (let i = 0; i < 8; i++) {
                            months.push(currentDate.format('YYYYMM'));
                            currentDate = currentDate.subtract(1, 'months');
                        
    
                            }
            //  const months = ['202312','202311','202310','202309','202401', '202402', '202403', '202404']; // Array con los meses en formato de dos dígitos
               return months;
        },
      async  getDataPeriod(){
        this.items = [];
          this.isLoading = true;
            try {
                let response = await axios.get('logistics-reports',
                {
                  params: {
                    'P_SPMON': this.optionsSelected.selectedPeriod,
                    'P_VTWEG': this.selectedVTWEG,
                  }
                }
                );
    
                const jsonArray = JSON.parse(response.data.data);
                this.items = jsonArray;
    
              //  console.log(this.items)
                this.getGerentes1();
              
            
                this.getCedis();
                this.getVendedores(); //vendedor/zona
                this.getStatus();
               
             
              
    
    
             
                
               
            } catch (error) {
                console.error('Error fetching data:', error);
            }
            this.isLoading = false;
        },
        changePeriod(){
    
          this.optionsSelected.selectedPeriod != "" ? this.getDataPeriod() : this.getData()
            //this.getDataPeriod;
        },
        ...mapActions({
            sendToPDF: 'printer/sendToPDF',
            activeLoader: 'loader/activeLoader',
            desactiveLoader: 'loader/desactiveLoader',
            actualizarUsuario: 'auth/actualizarUsuario'
        }), 
       
    },
    
    beforeRouteEnter(to, from, next) {
        //se ejecuta el loader solo si venimos desde el componente SignIn
        next(vm => {
            if (from.name === 'SignIn') {
                vm.activeLoader();
            }
        });
    },
    
    };
    </script>
    
    
    
    
    <style scoped>
       .filter--container{
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
            opacity: 1;
            padding: 0px 0px;
            font-size: 16px;
          
        }
    
        .item-filter{
        /*  box-shadow: 0px -3px 6px red;*/
       border-top:solid 5px #00000029;
    
        }
    
    
         .filter--bloq{
            margin-left: 40px;
        }
    
        .filter--bloq:first-child{
            margin-left: 0;
        }
    
    
    
        .my-table a, .my-table tfoot td{
          font-weight:bolder!important;
        
        }
    
        .my-table thead,.my-table tfoot{
          box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
          background-color: #f2f2f2;
          font-weight: bold;
        }
    
        .my-table th,.my-table tfoot td{
          box-shadow: inset 0px 0px 6px #00000029, 0px 0px 6px #00000029;
          
        }
    
        .mountTh,.mountTd{
          box-shadow: none!important;
        
         
        }
        .mountTh{
         
          vertical-align: bottom;
         
        }
    
        b{
          font-size: 14px;
        }
    
        .title-mount{
        background-color: red;
        color: white;
        border-radius:15px;
        padding: 2px 10px;
        font-size: 10px;
        top: 5px ;
    
        }
    
        .title-backorder{
      
      
      
        padding: 2px 10px;
        font-size: 15px;
        top: 5px;
        left: 30px;
        font-weight: 800;
        }
    
        .hidden-shadow{
          box-shadow: inset 3px 0px 1px #f2f2f2, 54px 105px 121px #00000029!important;
    
        }
        
    
        @media (max-width: 600px) {
          .table-heading tr th{
            padding: 0px!important;
           }
    }
    
    .section-info{
      width: 99%;
      margin-top: 150px;
      padding:5px 0px ;
    }
    .v-text-field__details{
      display: none!important;
    }
    
    /* Estilo para hacer que el select se vea similar a uno de Vuetify */
  /*  .v-select {
    
      font-size: 14px;
      border: 1px solid #ced4da; 
      border-radius: 4px; 
      padding: 2px 10px;
      width: 100%; 
      background-color: #fff; 
      color: #495057; 
      outline: none; 
    }*/
    
    /* Estilo para el hover del select *//*
    .v-select:hover {
      border-color: #80bdff; 
    }*/
    
    /* Estilo para el foco del select *//*
    .v-select:focus {
      border-color: #007bff;
    }
    */
    
    </style>