<template>
  <v-app id="inspire" style="background-color: #ddd">
    <v-main>
      <v-container fluid fill-height>
        <v-row align="center" justify="center">
          <v-col col="12" md="8">
            <div
              class="alert alert-danger"
              role="alert"
              v-if="hasError"
              style="font-weight: 500"
            >
              {{ msg }}
            </div>
            <v-card
              class="elevation-10"
              style="
                padding-left: 50px;
                padding-right: 50px;
                padding-bottom: 10px;
                margin-top: 20px;
                border-radius: 19px;
              "
            >
              <v-row>
                <v-card-text class="mt-7">
                  <router-link :to="{ name: 'Home' }">
                    <img
                      src="img/propuesta-1-x2.svg"
                      alt=""
                      width="200"
                      class="mt-n5"
                    />
                  </router-link>
                  <h6 class="mt-5 mb-5">
                    <v-icon style="color: #000">
                      mdi-check-circle-outline
                    </v-icon>
                    <strong> Registro de Cuenta</strong>
                  </h6>
                  <p style="text-align: center">
                    IUSA en línea es un sistema que te permite
                    <strong
                      >consultar información relevante sobre tus compras,
                      estadísticas, avance de tus bonos, pagos, cartera,
                      pedidos, etc.</strong
                    >
                    en cualquier momento y desde cualquier lugar, con la cual
                    podrás tomar mejores decisiones sobre tus pedidos y así
                    aumentar tus ventas.
                    <strong
                      >Además IUSA en línea te permite cargar pedidos
                      directamente de tu sistema o capturarlos en tu
                      tienda.</strong
                    >
                  </p>
                  <v-form ref="form" v-model="formValid">
                    <v-row >
                      <v-col cols="12" md="6">
                        <v-date-picker
                          v-model="form.birthday"
                          mode="date"
                          :model-config="modelConfig"
                          :max-date="maxDate"
                        >
                          <template v-slot="{ inputValue, inputEvents }">
                            <v-text-field
                              dense
                              label="Fecha de nacimiento *"
                              outlined
                              placeholder="Ingrese DD/MM/AAAA"
                              :value="inputValue"
                              v-on="inputEvents"
                              required
                              :rules="[
                                (v) => !!v || 'La fecha de nacimiento es requerida',
                              ]"
                            ></v-text-field>
                          </template>
                        </v-date-picker>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          dense
                          label="Contraseña *"
                          outlined
                          placeholder="Ingrese Contraseña"
                          type="password"
                          v-model="form.password"
                          required
                          :rules="[
                            (v) => !!v || 'La contraseña es requerida',
                          ]"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          dense
                          label="E-mail *"
                          outlined
                          type="email"
                          placeholder="Ingrese E-mail"
                          v-model="form.username"
                          required
                          :rules="[
                            (v) => !!v || 'E-mail es requerido',
                            (v) => /.+@.+/.test(v) || 'E-mail debe ser válido',
                          ]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-file-input
                          label="Foto de perfil"
                          placeholder="Seleccionar archivo de imagen"
                          outlined
                          dense
                          append-outer-icon="mdi-image"
                          prepend-icon=""
                          v-model="form.image"
                        ></v-file-input>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          dense
                          label="Confirmar E-mail *"
                          outlined
                          placeholder="Confirmar E-mail"
                          type="email"
                          v-model="form.verify_email"
                          required
                          :rules="[
                            (v) => !!v || 'Debe confirmar su email',
                            (v) => /.+@.+/.test(v) || 'E-mail debe ser válido',
                          ]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          dense
                          label="Teléfono *"
                          outlined
                          placeholder="Ingrese Teléfono"
                          type="tel"
                          v-model="form.phone"
                          required
                          :rules="[
                            (v) => !!v || 'El teléfono es requerido',
                          ]"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          dense
                          label="Nombre *"
                          outlined
                          placeholder="Ingrese Nombre"
                          v-model="form.name"
                          required
                          :rules="[
                            (v) => !!v || 'El mombre es requerido',
                          ]"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        align="left"
                        justify="left"
                        class="mt-n7"
                      >
                        <v-container class="px-0" fluid>
                          <v-switch v-model="form.whatsapp">
                            <template v-slot:label>
                              ¿El número tiene WhatsApp? &nbsp;
                              <v-icon> mdi-whatsapp </v-icon>
                            </template>
                          </v-switch>
                        </v-container>
                      </v-col>
                    </v-row>
                    <v-row
                      class="mt-n10"
                      align="center"
                      justify="center"
                      style="color: rgb(153, 153, 153)"
                    >
                      <v-col col="12" md="8">
                        <v-checkbox
                          :label="`Compartir mis datos de registro con los proveedores de contenidos de IUSA en línea para fines de marketing.`"
                          v-model="form.sharedata"
                          style="margin-left: 40px"
                        ></v-checkbox>
                        <v-checkbox
                          :label="`No quiero recibir mensajes de marketing de IUSA en línea.`"
                          v-model="form.marketing"
                          style="margin-left: 40px"
                          class="mt-n3"
                        ></v-checkbox>
                        <p>*Son campos requeridos, favor de llenarlos.</p>
                      </v-col>
                    </v-row>
                    <v-row class="mt-n5" style="color: rgb(153, 153, 153)">
                      <p>
                        Al hacer click en registrar, acepta los
                        <strong style="color: red">
                          <a
                            href="https://www.tiendaiusa.com/terminos-y-condiciones"
                            target="_blank"
                            >Términos y condiciones de uso
                          </a></strong
                        >de IUSA en línea.
                      </p>
                    </v-row>
                    <v-row style="color: rgb(153, 153, 153)" class="mt-n1">
                      <p>
                        Para obtener más información acerca de cómo IUSA en
                        línea recopila, utiliza, comparte y protege tus datos
                        personales, consulta nuestra
                        <strong style="color: red"
                          ><a
                            href="https://www.tiendaiusa.com/aviso-de-privacidad"
                            target="_blank"
                          >
                            Política de privacidad
                          </a></strong
                        >
                        de IUSA en línea.
                      </p>
                    </v-row>
                    <div class="text-center mt-3">
                      <vue-recaptcha
                        :sitekey="$googleSitekey"
                        @verify="onVerify"
                        @expired="onExpired"
                        class="g-recaptcha"
                      ></vue-recaptcha>
                    </div>
                    <v-col cols="12" align="left" justify="left">
                      <div class="text-center">
                        <v-btn
                          @click="submit"
                          rounded
                          block
                          style="
                            background-color: #2fd410;
                            color: #fff;
                            text-transform: none;
                            font-weight: 800;
                          "
                          :disabled="procesando"
                        >
                          {{ procesando ? "Validando..." : "Registrar" }}
                        </v-btn>
                      </div>
                      <v-card-subtitle
                        class="mt-1 text-center"
                        style="color: #999"
                        ><router-link
                          :to="{ name: 'PasswordReset' }"
                          class="nav-link active"
                          style="color: rgb(153, 153, 153)"
                          ><span
                            >Si aún necesitas ayuda, ponte en contacto con el
                            <strong style="color: red"
                              >Soporte IUSA en línea.</strong
                            ></span
                          ></router-link
                        ></v-card-subtitle
                      >
                    </v-col>
                  </v-form>
                </v-card-text>
              </v-row>
              <div class="text-center mt-n8">
                <span style="font-weight: 800">Contáctanos:</span>
                <a
                  href="https://wa.me/5215551181585"
                  target="_blank"
                  style="text-decoration: none; color: #4caf50 !important"
                >
                  <v-btn class="mx-2" fab color="success" outlined small>
                    <v-icon>mdi-whatsapp</v-icon>
                  </v-btn>
                </a>
                <a
                  href="mailto:omartinez@iusa.com.mx"
                  target="_blank"
                  style="text-decoration: none; color: #1976d2 !important"
                >
                  <v-btn class="mx-2" fab color="primary" outlined small>
                    <v-icon>mdi-email-outline</v-icon>
                  </v-btn>
                </a>
              </div>
              <v-card-actions> </v-card-actions>
            </v-card>
            <div class="text-center ma-2">
              <v-snackbar v-model="snackbar" :timeout="timeout">
                {{ msg }}
                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="red"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                  >
                    Close
                  </v-btn>
                </template>
              </v-snackbar>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
import axios from "@/plugins/axios";
export default {
  name: "SignUp",
  components: {
    VueRecaptcha,
  },
  data() {
    return {
      snackbar: false,
      procesando: false,
      hasError: false,
      recaptcha: null,
      msg: null,
      timeout: 8000,
      formValid: false,
      errors: [],
      form: {
        birthday: "",
        password: "",
        username: "",
        image: [],
        verify_email: "",
        phone: "",
        name: "",
        whatsapp: false,
        sharedata: false,
        marketing: false,
      },
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD", // Uses 'iso' if missing
      },
      maxDate: new Date(),
    };
  },

  mounted() {
    let validDate = new Date(
      this.maxDate.getFullYear() - 18,
      this.maxDate.getMonth(),
      this.maxDate.getDate()
    );

    this.maxDate = validDate;
  },

  methods: {
    onVerify: function (response) {
      this.recaptcha = response;
    },
    onExpired: function () {
      this.recaptcha = null;
    },
    submit() {
      if (this.recaptcha != null) {
        this.$refs.form.validate();

        if(this.formValid == false) {
          this.msg = "Existen errores de validación en el formulario";
          this.hasError = true;
          this.snackbar = true;
          setTimeout(() => {
            this.hasError = false;
          }, 8000);
          return;
        } 
        this.procesando = true;
        let formData = new FormData();

        for (const [key, value] of Object.entries(this.form)) {
          formData.append(key, value);
        }
        axios
          .post(`${process.env.VUE_APP_BASE_SERVER}/auth/register`, formData)
          .then(() => {
            this.form = {};
            this.recaptcha = null;
            this.$router.replace({
              name: "ConfirmAccount",
            });
          })
          .catch((error) => {
            if (error.response.status == 422) {
              this.msg = "Existen errores de validación en el formulario"
              this.errors = error.response.data.issues;
              this.hasError = true;
              this.snackbar = true;
              setTimeout(() => {
                this.hasError = false;
              }, 8000);
            } else {
              alert(error);
            }
          })
          .finally(() => (this.procesando = false));
      } else {
        this.hasError = true;
        this.snackbar = true;
        setTimeout(() => {
          this.hasError = false;
        }, 8000);
        this.msg = "El campo reCAPTCHA es obligatorio.";
      }
    },
  },
};
</script>

<style scoped>
.text-center {
  text-align: center;
}

.g-recaptcha {
  display: inline-block;
}
</style>
