<template>
  <div class="my-select">
    <button class="my-select-button" @click="showOptions = !showOptions">
      <span>{{ selectedLabel }}</span>
      <i class="fas fa-chevron-down"></i>
    </button>
    <ul class="my-select-options" v-show="showOptions">
      <li
        class="my-select-item"
        v-for="(option, index) in options"
        :key="index"
      >
        <button @click="selectOption(option.KUNNR)">{{ option.NAME1 }}</button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "MySelect",
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showOptions: false,
    };
  },
  computed: {
    selectedLabel() {
      const selectedOption = this.options.find(
        (option) => option.KUNNR === this.value
      );
      console.info( 'selectedOption() => ', selectedOption );
      return selectedOption ? selectedOption.NAME1 : this.placeholder;
    },
  },
  methods: {
    selectOption(optionValue) {
      this.$emit("input", optionValue);
      this.showOptions = false;
    },
  },
};
</script>

<style scoped>
.my-select {
  position: relative;
  width: 100%;
}
.my-select-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.8em;
  background-color: #ebebeb;
  cursor: pointer;
}
.my-select-button span {
  flex-grow: 1;
  font-weight: bold;
  margin-right: 5px;
}
.my-select-button i {
  margin-left: 0.5rem;
}
.my-select-options {
  list-style: none;
  position: absolute;
  top: 100;
  background-color: white;
  width: 100%;
  min-height: 100px;
  padding-left: 0;
  border: 1px solid #ebebeb;
  z-index: 1;
  max-height: 300px;
    overflow-y: scroll;
}
.my-select-item {
    width: 100%;
    padding-top: 0.7em;
    padding-bottom: 0.7em;
}
.my-select-item:hover {   
  background-color: #ebebeb;
}
</style>