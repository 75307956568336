<template>
    <div id="component-search-customer-seller">
        <v-form ref="form" class="d-flex align-items-center">
              <img src="img/arrow-u-left-top.svg">
              <label class="ml-4 mr-2">Zona:</label>              
              <v-text-field 
              hide-details="auto"
                  small
                  dense
                  solo  
                  v-model="seller_code" 
                  @keyup="customer_code = null"
                  :rules="[(v) => v === null || /^\d+$/.test(v) || 'Solo números enteros']"                                            
              ></v-text-field>

              <label class="ml-4 mr-2">Cliente:</label>                     
              <v-text-field    
              hide-details="auto"                
                  small
                  dense
                  solo
                  v-model="customer_code"      
                  :rules="[(v) => v === null || /^\d+$/.test(v) || 'Solo números enteros']"  
                  @keyup="seller_code = null"                                            
              ></v-text-field>
              <div class="ml-4">
                <v-btn @click="sendCode()" class="btn-danger btn-custom rounded-pill" block>Consultar</v-btn>
              </div>
        </v-form>
    </div>
</template>

<script>
import axios from "@/plugins/axios";
import { mapGetters } from "vuex";
export default {
    data(){
        return{
            seller_code: null,
            customer_code: null
        }
    },  
    computed:{
        ...mapGetters({
          authenticated: "auth/authenticated",
          user: "auth/user",
        }),
    },  
    methods:{
        async sendCode(){            
            if (this.validate()) {
                try{
                    let P_USRNA = this.seller_code != null ? this.seller_code : this.customer_code
                    let P_IDKOU = this.seller_code != null ? 51 : 50
                    let response = await axios.get('/search-user',{
                        params : {
                            P_USRNA: P_USRNA,
                            P_VTWEG: this.user.VTWEG,
                            P_IDKOU: P_IDKOU
                        }
                    })
                    let data = response.data.data
                    if(data[0].FNMSG == '1'){
                        if(this.seller_code != null){ 
                            this.$setLocalStorageSeller(this.seller_code)   
                            // localStorage.setItem('currentSellerCode', JSON.stringify(this.seller_code));                     
                            this.$router.push({name: "SellerResults"})
                        }else{     
                            this.$setLocalStorageCustomer(this.customer_code)          
                            // localStorage.setItem('currentCustomerCode', JSON.stringify(this.customer_code));          
                            this.$router.push({name: "CustomerCoverDetails"})
                        }
                    }else{
                        const message = this.seller_code != null ? `No existe el código de gerente ${this.seller_code}` : `No existe el código de cliente ${this.customer_code}`
                        this.$toast.error(message)
                    }
                }catch(error){
                    this.$toast.error('Ocurrió un error al consultar el código. Por favor inténtelo de nuevo')
                }
            }
        },
        validate(){
            const isFormValid = this.$refs.form.validate();
            return (this.seller_code && this.seller_code.trim() !== '' && isFormValid) || (this.customer_code && this.customer_code.trim() !== '' && isFormValid)
        }
    }
}
</script>

<style >
    #component-search-customer-seller .btn-custom{
      background: transparent linear-gradient(180deg, #A82222 0%, #FF0000 100%) 0% 0% no-repeat padding-box;
          opacity: 1;
          color: #FFF !important;
          text-transform: inherit !important;
          font-size: 12px !important;

    } 
</style>