<template>
	<div>
		<v-card class="mx-auto mt-2 rounded-lg elevation-4 mb-4 ml4" outlined :style="'border: 4px solid #1086D4;'">
			<v-list-item-content>
                <div class="text-primary text-desp">
                    <v-icon style="color: #1086D4 !important">mdi-clock-time-five-outline</v-icon>
                    Oportunidad de Mercado
                </div>
                <div class="text-primary text-desp">Periodo de ventas : {{ dateText }}</div>
			</v-list-item-content>
		</v-card>
		<v-card v-if="step1" class="mx-auto rounded-xl elevation-5 ma-5" outlined>
			<v-row v-if="loading">
				<v-progress-linear :active="loading" :indeterminate="loading" top color="deep-purple accent-4"></v-progress-linear>
			</v-row>
			<br>
			<v-card-title>
				<v-text-field
				v-model="search"
				append-icon="mdi-magnify"
				label="Buscar"
				single-line
				hide-details
				></v-text-field>
			</v-card-title>
			<v-data-table
			dense
			:headers="headerOpportunityMarket"
			:items="dataOpportunityMarket"
			:items-per-page="10"
			item-key="name"
			class="elevation-1"
			locale="esp"
			:search="search"
			:footer-props="{
				showFirstLastPage: true,
				firstIcon: 'mdi-arrow-collapse-left',
				lastIcon: 'mdi-arrow-collapse-right',
				prevIcon: 'mdi-minus',
				nextIcon: 'mdi-plus',
			}">
                <template v-slot:[`item.DIVI`]="{ value }">
                    <a class="a-set" @click="setDivision(value)">{{ value }}</a>
                </template>
			</v-data-table>
            <v-footer>
                <p>
                    <b>Nota: Los datos corresponden al mes cerrado anterior</b>
                </p>
            </v-footer>
		</v-card>
        
        <v-card v-if="step2" class="mx-auto rounded-xl elevation-5 ma-5" outlined>
			<v-row v-if="loading">
				<v-progress-linear :active="loading" :indeterminate="loading" top color="deep-purple accent-4"></v-progress-linear>
			</v-row>
			<br>
            <v-row>
                <v-col cols="4" align-self="left">
                    <v-btn @click="backStep1()" color="green">Volver</v-btn>
                </v-col>
			</v-row>
			<br>
			<v-card-title>
				<v-text-field
				v-model="searchDivision"
				append-icon="mdi-magnify"
				label="Buscar"
				single-line
				hide-details
				></v-text-field>
			</v-card-title>
			<v-data-table
			dense
			:headers="headerOpportunityMarketDivision"
			:items="dataOpportunityMarketDivision"
			:items-per-page="10"
			item-key="name"
			class="elevation-1"
			locale="esp"
			:search="searchDivision"
			:footer-props="{
				showFirstLastPage: true,
				firstIcon: 'mdi-arrow-collapse-left',
				lastIcon: 'mdi-arrow-collapse-right',
				prevIcon: 'mdi-minus',
				nextIcon: 'mdi-plus',
			}">
                <template v-slot:[`item.BEZEI`]="{ value }">
                    <a class="a-set" @click="setMaterial(value)">{{ value }}</a>
                </template>
			</v-data-table>
            <v-footer>
                <p><b>Nota: Los datos corresponden al mes cerrado anterior</b></p>
            </v-footer>
		</v-card>

        <v-card v-if="step3" class="mx-auto rounded-xl elevation-5 ma-5" outlined>
			<v-row v-if="loading">
				<v-progress-linear :active="loading" :indeterminate="loading" top color="deep-purple accent-4"></v-progress-linear>
			</v-row>
			<br>
            <v-row>
                <v-col cols="4" align-self="left">
                    <v-btn @click="backStep2()" color="green">Volver a División</v-btn>
                </v-col>
			</v-row>
			<br>
			<v-card-title>
				<v-text-field
				v-model="searchMaterial"
				append-icon="mdi-magnify"
				label="Buscar"
				single-line
				hide-details
				></v-text-field>
			</v-card-title>
			<v-data-table
			dense
			:headers="headerOpportunityMarketMaterial"
			:items="dataOpportunityMarketMaterial"
			:items-per-page="10"
			item-key="name"
			class="elevation-1"
			locale="esp"
			:search="searchMaterial"
			:footer-props="{
				showFirstLastPage: true,
				firstIcon: 'mdi-arrow-collapse-left',
				lastIcon: 'mdi-arrow-collapse-right',
				prevIcon: 'mdi-minus',
				nextIcon: 'mdi-plus',
			}">
			</v-data-table>
            <v-footer>
                <p><b>Nota: Los datos corresponden al mes cerrado anterior</b></p>
            </v-footer>
		</v-card>
	</div>
</template>
<script>
import axios from "@/plugins/axios";
import { mapGetters } from "vuex";
export default {
    name: "OpportunityMarket",
    components: {},
  data() {
    return {
		search: "",
        searchDivision: "",
        searchMaterial: "",
        dateText: "",
		loading:false,
		dataOpportunityMarket:[],
        dataOpportunityMarketDivision:[],
        dataOpportunityMarketMaterial:[],
		headerOpportunityMarket: [
            { text: "DIVISIÓN", align: 'center',value: "DIVI" },
            { text: "SUCURSAL", align: 'center',value: "SUCURSAL" },
            { text: "UNIDADES", align: 'center',value: "TOTALCANT", light:true },
            { text: "IMPORTE", align: 'center',value: "TOTALAMOUNT", light:true },
		],
        headerOpportunityMarketDivision: [
            { text: "MATERIAL", align: 'center',value: "BEZEI" },
            { text: "DIVISIÓN", align: 'center',value: "DIVI" },
            { text: "SUCURSAL", align: 'center',value: "SUCURSAL" },
            { text: "UNIDADES", align: 'center',value: "TOTALCANT", light:true },
            { text: "IMPORTE", align: 'center',value: "TOTALAMOUNT", light:true },
		],
        headerOpportunityMarketMaterial: [
            { text: "DESCRIPCIÓN", align: 'center',value: "ARKTX" },
            { text: "MATERIAL", align: 'center',value: "BEZEI" },
            { text: "DIVISIÓN", align: 'center',value: "DIVI" },
            { text: "SUCURSAL", align: 'center',value: "SUCURSAL" },
            { text: "UNIDADES", align: 'center',value: "TOTALCANT", light:true },
            { text: "IMPORTE", align: 'center',value: "TOTALAMOUNT", light:true },
		],
		form:{
			client: "",
			division: "",
			material: "",
		},
        step1: true,
        step2: false,
        step3: false,
    };
  },
  async mounted() {
    let self = this;
    self.getOpportunityMarketGeneral();
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    })
  },
  methods: {
    backStep1(){
        this.step1 = true;
        this.step2 = false;
        this.step3 = false;
        this.dataOpportunityMarketDivision = [];
    },
    backStep2(){
        this.step1 = false;
        this.step2 = true;
        this.step3 = false;
        this.dataOpportunityMarketMaterial = [];
    },
	getOpportunityMarketGeneral() {
		this.loading = true;
		this.form.client = this.user.custumer_code;
		this.selectDivision = [];
		this.selectMaterial = [];
		this.dataOpportunityMarket = [];
		axios.post("opportunity-market/general", {
			client: this.form.client
		})
		.then((response) => {
			this.dataOpportunityMarket = response.data.data;
			this.selectDivision = response.data.select;
            this.dateText = response.data.date;
			this.loading = false;
		})
		.catch((e) => {
		console.warn(e);
		});
	},
    getOpportunityMarketDivision() {
        this.loading = true;
        this.dataOpportunityMarketDivision = [];
        axios.post("opportunity-market/division", this.form)
        .then((response) => {
            this.dataOpportunityMarketDivision = response.data.data;
            this.loading = false;
        })
        .catch((e) => {
            console.warn(e);
        });
    },
    getOpportunityMarketMaterial() {
		this.loading = true;
		this.dataOpportunityMarketMaterial = [];
		axios.post("opportunity-market/material", this.form)
		.then((response) => {
			this.dataOpportunityMarketMaterial = response.data.data;
			this.loading = false;
		})
		.catch((e) => {
			console.warn(e);
		});
	},
    setDivision(division){
        
        this.step1 = false;
        this.step3 = false;
        this.step2 = true;
        
        this.form.division = division;
        this.getOpportunityMarketDivision();
    },
    setMaterial(material){
        this.step1 = false;
        this.step2 = false;
        this.step3 = true;
        this.form.material = material;
        this.getOpportunityMarketMaterial();
    }
  },
};
</script>
<style scoped>
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}
.a-set{
    color:blue;
    text-decoration: underline;
}
</style>