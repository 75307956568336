<template>
  <div>
		<h4 class="text-left text-h6">{{ title }}</h4>

    <v-data-table
      id="cart-table"
      :headers="tableHeaders"
      :items="cartItemsFormatted"
      disable-pagination
      hide-default-footer
      no-data-text="Carrito vacío"
      :mobile-breakpoint="0"
      disable-sort
      :item-class="rowTableClass"
    >
      <template v-slot:[`item.textBox`]="{ item, index }">
        <v-textarea 
          class="my-2 input-width" 
          @change="(newVal) => emitTextChange(newVal, item, index)" 
          outlined rows="2"
          dense
          no-resize
          hide-details
        />
      </template>

      <template v-slot:[`item.unitsRequired`]="{ item, index }">
        <div v-if="editableUnits && !item.child && !item.noEditableUnits">
          <v-form @submit.prevent="emitQtyChange(item, index)">
            <plus-minus-input 
              v-model.number="item.unitsRequired"
              @onPlus="emitQtyChange(item, index)"
              @onMinus="emitQtyChange(item, index)"
              :min="item.packingValue"
              :increment-value="item.packingValue"
              :decrement-value="item.packingValue"
            />
          </v-form>
        </div>
        <div v-else>{{ item.unitsRequired }}</div>
      </template>

      <template v-slot:[`item.validation`]="{ item }">
        <v-chip v-if="!item.separator" label pill class="card-shadow validation-pill" dark :color="validationColor(item.validation)">{{ item.validation }}</v-chip>
      </template>
      <template v-slot:[`item.actions`]="{ item, index }">
        <v-btn v-if="!item.child" @click="emitDelete(item, index)" class="hide-on-print card-shadow rounded-2" color="white" fab x-small>
          <v-icon>mdi-trash-can-outline</v-icon>
        </v-btn>
      </template>
      <template v-slot:[`item.ZK14`]="{ item }">
        <div>{{ item.ZK14 }}</div><div>{{ item.ZK71 }}</div> 
      </template>
    </v-data-table>

    <v-row v-if="!hideCartTotals" no-gutters class="mt-10" justify="end">
      <div class="cart-total-box">
        <v-row class="card-shadow cart-total-item mb-1" dense>
          <v-col cols="7" sm="8">Subtotal: </v-col>
          <v-col cols="5" sm="4" class="text-left">{{ subtotal }}</v-col>
        </v-row>
        <v-row class="card-shadow cart-total-item mb-1" dense>
          <v-col cols="7" sm="8">I.V.A: </v-col>
          <v-col cols="5" sm="4" class="text-left">{{ iva }}</v-col>
        </v-row>
        <v-row class="card-shadow cart-total-item-secondary" dense>
          <v-col cols="7" sm="8">Importe Total: </v-col>
          <v-col cols="5" sm="4" class="text-left">{{ total }}</v-col>
        </v-row>
      </div>
    </v-row>
	</div>
</template>

<script>
  import PlusMinusInput from '@/components/PlusMinusInput';
  import numbersFormats from '@/mixins/numbersFormats';

  const cartTableHeader = [
    { text: 'CÓDIGO', value: 'materialId' },
    { text: 'DESCRIPCIÓN', value: 'name' },
    { text: 'Texto sucursal', value: 'textBox' },
    { text: 'EXISTENCIAS', value: 'stock' },
    { text: 'EXISTENCIAS CDPT', value: 'stockCdpt' },
    { text: 'U. MED', value: 'unitMeasure' },
    { text: 'EMPAQUE', value: 'packing' },
    { text: 'UNIDADES SOLICITADAS', value: 'unitsRequired' },
    { text: 'U. CONFIRMADAS', value: 'unitsConfirmed' },
    { text: 'RECORDATORIO', value: 'reminders' },
    { text: 'PRECIO UNITARIO', value: 'listPriceF' },
    { text: 'PRECIO CON DESCUENTO', value: 'discountPriceF' },
    { text: 'IMPORTE', value: 'productPriceF' },
    { text: 'RTP', value: 'ZK14' },
    { text: 'VALIDACION', value: 'validation' },
    { text: '#CARRETE', value: 'reelNumber' },
    { text: '', value: 'actions' },
  ];

	export default {
		name: 'CartOrderTable',

		props: {
			cartItems: {
				type: Array,
				required: true
			},

			headers: {
				type: Array,
				default: () => cartTableHeader
			},

      hideHeaders: {
        type: Array,
        default: () => []
      },

			total: {
				type: String,
				default: '$0'
			},

			subtotal: {
				type: String,
				default: '$0'
			},

			iva: {
				type: String,
				default: '$0'
			},

      title: {
        type: String,
        default: 'Carrito'
      },

			hideCartTotals: Boolean,
      editableUnits: Boolean,
      hasChilds: Boolean,
		},

    components: { PlusMinusInput },
    mixins: [numbersFormats],

    data(){
      return {
        cartRequiredQtyValues: [],
        tableHeaders: [],
      }
    },

    created() {
      if (this.hideHeaders.length > 0) {
        this.tableHeaders = this.headers.filter(h => !this.hideHeaders.includes(h.value))
      } else {
        this.tableHeaders = this.headers;
      }
    },

    methods: {
      formatCartItem(item){
        return {
          ...item,
          materialId: this.removeLeftZeros(item.materialId),
          stock: this.formatNumber(item.stock, '', 0),
          stockCdpt: this.formatNumber(item.stockCdpt, '', 0),
          packing: this.formatNumberOptionalDecimals(item.packing, '', 2),
          listPriceF: this.formatNumber(item.listPrice),
          discountPriceF: this.formatNumber(item.discountPrice),
          productPriceF: this.formatNumber(item.productPrice),
          ZK14: this.formatFloat(item.ZK14),
          ZK71: this.formatFloat(item.ZK71)
        }
      },

      validationColor(validation) {
        switch (validation) {
          case 'Disponible': return 'green'
          case 'No disponible': return 'red'
          case 'Parcial': return 'orange'
          default: return ''
        }
      },

      emitQtyChange(item, index) {
        if(item.unitsRequired <= 0)
          item.unitsRequired = item.packingValue;

        const emitIndex = !this.hasChilds ? index : item.originalIndex;

        this.$emit('updateUnitsItem', item, emitIndex)
      },

      emitTextChange(text, item, index) {
        this.$emit('textChange', text, item, index)
      },

      emitDelete(item, index) {
        const emitIndex = !this.hasChilds ? index : item.originalIndex;

        this.$emit('onDeleteItem', item, emitIndex)
      },

      rowTableClass(item) {
        if(this.hasChilds){
          var classes = 'card-shadow';

          if(item.separator) return `${classes} kit-separator`
          if(!item.child) return `${classes} kit-parent`
          
          return '';
        }
        return '';
      },
    },

    computed: {
      cartItemsFormatted() {
        if(!this.hasChilds)
          return this.cartItems.map(item => this.formatCartItem(item));

        const accum = [];

        this.cartItems.forEach((item, index) => {
          const kit = this.formatCartItem(item);
          kit.originalIndex = index;

          const childs = item.childs.map(item => ({ ...this.formatCartItem(item), child: true }));
          const separatorChild = { child: true, materialId: 'DESCRIPCIÓN DEL KIT', separator: true };
          accum.push(kit, separatorChild, ...childs);
        });

        return accum;
      }
    }
	}
</script>

<style>
.cart-total-box {
  font-weight: bold;
  max-width: 400px;
}

.cart-total-item {
  background-color: rgb(246,248,254);
  border-bottom: 4px solid rgb(111, 111, 111);
}

.cart-total-item-secondary {
  background-color: rgb(13, 110, 253);
  border-bottom: 4px solid rgb(111, 111, 111);
  color: white;
}

.validation-pill {
  width: 110px;
}

#cart-table > div > table > thead {
  box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029 !important;
  background-color: #0054a3;
}

#cart-table > div > table > thead > tr > th {
  color: white !important;
}
.v-data-table >>> .v-data-table-header {
  box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029 !important;
  background-color: #0054a3;
}

.v-data-table >>> .v-data-table-header > tr > th {
  color: white !important;
}

.kit-parent {
  background-color: rgb(244, 247, 255);
  position: relative;
}

.kit-separator {
  background-color: rgb(191, 220, 353);
  position: relative;
}

.kit-separator:hover {
  background-color: rgb(191, 220, 353) !important;
}

.kit-separator > td:first-child{
  position: absolute;
  left: 46%;
  top: 30%;
  height: 100px;
}

.kit-separator > td {
  border-bottom: 0px !important;
}

.input-width {
  min-width: 100px;
}
</style>