<template>
  <div class="container-fluid">
    <CardTitle title="HISTORICO DE COMPRAS" icon="archive-arrow-down-outline">
      <template v-slot:actions>

        <div class="d-flex d-inline pb-0 mb-0 justify-center">
          <v-checkbox class="ml-1 mr-0 mb-0 mt-0 pb-0" dense style="height: 25px; font-size: 10px !important;"
            v-model="showQuarter" @input="showDetails()" :disabled="(!dataHistoryShopping.length || loading)">
            <template v-slot:label>
              <div class="text-black">Trimestral </div>
            </template>
          </v-checkbox>
          <v-checkbox class="ml-1 mr-0 mb-0 mt-0 pb-0 " style="height: 25px;" v-model="showMontly" @input="showDetails()"
            :disabled="(!dataHistoryShopping.length || loading)" color="blue">
            <template v-slot:label>
              <div class="text-black">Mensual</div>
            </template>
          </v-checkbox>
        </div>
      </template>

      <template v-slot:content>

        <div class="elevation-5">
          <div class="card-body text-lg-center">
            <v-progress-linear indeterminate color="cyan"
              v-if="!dataHistoryShopping.length && loading"></v-progress-linear>
            <!-- <h2 class="h2">Tiempo de Entrega de Pedidos en los últimos 3 meses</h2> -->
            <v-simple-table dense class="box-shadow" v-if="dataHistoryShopping.length && !loading">
              <template v-slot:default>
                <thead class="d-sm-table-row-group">
                  <tr class="font-weight-bold font-size-x text-black">
                    <td v-for="(item, index) in headerHistoryShopping" :key="index" class="box-shadow">
                      <v-tooltip bottom v-if="index==headerHistoryShopping.length-1">
                        <template v-slot:activator="{ on, attrs }">
                          <span
                            v-bind="attrs"
                            v-on="on"
                          ><v-icon color="red">mdi-information-outline</v-icon></span>
                        </template>
                        <span>Información al día anterior</span>
                      </v-tooltip>
                      {{ item.text }}</td>
                  </tr>
                </thead>
                <tbody class="d-sm-table-row-group">
                  <tr class=" no-border custom-body-hs font-size-x" v-for="(item, index) in dataHistoryShopping"
                    :key="index">
                    <td :class="(index < 2 ? 'font-weight-bold ' : '') + ' text-left font-weight-regular fixed-column'"> {{
                      item.HC_TITULO.replaceAll(" ", "&nbsp;") }}
                    </td>
                    <template v-if="index < 4"> 
                    <td> 
                     <span v-if="index!=3"> <!-- Cuando es la fila renglones sin reorden (index 3) se oculta el valor --> 
                      <template v-if="index == 1 || index == 2" > <!-- Renglonaje  -->
                          <a class="text-black text-decoration-underline"
                              @click="goToReng(headerHistoryShopping[1].text, validateFamBox(index), item.HC_VAL8)"> {{ item.HC_VAL8 }}</a>  
                        </template>                      
                        <template v-else> <!-- Otros  -->
                          {{ item.HC_VAL8 }}
                        </template>   
                        </span>
                      <transition name="slide-fade" v-if="showMontly">
                        <MonthlyDetailsTable v-if="index < 2" :type="index"
                          :data="index == 0 ? monthlyBillingDetails[0] : monthlyRengDetails[0]" />
                      </transition>
                    </td>
                    <td >
                      <span v-if="index!=3"> <!-- Cuando es la fila renglones sin reorden (index 3) se oculta el valor -->
                        <template v-if="index == 1 || index == 2" > <!-- Renglonaje  -->
                          <a class="text-black text-decoration-underline"
                              @click="goToReng(headerHistoryShopping[2].text,validateFamBox(index), item.HC_VAL7)"> {{ item.HC_VAL7 }}</a>  
                        </template>                      
                        <template v-else> <!-- Otros  -->
                          {{ item.HC_VAL7 }}
                        </template>   
                        </span>
                      <transition name="slide-fade" v-if="showMontly">
                        <MonthlyDetailsTable v-if="index < 2" :type="index"
                          :data="index == 0 ? monthlyBillingDetails[1] : monthlyRengDetails[1]" />
                      </transition>
                    </td> 
                    <td > 
                      <span v-if="index!=3"> <!-- Cuando es la fila renglones sin reorden (index 3) se oculta el valor -->
                        <template v-if="index == 1 || index == 2" > <!-- Renglonaje  -->
                          <a class="text-black text-decoration-underline"
                              @click="goToReng(headerHistoryShopping[3].text, validateFamBox(index), item.HC_VAL6)"> {{ item.HC_VAL6 }}</a>  
                        </template>                      
                        <template v-else> <!-- Otros  -->
                          {{ item.HC_VAL6 }}
                        </template>  
                      </span>
                      <transition name="slide-fade" v-if="index < 2 && showMontly">
                        <MonthlyDetailsTable v-if="index < 2" :type="index"
                          :data="index == 0 ? monthlyBillingDetails[2] : monthlyRengDetails[2]" />
                      </transition>
                    </td>
                    <td> 
                      <span v-if="index!=3"> <!-- Cuando es la fila renglones sin reorden (index 3) se oculta el valor -->
                        <template v-if="index == 1 || index == 2" > <!-- Renglonaje  -->
                          <a class="text-black text-decoration-underline"
                              @click="goToReng(headerHistoryShopping[4].text, validateFamBox(index), item.HC_VAL5)"> {{ item.HC_VAL5 }}</a>  
                        </template>                      
                        <template v-else> <!-- Otros  -->
                          {{ item.HC_VAL5 }}
                        </template> 
                      </span> 
                      <transition name="slide-fade" v-if="showMontly">
                        <MonthlyDetailsTable v-if="index < 2" :type="index"
                          :data="index == 0 ? monthlyBillingDetails[3] : monthlyRengDetails[3]" />
                      </transition>
                    </td>
                    <td>
                      <template v-if="index == 1 || index == 2" > <!-- Renglonaje  -->
                        <a class="text-black text-decoration-underline"
                            @click="goToReng(headerHistoryShopping[5].text, validateFamBox(index), item.HC_VAL4)"> {{ item.HC_VAL4 }}</a>  
                      </template>
                      <template v-else> <!-- Otros  -->
                        {{ item.HC_VAL4 }}
                      </template>  
                      <transition name="slide-fade" v-if="showMontly">
                        <MonthlyDetailsTable v-if="index < 2" :type="index"
                          :data="index == 0 ? monthlyBillingDetails[4] : monthlyRengDetails[4]" />
                      </transition>
                    </td>
                    <td>
                      <template v-if="index == 1 || index == 2" > <!-- Renglonaje  -->
                        <a class="text-black text-decoration-underline"
                            @click="goToReng(headerHistoryShopping[6].text, validateFamBox(index), item.HC_VAL3)"> {{ item.HC_VAL3 }}</a>  
                      </template>
                      <template v-else> <!-- Otros  -->
                        {{ item.HC_VAL3 }}
                      </template> 
                      <transition name="slide-fade" v-if="showMontly">
                        <MonthlyDetailsTable v-if="index < 2" :type="index"
                          :data="index == 0 ? monthlyBillingDetails[5] : monthlyRengDetails[5]" />
                      </transition>
                    </td>
                    <td> 
                      <div> 
                        <span v-if="index == 1 || index == 2"><!-- Renglonaje  -->
                          <a class="text-black text-decoration-underline"
                            @click="goToReng(headerHistoryShopping[7].text, validateFamBox(index), item.HC_VAL2)"> {{ item.HC_VAL2 }}</a>
                        </span>
                        <span v-else-if="index == 3"> <!-- Renglones nuevos  -->
                          <a class="text-black text-decoration-underline" @click="goToReng(headerHistoryShopping[7].text, 2, item.HC_VAL2)">{{ item.HC_VAL2
                          }}  </a>
                        </span>
                        <span v-else> {{ item.HC_VAL2 }}   </span>
                      </div>
                      <transition name="slide-fade" v-if="showMontly">
                        <MonthlyDetailsTable v-if="index < 2" :type="index"
                          :data="index == 0 ? monthlyBillingDetails[6] : monthlyRengDetails[6]" />
                      </transition>
                    </td> 
                    </template>
                    <template v-else> 
                    <td colspan="7" />  
                    </template>
                      <td>
                      <div>
                        <span v-if="index == 4">
                          <a class="text-black text-decoration-underline" @click="goToReng(headerHistoryShopping[8].text, 3, item.HC_VAL1)"> {{ item.HC_VAL1 }}  </a>
                        </span>
                        <span v-else-if="index == 1 || index == 2">
                          <a class="text-black text-decoration-underline"
                            @click="goToReng(headerHistoryShopping[8].text, validateFamBox(index), item.HC_VAL1)"> {{ item.HC_VAL1 }} </a>                          
                        </span>
                        <span v-else-if="index == 3">
                          <a class="text-black text-decoration-underline" @click="goToReng(headerHistoryShopping[8].text, 2, item.HC_VAL1)">{{ item.HC_VAL1
                          }}  </a>
                        </span>
                        <span v-else-if="index == 5">
                          <a class="text-black text-decoration-underline" @click="goToReng(headerHistoryShopping[8].text, 4, item.HC_VAL1)"> {{ item.HC_VAL1 }}</a>
                        </span>
                        <span v-else>
                          {{ item.HC_VAL1 }}
                        </span>
                      </div>
                      <transition name="slide-fade" v-if="showMontly">
                        <MonthlyDetailsTable v-if="index < 2" :type="index"
                          :data="index == 0 ? monthlyBillingDetails[7] : monthlyRengDetails[7]" />
                      </transition>
                    </td>
                  </tr>
                  <tr class="font-size-x">
                    <td class="text-left">
                      Disponibilidad&nbsp;de&nbsp;pedidos&nbsp;de&nbsp;produtos&nbsp;no&nbsp;comprados&nbsp;PNC</td>
                    <td><v-icon :color="PNCColor" @click="showDialogPNCPPR(1)">mdi-circle</v-icon></td> 
                  </tr>
                </tbody>
<!-- Mobile version-->
                <tbody class="d-sm-none"
                  v-for="(item, index) in (showMontly ? dataHistoryShopping.slice(0, 2) : dataHistoryShopping)"
                  :key="index">
                  <tr class=" no-border custom-body-hs font-size-x">
                    <td colspan="8"
                      :class="(index < 2 ? 'font-weight-bold ' : '') + ' text-left font-weight-regular box-shadow'">
                      {{ item.HC_TITULO.replaceAll(" ", "&nbsp;") }}</td>
                  </tr>
                  <tr class="font-weight-bold font-size-x text-black">
                    <td v-for="(head, indx) in headerHistoryShopping.slice(1)" :key="indx"
                      class=" mobile-head-text pt-0 pb-0 pl-1 pr-1">  
                      <v-tooltip bottom v-if="indx==7">
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on" >
                            <v-icon color="red">mdi-information-outline</v-icon>
                          </span>
                        </template>
                        <span>Información al día anterior</span>
                      </v-tooltip> 
                      {{ head.text.replaceAll(' ', '&nbsp;')  }}  
                      <transition name="slide-fade" v-if="index < 2 && showMontly"> </transition>
                    </td>
                  </tr>
                  <tr v-if="!showMontly" class="font-size-x">
                    <td :class="index >= 1 ? 'text-black text-decoration-underline' : ''"> {{ item.HC_VAL8.replaceAll(' ',
                      '&nbsp;')
                    }}</td>
                    <td :class="index >= 1 ? 'text-black text-decoration-underline' : ''"> {{ item.HC_VAL7.replaceAll(' ',
                      '&nbsp;')
                    }}</td>
                    <td>{{ item.HC_VAL6.replaceAll(' ', '&nbsp;') }} </td>
                    <td>{{ item.HC_VAL5.replaceAll(' ', '&nbsp;') }}</td>
                    <td>{{ item.HC_VAL4.replaceAll(' ', '&nbsp;') }}</td>
                    <td>{{ item.HC_VAL3.replaceAll(' ', '&nbsp;') }}</td>
                    <td>{{ item.HC_VAL2.replaceAll(' ', '&nbsp;') }}</td>
                    <td>{{ item.HC_VAL1.replaceAll(' ', '&nbsp;') }}</td>
                  </tr>
                </tbody>
<!-- End Mobile version-->
              </template>
            </v-simple-table>
            <div v-if="dataHistoryShopping.length && !loading"
              class="d-flex flex-column font-size-x d-sm-none pl-1 pr-1 pt-1">
              <div class="d-flex justify-space-between">
                <span class="text-left">Disponibilidad de pedidos de produtos no comprados PNC</span>
                <span><v-icon @click="showDialogPNCPPR(1)" :color="PNCColor">mdi-circle</v-icon></span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </CardTitle>
    <v-dialog v-model="showReorderDetails" width="auto" max-width="800">
      <v-card>
        <div class="  custom-title d-flex">
          <div class="mt-2"> Renglones sin reorden en los ultimos 6 meses</div>
          <div class=" ml-auto">
            <v-btn icon @click="showReorderDetails = false">
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </div>
        </div>
        <v-card-text>
          <div class="pt-10">
            <span class="reorder-title">Renglones sin reorden en los ultimos 6 meses
              <div class="custom-line"></div>
            </span>
          </div>
          <v-simple-table v-if="!loadingReorderDetails" dense class="mt-10">
            <template v-slot:default>
              <thead>
                <tr class=" cstm-header-table">
                  <th class="text-center ">N°</th>
                  <th class="text-center ">Código</th>
                  <th class="text-center ">Descripción</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in reorderDetails" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td class="text-left">{{ transformMaterial(item?.MATNR) }}</td>
                  <td class="text-left">{{ item?.MAKTX }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-progress-linear v-if="loadingReorderDetails" indeterminate color="cyan"></v-progress-linear>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="showReorderDetails = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
    <v-dialog v-model="dialogPNCPPR" width="auto" max-width="800" :persistent="true">
      <div>
        <PNCDetails v-if="dialogPNC" @close-reorder-products="close()" :rules="customerRulesPNC"
          :wallet="walletInformation"></PNCDetails>
      </div>
    </v-dialog>
    <!-- /. Dialog -->
    <v-dialog v-model="dialog_pnc" width="auto">
      <v-card>
        <div class="modal--head pa-2">
          Renglones disponibles para pedido de productos no comprados PNC
          <a @click="dialog_pnc = false">
            <v-icon color="white darken-2">
              mdi-close-circle
            </v-icon>
          </a>
        </div>
        <v-card-title class="d-flex justify-center">
          <div class="text-h5">
            Renglones disponibles para pedidos de productos no comprados PNC
          </div>
        </v-card-title>
        <v-card-text>
          <div style="border: 1px solid #033C6E; width: 95%;"></div>
          <v-row>
            <v-col md="6">
              <br />
              <v-btn depressed large color="primary" :disabled="loaderPNC" block @click="generatePDF">
                Productos PNC/PC PDF &nbsp;<v-icon> mdi-file-pdf-box</v-icon>
              </v-btn>
            </v-col>
            <v-col md="6">
              <v-text-field v-model="searchPNCProducts" append-icon="mdi-magnify" label="Buscar" single-line
                hide-details></v-text-field>
            </v-col>
          </v-row>
          <br />
          <v-row>
            <v-col md="6" style="text-align: center">
              <p><b class="pnclabel">PRODUCTOS NO COMPRADOS</b></p>
              <v-progress-linear :active="loading" :indeterminate="loaderPNC" top
                color="deep-purple accent-4"></v-progress-linear>
              <v-data-table dense :headers="header_pnc" :items="itemPNC" :items-per-page="5" :search="searchPNCProducts"
                item-key="MAKTX" no-data-text="No hay datos disponibles" :loading="loaderPNC"
                loading-text="Cargando... Por favor espere">
              </v-data-table>
            </v-col>
            <v-col md="6" style="text-align: center">
              <p><b class="pnclabel">PRODUCTOS COMPRADOS</b></p>
              <v-data-table dense :headers="header_pnc" :items="itemPC" :items-per-page="5" :search="searchPNCProducts"
                :loading="loaderPNC" loading-text="Cargando... Por favor espere" item-key="MAKTX"
                no-data-text="No hay datos disponibles">
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="dialog_pnc = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- /. Dialog -->
    <!-- /. Dialog -->
    <v-dialog v-model="dialog_reg" width="auto">
      <v-card>
        <div class="modal--head pa-2">
          Renglones nuevos en el trimestre al día (Total últimos 4 trim #)
          <a @click="dialog_reg = false">
            <v-icon color="white darken-2">
              mdi-close-circle
            </v-icon>
          </a>
        </div>
        <v-card-text>
          <v-row>
            <v-col md="12">
              <v-text-field v-model="searchPNCProducts" append-icon="mdi-magnify" label="Buscar" single-line
                hide-details></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="12">
              <p><b class="pnclabel">PRODUCTOS NO COMPRADOS</b></p>
              <div style="border: 1px solid #033C6E; width: 95%;"></div>
              <v-data-table dense :headers="header_pnc2" :items="dataReg" :items-per-page="5" :search="searchPNCProducts"
                :loading="loading_reg" loading-text="Cargando... Por favor espere"
                no-data-text="No hay datos disponibles">
                <template v-slot:[`item.MATNR`]="{ item }"> {{ limitToSevenDigits(item.MATNR) }} </template>
                <template v-slot:[`item.item`]="{ index }"> {{ index + 1 }} </template>

              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="dialog_reg = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- /. Dialog -->

  </div>
</template>
<script>
import moment from "moment";
import axios from "@/plugins/axios";
import { mapGetters } from "vuex";
import { jsPDF } from "jspdf";
import 'jspdf-autotable';
import CardTitle from "./CardTitle.vue";
import PNCDetails from './PNCDetails.vue'
import MonthlyDetailsTable from '@/views/history_shopping/components/MonthlyDetailsTable.vue'
export default {
  props: ['walletInformation','generalInfoCustomer'],
  components: {
    CardTitle,
    PNCDetails,
    MonthlyDetailsTable
  },
  watch: {
    showMontly(value) {
      if (value) {
        this.showQuarter = false;
        if (this.monthlyRengDetails.length == 0) {
          this.loadMontlyRengDetails()
          this.loadMontlyBillingDetails()
        }
      } else {
        this.showQuarter = true;
      }
    },
    showQuarter(value) {
      if (value) {
        this.showMontly = false;
      } else {
        this.showMontly = true;
      }
    },
    walletInformation() { 
      this.customerDetails = {
          customer_code: this.walletInformation.KUNNR,
          channel: this.walletInformation.VTWEG,
          business: this.walletInformation.VKORG
        } 
      this.getHistoryShopping()  
    }
  },
  data() {
    return {
      dialogPNCPPR: false,
      showReorderDetails: false,
      loadingReorderDetails: false,
      reorderDetails: {},
      monthlyRengDetails: [],
      monthlyBillingDetails: {},
      dataHistoryShopping: [],
      showMontly: false,
      showQuarter: true,
      headerHistoryShopping: [],
      data: null,
      dataReg: [],
      dialogPNC: false,
      dialog_inv: false,
      dialog_reg: false,
      loading: false,
      quarter: null,
      customerDetails: {},
      dialog_pnc: false,
      loaderPNC: false,
      searchPNCProducts: "",
      PNCColor: 'gray',
      PPRColor: 'gray',
      header_pnc: [
        { text: "", align: "center", value: "item" },
        { text: "Código", align: "center", value: "MATNR" },
        { text: "Material", align: "center", value: "MAKTX" },
        { text: "Empaque", align: "center", value: "MEINS" },
      ],
      header_pnc2: [
        { text: "", align: "center", value: "item" },
        { text: "Código", align: "center", value: "MATNR" },
        { text: "Descripción", align: "center", value: "MAKTX" },
      ],
      itemPNC: [],
      itemPC: [],
      loading_reg: true,
      customerRulesPNC: [],
      customerRulesPPR: [],
    };
  }, 
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {
    getPNCRules() {
      const erdat = moment(this.walletInformation.ERDAT, 'YYYY-MM-DD');
      const today = moment();
      const seniority = today.diff(erdat, 'month');
      this.customerRulesPNC.seniorityRule = seniority >= 6 ? true : false
      this.customerRulesPNC.seniorityValue = seniority  
      this.customerRulesPNC.PPDP = parseInt(this.walletInformation.PPDP) <= 36 ? true : false
      this.customerRulesPNC.RTRIMA = parseInt(this.walletInformation.RTRIMA) >= 20 ?? false
      this.customerRulesPNC.PSNW = parseInt(this.walletInformation.PSNW) == 0 ?? false //validar PPN
      this.customerRulesPNC.AUFSD = parseInt(this.walletInformation.AUFSD) == 0 ?? false; //Sin saldos vencidos
      if (
        this.customerRulesPNC.seniorityRule &&
        this.customerRulesPNC.PPDP &&
        this.customerRulesPNC.RTRIMA &&
        this.customerRulesPNC.PSNW  &&
        this.customerRulesPNC.AUFSD
      ) {
        this.PNCColor = '#28A745';
      } else {
        this.PNCColor = 'red';
      }
    },
    getHistoryReng(type, trim) {
      this.dataReg = []
      var params = {
        'customer': this.customerDetails.customer_code,
        'VTWEG': this.customerDetails.channel,
        'trim': trim,
        'type': type
      }
      this.dialog_reg = true;
      this.loading_reg = true;
      axios
        .post("/history/reng", params, {
          noCancelRequest:true,
          cachable:true,
        })
        .then((response) => {
          if (!response.data.FNMSG)
            this.dataReg = response.data
        })
        .catch((error) => {
          console.log(error);
        }).finally(() => (this.loading_reg = false));

    },
    pcList() {
      this.loaderPNC = true;
      axios
        .post("/pcList", {
          client: this.customerDetails.customer_code,
          VTWEG: this.customerDetails.channel,
          TRIM: 1,
        })
        .then((response) => {
          const data = response.data;
          let info = data.length;
          this.loaderPNC = false;
          for (let i = 0; i < info; i++) {
            this.itemPC.push({
              item: (i + 1),
              MATNR: this.limitToSevenDigits(data[i].MATNR),
              MAKTX: data[i].MAKTX,
              MEINS: data[i].MEINS,
            });
          }
        })
        .catch((error) => {
          this.loaderPNC = false;
          // alert(error);
          console.log(error);
        });
    },
    limitToSevenDigits(number) {
      let numberString = String(number);
      if (numberString.length > 7) {
        numberString = numberString.slice(-7);
      }
      return Number(numberString);
    },
    generatePDF() {
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "in",
        format: "letter",
      });

      var img = new Image();
      img.src = "img/header_p.png";

      var footer = new Image();
      footer.src = "img/footer.png";
      doc.setFontSize(10);
      doc.addImage(img, "png", 0.5, 0.5, 7.5, 0.8);

      doc.autoTable({
        styles: {
          fillColor: [255, 255, 255],
        },
        alternateRowStyles: { fillColor: [255, 255, 255] },
        columns: [
          { header: "", dataKey: "title" },
          { header: "", dataKey: "content" },
        ],
        body: [
          { title: "Cliente:", content: this.generalInfoCustomer.KUNNR },
          { title: "Nombre:", content: this.generalInfoCustomer.NAME1 },
          { title: "RFC:", content: this.generalInfoCustomer.STCD1 },
          { title: "Email:", content: this.generalInfoCustomer.SMTP_ADDR },
          { title: "Teléfono:", content: this.generalInfoCustomer.TELF1 },
        ],
        columnStyles: {
          title: { fontStyle: "bold" },
        },
        margin: { left: 0.5, top: 1.5 },
      });

      doc.autoTable({
        styles: { halign: "center", fillColor: [255, 255, 255] },
        alternateRowStyles: { fillColor: [255, 255, 255] },
        tableLineColor: [169, 169, 169],
        tableLineWidth: 0.01,
        theme: "grid",
        columns: [
          { header: "Código", dataKey: "MATNR" },
          { header: "Materiales Comprados", dataKey: "MAKTX" },
          { header: "Empaque", dataKey: "MEINS" },
        ],
        body: this.itemPC,
        headStyles: {
          fillColor: [60, 179, 113],
          textColor: [255, 255, 255],
          lineColor: [169, 169, 169],
          lineWidth: 0.01,
        },
        bodyStyles: {
          lineColor: [169, 169, 169],
          lineWidth: 0.01,
        },
        margin: { left: 0.5, top: 1.5 },
      });

      doc.addPage();

      doc.autoTable({
        styles: { halign: "center", fillColor: [255, 255, 255] },
        alternateRowStyles: { fillColor: [255, 255, 255] },
        tableLineColor: [169, 169, 169],
        tableLineWidth: 0.01,
        theme: "grid",
        columns: [
          { header: "Código", dataKey: "MATNR" },
          { header: "Materiales No Comprados", dataKey: "MAKTX" },
          { header: "Empaque", dataKey: "MEINS" },
        ],
        body: this.itemPNC,
        headStyles: {
          fillColor: [255, 99, 71],
          textColor: [255, 255, 255],
          lineColor: [169, 169, 169],
          lineWidth: 0.01,
        },
        bodyStyles: {
          lineColor: [169, 169, 169],
          lineWidth: 0.01,
        },
        margin: { left: 0.5, top: 1.5 },
      });

      doc.addImage(footer, "png", 0.5, 9.8, 7.5, 0.6);
      doc.save("Productos.pdf");
    },
    getpncList(trim) {
      //var trim = 1;
      this.dialog_pnc = true;
      this.itemPNC = [];
      this.loaderPNC = true;
      axios
        .post("/pncList", {
          client: this.customerDetails.customer_code,
          VTWEG: this.customerDetails.channel,
          TRIM: trim,
        })
        .then((response) => {
          this.pcList();
          const data = response.data;
          let info = data.length;
          for (let i = 0; i < info; i++) {
            this.itemPNC.push({
              item: (i + 1),
              MATNR: this.limitToSevenDigits(data[i].MATNR),
              MAKTX: data[i].MAKTX,
              MEINS: data[i].MEINS,
            });
          }
        })
        .catch((error) => {
          // alert(error);
          console.log(error);
          this.loaderPNC = false
        })
        .finally(() => (this.loaderPNC = false));
    },
    goToReng(reng, type, value) {
      if(value == 0){
        this.$toast.info('Procesando información, favor de intentar más tarde.')
        return false;
      }      

      const paths = {
        1: 'box-tied',
        2: 'new-lines',
        3: 'without-reorder',
        4: 'special-orders-pnc',
        5: 'families'
      }

      let trim = reng.match(/\d+/)[0]
      let year = reng.match(/\b\d{4}\b/)[0]
      this.$router.push({
        name: 'Renglonaje',
        params: {
          trim: trim,
          year: year,
          view: paths[type]
        }
      })
    },
    transformCustomerCode(code) {
      // Utiliza expresiones regulares para eliminar los ceros a la izquierda
      return code.replace(/^0+/, '');
    },
    transformMaterial(number) {
      return number.slice(-6);
    },
    getReorderDetails() {
      this.loadingReorderDetails = true;
      this.showReorderDetails = true;
      var params = {
        'customer_id': this.customerDetails.customer_code,
        'P_VTWEG': this.customerDetails.channel,
        'quarter': 4
      }
      axios
        .post("/cover/history-shopping-reorder-details", params)
        .then((response) => {
          if (response.data)
            this.reorderDetails = response.data?.MAKTX ? [response.data] : response.data;
        })
        .catch((error) => {
          console.log(error);
        }).finally(() => (this.loadingReorderDetails = false));
    },
    loadMontlyRengDetails() {
      axios.post("cover/monthly-reng", {
        customer_id: this.customerDetails.customer_code,
        channel: this.customerDetails.channel
      },{
        noCancelRequest:true,
        cachable:true,
      })
        .then((response) => {
          this.monthlyRengDetails = response.data;
        })
        .catch((e) => {
          console.warn(e);
        }).finally(() => (this.loading = false));
    },
    loadMontlyBillingDetails() {
      axios.post("cover/monthly-billing", {
        customer_id: this.customerDetails.customer_code,
        channel: this.customerDetails.channel
      })
        .then((response) => {
          this.monthlyBillingDetails = response.data;
        })
        .catch((e) => {
          console.warn(e);
        });
    },
    showDetails() {

    },
    goToDetails(col) {
      const per = this.headerHistoryShopping[col].text;
      const year = per.slice(-4)
      const trim = per.slice(5, 6)
      const url = '/renglonaje/' + trim + '/' + year
      this.$router.push(url)
    },
    getHistoryShopping() {
      this.loading = true;
      axios.post("history-shopping", {
        client: this.customerDetails.customer_code
      },{
        noCancelRequest:true,
        cachable:true,
      })
        .then((response) => { 
          var headerCurrent = response.data.header;
          this.dataHistoryShopping = response.data.data;
          if (this.dataHistoryShopping.length > 0) {
            this.headerHistoryShopping = [
              {
                text: headerCurrent[0].HC_TITULO,
                align: "start",
                value: "HC_TITULO",
              },
              { text: headerCurrent[0].HC_VAL8, value: "HC_VAL1" },
              { text: headerCurrent[0].HC_VAL7, value: "HC_VAL2" },
              { text: headerCurrent[0].HC_VAL6, value: "HC_VAL3" },
              { text: headerCurrent[0].HC_VAL5, value: "HC_VAL4" },
              { text: headerCurrent[0].HC_VAL4, value: "HC_VAL5" },
              { text: headerCurrent[0].HC_VAL3, value: "HC_VAL6" },
              { text: headerCurrent[0].HC_VAL2, value: "HC_VAL7" },
              { text: headerCurrent[0].HC_VAL1, value: "HC_VAL8" },
            ]
          }
        })
        .catch((e) => {
          console.warn(e);
          this.loading = false;
        }).finally(() => {
          this.getPNCRules();
          this.loading = false
          }
          );
    },
    close() {
      this.dialogPNCPPR = false;
      this.dialogPNC = false;
    },
    showDialogPNCPPR(type) {
      this.dialogPNCPPR = true;
      if (type == 1) {
       // this.getPNCRules();
        this.dialogPNC = true;
      }
    },
    close2() {
      this.dialog_inv = false;
    },
    format(value) {
      let result = Math.trunc(value);
      return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    validateFamBox(index){
      return index == 1 ? 1 : 5;
    }
  },
};
</script>
<style scoped>
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}

.modal--head {
  background: #000;
  color: #fff;
  font-size: 16px;
  padding-left: 5px;
  display: flex;
  justify-content: space-between;
}

.pnclabel {
  text-align: left;
  font: normal normal bold 20px/10px Roboto;
  letter-spacing: 0px;
  color: #173477;
  opacity: 1;
}

.fixed-column {
    position: sticky;
    left: 0;
    background-color: white;
    z-index: 1;
    /* Agregar borde a la derecha para diferenciar la columna fija */
    border-right: 2px solid #ddd;
}
</style>
<style src="@/assets/css/customerHome.css" scoped></style>
