import { mapActions } from "vuex";

export default {
  data() {
    return {
      inactivityTimeout: null,
      isOpenInctivityTimeModal: false,
      timeInitChronometer: 60, // tiempo por default en segundos
      isTabActive: true,
    };
  },
  mounted() {
    if (process.env.VUE_APP_INACTIVITY_TIME) {
      this.initInactivityTimerChronometer();
      this.initInactivityTimer();
      document.addEventListener("visibilitychange", this.handleVisibilityChange);
    }
  },

  methods: {
    ...mapActions('modalStepsAfterLogin', [
      'addManualStep',
      'updateModalState',
      'findStepByKey',
      'changeCurrentStepValue',
      'updateStepStatus',
    ]),
    handleInactive() {
      this.signOutAction().then(() => {
        this.$router.replace({
          name: "SignIn",
        });
      });
    },
    setupInactivityTimer(time) {
      document.addEventListener("mousemove", this.resetInactivityTimer);
      document.addEventListener("keydown", this.resetInactivityTimer);
      this.inactivityTimeout = setTimeout(async () => {
        if (this.authenticated) {
          this.isOpenInctivityTimeModal = true;
          this.addManualStep({ key: 'inactivity', text: 'Cierre de sesión' });
          const inactivityStep = await this.findStepByKey('inactivity');

          if (inactivityStep) {
            inactivityStep.visited = true;
            this.updateStepStatus(inactivityStep);
            this.changeCurrentStepValue(inactivityStep.value);
            this.updateModalState(true);
          }
        }
      }, time);
    },
    resetInactivityTimer() {
      clearTimeout(this.inactivityTimeout);
      if (process.env.VUE_APP_INACTIVITY_TIME) {
        this.setupInactivityTimer(process.env.VUE_APP_INACTIVITY_TIME);
      }
    },
    initInactivityTimer() {
      const inactivityTime = process.env.VUE_APP_INACTIVITY_TIME;
      if (inactivityTime !== undefined) {
        this.setupInactivityTimer(inactivityTime);
      }
    },
    initInactivityTimerChronometer() {
      const inactivityTimeChronometer = process.env.VUE_APP_CHRONOMETER_SECONDS;
      this.timeInitChronometer =
        inactivityTimeChronometer !== undefined && inactivityTimeChronometer > 0
          ? inactivityTimeChronometer
          : this.timeInitChronometer;
    },
    handleVisibilityChange() {
      this.isTabActive = !document.hidden; 
      if (this.isTabActive) {
        this.resetInactivityTimer();
      } else {
        this.startSessionTimeout();
      }
    },
    startSessionTimeout() {
      clearTimeout(this.inactivityTimeout);
      if (process.env.VUE_APP_INACTIVITY_TIME) {
        this.inactivityTimeout = setTimeout(() => {
          this.handleInactive();
        }, process.env.VUE_APP_INACTIVITY_TIME);
      }
    },
  },
  beforeDestroy() {
    document.removeEventListener('mousemove', this.resetInactivityTimer);
    document.removeEventListener('keydown', this.resetInactivityTimer);
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    clearTimeout(this.inactivityTimeout);
  }
};
