<template>
  <div>
    <v-data-table
      :headers="tableHeaders"
      :items="itemsFormated"
      :search="search"
      :loading="loading"
      loading-text="Cargando información..."
      no-data-text="No se encontro información"
      :footer-props="{ itemsPerPageText: 'Mostrar en grupo de', itemsPerPageAllText: 'Todos', pageText: '{0}-{1} de {2}' }"
      :mobile-breakpoint="0"
      :sort-by="variant ? 'ERDAT' : 'DVBELN' "
      sort-desc
    >
      <template v-slot:[`item.VBELN`]="{ item }">
        <div
          class="text-decoration-underline indigo--text cursor-pointer"
          @click="$emit('onQuotationClick', item.VBELN)"
        >
          {{ item.VBELN }}
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item, index }">
        <v-btn
          @click="launchOrderHeadersModal(item)"
          color="blue"
          dark
          small
          v-if="!variant"
          >Textos cabecera</v-btn
        >
        <v-btn
          @click="$emit('onActionClick', item.VBELN, index)"
          color="blue"
          icon
          dark
          v-else
          ><v-icon>mdi-notebook-outline</v-icon></v-btn
        >
      </template>
    </v-data-table>

    <order-headers-modal
      v-if="!variant"
      v-model="showOrderHeadersModal"
      :headers="selectedOrderHeaders"
      :order-number="selectedQuotationId"
    />
  </div>
</template>

<script>
import OrderHeadersModal from '@/components/modals/OrderHeadersModal.vue';
import numbersFormats from '@/mixins/numbersFormats';
import dates from '@/mixins/dates';

const shortTable = [
  { text: '# Cotiza', value: 'VBELN' },
  { text: 'Fecha', value: 'DVBELN' },
  { text: 'Fecha val', value: 'DVALID' },
  { text: 'Fecha val', value: 'DREQH' },
  { text: '', value: 'actions' },
];

const fullTable = [
  { text: 'Fe. cotización', value: 'ERDAT' },
  { text: 'Of. vta', value: 'VKBUR' },
  { text: 'Centro', value: 'WERKS' },
  { text: 'Cotización', value: 'VBELN' },
  { text: 'Tex.', value: 'actions' },
  { text: 'Estado', value: 'STATUS' },
  { text: 'Rechazo', value: 'BEZEI' },
  { text: 'Rechazo ped.', value: 'BEZEIP' },
  { text: 'Orden', value: 'BSTKD' },
  { text: 'Pos', value: 'POSNR' },
  { text: 'Material', value: 'MATNR' },
  { text: 'Descripción', value: 'MAKTX' },
  { text: 'Cantidad', value: 'KWMENG' },
  { text: 'UM', value: 'MEINS' },
  { text: 'Importe', value: 'NETWR' },
  { text: 'Plazo de entrega', value: 'DELCO' },
  { text: 'Lib', value: 'BSTZD' },
  { text: 'Creado', value: 'ERNAMP' },
  { text: 'Pedido', value: 'VBELNP' },
  { text: 'Rem planta', value: 'VBELNR' },
  { text: 'Rem CDPT', value: 'VBELNR2' },
  { text: 'Cant. ent. planta', value: 'LFIMG' },
  { text: 'Cant. ent. CDPT', value: 'LFIMG2' },
  { text: 'Saldo planta', value: 'SALDO1' },
  { text: 'Saldo CDPT', value: 'SALDO2' },
];

export default {
  name: 'RequestForQuotationTable',

  props: {
    items: {
      type: Array,
      required: true,
    },

    search: {
      type: String,
      default: null,
    },

    loading: Boolean,
    fullTable: Boolean,
    variant: Boolean,
  },

  components: { OrderHeadersModal },
  mixins: [numbersFormats, dates],

  data() {
    return {
      tableHeaders: shortTable,

      selectedOrderHeaders: {},
      selectedQuotationId: null,
    };
  },

  created() {
    if (this.fullTable) this.tableHeaders = fullTable;
  },

  methods: {
    launchOrderHeadersModal(item) {
      this.selectedQuotationId = item.VBELN;
      this.selectedOrderHeaders = item;
    },
  },

  computed: {
    showOrderHeadersModal: {
      set(newVal) {
        if (!newVal) {
          this.selectedQuotationId = null;
          this.selectedOrderHeaders = {};
        }
      },

      get() {
        return this.selectedQuotationId !== null;
      },
    },

    itemsFormated(){
      return this.items.map(i => {
        return {
          ...i,
          MATNR: this.removeLeftZeros(i.MATNR) || '',
          POSNR: this.removeLeftZeros(i.POSNR) || '',
          ERDAT: this.removeTimeFromDate(i.ERDAT) || '',
          DVALID: this.removeTimeFromDate(i.DVALID) || '',
          DREQH: this.removeTimeFromDate(i.DVALID) || '',
          KWMENG: this.formatNumber(i.KWMENG, '', 0),
          NETWR: this.formatNumber(i.NETWR),
          LFIMG: this.formatNumber(i.LFIMG, '', 0),
          LFIMG2: this.formatNumber(i.LFIMG2, '', 0),
          SALDO1: this.formatNumber(i.SALDO1, '', 0),
          SALDO2: this.formatNumber(i.SALDO2, '', 0),
        }
      })
    }
  },
};
</script>
