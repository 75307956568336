<template>
  <v-dialog
    v-model="showDialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="indigo">
        <v-btn icon dark @click="showDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title v-if="orderNumber !== null">
					Pedido: {{ orderNumber }}
				</v-toolbar-title>
      </v-toolbar>

      <products-order-table :products="products" :total="total" />
    </v-card>
  </v-dialog>
</template>

<script>
import ProductsOrderTable from "@/components/tables/ProductsOrderTable.vue";

export default {
  name: "ProductsOrderModal",

  components: {
    ProductsOrderTable,
  },

  props: {
    value: {
      type: Boolean,
      default: true,
    },

    orderNumber: {
      type: String,
      default: null,
    },

    products: {
      type: Array,
      required: true,
    },

    total: {
      type: Number,
      default: null,
    },
  },

  computed: {
    showDialog: {
      set(newVal) {
        this.$emit("input", newVal);
      },

      get() {
        return this.value;
      },
    },
  },
};
</script>
