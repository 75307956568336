<template>
  <div class="container-fluid pa-xs-0">
    <v-skeleton-loader
      v-bind="attrs"
      type="card-avatar, article, actions"
      v-if="customerInformation == null"
    ></v-skeleton-loader>
    <div v-else class="mt-2">
      <CardTitle :title="'DATOS CLIENTE (' + (customerInformation.VTWEG == 'DT' ? 'MINORISTA' : 'DISTRIBUCIÓN') +')'" :bReturn="true" icon="bookmark-check-outline">
        <template v-slot:content>
          <v-row class="ml-0 mr-n1 ">
            <v-col cols="12" sm="12" md="7">
              <v-row style=" -webkit-box-shadow: -5px 4px 5px 0px rgba(0, 0, 0, 0.09); -moz-box-shadow: -5px 4px 5px 0px rgba(0, 0, 0, 0.09);box-shadow: -5px 4px 5px 0px rgba(0, 0, 0, 0.09);">
                <v-col
                  md="4"
                  sm="4"
                  class="mt-2 text-left font-size-x pl-1 pr-0"
                >
                  <span class="text-center font-weight-black">
                    {{ customerInformation.NAME1 }}</span
                  >
                  <br />
                  <span class="font-weight-black">RFC:</span
                  >{{ customerInformation.STCD1 }}
                </v-col>
                <v-col md="3" sm="3" class="text-left mt-0 pl-0 pr-0">
                  <div class="d-flex d-inline-block">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon  v-on="on" v-bind="attrs" size="20" color="#9E3749" >mdi-cake-variant</v-icon >
                      </template> 
                      <span  >
                        <span v-if="dataContact.length > 0" >
                              {{ dataContact[0]?.main ? dataContact[0].date_of_birth:'Seleccione un contacto principal'  }} 
                        </span>
                        <span v-else> No se han encontrado contactos</span>
                      </span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon @click="showPie()" v-on="on">
                          <v-icon v-bind="attrs" size="20" color="#C82333"
                            >mdi-chart-pie</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Mostrar participacion / Division</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <a  href="https://www.youtube.com/watch?v=LHrrudI5zdc" class="mt-1" target="_blank" >
                          <v-icon v-bind="attrs" v-on="on" size="24" color="#28A745" >mdi-cash-100</v-icon>
                        </a>
                      </template>
                      <span>Pagos en línea</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-if="pictures.length > 0" icon @click="imageIndex = 0" v-on="on" >
                          <v-icon v-bind="attrs" size="20" color="black"
                            >mdi-camera</v-icon
                          >
                        </v-btn>
                        <v-btn v-else icon v-on="on">
                          <v-icon v-bind="attrs" size="20" color="black"
                            >mdi-camera</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>{{
                        pictures.length ? "Galería" : "Sin fotografías"
                      }}</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-img max-width="22" class="mt-2" max-height="25" src="/img/cliente-con-iusa-en-linea.svg" v-bind="attrs" v-on="on" ></v-img>
                      </template>
                      <span>Usuarios de IUSA en linea</span>
                    </v-tooltip>
                  </div>
                </v-col>
                <v-col md="5" sm="5" cols="12" class="pa-0">
                  <div
                    class="text-left font-size-x pl-1 pl-md-8 pa-0 pr-0 pb-0 pt-sm-5"
                  >
                    <span class="font-weight-black ml-1 ml-sm-10 ml-md-2">
                      Antigüedad:&nbsp;{{ customerInformation.ERDAT }} <br />
                      <label class="pl-2">{{
                        customerInformation.SMTP_ADDR
                      }}</label>
                    </span>
                  </div>
                </v-col>
              </v-row>
              <v-row style="box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2)">
                <v-col
                  cols="12"
                  md="6"
                  class="mt-2 text-left font-size-x pr-0 pl-1 pt-0"
                >
                  <span class="text-center font-weight-black">
                    Domicilio fiscal: <br
                  /></span>
                  <span class="">
                    {{ customerInformation.ORT01 }}
                    {{ customerInformation.STREET }}
                    {{ customerInformation.HOUSE_NUM1 }}
                    {{ customerInformation.CITY2 }} CP:
                    {{ customerInformation.POST_CODE1 }}
                  </span>
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                  class="text-left font-size-x mt-0 pt-2 pr-0"
                >
                  <span class="font-weight-black">Domicilio de entrega:</span>
                  <br />
                  <span class=""
                    >{{ customerInformation.ORT01 }}
                    {{ customerInformation.POST_CODE1 }}
                    {{ customerInformation.STREET }}
                    {{ customerInformation.HOUSE_NUM1 }}
                    {{ customerInformation.CITY2 }}</span
                  >
                </v-col>
              </v-row>
              <v-row
                style=" border-style: solid; border-width: 3px 0px 0px; border-color: gray; box-shadow: rgba(0, 0, 0, 0.2) 2px -8px 9px -6px; " class="font-size-x pt-1">
                <v-col cols="12" class="text-left pl-1 ">
                  <span class="font-weight-black mr-2"
                    >Información de contactos
                  </span>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn @click="dialogContact = true" icon v-on="on">
                        <v-icon class="ml-1 mr-1 mt-n1" v-bind="attrs" size="24" color="#AE388B" >mdi-account-supervisor</v-icon >
                      </v-btn>
                    </template>
                    <span>Datos del contacto</span>
                  </v-tooltip>
                  <!--
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <a  v-on="on" v-bind="attrs">
                        <v-icon  size="28" color="black">mdi-youtube</v-icon>
                      </a>
                    </template>
                    <span>Ver video administrador de contacto</span>
                  </v-tooltip>  
                --> 
                </v-col>
                <!-- Mobile version -->
                <v-col class="pl-0 pr-0 pt-0 mt-n3 mb-2 d-block d-sm-none" cols="12">
                  <div
                    style="
                      background: transparent
                        linear-gradient(180deg, #b00020 0%, #580010 100%) 0% 0%
                        no-repeat padding-box;
                      border: 1px solid #dde1e6;
                      opacity: 1;
                      color: #fff;
                    "
                    class="font-weight-bold text-left pa-1"
                  >
                    <span class="ml-10">Contacto</span>
                  </div>
                  <table style="width: 100%">
                    <tbody>
                      <tr v-if="dataContact.length>0">
                        <td class="text-left pl-2">
                          {{
                            dataContact[0].name + " " + dataContact[0].last_name
                          }}
                        </td>
                      </tr>
                      <tr v-else-if="loadingContacts">
                        <td class="text-left pl-2">Cargando contactos... </td>
                      </tr>
                      <tr v-else>
                        <td class="text-left pl-2">
                          No se encontraron contactos
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <v-row class="pl-4 pr-4 pb-1">
                    <v-col cols="5" class="pb-0 pl-0 pr-0 text-left">
                      <div class="ml-4">
                        <span class="font-weight-black">Estado: </span
                        >{{customerInformation?.user.customer_addresses?.state  ?? 'Ciudad de México'}}
                      </div>
                    </v-col>
                    <v-col cols="6" class="pb-0">
                      <span class="font-weight-black ml-10">Municipio: </span
                      >{{customerInformation?.user.customer_addresses?.municipality ?? 'Sin especificar'}}
                    </v-col>
                    <v-col cols="12" class="mt-0 pa-0">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-on="on"
                            v-bind="attrs"
                            @click="changeTown"
                          >
                            <v-icon color="#E97B86">
                              mdi-pencil-circle-outline
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Editar</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="showDialogReception()"
                          >
                            <v-icon color="#AE388B">
                              mdi-home-clock-outline
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Horarios de recepción</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="getDestinyCustomer()"
                          >
                            <v-icon color="#30009C">
                              mdi-compass-outline
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Ubicación</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                  <div
                    style="
                      background: transparent
                        linear-gradient(180deg, #b00020 0%, #580010 100%) 0% 0%
                        no-repeat padding-box;
                      border: 1px solid #dde1e6;
                      opacity: 1;
                      color: #fff;
                    "
                    class="font-weight-bold d-flex pa-1 mt-2"
                  >
                    <div class="text-left" style="width: 60%">
                      <span class="ml-10">Correo</span>
                    </div>
                    <div style="width: 40%">
                      <span class="text-right">Teléfono</span>
                    </div>
                  </div>
                  <div class="d-flex pl-2" v-if="dataContact.length>0">
                    <div class="text-left" style="width: 60%">
                      <v-icon size="20" color="#FF0000">mdi-email</v-icon>
                      <span> {{ dataContact[0].email }} </span>
                    </div>
                    <div class="text-right">
                      <v-icon size="20" color="#3F3FFF">mdi-cellphone</v-icon
                      >{{ dataContact[0].phone }}
                    </div>
                  </div>
                  <div v-else-if="loadingContacts" class="d-flex pl-2">
                    <div class="text-left" style="width: 100%">
                      Cargando contactos...
                    </div>
                  </div>
                  <div v-else class="d-flex pl-2">
                    <div class="text-left" style="width: 100%">
                      No se encontraron contactos
                    </div>
                  </div>
                </v-col>
                <!-- End Mobile version -->
              </v-row>
            </v-col>
            <v-col
              md="5"
              style="
                border-style: solid;
                border-color: #f5f5f5;
                border-width: 0px 0px 0px 2px;
                box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
                opacity: 1;
              "
            >
              <v-row class="font-size-x">
                <v-col cols="7" class="pt-0 pb-0">
                  <div class="d-flex justify-space-between mt-0 mt-md-6">
                    <div>
                      <span class="font-weight-black ml-4 mt-3"
                        >N° de cliente:
                        {{
                          transformCustomerId(customerInformation.KUNNR)
                        }}</span
                      >
                    </div>
                    <div>
                      <div class="d-sm-flex justify-end ">
                        <div v-if="customerInformation.PPPAP >= 0"> 
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }"> 
                                <a  v-bind="attrs" v-on="on">
                                  <img style="width:50px" src="/img/warning.svg" alt="" srcset="">
                                </a> 
                            </template>
                            <span>
                              <v-card class=" subtitle-2 pl-4 pr-4" outlined>
                                {{ customerInformation.PPPAP == 0 ? 'No tienes' : 'Tienes ' + customerInformation.PPPAP }} saldo pendiente <br> por aplicar
                              </v-card>
                            </span>
                          </v-tooltip> 
                        </div> 
                      </div> 
                      </div>
                  </div>
                </v-col>
                <v-col cols="4" class="mt-0 pa-1 mt-md-5 mb-0 text-right">
                  <v-btn
                    class="text-capitalize custom-red-button font-size-x"
                    @click="showGroup()"
                    width="80%"
                    color="error"
                    dark
                    small
                    rounded
                    v-if="customerInformation.CTEGPO>0"
                  >
                    Ver grupo </v-btn
                  >
                </v-col>
              </v-row>
              <v-row class="font-size-x">
                <v-col cols="12" class="pa-0 pt-0 pl-sm-0 pr-sm-0">
                  <div
                    style="
                      background: transparent linear-gradient(180deg, #b00020 0%, #580010 100%) 0% 0% no-repeat padding-box;
                      border: 1px solid #dde1e6;
                      opacity: 1;
                      color: #fff;
                      height: 25px;
                    "
                  ></div>
                </v-col>
                <v-col cols="12" sm="7" lg="7" md="12" class="pt-1 pb-0">
                  <div class="d-flex justify-space-between">
                    <div class="text-left">
                      <span 
                        >Total de beneficios financieros trim. anterior:</span
                      >
                    </div>
                    <div v-if="!loadingBenefits">
                      <v-tooltip bottom top>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on" style="cursor: pointer" :class=" 'font-weight-black text-decoration-underline' + (benefits.total < 1 ? 'text-danger' : '')"
                            >${{ numberformat(benefits.total) }}</span>
                        </template>
                        <span>Trimestre: {{ benefits.period }}</span> <br />
                        <table>
                          <template  v-if="customerInformation?.VTWEG!='PR'">
                            <td v-if="loadingDiscounts" class="pa-1">  <v-progress-circular indeterminate color="primary" ></v-progress-circular> </td>
                            <template  v-else >
                            <td class="pa-1">  <span class="d-flex"> Comerciales ({{numberformat(discounts.CONDZKVL,false)}}% ,
                              {{numberformat(discounts.CONDZKRF,false)}}% ,
                              {{numberformat(discounts.CONDZKRG,false)}}% ,
                              {{numberformat(discounts.CONDZKCM,false)}}%) </span> </td>
                            <td class="pa-1">  <span class="d-flex"> $&nbsp;{{numberformat(discounts.CONDTOTAL) }}</span> </td>
                            </template>
                          </template>
                          <tr v-for="(item, index) in benefits.details" :key="index">
                            <td class="pa-1"> <span class="d-flex"> {{ item.title }}</span> </td>
                            <td class="pa-1"> <span class="d-flex">$ {{ numberformat(item.amount) }}</span> </td>
                          </tr>
                        </table>
                      </v-tooltip>
                    </div>
                    <div v-else class="text-center">
                      <v-progress-circular
                      size="16"
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                    </div>
                     
                  </div>
                </v-col>
                <v-col cols="12" class="pt-0 mt-0 pb-0 mb-0">
                  <div class="pt-0 mt-sm-0 mt-md-0 mt-lg-n1 flex-lg-row flex-md-column d-lg-flex justify-lg-space-between">
                  <label class="d-flex  text-left">Descuentos trim. actual calculados con los resultados del trim. anterior: </label>
                  <v-spacer></v-spacer> 
                    <div class=" d-flex justify-sm-space-between "> 
                      <template v-if="!loadingDiscounts">
                      <div class="ma-1 pa-1"> <span class="font-weight-bold"> Vol. </span> <br>&nbsp;{{numberformat(discounts.CONDZKVL??0,false)}}%</div>
                      <div class="ma-1 pa-1"> <span class="font-weight-bold"> Fam. </span> <br> &nbsp;{{numberformat(discounts.CONDZKRF??0,false)}}%</div>
                      <div class="ma-1 pa-1"> <span class="font-weight-bold"> Reng. </span> <br>&nbsp;{{numberformat(discounts.CONDZKRG??0,false)}}%</div>
                      <div class="ma-1 pa-1"> <span class="font-weight-bold"> Comp. </span> <br>&nbsp;{{numberformat(discounts.CONDZKCM??0,false)}}%</div>
                      </template>
                      <template v-else>
                      <v-progress-circular indeterminate color="primary" ></v-progress-circular>
                      </template>
                    </div>
                  </div>  
                  <div class="text-left mt-sm-n1 mt-lg-n3">
                            <span class="">Suministro:  
                              <b>  {{ customerInformation.VKBUR }} | {{ customerInformation.VKBUR_TXT }}</b>
                            </span> 
                          </div> 
                </v-col>
                <v-col cols="12" class="pl-md-3 pa-0 pl-sm-0 pt-3">
                  <div
                    style="
                      background: 0% 0% no-repeat padding-box;
                      background: #ffffff 0% 0% no-repeat padding-box;
                      box-shadow: inset 0px 3px 6px #00000029,
                        0px 3px 6px #00000029;
                      opacity: 1;
                    "
                  >
                    <v-row class="pr-0 pr-sm-2">
                      <v-col cols="12" sm="7" class="pl-sm-3 pa-0 pl-sm-0 pt-3">
                        <div  class="d-flex d-inline-block justify-sm-space-between">
                          <div class="mt-0 mt-sm-2 ml-4">
                              <span class="font-weight-black">Pagaré</span> ${{formatPrice( docCustomerdata? docCustomerdata?.promissory_note_amount: 0)}}
                            </div>
                          <div>
                            <span class="font-weight-black">
                              {{ customerInformation.WERKS }}</span>
                            <div class="mt-1"> 
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn icon>
                                    <a
                                      :href="
                                        urlServer +
                                        docCustomerdata?.promissory_note
                                      "
                                      target="_blank"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <v-icon color="black">
                                        mdi-printer-outline
                                      </v-icon>
                                    </a>
                                  </v-btn>
                                </template>
                                <span>Descargar pagaré </span>
                              </v-tooltip>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn icon v-bind="attrs" v-on="on">
                                    <v-icon
                                      :color="
                                        docCustomerdata
                                          ? promissoryStatusColor
                                          : 'gray'
                                      "
                                    >
                                      mdi-circle
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span v-if="docCustomerdata"
                                  >Pagaré monto: ${{
                                    formatPrice(
                                      docCustomerdata?.promissory_note_amount
                                    )
                                  }}<br />
                                  Fecha de vencimiento:{{
                                    getLimitPromissoryDate(
                                      docCustomerdata?.promissory_note_date
                                    )
                                  }}</span
                                >
                                <span v-else>Sin documentación</span>
                              </v-tooltip>
                            </div>
                          </div>
                        </div>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                        md="5"
                        lg="5"
                        class="text-right pt-0 mb-4"
                      >
                        <div class="d-flex justify-end pr-1">
                          <change-password-control 
                            v-if="$hasAnyRole(['Customer', 'CustomerPR'])"
                            class="mt-0 mt-sm-4"
                          />
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" class="pl-0 pr-0 pt-0 mt- d-none d-sm-block">
              <table style="width: 100%">
                <thead
                  style="
                    background: linear-gradient(#b00020, #580010);
                    color: #fff;
                  "
                  class="font-weight-bold"
                >
                  <tr>
                    <td class="pt-1 pb-1 pl-16 text-left">Contacto</td>
                    <td class="pt-1 pb-1 pl-16 text-left">Correo</td>
                    <td class="pt-1 pb-1 pl-16 text-left">Teléfono</td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="dataContact.length>0">
                    <td class="text-left pl-10">
                      {{ dataContact[0].name + " " + dataContact[0].last_name }}
                    </td>
                    <td class="text-left pl-10">
                      <v-icon size="20" color="#FF0000">mdi-email</v-icon>
                      {{ dataContact[0].email }}
                    </td>
                    <td class="text-left pl-10">
                      <v-icon size="20" color="#3F3FFF">mdi-cellphone</v-icon
                      >{{ dataContact[0].phone }}
                    </td>
                  </tr>
                  <tr v-else-if="loadingContacts">
                    <td></td>
                    <td class="text-left pl-10 pb-1 pt-1" colspan="2">
                      <v-progress-circular
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                      Cargando contactos... 
                    </td>
                  </tr>
                  <tr v-else>
                    <td></td>
                    <td class="text-left pl-10" colspan="2">
                      No se encontraron contactos  
                    </td>
                  </tr>
                </tbody>
              </table>
              <v-row>
                <v-col cols="4" class="mt-2 pb-sm-0 text-left">
                  <span class="font-weight-black ml-4 ml-md-16">Estado: </span 
                  >{{customerInformation?.user.customer_addresses?.state  ?? 'Ciudad de México'}}
                </v-col>
                <v-col cols="6" class="mt-2 pb-sm-0 pb-md-2">
                  <span class="font-weight-black ml-10">Municipio: </span
                  >{{customerInformation?.user.customer_addresses?.municipality  ?? 'Sin especificar'}}
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-on="on" v-bind="attrs" @click="changeTown">
                        <v-icon color="#E97B86">
                          mdi-pencil-circle-outline
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click="showDialogReception()"
                      >
                        <v-icon color="#AE388B">
                          mdi-home-clock-outline
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Horarios de recepción</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click="getDestinyCustomer()"
                      >
                        <v-icon color="#30009C"> mdi-compass-outline </v-icon>
                      </v-btn>
                    </template>
                    <span>Ubicación</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </template>
      </CardTitle>
 
      <v-dialog
        v-model="showMap"
        persistent
        transition="dialog-bottom-transition"
        :max-width="isMobile ? '100%' : '45%'"
      >
        <location-maps 
          :locationData="locationData"
          :customerInformation="customerInformation"
          @acceptLocation="acceptLocation"
          @cancelLocation="cancelLocation"
          @setMapLocation="setMapLocation"
          @close="showMap = false" >
        </location-maps>
      </v-dialog>

      <v-dialog
        v-model="showFormChangeTown"
        transition="dialog-bottom-transition"
        :max-width="isMobile ? '100%' : '45%'"
      >
        <ChangeTownForm @addressUpdated="updateAddress" :customer=customerInformation @closeTownForm="showFormChangeTown = false">
        </ChangeTownForm>
      </v-dialog>

      <v-dialog
        v-model="birthdayForm"
        transition="dialog-bottom-transition"
        min-width="800" 
        width="auto"
       
      >
        <ContactForm
          :modal="birthdayForm"
          :dataContact="dataContact"
          :customer="customer_code"
          :user_id="customerInformation.user.id"
          @updateContactList="updateContacts"
          @closeBF="closeModalBirthdayForm"
        />
      </v-dialog>
      <v-dialog  v-model="dialogContact"  scrollable  transition="dialog-bottom-transition"  :max-width="isMobile ? '100%' : '60%'"> 
      <ContactList :dataContact="dataContact" :customerId="customer_code" @editContact="editContactBtn()" @closeModal="dialogContact=false" />
      </v-dialog>
      <v-dialog v-model="dialogReception" persistent transition="dialog-bottom-transition" :max-width="isMobile ? '100%' : '40%'">
        <v-card>
          <v-toolbar :dark="true">
            <v-toolbar-title>HORARIOS DE RECEPCIÓN</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn class="right" @click="dialogReception = false" icon>
              <v-icon>mdi-close-circle</v-icon>
            </v-btn>
          </v-toolbar>
          <div class="mb-3 mt-3 text-left pl-4">
            <span> Nombre del establecimiento:</span>
            <v-progress-linear
              v-if="loaderReception"
              indeterminate
              color="cyan"
            ></v-progress-linear>
          </div>
          <div class="d-flex justify-space-around">
            <div style="width: 80%">
              <v-select :disabled="loaderReception" class="pl-4" @change="changeReception" :items="dataReception" label="Seleccione un establecimiento" 
                persistent-hint :item-text="displayText" item-value="reception.KUNNR">
              </v-select>
            </div>
            <div class="">
              <template v-if="!loaderReception && !editReception && dataReceptionCurrent?.days">
              <v-btn @click="editReception=true"  >Editar </v-btn>
              </template>
            </div>
          </div>
          <br />
          <v-card-text>
            <template v-if="dataReceptionCurrent">
              <v-row style="text-align: left" v-for="(c, index) in dataReceptionCurrent.days" :key="index" >
                <v-col cols="12" sm="12" md="12" lg="12" class="text-dark mb-n10" >{{ daytext(c.day) }}</v-col><br />
                <template v-if="!editReception">
                  <template v-if="c.not_reception">
                    <v-col cols="2" sm="2" md="2" lg="2"><v-icon style="color: red">mdi-clock</v-icon></v-col>
                    <v-col style="text-align: left" cols="10" sm="10" md="10" lg="10" >Sin recepción</v-col>
                  </template>
                  <template v-else>
                    <v-col md="1"
                      ><v-icon color="black">mdi-clock</v-icon></v-col>
                    <v-col style="text-align: left" cols="10" sm="10" md="10" lg="10" >
                      Recepción De: {{ c.start }} A {{ c.end }} <br />
                    </v-col>
                  </template>
                </template>
                <template v-else>
                  <div class="d-flex flex-nowrap">
                    <v-checkbox class="ml-10 mr-10" v-model="dataReceptionCurrent.days[index].not_reception" label="Sin Recepción" ></v-checkbox>
                    <v-text-field class="ml-10 mr-10" v-model="dataReceptionCurrent.days[index].start" type="time" solo ></v-text-field>
                    <v-text-field class="ml-10 mr-10" v-model="dataReceptionCurrent.days[index].end" type="time" solo ></v-text-field>
                  </div>
                </template>
              </v-row>
            </template>
            <v-row style="text-align: left" v-if="receptionLast">
              <v-col ><b>Fecha del último registro:</b> {{ receptionLast }}</v-col>
            </v-row>

            <v-row style="text-align: left" v-if="required_documents && !editReception">
              <v-col><b>Comentarios:</b> {{ required_documents }}</v-col>
            </v-row>

            <v-progress-circular v-if="loadingReception" indeterminate color="primary"  ></v-progress-circular>


            <template v-if="editReception">
              <label for="" class="text-table-profile ml-10 mr-10"
                >Comentarios</label>
              <v-text-field class="ml-10 mr-10"  v-model="required_documents" counter label="Comentarios" solo ></v-text-field> 
              <div class="d-flex flex-nowrap">
                <v-btn :disabled="!dataReceptionCurrent.sendFormReception" depressed color="success" class="justify-text ma-2" large @click="saveReception()" >
                  Actualizar
                  <v-icon style="margin-left: 0.5em">mdi-check-bold</v-icon>
                </v-btn>
                <v-btn depressed color="error" class="justify-text ma-2" large v-on:click="editReception = false" >
                  Cancelar
                  <v-icon style="margin-left: 0.5em"> mdi-window-close</v-icon>
                </v-btn>
              </div>
            </template>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <v-dialog
      v-model="showModalPie"
      :max-width="900"
      :persistent="loadingBilling"
    >
      <!-- 500 para table 1 |  350 a table 2 | 800 table 3 -->
      <v-card>
        <ChartBillingByDiv
          :charTitle="'Detalle de cliente ' + customer_code"
          :charData="billingByArea"
          :load="loadingBilling"
          @closeModal="showModalPie = false"
          @periodChanged="loadSalesPerArea"
        >
        </ChartBillingByDiv>
        <div class="text-right pr-2 pb-2">
          <v-btn small outlined color="success" @click="showModalPie = false">
            Cerrar
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <CoolLightBox
      :items="pictures"
      :index="imageIndex"
      @close="imageIndex = null"
    >
    </CoolLightBox>
  </div>
</template>

<script>
import axios from "@/plugins/axios";
import { mapGetters } from "vuex";
import CoolLightBox from "vue-cool-lightbox";
import CardTitle from "@/components/CardTitle.vue";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import ChartBillingByDiv from "@/components/ChartBillingByDiv.vue";
import moment from "moment";
import ContactForm from "./modals/ContactForm.vue";
import ContactList from "./modals/ContactList.vue";
import ChangeTownForm from "./modals/ChangeTownForm.vue";
import LocationMaps from './modals/LocationMaps';
import ChangePasswordControl from '@/components/ChangePasswordControl.vue';

export default {
  data() {
    return {
      benefits: {},
      loadingBenefits: true,
      lastUpdate: moment(),
      showModalPie: false,
      loadingBilling: false,
      billingByArea: [],
      showFormChangeTown: false,
      birthdayForm: false,
      showMap: false,
      required_documents: "",
      customerInformation: null,
      imageIndex: null,
      fiscalCustomerdata: null,
      docCustomerdata: null,
      switch1: true,
      toggle: false,
      countdata: null,
      customerDestinyList: [],
      dialogContact: false,
      dialogFacade: false,
      dialogReception: false,
      loaderReception: false,
      dialogGallery: false,
      dataContact: {},
      dataReception: [],
      galleryPrincipal: {
        id: 0,
        caption: null,
        src: null,
        thumb: null,
      },
      fachada: {
        id: 0,
        caption: null,
        src: null,
        thumb: null,
      },
      interior: {
        id: 0,
        caption: null,
        src: null,
        thumb: null,
      },
      mostrador: {
        id: 0,
        caption: null,
        src: null,
        thumb: null,
      },
      attrs: {
        class: "mb-6",
        boilerplate: true,
        elevation: 2,
      },
      urlServer: process.env.VUE_APP_FILES_URL + "/",
      dataDocument: {},
      media: [],
      pictures: [],
      receptionLast: "",
      isMobile: false,
      emailCurrent: "",
      phoneCurrent: "",
      customer_code: null,
      loadingContacts: false,
      promissoryStatusColor: "",
      showChanguePassword: false,
      dataReceptionCurrent: [],
      editReception: false,
      loadingReception:false,
      loadingDiscounts:false,
      discounts:[],
      locationData: {
        geolocation: {
          lat: '',
          lng: '',
          updatedAt: ''
        },
        updateLocation: false,
        showBtnCoordinates: false,
        showBtnOptions: false,
      },
      lastGeolocation: null,    
    };
  },
  components: {
    CoolLightBox,
    CardTitle,
    ContactForm,
    ChartBillingByDiv,
    ChangeTownForm,
    ContactList,
    ChangePasswordControl,
    LocationMaps
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
      customerLoaded: "auth/customerData",
      mustchangepassword: "auth/mustchangepassword",
    }),
  },
  watch:{ 
    editReception(value){
      if (value) {
        this.dataReceptionCurrent.sendFormReception=true;
      }else{
        this.dataReceptionCurrent.sendFormReception=false;
      }
    }
  },
  mounted() {
    if (this.$getLocalStorageCustomer() == null) {
      this.customer_code = this.user.custumer_code;
    } else {
      const currentCustomerCode =
        JSON.parse(localStorage.getItem("currentCustomerCode")) || [];
      if (currentCustomerCode.length < 1) {
        this.$router.push({ name: "Home" });
      }
      this.customer_code = currentCustomerCode;
    }
    this.getCustomerInformation();
    this.checkMobile();
    window.addEventListener("resize", this.checkMobile);

    if(this.mustchangepassword == 1) {
      console.log('debe cambiar ps');
      this.showChanguePassword = true;
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkMobile);
  },

  methods: {
    updateAddress(address){
      this.customerInformation.user.customer_addresses = address; 
    },
    editContactBtn(){
      this.dialogContact=false;
      this.birthdayForm=true;
    },
    saveReception() {
    
      this.dataReceptionCurrent.sendFormReception = false;
      let form = new FormData();
      form.append("lzone", this.dataReceptionCurrent.reception.LZONE);
      form.append("client", this.customerInformation.user.customer_user.id);
      form.append("documentos_requeridos", this.required_documents);
      form.append("zone_id", this.dataReceptionCurrent.reception.KUNNR);
      for (let d = 0; d < this.dataReceptionCurrent.days.length; d++) {
        if( !this.dataReceptionCurrent.days[d].not_reception ){

if(
    ( this.dataReceptionCurrent.days[d].start && !this.dataReceptionCurrent.days[d].end ) ||
    ( !this.dataReceptionCurrent.days[d].start && this.dataReceptionCurrent.days[d].end )
){

    this.$swal({
        title: "El dia debe tener hora inicio y fin",
        icon: "error",
    }); 
    return false;
} 

if(
    this.dataReceptionCurrent.days[d].start > this.dataReceptionCurrent.days[d].end
){
    
    this.$swal({
        title: "El horario fin, no puede ser mayor al horario de inicio",
        icon: "error",
    }); 
    return false;
}
}
        form.append("day[]", this.dataReceptionCurrent.days[d].id);
        form.append("not_reception[]",this.dataReceptionCurrent.days[d].not_reception?1:0);
        form.append("start[]", this.dataReceptionCurrent.days[d].start);
        form.append("end[]", this.dataReceptionCurrent.days[d].end);
      } 
      this.editReception = false;
      this.loadingReception = true;
      axios
        .post("profile/reception-save", form)
        .then((response) => {
          if (response.data.result == 1) {
            this.dataReceptionCurrent.sendFormReception = true;
            this.$swal({
              title: response.data.message,
              icon: "success",
              type: "succes",
            })  
          } else {
            this.$swal({ title: response.data.message, icon: "error" });
            this.dataReceptionCurrent.sendFormReception = true;
            
          }
          this.loadingReception = false;
        })
        .catch((error) => {
          console.error(error);
          this.$swal({ title: error, icon: "error" });
          this.loadingReception = false;
          this.dataReceptionCurrent.sendFormReception = true;
        });
    },
    getLimitPromissoryDate(val) { 
      var today = moment().format("YYYY-MM-DD");
      const objDate = moment(val, "YYYY-MM-DD");
      var finishPromissory = objDate.clone().add(3, "y").format("YYYY-MM-DD");

      var greenflag = objDate.clone().add(2.5, "y").format("YYYY-MM-DD");
      if (greenflag > today) {
        this.promissoryStatusColor = "green";
      } else if ( finishPromissory < today) { 
        this.promissoryStatusColor = "red";
      } else {
        this.promissoryStatusColor = "warning";
      }
      return finishPromissory;
    },
    showGroup() {
      this.$router.push({
        name: "ClientGroup",
        params: {
          opt: 'byGroup'
        }
      });
    },
    numberformat(number = 0, round = true) {
      let result = round ? Math.trunc(number) : Number(number).toFixed(2);
      return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    closeModalBirthdayForm() {
      this.birthdayForm = false;
    },
    updateContacts(newContacts) {
      this.dataContact = newContacts;
    },
    _getBenefits() {
      this.loadingBenefits = true;
      axios
        .post("/cover/benefitDetails", { 
          customer_id: this.customer_code,
          channel:this.customerInformation.VTWEG,
        
        },{
          noCancelRequest:true,
          cachable:true,
        })
        .then((response) => { 
          if (response.data?.success == false) {
            console.log(response.data);
          } else {
            this.benefits = response.data;
          }
          this.loadingBenefits = false;
        })
        .catch((e) => {
          console.log(e);
          this.loadingBilling = false;
        });
    },
    showPie() {
      this.showModalPie = true;
      let currentTrim = this.getCurrentTrim();
      let currentYear = new Date().getFullYear();
      this.loadSalesPerArea(currentYear + "-" + currentTrim);
    },
    loadSalesPerArea(per) {
      this.loadingBilling = true;
      let year = per.slice(0, 4);
      let trim = per.slice(5, 6);
      axios
        .post("seller/home/sales-by-div", {
          customer_id: this.customer_code,
          channel: this.customerInformation.VTWEG,
          type: 2,
          trim: trim,
          year: year,
        })
        .then((response) => {
          if (response.data?.success == false) {
            console.log(response.data);
          } else {
            if (!response.data.DIVISION) {
              this.billingByArea = [];
            } else {
              // this.billingByArea = response.data.DIVISION;

              this.billingByArea =
                "NETWR1" in response.data.DIVISION //Cuando viene un item. Convertirlo en array con index 0
                  ? [response.data.DIVISION]
                  : response.data.DIVISION;
            }
          }
          this.loadingBilling = false;
        })
        .catch((e) => {
          console.log(e);
          this.loadingBilling = false;
        });
    },
    getCurrentTrim() {
      let currentMonth = new Date().getMonth() + 1;
      return Math.ceil(currentMonth / 3);
    },
    changeTown() {
      this.showFormChangeTown = true;
    },
    transformCustomerId(number) {
      // Convertir el número a una cadena de texto
      let newNumber = number.toString();
      // Verificar si el número tiene menos de 6 dígitos
      if (newNumber.length < 6) {
        // Calcular cuántos ceros agregar a la izquierda
        const cerosFaltantes = 6 - newNumber.length;
        // Agregar ceros a la izquierda
        newNumber = "0".repeat(cerosFaltantes) + newNumber;
      } else if (newNumber.length > 6) {
        // Si el número tiene más de 6 dígitos, eliminar ceros a la izquierda
        newNumber = newNumber.slice(newNumber.length - 6);
      }
      // Convertir la cadena resultante de nuevo a un número
      const res = parseInt(newNumber, 10);
      return res;
    },
    showBirthdayForm() {
      this.birthdayForm = true;
    },
    displayText(item) {
      // console.info('disply => ', item);
      return `${item.reception.ORT01} ${item.reception.PSTLZ} ${item.reception.STRAS}`;
    },
    checkMobile() {
      this.isMobile = window.innerWidth <= 600;
      console.info("checkMobile => ", this.isMobile);
    },
    changeReception(reception_id) {
      this.dataReceptionCurrent =[];
      this.required_documents =""
      // this.dataReceptionCurrent = rc;
      ///this.receptionLast = ""; 

      for (let index = 0; index < this.dataReception.length; index++) {
        if (this.dataReception[index].reception.KUNNR === reception_id){
          this.dataReceptionCurrent = this.dataReception[index];
          this.receptionLast = this.dataReception[index].reception.last;
          this.required_documents = this.dataReception[index].days[0].documentos_requeridos;   
          break
        }
        
      }
      /*
      this.dataReception.map((r) => {
        if (r.reception.KUNNR === reception_id) {
          //Reception id se convierte en id de cliente cuando solo es 1 dirección
         
          this.dataReceptionCurrent = r;
          console.log(r)
          
          console.log('Docuemtos requeridos: ')
          console.log(r.days[0].documentos_requeridos)
           
        }
      }); */ 
    
    },
    daytext(day) {
      let dayText = "";
      switch (day) {
        case 0:
          dayText = "Lunes";
          break;
        case 1:
          dayText = "Martes";
          break;
        case 2:
          dayText = "Miercoles";
          break;
        case 3:
          dayText = "Jueves";
          break;
        case 4:
          dayText = "Viernes";
          break;
        case 5:
          dayText = "Sabado";
          break;
        case 6:
          dayText = "Domingo";
          break;
      }
      return dayText;
    },
    _getContact() {
      this.loadingContacts=true;

      axios
        .post("contact-client/list", {
          client: this.customerInformation.user.id,
        })
        .then((response) => {  
          if (response.data.result == 1) { 
            this.dataContact = response.data.data;
          }
         
        })
        .catch((error) => {
          console.error(error); 
        })
        .finally(() => {
          this.dialogLoading = false;
          this.loadingContacts = false;
        });
    },

    _getDocumentData() {
      if (this.docCustomerdata?.facade_photo) {
        this.fachada.id = 1;
        this.fachada.caption = "Fachada";
        this.fachada.src = this.urlServer + this.docCustomerdata.facade_photo;
        this.fachada.thumb = this.urlServer + this.docCustomerdata.facade_photo;
        this.galleryPrincipal = this.fachada;
        this.media.push(this.fachada);

        this.pictures.push({
          description: "Fachada",
          title: "Fachada",
          src: this.urlServer + this.docCustomerdata.facade_photo,
        });
      }
      if (this.docCustomerdata?.indoor_photo) {
        this.interior.id = 2;
        this.interior.caption = "Interior";
        this.interior.src = this.urlServer + this.docCustomerdata.indoor_photo;
        this.interior.thumb =
          this.urlServer + this.docCustomerdata.indoor_photo;
        this.media.push(this.interior);

        this.pictures.push({
          description: "Interior",
          title: "Interior",
          src: this.urlServer + this.docCustomerdata.indoor_photo,
        });
      }
      if (this.docCustomerdata?.bar_photo) {
        this.mostrador.id = 3;
        this.mostrador.caption = "Mostrador/Bodega";
        this.mostrador.src = this.urlServer + this.docCustomerdata.bar_photo;
        this.mostrador.thumb = this.urlServer + this.docCustomerdata.bar_photo;
        this.media.push(this.mostrador);
      }

      if (this.docCustomerdata?.bar_photo) {
        this.pictures.push({
          description: "Mostrador/Bodega:",
          title: "Mostrador/Bodega:",
          src: this.urlServer + this.docCustomerdata.bar_photo,
        });
      }
    },
    showDialogReception() {
      this.dialogReception = true;

      if (!this.dataReception.length) {
        this.loaderReception = true;
        axios
          .post("profile/reception-list", {
            customer: this.customer_code,
            vkorg: this.customerInformation.VKORG,
            vtweg: this.customerInformation.VTWEG,
            client: this.customerInformation.user.customer_user.id,
          })
          .then((response) => {
            if (response.data.result == 1) {  
              this.dataReception = response.data.data;
            }
            this.loaderReception = false;
          })
          .catch((error) => {
            this.loaderReception = false;
            console.error(error);
          });
      }
    },
    getCustomerInformation() { 
      axios
        .post("/cover/getCustomerInformation", {
          customer: this.customer_code,
          society: "217",
          VKORG: this.user.VKORG,
          VTWEG: this.user.VTWEG,
        },{
            noCancelRequest:true,
            cachable:true,
        })
        .then((response) => {
          this.customerInformation = response.data;
          this.emailCurrent = this.customerInformation.SMTP_ADDR;
          this.phoneCurrent = this.customerInformation.TELF1;
          this.docCustomerdata = response.data?.user?.customer_user?.document;
          this.fiscalCustomerdata = response.data?.user?.customer_user?.fiscal;
          localStorage.setItem(
            "currentCustomerInfo",
            JSON.stringify(this.customerInformation)
          );
          this.$emit("customerInfoloaded", this.customerInformation);
        })
        .finally(() => {
          this._getContact();
          this._getBenefits();
          this._getDocumentData();
          this.getDiscounts();
        });
    },
    getDiscounts() {
      this.loadingDiscounts = true;
      axios
        .post("/cover/discounts", {
          customer_id: this.customer_code, 
          channel: this.customerInformation.VTWEG, 
        },{
          noCancelRequest:true,
          cachable:true
        })
        .then((response) => {
          this.discounts = response.data; 
          this.loadingDiscounts = false;
        })
        .catch((error) => {
          console.error(error);
          this.loadingDiscounts = false;
        });
    },    
    formatPrice(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },    
    async getDestinyCustomer() {
      this.locationData.geolocation = {
        lat: '',
        lng: '',
        updatedAt: ''
      };
      this.locationData.updateLocation = false;
      this.locationData.showBtnCoordinates = false;
      this.showMap = true;
      
      try{
        let response = await axios.get(`cover/location-customer/${this.customer_code}`);
        
        let data = response.data.data;   
        if(data != null){
          this.locationData.geolocation = {
            lat: data.latitude,
            lng: data.longitude,
            updatedAt: data.updated_at
          };                
          this.locationData.updateLocation = true;
        }   

        this.locationData.showBtnCoordinates = true;
      }catch(error){
        console.log(error);
      } 
      
    },
    async updateLocationCustomer(){
      try{
        await axios.put('cover/location-customer', {
          customer: this.customer_code,
          latitude: this.locationData.geolocation.lat,
          longitude: this.locationData.geolocation.lng
        });

        this.locationData.geolocation.updatedAt = moment();
      }catch(error){    
        console.log(error);
      }   
        
      this.resetButtonState();   
    },
    async storeLocationCustomer(){
      try{
        await axios.post('cover/location-customer', {          
            customer: this.customer_code,
            latitude: this.locationData.geolocation.lat,
            longitude: this.locationData.geolocation.lng                  
        });        
        this.locationData.geolocation.updatedAt = moment(); 
        this.locationData.updateLocation = true; 
      }catch(error){         
        console.log(error);
      }
      
      this.resetButtonState();      
    },    
    cancelLocation(){
      this.locationData.geolocation = this.locationData.lastGeolocation;
      this.resetButtonState();
    },
    acceptLocation(){
      this.locationData.updateLocation ? this.updateLocationCustomer() : this.storeLocationCustomer()
    },
    async setMapLocation(){
      //se guardan los datos si el usuario quiere cancelar el cambio
      this.locationData.lastGeolocation = this.locationData.geolocation;
      this.locationData.geolocation = {
        lat: '',
        lng: '',
        updatedAt: ''
      };
      this.locationData.showBtnCoordinates = false;
      this.locationData.showBtnOptions = true;

      const permissionsGranted = await this.validateBrowserGeolocation();
      if (!permissionsGranted) {
        this.$swal({
          text: "Antes de registrar coordenadas tienes que activar tu geolocalización",
          icon: "warning",
          confirmButtonColor: "#ec0707",
        });
      }
      // Obtener la ubicación del usuario
      navigator.geolocation.getCurrentPosition((position) => {
        this.locationData.geolocation.lat = position.coords.latitude;
        this.locationData.geolocation.lng = position.coords.longitude;      
      });
    },
    async validateBrowserGeolocation() {
      if ("permissions" in navigator && "geolocation" in navigator) {
        const permissionStatus = await navigator.permissions.query({
          name: "geolocation",
        });        
        return permissionStatus.state === "granted";
      } 
      return false;      
    },
    resetButtonState() {
      this.locationData.showBtnCoordinates = true;
      this.locationData.showBtnOptions = false;
    } 
  },
};
</script>
<style scoped>
.text-color-gold {
  color: #e0a800;
}

.custm-table-bordered {
  border-width: 2px !important;
  border-color: #e0e0e0;
}

.custm-table-no-bordered {
  border-width: 0px !important;
  padding: 0px !important;
}

.custom-red-button {
  background: transparent linear-gradient(180deg, #a82222 0%, #ff0000 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
}

.font-size-x {
  font-size: 14px;
}

.fontAvenir {
  font-family: "Avenir";
  font-size: medium;
}

@font-face {
  font-family: "Avenir";
  font-style: "95 Black";
  font-weight: 400;
  font-display: swap;
  src: local("Avenir"), url(~@/assets/fonts/Avenir.otf) format("otf");
}

.header-dialog,
.header-dialog div {
  background: grey;
  color: white !important;
  height: auto !important;
}

.header-dialog {
  margin-bottom: 1em;
}

.gallery-max {
  height: 70%;
}

.gallery-min {
  cursor: pointer;
  height: 30%;
}

.card-without-scroll {
  overflow-x: hidden;
}

.list-sucu-mobile {
  display: none !important;
}

@media (max-width: 600px) {
  .list-sucu-mobile {
    display: block !important;
    width: 100%;
  }
  .font-size-x {
    font-size: 10px;
  }

  .list-sucu-desktop {
    display: none !important;
  }

  .list-sucu-mobile .col-4 {
    float: left !important;
  }
}

.custom-heading-contact {
  background: #f2f2f2 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
  border: 1px solid #dde1e6;
  height: 68px;
}

.custom-heading-contact > th {
  font-weight: bold !important;
  color: #000 !important;
}
</style>