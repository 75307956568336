<template>
	<div class="container-fluid mt-n3 ">
		<div class="alert alert-danger" role="alert" v-if="hasError" style="font-weight: 500">{{ msg }}</div>
		<cardTitle title="NIVEL DE SERVICIO Y TIEMPO DE ENTREGA" icon="calendar-check">
			
			<template v-slot:content>
				<template v-if="loadingFillRate">
							<v-progress-linear :active="loadingFillRate" :indeterminate="loadingFillRate" top
					color="deep-purple accent-4"></v-progress-linear>
			</template>
				<v-card  v-else class="ma-0">
					<!-- <h2 class="h2">Fill Rate</h2> -->
					<div class="card-body ">
						<v-simple-table dense class="box-shadow ">
							<template v-slot:default>
								<thead>
									<tr class="font-weight-bold  text-black font-size-x">
										<td class="box-shadow sticky-column text-capitalize">Concepto</td>
										<td v-for="(item, index) in dataFillRate.headers.slice(1,7)" :key="index"
											class="box-shadow sticky-column text-capitalize"> 
												{{ dateFormat(item) }} 
										</td>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(item, index) in dataFillRate.body" :key="index" class="font-size-x no-border">
										<td class="text-left pa-2 pl-sm-10 no-border sticky-column  all-borders-bsadow"> 
											{{item[0] }} 
										</td>
										<td  style="  box-shadow: inset 8px 0 5px -5px #00000029; ">{{ numberFormat(item[6], index) }} </td>
										<td style="">{{ numberFormat(item[5], index) }} </td>
										<td style="">{{ numberFormat(item[4], index) }} </td>
										<td style="">{{ numberFormat(item[3], index) }}</td>
										<td style="">{{ numberFormat(item[2], index) }} </td>
										<td>{{ numberFormat(item[1], index) }} </td>										
									</tr>
								</tbody>
							</template>
						</v-simple-table>
					</div>
				</v-card>
			</template>

		</cardTitle>

		<cardTitle title="TIEMPO DE ENTREGA DE PEDIDOS EN LOS ÚLTIMOS 3 MESES" icon="calendar-clock-outline">
			<template v-slot:content>
				<v-progress-linear :active="loadingOrderDeliveryTime" :indeterminate="loadingOrderDeliveryTime" top
					color="deep-purple accent-4"></v-progress-linear>

				<v-card v-if="!loadingOrderDeliveryTime">
					<!-- <h2 class="h2">Tiempo de Entrega de Pedidos en los últimos 3 meses</h2> -->
					<v-simple-table dense class="box-shadow d-none d-sm-block">
						<template v-slot:default>
							<thead>
								<tr class="font-weight-bold  text-black">
									<td v-for="(item, index) in headers_order_delivery_time" :key="index"
										class="box-shadow">{{ item.text }}</td>
								</tr>
							</thead>
							<tbody>
								<tr class="font-size-x ">
									<td class=" pl-10 no-border" style=""> {{ dataODT?.PED_TOTALES ? dataODT?.PED_TOTALES :
										0
									}}</td>
									<td style="border-width: 0px;  box-shadow: inset 8px 0 5px -5px #00000029; ">
										{{ dataODT.PED_PRODENT ? dataODT.PED_PRODENT : 0 | formatCurrency }} </td>
									<td style="border-width: 0px;">{{ dataODT.PED_ENT1DIA }}
										({{ calculePercent(dataODT.PED_ENT1DIA) }}%)</td>
									<td style="border-width: 0px;">{{ dataODT.PED_ENT2DIA }}
										({{ calculePercent(dataODT.PED_ENT2DIA) }}%)</td>
									<td style="border-width: 0px;">{{ dataODT.PED_ENT3DIA }}
										({{ calculePercent(dataODT.PED_ENT3DIA) }}%)</td>
									<td style="border-width: 0px;">{{ dataODT?.PED_ENT4DIA }}
										({{ calculePercent(dataODT?.PED_ENT4DIA) }}%)</td>
									<td style="border-width: 0px;">{{ dataODT?.PED_ENT5DIA }}
										({{ calculePercent(dataODT?.PED_ENT5DIA) }}%)</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
					<!-- movile desng -->
					<v-simple-table v-if="!loadingOrderDeliveryTime" dense class="box-shadow d-sm-none d-block">
						<template v-slot:default>
							<thead>
								<tr class="text-black font-size-x " v-for="(item, index) in headers_order_delivery_time"
									:key="index">
									<td class="box-shadow font-weight-bold">{{ item.text }}</td>
									<td class="box-shadow" v-if="index == 0">{{ dataODT[item.value] }}</td>
									<td class="box-shadow" v-else-if="index == 1">{{ dataODT[item.value] | formatCurrency }}
									</td>
									<td v-else class="box-shadow"> {{ dataODT[item.value] }}&nbsp;({{
										calculePercent(dataODT[item.value]) }}%) </td>
								</tr>
							</thead>
						</template>
					</v-simple-table>
					<!-- end movile desng -->
				</v-card>
			</template>
		</cardTitle>

	</div>
</template>
  
<script>
import CardTitle from "@/components/CardTitle.vue";
import axios from "@/plugins/axios";
import { mapGetters } from "vuex";
import moment from "moment";
export default {
	name: "FillRate",
	components: { CardTitle },
	props: {
		customer: {},
	},
	watch: {
		customer() {
			this.submitOrderDeliveryTime()
			this.fillRate()
		}
	},
	data() {
		return {
			dataFillRate: [],
			dataODT: [],
			totals: [],
			loading: true,
			loadingFillRate: true,
			loadingOrderDeliveryTime: true,
			msg: null,
			hasError: false,
			timeout: 8000,
			headers_fill_rate: [
				{ text: "PERIODO", value: "SPMON" },
				{ text: "CANT ACUMULADA", value: "KWMENG" },
				{ text: "CANT FACTURADA", value: "FKIMG" },
				{ text: "% CANTIDAD", value: "PORC" },
				{ text: "IMP POS", value: "NETWR" },
				{ text: "% LINEAS", value: "PORC1" },
				{ text: "LINEAS PEDIDAS", value: "LINEASP" },
				{ text: "LINEAS FACTURADAS", value: "LINEASF" },
				{ text: "PROM DIAS", value: "PROM" },
			],
			headers_order_delivery_time: [
				{ text: "Pedidos Totales", align: 'center', value: "PED_TOTALES" },
				{ text: "promedio de días de entrega", align: 'center', value: "PED_PRODENT" },
				{ text: "Entregados en 1 día hábil", align: 'center', value: "PED_ENT1DIA" },
				{ text: "Entregados en 2 días hábiles", align: 'center', value: "PED_ENT2DIA", },
				{ text: "Entregados en 3 días hábiles", align: 'center', value: "PED_ENT3DIA" },
				{ text: "Entregados en 4 días hábiles", align: 'center', value: "PED_ENT4DIA" },
				{ text: "Entregados en 5 días hábiles o más", align: 'center', value: "PED_ENT5DIA" },
			],
			form: {
				client: "",
			},
		};
	},
	computed: {
		...mapGetters({
			user: "auth/user",
		}),
	},
	methods: {
		fillRate() {
			this.loadingFillRate = true;
			axios
				.post("/cover/fillrate",
					{
						customer: this.customer.KUNNR,
						channel: this.customer.VTWEG,
					},
					{
						noCancelRequest:true,
						cachable:true
					})
				.then((response) => { 
					this.dataFillRate = response.data;
					//this.dataFillRate.headers = this.dataFillRate.headers 
				})
				.catch((error) => {
					if (error.response.data.errors) {
						this.msg = error.response.data.errors;
						this.hasError = true;
						setTimeout(() => {
							this.hasError = false;
						}, 8000);
					} else {
						//alert(error);
						console.log(error);
					}
				})
				.finally(() => (this.loadingFillRate = false));
		},
		submitOrderDeliveryTime() {
			this.form.client = this.customer.KUNNR;
			this.loadingOrderDeliveryTime = true;

			axios
				.post("orderDeliveryTime", this.form,
				{
					noCancelRequest:true,
					cachable:true,
				})
				.then((response) => {
					//	console.log(response);
					this.dataODT = response.data.dataOrderDeliveryTime[0];
					console.log(this.dataODT)
				})
				.catch((error) => {
					if (error.response.data.errors) {
						this.msg = error.response.data.errors;
						this.hasError = true;
						setTimeout(() => {
							this.hasError = false;
						}, 8000);
					} else {
						//alert(error);
						console.log(error);
					}
				})
				.finally(() => (this.loadingOrderDeliveryTime = false));
		},
		calculePercent(value) {
			value = Number(value) ? value : 0;
			const total = Number(this.dataODT?.PED_TOTALES) ? this.dataODT?.PED_TOTALES : 0;
			if (value == 0 && total == 0 || total == 0) {
				return 0;
			}
			value = (value / total) * 100;
			let result = Number(value).toFixed(2);
			return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		},
		numberFormat(item, type) {
			var result = Number(item);
			result = Math.trunc(item)
			switch (type) {
				case 2:
					item =  result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
					break;
				default:
					result = result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
					item = result + "%";
					break;
			}
			return item;
		},
		dateFormat(item) { 
				const year = item.slice(0, 4);
				const month = item.slice(4);
				item = `${year}-${month}`;
				const parsedDate = moment(`${year}-${month}`, 'YYYY-MM');
				item = parsedDate.format('MMM / YY');
			return item;
		}

	},
	mounted() {
		//this.fillRate()
	},

};
</script>
  
<style scoped>
.custom-table {
	display: flex;
	flex-direction: column;
	border: 1px solid #ccc;
	max-width: 300px;
	/* Ajusta el ancho máximo según tus necesidades */
}

.custom-row {
	display: flex;
	flex-direction: row;
	border-top: 1px solid #ccc;
}

.custom-cell {
	flex: 1;
	padding: 8px;
	text-align: left;
	border: 2px;
}

.header {
	font-weight: bold;
	background-color: #f2f2f2;
	/* Cambia el color de fondo según tus preferencias */
}





.sticky-column {
	border-width: 0px;
	left: 0;
}

.font-size-x>td {
	font-size: 14px !important;
}

.all-borders-bsadow {
	box-shadow: inset -5px 0 5px -5px #00000029;
}

.label {
	font-weight: 700;
}

.no-border {
	border-width: 0px !important;
}

.no-border>td {
	border-width: 0px !important;
}

.box-shadow {
	box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
	opacity: 1;
}

.box {
	height: 150px;
	width: 300px;
	margin: 20px;
	border: 1px solid #ccc;
}

.box-top {
	box-shadow: 0 -5px 5px -5px #00000029;
}

.box-right {
	box-shadow: 5px 0 5px -5px #00000029;
}

.box-bottom {
	box-shadow: 0 5px 5px -5px #00000029;
}

.box-left {
	box-shadow: -5px 0 5px -5px #00000029;
}

.box-left-inset {
	box-shadow: inset -5px 0 5px -5px #00000029;
}

.box-right-inset {
	box-shadow: 5px 0 5px -5px #00000029;
}

.box-all {
	box-shadow: 0 0 5px #00000029;
}

@media (max-width: 600px) {
	.sticky-column {
		border-width: 0px;
		position: sticky;
		left: 0;
		padding: 1px !important;
		background-color: #fff;
		/* Cambia el color de fondo según tus preferencias */
		z-index: 1;
		/* Asegura que la columna fija esté por encima de las demás celdas */
	}

	.font-size-x>td {
		font-size: 11px !important;
	}
}

@media (min-width: 601px) and (max-width:960px) {
	.font-size-x>td {
		font-size: 12px !important;
	}
}
</style>