<template>
    <v-container white lighten-5 fluid class="card-rounded">

        <div class="d-flex align-items-center ">
            <div v-if="show"
                class="mr-4 float-left"                
                @click="$router.push('/home');"
                >
                <v-icon color="black" class="icon-return">
                    mdi mdi-arrow-u-left-top
                </v-icon>
            </div>
            <div v-else
                class="mr-4 float-left"                
                @click="show = true"
                >
                <v-icon color="black" class="icon-return">
                    mdi mdi-arrow-u-left-top
                </v-icon>
            </div>

            <v-card class="mx-auto rounded-lg elevation-4 ma-3 w-100" outlined :style="'border: 4px solid black; background-color:transparent;' ">
                <v-list-item-content>
                    <div class="text-general-module mb-0">
                        <v-icon color="black">mdi mdi-clock-outline</v-icon>
                        <span class="title-uppercase" v-if="view == 'box-tied'">RENGLONAJE CAJA/ATADO DEL TRIMESTRE AL DÍA</span>
                        <span class="title-uppercase" v-else-if="view == 'families'">Familias del trimestre al día</span>
                        <span class="title-uppercase" v-else-if="view == 'new-lines'">Renglones Nuevos en el trimestre al día</span>
                        <span class="title-uppercase" v-else-if="view == 'without-reorder'">Renglones sin reorden en los últimos 6 Meses</span>
                        <span class="title-uppercase" v-else-if="view == 'special-orders-pnc'">Renglones disponibles para pedido especial de PNC</span>
                        <span class="title-uppercase" v-else>RENGLONAJE CAJA/ATADO DEL TRIMESTRE AL DÍA</span>
                        / Trim {{ trim }} ({{ year }})
                    </div>                    
                </v-list-item-content>               
            </v-card>            
        </div>
           
            <MenuDivisiones 
                :loader="loader" 
                :view="view"
                :activeDIVI="activeDIVI" 
                :divisiones="divisiones"
                @filterDIVIs="filterDIVIs">
            </MenuDivisiones>           
            

            <div class="row d-flex justify-content-center">
                <div class="col-xl-9">

                    <!-- Table 1 -->
                    <div v-show="show" class="mb-2">
                        <table-families ref="table1"  
                            :items="filterItems" 
                            :materials="items_sub" 
                            :isLoading="loader"                          
                            @filterFamily="(data) => { searchParameters = data; filterFamily(); this.show = false }"
                            @sendItemCSV="sendItemCSV">
                        </table-families>
                    </div>
                    <!-- End Table 1 -->

                    <!-- Table 2 -->
                    <div v-if="!show" class="mb-2">
                        <table-materials ref="table2"
                            :items="selectMaterials"
                            :searchParameters="searchParameters" 
                            :optionSelectFamily="items"
                            :subtotal="subtotal"
                            :allItems="items_sub"
                            @filterFamily="(data) => { searchParameters = data; filterFamily() }"
                            @back="show = true"
                            @cantidad="updateCantidadMateriales"
                            @sendItemCSV="sendItemCSV"
                        ></table-materials>
                    </div>
                    <!-- End Table 2 -->

                    <div class="row d-flex justify-content-between" style="font-size: 14px">
                        <div class="col-xs-12 col-md-6 col-lg-6 text-left">
                            <p class="mb-0">*Ultima fecha de actualización:{{today}} </p>
                        </div>
                        <div class="col-xs-12 col-md-6 col-lg-6">
                            <p class="text-right text-conf">Información confidencial Prohibida su reproducción o divulgación total o parcial, así como su uso o aprovechamiento sin autorización escrita de IUSA.</p>
                        </div>
                    </div>

                </div>
            </div>            
          
    </v-container>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions } from "vuex";
import TableFamilies from './components/TableFamilies'
import TableMaterials from './components/TableMaterials'
import moment from 'moment'
import axios from "@/plugins/axios";
// import html2pdf from 'html2pdf.js'
import MenuDivisiones from './components/MenuDivisiones'
export default {
    props:['view', 'trim', 'year'],
    components: {TableFamilies, TableMaterials, MenuDivisiones},
    data () {
      return { 
        loader: true,
        today: moment().format('YYYY/MM/DD HH:mm:ss a'), 
        today2: moment().format('YYYY/MM/DD'),
        items: [], 
        items_sub: [],
        selectMaterials: [],
        searchParameters: {},
        show: true,
        divisiones: '',
        activeDIVI: '',
        filterItems: [],
        showLabels: true,
        subtotal: {
            subtotal: 0,
            numRenglones: 0
        },
        dialogImg: '',
        customer_code:[],
        pathRenglonaje: ''
      }      
    }, 
    beforeRouteLeave(to, from, next) {
        const dataToCache = {
            // items_sub: this.items_sub,
            selectMaterials: this.selectMaterials,
            searchParameters: this.searchParameters,
            activeDIVI: this.activeDIVI,
            show: this.show,
            filterItems: this.filterItems
        };        
        localStorage.setItem(`cachedArray-${this.$route.path}`, JSON.stringify(dataToCache));
        next();
    },
    computed:{
        ...mapGetters({
            user: 'auth/user'
        })      
    },
    async mounted() {
        //Validación tipo de vista
        const viewToPathMap = {
            'box-tied': '',
            'families': '',
            'new-lines': '/renglones-nuevos',
            'without-reorder': '/sin-reorden',
            'special-orders-pnc': '/pedido-especial-pnc'
        };
        this.pathRenglonaje = viewToPathMap[this.view];

        //Validación localStorage Cliente
        if (this.$getLocalStorageCustomer() == null) {
            this.customer_code = this.user.custumer_code;
        } else {
            const currentCustomerCode = JSON.parse(localStorage.getItem("currentCustomerCode")) || [];
            if (currentCustomerCode.length < 1) {
                this.$router.push({ name: "Home" });
            }
            this.customer_code = currentCustomerCode;
        } 
        
        await this.getFamilies()        
        this.setDIVIs() 
        this.filterDIVIs('todos')    
        this.getMaterials()
        this.updateCantidadMateriales()
    }, 
    watch:{
        show(value){
            if(value){
                this.$refs.table1.generarExcel()
            }
        }
    },
    methods:{
        ...mapActions({
            sendToExcel: 'printer/sendToExcel',
            sendToPDF: 'printer/sendToPDF',
        }),
        async getFamilies(){
            try {
                let response = await axios.get(`renglonaje${this.pathRenglonaje}/families`, {
                    params :{
                        trim: this.trim,
                        year: this.year,
                        customer_code: this.customer_code,
                        P_VTWEG: this.user.VTWEG,
                    }
                })
                this.items = response.data.data;  
                this.loader = false 
            } catch (error) {
                console.error(error);
            }         
        }, 
        async getMaterials(){  
            this.validateLocalStorage();            
            try {
                let response = await axios.get(`renglonaje${this.pathRenglonaje}/materials`, {
                    params :{
                        trim: this.trim,
                        year: this.year,
                        customer_code: this.customer_code,
                        P_VTWEG: this.user.VTWEG
                    }
                })
                this.items_sub = response.data.data;
                this.filterFamily()  
            } catch (error) {
                console.error(error);
                this.$router.push('/home')
            }
        },  
        filterFamily(){
            if(this.searchParameters != ''){
                let itemsFiltrados = this.items_sub.filter(item_sub => item_sub.MVGR4 === this.searchParameters.MVGR4 && item_sub.EWBEZ === this.searchParameters.EWBEZ);            
                if (itemsFiltrados) {
                    this.selectMaterials = itemsFiltrados.map((item, index) => item);
                }   
            }  
        },
        updateCantidadMateriales(){
            let numRenglones = 0
            const subtotal = this.items_sub.reduce((sum, element) => {
                const netwr1 = parseFloat(element.NETWR1);
                const fkimg = parseFloat(element.FKIMG);
                const cantidad = parseInt(element.cantidad);

                if(element.cantidad > 0){
                    numRenglones++
                }

                if (!isNaN(netwr1) && !isNaN(fkimg) && !isNaN(cantidad) && netwr1 > 0 && fkimg > 0) {
                    const unit = netwr1 / fkimg;
                    const total = unit * cantidad;
                    return sum + total;
                } else {
                    return sum;
                }
            }, 0);

            this.subtotal.subtotal = subtotal.toFixed(2);
            this.subtotal.numRenglones = numRenglones
        },
        setDIVIs(){
            // Utilizamos Set para obtener los valores únicos de la propiedad DIVI
            const uniqueDIVIs = [...new Set(this.items.map(item => item.DIVI))];
            this.divisiones = uniqueDIVIs;
        },
        filterDIVIs(data){
            this.activeDIVI = data
            this.show = true
            this.filterItems = []
            if(data == 'todos'){
                this.filterItems = this.items
            }else{
                this.filterItems = this.items.filter(item => item.DIVI == data)                
            }
        },
        capitalizeFirstLetter(text) {
            let textLower = text.toLowerCase()
            return textLower.charAt(0).toUpperCase() + textLower.slice(1)
        },  
        sendItemCSV(data){           
            this.sendToExcel({
                name: this.show ? `renglonaje-familias` : `renglonaje-materiales`,
                showLabels: this.show,
                items: data
            })
        },
        validateLocalStorage(){
            // Al cargar el componente, verificar si hay datos en caché y restaurarlos en el array            
            const cachedData = localStorage.getItem(`cachedArray-${this.$route.path}`); 
            let cacheDataJson = JSON.parse(cachedData)
            
            if (cacheDataJson != null) {
                // this.items_sub = cacheDataJson.items_sub;                               
                this.selectMaterials = cacheDataJson.selectMaterials;
                this.activeDIVI = cacheDataJson.activeDIVI
                this.show = cacheDataJson.show 
                this.filterItems = cacheDataJson.filterItems   
                //Si existe una consulta en los materiales
                if(Object.keys(cacheDataJson.searchParameters).length != 0){
                    this.searchParameters = cacheDataJson.searchParameters
                }           
                // Luego, eliminar los datos del caché para que no se restauren en futuras visitas
                localStorage.removeItem(`cachedArray-${this.$route.path}`);
            }   
        }           
    }
  }
</script>

<style scoped>
    .title-uppercase{
        text-transform: uppercase;
    }
    .icon-return{
        font-size: 32px !important;
        cursor: pointer;
    }    
</style>

<style>
    /* height Inputs selects */
    .renglonaje-select-small .v-text-field.v-text-field--solo.v-input--dense > .v-input__control {     
        min-height: auto !important;
    }   
    .renglonaje-select-small .v-text-field.v-text-field--solo .v-input__control {
        min-height: inherit !important;
    }
    .renglonaje-select-small .v-select__selection, .v-text-field__slot{
        font-size: 14px !important;              
    }

    .renglonaje-select-small .v-text-field__slot input{
        text-align: right;
    }
    /* End height Inputs selects */

     /* table renglonaje */
     .table-renglonaje td{
        height: 30px !important;
        font-size:14px !important;
        border-bottom: 0 !important;
    }

    .table-renglonaje th{
        height: 30px !important;
        font-size: 14px !important;
    } 

    .table-renglonaje thead{
        border-bottom: 2px solid #000 !important
     }

    .table-renglonaje thead tr{
        background: #f2f2f2 !important;
    }
    
    .table-renglonaje thead th{        
        font-size: 14px !important;
        font-weight: 500 !important;
        font-family: Roboto !important;
    }
    .table-renglonaje .footer-table{
        background: #f2f2f2 !important; 
    }

    .table-renglonaje .v-data-table__progress th{
        height: auto !important;
    }

    .table-renglonaje .footer-table td{
        height: 35px !important;
    }

    .table-renglonaje .link_table{
        color: rgba(0,0,0,.87) !important;        
    }
    /* end table renglonaje */
</style>