<template>
    <router-link v-if="!noLink" :to="to">
      <img src="@/assets/img/propuesta-1-x2.svg" alt="" :width="lg ? '200px' : width" />
    </router-link>
    <img  v-else src="@/assets/img/propuesta-1-x2.svg" alt="" :width="lg ? '200px' : width" />
  </template>
  
  <script>
  export default {
    name: 'IusaLogo',
  
    props: {
      width: {
        type: String,
        default: '120px',
      },
  
      to: {
        type: [String, Object],
        default: () => ({ name: 'Home' })
      },
  
      lg: Boolean,
      noLink: Boolean,
    }
  }
  </script>