<template>

    <div>

            <div class="row d-flex justify-content-center">                
                
                <div class="col-md-12">
                    <div class="row d-flex justify-content-between align-items-center">
                        <div class="col-sm-12 col-lg-4">
                            <div class="d-flex justify-content-start align-items-center">                            
                                <p class="mr-3 mb-0">Familia:</p>
                                <div class="renglonaje-select-small">
                                    <v-select
                                        :items="optionSelectFamily"  
                                        item-text="BEZEI"
                                        item-value="id"
                                        v-model="selectID"
                                        hide-details                                 
                                        small
                                        dense
                                        solo
                                        @change="sendFamilia()"                                        
                                    ></v-select>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-8 text-right">
                            <div class="bloq-sombra-interna">
                                <b>Total de Familia {{foundMaterial.BEZEI}} en el Periodo <br> ${{foundMaterial.TOTAL | formatCurrency}} / {{foundMaterial.PORC}}%</b>
                            </div>
                        </div>
                    </div>
                </div>
                

                <div class="col-md-12" id="div-a-imprimir-material">
                    <v-data-table
                        :headers="headers"
                        :items="items"
                        :footer-props="footerProps"
                        :options="dataTableOptions"  
                        :sort-by="sortBy"
                        :sort-desc="sortDesc"    
                        :mobile-breakpoint="null"   
                        :loading="isLoading"        
                        class="elevation-2 table-renglonaje"  
                                      
                    >    
                    <template v-slot:header>
                        <thead style="border-bottom: 0 !important">
                            <tr class="top-thead">
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th colspan="2" class="text-center line-bottom"><b>Comprados en el periodo</b></th>
                                <th></th>
                                <th></th>
                            </tr>
                            <!-- <tr>
                                <th class="text-center" v-for="header in headers" :key="header.value" :align="header.align" @click="sortByColumn(header.value)" :class="{ 'sortable': sortBy === header.value }">
                                    <div class="d-flex justify-content-center"
                                        @mouseover="hoverColumn = header.value"
                                        @mouseleave="hoverColumn = ''">
                                        <b>{{ header.text }}</b>                                    
                                        <div style="width: 20px">
                                            <v-icon small v-show="sortBy === header.value || hoverColumn === header.value" :class="{ 'active': sortDesc }">
                                                {{ sortDesc ? 'mdi-arrow-up' : 'mdi-arrow-down' }}
                                            </v-icon>
                                        </div>  
                                    </div>                                  
                                </th>
                            </tr> -->
                        </thead>
                    </template> 

                    <template v-slot:[`item.id`]="{ item }">
                        {{ items.indexOf(item) + 1 }}
                    </template>             

                    <template v-slot:[`item.MCS`]="{ item }">

                        <div class="d-flex justify-content-center">
                            <img v-if="item.MCS != ''" :src="`/img/renglonaje/penalizaciones/${item.MCS}.svg`" style="width:45px;" alt="">
                            <span v-if="item.listZks != ''" class="ml-2">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">                                    
                                        <img v-bind="attrs" v-on="on" src="/img/renglonaje/penalizaciones/ZKRG.svg" style="width:32px;" alt="">                                    
                                    </template>
                                    <span v-for="(zk, index) in item.listZks" :key="index" class="tooltip__zk">
                                        {{zk}}
                                        <span v-if="index < item.listZks.length - 1">,</span>
                                    </span>
                                </v-tooltip>
                            </span>
                        </div>
                        
                    </template>

                    <template v-slot:[`item.NETWR1`]="{ item }">                        
                            ${{ item.NETWR1 | formatCurrency}}                       
                    </template>

                    <template v-slot:[`item.cantidad`]="{ item }">                        
                        <div class="py-1 d-flex align-items-center renglonaje-select-small">
                            <v-text-field
                                label="Introducir cantidad" 
                                small
                                dense                          
                                solo                                    
                                style="min-width:100px"
                                type="number"
                                min="0"
                                hide-details="auto"
                                :rules="[v => /^[0-9]+$/.test(v) || 'Debe ser un número']"
                                v-model="item.cantidad"
                                @input="$emit('cantidad'); generarExcel()"
                            ></v-text-field>                         
                        </div>                        
                    </template>

                    <template v-slot:[`item.PROMEN`]="{ item }">                        
                        <a class="link_table" href="#" @click.prevent="openModal(item.MATNR)">{{ item.PROMEN }}</a>                        
                    </template>  
                        
                    </v-data-table>
 
                </div>


                <div class="col-md-12">
                    <div class="row">
                        <div class="col-sm-4 col-md-8 d-flex align-items-center  justify-content-start" v-if="is_customer">  
                            <button type="button" class="btn btn-danger text-white d-flex align-items-center ml-2" v-if="mostrarBoton && order_id != null"  @click="saveNewMaterialOrder()">
                                    <span class="mr-2">Pedido / Cotización</span>
                                    <v-icon size="25" class="text-white">mdi mdi-cash-multiple</v-icon>
                            </button>
                        </div>
                        <div class="col-12 col-sm-8 col-md-4">
                            <div class="bloq-sombra-interna" style="padding:20px 35px">
                                <b>Llevas {{subtotal.numRenglones}} renglones, Subtotal: ${{subtotal.subtotal}}</b>
                            </div>
                        </div>
                    </div>                    
                </div>
        </div>


       <!-- Diálogo de carga -->
      <v-dialog v-model="dialogoCarga" persistent max-width="500">
        <v-card color="blue">
          <v-card-text class="py-4">
            <div class="d-flex align-items-center justify-content-center">
              <v-progress-circular                
                indeterminate
                color="white"
              ></v-progress-circular>
              <span class="white--text ml-3">Se están guardando los datos, por favor espere.</span>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>

        <!-- Model Detalles -->
        <v-dialog v-model="dialog" persistent max-width="1200px"> 
              <v-card>
                <v-card-title class="d-flex justify-content-end">
                    <v-card-actions>
                        <v-btn icon large color="red red-darken-4" @click="dialog = false; fileURL = ''">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card-title>

                <v-card-text>
                    <div class="row">
                        <div class="col-12 text-left">                            
                            <v-btn :disabled="fileURL == ''"  v-bind:href="fileURL" depressed class="elevation-1">
                                Consultar Ficha Técnica
                                <v-icon class="ml-2" color="red red-darken-4">
                                    mdi-folder-outline
                                </v-icon>
                            </v-btn>
                        </div>
                    </div>
                    <div id="divID">
                        <div class="row">
                            <div class="col-12 text-center">
                                <div>
                                    <v-progress-circular v-if="loader"
                                        indeterminate
                                        color="primary"
                                        class="mb-5"
                                    ></v-progress-circular>
                                    <img v-else :src="image" style="width:150px" class="mb-5" alt="">
                                </div>
                                <h6>{{selectMaterial.MATNR}} {{selectMaterial.ARKTX}}</h6>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <v-simple-table class="mt-5 elevation-2 table-renglonaje">
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-center" v-for="(detalles, index) in selectMaterial.DETXMES.slice().reverse()" :key="index"><b>{{formatMes(detalles.NUMMES)}} / {{detalles.NUMANO}}</b></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="text-center" v-for="(detalles, index) in selectMaterial.DETXMES.slice().reverse()" :key="index">{{detalles.FKIMG}}</td>                                                
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </div>
                        </div>
                        <div class="row">                        
                            <div class="col-12">
                                <v-simple-table class="mt-5 elevation-2 table-renglonaje">
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <td colspan="5" style="border-bottom: 1px solid #e0e0e0" class="text-center"><b>Diagramas, manuales e Información Técnica</b></td>
                                            </tr>
                                            <tr>
                                                <th class="text-center">Manual o diagrama de  Instalación</th>
                                                <th class="text-center">Diagrama de  Relaciones</th>
                                                <th class="text-center">Boletines de Servicio</th>
                                                <th class="text-center">Manual Técnico de Reparación</th>
                                                <th class="text-center">Hoja de Seguridad</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="text-center" style="width: 20%">                                                    
                                                    <v-icon style="font-size:45px">
                                                        mdi mdi-folder-download
                                                    </v-icon>
                                                </td>
                                                <td class="text-center" style="width: 20%">
                                                    <v-icon style="font-size:45px">
                                                        mdi mdi-folder-download
                                                    </v-icon>
                                                </td>
                                                <td class="text-center" style="width: 20%">
                                                    <v-icon style="font-size:45px">
                                                        mdi mdi-folder-download
                                                    </v-icon>
                                                </td>
                                                <td class="text-center" style="width: 20%">
                                                    <v-icon style="font-size:45px">
                                                        mdi mdi-folder-download
                                                    </v-icon>
                                                </td>
                                                <td class="text-center" style="width: 20%">
                                                    <v-icon style="font-size:45px">
                                                        mdi mdi-folder-download
                                                    </v-icon>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </div>
                        </div>
                        <div class="row d-flex justify-content-end">
                            <div class="col-xs-12 col-md-6">
                                <p class="text-conf">Información confidencial Prohibida su reproducción o divulgación total o parcial, así como su uso o aprovechamiento sin autorización escrita de IUSA.</p>
                            </div>
                        </div>
                    </div>
                </v-card-text>
              </v-card>
          </v-dialog>
          <!-- End Model Detalles -->


        <v-dialog v-model="dialogMessage" max-width="100%" persistent @click:outside="dialogMessage = false">            
            <v-card class="dialog-card"> 
                <v-card-actions class="d-flex justify-content-center">
                    <v-btn depressed color="red white--text" @click="goTo('NewQuoterCart')">Ir al Pedido / Cotizador</v-btn>
                    <v-btn depressed @click="dialogMessage = false">Mantenerme en la Página</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from "vuex";
import moment from 'moment';
import axios from "@/plugins/axios";
export default {
    props: ['items', 'searchParameters', 'optionSelectFamily', 'subtotal', 'allItems'],
    data(){
        return{
            isLoading: true,
            dialogoCarga: false,
            dialogMessage: false,
            today: moment().format('YYYY-MM-DD'),
            dialog: false,
            selectMaterial: {},
            sumaPeriodo: 0,
            selectID: '',
            headers: [
                { text: 'id', value: 'id', align:"center" },
                { text: 'Código', value: 'MATNR', align:"center" },
                { text: 'Descripción', value: 'ARKTX', align:"left" },
                { text: '', value: 'MCS', align:"center" },
                { text: 'Piezas', value: 'FKIMG', align:"center" },
                { text: 'Monto', value: 'NETWR1', align:"center" },
                { text: 'Promedio mensual de compra', value: 'PROMEN', align:"center" },
                { text: 'Cantidad', value: 'cantidad', align:"center" }
            ], 
            footerProps: {
                itemsPerPageOptions: [10, 14, 24, 34, { text: 'Todas', value: -1 }],
                itemsPerPageText: 'Mostrar en grupo de:',
                showCurrentPage: false,
                pageText: 'Desplegado {0} / {1} de {2} Materiales'           
            }, 
            dataTableOptions: {
                itemsPerPage: 24,
                pagination: {
                    enabled: true,
                    options: {
                        numeric: true,
                    },
                },
            },
            sortBy: '',
            sortDesc: false,  
            hoverColumn: '',
            itemCSV: [], 
            image: '',
            loader: true,
            foundMaterial: {},
            order_id: null,
            order: null,
            fileURL: '',
            customer_code:[],
            is_customer:true,
        }
    },
    mounted(){
        
        if (this.$hasAnyRole(["Customer", "CustomerPR"])) { 
            this.customer_code = this.user.custumer_code;
            } else {
                // this.is_customer = false;
            const currentCustomerCode =
                JSON.parse(localStorage.getItem("currentCustomerCode")) || [];
            if (currentCustomerCode.length < 1) {
                this.$router.push({ name: "Home" });
            }
            this.customer_code = currentCustomerCode;
            }

        this.getOrderID()  
        this.generarExcel()        
    },
    computed:{
        ...mapGetters({
            user: 'auth/user'
        }),
        mostrarBoton() {
            return this.items.some(item => item.cantidad > 0);
        }
    },
    watch: {
        searchParameters:{
            immediate: true,
            handler(newVal){
                this.selectID = newVal.ID;     
                this.foundMaterial = this.optionSelectFamily.find(item => item.id === this.selectID);                   
            }
        },
        items: {
            immediate: true, // Ejecutar el watcher inmediatamente al montar el componente
            handler() {
                (this.items == '') ? this.isLoading = true : this.isLoading = false
                this.generarExcel()
            },
        },
    },
    methods:{      
        sortByColumn(column) {
            if (this.sortBy === column) {
                this.sortDesc = !this.sortDesc;
            } else {
                this.sortBy = column;
                this.sortDesc = false;
            }
        },
        generarExcel(){
            let newItems = []
            this.itemCSV = []            

            const csvHeader = [{
                    'C1': 'Version',
                    'C2': 'Codigo de productos',
                    'C3': 'Orden de Compra'     
                },
                {
                    'C1': '1.3.0',
                    'C2': 'IUSA',
                    'C3': this.order     
                }
            ]

            newItems.push({
                'C1': 'Cantidad',
                'C2': 'Codigo',
                'C3': 'Nombre'
            })
            for (let key in this.items) {                
                let material = this.items[key]; 
                if(this.items.some(item => item.cantidad > 0)){
                    if(material.cantidad != 0){                 
                        newItems.push({
                            'C1': material.cantidad,
                            'C2': material.MATNR,
                            'C3': material.ARKTX
                        });
                    }
                }else{
                    newItems.push({
                        'C1': material.cantidad,
                        'C2': material.MATNR,
                        'C3': material.ARKTX
                    });
                }
            }

            this.itemCSV = [...csvHeader, ...newItems]
            this.$emit('sendItemCSV', this.itemCSV)
        },
        openModal(MATNR){
            const material = this.items.find(item => item.MATNR === MATNR);
            if (material) { 
                this.selectMaterial = material 
                this.getImge(material.MATNR)
                this.getFileDownload(material.MATNR)
            }
            this.dialog = true
        },
        formatMes(mesNumero){
            let mesAbreviado = moment().month(mesNumero - 1).format('MMMM').toUpperCase();
            return mesAbreviado.charAt(0) + mesAbreviado.slice(1).toLowerCase();
        },
        getImge(codigo_material) {
            this.loader = true
            axios.get("https://bancodeimagenes.iusa.com.mx/api/listDropboxNew?code="+codigo_material+"&quant=1&view=0", {
                headers: {
                    'Content-Type':'application/json',
                    'api-key':'$2y$10$PtV2yrGrt/CwoVlqGDroiOyoUSq581Pq.6NVyAX2vQJUBdE21gxKC',
                    'Authorization':'Bearer token',
                }
            })
            .then((response) => {
                if(response.data[0].path) {
                    this.image = response.data[0].path
                    this.loader = false
                }                
            })
        },
        sendFamilia(){
            let itemFound = this.optionSelectFamily.find(item => item.id === this.selectID);    
            this.$emit('filterFamily', {MVGR4: itemFound.MVGR4, EWBEZ: itemFound.EWBEZ, ID: itemFound.id})
        },
        getOrderID() {
            let type = "CL";
            //let type = "VE"; // ENCASO DE PORTAL VENDEDOR ENVIAR ESTA VARIABLE
            axios.post("quoterOrder", {
            customer: this.customer_code,
            user_id: this.user.id,
            type: type,
            nu_tender: '',
          }).then((response) => {
            this.order_id = response.data.order_id;
            this.getOrderInfo(this.order_id)
          })
        },
        getOrderInfo(id) {
            axios.post("getQuoterInfo", {
                id_order: id,
            }).then((response) => {
                this.order = response.data.name;
                this.generarExcel()
            })
        },
        async saveNewMaterialOrder() {
            const items = this.allItems.filter(item => this.validateCantidad(item.cantidad) && item.cantidad > 0);
            if (items.length == 0) { return false } 
                
            this.dialogoCarga = true;    
            const maxIntentos = 3; // Número máximo de reintentos
            for (let item of items) {
                if (item.cantidad > 0) {
                    let intentos = 0;
                    let registroExitoso = false;

                    const response = await axios.get("/renglonaje/show", {
                       params:{
                            quoter_id: this.order_id,
                            code: item.MATNR
                       }
                    })

                    let cantidad = 0
                    if(response.data.data){
                        cantidad = parseInt(response.data.data.cantidad) + parseInt(item.cantidad)
                    }else{
                        cantidad = item.cantidad
                    }

                    console.log(cantidad)

                    while (intentos < maxIntentos && !registroExitoso) {
                        try {
                            const responseMaterialInfo = await axios.post("getMaterialInfo", {
                                code: item.MATNR,
                                units: cantidad,
                                customer: this.customer_code,
                                VKORG: this.user.VKORG,
                                VTWEG: this.user.VTWEG,
                                VKBUR: this.user.VKBUR,
                            });

                            if (responseMaterialInfo.data && Object.keys(responseMaterialInfo.data).length > 0) {
                                try {
                                    await axios.post("saveNewMaterialQuoter", {
                                        order_id: this.order_id,
                                        item_cart: responseMaterialInfo.data,
                                    });

                                    //Si se registró correctamente y ya existía un material similar lo eliminamos
                                    if(response.data.data){
                                        await axios.delete(`/renglonaje/${response.data.data.id}`)
                                    }

                                    registroExitoso = true;
                                } catch (error) {
                                    console.error(error);
                                    intentos++;
                                }
                            } else {
                                console.log("responseMaterialInfo.data está vacío o no tiene datos");
                                intentos++;
                            }
                        } catch (error) {
                            console.error(error);
                            intentos++;
                        }
                    }
                }
            }

            this.dialogoCarga = false;
            this.dialogMessage = true;
            
        },
        goTo(routeName) {
            this.$router.push({ name: routeName });
        },
        validateCantidad(value) {
            return /^[0-9]+$/.test(value);
        },
        getFileDownload(codigo_material) {
            axios.get("https://bancodeimagenes.iusa.com.mx/api/allFileDropboxPdf?code="+codigo_material+"&type=6", {
                headers: {
                    'Content-Type':'application/json',
                    'api-key':'$2y$10$PtV2yrGrt/CwoVlqGDroiOyoUSq581Pq.6NVyAX2vQJUBdE21gxKC',
                    'Authorization':'Bearer token',
                }
            }).then((response) => {
                if(response.data[0].path) {
                    this.fileURL = response.data[0].path
                }        
            }).catch((error) => {
                this.fileURL = ''
                console.log(error);                       
            });
        },
        
        getColor (value) {
            if (value == 'MC0') return 'mc0'
            else if (value == 'MC1') return 'mc1'
            else if (value == 'MC2') return 'mc2'
            else if (value == 'MC3') return 'mc3'
            else if (value == 'MC4') return 'mc4'
            else if (value == 'MC5') return 'mc5'
        },
    },   
}
</script>

<style scoped>
    .dialog-card {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: auto;
    }

    .bloq-sombra-interna{
        padding: 10px;
        width: auto;
        display: table;
        float: right;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
        border-radius: 5px;
        font-size: 14px
    }    

    table thead tr{
        background: #f2f2f2 !important;
    }

    table .footer-table{
        background: #f2f2f2 !important; 
    }

    .top-thead th{
        border-bottom: 0 !important;
    }

    .top-thead th.line-bottom{
        border-bottom: 1px solid #e0e0e0 !important
    }

    @media (max-width: 600px){
        .top-thead{
            display: none;
        }
    }

    .mc0{
        background: #e0a800 !important;
        color: #fff !important;
    }
    .mc1{
        background: #e20028 !important;
        color: #fff !important;
    }
    .mc2{
        background: #138496 !important;
        color: #fff !important;
    }
    .mc3{
        background: blue !important;
        color: #fff !important;
    }
    .mc4{
        background: green !important;
        color: #fff !important;
    }
    .mc5{
        background: purple !important;
        color: #fff !important;
    }

    .tooltip__zk{
        font-size: 12px !important;
    }
</style>