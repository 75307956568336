<template>
  <div id="contenedor">    
    <v-container fluid class="white lighten-5" style="padding-left: 3%;padding-right: 3%;">
      <v-row>
        <v-col md="12">
        
          <div class="d-flex align-items-center">
              <v-card class="mx-auto rounded-lg elevation-4 ma-3 w-100" outlined :style="'border: 4px solid black; background-color:transparent;' ">
                  <v-list-item-content>
                      <div class="text-general-module mb-0">
                        <v-icon color="black">mdi-folder-star-multiple-outline</v-icon>
                        <span>&nbsp;CARTERA</span>
                      </div>                    
                  </v-list-item-content>               
              </v-card>

              <menu-print
                  :nameCSV="'cartera'"
                  :existExcel="false"
                  @generarDocumento="generarDocumento">
              </menu-print>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="12">
          <v-card class="card-border" v-if="!loadingClient">
            <v-card-text>
              <v-row>
                <v-col cols="10" offset="1">
                  <v-row class="box-white">
                    <v-col class="cartera-filter" cols="3" style="margin-top: 1.5em;">
                      <b style="color: black;">Zona {{ zone }}</b>
                    </v-col>
                    <v-col class="cartera-filter" cols="3">
                      <v-checkbox v-model="isCheckedFacturas" class="font-weight-bold" label="Solo Facturas Vencidas" @change="handleCheckboxChangeFacturas"></v-checkbox>
                    </v-col>
                    <v-col class="cartera-filter" cols="3">
                      <v-row style="margin-top: 0.4em;">
                        <v-col cols="3">
                          <b style="color: black;">Cliente:</b>
                        </v-col>
                        <v-col cols="9">
                          <custom-select :options="clientsSelect" :placeholder="'Mostrar Todos'" v-model="clientCurrent"></custom-select>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col class="cartera-filter" cols="3">
                      <!--v-if="clientsSaldoVencido.length > 0"-->
                      <v-checkbox v-model="isChecked" class="font-weight-bold" label="Solo clientes con saldos vencidos" @change="handleCheckboxChange"></v-checkbox>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col md="12">
          <v-card class="card-border">
            <v-card-text>
              <v-row v-if="!loadingClient">
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-card class="mt-5">
                    <v-card-text>
                      <v-simple-table v-for="client in clients" :key="client.KUNNR" class=" my-t">
                        <template v-slot:default>
                          <thead class="table-heading" style="background:#F2F2F2;border-bottom-color: rgb(112, 112, 112);">
                            <tr>
                              <th colspan="8" class="text-left text-dark" style="background:#F2F2F2;border-bottom-color: rgb(112, 112, 112);"><b style="color:red;">Cliente</b></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr style="border:1px solid #DDE1E6;">
                              <td colspan="4" style="border-left:1px solid #DDE1E6;">
                                  <b><u>{{ client.NAME1 }}</u></b>
                              </td>
                              <td colspan="4">
                                  <b>Cliente N° {{ formatCustomer(client.KUNNR) }}</b>
                              </td>
                            </tr>
                            <tr style="border:1px solid #DDE1E6;">
                              <td colspan="4">
                                  <v-row>
                                      <v-col class="text-center" cols="12">
                                        {{ client.STREET + ' ' + client.HOUSE_NUM1 + ' ' + client.ORT01 + ' ' + client.CITY2 + ' ' + client.POST_CODE1 }}
                                      </v-col>
                                  </v-row>
                              </td>
                            </tr>
                          </tbody>
                          <thead class="table-heading">
                            <tr>                      
                              <th colspan="8" class="text-left text-dark t-shadow">Cartera al {{ dateCurrent }}</th>
                            </tr>
                          </thead>
                          <tbody>
                              <tr style="border:solid 1px #DDE1E6;">
                                  <td colspan="4" style="border-right:1px solid #DDE1E6;">
                                      <v-row>
                                          <v-col class="text-left" cols="8">
                                              Límite de crédito
                                          </v-col>
                                          <v-col class="text-left" cols="4">
                                            ${{ formatPrice( client.KLIMK ) }}
                                          </v-col>
                                      </v-row>
                                  </td>
                                  <td colspan="4">
                                      <v-row>
                                          <v-col class="text-left" cols="8">
                                              Saldo Vencido
                                          </v-col>
                                          <v-col class="text-left" cols="4">
                                              <span style="color:red;">${{ formatPrice( client.SVENC ) }}</span>
                                          </v-col>
                                      </v-row>
                                  </td>
                              </tr>
                            <tr style="border:solid 1px #DDE1E6;">
                              <td colspan="4" style="border-right:1px solid #DDE1E6;">
                                  <v-row>
                                      <v-col class="text-left" cols="8">
                                          Límite de crédito comprometida
                                      </v-col>
                                      <v-col class="text-left" cols="4">
                                        ${{ formatPrice( client.SKFOR ) }}
                                      </v-col>
                                  </v-row>
                              </td>
                              <td colspan="4">
                                  <v-row>
                                      <v-col class="text-left" cols="8">
                                          Saldo Total
                                      </v-col>
                                      <v-col class="text-left" cols="4">
                                        ${{ formatPrice( client.STOTA ) }}
                                      </v-col>
                                  </v-row>
                              </td>
                            </tr>
                            <tr style="border:solid 1px #DDE1E6;">
                              <td colspan="4" style="border-right:1px solid #DDE1E6;">
                                  <v-row>
                                      <v-col class="text-left" cols="8">
                                          Límite de crédito disponible
                                      </v-col>
                                      <v-col class="text-left" cols="4">
                                        <span v-if="client.CREDD > 0" style="color:green;">${{ formatPrice( client.CREDD ) }}</span>
                                        <span v-else style="color:red;">${{ formatPrice( client.CREDD ) }}</span>
                                      </v-col>
                                  </v-row>
                              </td>
                              <td colspan="4">
                                  <v-row>
                                      <v-col class="text-left" cols="8">
                                          Notas de crédito pendientes de aplicar
                                      </v-col>
                                      <v-col class="text-left" cols="4">
                                        ${{ formatPrice( client.NTCPA ) }}
                                      </v-col>
                                  </v-row>
                              </td>
                            </tr>
                            <tr style="border:solid 1px #DDE1E6;">
                              <td colspan="4" style="border-right:1px solid #DDE1E6;">
                                  <v-row>
                                      <v-col class="text-left" cols="8">
                                          <b>Promedio ponderado de días de pago</b>
                                      </v-col>
                                      <v-col class="text-left" cols="4">
                                          <b>{{ client.PPDP }}</b>
                                      </v-col>
                                  </v-row>
                              </td>
                              <td colspan="4">
                                  <v-row>
                                      <v-col class="text-left" cols="8">
                                          <b>Pagos pendientes de aplicar</b>
                                      </v-col>
                                      <v-col class="text-left" cols="4">
                                          <b>${{ formatPrice( client.PPPA ) }}</b>
                                      </v-col>
                                  </v-row>
                              </td>
                            </tr>
                          </tbody>
                          <thead class="table-heading">
                            <tr>                      
                              <th colspan="8" class="text-left text-dark t-shadow">Movimientos al {{ dateCurrent }}</th>
                            </tr>
                          </thead>
                          <tbody>
                              <tr style="border:solid 1px #DDE1E6;">
                                  <td style="border-right:1px solid #DDE1E6;">
                                      <b>Número de documento</b>
                                  </td>
                                  <td style="border-right:1px solid #DDE1E6;">
                                      <b>Tipo de documento</b>
                                  </td>
                                  <td style="border-right:1px solid #DDE1E6;">
                                      <b>Fecha de documento</b>
                                  </td>
                                  <td style="border-right:1px solid #DDE1E6;">
                                      <b>Fecha de vencimiento</b>
                                  </td>
                                  <td style="border-right:1px solid #DDE1E6;">
                                      <b>Días vencido</b>
                                  </td>
                                  <td style="border-right:1px solid #DDE1E6;">
                                      <b>Monto</b>
                                  </td>
                                  <td style="border-right:1px solid #DDE1E6;">
                                      <b>Saldo a vencer</b>
                                  </td>
                                  <td style="border-right:1px solid #DDE1E6;">
                                      <b>Saldo vencido</b>
                                  </td>
                              </tr>
                              <template v-if="client.MOVIMIENTOS.MOVIMIENTO != null || client.MOVIMIENTOS.MOVIMIENTO != ''">
                                <tr v-for="movement in client.MOVIMIENTOS.MOVIMIENTO" :key="movement.BELNR" :class="{ 'red-row': parseFloat(movement.DMORA) > 0 }" style="border:solid 1px;">
                                    <td style="border-right:1px solid #DDE1E6;border-left: 1px solid #DDE1E6;">
                                      {{ movement.BELNR }}&nbsp;
                                      
                                      <!-- <a style="cursor:pointer" @click="getPDFXMLInvoice(movement.BELNR)"><img src="/img/Grupo3159.svg"></a> -->

                                      <a v-if ="movement.BELNR != '' "
                                        @click=" getPDFXMLInvoice(movement.BELNR,1)"> 
                                        <v-icon  
                                            dense 
                                            large 
                                            color="red"
                                            style="font-size:22px">
                                        mdi-file-download
                                        </v-icon></a>   

                                        <a v-if ="movement.BELNR != ''"
                                          @click="getPDFXMLInvoice(movement.BELNR,2)" > 
                                          <v-icon  
                                              dense 
                                              large 
                                              color="blue"
                                              style="font-size:22px">
                                          mdi-file-download
                                          </v-icon></a> 

                                    </td>
                                    <td style="border-right:1px solid #DDE1E6;">
                                      {{ movement.TIPO }}
                                    </td>
                                    <td style="border-right:1px solid #DDE1E6;">
                                      {{ movement.BUDAT }}
                                    </td>
                                    <td style="border-right:1px solid #DDE1E6;">
                                      {{ movement.FVENC }}
                                    </td>
                                    <td :style="{ 'border-right': '1px solid #DDE1E6', 'color': parseFloat(movement.DMORA) < 0 ? 'green' : 'black' }">
                                      <template v-if="parseFloat(movement.DMORA) > 0">
                                        <span style="color:red">{{ movement.DMORA }}</span>
                                      </template>
                                      <template v-else>
                                        <span>-{{ daysDifference(movement.FVENC) }}</span>
                                      </template>
                                    </td>
                                    <td style="border-right:1px solid #DDE1E6;">
                                      ${{ formatPrice( movement.DMBTR ) }}
                                    </td>
                                    <td style="border-right:1px solid #DDE1E6;">
                                      <template v-if="parseFloat(movement.DMORA) == 0">
                                        ${{ formatPrice( movement.DMBTR ) }}
                                      </template>
                                    </td>
                                    <td style="border-right:1px solid #DDE1E6;">
                                      <template v-if="parseFloat(movement.DMORA) > 0">
                                        ${{ formatPrice( movement.DMBTR ) }}
                                      </template>
                                    </td>
                                </tr>
                              </template>
                              <template v-else>
                                <tr>
                                  <td colspan="8">No Hay Movimientos</td>
                                </tr>
                              </template>
                              <tr v-if="client.MOVIMIENTOS.MOVIMIENTO != null || client.MOVIMIENTOS.MOVIMIENTO != ''" style="border:solid 1px;border-left: 1px solid #DDE1E6;">
                                  <td colspan="5"></td>
                                  <td>
                                      <b>Subtotales:</b>
                                  </td>
                                  <td>
                                      <b>${{ formatPrice( client.subtotal ) }}</b>
                                  </td>
                                  <td style="border-right:1px solid #DDE1E6;"></td>
                              </tr>
                              <tr v-if="client.MOVIMIENTOS.MOVIMIENTO != null || client.MOVIMIENTOS.MOVIMIENTO != ''" style="border:solid 1px;border-left: 1px solid #DDE1E6;">
                                  <td colspan="7"></td>
                                  <td class="text-right" style="border-right:1px solid #DDE1E6;">
                                    <b>Saldo Total: ${{ formatPrice( client.total ) }}</b>
                                  </td>
                              </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                      
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <v-row v-if="loadingClient">
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-progress-linear
                    indeterminate
                    color="cyan"
                  ></v-progress-linear><br>
                  Cargando... Por favor espere
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <LegacyFooter></LegacyFooter>
    </v-container>
  </div>
</template>
  
  <script>
  import axios from "@/plugins/axios";
  import { mapGetters } from "vuex";
  import html2pdf from 'html2pdf.js'
  import MenuPrint from '@/components/MenuPrint'
  import CustomSelect from "./CustomSelect.vue";
  import LegacyFooter from "@/views/seller/components/LegacyFooter.vue"
  
  export default {
    name: "Briefcase",
    components: {MenuPrint,CustomSelect,LegacyFooter},
    props: [],
    data() {
      return {
        clientCurrent:'Mostrar Todos',
        isChecked: false,
        isCheckedFacturas: false,
        clients:[],
        clientsAll:[],
        clientsSelect:[],
        clientsSaldoVencido:[],
        facturaVencida:[],
        facturaVencidaKey:[],
        facturas:[],
        facturasCurrent:[],
        loadingClient:true,
        dayHabil:true,
        dayNatural:true,
        period: '',
        dateCurrent: null,
        isMobile: false,
        quarters: [],
        quartersData:[],
        months: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
        monthCurrent: '',
        dateBreadCrumb: '',
        zone: '',
  
      };
    },
  
    async mounted() {

      let current_date = new Date();
      let hours = current_date.getHours();
      let minutes = current_date.getMinutes();
      let am_pm = hours >= 12 ? "pm" : "am";

      // Convierte las horas al formato de 12 horas
      hours = hours % 12;
      hours = hours ? hours : 12; // Si las horas son 0, establece 12 en su lugar
      minutes = minutes < 10 ? "0" + minutes : minutes;
    
      this.dateCurrent =
      current_date.getDate() +
      " de " +
      this.months[current_date.getMonth()] +
      " del " +
      current_date.getFullYear() +
      " " +
      hours +
      ":" +
      minutes +
      " " +
      am_pm;

      this.dateBreadCrumb = current_date.getDate() + " de " + this.months[current_date.getMonth()] + " " + current_date.getFullYear();
      // this.breadcrumbsItems.push(
      // {
      //   text: 'Aguascaliente 2023 - Cartera del ' + this.dateBreadCrumb,
      //   disabled: true,
      //   href: '/',
      // });

      this.monthCurrent = this.months[current_date.getMonth()].slice(0,3);

      window.addEventListener('resize', this.checkMobile);
      this._getClients();
    },
  
    computed: {
      ...mapGetters({
        authenticated: "auth/authenticated",
        user: "auth/user",
      }),
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.checkMobile);
    },
    methods: {
      checkMobile() {
        this.isMobile = window.innerWidth <= 600;
      },
      parseDate(dateString) {
        const parts = dateString.split('/');
        return new Date(parts[2], parts[1] - 1, parts[0]);
      },
      daysDifference(endDate) {
        // Convertir la fecha de vencimiento a un objeto de fecha
        const endDateObj = this.parseDate(endDate);

        // Obtener la fecha actual
        const currentDate = new Date();

        // Calcular la diferencia en milisegundos
        const timeDifference = endDateObj - currentDate;

        // Convertir la diferencia a días
        const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

        return daysDifference;
      },
      _getClients() {
        var client = null;
        
        // if( this.user.custumer_code ){
        //   client = this.user.custumer_code;
        // }
        // else{
          client = this.user.employee_number;
          //2543897;
        // }
        //client = "2543899";
        
        axios
          .post("brief-case/general", {
            client: client,
            vkorg: this.user.VKORG,
            channel: this.user.VTWEG
          })
          .then((response) => {
            this.clients = response.data;
            this.clientsAll = response.data;
            // this.clientsSelect = response.data;//debugger;
            // this.clientsSelect.push({
            //   NAME1: 'Mostrar Todos',
            //   KUNNR: 'Mostrar Todos',
            // })
            // Filtrar y mapear los objetos para obtener solo NAME1 y KUNNR
            this.clientsSelect = response.data.map(client => ({
              NAME1: client.NAME1,
              KUNNR: client.KUNNR
            }));

            // Agregar la opción "Mostrar Todos" al final del array
            this.clientsSelect.push({
              NAME1: 'Mostrar Todos',
              KUNNR: 'Mostrar Todos'
            });

            for( var c = 0; c < this.clients.length; c++ ){
              if(this.clients[c].BZIRK!=null && this.clients[c].BZTXT!=null){
                this.zone = this.clients[c].BZIRK + ' ' + this.clients[c].BZTXT;
              }

              var subtotal = 0;
              var total = 0;
              this.facturas[this.clients[c].KUNNR] = this.clients[c].MOVIMIENTOS.MOVIMIENTO;
              this.facturasCurrent[this.clients[c].KUNNR] = this.clients[c].MOVIMIENTOS.MOVIMIENTO;

              if(this.clients[c].CTESV != "0" || this.clients[c].CTESV != 0){
                this.clientsSaldoVencido.push(this.clients[c]);
              }
              if(this.clients[c].MOVIMIENTOS.MOVIMIENTO){
                if(this.clients[c].MOVIMIENTOS.MOVIMIENTO.length > 0){
                  this.facturaVencida[this.clients[c].KUNNR] = [];
                  for( var m = 0; m < this.clients[c].MOVIMIENTOS.MOVIMIENTO.length; m++ ){
                    total +=  parseFloat( this.clients[c].MOVIMIENTOS.MOVIMIENTO[m].DMBTR );
                    if( this.clients[c].MOVIMIENTOS.MOVIMIENTO[m].DMORA > 0 ){
                      this.facturaVencidaKey.push(this.clients[c].KUNNR);
                      this.facturaVencida[this.clients[c].KUNNR].push(this.clients[c].MOVIMIENTOS.MOVIMIENTO[m]);
                    }
                    else{

                      subtotal += parseFloat( this.clients[c].MOVIMIENTOS.MOVIMIENTO[m].DMBTR );
                    }
                  }
                }
                else if(this.clients[c].MOVIMIENTOS.MOVIMIENTO.BELNR){
                  this.facturaVencida[this.clients[c].KUNNR] = [];
                  
                  total +=  parseFloat( this.clients[c].MOVIMIENTOS.MOVIMIENTO.DMBTR );
                  if( this.clients[c].MOVIMIENTOS.MOVIMIENTO.DMORA > 0 ){
                    this.facturaVencidaKey.push(this.clients[c].KUNNR);
                    this.facturaVencida[this.clients[c].KUNNR].push(this.clients[c].MOVIMIENTOS.MOVIMIENTO);
                  }
                  else{

                    subtotal += parseFloat( this.clients[c].MOVIMIENTOS.MOVIMIENTO.DMBTR );
                  }

                  this.clients[c].MOVIMIENTOS.MOVIMIENTO = [{
                    BELNR: this.clients[c].MOVIMIENTOS.MOVIMIENTO.BELNR,
                    BUDAT: this.clients[c].MOVIMIENTOS.MOVIMIENTO.BUDAT,
                    DIASC: this.clients[c].MOVIMIENTOS.MOVIMIENTO.DIASC,
                    DMBTR: this.clients[c].MOVIMIENTOS.MOVIMIENTO.DMBTR,
                    DMORA: this.clients[c].MOVIMIENTOS.MOVIMIENTO.DMORA,
                    ESTAT: this.clients[c].MOVIMIENTOS.MOVIMIENTO.ESTAT,
                    FVENC: this.clients[c].MOVIMIENTOS.MOVIMIENTO.FVENC,
                    STATUS: this.clients[c].MOVIMIENTOS.MOVIMIENTO.STATUS,
                    TIPO: this.clients[c].MOVIMIENTOS.MOVIMIENTO.TIPO,
                    VTEXT: this.clients[c].MOVIMIENTOS.MOVIMIENTO.VTEXT,
                    ZTERM: this.clients[c].MOVIMIENTOS.MOVIMIENTO.ZTERM
                  }];
                  
                  this.facturas[this.clients[c].KUNNR] = this.clients[c].MOVIMIENTOS.MOVIMIENTO;
                  this.facturasCurrent[this.clients[c].KUNNR] = this.clients[c].MOVIMIENTOS.MOVIMIENTO;
                }
              }

              this.clients[c].subtotal = subtotal.toFixed(2).toString();
              this.clients[c].total = total.toFixed(2).toString();
            }
            
            this.loadingClient = false;
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => (this.dialogLoadingDataClient = false));
      },
  handleCheckboxChange() {
    this.clients = this.clientsAll;
    // Este método se llama cuando cambia el estado del checkbox.
    if (this.isChecked) {
      
      // El checkbox está activo
      this.facturasCurrent = this.facturas;
      this.isCheckedFacturas = false;
      this.clients = this.clientsSaldoVencido;
    } else {

      this.clients = this.clientsAll;
      console.log(this.clients)
    }

    // this.clientsSelect = this.clients;
    this.clientCurrent = 'Mostrar Todos'
    
    this._getValor();
  },
  handleCheckboxChangeFacturas(){
    this.clients = this.clientsAll;
    if (this.isCheckedFacturas) {
      this.isChecked = false;
      // El checkbox está activo
      this.facturasCurrent = this.facturaVencida;
      const $this = this;

      // Filtra los clientes que no están en this.facturaVencida
      this.clients = this.clients.filter(function(cliente) {
        return $this.facturaVencidaKey.includes(cliente.KUNNR);
      });

      // this.clientsSelect = this.clients;
      // this.clientsSelect.push({
      //   NAME1: 'Mostrar Todos',
      //   KUNNR: 'Mostrar Todos',
      //   BZIRK: null,
      //   BZTXT: null,
      //   CITY2: null,
      //   COUNTRY: null,
      //   CREDD: null,
      //   CTESV: null,
      //   DMBTR: null,
      //   FNMSG: null,
      //   HOUSE_NUM1: null,
      //   HOUSE_NUM2: null,
      //   KLIMK: null,
      //   LDISP: null,
      //   MOVIMIENTOS:{
      //     MOVIMIENTO: []
      //   },
      //   MYERR: null,
      //   NAMEV: null,
      //   NTCPA: null,
      //   ORT01: null,
      //   PERNR: null,
      //   POST_CODE1: null,
      //   PPDP: null,
      //   PPPA: null,
      //   SKFOR: null,
      //   STOTA: null,
      //   STREET: null,
      //   SVENC: null,
      //   VTWEG: null
      // });
      this.clientCurrent = 'Mostrar Todos'
      this._getValorFacturaVencida();
    } else {

      this.clients = this.clientsAll;
      // this.clientsSelect = this.clients;
      this.facturasCurrent = this.facturas;
      this._getValor();
    }
  },
      formatPrice(value) {
        if(value){
          if( value < 0 ){
            var cred = value.split( '-' );
            return '-' + cred[1].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          }
          return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        return "0,00";
      },
      formatPor(value) {
        var valueXtra = value.split('.');
        return valueXtra[0] + '.' + valueXtra[1].slice(0,2);
      },   
      generarDocumento(){
          var element = document.querySelector('html');
          var opt = {
              margin:       1,
              filename:     'cartera.pdf',
              image:        { type: 'jpeg', quality: 0.98 },
              html2canvas:  { scale: 2 },
              jsPDF: { unit: 'px', format: [1400,1600], orientation: 'landscape' }
          };

          html2pdf().set(opt).from(element).save(); 
      },
      getPDFXMLInvoice(invoice){
        
        axios
          .post("InvoicePDFXML", {
            invoice: invoice,
            type: 1,
          })
          .then((response) => {
            this.url = response.data;
            window.open(this.url, '_blank');          
          })
          .catch((error) => {
            //alert(error);
            console.log(error);
          });

          axios
          .post("InvoicePDFXML", {
            invoice: invoice,
            type: 'xml',
          })
          .then((response) => {
            this.url = response.data;
            window.open(this.url, '_blank');          
          })
          .catch((error) => {
            //alert(error);
            console.log(error);
          });
      },
      _getValor(){
        for( var c = 0; c < this.clients.length; c++ ){
          var subtotal = 0;
          var total = 0;
          if(this.clients[c].MOVIMIENTOS.MOVIMIENTO.length > 0){
            
            for( var m = 0; m < this.clients[c].MOVIMIENTOS.MOVIMIENTO.length; m++ ){
              total +=  parseFloat( this.clients[c].MOVIMIENTOS.MOVIMIENTO[m].DMBTR );
              if( this.clients[c].MOVIMIENTOS.MOVIMIENTO[m].DMORA == 0 ){
                subtotal += parseFloat( this.clients[c].MOVIMIENTOS.MOVIMIENTO[m].DMBTR );
              }
            }
          }

          this.clients[c].subtotal = subtotal.toFixed(2).toString();
          this.clients[c].total = total.toFixed(2).toString();
        }
      },
      _getValorFacturaVencida(){
        for( var c = 0; c < this.clients.length; c++ ){
          var subtotal = 0;
          var total = 0;
          if(this.clients[c].MOVIMIENTOS.MOVIMIENTO.length > 0){
            
            for( var m = 0; m < this.clients[c].MOVIMIENTOS.MOVIMIENTO.length; m++ ){
              
              if( this.clients[c].MOVIMIENTOS.MOVIMIENTO[m].DMORA > 0 ){
                total +=  parseFloat( this.clients[c].MOVIMIENTOS.MOVIMIENTO[m].DMBTR );
                subtotal += parseFloat( this.clients[c].MOVIMIENTOS.MOVIMIENTO[m].DMBTR );
              }
            }
          }

          this.clients[c].subtotal = subtotal.toFixed(2).toString();
          this.clients[c].total = total.toFixed(2).toString();
        }
      },
      formatCustomer(data){
          if (data.length >= 6) {
              // Utilizar el método slice para obtener los últimos 6 dígitos
              var ultimosSeisDigitos = data.slice(-6);
              return ultimosSeisDigitos;
          } else {
              // Si la cadena tiene menos de 6 caracteres, devolver la cadena original
              return data;
          }
      }
    },
    watch: {
      clientCurrent(newValue, oldValue) {
        
        if(newValue != oldValue){
          if(newValue == 'Mostrar Todos'){
            this.clients = this.clientsAll;
          }
          else{
            var clientGet = this.clientsAll.filter((c =>{
              if(c.KUNNR == newValue){
                return c;
              }
            }));
            
            this.clients = clientGet;
          }

          this._getValor();
        }
      }
    },
  };
  </script>
  
<style scoped>
  .font-weight-bold .theme--light.v-label, .theme--light.v-label{
    font-weight: bold !important;
    color: black !important;
  }

  .table-heading {
  font-weight: bold;
  font-size: 0.75rem;
  color: #000;
  border-width: 1px 1px 3px 1px;
  border-color: rgb(224, 228, 232);
  height: 32px !important;

  background:#F2F2F2;
  border-bottom-color: rgb(112, 112, 112);
}

.card-border{
  box-shadow: rgba(224, 37, 37, 0.45) -1px 1px 7px 0px !important;
}

.table-heading tr {
  color: #000;
}

td {
    padding: 10px;
  }
.v-data-table>.v-data-table__wrapper>table>tbody>tr>td, .v-data-table>.v-data-table__wrapper>table>tfoot>tr>td, .v-data-table>.v-data-table__wrapper>table>thead>tr>td, .table-heading tr, .table-heading th{
  height: 32px !important;
}
.box-white{
border: 1px solid #ddd; /* Borde con color gris claro */
box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
}
.cartera-filter{
padding:0px !important;
}
.red-row {
color: red;
}
</style>
