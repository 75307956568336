<template>
  <div>
    <div class="d-flex flex-wrap" style="background-color: #000; color:#fff">
        <div class="text-left ml-3 mt-1" >Detalle de Crecimiento constante acumulado del asesor {{ seller.PERNR }} | {{ period }}</div> 
            <v-btn v-if="!loading" icon @click="closeModal()" class="ml-auto">
                <v-icon size="20" color="#fff" >mdi-close-circle</v-icon>
            </v-btn> 
    </div> 
    <v-simple-table dense>
      <template v-slot:default>
        <thead class="">
          <tr class="custom-table-bordered ">
            <th class="text-center text-body-1 text-dark" colspan="7"><b>Facturación actual</b></th>
          </tr>
          <tr class="custom-table-bordered ">
            <td class="text-center table-heading text-body-2 ">Mes</td>
            <td class="text-center table-heading text-body-2 ">Facturación</td>
          </tr>
        </thead>
        <tbody v-show="!loading" class="custom-table-bordered">
          <tr v-for="(item, index) in billing.FACACTUAL?.FAC_ACTUAL" :key="index">
            <td :style="index % 2 == 0 ? 'background-color: #fff;' : ''" class="table-heading small text-body-2">
               {{formatDate(item.SPMON) }}  
             
          </td>
            <td :style="index % 2 == 0 ? 'background-color: #fff;' : ''" class="table-heading text-body-2">
               ${{ number_format(item.FACNETA) }} 
            </td>
          </tr>
          <!-- <tr  v-if="option==4">
            <td   class="table-heading small text-body-2">
               {{formatDate(billing.FACACTUAL.FAC_ACTUAL.SPMON)}}  
             
          </td>
            <td class="table-heading text-body-2">
               $ {{ numberformat(billing.FACACTUAL.FAC_ACTUAL.FACNETA)}}
            </td>
            </tr> -->
            <tr style="">
            <td colspan="2" style="background-color: #fff;-webkit-box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.75);
-moz-box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.75);
box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.75)" class="table-heading small text-body-2">
              <v-row>
                <v-col cols="6" class="pa-3">
                  <b class="ml-n9">Total=</b>
                </v-col>
                <v-col cols="6">
                  <b class="ml-n9"> ${{ number_format(billing.TOTALACT) }}</b>
                </v-col>
              </v-row>
            </td>
          </tr>
        </tbody>
        <tbody v-if="loading">
          <tr>
            <td colspan="7"> <v-progress-linear class="mt-3" indeterminate color="teal"></v-progress-linear> <span
                class="text--disabled"> Cargando información</span></td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-simple-table dense v-if="!loading">
      <template v-slot:default>
        <thead class="">
          <tr class="custom-table-bordered ">
            <th class="text-center text-body-1 text-dark" colspan="7"><b>Facturación Anterior</b></th>
          </tr>
          <tr class="custom-table-bordered ">
            <td class="text-center table-heading text-body-2 ">Mes</td>
            <td class="text-center table-heading text-body-2 ">Facturación</td>
          </tr>
        </thead>
        <tbody v-if="!loading" class="custom-table-bordered">
          <tr v-for="(item, index) in billing.FACANT?.FAC_ANT" :key="index">
            <td :style="index % 2 == 0 ? 'background-color: #fff;' : ''" class="table-heading small text-body-2">{{
              formatDate(item.SPMON) }}</td>
            <td :style="index % 2 == 0 ? 'background-color: #fff;' : ''" class="table-heading text-body-2">
              ${{ number_format(item.FACNETA) }}</td>
          </tr>
          <tr>
            <td colspan="2" style="background-color: #fff;-webkit-box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.75);
-moz-box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.75);
box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.75)" class="table-heading small text-body-2">
              <v-row>
                <v-col cols="6" class="pa-3">
                  <b class="ml-n9">Total=</b>
                </v-col>
                <v-col cols="6">
                  <b class="ml-n9"> ${{ number_format(billing.TOTALANT) }} </b>
                </v-col>
              </v-row>
            </td>
          </tr>
          <tr class="yellow-tr ">
            <td colspan="2">
              <v-row>
                <v-col cols="6" class="pa-3">
                  <b class="ml-n9">Crecimiento= </b>
                </v-col>
                <v-col cols="6">
                  <b class="ml-n9"> {{ numberformat(billing.PROMCREC-100) }}% </b>
                </v-col>
              </v-row>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table> 
    <div class="text-right mt-2">
            <v-btn @click="closeModal()" outlined class="text-left" color="success">
                    Cerrar
                  </v-btn>
                </div>  
  </div>
</template>
<script>

import axios from "@/plugins/axios"; 
import helpers from "@/mixins/helpers";
export default ({
  watch: { 
    modal(value){
      if (value) {
        this.refreshData =  value;
      } 
    },
    refreshData(value){
      if (value) { 
        this.getPaymentInfo();
      } 
    }

  },
  props: {
    seller: {
      default: 0
    },    
    modal: {
      default: false
    },
    option: {
      default: 0
    },
    year: {
      default: 0
    },
    month: {
      default: 0
    },
  },
  computed: {
    period(){
      var label
      switch (this.option) {
        case 1: label = 'Acumulado '+this.year+" vs "+(this.year-2);  break; 
        case 3: label = 'Últimos 3 meses';  break;   
        case 2: label = this.monthNames[this.month-1]+" / "+(this.year);break; 
        case 4: label = 'Último mes vs 3 anteriores';  break; 
        default:
        label = this.monthNames[this.month]+" / "+(this.year);
          break;
      }
      return label;
    },
    currentDate() {
      const date = new Date();
      let month = date.getMonth();
      let year = date.getFullYear();
      return this.monthNames[month] + " " + year;
    },
    monthNames() {
      return ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
        "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
      ];

    }
  },
  mixins:[helpers],
  name: "ConstantGrowth",
  data() {
    return {
      historicos: [],
      loading: false,
      billing: [],
      refreshData:false,
    }
  },
   
  mounted() {
    this.refreshData = this.modal;
  }, 
  methods: {
    getPaymentInfo() {
      this.loading = true; 
      axios
        .post("seller/home/constant-growth-details", {
          seller_id: this.seller.PERNR,
          year: this.year,
          month: this.month,
          option: this.option,
          channel: this.seller.VTWEG
        })
        .then((response) => {
          if (response.data?.success == false) {
            console.log(response.data)
          } else {
            this.billing = response.data;
            this.billing.FACACTUAL.FAC_ACTUAL =  
             ('SPMON' in this.billing.FACACTUAL.FAC_ACTUAL) ?  //Cuando viene un item. Convertirlo en array con index 0
            [this.billing.FACACTUAL.FAC_ACTUAL]:this.billing.FACACTUAL.FAC_ACTUAL 
            this.billing.FACANT.FAC_ANT =  
             ('SPMON' in this.billing.FACANT.FAC_ANT) ?  //Cuando viene un item. Convertirlo en array con index 0
            [this.billing.FACANT.FAC_ANT]:this.billing.FACANT.FAC_ANT 
       
          }
          this.loading = false;
        }).finally(() => {
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });   
    },
    numberformat(number = 0) {
      let result = Number(number).toFixed(2);
      return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    formatDate(value) {
     // return value;
       const data = value.toString();
       const year = data.slice(0, 4);
       const month = data.slice(-2);
       return month + "/" + year;
    },
    closeModal() {
      this.refreshData=false;
      this.$emit('closeModal', true)
    }
  },


})
</script>
<style scoped> .yellow-tr {
   background: #FFD75E 0% 0% no-repeat padding-box;
   box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
   border: 1px solid #DDE1E6;
   opacity: 1;
 }</style>