<template>
    <div class="details--content" >
        <!--<a href="#" @click.prevent="open" style="text-decoration: underline; color:  #C82333;">
            {{pending}}
        </a>-->
        <!--<div class="datails--modal elevation-2" v-if="active">-->
            <div class="datails--modal elevation-2">
            <div class="modal--head">
                Detalles de Pedido Producto de Reorden
                <a href="#"  @click.prevent="active = false">
                    <v-icon color="white darken-2">
                        mdi-close-circle
                    </v-icon>
                </a>
            </div>
            <v-simple-table  border="1" class="details--table">
            <thead>
                <tr>
                <th>Cliente</th>
                <th>Nombre</th>
                <th width="30%">Aplicaciones Pendientes</th>
            </tr>
            </thead>
            <tbody v-if="app_one == 0">
                <tr  v-for="item in applications.applications" :key="item.KUNNR">
                    <td style="font-size: 12px;">{{item.KUNNR}}</td>
                    <td style="font-size: 12px;">{{item.NAME1}}</td>
                    <td style="font-size: 12px;">{{item.APPPE}}</td>
                </tr>
            </tbody>
            <tbody v-if="app_one != 0">
                <tr >
                    <td style="font-size: 12px;">{{applications.applications.KUNNR}}</td>
                    <td style="font-size: 12px;">{{applications.applications.NAME1}}</td>
                    <td style="font-size: 12px;">{{applications.applications.APPPE}}</td>
                </tr>
            </tbody>

            
        </v-simple-table>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import { eventBus } from '@/main.js';
import moment from 'moment';
export default {
    props: ['applications', 'pending','index', 'app_one'],
    data(){
        return {
            active: false,
        }
    },
    mounted() {
        const n = this
        eventBus.$on('close-aplicaciones', function(index){
            if (n.index !== index) {
                n.active = false;
            }
        }.bind(this));
    },
    watch: {
  
    },
    methods:{
        open(){
            eventBus.$emit('close-aplicaciones', this.index)
            this.active = true
        }
    },
    
}
</script>
<style scoped>
    .details--content {
        position: relative;
       
    }

    .datails--modal {
        position: absolute;
        top: 20px;
        right: -10px;
        background: white;
        z-index: 1;
    }

    .details--table{
        min-width: 400px;
        border: thin solid rgba(0,0,0,.12);
    }
    .details--table thead{
        background: #f2f2f2;        
    }
    .details--table thead th, .details--table tbody td{
        height: 35px !important;
        text-align: center;
        border-left: 1px solid #dddddd;
    }

    .details--table tbody tr:nth-child(even){
        background-color: #f2f2f2;
    }

    .modal--head{
        background: #000;
        color: #fff;
        font-size: 16px;       
        padding-left: 5px;
        display: flex;
        justify-content: space-between;
    }   
</style>