<template>
  <div
    class="w-full bg-white d-flex flex-wrap"
    style="padding-left: 1em; padding-right: 1em"
  >
    <h5 class="w-full text-left mt-4" style="border-bottom: 3px solid #344c8b">
      Solicitudes realizadas
    </h5>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="">Cliente</th>
            <th class="">Número Cliente</th>
            <th class="">Monto Pagaré</th>
            <th class="">Estatus</th>
            <th class="">Aval</th>
            <th class="">Documentos</th>
            <th class="">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in promissorynotes" :key="item.name">
            <td>{{ item.customer.company_name }}</td>
            <td>{{ item.customer.custumer_code }}</td>
            <td>{{ item.amount | formatCurrency }}</td>
            <td>{{ item.status.status }}</td>
            <td>{{ item.name_endorsement }}</td>
            <td>
              <v-btn
                class="mx-2"
                x-small
                color="primary"
                @click="openFile(item.promissory_notes_path)"
                title="Pagaré"
              >
                <v-icon> mdi-invoice-list-outline </v-icon>
              </v-btn>
              <v-btn
                class="mx-2"
                x-small
                color="primary"
                @click="openFile(item.card_id_path)"
                title="Identificación del aval"
              >
                <v-icon> mdi-id-card </v-icon>
              </v-btn>
              <v-btn
                class="mx-2"
                x-small
                color="primary"
                @click="openFile(item.endorsement_address_path)"
                title="Comprobante de domicilio"
              >
                <v-icon> mdi-home-account </v-icon>
              </v-btn>
            </td>
            <td>
              <v-btn
                class="mx-2"
                small
                color="primary"
                @click="editPromissory(item)"
                v-if="item.status.status == 'Rechazado Gerencia' || item.status.status == 'Rechazada Crédito'"
              >
                Editar
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="editingForm"
    >
      <v-card>
        <v-toolbar color="primary" dark>Editar solicitud de pagaré</v-toolbar>
        <v-card-text>
          <v-row class="mt-10">
            <v-col cols="12" md="6" class="d-flex flex-wrap">
              <label for="" class="w-full text-left font-weight-black mb-4"
                >PDF del pagaré:</label
              >
              <div
                class="w-100 text-left"
                v-if="promissorynote.promissory_notes_path"
              >
                <a
                  :href="`${this.$filesUrl}/${promissorynote.promissory_notes_path}`"
                  target="_blank"
                  >Ver actual</a
                >
              </div>
              <button
                class="custom-button font-weight-bold"
                @click="$refs.promissorynote.click()"
              >
                Seleccionar archivo
              </button>
              <input
                type="file"
                style="display: none"
                ref="promissorynote"
                accept="application/pdf"
                @change="selectPromissoryNote"
                v-if="form.reset"
              />
              <span
                class="ml-2 font-weight-black"
                v-if="promissorynote.promissory_note"
                >{{ promissorynote.promissory_note.name }}</span
              >
              <span class="ml-2 font-weight-black" v-else>Sin selección</span>
            </v-col>
            <v-col cols="12" md="6" class="d-flex flex-wrap">
              <label for="" class="w-full text-left font-weight-black mb-4"
                >INE del aval*:</label
              >
              <div class="w-100 text-left" v-if="promissorynote.card_id_path">
                <a
                  :href="`${this.$filesUrl}/${promissorynote.card_id_path}`"
                  target="_blank"
                  >Ver actual</a
                >
              </div>
              <button
                class="custom-button font-weight-bold"
                @click="$refs.endorsementid.click()"
              >
                Seleccionar archivo
              </button>
              <input
                type="file"
                style="display: none"
                ref="endorsementid"
                accept="application/pdf"
                @change="selectEndorsementId"
                v-if="form.reset"
              />
              <span
                class="ml-2 font-weight-black"
                v-if="promissorynote.card_id"
                >{{ promissorynote.card_id.name }}</span
              >
              <span class="ml-2 font-weight-black" v-else>Sin selección</span>
            </v-col>
            <v-col cols="12" md="6" class="d-flex flex-wrap">
              <label for="" class="w-full text-left font-weight-black mb-4"
                >Comprobante Domicilio del aval*:</label
              >
              <div
                class="w-100 text-left"
                v-if="promissorynote.endorsement_address_path"
              >
                <a
                  :href="`${this.$filesUrl}/${promissorynote.endorsement_address_path}`"
                  target="_blank"
                  >Ver actual</a
                >
              </div>
              <button
                class="custom-button font-weight-bold"
                @click="$refs.endorsementaddress.click()"
              >
                Seleccionar archivo
              </button>
              <input
                type="file"
                style="display: none"
                ref="endorsementaddress"
                accept="application/pdf"
                @change="selectEndorsementAddress"
                v-if="form.reset"
              />
              <span
                class="ml-2 font-weight-black"
                v-if="promissorynote.endorsementaddress"
                >{{ promissorynote.endorsementaddress.name }}</span
              >
              <span class="ml-2 font-weight-black" v-else>Sin selección</span>
            </v-col>
            <v-col cols="12">
              <label for="" class="w-full text-left font-weight-black"
                >Nombre del aval*:</label
              >
              <v-text-field
                :value="promissorynote.name_endorsement"
                @input="
                  (value) =>
                    setFormValue({ key: 'name_endorsement', value: value })
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <label for="" class="w-full text-left font-weight-black mb-1"
                >Comentarios*:</label
              >
              <v-textarea
                solo
                :value="promissorynote.comment"
                class="comments"
                @input="
                  (value) => setFormValue({ key: 'comment', value: value })
                "
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <button class="cancel-button" @click="cancel">Cancelar</button>
          <button class="success-button" @click="send">Enviar</button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "TablePromissoryNotes",

  props: ["customerCode"],

  data: () => ({
    editingForm: false,
    form: {
      reset: true,
    },
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
      promissorynotes: "promissorynotes/promissorynotes",
      promissorynote: "promissorynotes/promissorynote",
    }),
  },

  methods: {
    ...mapMutations({
      setCustomerCode: "promissorynotes/SET_CUSTOMER_CODE",
      setPromissoryNoteForm: "promissorynotes/SET_PROMISSORY_NOTE_FORM",
      setFormValue: "promissorynotes/SET_VALUE",
      resetForm: "promissorynotes/RESET_FORM",
    }),
    ...mapActions({
      loadCustomerId: "promissorynotes/loadCustomerId",
      loadPromissoryNotes: "promissorynotes/loadPromissoryNotes",
      updatePromissoryNote: "promissorynotes/updatePromissoryNote",
    }),
    editPromissory(item) {
      this.setPromissoryNoteForm(item);
      this.editingForm = true;
    },
    cancel() {
      this.editingForm = false;
      this.resetForm();
    },
    openFile(file) {
      window.open(`${this.$filesUrl}/${file}`, "_blank");
    },
    selectPromissoryNote(event) {
      this.setFormValue({
        key: "promissory_note",
        value: event.target.files[0],
      });
    },
    selectEndorsementId(event) {
      this.setFormValue({ key: "card_id", value: event.target.files[0] });
    },
    selectEndorsementAddress(event) {
      this.setFormValue({
        key: "endorsementaddress",
        value: event.target.files[0],
      });
    },
    submit() {
      this.updatePromissoryNote()
        .then(() => {
          this.$swal({
            title: "Enviado a validación con el gerente",
            type: "success",
          });
          this.resetForm();
          this.editingForm = false;
        })
        .catch((error) => {
          this.$swal({
            title: "Ocurrio un error al enviar el pagaré",
            text: error,
            type: "warning",
          });
        });
    },
    send() {
      this.$swal
        .fire({
          title: "Confirmar el envío del Pagaré",
          icon: "warning",
          showCancelButton: true,
          confirmButton: "#66BB6A",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Si, enviar!",
        })
        .then((result) => {
          if (result.value) {
            this.submit();
          }
        });
    },
  },

  mounted() {
    this.setCustomerCode(this.customerCode);
    this.loadCustomerId(this.customerCode).then((success) => {
        if(success) {
            this.loadPromissoryNotes(this.user.id);
        }
    })
  },
};
</script>

<style scoped>
.custom-button {
  background: #f1edec 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
  border: 1px solid #707070;
  opacity: 1;
  padding-left: 3em;
  padding-right: 3em;
  font-size: 0.7em;
}
.cancel-button {
  background: var(--danger-hover-c82333) 0% 0% no-repeat padding-box;
  background: #c82333 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
  border-radius: 5px;
  opacity: 1;
  width: 300px;
  color: #fff;
  margin-right: 0.5em;
  padding: 0.4em;
}
.success-button {
  background: #66bb6a 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
  border-radius: 5px;
  opacity: 1;
  width: 300px;
  color: #fff;
  margin-left: 0.5em;
  padding: 0.4em;
}
</style>
