<template>
    <div class="text-center">        

        <v-row>
          <v-col cols="4" sm="4" md="4">
            <v-checkbox class="mt-0"
              v-model="filtroPNC"
              label="Mostrar Clientes que aplican para PNC"
              color="blue"
              hide-details
              @click="currentPage = 1"
            ></v-checkbox>
          </v-col>
            <v-col cols="4">
                <title-planner :semana="semana" :title="'Resumen de la Semana'"></title-planner>
            </v-col>          
        </v-row>       

        <div class="table__content">
            <v-simple-table class="elevation-2">
                <template v-slot:default>
                    <thead class="resume__thead--color">
                        <tr >
                        <th scope="col" class="text-center">#</th>
                        <th scope="col" class="text-center">Frecuencia <br>visita</th>
                        <th scope="col" class="text-center">Dia(s) <br> Visita</th>
                        <th scope="col" class="text-center">FNETA <br> Trimestre {{obtenerTrimestre()}}</th>
                        <th></th>
                        <th scope="col" class="text-center">Cliente</th>
                        <th scope="col" class="text-center">Nombre del Cliente</th>                       
                        <th scope="col" class="text-center">RN</th>                        
                        <th scope="col" class="text-center">PNC</th>                                  
                        <th scope="col" class="text-center" v-for="(date, index) in dateArray" :key="index">
                            
                            <v-icon icon :color="colorWeek[date.day]"  v-if="date.day != 'domingo'">
                                mdi-google-maps
                            </v-icon>
                               
                            {{date.date | formatDate}}
                            <table style="min-width: 80px; margin:0 auto">
                                <tbody>
                                    <tr>
                                        <td style="width: 50%">P</td>
                                        <td style="width: 50%">R</td>
                                    </tr>
                                </tbody>
                            </table>                      
                        </th>                                       
                        </tr>
                        <tr class="resume__loader" v-if="loader">
                            <th colspan="16" class="resume_line"><v-progress-linear indeterminate color="indigo" ></v-progress-linear></th>
                        </tr>
                    </thead>
                    <tbody > 
                    <tr v-if="!loader && paginatedClientes.length === 0">
                        <td colspan="16"><i class="h5">Sin resultados</i></td>
                    </tr>                   
                    <tr class="resume__tbody--align" 
                        v-for="(item, index) in paginatedClientes" :key="index">
                            <td>{{ index + 1 }}</td>
                            <td :class="[( item.planner.length > 0 && item.planner[0].status == 0) ? 'line': '']">{{( item.planner.length > 0) ? item.planner[0].frequency : '-'}}</td>
                            <td :class="[( item.planner.length > 0 && item.planner[0].status == 0) ? 'line': '']">{{( item.planner.length > 0) ? item.planner[0].day : '-'}}</td>
                            <td>${{isNaN(item.FNETA) ? 0 : item.FNETA | formatCurrency}}</td>
                            <td>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon :color="(parseFloat(item.MPAGOSL) > 0) ? 'green' : 'red'"
                                            v-bind="attrs"
                                            v-on="on">
                                            mdi-currency-usd
                                        </v-icon>
                                    </template>
                                    <span>Monto Pagos ${{item.MPAGOS | formatCurrency}} - Monto pagos Línea ${{item.MPAGOSL | formatCurrency}}</span>
                                </v-tooltip>
                                
                            </td>
                            <td>                                
                                <p class="mb-0 d-flex align-items-center justify-content-center">
                                    <v-icon icon color="green">
                                        mdi-arrow-right-thin
                                    </v-icon>
                                    {{ formatCustomer(item.KUNNR) }}
                                </p>
                                <p class="mb-0">{{ item.BZIRK }}</p>
                                <p class="mb-0">{{ item.REGIO }}</p>
                            </td>
                            <td>
                                <p class="mb-0" :class="{'resume__porcent--green': item.PEDIDOS == 1}">{{item.NAME1}}</p>
                                <p class="mb-0 d-flex align-items-center justify-content-center" v-if="item.TELF1">
                                    <v-icon icon color="black" style="font-size:16px" class="mr-1">
                                        mdi-phone
                                    </v-icon>
                                    {{item.TELF1}}
                                </p>
                            </td>                                            
                            <td>
                                <presentacion-planeador 
                                    :item="item"
                                    :startDate="startDate" 
                                    :endDate="endDate"
                                    @confirmarPresentacion="(item) => $emit('confirmarPresentacion', item)"></presentacion-planeador>
                            </td>                                                     
                            <td>                                
                                <div class="d-flex justify-content-center">
                                    <productos-nuevos 
                                        :producto="item" 
                                        :index="index"></productos-nuevos>
                                </div>
                            </td>           
                            <td v-for="(date, index) in dateArray" :key="index">
                                <div class="d-flex align-items-center">
                                    <fila-semana
                                        :item="item" 
                                        :date="date" 
                                        :startDate="startDate" 
                                        :endDate="endDate" 
                                        @selectCustomerEdit="(item) => $emit('selectCustomerEdit', item)"
                                        @selectCustomerResume="(item) => $emit('selectCustomerResume', item)"
                                        @counter="counter"></fila-semana>
                                </div>                                
                            </td>
                        </tr>  
                    </tbody>
                    <tfoot class="resume__footer resume__footer--color">
                        <tr>
                            <td colspan="9">
                                <div class="text-left d-flex align-items-center">
                                    <p class="mb-0 mr-3"><b>Total de Pedidos del Día / Porcentaje de la Semana</b> </p>                               
                                    <div class="form-check mb-0 mr-3" style="min-height: 0">
                                        <input class="form-check-input" type="radio" value="monto" v-model="by" id="r_monto">
                                        <label class="form-check-label" for="r_monto">Por Monto</label>
                                    </div>

                                    <div class="form-check mb-0 mr-3" style="min-height: 0">
                                        <input class="form-check-input" type="radio" value="numero" v-model="by" id="r_numero">
                                        <label class="form-check-label" for="r_numero">Por Número de Pedido</label>
                                    </div>

                                    <p class="mb-0" v-if="by == 'monto'"><b>Total Sem: ${{sumaTotalSemana | formatCurrency}}</b></p>
                                    <p class="mb-0" v-else><b>Numero Pedido: {{sumaTotalPedidosSemana}}</b></p>
                                </div>                                
                            </td>
                            <td v-for="(date, index) in dateArray" :key="index">
                                <div v-if="by == 'monto'">
                                    <b v-if="date.day == 'domingo'">${{sumaTotalSemana | formatCurrencyMiles}} / <span class="resume__porcent--red">100%</span></b>
                                    <div v-else>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <span
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    ><b>${{date.monto | formatCurrencyMiles}}</b></span>                                        
                                            </template>
                                            <span>${{date.monto | formatCurrency}}</span>
                                        </v-tooltip> / <span class="resume__porcent--red"><b>{{porcentage(date.monto) | formatCurrency}}%</b></span>
                                    </div>
                                </div>
                                <div v-else>
                                    <b v-if="date.day == 'domingo'">{{sumaTotalPedidosSemana}} / <span class="resume__porcent--red">100%</span></b>
                                    <div v-else>
                                        <b>{{date.counter['TP']}}</b> /
                                        <span class="resume__porcent--red"><b>{{porcentajePedido(date.counter['TP'])}}%</b></span>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="9" class="text-left">                                
                                <p class="mb-0 mr-3"><b>Total de Visitas Programadas con Pedido / Visitas Programadas  / Total de pedidos</b> </p> 
                            </td>
                            <td v-for="(date, index) in dateArray" :key="index">
                                <span v-if="date.day == 'domingo'">{{sumaTotalVisitasProgramadasPedido}} / {{sumaTotalVisitasProgramadas}} / {{sumaTotalPedidosSemana}}</span>
                                <span v-else>{{date.counter['TVPP']}} / {{date.counter['VP']}} / {{date.counter['TP']}}</span>
                            </td>                            
                        </tr>

                        <tr>
                            <td colspan="9"></td>
                            <td scope="col" class="text-center" v-for="(date, index) in dateArray" :key="index">{{date.date | formatDate}}</td>  
                        </tr>
                    </tfoot>
                </template>
            </v-simple-table>

        </div>
        

        <table class="mt-5" >
            <thead>
                <th>
                    <td>Glosario:</td>
                </th>
            </thead>            
            <tbody>
                <tr class="text-left">
                    <td class="pr-3"><b>VEP</b> = Visita Efectiva Programada</td>
                    <td class="pr-3"><b>PFD</b> = Pedido Fuera de Día</td>
                </tr>
                <tr class="text-left">                                        
                    <td class="pr-3"><b>RN</b> = Revisión de Negocio</td>                        
                    <td class="pr-3"><b>PNC</b> = Pedido de productos Nuevos</td>                                               
                </tr> 
                <tr class="text-left">     
                    <td class="pr-3"><b>P</b> = Visita Programada</td>
                    <td class="pr-3"><b>R</b> = Pedido Realizado en Miles</td>
                </tr>               
            </tbody>
        </table>
    </div>
</template>

<script>
 /* eslint-disable */
import moment from "moment"
import TitlePlanner from "./TitlePlanner"
import FilaSemana from "./FilaSemana"
import PresentacionPlaneador from "./PresentacionPlaneador"
import ProductosNuevos from "./ProductosNuevos"
import { mapGetters } from "vuex";
// import axios from "@/plugins/axios";
export default {
    props: ['semana', 'clientes', 'loader', 'currentP'],
    components: {TitlePlanner, FilaSemana, PresentacionPlaneador, ProductosNuevos},
    data(){
        return {
            dateArray: [],               
            startDate: '',
            endDate: '',
            currentPage: this.currentP,
            itemsPerPage: 15,
            by: 'monto',
            filtroPNC: false,            
            colorWeek:{
                'lunes': 'yellow',
                'martes': 'red',
                'miércoles': 'purple',
                'jueves': 'green',
                'viernes': 'blue',
                'sábado': 'orange'
            },
            frequency: {
                'semanal': 1,
                'quincenal': 2
            }
        }
    },     
    computed: {
        ...mapGetters({
            authenticated: "auth/authenticated",
            user: 'auth/user'
        }),
        paginatedClientes() {
            return this.clientesFiltrados
        },
        clientesFiltrados() {    
            return this.clientes.filter(cliente => this.filtroPNC === false || cliente.PNC === this.filtroPNC);     
        },
        sumaTotalSemana(){
            let sumaTotal = 0
            this.dateArray.forEach(date => {
                sumaTotal += parseFloat(date.monto)
            })
            return sumaTotal
        },
        sumaTotalPedidosSemana(){
            let sumaTotal = 0
            this.dateArray.forEach(date => {
                sumaTotal += parseFloat(date.counter.TP)
            })
            return sumaTotal
        }, 
        sumaTotalVisitasProgramadas(){
            let sumaTotal = 0
            this.dateArray.forEach(date => {
                sumaTotal += parseFloat(date.counter.VP)
            })
            return sumaTotal
        },
        sumaTotalVisitasProgramadasPedido(){
            let sumaTotal = 0
            this.dateArray.forEach(date => {
                sumaTotal += parseFloat(date.counter.TVPP)
            })
            return sumaTotal
        }
    },
    watch: {
        semana: {
            immediate: true,
            handler(newVal) {
                this.startDate = newVal.inicio
                this.endDate = newVal.fin
                this.generateDateArray()
            }
        },
        currentP(newPage) {
            this.currentPage = newPage;
        },
        clientes: {
            immediate: true,
            handler(newVal) { 
                this.generateDateArray()               
                this.clientes.forEach(item => {                 
                      
                    if (item.planner.length > 0) {
                        let flagVisitDay = '';
                        item.planner.forEach((planner, index) => {
                            const plannerStartDate = this.formatDate(planner.start_date);
                            const plannerEntersRange = this.entraEnRango(planner);                                
                                
                            this.dateArray.forEach(date => { 
                                    if (plannerEntersRange && date.day === plannerStartDate && flagVisitDay !== planner.day) {                                                                                   
                                        this.$set(planner, 'VP', true);                                            
                                        this.counter('VP', date);
                                        flagVisitDay = planner.day;
                                    
                                        if(item.pedidos.length > 0){
                                            item.pedidos.forEach(pedido => {
                                                if (date.date == pedido.FECHA) {
                                                    //por cliente
                                                    this.$set(item, 'TVPP', true);
                                                    //por pedidos
                                                    this.$set(pedido, 'TVPP', true);
                                                    this.counter('TVPP', date);                                          
                                                }else{
                                                    //por cliente
                                                    this.$set(item, 'PFD', true);
                                                    //por pedidos
                                                    this.$set(pedido, 'PFD', true);
                                                }                                                    
                                            });
                                        }                                         
                                    }else{
                                        if(item.pedidos.length > 0){
                                            item.pedidos.forEach(pedido => {
                                                if (date.date != pedido.FECHA) {
                                                    //por cliente
                                                    this.$set(item, 'PFD', true);  
                                                    //por pedidos                                             
                                                    this.$set(pedido, 'PFD', true);                                               
                                                }
                                            });
                                        }
                                    }
                                });                                
                            
                        });
                    } 

                    this.countOrdersAndMounts(item);
                });


                //Deshabilitar paginación para asesores
                if(this.$hasAnyRole(['Seller'])){
                    this.itemsPerPage = this.clientes.length
                }
            }
        },
    },
    methods: {        
        generateDateArray() {
            let start = moment(this.startDate);
            let end = moment(this.endDate);
            let dateArray = [];
            while (start <= end) {
                let dateObj = {
                    date: start.format('YYYY-MM-DD'),
                    day: start.format('dddd'),
                    counter: {
                        VP: 0,
                        TP: 0,
                        TVPP: 0
                    },
                    monto: 0                                    
                };

                dateArray.push(dateObj);                

                start = start.add(1, 'day');
            }
            this.dateArray = dateArray;
            this.$emit('dateArray', dateArray)
        },         
        formatoFecha(value){
          return moment(value).format('YYYY-MM-DD')
        },
        formatoDiaSemana(value){
            return moment(value).format('dd')
        },   
        handlePageChange(page) {        
            this.$emit('currentPage', page);
        },
        counter(typeCounter, date){          
            const foundDate = this.dateArray.find(counter => counter.date === date.date)
            if (foundDate) {
                foundDate.counter[typeCounter]++;
            }
        },
        entraEnRango(planner) {  
            const fechaInicio = moment(planner.start_date)   
            const fechaFin = moment(planner.finish_date)  
            const fechaSemanaInicio = moment(this.startDate)  
            const fechaSemanaFin = moment(this.endDate)           
            
            if (planner.status === 0 && (this.evalFechaInicio(fechaInicio, fechaSemanaInicio, fechaSemanaFin) || this.evalFechaFin(fechaFin, fechaSemanaInicio, fechaSemanaFin))) {
                return false;
            } else if (this.evalFechaInicio(fechaInicio, fechaSemanaInicio, fechaSemanaFin)) {
                return false;
            }

            if(planner.frequency == 'mensual'){
                if(fechaInicio.isSameOrAfter(fechaSemanaInicio, 'day') && fechaInicio.isSameOrBefore(fechaSemanaFin, 'day')){
                    return true
                }
                return false
            }else{
                const diffWeeks = fechaSemanaFin.diff(fechaInicio, 'weeks');                
                return diffWeeks >= 0 && diffWeeks % this.frequency[planner.frequency] == 0;
            }
            
        },  
        evalFechaInicio(fechaInicio, fechaSemanaInicio, fechaSemanaFin){ 
            return fechaInicio.isAfter(fechaSemanaInicio) && fechaInicio.isAfter(fechaSemanaFin);
        },
        evalFechaFin(fechaFin, fechaSemanaInicio, fechaSemanaFin){            
            return fechaFin.isBefore(fechaSemanaInicio) && fechaFin.isBefore(fechaSemanaFin);
        },
        formatDate(date) {
            return moment(date).format('dddd');
        },
        // formatToday(date){
        //     return moment(date);
        // },
        porcentage(monto){
            let result = monto * 100
            if (result === 0) {
                return 0;
            } else {
                return result / this.sumaTotalSemana;
            }       
        },
        porcentajePedido(numPedido){
            let result = numPedido * 100
            if (result === 0) {
                return 0;
            } else {
                let total = result / this.sumaTotalPedidosSemana;
                return /^\d+$/.test(total) ? total.toString() : total.toFixed(2);
            }  
        },
        obtenerTrimestre() {
            const startDate = new Date(this.startDate);
            const endDate = new Date(this.endDate);

            let startYear = startDate.getUTCFullYear();
            // const endYear = endDate.getUTCFullYear();

            const startMonth = startDate.getUTCMonth() + 1;
            const endMonth = endDate.getUTCMonth() + 1;

            let startTrimestre = (Math.floor((startMonth - 1) / 3));
            let endTrimestre = (Math.floor((endMonth - 1) / 3));            

            if (startTrimestre === endTrimestre) {
                return (startTrimestre == 0) ? `4 / ${startYear - 1} ` : `${startTrimestre} / ${startYear}`                
            } else {
                return (startTrimestre == 0) ? `4-${endTrimestre} / ${startYear - 1} ` : `${startTrimestre }-${endTrimestre} / ${startYear}`
            }
        },
        formatCustomer(data){
            if (data.length >= 6) {
                // Utilizar el método slice para obtener los últimos 6 dígitos
                var ultimosSeisDigitos = data.slice(-6);
                return ultimosSeisDigitos;
            } else {
                // Si la cadena tiene menos de 6 caracteres, devolver la cadena original
                return data;
            }
        },
        countOrdersAndMounts(customer){
            if(customer.pedidos.length > 0){ 
                this.dateArray.forEach(date => { 
                    customer.pedidos.forEach(order => {  
                        if (date.date == order.FECHA) { 
                            this.$set(customer, 'TP', true);
                            // Suma de Pedidos
                            date.monto = parseFloat(date.monto) + parseFloat(order.monto);
                            // Número de Pedidos
                            order.detalles.forEach(() => this.counter('TP', date));
                        }   
                    });   
                });
            } 
        }
    }
}
</script>

<style>
    .resume__thead--color, .resume__footer--color{
        background: #f2f2f2
    }
    .line {
        text-decoration:line-through;
    }
    .resume_line{
        height: 0 !important;
        margin: 0 !important;
        padding: 0 !important;
        border-bottom: 0 !important ;
    }
    .span__1-red{
        color: red;
    }
    .table__content{
        margin-top:30px
    }
    .table__content td, .table__content th{
        padding:0 8px !important;
    }
    .resume__footer td{
        height: 30px !important;        
    }
    .resume__porcent--red{
        color: red;
    }

    .resume__porcent--green{
        color: #4caf50;
    }

    .table__content .v-data-table__wrapper {
        overflow-y: auto !important;
        position: relative !important;
    }

</style>






