<template>
  <input
    @keydown="checkNumber"
    @input="handleInput"
    class="form-control"
    :placeholder="placeholder"
    :value="formattedNumber"
  />
</template>

<script>
export default {
  name: "InputMoneyNumber",
  prop: ["value", "placeholder"],
  data() {
    return {
      content: this.value,
      placeholder: this.placeholder,
      formattedNumber: "",
    };
  },
  methods: {
    handleInput(e) {
      let value = this.removeMask(e.target.value);
      this.formattedNumber = Number(value).toLocaleString();
      this.$emit("input", value);
    },
    checkNumber(event) {
      if (
        ![
          "0",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "Backspace",
        ].includes(event.key)
      ) {
        return event.preventDefault();
      }
    },
    removeMask(str) {
      return str.replace(/\D+/g, "");
    },
  },
};
</script>
