<template>
  <v-simple-table class="mt-4 my-table" dense>
    <template v-slot:default>
      <thead class="table-heading">
        <tr class="th-auto">
          <th rowspan="2" class="text-center boder-x-none text-dark" v-for="(cell, index) in headers" :key="`head-${index}`">
            <b>{{ cell.text }}</b>
          </th>
        </tr>
        <tr class="custom-row2 th-auto" v-if="subheader">
          <th rowspan="2" class="text-center boder-x-none text-dark">
            <b>%&nbsp;de<br />clientes&nbsp;con <br />pago&nbsp;vía<br />IEL</b>
          </th>
        </tr>
      </thead>
      <tbody v-if="!loading" class="custom-white-table">
        <slot></slot>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="10">
            <v-progress-linear indeterminate color="cyan"></v-progress-linear>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
    name: "IELTable",
    props: {
      loading: {
        type: Boolean,
        default: false,
      },
      headers: {
        type: Array,
        default: () => []
      },
      subheader: {
        type: Array,
        default: null,
      },
    }
}
</script>