<template>
  <v-container fluid class="white pb-10 pt-7 px-5">
    <div class="d-flex align-center gap-2">
      <back-btn />
      <div class="outlined-card pa-4">
        <icon-title text="Pedido carretes" icon="mdi-bookmark-check"/>
      </div>
    </div>

    <blocked-by-backorders-alert v-if="userIsBlockedByBackorders" class="mt-5" />

    <div v-if="!orderCreated">
      <customer-order-information 
        :customer-data="customerData"
        :customer-channel="customerData?.CUSTOMER?.channel || ''"
        :past-due-balance="walletPastDueBalanceFormated"
        :current-order-status="orderStatus"
        :current-order-total="cartTotals.total"
        :available-credit="availableCreditFormated"
        :loading="loadingCustomerData"
        :error="customerDataError || loadCustomerError"
      />

      <v-card class="pa-5 my-5 card-shadow" rounded="0" v-if="isACreditCustomer">
        <tax-data-form 
          v-model="orderForm"
          :payment-methods="paymentValues.paymentMethod"
          :ways-to-pay="paymentValues.wayToPay"
          :cfdi-uses="paymentValues.cfdiUse"
        />
      </v-card>

      <v-card class="pa-5 my-5 card-shadow" rounded="0">
        <order-information-form 
          v-model="orderForm"
          :cfdi-relations="paymentValues.cfdiRelac"
          :address-list="shippingAddressList"
        />
      </v-card>

      <icon-title class="my-5 my-md-10" text="Catálogo de carretes"/>

      <v-row class="my-5">
        <v-col cols="12" v-if="reelErrorMessage.length > 0">
          <v-alert text color="warning"> {{ reelErrorMessage }}</v-alert>
        </v-col>

        <v-col cols="12" v-if="remindersErrorMessage.length > 0">
          <v-alert text color="warning"> {{ remindersErrorMessage }}</v-alert>
        </v-col>

        <v-col cols="12" md="6" align-self="end" order="2" order-md="2">
          <reels-table 
            v-model="reelsSelected"
            :reels="reels"
            :loading="loadingReels"
            :search="searchReel"
            :full-table="fullReelsTable"
            @item-selected="modifyCart"
            :noSelectable="loadingCustomerData || userIsBlockedByBackorders"
          />
        </v-col>
        
        <v-col cols="12" md="6" order="1" order-md="2">
          <search-input v-model="searchReel" placeholder="Buscar carrete"/>
          <div class="button-container">
            <v-btn @click="fullReelsTable = !fullReelsTable" class="card-shadow text-capitalize" dark color="#006cb1">{{ fullHeaderReelsTableTextBtn }}</v-btn>
          </div>
          <v-card v-if="reminders.length > 0" class="mt-5">
            <div class="text-left pa-2">Selecciona uno o más recordatorios para eliminar</div>
            <reel-reminders-table 
              v-model="remindersSelected"
              :reminders="reminders"
              :loading="loadingReminders"
              :noSelectable="cart.length <= 0"
            />
          </v-card>
        </v-col>
      </v-row>

      <div v-if="cart.length > 0" class="mt-10">
        <reel-order-cart-table 
          :cart-items="cart"
          :total="cartTotals.total"
          :subtotal="cartTotals.subtotal"
          :iva="cartTotals.iva"
          :hide-headers="hideHeaders"
          @onDeleteItem="item => modifyCart({ item: item.reel, value: false }, true)"
        />
       
        <!-- Alerts -->
        <div class="mt-10">
          <v-alert text color="error" v-if="cartMinimumTotalMessage.length > 0"> {{ cartMinimumTotalMessage }}</v-alert>
          <v-alert text color="primary" v-if="creditLimitExceededMessage.length > 0"> {{ creditLimitExceededMessage }}</v-alert>
          <v-alert text color="error" v-if="orderWillBeBlockedMessage.length > 0"> {{ orderWillBeBlockedMessage }}</v-alert>
          <v-alert text color="error" v-if="invalidOrderDataMessage.length > 0"> {{ invalidOrderDataMessage }}</v-alert>
        </div>

        <v-row class="mt-10" justify="end">
          <div class="button-container">
            <v-btn @click="storeOrder" class="card-shadow" dark color="#007D36">
              Confirmar pedido <v-icon color="white">mdi-checkbox-multiple-marked-circle</v-icon>
            </v-btn>
          </div>
        </v-row>
      </div>
    </div>
    
      <!-- Created order success message -->
    <div v-else>
      <v-card class="pa-5 my-10 card-shadow">
        <icon-title v-if="orderWillBeBlockedMessage.length > 0" :text="orderWillBeBlockedMessage" class="mb-5 text-left" />
        <icon-title :text="orderCreationResponse.message" />

        <div class="text-left">Número de pedido: {{ orderCreationResponse.orderNumber }}</div>
        <div v-if="orderCreationResponse.shippingNumber.length > 0" class="text-left mt-2">Número de entrega: {{ orderCreationResponse.shippingNumber }}</div>
        <div v-if="orderCreationResponse.billNumber.length > 0" class="text-left mt-2">Número de factura: {{ orderCreationResponse.billNumber }}</div>
        <v-row class="mt-15">
          <v-col cols="6">
            <v-btn block color="success" to="/">Ir a inicio</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn block color="primary" to="/history-orders">Ir a historial</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </div>

    <overlay :show="loadingMaterial" text="Validando información del carrete..."/>
    <overlay :show="savingOrder" text="Generando pedido..."/>
  </v-container>
</template>

<script>
  import TaxDataForm from '@/components/forms/TaxDataForm.vue';
  import OrderInformationForm from '@/components/forms/OrderInfoForm.vue'
  import ReelsTable from '@/components/tables/ReelsTable';
  import ReelRemindersTable from '@/components/tables/ReelRemindersTable';
  import ReelOrderCartTable from '@/components/tables/CartOrderTable';
  import SearchInput from '@/components/SearchInput.vue';
  import CustomerOrderInformation from '@/components/CustomerOrderInformation.vue';
  import IconTitle from '@/components/IconTitle.vue';
  import Overlay from '@/components/Overlay.vue';
  import BackBtn from '@/components/BackBtn.vue';
  import BlockedByBackordersAlert from '@/components/BlockedByBackordersAlert'

  import numbersFormats from '@/mixins/numbersFormats';
  import userBlockedByBackorders from '@/mixins/userBlockedByBackorders';

  import api from '@/plugins/axios';
  import { mapGetters, mapActions } from 'vuex';
  import { generatePurchaseOrderId } from '@/repositories/Orders';

  const finantialErrorsCountInit = { commercial: 0, manager: 0, gpom4: 0 }

  export default {
    name: 'CreateReelOrder',

    components: { 
      IconTitle, 
      TaxDataForm, 
      OrderInformationForm,
      ReelsTable,
      ReelRemindersTable,
      ReelOrderCartTable,
      SearchInput, 
      CustomerOrderInformation, 
      Overlay,
      BackBtn,
      BlockedByBackordersAlert
    },

    mixins: [numbersFormats, userBlockedByBackorders],

    data(){
      return {
        reelsSelected: [],
        reels: [],
        remindersSelected: [],
        reminders: [],

        loadingReels: false,
        loadingReminders: false,
        loadingMaterial: false,

        cart: [],
        fullReelsTable: false,
        searchReel: '',

        customerData: null,
        customerCreditData: null,
        loadingCustomerData: false,
        customerDataError: false,
        reelErrorMessage: '',
        remindersErrorMessage: '',

        paymentValues: {
          cfdiRelac: [],
          paymentMethod: [],
          wayToPay: [],
          cfdiUse: [],
        },

        orderForm: {
          orderConcept: '',
          shippingAddressId: '',
          paymentMethod: '',
          wayToPay: '',
          cfdiUse: '',
          documents: '',
          reBill: '',
        },
        shippingAddressList: [],
        invalidOrderDataMessage: '',

        finantialErrorsCount: { ...finantialErrorsCountInit },

        orderWillBeBlockedMessage: '',
        blockStatus: 1,
        orderCreated: false,
        savingOrder: false,
        orderCreationResponse: null,
      }
    },

    async created(){
      this.loadingCustomerData = true;

      this.fetchReels();
      this.orderForm.orderConcept = await generatePurchaseOrderId();

      await this.loadCustomer(); // Loads user data if the user is a seller it will load data of a preselected customer
      
      if (this.noCustomerSelected) // If seller is signed and no preselected customer, we redirect to home
        this.$router.replace('/');
      else if(!this.loadCustomerError) { // If a error happens while loading preselected customer
        this.validateUserBlockedByBackorders();
        this.fetchCustomerReminders();
        await this.fetchCustomerInfo();
      }

      this.sendToPDF({ active: true });
      this.loadingCustomerData = false;
    },

    methods: {
      ...mapActions('selectedUser', ['loadCustomer']),
      ...mapActions('printer', ['sendToPDF']),

      async fetchCustomerInfo(){
        try {
          const customerInfo = api.post('/get-customer-info', this.userData);
          const crediData = api.post('/get-customer-credit-data', this.userData);
          const addresses = api.post('/destinyCustomer', this.userData);

          const [{ data }, { data: creditData }, { data: customerDestinyList }] = await Promise.all([customerInfo, crediData, addresses]);
          
          this.customerData = data.customerData;
          this.paymentValues.cfdiRelac = data.cfdiRelac;
          this.paymentValues.paymentMethod = data.paymentMethods;
          this.paymentValues.wayToPay = data.waysToPay;
          this.paymentValues.cfdiUse = data.cfdiUses;
          this.customerCreditData = creditData;
          this.shippingAddressList = customerDestinyList;
        } catch (error) {
          this.customerDataError = true;
        }
      },

      async fetchReels(){
        this.loadingReels = true;

        try {
          const response = await api.get('/reel-list/');
          this.reels = this.formatReelsData(response.data);
        } catch (error) {
          this.reelErrorMessage = 'No se pudo obtener la lista de carretes';
        }

        this.loadingReels =  false;
      },

      async fetchCustomerReminders(){
        this.loadingReminders = true;

        try {
          const response = await api.post('/get-customer-reel-order-reminders', this.userData);
          this.reminders = response.data;
        } catch (error) {
          this.remindersErrorMessage = 'No se pudo obtener la lista de recordatorios';
        }

        this.loadingReminders =  false;
      },

      async modifyCart({ item, value }, fromSelectedList = false){ // FromSelectedList removes the reel table selection when the reel is removed from the cart
        const reelNumber = item.NCARR;
        
        try {
          if(value){
            this.loadingMaterial = true;
            const material = await this.fetchMaterial(item.MATNR, item.KWMENG, this.userData);
            this.cart.push({ ...material, reelNumber, reel: item })
            this.changeReelStatus(reelNumber, 'selected');

          } else {
            this.removeReelFromCart(reelNumber, fromSelectedList);
          }
        } catch (error) {
          this.changeReelStatus(reelNumber, 'error', false);
          this.reelErrorMessage = this.resolveHttpError(error, [400], 'Sucedió un error al recuperar la información del carrete');
        }
        
        this.loadingMaterial = false;
        this.checkOrderBlocks();
      },

      async fetchMaterial(materialId, requiredQty, userCreds){
        this.reelErrorMessage = '';
        const response = await api.post('/reel-material', { ...userCreds, materialId, requiredQty });
        return response.data;
      },

      async storeOrder(){
        this.invalidOrderDataMessage = '';
        const dataValidMessage = this.validateOrderData();
        const orderConceptGpom4 = this.finantialErrorsCount.gpom4 >= 1 ? '-BC' : '' ;

        if (dataValidMessage != null) {
          this.invalidOrderDataMessage = dataValidMessage;
        } else {
          const form = {
            ...this.userData,
            ...this.orderForm,
            orderConcept: `${this.orderForm.orderConcept}${orderConceptGpom4}`,
            cart: this.cart,
            blockStatus: this.blockStatus,
            newReelReminderList: [],
            removeReelReminderList: this.remindersSelected,
            createdBySeller: !this.loggedinUserIsCustomer,
            sellerCode: this.userCode,
          }

          try {
            this.savingOrder = true;
            const storeReelResponse = await api.post('/store-reel-order', form);
            this.orderCreationResponse = storeReelResponse.data;
            this.orderCreated = true;
            this.goToHistory();
          } catch ({ response }) {
            this.invalidOrderDataMessage = this.resolveHttpError(response, [400], 'No se pudo crear el pedido');
          }

          this.savingOrder = false;
        }
      },

      validateOrderData(){
        const invalidOrderCharacter = /[|]|[~]|[¬]|[']/.test(this.orderForm.orderConcept);

        if(invalidOrderCharacter){
          return 'Carácter inválido en orden de compra';
        }else if (this.orderForm.orderConcept == '' && this.orderForm.shippingAddressId == '') {
          return 'Favor de completar orden de compra y destino';
        } else if (this.orderForm.orderConcept == '') {
          return 'Favor de completar orden de compra';
        } else if (this.orderForm.shippingAddressId == '') {
          return 'Favor de completar destino';
        } else if (this.isACreditCustomer && this.orderForm.paymentMethod == '') {
          return 'Favor de completar metodo de pago';
        } else if (this.isACreditCustomer  && this.orderForm.wayToPay == '') {
          return 'Favor de completar via de pago';
        } else if (this.isACreditCustomer  && this.orderForm.cfdiUse == '') {
          return 'Favor de completar uso de CFDI';
        }

        return null;
      },

      removeReelFromCart(reelNumber, fromSelectedList = false){
        const index = this.cart.findIndex(reel => reel.reelNumber === reelNumber);
        index >= 0 && this.cart.splice(index, 1);

        if(fromSelectedList){ // Removes selection when reels is removed from cart table
          const indexSelected = this.reelsSelected.findIndex(reel => reel.NCARR === reelNumber);
          indexSelected >= 0 && this.reelsSelected.splice(indexSelected, 1);
        }
        this.changeReelStatus(reelNumber, 'unSelected');
      },

      checkOrderBlocks(){
        this.finantialErrorsCount = { ...finantialErrorsCountInit };
        this.orderWillBeBlockedMessage = '';

        this.cart.forEach(({ finantialErrors }) => {
          this.finantialErrorsCount.commercial += finantialErrors.commertial ? 1 : 0;
          this.finantialErrorsCount.manager += finantialErrors.manager ? 1 : 0;
          this.finantialErrorsCount.gpom4 += finantialErrors.gpom4 ? 1 : 0;
        })
        
        const { commercial, manager } = this.finantialErrorsCount;
        
        this.blockStatus = 1;

        if(this.noCreditProblems){
          if(commercial != 0 && manager == 0) {
            this.orderWillBeBlockedMessage = 'El pedido será bloqueado para autorización gerencia de planeación.';
            this.blockStatus = 3;
          } else if (commercial == 0 && manager != 0) {
            this.orderWillBeBlockedMessage  = 'El pedido será bloqueado para autorización de la gerencia.';
            this.blockStatus = 5;
          } else if (commercial != 0 && manager != 0) {
            this.blockStatus = 7;
            this.orderWillBeBlockedMessage  = 'El pedido será bloqueado para autorización de la gerencia y comercial.';
          }
        } else {
          if (manager == 0 && commercial == 0) {
            this.orderWillBeBlockedMessage = 'El pedido será bloqueado por crédito';
            this.blockStatus = 2;
          } else if (commercial != 0 && manager == 0) {
            this.orderWillBeBlockedMessage = 'El pedido será bloqueado para autorización comercial y crédito.';
            this.blockStatus = 4;
          } else if (commercial == 0 && manager != 0) {
            this.orderWillBeBlockedMessage = 'El pedido será bloqueado para autorización de la gerencia y crédito.';
            this.blockStatus = 6;
          } else if (commercial != 0 && manager != 0) {
            this.orderWillBeBlockedMessage = 'El pedido será bloqueado para autorización de la gerencia, comercial y crédito.';
            this.blockStatus = 8;
          }
        }
      },

      formatReelsData(reels){
        return reels.map(reel => ({
          ...reel,
          matnr: this.removeLeftZeros(reel.MATNR),
          kwmeng: this.formatNumber(reel.KWMENG, ''),
          POSNR: this.removeLeftZeros(reel.POSNR)
        }))
      },

      changeReelStatus(reelNumber, status, isSelectable = true){
        const reelIndex = this.reels.findIndex(reel => reel.NCARR === reelNumber);

        if(reelIndex >= 0){
          const reel = { ...this.reels[reelIndex] };
          reel.status = status;
          reel.isSelectable = isSelectable;
          this.reels.splice(reelIndex, 1, reel);
        }
      },

      resolveHttpError(error, statusCodes = [], defaultMessage = ''){
        const { response } = error;

        if(response && statusCodes.includes(response.status))
          return response.data.message;

        return defaultMessage;
      },

      goToHistory(){
        setTimeout(() => {
          this.$router.push('/history-orders')
        }, 5000);
      },
    },

    computed: {
      cartSubtotal() {
        return this.cart.reduce((sum, currentValue) => sum + parseFloat(currentValue.productPrice), 0);
      },

      cartTotalIva() {
        const iva = 0.16;
        return this.cartSubtotal * iva;
      },

      cartTotal() {
        return this.cartSubtotal + this.cartTotalIva;
      },

      cartTotals() {
        return { total : this.formatNumber(this.cartTotal), subtotal: this.formatNumber(this.cartSubtotal), iva: this.formatNumber(this.cartTotalIva)}
      },

      cartProfitMargin() { 
        return this.cart.reduce((sum, currentValue) => sum + parseFloat(currentValue.profitMargin), 0);
      },

      cartHasMinimumTotal() {
        return this.cartTotal >= 5000;
      },

      customerCredit(){
        const credit = this.customerData?.CREDD || '0';
        const creditNoCommas = credit.replace(/,/gi, '');

        return parseFloat(creditNoCommas);
      },

      isACreditCustomer(){
        const zterm = this.customerData?.ZTERM || '';
        return zterm == 'IU00'; 
      },

      availableCredit(){
        return this.customerCredit - this.cartTotal;
      },

      availableCreditFormated(){
        return this.formatNumber(this.availableCredit);
      },

      walletPastDueBalance(){
        return this.customerCreditData?.wallet?.T7 || null
      },

      pastDueBalanceCount(){
        return this.customerCreditData?.pastDueBalancesCount || 0;
      },

      walletPastDueBalanceFormated(){
        if(this.walletPastDueBalance === null) return '';

        return this.formatNumber(this.walletPastDueBalance);
      },

      noCreditProblems(){
        if(this.pastDueBalanceCount > 0 ||  this.customerCredit < 0 || this.availableCredit < 0){
          return false;
        }

        return true;
      },
      
      orderStatus(){
        if(this.walletPastDueBalance === null)
          return 'Análisis Crediticio 2'
        else if(this.availableCredit < 0 || this.walletPastDueBalance < 0)
          return 'Análisis Crediticio'
        else
          return 'Correcto'
      },

      // Alert Messages 
      cartMinimumTotalMessage(){
        if(this.cartTotal > 0 && !this.cartHasMinimumTotal)
          return 'El monto mínimo de compra es $ 5,000.00 MXP' // MXM ???

        return '';
      },

      creditLimitExceededMessage(){
        if(this.walletPastDueBalance > 0 || this.orderStatus != "Correcto"){
          return "Se ha excedido el límite de crédito y/o partidas vencidas. Se generará su pedido.";
        }

        return ''
      },

      fullHeaderReelsTableTextBtn(){
        return !this.fullReelsTable ? 'Tabla completa' : 'Reducir tabla'
      },

      userData(){
        return this.customer;
      },

      hideHeaders(){
        if(this.loggedinUserIsCustomer) return ['stockCdpt', 'stock', 'textBox'];

        return ['textBox'];
      },

      ...mapGetters('auth', ['channel', 'userCode', 'user']),
      ...mapGetters('selectedUser', ['customer', 'loadCustomerError', 'noCustomerSelected', 'loggedinUserIsCustomer']),
    },
  }
</script>

<style scoped>
.button-container {
  display: flex;
  justify-content: right;
}

.button-container > button {
  width: 400px;
  max-width: 400px;
  border-radius: 8px;
}

@media screen and (max-width: 960px) {
  .button-container > button {
    width: 100%;
    max-width: auto;
  }
}
</style>