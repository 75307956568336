<template>
  <v-navigation-drawer
    v-model="drawer"
    absolute
    temporary
    right
    @input="onInput"
    class="teal"
    dark
  >
    <v-row class="align-center" style="height: 100vh;" no-gutters>
      <div class="">
        <v-img
          src="img/propuesta-1-x2.svg"
        ></v-img>
        <h4 class="w-100 text-white">Has sido invitado a un evento:</h4>
        <p class="w-100 text-white">Titulo: {{ notification.data.title }}</p>
        <p class="w-100 text-white">Tipo: {{ notification.data.typeDiary.type }}</p>
        <p class="w-100 text-white">Fecha: {{ notification.data.start_date }}</p>
        <p class="w-100 text-white">Te invitó: {{ notification.data.user_id }}</p>
        <v-btn class="mr-2" @click="close"> Rechazar </v-btn>
        <v-btn color="primary" @click="addEvent"> Aceptar </v-btn>
      </div>
    </v-row>
  </v-navigation-drawer>
</template>

<script>
import Vue from "vue";
import { mapMutations, mapActions } from "vuex";
import { eventBus } from "@/main.js";
import generalMixin from "@/mixins/general";

export default {
  name: "RightNavDrawer",

  mixins: [generalMixin],

  data() {
    return {
      drawer: false,
      notification: { data: { typeDiary: {} }},
    };
  },

  mounted() {
    eventBus.$on("RightNavDrawer::openDrawer", (data) => {
      this.drawer = true;
      console.log(JSON.stringify(data));
      this.notification = data;
    });
    eventBus.$on("clearData", () => {
    });
  },

  methods: {
    ...mapMutations({
      addNewNotification: "auth/ADD_NEW_NOTIFICATION",
    }),
    ...mapActions({
      acceptEvent: "diary/acceptEvent",
    }),
    onInput(data) {
      if (data == false) {
        this.drawer = false;
      }
    },
    close() {
        this.drawer = false;
        console.log('Close drawer:', this.notification);
        this.addNewNotification(this.notification);
        Vue.nextTick(() => {
          this.data = { typeDiary: {} };
        })
    },
    addEvent() {
      console.log(this.notification);
        this.acceptEvent(this.notification.data);
        this.close();
    }
  },
};
</script>

<style lang="scss" scoped></style>
