<template>
  <v-row>
		<h4 class="text-left text-h6">{{ title }}</h4>

    <v-col cols="12" md="8" class="pr-4">
      <v-row dense align="center">
        <v-col cols="12" sm="2" class="">
          <div class="text-left text-md-right font-weight-bold">
            <label for="cfdi-relac">*Orden de compra:</label>
          </div>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="cfdiForm.orderConcept"
            name="order-concept"
            autocomplete="off"
            hide-details
            dense
            solo
            class="card-shadow rounded-lg"
          />
        </v-col>
        <v-col cols="12" sm="2">
          <div class="text-left text-md-right font-weight-bold">
            <label for="cfdi-relac">CFDI Relacionado: </label>
          </div>
        </v-col>
        <v-col cols="12" sm="4">
          <v-select
            v-model="cfdiForm.reBill"
            :items="cfdiRelations"
            name="cfdi-relac"
            item-value="id"
            item-text="des"
            hide-details
            solo
            dense
            class="card-shadow rounded-lg"
          />
        </v-col>
        <v-col cols="12" sm="2">
          <div class="text-left text-md-right font-weight-bold">
            <label for="documents">Documento relacionado:</label>
          </div>
        </v-col>
        <v-col cols="12" sm="10 mt-2">
          <v-textarea
            v-model="cfdiForm.documents"
            class="card-shadow rounded-lg"
            name="documents"
            rows="2"
            hide-details
            solo
          />
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="4">
      <div class="text-left font-weight-bold mb-2">
        <label for="cfdi-relac">*Destino mercancía:</label>
      </div>
      <v-select
        v-model="cfdiForm.shippingAddressId"
        name="ship-address"
        :item-value="addresItemValue"
        :item-text="addressItemText"
        :items="addressListFormated"
        :height="87"
        hide-details
        solo
        class="card-shadow rounded-lg"
				no-data-text="Sin destinos"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "OrderInformationForm",

  props: {
    value: {
      required: true,
    },

    title: {
      type: String,
      default: 'Información del pedido'
    },

    cfdiRelations: {
      type: Array,
      required: true,
    },

    addressList: {
			type: Array,
			default: () => [],
		},

		addresItemValue: {
			type: String,
			default: 'KUNNR'
		},

		addressItemText: {
			type: String,
			default: 'text'
		},

		noFormatItemText: Boolean
  },

  computed: {
    cfdiForm: {
      set(newValue) {
        this.$emit("input", this.value, ...newValue);
      },
      get() {
        return this.value;
      },
    },

    addressListFormated() {
			return !this.noFormatItemText ? this.addressList.map(d => ({ ...d, text: `${d.KUNNR} ${d.STRAS} ${d.ORT01} ${d.PSTLZ} `})) : this.addressList;
		}
  },
};
</script>
<style scoped>
.input-container {
  width: 240px;
  text-align: right;
}

::v-deep .v-select__selection--comma {
  white-space: wrap;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>