<template>
    <div>
        <v-row class="mt-0 ml-3 mr-3" >
        <v-col cols="12" class="pb-0 pt-0">
          <div class="d-flex justify-space-between pb-2 pt-2 pr-1" style="box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;">
            <div class="pl-3 pr-3 pt-1 pb-1 d-flex flex-wrap">
              <div class="d-flex flex-wrap mb-n6">
                <label class="pt-2 mr-2 ml-2">Zona:</label>
                <v-text-field solo dense ></v-text-field>
              </div>
              <div class="d-flex flex-wrap mb-n6">
                <label class="pt-2 mr-2 ml-2">Cliente:</label>
                <v-text-field solo dense ></v-text-field>
              </div>
             <div class="pt-1 ml-2 ">
              <v-btn class="text-capitalize custom-red-button " color="error" dark small rounded>  Consultar</v-btn>
             </div>
            </div>
            <div class="d-flex flex-wrap">
              <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon  v-bind="attrs" v-on="on" color="#C82333"  class="mr-5" size="40">mdi-notebook-edit</v-icon>
              </template>
              <span>Directorio</span>
            </v-tooltip> 
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon  v-bind="attrs" v-on="on" color="black"  class="mr-5" size="40">mdi-notebook</v-icon>
              </template>
              <span>Agenda</span>
            </v-tooltip> 
            </div> 
            </div>
        </v-col>
        <v-col cols="12 pb-0 pt-0 mt-1">  
              <net-sales  
              :chnl="chnl" 
              :regionalManager="regionalManager" />
        </v-col>
        <v-col  cols="12" class="pt-0 mb-0 "> 
              <orders 
              :chnl="chnl" 
              :regionalManager="regionalManager" />
        </v-col> 
        <v-col cols="12" md="12" class="mt-0 pa-0 ml-n1">
          <manager-list 
          :chnl="chnl"  
          :regionalManager="regionalManager" 
          @groupByChange="changeGroupBy"
          :groupByOptions="groupByOptions"
        />
        </v-col>
        <v-col cols="12" md="12" class="mt-0 pa-0 pl-3 pr-2">
          <month-end 
          :chnl="chnl"
          :regionalManager="regionalManager"  />
        </v-col>
      </v-row>
    </div>
</template>

<script> 
import NetSales from './NetSales.vue'
import Orders from './Orders.vue'
import ManagerList from './ManagerList.vue' 
import MonthEnd from './MonthEnd.vue';

export default {
  name: "DirectorHome",
  components: { 
    NetSales,
    Orders,
    ManagerList,   
    MonthEnd,
  }, 
  props:{
    groupByOptions:{
      required:true,
    },
    chnl:{
      required:true,
      String
    },
    regionalManager:{
      required:true,
    }
    },  
    data: () => ({ 
      }),
  methods: {  
    changeGroupBy(data){
      this.$emit('groupByChange',data)   
    },   
  },

};
</script>
<style>
.custom-red-button {
  background: transparent linear-gradient(180deg, #A82222 0%, #FF0000 100%) 0% 0% no-repeat padding-box;
  opacity: 1; 
}
</style>