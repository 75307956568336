var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.authenticated && _vm.user.VTWEG != null)?_c('div',[_c('v-toolbar',{staticClass:"breadcrumbroles--path",class:_vm.breadcrumColor,attrs:{"dense":"","elevation":"5"}},[_c('div',{staticClass:"d-md-flex align-items-center justify-content-between",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"container-breadcrumb-desk"},[_c('v-breadcrumbs',{staticClass:"custom-breadcrumb",attrs:{"divider":">","dark":"","items":_vm.breadcrumbsItems},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [(item.nameTooltip == null)?_c('v-breadcrumbs-item',{attrs:{"href":item.href,"disabled":item.disabled},on:{"click":function($event){return _vm.saveCodeStorage(item.number_code, item.typeStorage)}}},[_c('span',{class:(!item.disabled ? 'yellow--text' : ' text-white ') +
                  ' small'},[_vm._v(" "+_vm._s(item.text)+" "+_vm._s(_vm.getDateBreadcrumb(item)))])]):_c('v-breadcrumbs-item',{attrs:{"href":item.href},on:{"click":function($event){_vm.saveCodeStorage(item.number_code, item.typeStorage);
                _vm.handleClick(item);}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({class:(!item.disabled ? 'yellow--text' : ' text-white ') + ' small'},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.nameTooltip)+" "+_vm._s(_vm.getDateBreadcrumb(item))+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(item.text)+" ")])],1)]}}],null,false,3566896786)})],1),_c('div',{staticClass:"container-breadcrumb-mobile",staticStyle:{"position":"relative"}},[_c('v-breadcrumbs',{staticClass:"d-flex justify-content-center",attrs:{"dark":"","items":_vm.lastBreadcrumb},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('v-breadcrumbs-item',[_c('v-breadcrumbs-item',{attrs:{"href":item.href,"disabled":item.disabled},on:{"click":function($event){return _vm.saveCodeStorage(item.number_code, item.typeStorage)}}},[_c('span',{class:(!item.disabled ? 'yellow--text' : ' text-white ') +
                    ' small'},[_vm._v(" "+_vm._s(item.text)+" "+_vm._s(_vm.getDateBreadcrumb(item))+" ")])]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"white","size":"24"}},'v-icon',attrs,false),on),[_vm._v(" mdi mdi-menu-down ")])]}}],null,true)},[_c('v-list',{staticClass:"navBar__dropdown"},_vm._l((_vm.breadcrumbsWithoutLast),function(submenu,index){return _c('v-list-item-title',{key:index,staticClass:"subMenu--title",class:{
                      [_vm.validateColor()]: !submenu.disabled,
                      pointer: !submenu.disabled,
                    },on:{"click":function($event){return _vm.$router.push(submenu.href)}}},[_vm._v(" < "+_vm._s(submenu.text)+" > ")])}),1)],1)],1)]}}],null,false,2443319952)})],1),_c('SelectMenuBreadcrumb')],1)])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }