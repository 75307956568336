
<template>
    <v-container fluid class="grey lighten-5">
      <div class="text-center ma-2">
                <v-snackbar v-model="errormsg" :timeout="timeout">
                  Cerciorase que haya buena conexión a internet. Re intente de nuevo.
                  <template v-slot:action="{ attrs }">
                    <v-btn
                      color="red"
                      text
                      v-bind="attrs"
                      @click="errormsg = false"
                    >
                      Close
                    </v-btn>
                  </template>
                </v-snackbar>
              </div>
      <v-card
        class="mx-auto rounded-lg elevation-4 ma-3 ml-5 mr-5 mb-7"
        outlined
        :style="'border: 4px solid black; background-color:transparent;'"
      >
        <v-list-item-content>
          <div class="text-general-module">
            <v-icon color="black">mdi-cart</v-icon>
            Crear Pedido de Reorden 
          </div>
        </v-list-item-content>
      </v-card>
      <v-card>
        <customer-info
          :catchTotal="total"
          :warnLock="catchRequestLock"
          :purValidation="catchpurValidation"
          :catchLimit="catchPercent"
          :catchAviableCredit="catchAviable"
          :catchErrorConnection="catchConnection"
          :capturedCart="cart.length"
          :capturedDocType="doctype"
          :catchYearValidation="catchYearV"
          :catchPPDP="catchPPDP"
          :catchPSDR="catchPSDR"
          :catchRTRIMA="catchRTRIMA"
          :catchCreditDays="catchCreditDays"
          :catchRSR6M="catchRSR6M"
          :catchPSNW="catchPSNW"
          :catchPSMA="catchPSMA"
          :catchPSPO="catchPSPO"
        ></customer-info>
        <p></p>
        <div class="col-12" style="text-align: left">
          <span class="text-ped">Captura de Pedido </span>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-md-4">
              <div class="input-group">
                <v-text-field
                  class="text-ped"
                  v-model="order"
                  label="Ingrese Orden de Compra"
                  required
                ></v-text-field>
              </div>
              <div>
                <v-autocomplete 
                   v-model="orderType" 
                   :items="orderList" 
                   item-text="text" 
                   item-value="value"
                   return-object dense
                   label="Tipo de Pedido"
                   rounded
                   color="#212529"
                   class=".v-select__selection"
                   style="background-color: red !important; font-size: 18px !important; font-weight: bold !important;"
                   >
                </v-autocomplete>
              </div>
            </div>
            <div class="col-md-6">
              <customer-destiny :warnDestiny="cacthDestiny" :catchErrorConnection="catchConnection"></customer-destiny>
            </div>
            <div class="col-md-2"></div>
            <div class="col-md-3">
              <h2
                class="text-ped"
                style="text-align: center"
                @click="dialogCatalogo = true"
              >
                Mas de 4,200 productos
              </h2>
            </div>
            <div class="col-md-3">
              <v-switch>
                <template v-slot:label>
                  <p class="text-ped">Pedido Programado </p>
                </template>
              </v-switch>
            </div>
          </div>
        </div>
        <div class="col-12" style="text-align: end; font-size: 12px">
          <a @click="dialogPromociones = true">
            <v-icon class="text-ped-links">mdi-bookmark-check</v-icon>Promociones
          </a>
          <a @click="dialogLiquidaciones = true">
            <v-icon class="text-ped-links">mdi-bookmark-check</v-icon
            >Liquidaciones
          </a>
          <a @click="dialogPedidos = true">
            <v-icon class="text-ped-links">mdi-book-open-variant</v-icon>Mis
            pedidos
          </a>
          <!--<a>
            <v-icon class="text-ped-links">mdi-bookmark-check</v-icon>Instrucciones de captura
          </a>
          <a>
            <v-icon class="text-ped-links">mdi-bookmark-plus</v-icon>Información del pedido
          </a>-->
        </div>
        <div class="card-body" ref="errorMaterial">
          <div class="row">
            <div class="col-md-12" v-if="hasError">
              <v-alert text dense outlined type="error">
                {{ hasError }}
              </v-alert>
            </div>
            <div v-if="order_psdr_v" class="col-md-8">
              <material-into-cart
                :warnMaterial="catchMaterialItem"
                :warnError="catchErrorMaterial"
                :warnDialogLoading="catchDialogLoading"
                :newMaterial="sendMaterialNew"
                :capturedDocType="doctype"
                :catchErrorConnection="catchConnection"
              ></material-into-cart>
            </div>
            <div class="col-md-4"></div>
          </div>
        </div>
        <br />
        <list-cart  v-if="order_psdr_v"
          :capturedMaterialItem="materialItem"
          :warnTotal="catchTotal"
          :warnError="catchErrorMaterial"
          :warnDialogLoading="catchDialogLoading"
          :warnCart="catchCart"
          :capturedIdOrder="order_id"
          :capturedDocType="doctype"
          :catchErrorConnection="catchConnection"
        ></list-cart>
        <v-row v-if="cart.length != 0" class="justify-end">
           <v-col md="2" class="align-self-end">
            <label
              >Subtotal <strong>$ {{ formatPrice(subtotalPrint)}}</strong></label
            ><br />
            <label
              >IVA 16% <strong>$ {{ formatPrice(iva) }}</strong></label
            ><br />
            <label
              >Total
              <v-card-title style="color: #000; font-weight: 800"
                >$ {{ formatPrice(total) }}
              </v-card-title></label
            ><br />
            <v-btn
              class="ma-1 elevation-1"
              rounded
              :disabled="isButtonDisabled"
              @click="checkBackOrder"
              color="success"
            >
              <span style="font-size: 12px;" v-if="isButtonDisabled"><v-icon>mdi-cancel</v-icon>Compra Bloqueada</span>
              <span v-else><v-icon>mdi-check</v-icon>Comprar
              Ahora</span>
            </v-btn>
          </v-col>
        </v-row>
        <br />
        <div class="col-12" style="text-align: initial; font-size: 12px">
          <a>
            <v-icon class="text-ped-links">mdi-bookmark-check</v-icon>Cargar
            archivo
          </a>
          <!--<a>
            <v-icon class="text-ped-links">mdi-bookmark-check</v-icon>Convertir a pedido apoyo a
            marca
          </a>
          <a>
            <v-icon class="text-ped-links">mdi-book-open-variant</v-icon>Convertir a pedido
            especial
          </a>
          <a>
            <v-icon class="text-ped-links">mdi-bookmark-check</v-icon>Convertir a Pedido de
            Tienda Nueva
          </a>-->
          <a>
            <v-icon class="text-ped-links">mdi-bookmark-plus</v-icon>Datos
            Generales
          </a>
         <!--<a
          >
            <v-icon class="text-ped-links">mdi-bookmark-check</v-icon>Generar
            Cotizacion
          </a>-->
        </div>
        <loading-bar :capturedDialogLoading="dialogLoading"></loading-bar>
      </v-card>
      <br />
      <v-row>
        <v-col sm="12">
          <!--<div class="col-12" style="text-align: left">
            <span class="text-ped">La mejor sugerencia</span> / en base a tus
            compras.
          </div>
          <v-slide-group active-class="success" show-arrows>
            <v-slide-item
              v-for="(item, i) in items_product"
              :key="i"
              style="margin: 10px !important"
            >
              <v-card
                class="mx-auto pedidos-card-sugerencias"
                max-width="280"
                style="text-align: left"
              >
                <v-card-text>
                  <strong>{{ item.name_product }}</strong>
                </v-card-text>
                <v-card-subtitle> SKU: {{ item.sku }} </v-card-subtitle>
                <v-img :src="item.src" width="180px"></v-img>
                <v-card-subtitle style="text-align: center">
                  Empaque de 1 pza
                </v-card-subtitle>
                <v-card-title>
                  <span style="text-align: center"> A {{ item.price }}</span>
                </v-card-title>
                <v-card-text>
                  <v-btn
                    color="primary"
                    rounded
                    block
                    style="background: #079FFF color: #fff !important"
                  >
                    <v-icon style="color: #fff !important">mdi-plus</v-icon
                    >Agregar al Carrito
                  </v-btn>
                </v-card-text>
                <v-card-text>
                  <v-btn
                    style="background: #2fd410 !important; color: #fff !important"
                    rounded
                    block
                  >
                    <v-icon style="color: #fff !important">mdi-check</v-icon
                    >Comprar Ahora
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-slide-item>
          </v-slide-group>-->
        </v-col>
      </v-row>
      <v-dialog v-model="dialogBackOrder" persistent scrollable>
        <v-card>
          <v-card-title>Confirmar BackOrder</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
          <p><b>Seleccionar los backorders confirmados</b></p>
          <v-row v-for="(item, i) in cart" :key="i">
            <v-checkbox v-model="item.reminder_check">
              <template v-slot:label>
                <div>
                  {{ item.codigo_material }}-{{ item.nombre_material }}
                  <strong>Backorder: {{ item.recordatorios }}</strong>
                </div>
              </template>
            </v-checkbox>
          </v-row>
        </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <!--<button class="btn btn-danger" @click="dialogBackOrder = false">
               Cancelar
             </button>
             <button class="btn btn-success" @click="orderGenerate">
               Aceptar junto con Pedido
             </button>-->
             
             <v-btn
              color="error"
              @click="dialogBackOrder = false"
            >
              Cancelar 
            </v-btn>
           
            <v-btn
              color="success"
              @click="orderGenerate"
              :disabled="isButtonDisabled"
            >
              Enviar Pedido
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Dialog loading -->
     <!--<v-row justify="center">
        <v-dialog
          v-model="dialogPromociones"
          hide-overlay
          width="600"
          transition="dialog-bottom-transition"
          justify="center"
        >
          <v-card justify="center">
            <div class="text-ped" style="text-align: center">PROMOCIONES</div>
            <div class="col-12 row">
              <table class="table">
                <thead>
                  <tr
                    style="font-size: 10px; background-color: rgb(238, 238, 238)"
                  >
                    <th colspan="2">Producto</th>
                    <th>Distribuidor</th>
                    <th>Promoción</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, i) in items_product"
                    :key="i"
                    style="font-size: 12px"
                  >
                    <td>
                      <v-img :src="item.src" width="50" class="imageCarItem" />
                    </td>
                    <td style="text-align: start">
                      {{ item.sku }}-{{ item.name_product }}
                    </td>
                    <td>
                      {{ item.price }}
                    </td>
                    <td>
                      {{ item.less }}
                    </td>
                    <td>
                      <v-btn
                        color="primary"
                        rounded
                        block
                        style="background: #079FFF color: #fff !important"
                      >
                        <v-icon style="color: #fff !important">mdi-cart</v-icon
                        >Add
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <v-card-actions class="justify-end">
              <v-btn text @click="dialogPromociones = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>-->
      <!-- /. Dialog -->
      <!-- Dialog loading -->
     <!--<v-row justify="center">
        <v-dialog
          v-model="dialogLiquidaciones"
          hide-overlay
          width="600"
          transition="dialog-bottom-transition"
          justify="center"
        >
          <v-card justify="center">
            <div class="text-ped" style="text-align: center">LIQUIDACIONES</div>
            <div class="col-12 row">
              <table class="table">
                <thead>
                  <tr
                    style="font-size: 10px; background-color: rgb(238, 238, 238)"
                  >
                    <th colspan="2">Producto</th>
                    <th>Distribuidor</th>
                    <th>Liquidación</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, i) in items_product"
                    :key="i"
                    style="font-size: 12px"
                  >
                    <td>
                      <v-img :src="item.src" width="50" class="imageCarItem" />
                    </td>
                    <td style="text-align: start">
                      {{ item.sku }}-{{ item.name_product }}
                    </td>
                    <td>
                      {{ item.price }}
                    </td>
                    <td>
                      {{ item.less }}
                    </td>
                    <td>
                      <v-btn
                        color="primary"
                        rounded
                        block
                        style="background: #079FFF color: #fff !important"
                      >
                        <v-icon style="color: #fff !important">mdi-cart</v-icon
                        >Add
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <v-card-actions class="justify-end">
              <v-btn text @click="dialogLiquidaciones = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>-->
      <!-- /. Dialog -->
      <!-- Dialog Catalogo -->
      <v-row justify="center">
        <v-dialog
          v-model="dialogCatalogo"
          hide-overlay
          width="800"
          transition="dialog-bottom-transition"
          justify="center"
        >
          <v-card justify="center">
            <div class="text-ped" style="text-align: center">CATALOGO</div>
            <div class="col-12 row">
              <v-text-field
                v-model="material_code_search"
                label="Buscar nombre / SKU"
                append-icon="mdi-magnify"
                style="padding: 15px"
              ></v-text-field>
              <v-data-table
                :headers="headersCatalogo"
                :items="catalogo_list"
                :items-per-page="5"
                :search="material_code_search"
                class="elevation-1"
              >
                <template v-slot:[`item.MATNR`]="{ item }">
                  {{ parseInt(item.MATNR) }}
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-btn
                    color="primary"
                    rounded
                    block
                    @click="newMaterialSearch(parseInt(item.MATNR))"
                    style="background: #079FFF color: #fff !important"
                  >
                    <v-icon style="color: #fff !important">mdi-cart</v-icon>Add
                  </v-btn>
                </template>
              </v-data-table>
            </div>
            <v-card-actions class="justify-end">
              <v-btn text @click="dialogCatalogo = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <!-- /. Dialog -->
      <!-- Dialog Pedidos -->
      <v-row justify="center">
        <v-dialog
          v-model="dialogPedidos"
          hide-overlay
          width="800"
          transition="dialog-bottom-transition"
          justify="center"
        >
          <v-card justify="center">
            <div class="text-ped" style="text-align: center">Ordenes de Compra Abiertas</div>
            <!--<div class="text-ped" style="text-align: initial">
              <v-btn
                style="margin-left: 10px"
                dark
                rounded
                color="primary"
                elevation="2"
                small
                @click="generateNewOrder()"
              >
                <v-icon style="color: #fff !important">mdi-plus</v-icon> Crear
                Nuevo Pedido
              </v-btn>
            </div>-->
  
            <div class="col-12 row">
              <v-data-table
                :headers="headersPedido"
                :items="orders_list"
                :items-per-page="5"
                class="elevation-1"
              >
                <template v-slot:[`item.name`]="{ item }">
                  <!--<a @click="loadOrder(item)">{{ item.name }}</a>-->
                  <p>{{ item.name }}</p>
                </template>
                <template v-slot:[`item.total`]="{ item }">
                  {{ formatPrice(item.total) }}
                </template>
                <template v-slot:[`item.type`]="{ item }">
                  <p v-if="item.type == 'CL'">Cliente</p>
                  <p v-if="item.type == 'VE'">Vendedor</p>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-btn
                    style="margin-left: 10px"
                    dark
                    rounded
                    color="red darken-2"
                    elevation="2"
                    small
                    @click="deleteOrder(item)"
                  >
                    <v-icon style="color: #fff !important">mdi-delete</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </div>
            <v-card-actions class="justify-end">
              <v-btn text @click="dialogPedidos = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <!-- /. Dialog -->
    </v-container>
  </template>
  
<script>
import axios from "@/plugins/axios";
import LoadingBar from "./componets/LoadingBar.vue";
import CustomerInfo from "./componets/CustomerInfo.vue";
import MaterialIntoCart from "./componets/MaterialIntoCart.vue";
import ListCart from "./componets/ListCart.vue";
import CustomerDestiny from "./componets/CustomerDestiny.vue";
import { mapGetters,mapMutations, mapActions  } from "vuex";
import moment from 'moment';
export default {
  name: "ShoppingCart",
  computed: {
    ...mapGetters({
      user: "auth/user",
      cartIte: "ordercart/cart",
    }),
    isButtonDisabled(){
      //total <= 5000 total < 2500
      if(this.cart.length < 10 ||  this.submitted || this.total <= 2500){
        if(this.cart.some((item) => item.recordatorios == 0)){ 
          // revisa si hay un material no es de recordatorio con la variable remider_check, aparece en false
          console.log("hay articulos sin recordatorios");
          console.log(this.cart.some((item) => item.recordatorios == 0));
          if(this.total <= 2500){
            return true;
          }else{
            console.log("solo hay recordatorios");
            return false;
          }
        }
      
      }
      return false;
    }
  },
  components: {
    CustomerInfo,
    MaterialIntoCart,
    ListCart,
    LoadingBar,
    CustomerDestiny,
  },
  data: () => ({
    material_code_search: "",
    submitted: false,
    hasError: null,
    materialItem: {},
    subtotal: 0,
    subtotalPrint:0,
    iva: 0,
    total: 0,
    total2: "",
    porcentaje:0,
    aviable:0,
    dialogLoading: false,
    customerDestiny: {},
    order: "",
    cart: [],
    requestLock: 0,
    dialogBackOrder: false,
    order_response: "",
    dialogPromociones: false,
    dialogLiquidaciones: false,
    dialogCatalogo: false,
    sendMaterialNew: "",
    dialogPedidos: false,
    order_id: null,
    orderItemsList:[],
    orderType:'PSDR',
    errormsg:false,
    timeout: 8000,
    doctype: null,
    year_validation: 0,
    ppdp_val: 0,
    psdr_val: null,
    rtrima_val: null,
    order_psdr_v: true,
    days_credit: null,
    ppr_val: null,
    psnw_val: null,
    psma_val: null,
    pspo_val: null,
    purchase_validation: null,
    order_purchase: null,
    headersCatalogo: [
      {
        text: "Material",
        align: "start",
        sortable: false,
        value: "MATNR",
      },
      { text: "Descripción", value: "MAKTX" },
      { text: "actions", value: "actions" },
    ],
    catalogo_list: [],
    headersPedido: [
      { text: "ID", value: "id" },
      {
        text: "Orden de compra",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "Num. Partidas", value: "materials" },
      { text: "Importe Total", value: "total" },
      { text: "Usuario Creador", value: "type" },
      { text: "", value: "actions" },
    ],
    orders_list: [],
    orderList:[
      {text:'Pedido de Reorden', value:'PSDR'}
    ],
    today2: moment().format('YYYY/MM/DD'), 
    itemCSV:[],
  }),
  async mounted() {
    this.generarPDF();
    this.getOrderID();
    this.checkOrdersHistory();
    this.getCatalogo();
    //this.orderTypeItemsData();
     //year_validation >= 12 || ppdp_val >= 38 || psdr_val == 0

    //this.getCatalogoRandom();
  },
  methods: {
    ...mapActions({
        sendToPDF: 'printer/sendToPDF',
        sendToExcel: 'printer/sendToExcel',
    }),
    ...mapMutations({
      setCard: "ordercart/SET_CART",
    }),
    generarPDF(){
            this.sendToPDF({active: true});
    },
    generarExcel(){
      let newItems = []
      this.itemCSV = []

      for (let key in this.cart) {                
                let item = this.cart[key];
                newItems.push({
                        'Producto': item.codigo_material ,
                        'Nombre': item.nombre_material,
                        'Genetica': item.material_type,
                        'Cantidad': item.u_confirm,
                        'Empaque': item.empaque,
                        'Unidad de Medida.':item.unidad_medida,
                        'Pedido Mensual.': item.PMCOM,
                        'Dias solicitados.': item.DSINV,
                        'Precio de lista': item.precio_lista,
                        'Precio C/ descuento':item.precio_con_descuento,
                        'Importe Total':item.importe_producto,
                    });
              }
      this.itemCSV = newItems

      let objectCSV = {
                'name': `Pedido-Reorden-${this.today2}`,
                'items': this.itemCSV,
                'showLabels': true
            }

    this.sendToExcel(objectCSV)
    },
    formatPrice(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getCatalogo() {
      axios
        .get("getCatalogo")
        .then((response) => {
          this.catalogo_list = response.data;
          //this.errormsg = true;
        })
        .catch((error) => {
          //alert(error);
          console.log(error);
          this.errormsg = true;
        })
        .finally();
    },
    getCatalogoRandom() {
      axios
        .get("getCatalogoRandom")
        .then((response) => {
          this.items_product = response.data;
        })
        .catch((error) => {
          //alert(error);
          console.log(error);
          this.errormsg = true;
        })
        .finally();
    },
    newMaterialSearch(item) {
      this.sendMaterialNew = item;
    },
    getOrderID() {
      let type = "CL";
      
      //let type = "VE"; // ENCASO DE PORTAL VENDEDOR ENVIAR ESTA VARIABLE
      axios
        .post("getOrderID", {
          customer: this.user.custumer_code,
          user_id: this.user.id,
          type: type,
          doctype: 'PSDR'
        })
        .then((response) => {
          this.order_id = response.data;
          this.doctype = "PSDR"
          //this.getCartItems(this.order_id);
          this.getOrderInfo(this.order_id);
        })
        .catch((error) => {
          ///alert(error);
          console.log(error);
          this.errormsg = true;
        })
        .finally();
    },
    getOrderInfo(id) {
      axios
        .post("getOrderInfo", {
          id_order: id,
        })
        .then((response) => {
          this.order = response.data.name;
        })
        .catch((error) => {
         // alert(error);
         console.log(error);
         this.errormsg = true;
        })
        .finally();
    },
    checkOrdersHistory() {
      axios
        .post("checkOrdersHistory", {
          customer: this.user.custumer_code,
        })
        .then((response) => {
          this.orders_list = response.data;
        })
        .catch((error) => {
          //alert(error);
          console.log(error);
          this.errormsg = true;
        })
        .finally();
    },
    saveOrderInfo() {
      axios
        .post("saveOrderInfo", {
          order_id: this.order_id,
          orden_compra: this.order,
          destiny: this.customerDestiny.KUNNR,
          cart: this.cart,
          total: this.total,

        })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          //alert(error);
          console.log(error);
          this.errormsg = true;
        })
        .finally();
    },
    loadOrder(item) {
      this.dialogLoading = true;
      this.dialogPedidos = false;
      this.order_id = item.id;
      //this.getCartItems(this.order_id);
      this.getOrderInfo(this.order_id);
    },
    deleteOrder(item) {
      this.dialogLoading = true;
      this.dialogPedidos = false;
      axios
        .post("orderDelete", {
          order_id: item.id,
        })
        .then((response) => {
          console.log(response);
          this.dialogPedidos = true;
          this.checkOrdersHistory();
        })
        .catch((error) => {
          //alert(error);
          console.log(error);
          this.errormsg = true;
        })
        .finally();
    },
    generateNewOrder() {
      this.dialogLoading = true;
      this.dialogPedidos = false;
      axios
        .post("generateNewOrder", {
          customer: this.user.custumer_code,
        })
        .then((response) => {
          this.order_id = response.data;
          this.checkOrdersHistory();
        })
        .catch((error) => {
          //alert(error);
          console.log(error);
          this.errormsg = true;
        })
        .finally();
    },
    catchMaterialItem(data) {
      console.log(data);
      this.materialItem = data;
      this.setCard(data);
    },
    catchErrorMaterial(data) {
      this.hasError = data;
      window.scroll(0, window.pageYOffset - 50);
    },
    catchTotal(data) {
     /* this.subtotalPrint =  parseFloat(data).toFixed(2);
      this.subtotal = parseFloat(data);
      this.iva = parseFloat((this.subtotal * 0.16).toFixed(2));
      this.total = this.subtotal + this.iva;
      this.total = this.total.toFixed(2);*/
      this.subtotal = 0;

      var iva_a = 0;
    //this.iva = 0;
    //this.total = 0;
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      var importe_producto = element["importe_producto"];
      var impuesto = element["MWST"];
      var calcular_impuesto = 0;

      if(impuesto == 0){
        calcular_impuesto = 0;
      }else if (impuesto == 1){
        calcular_impuesto = 0.16;
      }else if(impuesto == 2){
        calcular_impuesto = 0;
      }else if(impuesto == 3){
        calcular_impuesto = 0;
      }else if(impuesto == 4){
        calcular_impuesto = 0.16;
      }else if(impuesto == 5){
        calcular_impuesto = 0.16;
      }else if(impuesto == 6){
        calcular_impuesto = 0;
      }else{
        calcular_impuesto = 0.16;
      }

      this.subtotal += parseFloat(importe_producto);
      iva_a += parseFloat(importe_producto) * (calcular_impuesto);
      
      

    } //end for
    console.log("importe:"+this.iva);
      this.subtotalPrint =  parseFloat( this.subtotal).toFixed(2);
      this.subtotal = parseFloat( this.subtotal);
      //this.iva = iva.toFixed(2);
      this.iva = parseFloat((iva_a).toFixed(2));
      this.total = this.subtotal + this.iva;
      this.total = this.total.toFixed(2);
    },
    catchDialogLoading(data) {
      this.dialogLoading = data;
    },
    cacthDestiny(data) {
      console.log("Destiny"+data);
      this.customerDestiny = data;
    },
    catchCart(data) {
      this.cart = data;
      this.setCard(data);
      this.saveOrderInfo();
    },
    catchRequestLock(data) {
      console.log(data);
      this.requestLock = data;
    },
    catchpurValidation(data) {
      console.log(data);
      if (data > 1) {
        // if(data == 0){
        this.purchase_validation = 2;
      } else {
        this.purchase_validation = 1;
      }

    },
    catchPercent(data){
     this.porcentaje=data;
     if(this.porcentaje > 100 || this.porcentaje < 0){
      this.purchase_validation = 2;
      }else{
      this.purchase_validation = 1;
      }
    },
    catchAviable(data){
     this.aviable = data;
     if(this.total > this.aviable ){
      this.purchase_validation = 2;
      }else{
        this.purchase_validation = 1;
      }
    },
    catchConnection(data){
     this.errormsg = data;
    },
    catchYearV(data){
      this.year_validation = parseInt(data);
      if(this.year_validation < 6){
      this.order_psdr_v =false; 
      // Si la antiguedad es menor a 6 se bloquea el pedido y el valor pasa a falso
     }
    },
    catchPPDP(data){
      this.ppdp_val = data;
      if(this.ppdp_val > 36){
       this.order_psdr_v =false;
       //Si el valor es mayor a 36 se bloquea y el valor cambia a falso
     }
    },
    catchPSDR(data){
       this.psdr_val =data;
       if(this.psdr_val > 0){
       this.order_psdr_v =false;
       //Si ya tiene mas de un pedido se bloquea y el valor cambia a falso
     }
    },
    catchPSNW(data){
     this.psnw_val =data;
    },
    catchPSMA(data){
     this.psma_val =data;
    },
    catchPSPO(data){
     this.pspo_val =data;
    },
    catchRTRIMA(data){
     this.rtrima_val = data;
     if(this.rtrima_val < 20){
      this.order_psdr_v =false;
      // Si es menor a 20 se bloquea y el valor cambia a falso
     }
  },
  catchCreditDays(data) {
    this.days_credit = data;
    if (this.days_credit > 60) {
      this.order_psdr_v =false;
      //Si es mayor a 60 dias se bloquea el valor cambia a falso 
    }
  },
  catchRSR6M(data) {
    this.ppr_val = data;
    if (this.ppr_val < 10) {
      this.order_psdr_v =false;
      //Si es menor a 10 productos se bloquea el valor cambia a falso
    }
  },
  checkBackOrder() {
      this.hasError = null;
      if (this.order == "") {
        //this.hasError = "Es necesario ingresar orden de compra";
        //this.$refs.errorMaterial.scrollTop = 0;

        this.$swal({
         title: "Es necesario ingresar orden de compra.",
         icon: "error",
         type: "error",
       }).then((result) => {
         console.log(result);
         //location.reload();

       });
      } else if (this.customerDestiny == {}) {
        this.hasError = "Es necesario ingresar destino de compra";
        //this.$refs.errorMaterial.scrollTop = 0;
      } else {
        const existBackOrder = this.cart.some((item) => item.recordatorios > 0);
        if (existBackOrder) {
          this.dialogBackOrder = true;
        } else {
          this.orderGenerate();
        }
      }
    },
    orderGenerate() {
     console.log("Doctype");
     console.log(this.orderType);

     this.dialogBackOrder = false;
     this.dialogLoading = true;
     this.submitted = true;

     if(this.total > this.aviable ){
      this.purchase_validation = 2;
      }else{
      this.purchase_validation = 1;
      }

      if(this.porcentaje > 100 || this.porcentaje < 0){
      this.purchase_validation = 2;
      }else{
      this.purchase_validation = 1;
      }

     if(this.customerDestiny == {}){
        this.warnError("El campo de Destino de compra es obligatorio.");
      }else if (this.order_id == ""){
        //this.warnError("El campo de Orden de compra es obligatorio.");
        //this.hasError = "Es necesario ingresar orden de compra";
        //this.$refs.errorMaterial.scrollTop = 0;
      
       this.$swal({
         title: "Es necesario ingresar orden de compra.",
         icon: "error",
         type: "error",
       }).then((result) => {
         console.log(result);
         location.reload();

       });
          
                  
      }else{
      axios
        .post("generateOrderCustomer", {
          order_id: this.order_id,
          orden_compra: this.order,
          destino_compra: this.customerDestiny.KUNNR,
          carrito: this.cart,
          customer: this.user.custumer_code,
          VKORG: this.user.VKORG,
          VTWEG: this.user.VTWEG,
          VKBUR: this.user.VKBUR,
          numero_vendedor: this.user.custumer_code,
          order_type: this.orderType,
          bandera_programado: this.banderaProgramado,
          purchase_validation: this.purchase_validation,
          //fecha_rec: this.formatDate(this.fecha_programado),
        })
        .then((response) => {
         
         // console.log(response.data.RDCreaEntrega);
         if(response.data.message == "BACKORDER GENERADO" ){
         
          this.$swal.fire(response.data.message, 'Número de Pedido: S/N','success');
          this.$router.replace({
            name: "HistoryOrders",
          });
        
        }else{
          this.dialogLoading = false;
          //this.order_response = response.data.RDCreaEntrega.VBELN;
          this.order_response = response.data.VBELN;
          this.order_purchase = response.data.PVALID;
          this.cart = [];
          this.order = null;
           if (this.order_purchase == 2) {

             this.$swal.fire('Pedido Generado', 'Número de Pedido: ' + this.order_response + '. Pedido Bloqueado por Credito', 'success');
             this.$router.replace({
               name: "HistoryOrders",
             });
           } else {
             this.$swal.fire('Pedido Generado', 'Número de Pedido: ' + this.order_response + '.', 'success');
             this.$router.replace({
               name: "HistoryOrders",
             });
           }
        }
        })
         .catch((error) => {
          this.dialogLoading = false;
          if(error.response.status == 404){
          //alert(error.response.data.error_code);
          if (error.response.data.message == "BACKORDER GENERADO") {
              this.$swal.fire(
                error.response.data.message,
                "Número de Pedido: S/N",
                "success"
              );
              this.$router.replace({
                name: "HistoryOrders",
              });
            }else{
              this.$swal({
              title: error.response.data.error_code,
              icon: "error",
              type: "error",
            }).then((result) => {
              console.log(result);
             // location.reload();

            });
            }
          }else{
            //alert(error);
            console.log(error);
          }

          this.submitted= false;
        })
        .finally(() => (this.dialogLoading = false, this.submitted= false));
      }
    },
    /*orderTypeItemsData() {
      axios
        .post("orderType", {
          doctype: 'PSDR',
        })
        .then((response) => {
          //this.orderType = response.data.type;
         // this.getItemTextOrder = response.data.name;
          this.orderItemsList = response.data;
          this.orderType = this.orderItemsList[0].type;

          //this.destiny = this.customerDestinyList;
          console.log("Tipo PEDIDO");
          console.log(this.orderTypeItems);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => (this.dialogLoading = false));
    },*/
    getItemTextOrderType(item) {
      return `(${item.type}) ${item.name}`;
    },
    getItemValueOrderType(item) {
      return `${item.type}`;
    },
  },
  watch: {
    cart: {
            immediate: true,
            handler() {
                this.generarExcel()
            },
    },
  },
};
</script>
  
  