<template>
  <v-simple-table dense class="mt-4">
    <thead>
      <tr>
        <th class="text-left">CODIGO</th>
        <th class="text-left">DESCRIPCION</th>
        <th class="text-left">UNIDADES</th>
        <th class="text-left">EMPAQUE</th>
        <th class="text-left">VENTA</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in products" :key="item.id">
        <td class="text-left">{{ item.MATERIAL }}</td>
        <td class="text-left">{{ item.SHORT_TEXT }}</td>
        <td class="text-left">{{ item.REQ_QTY }}</td>
        <td class="text-left">{{ item.S_UNIT_DLV || item.BSTRF }}</td>
        <td class="text-left">$ {{ format(item.NETVA) }}</td>
      </tr>
      <tr v-if="total !== null">
        <td colspan="3" style="text-align: right">
          <b>Importe</b>
        </td>
        <td style="background-color: #3bafda">
          <b>$ {{ format(total) }}</b>
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
export default {
	name: 'ProductsOrderTable',

	props: {
		products: {
			type: Array,
			required: true
		},

		total: {
			type: Number,
			default: null
		}
	},

	methods: {
		format(value) {
      const result = Number(value).toFixed(2);
      return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
	},
}
</script>