<template>
  <div class="bg-white">
    <div class="card-title">
      <h1 class="font-weight-black">
        <v-icon large class="text-black"> mdi-cash</v-icon>
        SOLICITUDES PRUEBA DE VIDA
      </h1>
    </div>
    <v-container>
      <v-row>
        <v-col cols="12" md="4"></v-col>
        <v-col cols="12" md="4" class="text-left">
          <label for="" class="text-left">Filtro por estado:</label>
          <v-select
            :items="status"
            item-text="status"
            item-value="id"
            multiple
            v-model="params.status"
          ></v-select>
        </v-col>
        <v-col cols="12" md="4" class="text-left">
          <label for="" class="text-left">Búsqueda:</label>
          <v-text-field v-model="params.search" clearable></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <v-btn color="primary" @click="createRequest">
            GENERAR NUEVA SOLICITUD
          </v-btn>
        </v-col>
        <v-col cols="12" md="4">
          <v-btn
            color="primary"
            @click="loadPromissoryNotes"
            :loading="loading"
          >
            <v-icon>mdi-refresh</v-icon>
            ACTUALIZAR
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mt-10">
        <v-data-table
          :headers="headers"
          :items="items"
          class="elevation-2"
          :options.sync="options" 
          :server-items-length="totalItems">
          <template v-slot:[`item.custumer_code`]="{ item }">
            {{ item?.customer?.customer_code }}
          </template>
          <template v-slot:[`item.company_name`]="{ item }">
            {{ item?.customer?.company_name }}
          </template>
          <template v-slot:[`item.a_estate`]="{ item }">
            {{ item?.customer?.a_estate }}
          </template>
          <template v-slot:[`item.a_town`]="{ item }">
            {{ item?.customer?.a_town }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              color="warning"
              fab
              x-small
              dark
              @click="editLiveRequest(item)"
              class="mr-1"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              color="error"
              fab
              x-small
              dark
              @click="deleteLiveRequest(item)"
              class="mr-1"
              v-if="item.customer"
            >
              <v-icon>mdi-trash-can</v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.completed`]="{ item }">
            <v-chip color="green" outlined v-if="item.completed == 1">
              Completado
            </v-chip>
            <v-chip color="red" outlined v-else> Pendiente </v-chip>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="loadPromissoryNotes">
              Recargar
            </v-btn>
          </template>
        </v-data-table>
      </v-row>
    </v-container>
    <div class="w-full d-flex footer mt-10">
      <p class="text-left w-50">*Última fecha de actualización:</p>
      <p class="text-right w-50">
        Información confidencial. Prohibida su reproducción o divulgación total
        o parcial, así como su uso o aprovechamiento sin autorización escrita de
        IUSA.
      </p>
    </div>

    <v-dialog v-model="observationsModal" width="600px">
      <v-card>
        <v-card-text>
          <v-timeline align-top dense>
            <v-timeline-item
              color="primary"
              small
              v-for="observation in observations"
              :key="`obs-${observation.id}`"
            >
              <v-row class="pt-1">
                <v-col cols="3">
                  <strong>{{ observation.creado }} hrs.</strong>
                </v-col>
                <v-col>
                  <strong>{{ observation.user?.name }}</strong>
                  <div class="text-caption">
                    {{ observation.comment }}
                  </div>
                </v-col>
              </v-row>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "@/plugins/axios";
import { mapActions, mapMutations } from "vuex";

export default {
  name: "PromissoryNotes",
  components: {},
  data: () => ({
    loading: false,
    items: [],
    totalItems: 0,
    observationsModal: false,
    observations: [],
    headers: [
      { text: "Fecha (dd/mm/aaaa)", value: "test_created_at" },
      { text: "UUID", value: "uuid" },
      { text: "No. Cliente", value: "custumer_code" },
      { text: "Nombre del cliente", value: "company_name" },
      { text: "RFC", value: "rfc" },
      { text: "Estado", value: "a_estate" },
      { text: "Municipio", value: "a_town" },
      { text: "Estatus", value: "completed" },
      { text: "Acciones", value: "actions" },
    ],
    options: {},
    status: [
      { id: 0, status: "Pendiente" },
      { id: 1, status: "Completado" },
    ],
    params: {
      status: [],
      search: null,
    },
  }),
  
  watch: {
    options: {
      handler () {
        this.loadPromissoryNotes()
      },
      deep: true,
    },
  },

  mounted() {
    this.loadPromissoryNotes();
  },
  methods: {
    ...mapMutations({
      setStep: "livetest/SET_FORM_STEP",
      setLiveTest: "livetest/SET_LIVETEST",
    }),
    ...mapActions({
      deleteLivetest: "livetest/deleteLivetest",
      loadCustomerRequest: "livetest/loadCustomerRequest",
    }),
    loadPromissoryNotes() {
      this.loading = true;
      let params = [];
      const { page, itemsPerPage } = this.options

      if (this.params.status.length > 0) {
        params.push(`filter[completed]=${this.params.status.join(",")}`);
      }

      if (this.params.search && this.params.search.length > 0) {
        params.push(`search=${this.params.search}`);
      }

      if (itemsPerPage) {
        params.push(`per_page=${itemsPerPage}`);
      }

      if (page && page > 1) {
        params.push(`page=${page}`);
      }

      axios
        .get(`/livetest?${params.join('&')}`)
        .then((response) => {
          this.items = response.data.data;
          this.totalItems = response.data.total;
        })
        .catch((error) => {
          this.$swal({
            title: "Ocurrio un error al recuperar las solicitudes",
            text: error,
            type: "warning",
          });
        })
        .finally(() => (this.loading = false));
    },
    createRequest() {
      this.$router.push("/solicitudes/crear");
    },
    validate(item) {
      this.$swal({
        title: "Asignar línea de crédito:",
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        icon: "success",
        confirmButtonText: "Confirmar",
        confirmButtonColor: "#3085d6",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancelar",
        preConfirm: async (amount) => {
          try {
            let response = await axios.put(
              `/customers/${item.custumer_data_id}/promissory-notes/${item.id}`,
              {
                validated: true,
                amount,
              }
            );
            return response.data;
          } catch (error) {
            console.log(error);
          }
        },
        allowOutsideClick: () => !this.$swal.isLoading(),
      }).then((result) => {
        if (result.value?.promissory_note) {
          let index = this.items.findIndex((element) => element.id == item.id);
          if (index > -1) {
            this.items[index].custumer_status_id =
              result.value?.promissory_note.custumer_status_id;
          }
          this.$swal.fire({
            title: `YA SE LIBERÓ A CRÉDITO Y COBRANZA`,
            icon: "success",
            type: "success",
            confirmButtonText: "Confirmar",
            confirmButtonColor: "#3085d6",
          });
        }
      });
    },
    showComments(item) {
      this.observations = item.comments;
      this.observationsModal = true;
    },
    deleteLiveRequest(item) {
      this.$swal
        .fire({
          title: "¿Estás seguro de eliminar?",
          text: "No podrás revertir esta acción",
          icon: "warning",
          showCancelButton: true,
          confirmButton: "#3085d",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Si, eliminar!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.deleteLivetest(item)
              .then(() => {
                this.loadPromissoryNotes();
                this.$swal({
                  title: "¡El registro se ha eliminado correctamente!",
                  icon: "success",
                  type: "succes",
                });
              })
              .catch((e) => {
                console.log(e);
              });
          }
        });
    },
    editLiveRequest(item) {
      this.setLiveTest({ uuid: item.uuid, test_url: item.test_url });
      this.setStep(3);
      this.$router.push("/solicitudes/crear").then(() => this.loadCustomerRequest());
    },
  },
};
</script>

<style scoped>
.card-title {
  padding-left: 3em;
  padding-right: 3em;
  margin-top: 1em;
  margin-bottom: 1em;
}
.card-title h1 {
  border: 4px solid #000;
  border-radius: 5px;
  font-size: 1.2em;
  padding-top: 0.3em;
  padding-bottom: 0.2em;
}
.footer {
  padding-left: 3em;
  padding-right: 3em;
}
</style>
