import axios from "@/plugins/axios";
import store from ".";

export default {
  namespaced: true,
  state: {
    types: [],
    diaries: [],
    iddiary: null,
    currentDiary: { customData: { status: 0 }},
    filterdiaries: [],
    currentEvidence: null,
    currentChecking: null,
    checkingMap: { lat: 19.3907202, lng: -99.213652 },
    month: null,
    year: null,
    drawer: true,
    onlymy: false,
    idsfilters: []
  },
  getters: {
    iddiary(state) {
      return state.iddiary;
    },
    types(state) {
      return state.types;
    },
    diaries(state) {
      return state.diaries;
    },
    currentDiary(state) {
      return state.currentDiary;
    },
    filterdiaries(state) {
      return state.filterdiaries;
    },
    currentEvidence(state) {
      return state.currentEvidence;
    },
    currentChecking(state) {
      return state.currentChecking;
    },
    checkingMap(state) {
      return state.checkingMap;
    },
    totaldiaries(state) {
      return state.filterdiaries.length;
    },
    totalchecking(state) {
      return state.diaries.filter(item => item.checking).length;
    },
    mydiaries(state) {
      return state.filterdiaries.filter(item => item.customData.user_id == store.state.auth.user.id).length;
    },
    drawer(state) {
      return state.drawer;
    }
  },
  mutations: {
    SET_MONTH(state, data) {
      state.month = data;
    },
    SET_YEAR(state, data) {
      state.year = data;
    },
    SET_DIARY_ID(state, data) {
      state.iddiary = data;
    },
    SET_TYPES(state, data) {
      state.types = data;
    },
    SET_DIARIES(state, data) {
      state.diaries = data.map(item => {
        let startDate = new Date(item.start_date);
        let finishDate = new Date(item.finish_date);
        
        return {
          key: item.id,
          customData: {
            id: item.id,
            title: item.title,
            status: item.status,
            type: item.type,
            checking: item.checking,
            checkout: item.checkout,
            evidence: item.evidence,
            user_id: item.user_id,
            user: item.user,
            class: item.type.color + " white--text",
            color: item.type.color,
            start: startDate.toLocaleTimeString().substring(0,5),
            tooltip: item.type.type + '<br>De: ' + startDate.toLocaleTimeString() + ' a ' + finishDate.toLocaleTimeString()
          },
          dates: new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()),
          popover: {
            label: item.title,
          },
        };
      });

      state.filterdiaries = state.diaries;
    },
    SET_CURRENT_DIARY(state, data) {
      if(isNaN(data)) {
        console.log('No es numero');
        state.currentDiary.customData = data;
      } else {
        console.log('Es numero');
        state.currentDiary = state.diaries.find(item => item.customData.id == data);
      }
    },
    SET_FILTERS(state, data) {
      state.idsfilters = data.map(item => item + 1);
      state.diaries = state.filterdiaries.filter(item => state.idsfilters.includes(item.customData.type.id));
    },
    SET_CURRENT_EVIDENCE(state, data) {
      state.currentEvidence = data;
    },
    SET_CURRENT_CHECKING(state, data) {
      state.currentChecking = data;
    },
    SET_CHECKING_MAP(state, data) {
      state.checkingMap.lat = data.checking.latitude;
      state.checkingMap.lng = data.checking.longitude;
    },
    ADD_DIARY(state, data) {
      let startDate = new Date(data.start_date);
      let finishDate = new Date(data.finish_date);

      let diary = {
        key: data.iddiary,
        customData: {
          id: data.iddiary,
          title: data.title,
          status: data.status,
          type: data.typeDiary,
          checking: data.checking,
          checkout: data.checkout,
          evidence: data.evidence,
          user_id: data.user_id,
          user: data.user,
          class: data.typeDiary.color + " white--text",
          color: data.typeDiary.color,
          start: startDate.toLocaleTimeString().substring(0,5),
          tooltip: data.typeDiary.type + '<br>De: ' + startDate.toLocaleTimeString() + ' a ' + finishDate.toLocaleTimeString()
        },
        dates: new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()),
        popover: {
          label: data.title,
        },
      };
      state.diaries.push(diary);
    },
    SET_DRAWER_STATE(state, data) {
      state.drawer = data;
    },
    SET_ONLYMY(state, data) {
      state.onlymy = data;
      console.log(store.state.auth.user.id);
      if(data) {
        state.diaries = state.filterdiaries.filter(item => state.idsfilters.includes(item.customData.type.id) && item.customData.user_id == store.state.auth.user.id);
      } else {
        state.diaries = state.filterdiaries.filter(item => state.idsfilters.includes(item.customData.type.id));
      }
    },
    SET_DIARY_CHECKOUT(state, data) {
      let index = state.diaries.findIndex(item => item.id == data.id);
      state.diaries[index].customData.checkout = data;
    }
  },
  actions: {
    async loadDiaryTypes({ commit }) {
        axios.get("diary-types").then(response => {
            if(response.status == 200) {
                commit("SET_TYPES", response.data.data);
                commit("SET_FILTERS", response.data.data.map(item => item.id - 1));
            }
        }).catch(error => {
            console.log(error);
        });
    },
    async loadDiaries({ commit }, payload) {
        axios.get(`diary?month=${payload?.month}&year=${payload?.year}`).then(response => {
            if(response.status == 200) {
                commit("SET_DIARIES", response.data.diaries);
            }
        }).catch(error => {
            console.log(error);
        })
    },
    async storeChecking({getters, commit}, payload) {
      axios.post(`/diary/${getters.currentDiary.customData.id}/checking`, payload).then(response => {
        if(response.status == 201) {
          console.log('Checking guardado');
          commit("SET_CURRENT_DIARY", response.data.data);
        }
      });
    },
    async storeEvidence({getters}, payload) {
      console.log(payload);
      let formData = new FormData();

      formData.append('photo', payload);

      return axios.post(`/diary/${getters.currentDiary.customData.id}/evidences`, formData);
    },
    async acceptEvent({ commit }, payload) {
      axios.put(`/guest-diary/${payload.iddiary}`, payload).then(response => {
        commit("ADD_DIARY", payload);
        console.log(response.data);
      }).catch(error => {
        console.log(error);
      });
    },
    storeCheckout({ commit }, data) {
      return axios.post(`diary/${data.diary_id}/checkout`, data).then(response => commit('SET_DIARY_CHECKOUT', response.data));
    }
  },
};
