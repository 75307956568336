<template>
  <div>
    <div class="container-fluid grey lighten-5 pt-4">
<v-row>
  <v-col md="12">
    <h2 class="h2">Movimientos de Cuenta<a
      @click="videoShow"
    ><v-icon> mdi-cash</v-icon>
  </a></h2>
  </v-col>
</v-row>

<p></p>
<v-row>
  <v-col md="4">
    <v-btn
      depressed
      x-large
      color="primary"
      block
      @click="history"
    >
      Historial  &nbsp;<v-icon> mdi-text-box-multiple-outline</v-icon>
    </v-btn>
  </v-col>
  <v-col md="4" v-if="bonds.length > 0">
    <v-select
    v-if="invoiceBone!=0"
    label="Selecciona Bono"
    placeholder="Selecciona Bono"
    v-model="bondCurrent"
    :items="bonds" item-text="name" item-value="id">
    <template v-slot:item="{ on, item }">
            <v-list-item v-on="on" v-bind:class="item.id == 262 && getAuart == false ? 'list-hide-disabled':''">{{ item.name }}</v-list-item>
    </template>
  </v-select>
  </v-col>
  <v-col md="4">
    <v-btn
      depressed
      x-large
      color="error"
      v-if="selected.length > 0"
      block
      @click="toPay"
    >
      Pagar &nbsp;<v-icon> mdi-credit-card-check </v-icon>
    </v-btn><br>
    <v-btn
      depressed
      x-large
      color="info"
      v-if="bondCurrent > 0"
      block
      :disabled="loadingBono"
      @click.prevent="toBond"
    >Aceptar Bono</v-btn>
  </v-col>
  <v-col md="4">
    <v-btn
      depressed
      x-large
      color="success"
      block
      @click.prevent="toAntPay"
    >
      Pago Anticipado &nbsp;<v-icon> mdi-credit-card-clock </v-icon>
    </v-btn><br>
  </v-col>
</v-row>
<br>
<v-progress-linear :active="loadingBono" :indeterminate="loadingBono" top color="deep-purple accent-4"></v-progress-linear>
<br>
<v-row>
  <v-col>
    <v-card>
      <v-card-title>
  <v-spacer></v-spacer>
  <v-text-field
    v-model="search"
    append-icon="mdi-magnify"
    label="Buscar..."
    single-line
    hide-details
  ></v-text-field>
</v-card-title>
    <v-data-table
      v-model="selected"
      :search="search"
      :headers="headers"
      :items="markers"
      :items-per-page="15"
      show-select
      item-key="number"
      class="elevation-1"
    >
       <template v-slot:[`item.number`]="{ item }">
        <!--<div v-if="item.auart != 'PSPO'" class="ml-auto text-right">-->
          {{item.number}}
          <v-tooltip bottom v-if="item.auart == 'PSPO'">
            <template v-slot:activator="{ on, attrs }">
             <span v-bind="attrs" v-on="on" >
              <img class="ml-2"  :src="`/img/account_icons/BONO_POP.svg`" style="width:20px;" alt="">
             </span>
           </template><span>Bono POP</span>
          </v-tooltip>
      </template>
      <template v-slot:[`item.amount`]="{ item }">
        $ &nbsp;&nbsp;{{ format(item.amount) }}
      </template>
      <template v-slot:[`item.dmbtrnc`]="{ item }">
        $ &nbsp;&nbsp;{{ format(item.dmbtrnc) }}
      </template>
      <template v-slot:[`item.dmbtrto`]="{ item }">
        $ &nbsp;&nbsp;{{ format(item.dmbtrto) }}
      </template>
      <template v-slot:[`item.dmbtrdz`]="{ item }">
        $ &nbsp;&nbsp;{{ format(item.dmbtrdz) }}
      </template>
      <template v-slot:[`item.impfacnet`]="{ item }">
        $ &nbsp;&nbsp;{{ format(item.impfacnet) }}
      </template>
      <template v-slot:[`item.difbudat`]="{ item }">
        $ &nbsp;&nbsp;{{ format(item.difbudat) }}
      </template>
      <template v-slot:[`item.difbudatp`]="{ item }">
        % &nbsp;&nbsp;{{ format(item.difbudatp) }}
      </template>
    </v-data-table>
  </v-card>
  </v-col>
</v-row>
<p></p>
<div class="text-center">
  <v-dialog v-model="dialog" width="500" persistent>
    <bank-payment-card :userChannel="user.VTWEG" :customerCode="customerCode" :invoice="invoice" :typePay="typePay" @close="close"/>
  </v-dialog>
  <v-dialog
    v-model="dialog3"
    width="90%"
    >
      <div class="w-full bg-black d-flex justify-content-between align-center px-5">
        <span class="text-white d-flex align-center font-weight-bold">Historial estado de cuenta</span>
        <v-btn icon small style="bg-gray" @click="dialog3 = false">
          <v-icon class="text-white font-size-8">mdi-close</v-icon>
        </v-btn>
      </div>
    <account-status-card :customerCode="customerCode" :customerGroup="customerGroup"/>
  </v-dialog>
  <v-dialog
v-model="dialog4" width="50%">
<v-card>
<v-card-text>
    <v-row class="mb-5">
        <v-col>
            <v-card class="mx-auto">
              <v-divider></v-divider>
              <v-card-text>
                <iframe src="https://www.youtube.com/embed/LHrrudI5zdc" style="width: 100%;height: 30em;"></iframe>
              </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</v-card-text>
<v-divider></v-divider>
<v-card-actions>
<v-spacer></v-spacer>
<v-btn color="success" text @click="close4()"> Cerrar </v-btn>
</v-card-actions>
</v-card>
</v-dialog>
</div>
</div>
  </div>
 
</template>

<script>
import axios from "@/plugins/axios";
import { mapGetters, mapActions } from "vuex";
import AccountStatusCard from "../../components/AccountStatusCard.vue"; 
import BankPaymentCard from "../../components/BankPaymentCard.vue"; 
import moment from 'moment';
import 'jspdf-autotable';  

export default { 
  components: {
    AccountStatusCard,
    BankPaymentCard
  },
  data() {
    return {
      loadingBono:false,
      url: null,
      bank: 0,
      bank2: 0,
      dialog: false,
      dialog2:false,
      dialog3:false,
      dialog4:false,
      antpay:null,
      more: null,
      data_f: null,
      selected: [],
      bondCurrent:0,
      bonds: [],
      bondCost:[],
      data: null,
      invoice: [],
      markers: [],
      markersh:[],
      search: '',
      loadingOrderDeliveryTime:true,
      datacustumer:null,
      token: null,
      mp_order_1:null,
      invoiceBone: 0,
      inputAmout: false,
      textAlert:null,
      today2: moment().format('YYYY/MM/DD'), 
      invoiceBoneCurrent:[],
      //paypalClientId: process.env.VUE_PAYPAL_ID_CLIENTE,
      headers: [
        { text: "Factura", value: "number", visible: true  },
        { text: "Fe. Factura", value: "date" , visible: true },
        { text: "Fe. Vencimiento", value: "expiration", visible: true },
        { text: "Impte. Fact.", align: 'right', value: "amount", visible: true },
        { text: "DMora", value: "days", visible: true },
        { text: "Estatus", value: "status", visible: true },
        { text: "Nota Cred.", align: 'center', value: "vbelnnc", visible: true },
        { text: "Impte. NCred", align: 'right', value: "dmbtrnc", visible: true },
        { text: "Docto. Pago", align: 'center', value: "belnrdz", visible: true },
        { text: "Impte. Pago", align: 'center', value: "dmbtrdz", visible: true },
        { text: "DPP", align: 'center', value: "difbudat", visible: false },
        { text: "% DPP", align: 'center', value: "difbudatp", visible: false },
        { text: "Neto a Pagar", align: 'right', value: "impfacnet", visible: true },
      ],
      headerHistory:[
        {
          text: "Tipo Documento",
          align: "start",
          sortable: false,
          value: "type",
        },
        { text: "# Documento", value: "number" },
        { text: "Fecha", value: "date" },
        { text: "Vencimiento", value: "date_overdue" },
        { text: "Importe", value: "amount" },
        { text: "PDF/XML", value: "actions", sortable: false },
        { text: "Complemento de Pago", sortable: false, value: "pay_complement" },
      ],
      
      bonoShow:false, 
      customerCode:0,
      customerInfo:[],
      paypal: null,
      dialogPayPal: false,
      totalPaypal: null,
      PaypalButton: false,
      itemCSV:[],
      getAuart:false,
      customerGroup: "",
      typePay:null,
    };
  },
  computed: {
    currentPeriod(){
      const today = new Date;
      let firstDay = 1; 
      let year = today.getFullYear();
      let currentMonth = today.toLocaleString('default', { month: 'long' }) 
       currentMonth=  currentMonth.charAt(0).toUpperCase() + currentMonth.slice(1);
      return ` del (${firstDay} de ${currentMonth} ${year} al ${today.getDate()} de ${currentMonth} del ${year})`;
    },
        directorFullName(){
          return this.user.VTWEG =='DT'?'Minorista Nacional':'Privados Nacional';
          //  return 'Minorista Nacional'
                // return this.seller.NACHND+" "+this.seller.NACH2D+" "+this.seller.VORNAD
            },
        sellerFullName(){ 
            return this.customerInfo.NAMEVEN;
        },
    ...mapGetters({
      user: "auth/user",
    }),
},
  watch: {
    selected(value) {
      if(value[0].showSelect){
        this.$swal({
          title: "NO SE PUEDE SELECCIONAR ESTA FACTURA",
          icon: "error",
          type: "danger",
          }).then((result)=> {
          console.log(result);
        });
        return false;
      }
        this.inputAmout = false;
        this.invoice = [];
        this.invoiceBoneCurrent = value;
        this.invoiceBone = value[0].number;
        this.getBonds(value);
        
      for (let i = 0; i < value.length; i++) {
        this.invoice.push({
          number: value[i].number,
          amount: value[i].impfacnet,
          difbudat: value[i].difbudatp, 
          vbelnnc: value[i].vbelnnc,
          belnrdz: value[i].belnrdz,
        });
      }
    },
    markers: {
            immediate: true,
            handler() {
                this.generarExcel()
            },
    },
  },
  async mounted() {
    if(this.h != null){
      this.history()
    }
    let self = this; 
    if (this.$hasAnyRole(['Customer', 'CustomerPR'])) {
      this.customerCode = this.user.custumer_code;
      this.type = 'CL';
      this.numero_vendedor = this.user.custumer_code;
      this.pathHome = '/';
      this.datacustumer = this.customerInfo;
    } else {
      const currentCustomerInfo = JSON.parse(localStorage.getItem('currentCustomerInfo')) || [];
      if (currentCustomerInfo.length < 1) {
        this.$router.push({ name: 'Home' })
      }
      this.customerInfo = currentCustomerInfo;
      this.customerCode = parseInt(currentCustomerInfo?.KUNNR, 10);
      this.datacustumer = this.customerInfo;
      this.sellerOrderAviable = false;
      this.type = 'VE';
      this.numero_vendedor = this.user.employee_number;
      this.pathHome = 'CustomerCoverDetails';
    } 
        
    this.generarPDF();
    self.getCustomerInformation();
    

    self.daysPayDue();
    let uri = window.location.href.split('?');
    let vars = uri[1].split('=');
    let param = vars[0];

    if(param == "token"){
      self.getParam1();
    }else if(param == "data"){
      
      self.getParam2(this.$route.query.data,"","");
   
    }
    var nbResponse = this.$route.query.nbResponse;  
    var cdResponse = this.$route.query.cdResponse;  
    var nb_error = this.$route.query.nb_error;  
    if(nbResponse != ""){
      this.getParam2(nbResponse,cdResponse,nb_error);
    }  
    /*else if (param == "strResponse"){

      self.getParam3();
    }*/
   
 
  },
  methods: {
    ...mapActions({
        sendToPDF: 'printer/sendToPDF',
        sendToExcel: 'printer/sendToExcel',
    }),
    generarPDF(){
            this.sendToPDF({active: true});
    },
    generarExcel(){
      let newItems = []
      this.itemCSV = []

      for (let key in this.markers) {                
                let item = this.markers[key];
                newItems.push({
                        'Factura': item.number,
                        'Fe. Factura': item.date,
                        'Fe.Vencimiento': item.expiration,
                        'Impte. Fact.': item.amount,
                        'DMora.':item.days,
                        'Estatus.': item.status,
                        'Nota Cred..': item.vbelnnc,
                        'Impte. NCred.': item.dmbtrnc,
                        'Docto. Pago.':item.belnrdz,
                        'Impte. Pagp.':item.dmbtrdz,
                        'Neto a Pagar':item.impfacnet,
                    });
              }
      this.itemCSV = newItems

      let objectCSV = {
                'name': `Movimientos-${this.today2}`,
                'items': this.itemCSV,
                'showLabels': true
            }

    this.sendToExcel(objectCSV)
    },
    getBonds(value){
      this.bonds = [];
      if(value.some((item) => item.auart != 'PSPO')){ 
         this.getAuart = false;
       }else{
         this.getAuart = true;
       }
      //this.getAuart = value;
      //console.log("aurat"+value[0].auart);
        if( this.bonoShow ){
          if(this.user.VTWEG == "DT"){
        this.$swal({
          title: "NO SE PUEDE UTILIZAR BONO CUANDO SE TIENEN FACTURAS VENCIDAS",
          icon: "error",
          type: "danger",
          }).then((result)=> {
          console.log(result);
        });
        this.invoiceBone = 0;
      }else{
        this.$swal({
          title: "USTED TIENE FACTURAS VENCIDAS, NO PUEDE OBTENER EL BENEFICIO DEL DESCUENTO POR PRONTO PAGO  HASTA QUE LIQUIDE LAS FACTURAS VENCIDAS",
          icon: "error",
          type: "danger",
          }).then((result)=> {
          console.log(result);
        });
        this.invoiceBone = 0;
         }
      }
      else if( this.invoiceBoneCurrent[0].dmbtrto > 0 ){
        axios
        .get("get-bonds/"+this.customerCode+"/"+this.user.VTWEG)
        .then((response) => {
          if( response.data.result == 1 ){
            if( response.data.data.length == 0){
              this.$swal({
                title: "No tienes bonos asignados",
                icon: "error",
                type: "danger",
                });
            } else if (response.data.data.length == 1 && response.data.data[0].id == 262 && this.getAuart == false){
              this.$swal({
                title: "No tienes bonos asignados a esta factura",
                icon: "error",
                type: "danger",
                });
            } else {
              this.bonds = response.data.data;
            }
          }
        })
        .catch((error) => {
          console.warn(error);
        });
      }
      else{
        
        this.invoiceBone = 0;
      }
    },
    getCustomerInformation() {
      axios
        .post("getExpiredOrders", {
          client: this.customerCode,//'0000052365',
          channel: this.user.VTWEG,
        })
        .then((response) => {
          this.data = response.data;
          let info = this.data.length;
          for (let i = 0; i < info; i++) {
            if( this.data[i].ESTAT == "Vencido" || this.data[i].ESTAT == "VENCIDO" ){
              this.bonoShow = true;
            }

            var belnrExplode = false;
            if( this.data[i].BELNR.substring(0,1) == 6 ){
              belnrExplode = true;
            }
            if(this.data[i].IMPFACNET > 0){
            this.markers.push({
              type: "FACTURA",
              number: this.data[i].BELNR,
              date: this.data[i].BUDAT,
              expiration: this.data[i].FVENC,
              amount: this.data[i].DMBTR,
              days: this.data[i].DMORA,
              status: this.data[i].ESTAT,
              vbelnnc: this.data[i].VBELNNC,
              dmbtrnc: this.data[i].DMBTRNC,
              dmbtrto: this.data[i].DMBTRTO,
              belnrdz: this.data[i].BELNRDZ,
              dmbtrdz: this.data[i].DMBTRDZ,
              difbudat: this.data[i].DIFBUDAT,
              impfacnet: this.data[i].IMPFACNET,
              difbudatp: this.data[i].DIFBUDATP,
              showSelect: belnrExplode,
              auart: this.data[i].AUART,
              bonds_cont: this.data[i].bonds_cont,
              bonds_ant: this.data[i].bonds_ant,
            });
           }
         }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    toPay() {
      this.selected;
      for (let i = 0; i < this.selected.length; i++) {
        console.log(i);
        if (i > 0) {
          console.log(i);
          this.more = true;
        } else {
          this.more = false;
        }
      }
      this.typePay = 1;
      this.dialog = true;
    },
    toBond(){
      this.loadingBono = true;
      this.bondCost = [];
      this.bondCost = this.bonds.filter(b=> b.id==this.bondCurrent);
      if( parseFloat(this.invoiceBoneCurrent[0].dmbtrto) > parseFloat(this.bondCost[0].cost) ){

        axios
        .post("note-credit", {
          client_code: this.customerCode,
          invoice: this.invoice,
          code: this.bondCurrent,
          cost: this.bondCost[0].cost,
          channel: this.user.VTWEG,
        })
        .then((response) => {
          this.loadingBono = false;
          if(response.data.result == 1 ){
            
            this.$swal({
              title: response.data.message,
              icon: "success",
              type: "succes",
              });
            setTimeout(()=>{
              window.location.reload();
            },2000);
          }
          else{
            this.loadingBono = false;
            this.$swal({
              title: response.data.data,
              icon: "error",
              type: "danger",
              });
          }
        })
        .catch((e) => {
          this.loadingBono = false;
          console.warn(e);
        });
      }
      else{
        this.loadingBono = false;
        this.$swal({
          title: "El bono no puede ser mayor a el valor neto de la factura",
          icon: "error",
          type: "danger",
          }).then((result)=> {
          console.log(result);
        });
      }
    },
    toAntPay(){
     this.selected;
     this.typePay = 2;
     this.dialog=true;
    },
    history(){
     this.dialog3=true;
    },
    videoShow(){
      this.dialog4=true;
    },
    close() {
      this.dialog = false;
      this.bank = null;
    },
    close3() {
      this.dialog3 = false;
    },
    close4() {
      this.dialog4 = false;
    },
    close5() {
      this.dialogPayPal = false;
      window.location.reload();
      //$('.paypal-button').prop("disabled",true);
    },
    alertAmount(){
      this.inputAmout = false;
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    format(value) {
      const result = Number(value).toFixed(2);
      console.log(result);
      return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    formatPrice(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  
    getParam1(){
      
      let token = this.$route.query.token;
      let type_bank = 1;
      this.sendToken(token, type_bank);
    },
    getParam2(params1,params2,params3){
      this.$swal({
            title: params1,
            text:params2+" "+params3,
            icon: params1 == "Rechazado" ? "warning":"success",
            type: params1 == "Rechazado" ? "warning":"success",
          }).then((result)=> {
            if(result.value){
              this.$router.replace({'query': null});
              location.reload();
        }           
    });
    },
    getParam3(){
      
      let token = this.$route.query.strResponse;
      let type_bank = 3;
      this.sendToken(token, type_bank);
    },
    sendToken(token, bank) {
    if(token.length != 0 ){
      axios
        .post("getToken", {
          token: token,
          bank: bank,
        })
        .then((response) => {
          console.log(response.data.FNMSG);
          this.$swal({
            title: response.data.FNMSG,
            icon: "success",
            type: "succes",
          }).then((result)=> {
                  if(result.value){
                    this.$router.replace({'query': null});
                    location.reload();
                  }                  
                });
        })
        .catch((error) => {
          if (error.response.data.errors) {
            this.msg = error.response.data.errors;
            this.hasError = true;
            this.snackbar = true;
            setTimeout(() => {
              this.hasError = false;
            }, 8000);
          } else {
            //alert(error);
            console.log(error);
          }
        })
        .finally(() => (this.loading = false));
      }
    },
    enviar(e){
      e.preventDefault();
      this.$refs.form1.submit();
    },
  },
};
</script>

<style>
.list-hide-disabled {
  display: none !important
}
</style>