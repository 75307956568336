<template>
  <div
    class="w-full d-flex justify-space-between align-center rounded shadow-in m-1 p-1"
  >
  <div style="width: 70px" class="mr-1">
                  <img class="w-full" :src="src" alt="" />
                </div>
   
    <div class="flex-grow-1 mx-2">
      <p class="text-left">{{ item.name }}</p>
      <div class="text-left">
        <span class="">SKU {{ item.sku }}</span>
        
      </div>
    </div>
    <div class="d-flex justify-center align-center" style="height: 20px">
     <!--<div class="shadow-in px-2 py-2 mr-2 rounded">
        <input
          type="checkbox"
          :value="item.materialCode"
          :checked="checked"
          @change="toggleCheckbox"
        />
      </div>--> 
      <v-btn
            small
            class="button-green text-white font-weight-black py-5"
            rounded-md
            :value="item.materialCode"
            :checked="checked"
            @click="toggleCheckbox(src)"
          >
            <span>Agregar</span>
            
          </v-btn>
    </div>
  </div>
</template>

<script>
  import axios from "@/plugins/axios";
import { mapActions } from "vuex";
//import generalMixin from "@/mixins/general";
export default {
  name: "ResultItem",
  props: ["item"],
 // mixins: [generalMixin],
  data: () => ({
    src: null,
    checked: false,
  }),
  mounted() {
    this.loadImage();
  },
  methods: {
    ...mapActions({
      getImageUrl: "quotecart/getImageUrl",
    }),
    loadImage() {
      if (!this.item || this.item.materialCode == null) {
        return;
      }
      this.getImageUrl({ codigo_material: this.item.materialCode })
        .then((imagePath) => {
          this.src = imagePath;  
        })
        .catch((error) => {
          console.log(error);
        });
    },
    toggleCheckbox(urlimage="") {
      this.checked = !this.checked;
      this.$emit('checkboxtoggle', { checked: this.checked, item: this.item ,url :urlimage});
      console.log('checkboxtoggle', { checked: this.checked, item: this.item , url :urlimage})
    },
    getImge(item) {
     // this.warnDialogLoading(true);
      axios
        .get("https://bancodeimagenes.iusa.com.mx/api/listDropboxNew?code="+item.sku+"&quant=1&view=0", {
          headers: {
            'Content-Type':'application/json',
            'api-key':'$2y$10$PtV2yrGrt/CwoVlqGDroiOyoUSq581Pq.6NVyAX2vQJUBdE21gxKC',
            'Authorization':'Bearer token',
          }
        })
        .then((response) => {
          if(response.data[0].path) {
            let index = this.cart.findIndex(element => element.codigo_material ==item.sku);
  
            this.cart[index].image_url = response.data[0].path
            console.log('index', index);
          }
          console.log('dropbox', response.data[0].path);
          //this.validacionCredicticia();
          //this.warnCart(this.cart);
        })
    },
  },
};
</script>

<style scoped>
.shadow-in {
  box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
}
.button-green {
  background: transparent
    linear-gradient(180deg, var(--success-hover-218838) 0%, #11441c 100%) 0% 0%
    no-repeat padding-box;
  background: transparent linear-gradient(180deg, #218838 0%, #11441c 100%) 0%
    0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
  border: 1px solid #707070;
}
</style>
