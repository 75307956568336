<template>
    <v-dialog :value="showModal" @input="updateShowModal" max-width="600px">
      <v-card>
        <v-card-text>
          <slot></slot>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#FF0000" style="color: white;" @click="closeModal">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    name: 'NotificationModalContainer',
    props: {
      showModal: Boolean,
    },
    methods: {
      updateShowModal(value) {
        this.$emit('update:showModal', value);
      },
      closeModal() {
        this.$emit('close');
      },
    },
  }
  </script>