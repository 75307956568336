<template>
  <div>
    <v-container fluid class="white lighten-5 py-7" ref="printReport">
      <v-card class="pa-5 mb-0 ma-5 card-shadow" rounded="0" v-if="currentLevelLabel && currentLevelMatch(['family', 'material'])">
        <h2 class="text-h5 ma-0 text-uppercase font-weight-bold">{{ currentLevelLabel }}</h2>
      </v-card>

      <v-card class="pa-5 my-0 ma-5 card-shadow" rounded="0">
        <v-row no-gutters>
          <v-col cols="9" class="d-flex gap-2 align-center">
            <v-btn class="hide-on-print" icon color="black" @click="prevLevel()" :disabled="currentLevelIndex <= 0">
              <v-icon>mdi-arrow-u-left-top</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="3" class="d-flex justify-end hide-on-print">
            <v-icon color="black" size="30px">mdi-book</v-icon>
           
          </v-col>
        </v-row>

<div class="row justify-center">
<div class="box consulta1">
  <div class="title" style="background-color: #D7263D;">{{ boxes[ 4].title }}</div>
  <div class="align-center" style="margin-top: 1.3cm;">
<v-img
  width="45"
  src="img/logo_iusa.svg"
  class="mt-n10"
  style="display: inline-block; margin-right: 0.2cm;"
></v-img>
<a href="" target="_blank" style="text-decoration: underline; color:black; display: inline-block;">
  {{ boxes[2].text }}
</a>
</div>

</div>
<div class="box consulta2">
  <div class="title" style="background-color: #DE639A;">{{ boxes[5].title }}</div>
  <div class="align-center" style="margin-top: 1.3cm;">
<v-img
  width="45"
  src="img/logo_iusa.svg"
  class="mt-n10"
  style="display: inline-block; margin-right: 0.2cm;"
></v-img>
  <a href="" target="_blank" style="text-decoration: underline; color:black ">{{ boxes[3].text }}</a>
  </div>
</div>

<div class="box vinculo3">
  <div class="title" style="background-color: #F9BE00;">{{ boxes[6].title }}</div>
  <div class="align-center" style="margin-top: 1.3cm;">
<v-img
  width="45"
  src="img/logo_iusa.svg"
  class="mt-n10"
  style="display: inline-block; margin-right: 0.2cm;"
></v-img>
<a href="" target="_blank" style="text-decoration: underline; color:black; display: inline-block;">
  {{ boxes[6].text }}
</a>
</div>

</div>
</div>

<div class="row justify-center">


</div>
      </v-card>
    </v-container>
  </div>
</template>

<script>

  import Document from '@/mixins/document';
  import Service from '@/mixins/service';
  import { mapActions } from "vuex";

  export default {
    name: 'Vinculos',

    components: {

    },

    mixins: [Document, Service],

    data() {
      return {
        boxes: [
          { title: 'Reporte de Backorder', text: ' \u0020 IR A VÍNCULO' },
          { title: 'Reporte pedidos pendientes SAP', text: ' \u0020 IR A VÍNCULO' },
          { title: 'Reporte de Backorder cancelados', text: '     IR A VÍNCULO' },
          { title: 'Reporte precios X Ofi. Cite. GMat4', text: '    IR A VÍNCULO' },
          { title: 'Pólitica de Entrega', text: '    IR A VÍNCULO' },
          { title: 'Pólitica de crédito', text: '    IR A VÍNCULO' },
          { title: 'Pólitica de autos', text: '    IR A VÍNCULO' },
          { title: '', text: '  IR A VÍNCULO' },
        ]
      };
    },
    mounted() {
    this.sendToPDF({ active: true });
  },

  methods: {
    ...mapActions({
      sendToPDF: 'printer/sendToPDF'
    }),
    }
  };
</script>

<style scoped>
.box {
width: 320px;
height: 200px;
margin: 30px;
border-radius: 8px;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
text-align: center;
}

.title {
height: 40%;
color: white;
display: flex;
align-items: center;
justify-content: center;
}

.content {
height: 60%;
padding: 10px;
}


  /* Cambié las clases vinculo1, vinculo2, etc. a minúsculas para que coincidan con el HTML */
</style>
