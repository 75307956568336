<template>
	<div class="container-fluid grey lighten-5 pt-4">

        <v-row>
            <v-col md="6" offset="6">
                <router-link :to="{ name: 'ContactCreate' }" class="nav-link"><v-btn color="primary">Crear Contacto</v-btn></router-link>
            </v-col>
        </v-row>
		
		<br>
		<card>
			<h2 class="h2">Contactos</h2>
            <div class="alert alert-danger" role="alert" v-if="hasError" style="font-weight: 500">{{ msg }}</div>
            <v-progress-linear :active="loading" :indeterminate="loading" top color="deep-purple accent-4"></v-progress-linear>
			<div class="card-body text-lg-center" v-if="dataContact.length > 0">
				
				<v-data-table
					:headers="headerContact"
					:items="dataContact"
					:items-per-page="10"
					item-key="DESCR"
					class="elevation-1"
					locale="esp"
					:footer-props="{
						showFirstLastPage: true,
						firstIcon: 'mdi-arrow-collapse-left',
						lastIcon: 'mdi-arrow-collapse-right',
						prevIcon: 'mdi-minus',
						nextIcon: 'mdi-plus',
					}"></v-data-table>
			</div>
		</card>
	</div>
  </template>
  
  <script>
  // @ is an alias to /src
  import axios from "@/plugins/axios";
  export default {
    name: "ContactList",
    components: {},
    data() {
      return {
        dataContact: [],
        loading: true,
        msg: null,
        hasError: false,
        timeout: 8000,
		headerContact:[
			{ text: "NOMBRE", value: "name" },
			{ text: "APELLIDO", value: "last_name" },
			{ text: "EMAIL", value: "email" },
            { text: "TIPO DE CONTACTO", value: "contactType" },
			{
			text: "TELÉFONO",
			value: "phone",
			},
			{
			text: "WHASSAP",
			value: "whassap",
			},
			{ text: "FOTO", value: "photo" },
			{ text: "FECHA DE CREACIÓN", value: "created_at" },
		]
      };
    },
    methods: {
		getContact() {
			this.loading = true;
		
			axios
			.post("contact/list")
			.then((response) => {
				
                if( response.data.result == 1 ){

                    this.dataContact = response.data.data;
                }
                else{
                    alert( "ocurrio un error inesperado" );
                }
			})
			.catch((error) => {
				if (error.response.data.errors) {
				this.msg = error.response.data.errors;
				this.hasError = true;
				setTimeout(() => {
					this.hasError = false;
				}, 8000);
				} else {
				alert(error);
				}
			})
			.finally(() => (this.loading = false));
		}
    },
	mounted(){
		this.getContact();
	}
  };
</script>
  
 <style scoped>
	.label {
		font-weight: 700;
	}
</style>