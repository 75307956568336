<template>
  <div class="container-fluid">
    <CardTitle title="INDICADORES DE CRECIMIENTO" icon="arrow-top-left-thin-circle-outline">
      <template v-slot:content>
        <v-card class="mx-auto elevation-5 " outlined>
          <div>
            <div class="elevation-5">
              <div class="card-body text-lg-center">
                <v-progress-linear v-if="loading" :indeterminate="loading" color="cyan"></v-progress-linear>
                <v-simple-table dense class="" v-if="!loading">
                  <template v-slot:default>
                    <thead>
                        <tr class=" text-black custom-body-hs font-size-x  d-sm-none">
                          <td  colspan="4">Crecimiento acumulado año actual vs historico</td>
                      </tr>
                      <tr class=" text-black custom-body-hs font-size-x">
                        <td class="box-shadow d-none d-sm-table-cell"></td> 
                        <td v-if="showQuarter3" class="box-shadow">{{ cumulativeData.TRIM3 }}</td>
                        <td class="box-shadow">{{ cumulativeData.TRIM2 }}</td>
                        <td class="box-shadow">{{ cumulativeData.TRIM1 }}</td>
                      </tr>
                    </thead>
                 <tbody>
                      <tr class=" no-border custom-body-hs font-size-x">
                        <td class="d-none d-sm-table-cell">Crecimiento acumulado año actual vs historico</td>   
                        <td v-if="showQuarter3" ><a class="text-black text-decoration-underline"
                            @click="getCumulativeClientGrowth(3, 1)">{{ numberFormat(cumulativeData.ACUM3-100) }}%</a></td>
                        <td><a class="text-black text-decoration-underline"
                            @click="getCumulativeClientGrowth(2, 1)">{{ numberFormat(cumulativeData.ACUM2-100) }}%</a></td>
                        <td><a class="text-black text-decoration-underline"
                            @click="getCumulativeClientGrowth(1, 1)">{{ numberFormat(cumulativeData.ACUM1-100) }}%</a></td>
                      </tr>
                    </tbody> 
                  </template>
                </v-simple-table>
               
              <!--   <v-simple-table dense v-if="!loading">
                  <template v-slot:default>
                    <thead>  
                      <tr class=" text-black custom-body-hs font-size-x ">
                        <td class="box-shadow  "></td>
                        <td class="box-shadow">{{ cumulativeDataDetails.TRIM1 }} * </td>
                        <td class="box-shadow">{{ cumulativeDataDetails.TRIM2 }}</td>
                        <td class="box-shadow">{{ cumulativeDataDetails.TRIM3 }}</td> 
                      </tr>
                    </thead>
                   
                    <tbody>
                      <tr class=" no-border custom-body-hs font-size-x">
                        <td class="d-none d-sm-table-cell" style="width: 42%;">Crecimiento últimos 3 meses vs
                          mismos meses del año anterior</td>
                        <td><a class="text-black text-decoration-underline"
                            @click="getCumulativeClientGrowth(1, 2)">{{ numberFormat(cumulativeDataDetails.ACUM1-100) }}%</a>
                        </td>
                        <td><a class="text-black text-decoration-underline"
                            @click="getCumulativeClientGrowth(2, 2)">{{ numberFormat(cumulativeDataDetails.ACUM2-100) }}%</a>
                        </td>
                        <td><a class="text-black text-decoration-underline"
                            @click="getCumulativeClientGrowth(3, 2)">{{ numberFormat(cumulativeDataDetails.ACUM3-100) }}%</a>
                        </td>
                        <td><a class="text-black text-decoration-underline"
                            @click="getCumulativeClientGrowth(4, 2)">{{ numberFormat(cumulativeDataDetails.ACUM4-100) }}%</a>
                        </td>
                      </tr>
                    </tbody> 
                  </template>
                </v-simple-table>
               -->
              </div>
            </div>
          </div>
        </v-card>
      </template>
    </CardTitle>
    <v-dialog v-model="showCumulativeClientGrowth" persistent width="auto">
      <v-card>
        <ComulativeClientGrowth title="" :type="indicatorType" :year="indicatorYear" :quarter="indicatorQuarter"
          :customer="customerDetails.customer_code" />
        <div class=" justify-end pa-2">
          <v-btn outlined color="success" @click="showCumulativeClientGrowth = false">
            Cerrar
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import CardTitle from '@/components/CardTitle.vue'
import ComulativeClientGrowth from './ComulativeClientGrowth.vue';
import axios from "@/plugins/axios";
import { mapGetters } from "vuex";
import moment from 'moment';
export default ({
  props: {
    generalInfoCustomer: { //para evaluacion PNC y REORDEN DE PROD.
            default: []
        }, 
  },
  watch:{
    generalInfoCustomer(){
      if (this.$hasAnyRole(['Customer','CustomerPR'])){
        this.customerDetails = {
      customer_code : this.user.custumer_code,
      channel : this.user.VTWEG,
      business: this.user.VKORG
    }
    } else { 
    const currentCustomer = JSON.parse(localStorage.getItem('currentCustomer')) || [];
    if (!currentCustomer) {
      this.$router.push({name:'Home'})
    }
    this.customerDetails = {
      customer_code : this.transformCustomerCode(this.generalInfoCustomer.KUNNR),
      channel : this.generalInfoCustomer.VTWEG,
      business: this.generalInfoCustomer.VKORG
    }   
    }  
    this.loadCumulativeGrowthIndicators()
   
    }
  },  
  data() {
    return {
      showCumulativeClientGrowth: false,
      cumulativeData: {},
      cumulativeDataDetails: {},
      loading: true,
      indicatorQuarter: 0,
      indicatorYear: 2023,
      indicatorType: 0,
      customerDetails:{}
    }
  },
  name: 'GrowthIndicators',
  components: {
    CardTitle,
    ComulativeClientGrowth
  },
  mounted() {
    
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    showQuarter3(){
      const year = moment().format('YYYY');
      return year>2024;
    }
  },
  methods: {
    transformCustomerCode(code) {
    // Utiliza expresiones regulares para eliminar los ceros a la izquierda
    return code.replace(/^0+/, '');
  },
    loadCumulativeGrowthIndicators() {
      this.loading = true;
      const params = {
        customer_id: this.customerDetails.customer_code,
      }
      axios.post("/cover/cumulative-growth-indicators", params)
        .then((response) => {
          this.cumulativeData = response.data;
          this.loading=false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        }).finally(() => {
            // this.growthIndicators();
        });
    },
    getCumulativeClientGrowth(quarter, type) {
      this.indicatorQuarter = quarter;
      this.indicatorType = type;
      this.showCumulativeClientGrowth = true;
    },
    numberFormat(number = 0) {
      let result = Number(number).toFixed(2);
      return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
  /*  growthIndicators() {
      this.loading = true;
      const params = {
        customer_id: this.customerDetails.customer_code,
      }
      axios.post("/cover/growth-indicators", params)
        .then((response) => {
          this.cumulativeDataDetails = response.data;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        }).finally(() => {
          this.loading = false;
        });
    } */
  }
})
</script>

<style src="@/assets/css/customerHome.css" scoped></style> 