<template>
  <v-toolbar v-if="authenticated && showCustomerMenu()" :style="backgroundColor" elevation="5" class="lifeLine--toolbar over">
    <v-slide-group
      class="full-width"
      :show-arrows="!hideArrows"
    >
      <v-row dense no-gutters :key="$route.path">
        <!-- Admin paths -->
        <v-col :cols="false" md="auto" v-if="$hasAnyRole(['Administrator'])">
          <v-slide-item>
              <v-btn color="transparent" elevation="0" :to="{ name: 'Administrator' }">
                <v-icon class="mr-1" color="white">mdi-account-supervisor-outline</v-icon>
                <label class="text-white ml-2">Administrador</label>
              </v-btn>
          </v-slide-item>
        </v-col>

        <!-- All paths -->
        <v-col :cols="false" md="auto" v-for="(menuParent, index) in filteredPaths" :key="index">
          <v-slide-item>
            <v-menu offset-y rounded="0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="transparent" class="mx-0" elevation="0" v-bind="attrs" v-on="on">
                  <v-icon class="mr-md-1" color="white">{{ menuParent.icon }}</v-icon>
                  <label class="text-white d-none d-md-block text-ellipsis cursor-pointer">{{ menuParent.text }}</label>
                </v-btn>
              </template> 
              <sub-menu :items="menuParent.menu" :menu-text="menuParent.text" />
            </v-menu>
         </v-slide-item>
        </v-col>

        <!-- Customer paths -->
        <v-col :cols="false" md="auto" v-if="showGuestRegister">
          <v-slide-item>
            <v-btn color="transparent" elevation="0" :to="{ name: 'CustomerRegistration' }">
              <v-icon class="mr-1" color="white">mdi-bookmark-check</v-icon>
              <label class="text-white d-none d-md-block">Solicitud Alta</label>
            </v-btn>
         </v-slide-item>
        </v-col>
      </v-row>
    </v-slide-group>
  </v-toolbar>
</template>

<script>
import { mapGetters } from "vuex";
import { menuDropdown } from "./menuDropDown";
import SubMenu from "./SubMenu.vue";

export default {
  name: 'CustomerMenu',

  components: { SubMenu },

  data() {
    return {
      pathRoles: {
        DT: {
          color: "red",
          text: "Minorista",
        },
        PR: {
          color: "#0d6efd",
          text: "Distribuidor",
        },
      },
    };
  },

  methods: {
    getFilteredSubmenu(submenu) { // Menus can have sub menus, and submenus more submenus, we filter the paths recursively
      if(!submenu) return ;

      const filteredItems = [];

      submenu.forEach(m => {
        const valid = this.validateMenuRoleChannel(m);

        if(!valid) return;

        if(!m.menu){
          filteredItems.push(m);
          return 
        }
        
        const menu = this.getFilteredSubmenu(m.menu);
        filteredItems.push({ ...m,  menu });

      })
      return filteredItems;
    },

    getFilteredMenu(menu) {
      return menu.filter(m => {
        return this.validateMenuRoleChannel(m);
      })
    },

    validateMenuRoleChannel(menu){
      const showIfhas = menu.show === undefined ? true : menu.show;

      if(menu.channels){
        const hasAnyChannel = this.$hasAnyChannel(menu.channels);

        if(!this.filterMenuItem(hasAnyChannel, showIfhas))
          return false;
      }

      if(menu.roles || menu.notRoles){
        const hasAnyRole = menu.notRoles ? !this.$hasAnyRole(menu.notRoles) : this.$hasAnyRole(menu.roles);

        if(!this.filterMenuItem(hasAnyRole, showIfhas))
          return false;
      }

      return true;
    },

    filterMenuItem(hasChannelRole, show){
      if((show && hasChannelRole) || (!show && !hasChannelRole))
        return true;

      return false;
    },

    showCustomerMenu() {
      if(this.$route.meta.validateRole) {
        return this.$hasAnyRole(this.$route.meta?.roles || []) && this.$route.meta.role == "Customer";
      }
      else {
        return this.$route.meta.role == "Customer";
      }
    },
  },

  computed: {
    ...mapGetters('auth', ['authenticated', 'channel',  'user']),

    backgroundColor() {
      return "background-color:" + this.pathRoles[this.channel].color;
    },

    filteredMenu(){
      return !this.userIsGuest ? this.getFilteredMenu(menuDropdown) : [];
    },

    filteredPaths(){
      return this.filteredMenu.map(subMenu => {
        return {
          ...subMenu,
          menu: this.getFilteredSubmenu(subMenu.menu),
        }
      })
    },

    userIsGuest(){
      return this.$hasAnyRole(['Guest']);
    },

    showGuestRegister(){
      return this.userIsGuest && this.user.request_status != 'Aceptada' && this.user.request_status != 'Rechazada'
    },

    hideArrows() {
      return this.$vuetify.breakpoint.name == 'xs';
    }
  },
};
</script>

<style>
.lifeLine--toolbar.v-toolbar.v-toolbar,
.lifeLine--toolbar.v-toolbar .v-toolbar__content {
  height: auto !important;
}
</style>

<style scoped>
.full-width {
  width: 100%;
}

/* I have to do this because in CustomerProducts styles where applied without being scoped */
::v-deep .v-slide-group__next, ::v-deep .v-slide-group__prev {
  position: static;
  height: auto;
}

::v-deep .v-slide-group__prev > i, ::v-deep .v-slide-group__next > i {
  font-weight: 900 !important;
  font-size: 24px !important;
}

::v-deep .theme--light.v-icon.v-icon.v-icon--disabled {
  color: rgba(0,0,0, .87);
}

</style>
