<template>
  <div v-if="cart.length != 0">
      <!--<div class="text-ped  ml-2" style="text-align: left;">
          Detalle Compra
      </div>
      <div class="text-ped  ml-2" style="text-align: right;">
        Selccionar compra</div>
      <br>-->
      <v-row>
      <v-col md="4">
        <div class="text-ped  ml-2"  style="text-align: left;">
          Detalle Compra
        </div>
      </v-col>
      <v-col md="8">
        <v-row>
          <v-col md="3">
            <div class="text-ped  ml-2 mt-5"  style="text-align: right;" v-if="quoter_type == '2'">
             Seleccionar Incremento: 
          </div>
          </v-col>
          <v-col md="9"> 
          <div class="text-ped ml-2"  style="text-align: right;">
           <v-radio-group v-model="radio_percent" row v-if="quoter_type == '2'">
            <v-radio color="success" label="S/N" v-model="radio1"  v-on:change="getMaterialIncrease(radio1)"></v-radio>
            <v-radio color="success" label="10 % de Incremento" v-model="radio10"  v-on:change="getMaterialIncrease(radio10)"></v-radio>
            <v-radio color="success" label="20 % de Incremento" v-model="radio20"  v-on:change="getMaterialIncrease(radio20)"></v-radio>
            <v-radio color="success" label="30 % de Incremento" v-model="radio30"  v-on:change="getMaterialIncrease(radio30)"></v-radio>
            <v-radio color="success" label="40 % de Incremento" v-model="radio40"  v-on:change="getMaterialIncrease(radio40)"></v-radio>
          </v-radio-group>
        </div>
      </v-col>
        </v-row>
      </v-col>
      <v-dialog v-model="quantityDialog.show" persistent max-width="700">
      <v-card>
        <v-card-title>
          <span class="text-h5">Editar cantidad</span>
        </v-card-title>
        <v-card-text>
          <div class="d-flex align-items-baseline">
            <span class="w-30 text-left font-weight-bold font-size-9"
              >Producto:</span
            >
            <v-text-field
              dense
              solo
              class="text-ped pt-0 mt-0 w-40"
              required
              v-model="quantityDialog.name"
            ></v-text-field>
          </div>
          <div class="d-flex align-items-baseline">
            <span class="w-30 text-left font-weight-bold font-size-9"
              >Nueva cantidad:</span
            >
            <v-text-field
              dense
              solo
              class="text-ped pt-0 mt-0 w-40"
              required
              type="number"
              min="1"
              v-model="quantityDialog.quantity"
            ></v-text-field>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="cancelQuantity">Cancelar</v-btn>
          <v-btn color="primary" @click="saveQuantity">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

      </v-row>
      <v-row>
        <v-col cols="12">
         <!--<table class="table">-->
          <v-simple-table >
            <thead class="table-header">
            <tr>
              <th class="table-header text-center">Producto</th>
              <th class="table-header text-center">Genética</th>
              <th class="table-header text-center">Cantidad</th>
              <th class="table-header text-center">Empaque</th>
              <th class="table-header text-center">
                Unidad de medida de venta
              </th>
              <th class="table-header text-center">Existencia</th>
              <th class="table-header text-center">
                Promedio mensual de <br/> compra*
              </th>
              <th class="table-header text-center">
                Días solicitados de inventario*
              </th>
              <th class="table-header text-center">Precio Descuento</th>
              <th class="table-header text-center">Importe Total</th>
              <th class="table-header"></th>
            </tr>
          </thead>
              <tbody>
                  <tr v-for="(item, i) in cart" :key="i" style="font-size: 12px;">
                    <td class="h-auto d-flex flex-nowrap align-center">
                <div style="width: 70px" class="mr-1">
                  <v-img :src="item.image_url" v-if="item.image_url != ''" width="100"
                              @click="seeImageDetail(item)" class="imageCarItem" />
                </div>
                <div class="d-flex flex-column">
                  <span class="text-left font-size-8"
                    > {{ item.nombre_material }}
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          :class="item.validacion"
                          v-bind="attrs"
                          v-on="on"
                          >mdi-alert-circle
                        </v-icon>
                      </template>
                      <span>{{ item.stock_label }}</span>
                    </v-tooltip>
                  </span>
                </div>
              </td>
              <!--
                      <td style="text-align: start;">

                          <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                  <v-icon :class="item.validacion" v-bind="attrs" v-on="on">mdi-alert-circle
                                  </v-icon>
                              </template>
                              <span>{{ item.stock_label }}</span>
                          </v-tooltip>
                          <strong>{{ item.codigo_material }}</strong><br>
                         
                          <span v-for="(item1, j) in item.array_doc" :key="j">
                              <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                      <a :href="item1.path" target="_blank">
                                          <v-icon v-bind="attrs" v-on="on" class="Parcial">mdi-folder</v-icon>
                                      </a>
                                  </template><span>{{ item1.name }}</span>
                              </v-tooltip>
                          </span>
                          <span>
                            <a @click="getFileDownload(item.codigo_material)" target="_blank">
                              <v-icon v-on="on" class="Parcial">mdi-folder</v-icon>
                            </a>
                          </span>
                          

                          <v-tooltip bottom v-if="item.PNC != 0">
                              <template v-slot:activator="{ on, attrs }">
                                  <v-chip v-bind="attrs" v-on="on" color="success"
                                      style="font-size:10px;">PNC</v-chip>
                              </template><span>PNC</span>
                          </v-tooltip>

                         <v-tooltip bottom v-if="item.DM != 0">
                              <template v-slot:activator="{ on, attrs }">
                                  <v-chip v-bind="attrs" v-on="on" color="success" style="font-size:10px;">DM</v-chip>
                              </template><span>DM</span>
                          </v-tooltip>

                          <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                  <v-chip v-bind="attrs" v-on="on" color="primary" @click="descuentosInfo(item)" style="font-size:10px;">DES
                                  </v-chip>
                              </template><span>Ver Descuentos</span>
                          </v-tooltip> 

                          <v-tooltip bottom v-if="item.ZKVL != 0">
                              <template v-slot:activator="{ on, attrs }">
                                  <v-chip v-bind="attrs" v-on="on" color="primary"
                                      style="font-size:10px;">ZKVL</v-chip>
                              </template><span>Des. Volumen= {{ seePorcent(item.ZKVL) }}%</span>
                          </v-tooltip>

                          <v-tooltip bottom v-if="item.ZKRF != 0">
                              <template v-slot:activator="{ on, attrs }">
                                  <v-chip v-bind="attrs" v-on="on" color="primary"
                                      style="font-size:10px;">ZKRF</v-chip>
                              </template><span>Des. Rengl. Famila= {{ seePorcent(item.ZKRF) }}%</span>
                          </v-tooltip>

                          <v-tooltip bottom v-if="item.ZKRG != 0">
                              <template v-slot:activator="{ on, attrs }">
                                  <v-chip v-bind="attrs" v-on="on" color="primary"
                                      style="font-size:10px;">ZKRG</v-chip>
                              </template><span>Des Rengl. Material={{ seePorcent(item.ZKRG) }}%</span>
                          </v-tooltip>

                          <v-tooltip bottom v-if="item.ZK14 != 0">
                              <template v-slot:activator="{ on, attrs }">
                                  <v-chip v-bind="attrs" v-on="on" color="primary"
                                      style="font-size:10px;">ZK14</v-chip>
                              </template><span>Descto. Promocion={{ seePorcent(item.ZK14) }}%</span>
                          </v-tooltip>

                          <v-tooltip bottom v-if="item.ZK25 != 0">
                              <template v-slot:activator="{ on, attrs }">
                                  <v-chip v-bind="attrs" v-on="on" color="primary"
                                      style="font-size:10px;">ZK25</v-chip>
                              </template><span>Descto. Cte /Mat.={{ seePorcent(item.ZK25) }}%</span>
                          </v-tooltip>

                          <v-tooltip bottom v-if="item.ZK69 != 0">
                              <template v-slot:activator="{ on, attrs }">
                                  <v-chip v-bind="attrs" v-on="on" color="primary"
                                      style="font-size:10px;">ZK69</v-chip>
                              </template><span>Descto. Cte/Gpo.Mat4. ={{ seePorcent(item.ZK69) }}%</span>
                          </v-tooltip>

                          <v-tooltip bottom v-if="item.ZK71 != 0">
                              <template v-slot:activator="{ on, attrs }">
                                  <v-chip v-bind="attrs" v-on="on" color="primary"
                                      style="font-size:10px;">ZK71</v-chip>
                              </template><span>Dcto.OR.CA.GCTE.GMAT. ={{ seePorcent(item.ZK71) }}%</span>
                          </v-tooltip>

                          <v-tooltip bottom v-if="item.MVGR5_POR != 0">
                              <template v-slot:activator="{ on, attrs }">
                                  <v-chip v-bind="attrs" v-on="on" color="error"
                                      style="font-size:10px;">{{ item.MVGR5 }}
                                  </v-chip>
                              </template><span>{{ item.MVGR5_POR }} %</span>
                          </v-tooltip>
                      </td> -->
                      <td style="width:15px;">
                        {{ item.material_type }}
                    </td>

                  <!--  <td class="text-center">
                <a class="text-black" @click="editQuantity(item)">
                  <span class="font-size-8"
                    >{{ formatInt(item.u_confirm) }}
                    {{
                      item.recordatorios != 0
                        ? `BACKORDER ${item.recordatorios}`
                        : ""
                    }}</span
                  >
                </a>
              </td> -->
              <td class="text-center">
                <a class="text-black" @click="editQuantity(item)">
                  <span class="font-size-8"
                    >{{ formatInt(item.u_confirm) }}
                    {{
                      item.recordatorios != 0
                        ? `BACKORDER ${item.recordatorios}`
                        : ""
                    }}</span
                  >
                </a>
              </td>
              <td>
                <span class="font-size-8">{{ item.empaque }}</span>
              </td>
              <td>
                <span class="font-size-8">{{ item.unidad_medida }}</span>
              </td>
              <td>
                <span class="font-size-8">{{ format(item.existencia) }}</span>
              </td>
                      <!--<td> {{ format(item.existencia_cdpt) }}</td>-->
                      <!--<td>
                          <p class="pa-lg-2" style="font-size: 12px">

                              <v-icon slot="prepend" color="green" @click="materialChageMinus(item, item.INN, i)">
                                  mdi-minus
                              </v-icon>
                              <v-icon slot="append" color="red" @click="materialChagePlus(item, item.INN, i)">
                                  mdi-plus
                              </v-icon>
                              <br>
                              <button class="btn INN">{{ item.INN }}</button>
                              
                          </p>
                      </td>
                      <td v-if="item.MST != ''">
                          <p class="pa-lg-2" style="font-size: 12px">

                              <v-icon slot="prepend" color="green" @click="materialChageMinus(item, item.MST, i)">
                                  mdi-minus
                              </v-icon>
                              <v-icon slot="append" color="red" @click="materialChagePlus(item, item.MST, i)">
                                  mdi-plus
                              </v-icon>
                              <br>
                              <button class="btn MST">{{ item.MST }}</button>
                          </p>
                      </td>
                     <td v-if="item.MST == ''"></td>-->
                      <td style="width:15px;">
                          {{ item.PMCOM }}
                      </td>
                      <td style="width:15px;">
                          {{ item.DSINV }}
                      </td>
                     <!-- <td v-if="quoter_type == '1'">
                          $ {{ item.precio_lista }}
                      </td>-->
                      <td>
                        $ {{parseFloat(item.precio_con_descuento).toFixed(2)}}
                      </td>
                      <!--<td style="width:90px;">
                          <v-text-field v-model="item.precio_con_descuento"
                              @keydown.enter.prevent="getMaterialPrice(item, item.u_confirm, item.precio_con_descuento, i)">
                          </v-text-field>
                          <br/>
                      </td>-->
                      <td>
                          <strong>$ {{
                                  parseFloat(item.importe_producto).toFixed(2)
                          }} </strong>
                      </td>
                      <td>
                       <button class="icon-button" @click="deleteItem(i, item)">
                        <img src="/img/trash-can-outline.svg" alt="" />
                       </button>
                      </td>
                      <!--<td>
                          <v-btn style="margin-left:10px;" dark rounded color="red darken-2" elevation="2" small
                              @click="deleteItem(i, item)">
                              <v-icon style="color: #fff !important">mdi-delete</v-icon>
                          </v-btn>
                      </td>-->
                  </tr>
              </tbody>
          <!--</table>-->
          </v-simple-table>
        </v-col>
      </v-row>

      <!-- Dialog loading -->
      <v-row justify="center">
          <v-dialog v-model="dialogCatalogo" hide-overlay width="600" transition="dialog-bottom-transition"
              justify="center">
              <v-card justify="center" v-if="itemSelect">
                  <div class="text-ped" style="text-align: center;">
                      Estadistica de Producto
                  </div>
                  <v-avatar class="ma-3" size="100" tile>
                      <v-img :src="itemSelect.image_url" v-if="itemSelect.image_url != ''" width="100"
                          class="imageCarItem" />
                      <v-img src="img/img-default.svg" v-if="itemSelect.image_url == ''" width="100"
                          class="imageCarItem" />

                  </v-avatar>
                  <v-card-text>
                      <strong>Material: {{ itemSelect.codigo_material }} -
                          {{ itemSelect.nombre_material }}</strong><br>
                      <table class="table">
                          <thead>
                              <tr>
                                  <th>Jul22</th>
                                  <th>Ago22</th>
                                  <th>Sep22</th>
                                  <th>Oct22</th>
                                  <th>Nov22</th>
                                  <th>Dic22</th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr>
                                  <td>{{ product_statistics.MONT6 }}</td>
                                  <td>{{ product_statistics.MONT5 }}</td>
                                  <td>{{ product_statistics.MONT4 }}</td>
                                  <td>{{ product_statistics.MONT3 }}</td>
                                  <td>{{ product_statistics.MONT2 }}</td>
                                  <td>{{ product_statistics.MONT1 }}</td>
                              </tr>
                          </tbody>
                      </table>
                  </v-card-text>
                  <v-card-actions class="justify-end">
                      <v-btn text @click="dialogCatalogo = false">Cerrar</v-btn>
                  </v-card-actions>
              </v-card>
          </v-dialog>
      </v-row>
      <!-- /. Dialog -->
      <!-- Dialog loading -->
      <v-row justify="center">
          <v-dialog v-model="dialogDescuentos" hide-overlay width="600" transition="dialog-bottom-transition"
              justify="center">
              <v-card justify="center" v-if="itemSelect">
                  <div class="text-ped" style="text-align: center;">
                      DESCUENTOS
                  </div>
                  <div class="col-12 row">
                      <div class="col-6">
                          <v-avatar class="ma-3" size="200" tile>
                              <v-img :src="itemSelect.image_url" v-if="itemSelect.image_url != ''" width="100"
                                  class="imageCarItem" />
                              <v-img src="img/img-default.svg" v-if="itemSelect.image_url == ''" width="100"
                                  class="imageCarItem" />
                          </v-avatar>
                      </div>
                      <div class="col-6">
                          <v-card-text>
                              <strong>{{ itemSelect.codigo_material }} -
                                  {{ itemSelect.nombre_material }}</strong><br>
                              <ul style="text-align: initial;">
                                  <li>
                                      ZKVL = <strong>{{ seePorcent(itemSelect.ZKVL) }} %</strong>
                                  </li>
                                  <li>
                                      ZKRF = <strong>{{ seePorcent(itemSelect.ZKRF) }} %</strong>
                                  </li>
                                  <li>
                                      ZKRG = <strong>{{ seePorcent(itemSelect.ZKRG) }} %</strong>
                                  </li>
                              </ul>
                          </v-card-text>
                      </div>
                  </div>
                  <v-card-actions class="justify-end">
                      <v-btn text @click="dialogDescuentos = false">Cerrar</v-btn>
                  </v-card-actions>
              </v-card>
          </v-dialog>
      </v-row>
      <!-- /. Dialog -->
     

  </div>
</template>
<script>
  import axios from "@/plugins/axios";
  import { mapGetters } from "vuex";
  export default {
  name: "ShoppingCart",
  components: {},
  computed: {
    ...mapGetters({
      user: "auth/user",
      statecart: "quotecart/cart",
      statesubtotal: "quotecart/subtotal",
      stateiva: "quotecart/iva",
      statetotal: "quotecart/total",
      increase: "quotecart/increase",
    }),
  },
  props: {
    warnTotal: {
      type: Function,
      default: null,
    },
    capturedMaterialItem: {
      type: Object,
      default: null,
    },
    warnError: {
      type: Function,
      default: null,
    },
    warnDialogLoading: {
      type: Function,
      default: null,
    },
    warnCart: {
      type: Function,
      default: null,
    },
    capturedIdOrder: {
      type: Number,
      default: null,
    },
    captureQuoter: {
      type: String,
      default: null,
    },
    captureRadioPercent: {
      type: Function,
      default: null,
    },
    captureRadioDataBase: {
      type: Number,
      default: null,
    },
    capturedDocType: {
        type: String,
        default: null,
    },
  },
  data: () => ({
    cart: [],
    subtotal: 0,
    iva: 0,
    total: 0,
    total2: 0,
    dialogLoading: false,
    dialogCatalogo: false,
    itemSelect: {},
    product_statistics: {},
    dialogDescuentos: false,
    order_id: null,
    ImageUrl:null,
    radio1:0,
    radio10:10,
    radio20:20,
    radio30:30,
    radio40:40,
    quoter_type: null,
    radio_percent: 0,
    doctype: null,
  }),
  async mounted() {
    console.log("This cart"+this.cart);
    this.captureRadioPercent(this.radio_percent);
  },
  methods: {
    formatPrice(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    format(value) {
      let result = Number(value).toFixed(2);
      return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    seePorcent(value) {
      return parseFloat(value * 100).toFixed(2);
    },
    deleteItem(index, item) {
      this.cart.splice(index, 1);
      this.validacionCredicticia();
      this.warnCart(this.cart);
      this.deleteMaterialOrder(item);
    },
    editQuantity(item) {
      let quantity = parseInt(item.u_confirm);
      this.quantityDialog.quantity = quantity;
      this.quantityDialog.name = item.nombre_material;
      this.quantityDialog.id = item.id;
      this.quantityDialog.item = item;
      this.quantityDialog.show = true;
    },
    cancelQuantity() {
      this.quantityDialog.quantity = null;
      this.quantityDialog.name = null;
      this.quantityDialog.id = null;
      this.quantityDialog.item = null;
      this.quantityDialog.show = false;
    },
    saveQuantity() {
      this.updateMaterialQuantity({
        codigo_material: this.quantityDialog.item.codigo_material,
        quantity: this.quantityDialog.quantity,
        precio_con_descuento: this.quantityDialog.item.precio_con_descuento,
      })
        .then((data) => {
          console.log(data);
          this.$toast.success("Producto actualizado");
          this.cancelQuantity();
        })
        .catch((error) => {
          console.log(error);
          this.$toast.error(error);
        });
    },
    formatInt(value) {
      try {
        return parseInt(value);
      } catch (error) {
        return value;
      }
    },
    catchTotal(data) {
        this.subtotalPrint = parseFloat(data).toFixed(2);
        this.subtotal = parseFloat(data);
        this.iva = parseFloat((this.subtotal * 0.16).toFixed(2));
        this.total = this.subtotal + this.iva;
        this.total = this.total.toFixed(2);
      },
    validacionCredicticia() {
      this.subtotal = 0;
      //this.iva = 0;
      //this.total = 0;
      for (let index = 0; index < this.cart.length; index++) {
        const element = this.cart[index];
        var importe_producto = element["importe_producto"];
        this.subtotal += parseFloat(importe_producto);
  
      } //end for
      console.log("importe:"+this.subtotal);
      //this.iva = this.subtotal * 0.16;
      //this.total = this.subtotal + this.iva;
      //this.subtotal = this.subtotal.toFixed(2);
      //this.iva = this.iva.toFixed(2);
      //this.total = this.total.toFixed(2);
      this.warnTotal(this.subtotal);
    },
    materialChagePlus(item, x, i) {
      var cant = parseInt(item.u_confirm, 10) + x;
      this.getMaterialChange(item, cant, i);
    },
    materialChageMinus(item, x, i) {
      var cant = item.u_confirm - x;
      this.getMaterialChange(item, cant, i);
    },
    getMaterialChange(item, cant, i) {
      this.warnError(null);
      this.warnDialogLoading(true);
      this.dialogLoading = true;
      axios
        .post("getMaterialInfoQuoterDiscount", {
          code: item.codigo_material,
          units: cant,
          customer: this.user.custumer_code,
          VKORG: this.user.VKORG,
          VTWEG: this.user.VTWEG,
          VKBUR: this.user.VKBUR,
          discount_price: item.precio_con_descuento,
        })
        .then((response) => {
          this.cart[i] = response.data;
          
         this.validacionCredicticia();
         this.warnCart(this.cart);
         this.updateMaterialOrder(response.data);
         this.getImages();
  
        })
        .catch((error) => {
          console.log("Error:"+error.response.data[0]);
          this.warnError(error.response.data[0]);
        })
        .finally(
          () => (this.warnDialogLoading(false), (this.dialogLoading = false))
        );
    },
    getMaterialPrice(item, cant, price, i){
      this.warnError(null);
      this.warnDialogLoading(true);
      this.dialogLoading = true;
      axios
        .post("getMaterialInfoQuoterDiscount", {
          code: item.codigo_material,
          units: cant,
          customer: this.user.custumer_code,
          VKORG: this.user.VKORG,
          VTWEG: this.user.VTWEG,
          VKBUR: this.user.VKBUR,
          discount_price: price,
        })
        .then((response) => {
          this.cart[i] = response.data;
          
         this.validacionCredicticia();
         this.warnCart(this.cart);
         this.updateMaterialOrder(response.data);
         this.getImages();
  
        })
        .catch((error) => {
          console.log("Error:"+error.response.data[0]);
          this.warnError(error.response.data[0]);
        })
        .finally(
          () => (this.warnDialogLoading(false), (this.dialogLoading = false))
        );
    },
    getMaterialIncrease(increase){
      this.warnDialogLoading(true);
      this.radio_percent = increase;
      this.captureRadioPercent(this.radio_percent);

      axios
        .post("getCartQuoterIncrease", {
          id_order: this.order_id,
          customer: this.user.custumer_code,
          VKORG: this.user.VKORG,
          VTWEG: this.user.VTWEG,
          VKBUR: this.user.VKBUR,
          increase: increase
        })
        .then((response) => {
          this.cart = response.data;
          
          this.validacionCredicticia();
          this.updateMaterialQuoterIncrease(this.cart);
          this.warnCart(this.cart);
          this.getImages();
          
        })
        .catch((error) => {
          //alert(error);
          console.log(error);
        })
        .finally(() => this.warnDialogLoading(false));
    },
    quantityDialog: {
      show: false,
      id: null,
      name: null,
      quantity: null,
      item: null,
    },
    seeImageDetail(item) {
      this.dialogCatalogo = true;
      this.itemSelect = item;
      this.product_statistics = {};
      axios
        .post("productStatistics", {
          customer: this.user.custumer_code,
          material_code: this.itemSelect.codigo_material,
        })
        .then((response) => {
          this.product_statistics = response.data;
        })
        .catch((error) => {
          this.warnError(error);
        })
        .finally(() => (this.dialogLoading = false));
    },
    descuentosInfo(item) {
      this.dialogDescuentos = true;
      this.itemSelect = item;
    },
    saveNewMaterialOrder(item) {
      axios
        .post("saveNewMaterialQuoter", {
          order_id: this.order_id,
          item_cart: item,
          percent: this.quoter_type,
        })
        .then((response) => {
          console.log(response);
          this.getImge(item)
        })
        .catch((error) => {
          console.log(error);
  
        })
        .finally(() => (this.dialogLoading = false));
    },
    deleteMaterialOrder(item) {
      axios
        .post("deleteMaterialQuoter", {
          order_id: this.order_id,
          item_cart: item,
        })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.dialogLoading = false));
    },
    updateMaterialOrder(item) {
      axios
        .post("updateMaterialQuoter", {
          order_id: this.order_id,
          item_cart: item,
          customer: this.user.custumer_code,
          VKORG: this.user.VKORG,
          VTWEG: this.user.VTWEG,
          VKBUR: this.user.VKBUR,
        })
        .then((response) => {
          console.log(response);
           this.getImge(item);
          //this.getImages();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.dialogLoading = false));
    },
    getCartItems(id,doctype) {
      this.warnDialogLoading(true);
      console.log(doctype);
     // alert(doctype);
      axios
        .post("getCartQuoterItems", {
          id_order: id,
          customer: this.user.custumer_code,
          VKORG: this.user.VKORG,
          VTWEG: this.user.VTWEG,
          VKBUR: this.user.VKBUR,
          doctype: this.$route.query.doctype,
        })
        .then((response) => {
          this.cart = response.data;
          
          this.validacionCredicticia();
          this.warnCart(this.cart);
          this.getImages();
          
        })
        .catch((error) => {
          alert(error);
        })
        .finally(() => this.warnDialogLoading(false));
    },
    updateMaterialQuoterIncrease(item) {
      axios
        .post("updateMaterialQuoterIncrease", {
          order_id: this.order_id,
          item_cart: item,
          customer: this.user.custumer_code,
          VKORG: this.user.VKORG,
          VTWEG: this.user.VTWEG,
          VKBUR: this.user.VKBUR,
        })
        .then((response) => {
          console.log(response);
          //this.getImge(item);
          //this.getImages();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.dialogLoading = false));
    },
    getImages() {
      for(let i = 0; i < this.cart.length; i++) {
        axios.get(`https://bancodeimagenes.iusa.com.mx/api/listDropboxNew?code=${this.cart[i].codigo_material}&quant=1&view=0`, {
          headers: {
            'Content-Type':'application/json',
            'api-key':'$2y$10$PtV2yrGrt/CwoVlqGDroiOyoUSq581Pq.6NVyAX2vQJUBdE21gxKC',
            'Authorization':'Bearer token',
          }
        }).then(response => {
          this.cart[i].image_url = response.data[0].path;
        })
      }
    },
    getImge(item) {
     // this.warnDialogLoading(true);
      axios
        .get("https://bancodeimagenes.iusa.com.mx/api/listDropboxNew?code="+item.codigo_material+"&quant=1&view=0", {
          headers: {
            'Content-Type':'application/json',
            'api-key':'$2y$10$PtV2yrGrt/CwoVlqGDroiOyoUSq581Pq.6NVyAX2vQJUBdE21gxKC',
            'Authorization':'Bearer token',
          }
        })
        .then((response) => {
          if(response.data[0].path) {
            let index = this.cart.findIndex(element => element.codigo_material == item.codigo_material);
  
            this.cart[index].image_url = response.data[0].path
            console.log('index', index);
          }
          console.log('dropbox', response.data[0].path);
          //this.validacionCredicticia();
          //this.warnCart(this.cart);
        })
    },
    getFileDownload(item) {
      axios
        .get("https://bancodeimagenes.iusa.com.mx/api/allFileDropboxPdf?code="+item+"&type=6", {
          headers: {
            'Content-Type':'application/json',
            'api-key':'$2y$10$PtV2yrGrt/CwoVlqGDroiOyoUSq581Pq.6NVyAX2vQJUBdE21gxKC',
            'Authorization':'Bearer token',
          }
        })
        .then((response) => {
          if(response.data[0].path) {
            var fileURL = response.data[0].path
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', 'file.pdf');
            document.body.appendChild(fileLink);
            fileLink.click();
          }
  
        }).catch((error) => {
          if(error.response.status == 404){
            this.warnError("El código ingresado no tiene archivos para descargar.");
          }else{
            //alert(error);
            console.log(error);
          }
          
        });
    },
  },
  watch: {
    capturedMaterialItem: function (val) {
      this.warnError(null);
      const exist = this.cart.some(
        (item) => item.codigo_material == val.codigo_material
      );
      if (exist) {
        this.warnError("El código ingresado ya está en el carrito.");
      } else {
        this.cart.unshift(val);
        this.validacionCredicticia();
        this.saveNewMaterialOrder(val);
        this.warnCart(this.cart);
      }
    },
    capturedDocType: function(val){
      this.doctype = val
     // alert(this.doctype)
    },
    captureQuoter: function(val){
      this.quoter_type = val
    },
    captureRadioPercent: function(val){
      this.radio_percent = val
    },
    captureRadioDataBase: function(val){
      this.radio_percent = val
    },
    capturedIdOrder: function (val) {
      this.order_id = val;
      this.$nextTick(function () {
        this.getCartItems(this.order_id,this.doctype);
      });
      
    },
  },
  };
  </script>
<style scoped>
.quoter-card {
  font-size: 0.9em; /* Tamaño de fuente deseado */
}
.w-20 {
  width: 20%;
}
.w-30 {
  width: 30%;
}
.w-40 {
  width: 40%;
}
.w-9 {
  width: 99%;
}
.font-size-7 {
  font-size: 0.7em;
}
.font-size-8 {
  font-size: 0.8em;
}
.font-size-9 {
  font-size: 0.9em;
}
.icon-button {
  background: #f5f5f5 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
  border: 1px solid #00000000;
  border-radius: 4px;
  opacity: 1;
  padding-left: 0.4em;
  padding-right: 0.4em;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  cursor: pointer;
}
.icon-button:hover {
  background-color: #ababab;
}
.button-block {
  box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
  border-radius: 5px;
  background: #ffffff;
}
.button-primary-red {
  background: transparent
    linear-gradient(180deg, var(--light-🌕-error-b00020) 0%, #580010 100%) 0% 0%
    no-repeat padding-box;
  border: 1px solid var(--gris-de-las-tablas);
  background: transparent linear-gradient(180deg, #b00020 0%, #580010 100%) 0%
    0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
  border: 1px solid #dde1e6;
  border-radius: 27px;
}
.button-warning {
  background: transparent
    linear-gradient(180deg, var(--warning-hover-e0a800) 0%, #705400 100%) 0% 0%
    no-repeat padding-box;
  border: 1px solid var(--gris-de-las-tablas);
  background: transparent linear-gradient(180deg, #e0a800 0%, #705400 100%) 0%
    0% no-repeat padding-box;
  border: 1px solid #dde1e6;
}
.button-green {
  background: transparent
    linear-gradient(180deg, var(--success-hover-218838) 0%, #11441c 100%) 0% 0%
    no-repeat padding-box;
  background: transparent linear-gradient(180deg, #218838 0%, #11441c 100%) 0%
    0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
  border: 1px solid #707070;
}
.table-header {
  background: transparent
    linear-gradient(180deg, var(--light-🌕-error-b00020) 0%, #580010 100%) 0% 0%
    no-repeat padding-box;
  background: transparent linear-gradient(180deg, #b00020 0%, #580010 100%) 0%
    0% no-repeat padding-box;
  color: white !important;
}
.new-card-red {
  background: #fff6f6 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
}
.new-card-red {
  background: transparent linear-gradient(180deg, #b00020 0%, #580010 100%) 0%
    0% no-repeat padding-box;
  color: white !important;
  box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
}
.new-card-warning {
  background: var(--warning-hover-e0a800) 0% 0% no-repeat padding-box;
  border: 1px solid var(--gris-de-las-tablas);
  background: #e0a800 0% 0% no-repeat padding-box;
  border: 1px solid #dde1e6;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: 1em 1em;
  grid-auto-flow: row;
  grid-template-areas:
    "customer"
    "destination"
    "promotions"
    "type-1"
    "main"
    "search"
    "increments";
}

.customer {
  grid-area: customer;
}

.destination {
  grid-area: destination;
}

.type-1 {
  grid-area: type-1;
}

.main {
  grid-area: main;
}

.promotions {
  grid-area: promotions;
}

.increments {
  grid-area: increments;
}

.search { 
  grid-area: search; }
.btnSearch {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
  border: 1px solid #707070;
  border-radius: 5px;
}

.footer-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: 1em 1em;
  grid-auto-flow: row;
  grid-template-areas:
    "btn-total"
    /*"btn-order" */
    "btn-dashboard"
    "btn-files"
    "btn-save"
    "btn-quotations";
}

/*.btn-order {
  grid-area: btn-order;
}*/

.btn-dashboard {
  grid-area: btn-dashboard;
}

.btn-files {
  grid-area: btn-files;
}

.btn-quotations {
  grid-area: btn-quotations;
}

.btn-save {
  grid-area: btn-save;
}

.btn-total {
  grid-area: btn-total;
  margin-top: 2em;
}

@media (min-width: 1024px) {
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 0.3fr 0.3fr 0.3fr;
    gap: 1em 1em;
    grid-auto-flow: row;
    grid-template-areas:
    "customer customer customer customer customer promotions promotions"
    "type-1 type-1 destination destination destination promotions promotions"
    "main main search search search increments increments";
  }

  .footer-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 1em 1em;
    grid-auto-flow: row;
    grid-template-areas:
      "btn-files btn-save btn-total "
      "btn-quotations btn-dashboard btn-total "
      ". . btn-total";
  }

  .btn-total {
    grid-area: btn-total;
    margin-top: 0;
  }
}
</style>