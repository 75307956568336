<template>
    <v-container fluid>
      <!-- <h1>Opportunities page</h1> -->
      <template>
        <v-card cols="12" class="pa-xs-2 pa-md-2 pa-lg-2 mt-xs-0 mt-md-0 mt-lg-0">
          <v-card-title>HISTORIAL RECORDATORIOS</v-card-title>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-data-table
            dense
            :headers="headers"
            :items-per-page="10"
            :items="data"
            :search="search"
            item-key="id"
            class="elevation-1"
          >
          </v-data-table>
        </v-card>
        <!-- Dialog -->
        <v-row justify="center">
          <v-dialog
            v-model="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
          >
            <v-card>
              <v-toolbar dark color="indigo">
                <v-btn icon dark @click="dialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Pedido: {{ pedidoSelect }}</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
              <p></p>
              <template>
                <v-simple-table dense>
                  <thead>
                    <tr>
                      <th class="text-left">CODIGO</th>
                      <th class="text-left">DESCRIPCION</th>
                      <th class="text-left">UNIDADES</th>
                      <th class="text-left">VENTA</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in pedidoInfo" :key="item.id">
                      <td>{{ item.MATERIAL }}</td>
                      <td>{{ item.SHORT_TEXT }}</td>
                      <td>{{ item.REQ_QTY }}</td>
                      <td>{{ item.NETVA }}</td>
                    </tr>
                    <tr>
                      <td colspan="3" style="text-align: right">
                        <b>Importe</b>
                      </td>
                      <td style="background-color: #3bafda">
                        <b>$ {{ pedidoImporte }}</b>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </template>
            </v-card>
          </v-dialog>
        </v-row>
        <!-- /. Dialog -->
        <!-- Dialog loading -->
        <v-row justify="center">
          <v-dialog v-model="loading" hide-overlay persistent width="300">
            <v-card color="primary" dark>
              <v-card-text>
                Cargando
                <v-progress-linear
                  indeterminate
                  color="white"
                  class="mb-0"
                ></v-progress-linear>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-row>
        <!-- /. Dialog -->
      </template>
    </v-container>
  </template>
  <script>
  import axios from "@/plugins/axios";
  import { mapGetters } from "vuex";
  export default {
    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
    },
    data: () => ({
      data: [],
      dialog: false,
      search: "",
      headers: [
        { text: "ID Record", align: "start", value: "id" },
        { text: "Orden Compra", align: "start", value: "orden_compra" },
        { text: "Código", value: "codigo" },
        { text: "Descripción", sortable: false, value: "nombre" },
        { text: "Medida", sortable: false, value: "medida" },
        { text: "Cantidad", sortable: false, value: "cantidad_pedida" },
        { text: "Estatus", sortable: false, value: "estatus_disponible" },
      ],
      loading: false,
      pedidoSelect: null,
      pedidoInfo: null,
      pedidoImporte: null,
      item:{}
    }),
    mounted() {
      let self = this;
      self.getRecordatoriosHistory();
    },
    methods: {
      getRecordatoriosHistory() {
        this.loading = true;
        axios
          .post("getRecordatoriosHistory", {
            idCliente: this.user.custumer_code,
            customer: this.user.custumer_code,
            VTWEG: this.user.VTWEG,
          })
          .then((response) => {
            this.data = response.data;
          })
          .catch((error) => {
            //alert(error);
            console.log(error);
          })
          .finally(() => (this.loading = false));
      },
      detallePedido(item) {
        this.pedidoSelect = item.pedido;
        this.dialog = true;
        this.loading = true;
        axios
          .post("getInfoPedido", {
            id_pedido: item.pedido,
            cliente: this.user.custumer_code
          })
          .then((response) => {
            this.loading = false;
            this.pedidoInfo = response.data.prueba_Pedido;
            this.pedidoImporte = response.data.importe;
            this.pedidoInfo.pop();
          })
          .catch((error) => {
            this.loading = false;
            //alert(error);
            console.log(error);
          })
          .finally(() => (this.loading = false));
      },
    },
  };
  </script>