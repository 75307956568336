<template>
  <div
    v-if="item.recordatorios == 0"
    class="w-full d-flex justify-space-between align-center rounded shadow-in m-1 p-1"
  >
    <v-checkbox class="mx-2" v-model="checked" @change="check($event,item)">
      <template v-slot:label> </template>
    </v-checkbox>
    <div style="width: 70px" class="mr-1">
      <img class="w-full" :src="src" alt="" />
    </div>

    <div class="flex-grow-1 mx-2">
      <div class="text-left">
        <span class="text-color-bk">{{ item.name }}</span>
      </div>
      <div class="text-left mt-0">
        <span class="text-color-bk">SKU {{ item.sku }}</span>
      </div>
      <div class="text-left mt-0 d-flex justify-space-between align-center">
        <span class="font-weight-bold text-color-bk-c"
          >U. Solicitadas {{ item.orderUnits }}</span
        >
        <span v-bind:class="checked ? 'font-weight-bold text-bk-price-succes':'font-weight-bold text-bk-price'"
          >${{ format(item.totalCost) }}</span
        >
      </div>
    </div>
    <div class="d-flex justify-center align-center" style="height: 20px"></div>
  </div>
</template>

<script>
import axios from "@/plugins/axios";

export default {
  name: "ResultItemValCredit",
  props: ['item'],
  data: () => ({
    src: null,
    checked: false,
    error:'',
    data:'',
  }),
  mounted() {
    this.checked = this.item.v_credit;
    this.loadImage();
  },
  methods: {
    check(event,item){
        this.$emit('onClick',event,item);
    },
    format(value) {
    let result = Number(value).toFixed(2);
    return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
    loadImage() {
      if (!this.item || this.item.materialCode == null) {
        return;
      }
    },
    getImge(item) {
      axios
        .get(
          "https://bancodeimagenes.iusa.com.mx/api/listDropboxNew?code=" +
            item.sku +
            "&quant=1&view=0",
          {
            headers: {
              "Content-Type": "application/json",
              "api-key":
                "$2y$10$PtV2yrGrt/CwoVlqGDroiOyoUSq581Pq.6NVyAX2vQJUBdE21gxKC",
              Authorization: "Bearer token",
            },
          }
        )
        .then((response) => {
          if (response.data[0].path) {
            let index = this.cart.findIndex(
              (element) => element.codigo_material == item.sku
            );

            this.cart[index].image_url = response.data[0].path;
            console.log("index", index);
          }
          console.log("dropbox", response.data[0].path);
        });
    },
  },/**text-title-bk-succes */
};
</script>

<style scoped>
.shadow-in {
  box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
}
.text-color-bk {
  color: gray;
}

.text-color-bk-c {
  color: black;
  font-size: 12px;
}

.text-bk-price {
  color: #b00606;
  font-size: 16px;
}

.text-bk-price-succes {
  color: green;
  font-size: 16px;
}
</style>
