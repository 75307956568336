import axios from "@/plugins/axios";

export default {
  namespaced: true,
  state: {
    requests: [],
    pagination: {
      page: 1,
      to: 1,
      total: 1,
    },
    customer: {},
  },

  getters: {
    allRequests(state) {
        return state.requests;
    },
    pagination(state) {
        return state.pagination;
    },
    customer(state) {
        return state.customer;
    },
  },

  mutations: {
    SET_REQUEST(state, payload) {
        state.requests = payload;
    },
    SET_PAGINATION(state, payload) {
        state.pagination.to = payload.last_page;
    },
    SET_CUSTOMER(state, payload) {
        state.customer = payload;
    },
  },

  actions: {
    async loadCustomerRequest({ commit }, payload) {
      let querySearch = '';
      if(payload.search && payload.search != '') {
        querySearch += '&filter[rfc]=%25' + payload.search + '%25';
      }
      return axios
        .get(`/customers?page=${payload.page}&with[0]=customer_status&select=id,status,channel,custumer_code,company_name,rfc,a_estate,person_type,created_at,live_uuid,envelope_id${querySearch}`)
        .then((response) => {
          commit("SET_REQUEST", response.data.data);
          commit("SET_PAGINATION", response.data);
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
    },
    async loadCustomerDetail({ commit }, payload) {
      return axios
        .get(`/customers/${payload}`)
        .then((response) => {
          commit("SET_CUSTOMER", response.data);
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
    },
    async updateCustomerRequest(_, {id, form}) {
        return axios
            .put(`/customers/${id}`, form)
            .then((response) => {
              return response.data;
            })
            .catch((error) => {
              throw error;
            });
    }
  },
};
