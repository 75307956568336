<template>
    <div>
        <v-btn class="btn__select" @click="isOpen = true">Seleccionar {{title}}</v-btn>
        <v-dialog v-model="isOpen" persistent max-width="1000px">
            <v-card class="card__dialog">
                <v-card-title> 
                    <v-card-actions class="card__close" v-if="this.$getLocalStorageManager()">
                        <v-btn icon dark @click="isOpen = false">
                        <v-icon color="white">mdi-close-circle</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card-title>
        
                <v-card-text class="card__text pt-3">
                    <v-data-table
                        :headers="headers"
                        :items="managers"
                        :footer-props="footerProps"
                        :options="dataTableOptions"
                        :mobile-breakpoint="null"
                        class="elevation-2 table__desing-general table__alternating-rows--grey"
                        >
                        <template v-slot:[`item.NOMGER`]="{ item }">
                            <span style="cursor: pointer; text-decoration: underline" @click="sendManagerHome(item.CODGER)">{{ item.NOMGER }}</span>
                        </template>
                    </v-data-table>          
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
  </template>
  
  <script>
  import axios from "@/plugins/axios";
  import { mapGetters } from "vuex";
  export default {
      props:{
          value: {
              type: Boolean,
              default: false,
          }
      },
      components: {},
      mounted(){
        this.getManagers();
        this.title = this.user.VTWEG == 'PR' ? 'Subgerente' : 'Gerente';
        this.headers[0].text = `${this.title}s`;
      },
      data(){
          return{
            managers: [],
            headers: [{ text: "", value: "NOMGER", align: "center" }],
            footerProps: {
                itemsPerPageOptions: [10, 14, 24, 34, { text: "Todas", value: -1 }],
                itemsPerPageText: "Mostrar en grupo de:",
                showCurrentPage: false,
                pageText: "Desplegado {0} / {1}",
            },
            dataTableOptions: {
                itemsPerPage: 14,
                pagination: {
                    enabled: true,
                    options: {
                        numeric: true,
                    },
                },
            },  
            title: ''          
          }
      },
      methods:{
        async getManagers(){
            try{
                let response = await axios.get('structure/manager-regional', {
                    params: {
                        VTWEG: this.user.VTWEG            
                    },
                    noCancelRequest: true,
                    cachable: true,
                    id: 'get-manager-' + parseInt(this.user.employee_number)
                });
                this.managers = response.data.data;                    
            }catch(error){                    
                console.log(error);
            }      
        },
        sendManagerHome(code){
            this.$setLocalStorageManager(code);
            this.$router.go(0);
        }
      },      
      computed: {
        ...mapGetters({
            user: "auth/user",
        }),
        isOpen: {
            get(){
                return this.value
            },
            set(newValue){
                this.$emit('input', newValue)
            }			
        }
      }      
  };
  </script>
  
  <style scoped>
  .card__dialog{
      background: #583636 !important;
      color: #fff !important;
  }
  .card__close{
      position: absolute;
      top: -8px;
      right: 0;
  }
  .card__text{
      color: #FFF !important;
      text-align: left;
  } 
  .btn__select{
        background: transparent linear-gradient(180deg, #A82222 0%, #FF0000 100%) 0% 0% no-repeat padding-box;
        opacity: 1;
        color: #FFF !important;
        text-transform: inherit !important;        
        height: 30px !important;
        font-size: 14px !important;
    } 
  </style>