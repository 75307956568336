<template>
  <v-container fluid>
    <v-card-title>Cliente</v-card-title>
    <customer-info
      :catchTotal="formattotal"
      :warnLock="catchRequestLock"
      :catchLimit="catchPercent"
      :catchAviableCredit="catchAviable"
      :purValidation="catchpurValidation"
    ></customer-info>
    <v-card :loading="loading">
      <v-card-title>
        <v-container fluid>
          <v-row>
            <v-col cols="12" md="4" class="text-left"> Orden de Compra</v-col>
            <v-col cols="12" md="8">
              <v-text-field
                label="code de orden de compra"
                outlined
                dense
                v-model="orderId"
              ></v-text-field
            ></v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4" class="text-left"> Destinatario de mercancía </v-col>
            <v-col cols="12" md="8">
              <customer-address v-model="form.address"></customer-address>
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <p>Agregar producto al carrito</p>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="addForm.code"
                  label="Código de material / SKU"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="addForm.quantity"
                  label="Cantidad"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col>
                <v-btn color="primary" @click="addItemToCart"
                  >Agregar al carrito</v-btn
                >
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col cols="12" md="12">
                <v-data-table
                  :loading="loading"
                  dense
                  :headers="headers"
                  :items="cart"
                  item-key="codigo_material"
                  disable-pagination
                  class="elevation-1"
                >
                  <template v-slot:[`item.u_confirm`]="{ item }">
                    {{ formatNumber(item.u_confirm) }}
                   <p class="font-size-8"><b>{{
                    item.recordatorios != 0
                      ? `BACKORDER ${item.recordatorios}`
                      : ""
                  }}</b></p>
                  </template>
                  <template v-slot:[`item.precio_lista`]="{ item }">
                    <p class="text-right m-0 p-0">
                      $ {{ formatNumber(item.precio_lista) }}
                    </p>
                  </template>
                  <template v-slot:[`item.precio_con_descuento`]="{ item }">
                    <p class="text-right m-0 p-0">
                      $ {{ formatNumber(item.precio_con_descuento) }}
                    </p>
                  </template>
                  <template v-slot:[`item.importe_total`]="{ item }">
                    <p class="text-right m-0 p-0">
                      $ {{ formatNumber(item.importe_producto)}}
                     <!-- $
                      {{
                        formatNumber(item.u_pedidas * item.precio_con_descuento)
                      }}-->
                    </p>
                  </template>
                  <template v-slot:[`item.acciones`]="{ item }">
                    <v-btn
                      color="error"
                      icon
                      small
                      @click="deleteFromCart(item)"
                    >
                      <v-icon>mdi-trash-can</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:[`body.append`]>
                    <tr>
                      <td colspan="8"></td>
                      <td>
                        <b>Subtotal :</b> <br />
                        <b>IVA :</b> <br />
                        <b>Total:</b>
                      </td>
                      <td style="text-right">
                        $ {{ formatNumber(subtotal) }} <br />
                        $ {{ formatNumber(iva) }} <br />
                        $ {{ formatNumber(total) }}
                      </td>
                      <td></td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn :disabled="loading">Cancelar</v-btn>
        <v-btn color="success" :disabled="isButtonDisabled" @click="makeOrderCsv"
          >Guardar y generar pedido <v-icon>mdi-cube-send</v-icon></v-btn
        >
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import axios from "@/plugins/axios";
import CustomerInfo from "@/views/shopping_cart/componets/CustomerInfo.vue";
import CustomerAddress from "@/views/order_csv/CustomerAddress.vue";
import { eventBus } from "@/main";

export default {
  name: "OrderCsv",
  components: { CustomerInfo, CustomerAddress },
  data: () => ({
    loading: false,
    addForm: {
      code: null,
      quantity: null,
    },
    form: {
      order_id: null,
      order_number: null,
      address: {},
    },
    materialItem: {},
    headers: [
      { text: "Código", value: "codigo_material" },
      { text: "Nombre", value: "nombre_material" },
      { text: "Cantidad", value: "u_confirm" },
      { text: "Empaque", value: "empaque" },
      { text: "Unidad medida", value: "unidad_medida" },
      { text: "Promedio mensual de compra", value: "PMCOM" },
      { text: "Días solicitados de inventario", value: "DSINV" },
      { text: "Precio lista", value: "precio_lista" },
      { text: "Precio descuento", value: "precio_con_descuento" },
      { text: "Importe total", value: "importe_total" },
      { text: "Acciones", value: "acciones" },
    ],
    porcentaje:null,
    aviable:null,
    purchase_validation:1,
    docven: null,
    submitted:false,
    customer_code:null,
    numero_vendedor:null,
    type:null,
    pathHome:null,
    aufsd:null,
  }),
  computed: {
    ...mapGetters({
      user: "auth/user",
      orderId: "ordercsv/orderId",
      cart: "ordercsv/cart",
      total: "ordercsv/total",
      iva: "ordercsv/iva",
      subtotal:"ordercsv/subtotal"
    }),
    formattotal() {
      let format = this.total.toFixed(2);
      return parseFloat(format);
    },
    isButtonDisabled(){
      //total <= 5000 total < 2500
      var total_import=  this.user.VTWEG == 'PR' ? 5000:2500;
      if(this.cart.length == 0 ||  this.submitted || this.total <= total_import){
        if(this.cart.some((item) => item.recordatorios == 0)){ 
          // revisa si hay un material no es de recordatorio con la variable remider_check, aparece en false
          console.log("hay articulos sin recordatorios");
          console.log(this.cart.some((item) => item.recordatorios == 0));
          if(this.total <= total_import){
            return true;
          }else{
            console.log("solo hay recordatorios");
            return false;
          }
        }
      
      }
      return false;
    }
  },
  mounted() {
  if (this.$hasAnyRole(['Customer','CustomerPR'])) {
  this.customer_code =  this.user.custumer_code;
  this.type = 'CL';
  this.numero_vendedor = this.user.custumer_code;
  this.pathHome = '/';
 }else{
  const currentCustomerInfo= JSON.parse(localStorage.getItem('currentCustomerInfo')) || [];
        if (currentCustomerInfo.length<1) {
        this.$router.push({name:'Home'})
        } 
  this.customerInfo_1 = currentCustomerInfo;
  this.customer_code =  parseInt(currentCustomerInfo?.KUNNR,10) 
  this.sellerOrderAviable = false;
  this.type = 'VE';
  this.numero_vendedor = this.user.employee_number;
  this.pathHome = 'CustomerCoverDetails';
 } 
    eventBus.$on("OrderCsv::addOrderId", (data) => {
      this.form.order_id = data.orderId;
      console.log(data);
    });
    this.getOrderID();
  },
  methods: {
    ...mapMutations({
      setOrderId: "ordercsv/SET_ORDER_ID",
      setCard: "ordercsv/SET_CART",
      addItem: "ordercsv/ADD_ITEM",
      deleteItem: "ordercsv/DELETE_FROM_CART",
    }),
    catchRequestLock(data) {
      this.aufsd = data;
    },

    catchTotal(data) {
      this.subtotalPrint = parseFloat(data).toFixed(2);
      this.subtotal = parseFloat(data);
      this.iva = parseFloat((this.subtotal * 0.16).toFixed(2));
      this.total = this.subtotal + this.iva;
      this.total = this.total.toFixed(2);
    },
    catchErrorMaterial(data) {
      this.hasError = data;
      window.scroll(0, window.pageYOffset - 50);
    },
    catchDialogLoading(data) {
      this.dialogLoading = data;
    },
    formatNumber(value) {
      const f = parseFloat(value);
      // return f.toFixed(2);
      return f
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    deleteFromCart(item) {
      this.deleteItem(item.codigo_material);
      return this.$toast.success("Producto eliminado del carrito");
    },
    catchPercent(data){
     this.porcentaje=data;
    },
    catchAviable(data){
     this.aviable = data;
    },
    catchpurValidation(data) {
      console.log(data);
      this.docven = data;
    },
    makeOrderCsv() {
      this.loading = true;
      if (!this.form.address || Object.keys(this.form.address).length <= 0) {
        this.loading = false;
        this.$toast.warning("Debe seleccionar el destinatario de mercancía");
        return;
      }

      if (this.docven > 0 || this.aviable <= 1 || this.aufsd == 1) {
        this.purchase_validation = 2;
      } else {
          if (this.total > this.aviable) {
            this.purchase_validation = 2;
          } else {
            this.purchase_validation = 1;
          }

    }

   let data = {
        VKBUR: this.user.VKBUR,
        VKORG: this.user.VKORG,
        VTWEG: this.user.VTWEG,
        order_type: this.user.VTWEG == 'PR' ? "PSIU":"PSDT",
        carrito: this.cart,
        customer: this.customer_code,
        destino_compra: this.form.address.KUNNR,
        numero_vendedor: this.numero_vendedor,
        orden_compra: this.orderId,
        order_id: this.order_id,
        type: this.type,
        purchase_validation: this.purchase_validation,
        quoter: 0,

      };


     axios
        .post(this.user.VTWEG == 'PR' ? "generate-order-customer-pr":"generateOrderCustomer", data)
        .then((response) => {
          console.log(response);
          this.loading = false;

          if (response.message == "BACKORDER GENERADO") {

            this.$swal.fire(response.message, 'Número de Pedido: S/N', 'success');
            this.$router.replace({
              name: "HistoryOrders",
            });

          } else {
            this.loading = false;
            //this.order_response = response.data.RDCreaEntrega.VBELN;
            this.order_response = response.data.VBELN;
            this.cart = [];
            this.order = null;

            this.$swal.fire('Pedido Generado', 'Número de Pedido: ' + this.order_response + '.', 'success').then(() => {
            this.form.order_id = null;
            this.form.order_number = null;
            this.form.address = {};
            this.setOrderId(null);
            this.setCard([]);
            eventBus.$emit("OrderCsv::orderCreated", response.data);
            this.$router.replace("/history-orders");
          });
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          if(error.response.status == 404){
          //alert(error.response.data.error_code);
            if(error.response.data.message == "BACKORDER GENERADO") {
              this.$swal.fire(
                error.response.data.message,
                "Número de Pedido: S/N",
                "success"
              );
              this.$router.replace({
                name: "HistoryOrders",
              });
            }else{
              this.$swal({
              title: error.response.data.error_code,
              icon: "error",
              type: "error",
            }).then((result) => {
              console.log(result);

            });
            }
          }else{
            //alert(error);
            console.log(error);
          }
        });
    },
    getOrderID() {
    axios
      .post(this.user.VTWEG == 'PR' ? "get-order-id-pr":"getOrderID", {
       // customer: this.user.custumer_code,
        customer: this.customer_code,
        user_id: this.user.id,
        type: this.type,
        doctype: this.user.VTWEG == 'PR' ? "PSIU":"PSDT",
        idq: this.idq,
      })
      .then((response) => {
        this.order_id = response.data;

      })
      .catch((error) => {
        ///alert(error);
        console.log(error);
        this.errormsg = true;
      })
      .finally();
  },
    addItemToCart() {
      let item = this.cart.find(
        (element) => element.codigo_material == this.addForm.code
      );

      if (item) {
        return this.$toast.warning(
          "Producto existente en carrito. Modifique la cantidad."
        );
      }

      let data = {
        VKBUR: this.user.VKBUR,
        VKORG: this.user.VKORG,
        VTWEG: this.user.VTWEG,
        customer:this.customer_code,
        code: this.addForm.code,
        units: this.addForm.quantity,
      };
      axios
        .post("/getMaterialInfo", data)
        .then((response) => {
          console.log(response.data);
          this.addItem(response.data);
          this.$toast.success("Producto agregado");
          this.resetAddForm();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    resetAddForm() {
      this.addForm.code = null;
      this.addForm.quantity = null;
    },
  },
};
</script>
