<template>
    <v-row justify="center">
        <v-dialog v-model="dialogLoading" hide-overlay persistent width="300">
            <v-card color="white" dark>
                <v-card-text>
                    <span style="color: #4A148C;" class="mb-7">Procesando....</span>
                    <v-progress-linear color="#4A148C" indeterminate rounded height="6" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
export default {
    name: "LoadingBar",
    props: {
        capturedDialogLoading: {
            type: Boolean,
            default: null,
        },
    },
    data: () => ({
        dialogLoading: false,
    }),
    methods: {
    },
    watch: {
        capturedDialogLoading: function (val) {
            this.dialogLoading = val;
        },
    },
};
</script>