<template>
    <div class="container-fluid grey lighten-5 pt-4">
        <h2 class="h2">Videoteca</h2>
        <v-row class="mb-5 mt-5">
            <v-col>
                <v-card class="mb-5">
                    <v-card-title>
                        <span>Tutoriales</span>
                    </v-card-title>
                    <v-progress-linear class="mx-4" :active="loadingTutorial" :indeterminate="loading" top color="deep-purple accent-4"></v-progress-linear>
                    <v-divider class="mx-4"></v-divider>
                    
                    <v-card-text>
                        <v-row class="mb-5">
                            <v-col v-for="(item, index) in dataTutorial" :key="index">
                                <v-card class="mx-auto" max-width="450">
                                    <iframe :src="item.video_show"></iframe>
                                    <a :href="item.video_link" target="_blank">
                                        <v-card-title>
                                            <div class="text-table-profile  mb-2">{{item.name}}</div>
                                        </v-card-title>
                                    </a>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>

                <v-card class="mb-5">
                    <v-card-title>
                        <span>Videos</span>
                    </v-card-title>
                    <v-progress-linear class="mx-4" :active="loadingVideo" :indeterminate="loading" top color="deep-purple accent-4"></v-progress-linear>
                    <v-divider class="mx-4"></v-divider>
                    
                    <v-card-text>
                        <v-row class="mb-5">
                            <v-col v-for="(item, index) in dataVideo" :key="index">
                                <v-card class="mx-auto" max-width="450">
                                    <iframe :src="item.video_show"></iframe>
                                    <a :href="item.video_link" target="_blank">
                                        <v-card-title>
                                            <div class="text-table-profile  mb-2">{{item.name}}</div>
                                        </v-card-title>
                                    </a>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <!--
                <v-card class="mb-5 mt-5">
                    <v-card-title>
                        <span>Catálogos</span>
                    </v-card-title>
                    <v-progress-linear class="mx-4" :active="loadingCatalogue" :indeterminate="loading" top color="deep-purple accent-4"></v-progress-linear>
                    <v-divider class="mx-4"></v-divider>
                    
                    <v-card-text>
                        <v-row class="mb-5">
                            <v-col v-for="(item, index) in dataCatalogue" :key="index">
                                <v-card class="mx-auto" max-width="350">
                                    <v-img :src="item.image" :aspect-ratio="16/9" style="height: 400px;"></v-img>
                                    <v-card-title>
                                        <a :href="item.file" target="_blank">
                                            <div class="text-table-profile  mb-2">{{item.name}}</div>
                                        </a>
                                    </v-card-title>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-card class="mb-5 mt-5">
                    <v-card-title>
                        <span>Promociones</span>
                    </v-card-title>
                    <v-progress-linear class="mx-4" :active="loadingPromotion" :indeterminate="loading" top color="deep-purple accent-4"></v-progress-linear>
                    <v-divider class="mx-4"></v-divider>

                    <v-card-text>
                        <v-row class="mb-5">
                            <v-col  v-for="(item, index) in dataPromotion" :key="index">
                                <v-card class="mx-auto" max-width="350">
                                    <v-img :src="item.image" :aspect-ratio="16/9" style="height: 350px;"></v-img>
                                    <v-card-title>
                                        <a :href="item.file" target="_blank">
                                            <div class="text-table-profile  mb-2">{{item.name}}</div>
                                        </a>
                                    </v-card-title>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card> -->
            </v-col>
        </v-row>
        
    </div>
</template>
  
<script>
// @ is an alias to /src
import axios from "@/plugins/axios";
export default {
    name: "MulimediaCenter",
    components: {},
    data() {
        return {
            dataCatalogue: [],
            loadingCatalogue: true,
            dataPromotion: [],
            loadingPromotion: true,
            dataVideo: [],
            dataTutorial: [],
            loadingVideo: true,
            loadingTutorial: true,
            hasError: false,
            timeout: 8000,
        };
    },
    methods: {
        getCatalogue() {
            this.loadingCatalogue = true;

            axios
                .get("catalogsPDF")
                .then((response) => {

                    this.dataCatalogue = response.data;
                })
                .catch((error) => {
                    if (error.response.data.errors) {
                        this.msg = error.response.data.errors;
                        this.hasError = true;
                        setTimeout(() => {
                            this.hasError = false;
                        }, 8000);
                    } else {
                        console.warn(error);
                    }
                })
                .finally(() => (this.loadingCatalogue = false));
        },
        getPromotion() {
            this.loadingPromotion = true;

            axios
                .get("promotionsPDF")
                .then((response) => {

                    this.dataPromotion = response.data;
                })
                .catch((error) => {
                    if (error.response.data.errors) {
                        this.msg = error.response.data.errors;
                        this.hasError = true;
                        setTimeout(() => {
                            this.hasError = false;
                        }, 8000);
                    } else {
                        console.warn(error);
                    }
                })
                .finally(() => (this.loadingPromotion = false));
        },
        getTutorial() {
            this.loadingTutorial = true;

            axios
                .get("tutorialMultimedia")
                .then((response) => {

                    this.dataTutorial = response.data;
                })
                .catch((error) => {
                    if (error.response.data.errors) {
                        this.msg = error.response.data.errors;
                        this.hasError = true;
                        setTimeout(() => {
                            this.hasError = false;
                        }, 8000);
                    } else {
                        console.warn(error);
                    }
                })
                .finally(() => (this.loadingTutorial = false));
        },
        getVideo() {
            this.loadingVideo = true;

            axios
                .get("videoMultimedia")
                .then((response) => {

                    this.dataVideo = response.data;
                })
                .catch((error) => {
                    if (error.response.data.errors) {
                        this.msg = error.response.data.errors;
                        this.hasError = true;
                        setTimeout(() => {
                            this.hasError = false;
                        }, 8000);
                    } else {
                        console.warn(error);
                    }
                })
                .finally(() => (this.loadingVideo = false));
        },
    },
    mounted() {
        this.getCatalogue();
        this.getPromotion();
        this.getTutorial();
        this.getVideo();
    }
};
</script>
  
<style scoped>
.v-image {
    height: 15em;
}

iframe {
    width: 100%;
}

.iframe-list-price {
    height: 70em;
}

.v-card-title {
    text-align: center;
}

.v-card-title a {
    text-decoration: none !important
}
</style>