<template>
  <div>
    <v-text-field
      v-model="passwordValue"
      :name="name"
      :label="label"
      :placeholder="placeholder"
      :required="!noRequired"
      :prepend-icon="prependIcon"
      :autocomplete="autocomplete"
      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
      :type="showPassword ? 'text' : 'password'"
      :rules="rules"
      :hide-details="hideDetails"
      @click:append="showPassword = !showPassword"
      @input="e => $emit('change', e)"
    />
    <check-list :checks="rulesMessages" class="ml-8 mb-4" />
  </div>
</template>

<script>
import CheckList from './CheckList.vue';

export default {
  name: 'PasswordInput',

  props: {
    value: {
      type: String,
      required: true,
    },

    label: {
      type: String,
      required: true,
    },

    placeholder: {
      type: String,
      default: 'Ingrese la contraseña',
    },

    name: {
      type: String,
      default: 'password'
    },

    autocomplete: {
      type: String,
      default: 'password'
    },

    rules: {
      type: Array,
      default: () => [],
    },

    rulesMessages: {
      type: Array,
      default: () => [],
    },

    prependIcon: {
      type: String,
      default: 'mdi-lock-alert-outline'
    },

    noRequired: Boolean,
    hideDetails: Boolean
  },

  components: { CheckList },

  data() {
    return {
      showPassword: false
    }
  },

  computed: {
    passwordValue: {
      set(newValue){
        this.$emit('input', newValue)
      },

      get(){
        return this.value;
      }
    }
  }
}
</script>