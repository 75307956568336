import api from '@/plugins/axios';

export default {
  namespaced: true,
  state: {
    customerSelected: null,

    loading: false,
    loadCustomerError: false,
    noCustomerSelected: false,
  },

  getters: {
    customer: (state) => state.customerSelected,
    loading: (state) => state.loading,
    loadCustomerError: (state) => state.loadCustomerError,
    noCustomerSelected: (state) => state.noCustomerSelected,

    loggedinUserIsCustomer: (state, getters, rootState) => {
      const userState = rootState.auth;
      const userRoles = userState.roles;
      return userRoles.some((role) =>
        ['Customer', 'CustomerPR'].includes(role.name)
      );
    },
  },

  mutations: {
    SET_USER(state, { custumer_code, VTWEG, VKORG, VKBUR } = {}) {
      state.customerSelected = {};
      state.customerSelected.customer = custumer_code;
      state.customerSelected.VTWEG = VTWEG;
      state.customerSelected.VKORG = VKORG;
      state.customerSelected.VKBUR = VKBUR;
    },

    SET_CUSTOMER_FLAGS(state, [loading, loadCustomerError, noCustomerSelected]) {
      state.loading = loading;
      state.loadCustomerError = loadCustomerError;
      state.noCustomerSelected = noCustomerSelected;
    },
  },

  actions: {
    async loadCustomer({ rootState, state, commit }) {
      // Loads user data if the user is a seller it will load data of a preselected customer
      const userState = rootState.auth;
      const userRoles = userState.roles;

      commit('SET_CUSTOMER_FLAGS', [true, false, false]);

      try {
        const hasRoles = userRoles.some((role) =>
          ['Customer', 'CustomerPR'].includes(role.name)
        );

        if (hasRoles) {
          // Customers can only load his own data
          commit('SET_USER', userState.user);
        } else {
          const selectedCustomerCode = getSelectedCustomerCode();
          // We fetch a user when no customer is setted or is preselected customer has changed
          if (
            state.customerSelected == null ||
            state.customerSelected.customer != selectedCustomerCode
          ) {
            if (selectedCustomerCode !== null) {
              const customerData = await fetchCustomer(
                selectedCustomerCode,
                userState.user.VTWEG,
                userState.user.VKORG
              );
              commit('SET_USER', customerData);
            } else {
              // IF not preselected customer noCustomerSelected flag is set to true
              commit('SET_CUSTOMER_FLAGS', [false, false, true]);
              return;
            }
          }
        }
      } catch (error) {
        commit('SET_CUSTOMER_FLAGS', [false, true, false]);
        return;
      }

      commit('SET_CUSTOMER_FLAGS', [false, false, false]);
    },

    setSelectedCustomerCode(vuex, code) {
      setSelectedCustomerCode(code);
    },

    selectedCustomerCode() {
      return getSelectedCustomerCode();
    },
  },
};

const getSelectedCustomerCode = () => {
  const code = localStorage.getItem('currentCustomerCode');
  if (code && code.length > 0) {
    return JSON.parse(code);
  }

  return null;
};

const setSelectedCustomerCode = (code) => {
  localStorage.setItem('currentCustomerCode', JSON.stringify(code));
};

const setSelectedCustomerData = (data) => {
  localStorage.setItem('currentCustomerInfo', JSON.stringify(data));
};

const fetchCustomer = async (customerCode, sellerChannel, sellerVKORG) => {
  const response = await api.post('cover/getCustomerInformation', {
    customer: customerCode,
    VTWEG: sellerChannel,
    VKORG: sellerVKORG,
    society: '217',
  });

  const customerData = response.data;

  setSelectedCustomerData(customerData);

  return {
    custumer_code: customerCode,
    VTWEG: customerData.VTWEG,
    VKORG: customerData.VKORG,
    VKBUR: customerData.VKBUR,
  };
};
