export const segmentos =
[
    {
        'image': 'divisiones_1.png',
        'name': 'ELECTRICIDAD',
        'ws_name': 'ELECTRICIDAD',
        'size': '60%'
    },
    {
        'image': 'divisiones_2.png',
        'name': 'PLOMERÍA',
        'ws_name': 'PLOMERIA',
        'size': '95px'
    },
    {
        'image': 'divisiones_3.png',
        'name': 'HERRAMIENTAS',
        'ws_name': 'HERRAMIENTAS',
        'size': '60%'
    },
    {
        'image': 'divisiones_4.png',
        'name': 'FERRETERÍA',
        'ws_name': 'FERRETERIA',
        'size': '100%'
    },
    {
        'image': 'divisiones_11.png',
        'name': 'CERRAJERÍA',
        'ws_name': 'CERRAJERIA',
        'size': '75%'
    },
    {
        'image': 'divisiones_13.png',
        'name': 'JARDINERÍA',
        'ws_name': 'JARDINERIA',
        'size': '65%'
    },
    {
        'image': 'divisiones_14.png',
        'name': 'HOGAR',
        'ws_name': 'HOGAR',
        'size': '70%'
    },               
    {
        'image': 'divisiones_16.png',
        'name': 'PROTECCIÓN E HIGIENE',
        'ws_name': 'PROTECCION E HIGIENE',
        'size': '80%'
    },
    {
        'image': 'divisiones_17.png',
        'name': 'RECUBRIMIENTOS Y ACABADOS',
        'ws_name': 'RECUBRIMIENTOS Y ACABADOS',
        'size': '55%'
    },
    {
        'image': 'divisiones_18.png',
        'name': 'COBRE Y SUS ALEACIONES',
        'ws_name': 'COBRE Y SUS ALEACIONES',
        'size': '56%'
    },
    {
        'image': 'divisiones_20.png',
        'name': 'FUNDICIÓN Y FORJAS',
        'ws_name': 'FUNDICION Y FORJAS',
        'size': '60%'
    },
    {
        'image': 'divisiones_19.png',
        'name': 'MADERA SINTÉTICA',
        'ws_name': 'MADERA SINTETICA',
        'size': '65%'
    },
    {
        'image': 'divisiones_21.png',
        'name': 'MEDICIÓN AGUA Y GAS',
        'ws_name': 'MEDICION AGUA Y GAS',
        'size': '70%'
    },
    {
        'image': 'divisiones_22.png',
        'name': 'PUBLICIDAD Y PROMOCIÓN',
        'ws_name': 'PUBLICIDAD Y PROMOCION',
        'size': '70%'
    },
]     