<template>
    <div class="container-fluid">
        <CardTitle :title="title"  icon="wallet-plus-outline">
            <template v-slot:content >
                <div v-if="!loading" class="elevation-5">
                    <v-card-text>
                        <v-row class="font-size-x">
                        <v-col cols="12" md="6" class="pa-sm-4 pb-md-0 pl-md-6 pr-md-9">
                            <v-row class="mt-1 pa-0">
                                <v-col cols="8" sm="6" class="text-left pa-0">
                                    <strong class=" pa-0 text-black "> Límite de crédito</strong>
                                </v-col>
                                <v-col cols="4" sm="6" class="text-right pa-0">
                                    ${{  number_format(walletData?.KLIMK)  }}
                                </v-col>
                            </v-row>
                            <v-row class="pa-0">
                                <v-col cols="8" sm="6" class="text-left pa-0">
                                    <strong class="text-black"> Línea de crédito comprometida</strong>
                                </v-col>
                                <v-col cols="4" sm="6" class="text-right pa-0">
                                    ${{ number_format(walletData?.SKFOR) }}
                                </v-col>
                            </v-row>
                            <v-row class="pa-0">
                                <v-col cols="8" sm="6" class="text-left pa-0">
                                    <strong class="text-black"> Línea de credito disponible</strong>
                                </v-col>
                                <v-col cols="4" sm="6" class="text-right pa-0">
                                    ${{ number_format(walletData?.CREDD) }}
                                </v-col>
                            </v-row>
                            <v-row class="pa-0">
                                <v-col cols="8" sm="6" class="text-left pa-0">
                                    <strong class="text-black"> Plazo en días</strong>
                                </v-col>
                                <v-col cols="4" sm="6" class="text-right pa-0">
                                    {{ walletData?.VTEXT }}
                                </v-col>
                            </v-row>
                            <v-row class="pa-0">
                                <v-col cols="8" sm="6" class="text-left pa-0">
                                    <strong class="text-black"> Prom. prond. de dias de pago (últimos 3 meses)(#docs)</strong>
                                </v-col>
                                <v-col cols="4" sm="6" class="text-right pa-0">
                                    {{walletData.PPDP}} ({{ walletData.DOCDP }})
                                </v-col>
                            </v-row>
                        </v-col> 
                        <v-col cols="12" offset-lg="1" md="5" class="pa-sm-4  pb-md-0 pl-md-6 pr-md-9">
                            <v-row class=" pa-0 pb-md-n10">
                                <v-col cols="8" sm="6" class="text-left pa-0">
                                    <strong class="text-black "> Saldo vencido</strong>
                                </v-col>
                                <v-col cols="4" sm="6" class="text-right pa-0">
                                    ${{ number_format(walletData?.MONVENC,false) }}
                                </v-col>
                            </v-row>
                            <v-row class="pa-0 pb-md-n10 ">
                                <v-col cols="8" sm="6" class="text-left pa-0">
                                    <strong class="text-black"> Saldo total</strong>
                                </v-col>
                                <v-col cols="4" sm="6" class="text-right pa-0">
                                    ${{ number_format(walletData?.SKFOR,false) }}
                                </v-col>
                            </v-row>
                            <v-row class=" pa-0 pb-md-n10 ">
                                <v-col cols="8" sm="6" class="text-left pa-0">
                                    <strong class="text-black"> Notas de crédito pendientes de aplicar</strong>
                                </v-col>
                                <v-col cols="4" sm="6" class="text-right pa-0">
                                    {{ walletData.NCPAP??0 }}
                                </v-col>
                            </v-row>
                            <v-row class=" pa-0 pb-md-n10 d-none d-lg-flex mb-1">
                                <v-col cols="8" sm="6" class="text-left pa-0">
                                    <strong class="text-black"> Pagos pendientes por aplicar</strong>
                                    
                                </v-col>                        
                                <v-col cols="4" sm="6" class="text-right pa-0 ">
                                    {{ walletData?.PPPAP }}
                                </v-col>
                                <v-col cols="12" >
                                    <div class="d-flex justify-center">  
                                    <a @click="goTo('CustomerAccountStatement')" class="nav-link text-center active pt-sm-n10" style="width:40px">
                                            <v-btn x-large block style="
                                            background: transparent linear-gradient(180deg, #A82222 0%, #FF0000 100%) 0% 0% no-repeat padding-box;
                                            box-shadow: inset 0px 4px 6px #00000029, 0px 4px 6px #00000029;
                                            color: #fff;
                                            text-transform: none;
                                            font-weight: 500;
                                            ">
                                            Estado de cuenta
                                            </v-btn> 
                                    </a>
                                </div>
                                </v-col> 
                            </v-row>
                        </v-col> 
                        <v-row >
                                <v-col cols="12" >
                                    <div class="d-flex d-lg-none mb-1 mt-1 justify-center">
                                        <a  @click="goTo('CustomerAccountStatement')"  class="nav-link active text-left pt-sm-n12" style="width:40px">
                                        <v-list-item class="text-center mb-4">
                                            <v-btn x-large block style="
                                            background: transparent linear-gradient(180deg, #A82222 0%, #FF0000 100%) 0% 0% no-repeat padding-box;
                                            box-shadow: inset 0px 4px 6px #00000029, 0px 4px 6px #00000029;
                                            color: #fff;
                                            text-transform: none;
                                            font-weight: 500;
                                            ">
                                            Estado de cuenta
                                            </v-btn>
                                        </v-list-item>
                                    </a>
                                    </div> 
                                </v-col> 
                            </v-row>
                    </v-row> 
                    </v-card-text> 
                </div>
                <div v-else>
                    <v-progress-linear indeterminate color="primary darken-2"></v-progress-linear>
                    <span class="text-secondary">Cargando información...</span>
                </div>
                    
            </template>
        
        </CardTitle> 
    </div>
</template>

<script>
import CardTitle from '@/components/CardTitle.vue'
import moment from 'moment';
import { mapGetters } from "vuex";
import helpers from '@/mixins/helpers'; 
import axios from '@/plugins/axios';

export default ({
    mixins:[helpers],
    mounted(){
        setInterval(() =>  this.time = moment().format("H:mm"), 1000)
    },
    computed:{
        title(){
            let currentDate = new Date();
            let day = currentDate.getDate();
            let month = currentDate.getMonth()+1;
            let year = currentDate.getFullYear();
      //      let hour = currentDate.getHours();
     //       let min = currentDate.getMinutes();
            day = day<10?'0'+day:day;
            month = month<10?'0'+month:month; 
            return `CARTERA Y LINEA DE CREDITO AL ${day} ${month} ${year} ${this.time}`;
        },
        ...mapGetters({
        user: "auth/user",
        }),
    },
    props: ['customer'],
    components: { CardTitle},
    name: 'WalletInformation',
    data() {
    return { 
      time: null, 
      loading:true,
      walletData:[]
    }
  },
  watch:{
        customer(){
            this.loadWallet();
        }
    },
  methods: {
    goTo(routeName) { 
        this.$router.push({name: routeName});
    },
    loadWallet(){ 
        this.loading=true;
        axios.post("/cover/customerWallet", {
          customer: this.customer.KUNNR,
          society: "217",
          VKORG: this.customer.VKORG,
          VTWEG: this.customer.VTWEG,             
        },
        {
            noCancelRequest:true,
            cachable:true,
        })
        .then((response) => {
          this.walletData = response.data;  
          this.$emit("walletLoaded", this.walletData);
        })
        .finally(() => {
            this.loading=false
        }).catch( () => this.loading=false);
    }
  }

})
</script>

<style scoped> 
.font-size-x {
		font-size: 14px !important;
	}     
@media (max-width: 600px) { 
	.font-size-x {
		font-size: 11px !important;
	}
}

@media (min-width: 601px) and (max-width:960px) {
	.font-size-x {
		font-size: 12px !important;
	}
}
</style>