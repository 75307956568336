<template>
    <div>
        <v-simple-table class="my-t" dense>
                  <template v-slot:default  >
                    <thead>
                      <tr class="bordered">
                        <th colspan="8"  class="text-center text-dark border-bottom">
                          <b>{{title}}</b>  </th>
                        </tr> 
                      <tr class="bordered"> 
                        <th v-for="(item,index) in newProducts.months" :key="index" class="text-center text-dark">
                          <b>{{ item }}</b>
                        </th> 
                      </tr> 
                    </thead>
                    <tbody>
                      <tr>
                        <td v-for="(item,index) in newProducts.values"  :key="index"> 
                          <span :class="setItemClass(item)">{{ formatFloat(item) }}%</span> 
                        </td> 
                      </tr>
                    </tbody>
                  </template>
                
                </v-simple-table>
    </div>
</template>
<script>
import axios from '@/plugins/axios.js';
import numbersFormats from '@/mixins/numbersFormats';

export default({
    name:'NewProducts',
    mixins:[numbersFormats],
    props: {
      chnl: { 
        required:true,
        String
      },
      isGen:{
        default:0,
      },
      title:{
        default:'FACTURACIÓN EN PRODUCTOS NUEVOS CLIENTE'
      },
      regionalManager:{
        required:true,
      }
    },
    data: () => ({
        loading:true, 
        newProducts:[],
        months:[],
    }),
    methods:{
      setItemClass(value){
        switch (value) {
          case this.newProducts.min: return 'text-danger'
          case this.newProducts.max: return 'text-success' 
        }
      },
    async loadNewPRoducts(){  
        this.loading=true;
        try { 
          let params = { 
                  channel: this.chnl, 
                  type:this.isGen
                };  
          if (this.regionalManager) params.rgManager = this.regionalManager ;
          const response = await axios.get("/daily-director/new-products", {
                params: params
              });
          this.newProducts = response.data;
        } catch (error) { 
          this.loading=false;
        } 
        this.loading=false;
      }, 
    },
    mounted(){
      this.loadNewPRoducts()
    },
    watch: {
      isGen() {
        console.log('Ejecutó new Products')
        this.loadNewPRoducts()
      }, 
  },
})
</script>
<style>
.border-bottom {
  border-width: 0px 0px 2px 0px !important;
  border: 1px solid #DDE1E6;
}
.bordered > td {
  border: 1px solid #DDE1E6;
}

.bordered > th { 
  background: #F2F2F2 0% 0% no-repeat padding-box;
box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
}
</style>
<style scoped src="../../../../../public/css/managercustom.css"></style>